import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  // DialogTitle,
} from "@mui/material";
import CustomDialog from "Components/CustomDialog/CustomDialog";

import { IConfirmationContext } from "Providers/ConfirmationProvider";

interface IConfirmationDialogProps extends IConfirmationContext {
  setOpen: (...args: any) => void;
}

const ConfirmationModal = ({
  open,
  title,
  body,
  setOpen,
  callback,
  size,
}: IConfirmationDialogProps) => {
  return (
    <CustomDialog
      isConfirmation
      onBackdropClick={() => {}}
      disableEscapeKeyDown
      fullWidth
      maxWidth={size || "xs"}
      open={open}
    >
      {body ? (
        <>
          <DialogTitle>
            <>{title}</>
          </DialogTitle>
          <DialogContent>{body}</DialogContent>
        </>
      ) : (
        <>
          <DialogContent>
            {typeof title === "string" ? (
              <Typography variant="body1" fontWeight={"bold"}>
                {title}
              </Typography>
            ) : (
              <>{title}</>
            )}
          </DialogContent>
        </>
      )}

      <DialogActions disableSpacing>
        <Stack direction="row" spacing={5} sx={{ width: "100%" }}>
          <Button
            color="secondary"
            fullWidth
            size="small"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button color="primary" fullWidth size="small" onClick={callback}>
            Ok
          </Button>
        </Stack>
      </DialogActions>
    </CustomDialog>
  );
};

export default ConfirmationModal;
