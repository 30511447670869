import { ApiMediaPayload, ApiMediaType } from "@incendium/api";
import { mediaService } from "Apis";
import { S3Response } from "react-s3-uploader";

export default async function createMedia(
  result: S3Response,
  file: File,
  projectId: number,
  type: ApiMediaType
) {
  const payload: ApiMediaPayload = {
    name: result.filename,
    type,
    files: [
      {
        fileName: result.filename,
        fullPath: result.publicUrl,
        fileType: file.type,
      },
    ],
  };

  return await mediaService.mediaServiceCreateMedia({
    projectId,
    payload,
  });
}
