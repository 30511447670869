import { useSelector } from "react-redux";
import { clientSelector } from "Selectors/clientSelector";
import { useClientIdFromUrl } from "./useClientIdFromUrl";

export const useClientSelector = () => {
  const clientId = useClientIdFromUrl();

  const client = useSelector(clientSelector(clientId));

  return client;
};
