import { IStoreState } from "Interfaces";

export const locationSelector =
  (clientId?: string, projectId?: string, locationId?: string) =>
  (state: IStoreState) => {
    if (!clientId || !projectId || !locationId) {
      return null;
    }

    const clientLocations = state.application.locations[clientId];
    if (!clientLocations) {
      return null;
    }
    const locations = clientLocations[projectId].state;
    if (!locations) {
      return null;
    }
    const index = locations.findIndex(
      (item) => item.id === parseInt(locationId)
    );
    if (index === -1) {
      return null;
    }
    return locations[index];
  };
