import { Typography, TypographyProps, Tooltip } from "@mui/material";
import { Box, styled } from "@mui/material";
import { getChartColor, labelMetricToName } from "features/analytics";
import { useMemo } from "react";

interface IChartLegendItemProps extends TypographyProps {
  item: string;
  index: number;
  noTooltip?: boolean;
  fill?: string;
}

const ChartLegendItem = styled(
  ({ item, noTooltip, ...rest }: IChartLegendItemProps) => {
    const label = useMemo(() => {
      return labelMetricToName(item);
    }, [item]);
    return (
      <Box
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {noTooltip ? (
          <Typography
            {...rest}
            variant="body2"
            noWrap
            sx={{ display: "block!important" }}
          >
            {label}
          </Typography>
        ) : (
          <Tooltip title={label}>
            <Typography
              {...rest}
              variant="body2"
              noWrap
              sx={{ display: "block!important" }}
            >
              {label}
            </Typography>
          </Tooltip>
        )}
      </Box>
    );
  }
)(({ theme, index, fill }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  paddingLeft: 16,
  lineHeight: "16px",
  // marginBottom: "4px",

  "&::before": {
    content: "''",
    width: 10,
    height: 10,
    marginRight: theme.spacing(1),
    background: fill || getChartColor(index, theme.palette.charts, 1),
    display: "block",
    borderRadius: "50%",
    position: "absolute",
    top: 4,
    left: 0,
  },
}));

export default ChartLegendItem;
