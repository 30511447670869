import { ApiEngagementRule, ApiProject } from "@incendium/api";
import { engagementService } from "Apis";
import { useSharedState } from "./useSharedState";

const fetchEngagements = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const engagements =
      await engagementService.engagementServiceListEngagements({
        projectId: selectedProject.id as number,
      });
    return engagements.results || [];
  }
  return [];
};

export const useEngagements = () => {
  const { state, setState } = useSharedState(
    "engagements",
    [],
    fetchEngagements
  );

  return {
    engagements: state as ApiEngagementRule[],
    setEngagements: setState,
  };
};
