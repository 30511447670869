/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiLocationFromJSON, ApiLocationToJSON, } from './ApiLocation';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
import { ApiReservedPageTypesFromJSON, ApiReservedPageTypesToJSON, } from './ApiReservedPageTypes';
export function ApiPageTypeFromJSON(json) {
    return ApiPageTypeFromJSONTyped(json, false);
}
export function ApiPageTypeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'locations': !exists(json, 'locations') ? undefined : (json['locations'].map(ApiLocationFromJSON)),
        '_default': !exists(json, 'default') ? undefined : ApiReservedPageTypesFromJSON(json['default']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}
export function ApiPageTypeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'project': ApiProjectToJSON(value.project),
        'name': value.name,
        'locations': value.locations === undefined ? undefined : (value.locations.map(ApiLocationToJSON)),
        'default': ApiReservedPageTypesToJSON(value._default),
        'projectId': value.projectId,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}
