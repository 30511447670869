import { useCallback, useRef, useState } from "react";
import {
  Theme,
  Typography,
  Box,
  Switch,
  Alert,
  Stack,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { locationService } from "Apis";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useLocations } from "Hooks";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import { CenterPage } from "Components/CenterPage/CenterPage";
import produce from "immer";
import SavingButton from "Components/UI/SavingButton";

export const useAddLocationStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4, 0),
  },
  firstForm: {
    marginBottom: theme.spacing(6),
  },
  caption: {
    margin: theme.spacing(0.5, 0, 2),
  },
  rulesWrapper: {
    minWidth: 550,
    flex: 1,
  },

  matchedLocationClass: {
    minWidth: 450,
    height: 370,
    flex: 1,
  },
  searchWrapperClass: {
    margin: theme.spacing(2, 0),
  },
  imageClass: {
    background: "transparent",
    maxWidth: "100%",
    padding: theme.spacing(0.5),
    marginLeft: -theme.spacing(1),
  },
  matchedLinksLoading: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: `calc(100% - ${theme.spacing(3)})`,
    height: `calc(100% - ${theme.spacing(3)})`,
    background: "rgba(255,255,255,0.7)",
  },
  matchedLink: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(0.5),
    },
  },
}));

export const AddaLocation = ({
  locationId,
  saveLocation,
  saving,
}: {
  locationId: number;
  saveLocation: () => Promise<void>;
  saving: boolean;
}) => {
  const [oldUrl, setOldUrl] = useState("");
  const [newUrl, setNewUrl] = useState("");
  const { firstForm } = useAddLocationStyles();
  const { location, setLocation } = useLocations(locationId);
  const formRef = useRef<HTMLFormElement | any>();

  const newURLAndContinue = useCallback(() => {
    setLocation(
      produce(location, (draft) => {
        draft.urlMatch = newUrl;
      })
    );

    formRef.current.submit();
  }, [newUrl, location, setLocation, formRef]);

  const validateSettings = useCallback(async () => {
    if (oldUrl === "") {
      const res = await locationService.locationServiceValidateLocation({
        body: {
          url: location.urlMatch,
        },
      });

      if (res.newUrl && res.newUrl !== "") {
        setNewUrl(res.newUrl);
        setOldUrl(location.urlMatch || "");
        return;
      }
    }

    saveLocation();
  }, [saveLocation, location, oldUrl]);

  return (
    <>
      <CenterPage calcHeight>
        <Stack sx={{ width: 600 }} spacing={2}>
          <Typography variant="h2" gutterBottom>
            Add location details
          </Typography>

          <ValidatorForm
            className={firstForm}
            onSubmit={validateSettings}
            ref={formRef}
          >
            <Stack spacing={2}>
              <TextValidator
                label="location name"
                value={location.name || ""}
                variant="outlined"
                name="location"
                fullWidth
                validators={["required"]}
                errorMessages={["Name is required"]}
                onChange={(e: any) =>
                  setLocation(
                    produce(location, (draft) => {
                      draft.name = e.target.value;
                    })
                  )
                }
              />

              <TextValidator
                variant="outlined"
                fullWidth
                label="example URL"
                name="domain"
                value={location.urlMatch || ""}
                validators={["required", "isDomain"]}
                errorMessages={["URL is required", "Enter a valid URL"]}
                onChange={(e: any) =>
                  setLocation(
                    produce(location, (draft) => {
                      draft.urlMatch = e.target.value;
                    })
                  )
                }
              />

              <StyledFlexBox sx={{ width: 300 }}>
                <Box display="flex" alignItems="center">
                  <Switch
                    checked={location.showInAnalytics}
                    onChange={(e, checked) =>
                      setLocation(
                        produce(location, (draft) => {
                          draft.showInAnalytics = checked;
                        })
                      )
                    }
                  />
                  <Typography
                    color={location.showInAnalytics ? "primary" : "secondary"}
                  >
                    Show In Analytics
                  </Typography>
                </Box>
                <ClickableTooltip
                  placement="right"
                  text="Deselecting this option will mean that this location will not be groupable in Analytics"
                  icon={<StyledHelp />}
                />
              </StyledFlexBox>
              {oldUrl !== "" && (
                <Box mt={1}>
                  <Alert severity="warning">
                    We found an issue with this locations url ({oldUrl}) and
                    want to change it to ({newUrl}). If you are happy with this
                    change reclick the save button.
                  </Alert>
                </Box>
              )}
              <Stack
                mt={2}
                spacing={2}
                sx={{ width: "100%" }}
                alignItems="flex-end"
              >
                <Divider />

                {newUrl !== "" ? (
                  <>
                    <SavingButton
                      disableElevation
                      variant="contained"
                      color="primary"
                      saving={saving}
                      size="small"
                      onClick={newURLAndContinue}
                    >
                      yes I am happy - save and continue
                    </SavingButton>
                    <SavingButton
                      disableElevation
                      variant="contained"
                      color="secondary"
                      saving={saving}
                      size="small"
                      type="submit"
                    >
                      keep current - save and continue
                    </SavingButton>
                  </>
                ) : (
                  <SavingButton
                    disableElevation
                    variant="contained"
                    color="primary"
                    saving={saving}
                    size="small"
                    type="submit"
                  >
                    save and continue
                  </SavingButton>
                )}
              </Stack>
            </Stack>
          </ValidatorForm>
        </Stack>
      </CenterPage>
    </>
  );
};
