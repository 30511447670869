/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiLanderRequestPayloadToJSON, ApiListLandersResponseFromJSON, ApiReadLanderResponseFromJSON, } from '../models';
/**
 *
 */
export class LandersServiceApi extends runtime.BaseAPI {
    /**
     */
    landersServiceActivateLanderRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling landersServiceActivateLander.');
            }
            if (requestParameters.landerId === null || requestParameters.landerId === undefined) {
                throw new runtime.RequiredError('landerId', 'Required parameter requestParameters.landerId was null or undefined when calling landersServiceActivateLander.');
            }
            const queryParameters = {};
            if (requestParameters.payloadComponentId !== undefined) {
                queryParameters['payload.componentId'] = requestParameters.payloadComponentId;
            }
            if (requestParameters.payloadSubdomainId !== undefined) {
                queryParameters['payload.subdomainId'] = requestParameters.payloadSubdomainId;
            }
            if (requestParameters.payloadPath !== undefined) {
                queryParameters['payload.path'] = requestParameters.payloadPath;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadNoIndex !== undefined) {
                queryParameters['payload.noIndex'] = requestParameters.payloadNoIndex;
            }
            if (requestParameters.payloadCanonical !== undefined) {
                queryParameters['payload.canonical'] = requestParameters.payloadCanonical;
            }
            if (requestParameters.payloadDeferIncendiumjs !== undefined) {
                queryParameters['payload.deferIncendiumjs'] = requestParameters.payloadDeferIncendiumjs;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/path/v1/projects/{projectId}/landers/{landerId}/activate`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"landerId"}}`, encodeURIComponent(String(requestParameters.landerId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    landersServiceActivateLander(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.landersServiceActivateLanderRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    landersServiceCreateLanderRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling landersServiceCreateLander.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling landersServiceCreateLander.');
            }
            const queryParameters = {};
            if (requestParameters.landerId !== undefined) {
                queryParameters['landerId'] = requestParameters.landerId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/path/v1/projects/{projectId}/landers`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiLanderRequestPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadLanderResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    landersServiceCreateLander(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.landersServiceCreateLanderRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    landersServiceDeactivateLanderRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling landersServiceDeactivateLander.');
            }
            if (requestParameters.landerId === null || requestParameters.landerId === undefined) {
                throw new runtime.RequiredError('landerId', 'Required parameter requestParameters.landerId was null or undefined when calling landersServiceDeactivateLander.');
            }
            const queryParameters = {};
            if (requestParameters.payloadComponentId !== undefined) {
                queryParameters['payload.componentId'] = requestParameters.payloadComponentId;
            }
            if (requestParameters.payloadSubdomainId !== undefined) {
                queryParameters['payload.subdomainId'] = requestParameters.payloadSubdomainId;
            }
            if (requestParameters.payloadPath !== undefined) {
                queryParameters['payload.path'] = requestParameters.payloadPath;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadNoIndex !== undefined) {
                queryParameters['payload.noIndex'] = requestParameters.payloadNoIndex;
            }
            if (requestParameters.payloadCanonical !== undefined) {
                queryParameters['payload.canonical'] = requestParameters.payloadCanonical;
            }
            if (requestParameters.payloadDeferIncendiumjs !== undefined) {
                queryParameters['payload.deferIncendiumjs'] = requestParameters.payloadDeferIncendiumjs;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/path/v1/projects/{projectId}/landers/{landerId}/deactivate`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"landerId"}}`, encodeURIComponent(String(requestParameters.landerId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    landersServiceDeactivateLander(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.landersServiceDeactivateLanderRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    landersServiceDeleteLanderRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling landersServiceDeleteLander.');
            }
            if (requestParameters.landerId === null || requestParameters.landerId === undefined) {
                throw new runtime.RequiredError('landerId', 'Required parameter requestParameters.landerId was null or undefined when calling landersServiceDeleteLander.');
            }
            const queryParameters = {};
            if (requestParameters.payloadComponentId !== undefined) {
                queryParameters['payload.componentId'] = requestParameters.payloadComponentId;
            }
            if (requestParameters.payloadSubdomainId !== undefined) {
                queryParameters['payload.subdomainId'] = requestParameters.payloadSubdomainId;
            }
            if (requestParameters.payloadPath !== undefined) {
                queryParameters['payload.path'] = requestParameters.payloadPath;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadNoIndex !== undefined) {
                queryParameters['payload.noIndex'] = requestParameters.payloadNoIndex;
            }
            if (requestParameters.payloadCanonical !== undefined) {
                queryParameters['payload.canonical'] = requestParameters.payloadCanonical;
            }
            if (requestParameters.payloadDeferIncendiumjs !== undefined) {
                queryParameters['payload.deferIncendiumjs'] = requestParameters.payloadDeferIncendiumjs;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/path/v1/projects/{projectId}/landers/{landerId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"landerId"}}`, encodeURIComponent(String(requestParameters.landerId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    landersServiceDeleteLander(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.landersServiceDeleteLanderRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    landersServiceListLandersRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling landersServiceListLanders.');
            }
            const queryParameters = {};
            if (requestParameters.landerId !== undefined) {
                queryParameters['landerId'] = requestParameters.landerId;
            }
            if (requestParameters.payloadComponentId !== undefined) {
                queryParameters['payload.componentId'] = requestParameters.payloadComponentId;
            }
            if (requestParameters.payloadSubdomainId !== undefined) {
                queryParameters['payload.subdomainId'] = requestParameters.payloadSubdomainId;
            }
            if (requestParameters.payloadPath !== undefined) {
                queryParameters['payload.path'] = requestParameters.payloadPath;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadNoIndex !== undefined) {
                queryParameters['payload.noIndex'] = requestParameters.payloadNoIndex;
            }
            if (requestParameters.payloadCanonical !== undefined) {
                queryParameters['payload.canonical'] = requestParameters.payloadCanonical;
            }
            if (requestParameters.payloadDeferIncendiumjs !== undefined) {
                queryParameters['payload.deferIncendiumjs'] = requestParameters.payloadDeferIncendiumjs;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/path/v1/projects/{projectId}/landers`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListLandersResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    landersServiceListLanders(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.landersServiceListLandersRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    landersServiceReadLanderRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling landersServiceReadLander.');
            }
            if (requestParameters.landerId === null || requestParameters.landerId === undefined) {
                throw new runtime.RequiredError('landerId', 'Required parameter requestParameters.landerId was null or undefined when calling landersServiceReadLander.');
            }
            const queryParameters = {};
            if (requestParameters.payloadComponentId !== undefined) {
                queryParameters['payload.componentId'] = requestParameters.payloadComponentId;
            }
            if (requestParameters.payloadSubdomainId !== undefined) {
                queryParameters['payload.subdomainId'] = requestParameters.payloadSubdomainId;
            }
            if (requestParameters.payloadPath !== undefined) {
                queryParameters['payload.path'] = requestParameters.payloadPath;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadNoIndex !== undefined) {
                queryParameters['payload.noIndex'] = requestParameters.payloadNoIndex;
            }
            if (requestParameters.payloadCanonical !== undefined) {
                queryParameters['payload.canonical'] = requestParameters.payloadCanonical;
            }
            if (requestParameters.payloadDeferIncendiumjs !== undefined) {
                queryParameters['payload.deferIncendiumjs'] = requestParameters.payloadDeferIncendiumjs;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/path/v1/projects/{projectId}/landers/{landerId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"landerId"}}`, encodeURIComponent(String(requestParameters.landerId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadLanderResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    landersServiceReadLander(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.landersServiceReadLanderRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    landersServiceUpdateLanderRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling landersServiceUpdateLander.');
            }
            if (requestParameters.landerId === null || requestParameters.landerId === undefined) {
                throw new runtime.RequiredError('landerId', 'Required parameter requestParameters.landerId was null or undefined when calling landersServiceUpdateLander.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling landersServiceUpdateLander.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/path/v1/projects/{projectId}/landers/{landerId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"landerId"}}`, encodeURIComponent(String(requestParameters.landerId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiLanderRequestPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadLanderResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    landersServiceUpdateLander(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.landersServiceUpdateLanderRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
