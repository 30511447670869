import { ApiLocation, ApiProject } from "@incendium/api";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { locationService } from "Apis";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import { useLocations } from "Hooks";
import produce from "immer";
import { GenericDialoagProps } from "Interfaces";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

interface IEditLocationURLDialog extends GenericDialoagProps {
  url: string | undefined;
  setUrl: React.Dispatch<React.SetStateAction<string | undefined>>;
  location: ApiLocation;
  project: ApiProject;
}

function EditLocationURLDialog({
  open,
  url,
  setOpen,
  setUrl,
  location,
  project,
}: IEditLocationURLDialog) {
  const [tmpURL, setTmpURL] = useState(url);
  const { locations, setLocations } = useLocations();
  const { enqueueSnackbar } = useSnackbar();

  const onSet = () => {
    setUrl(tmpURL);
    setOpen(false);
  };

  const onSubmit = async () => {
    try {
      const loc = await locationService.locationServiceUpdateLocation({
        projectId: project?.id as number,
        locationId: location.id as number,
        payload: { location: { ...location, urlMatch: tmpURL } },
      });
      setLocations(
        produce(locations, (draft) => {
          const idx = locations.findIndex((l) => l.id === location.id);
          draft[idx] = loc.result || {};
        })
      );
      enqueueSnackbar("Location Updated", {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      onSet();
    } catch (error) {
      enqueueSnackbar("There was an internal error, please try again", {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      setOpen(false);
    }
  };

  return (
    <CustomDialog
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth="sm"
      open={open}
    >
      <ValidatorForm onSubmit={onSubmit}>
        <DialogTitle>Amend Location URL</DialogTitle>
        <DialogContent>
          <TextValidator
            variant="outlined"
            name="url"
            fullWidth
            label="New URL"
            value={tmpURL}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setTmpURL(e.currentTarget.value)
            }
            validators={["required", "isDomain"]}
            errorMessages={["URL is required", "Enter a valid URL"]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={onSet} color="primary">
            Set (tmp)
          </Button>
          <Button type="submit" color="primary">
            Set As new location example URL
          </Button>
        </DialogActions>
      </ValidatorForm>
    </CustomDialog>
  );
}

export default EditLocationURLDialog;
