import {
  ApiClient,
  ApiProject,
  ApiReportResponse,
  ApiTabChartResponse,
  ApiTabElementResponse,
  ApiTabResponse,
} from "@incendium/api";
import produce from "immer";
import { useCallback, useEffect, useState } from "react";
import { useMount, useUpdateEffect } from "react-use";
import ReportTabChartDrawer from "./ReportTabChartDrawer";
import ReportBuilder, { ILayout } from "./ReportBuilder";
import { useTabElements } from "Hooks/useTabElements";
import { ApiTabElementType } from "@incendium/api";
import Loading from "Components/Loading/Loading";

interface IReportTabProps {
  project: ApiProject;
  client: ApiClient;
  report: ApiReportResponse;
  tab: ApiTabResponse;
  editMode: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  activeTab: number; // only needed until we manage tab element state correctly
}

function ReportTab({
  project,
  client,
  report,
  tab,
  editMode,
  activeTab,
}: IReportTabProps) {
  const [showChartContext, setShowChartContext] = useState(false);
  const [selectedTabChart, setSelectedTabChart] = useState<
    ApiTabChartResponse | undefined
  >(undefined);

  const { tabElements, setTabElements, refetchTabElements, loading } =
    useTabElements(report.id as number, tab.id as number, project.id);
  const [layout, setLayout] = useState<ILayout[]>([]);

  useMount(() => {
    refetchTabElements();
  });

  useEffect(() => {
    if (!showChartContext) {
      setSelectedTabChart(undefined);
    }
  }, [showChartContext]);

  useUpdateEffect(() => {
    if (!selectedTabChart) {
      return;
    }
    setTabElements((tabElements) =>
      produce(tabElements, (draft) => {
        const idx = draft.findIndex((d) => d.id === selectedTabChart?.id);
        if (idx >= 0) {
          draft[idx] = selectedTabChart;
        }
      })
    );
  }, [selectedTabChart]);

  useUpdateEffect(() => {
    if (selectedTabChart) {
      return;
    }
    // update layout
    const mapFn = (e: ApiTabElementResponse): ILayout => {
      let i = `${e.id}`;
      if (e.type !== ApiTabElementType.BLOCK && e.chart?.updatedAt) {
        i = i + `${ApiTabElementType.BLOCK && e.chart?.updatedAt}`;
      }
      if (e.type !== ApiTabElementType.BLOCK && e.chart?.chart?.updatedAt) {
        i = i + `${ApiTabElementType.BLOCK && e.chart?.chart?.updatedAt}`;
      }

      return {
        i,
        id: e.id,
        x: e.x as number,
        y: e.y as number,
        h: e.h as number,
        w: e.w as number,
        element: e,
        type: e.type,
        name: e.name,
        description: e.description,
        inner: (e.elements || []).map(mapFn),
      };
    };

    setLayout((tabElements || []).map(mapFn));
  }, [tabElements]);

  const editChartContext = useCallback((element: ApiTabElementResponse) => {
    setShowChartContext(true);
    setSelectedTabChart(element.chart);
  }, []);

  if (loading) {
    return <Loading fullHeight />;
  }

  return (
    <>
      <ReportBuilder
        layout={layout}
        setLayout={setLayout}
        project={project}
        client={client}
        report={report}
        tab={tab}
        editElement={editChartContext}
        editMode={editMode}
      />

      <ReportTabChartDrawer
        open={showChartContext}
        setOpen={setShowChartContext}
        report={report}
        project={project}
        tab={tab}
        tabChart={selectedTabChart}
        reload={refetchTabElements}
      />
    </>
  );
}

export default ReportTab;
