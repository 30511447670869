export const enumToArray = <T extends any>(enumData: T) => {
  const arr: T[keyof T][] = [];
  for (const val in enumData) {
    if (val.toLowerCase().includes("unassigned")) {
      continue;
    }
    arr.push(enumData[val]);
  }
  return arr;
};

const knownAcronyms = ["ctr", "roas", "roi", "ltv", "cac", "cpnv", "aov"];

export const convertMapFn = (s: string) => {
  s = knownAcronyms.includes(s.toLowerCase()) ? s.toUpperCase() : s;
  switch (s.toLowerCase()) {
    case "ctr":
      return "Click Through Rate";

    default:
      return s;
  }
};

export const formatEnumVal = (x: string) => {
  const s = (x || "").toLowerCase().split("_").map(convertMapFn).join(" ");
  return s.charAt(0).toUpperCase() + s.slice(1);
};
