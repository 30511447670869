import Image from "mui-image";
import Google_Ads_logo from "Assets/Images/ThirdParty/Google_Ads_logo.png";
import Bing from "Assets/Images/ThirdParty/bing.png";
import Facebook from "Assets/Images/ThirdParty/facebook.png";
import Instagram from "Assets/Images/ThirdParty/instagram.png";
import Twitter from "Assets/Images/ThirdParty/twitter.png";
import Reddit from "Assets/Images/ThirdParty/reddit.png";
import Linkdin from "Assets/Images/ThirdParty/linkedin.png";
import Unknown from "Assets/Images/ThirdParty/unknown.png";
import Meta from "Assets/Images/ThirdParty/meta.png";
import { motion } from "framer-motion";
import { useMemo } from "react";
import { Box } from "@mui/material";

interface IProviderIconProps {
  provider: string;
}

function ProviderIcon({ provider }: IProviderIconProps) {
  const logo = useMemo(() => {
    switch (provider.toLowerCase()) {
      case "google":
        return Google_Ads_logo;
      case "bing":
        return Bing;
      case "facebook":
        return Facebook;
      case "instagram":
        return Instagram;
      case "x":
      case "twiiter":
        return Twitter;
      case "reddit":
        return Reddit;
      case "linkedin":
        return Linkdin;
      case "audience_network":
        return Meta;

      default:
        return Unknown;
    }
  }, [provider]);

  return (
    <Box
      sx={{ width: 20 }}
      component={motion.div}
      initial={{
        opacity: 0,
        scale: 0,
      }}
      animate={{
        opacity: 1,
        scale: 1,
      }}
    >
      <Image
        height={20}
        src={logo}
        duration={50}
        fit={"contain"}
        objectPosition={"left"}
        sx={{ objectPosition: "left" }}
      />
    </Box>
  );
}

export default ProviderIcon;
