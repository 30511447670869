import {
  Box,
  Divider,
  Fab,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import produce from "immer";
import { ReactNode, useState, useCallback, useMemo, useEffect } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Graticule,
  Marker,
  Point,
  Sphere,
  ZoomableGroup,
} from "react-simple-maps";
import { scaleSqrt } from "d3-scale";
import { ZoomIn, ZoomInMap, ZoomOut, ZoomOutMap } from "@mui/icons-material";
import { TChartData } from "Interfaces";
import { groupCoordinatesByDistance } from "Helpers/geo";
import { ApiDimension, ApiMetric } from "@incendium/api";
import {
  formatMetric,
  metricToName,
  getChartColor,
  isAverageMetric,
  isPercentageMetric,
} from "features/analytics/";
import GeoMapJson from "../assets/mapFeatures.json";

interface IPosition {
  coordinates: Point;
  zoom: number;
}

interface IAnalyticsGeoMapProps {
  data: TChartData[];
  metric: ApiMetric;
}

function AnalyticsGeoMap({ data, metric }: IAnalyticsGeoMapProps) {
  const theme = useTheme();
  const [tooltipContent, setTooltipContent] = useState<ReactNode>("");
  const [position, setPosition] = useState<IPosition>({
    coordinates: [0, 0],
    zoom: 1,
  });

  const chartData = useMemo(() => {
    const tt = (data || []).map((d) => ({
      ...d,
      lonLat: d.name?.split(",") as unknown,
      lon: Number(d.name?.split(",")[0]),
      lat: Number(d.name?.split(",")[1]),
    }));

    const grouped = groupCoordinatesByDistance(tt, 350);

    return grouped
      .map((g) => {
        // value can be sum, percentage or average
        let value = g.coords.reduce((acc, cur) => acc + cur[metric], 0);
        if (isAverageMetric(metric) || isPercentageMetric(metric)) {
          value = value / g.coords.length;
        }
        return {
          ...g.center,
          name: g.center.name,
          country: g.center[ApiDimension.DIMENSION_COUNTRY],
          city: g.center[ApiDimension.DIMENSION_CITY],
          lonLat: g.center.lonLat,
          value,
        };
      })
      .sort((a, b) => b.value - a.value);
  }, [data, metric]);

  const handleZoomIn = useCallback(() => {
    if (position.zoom >= 5) return;
    setPosition(
      produce(position, (draft) => {
        draft.zoom = draft.zoom * 1.2;
      })
    );
  }, [position]);

  const handleZoomOut = useCallback(() => {
    setPosition(
      produce(position, (draft) => {
        draft.zoom = draft.zoom / 1.2;
      })
    );
  }, [position]);

  const handleReCenter = useCallback(() => {
    if (position.zoom <= 1) return;
    setPosition(
      produce(position, (draft) => {
        draft.coordinates = [0, 0];
        draft.zoom = 1;
      })
    );
  }, [position]);

  const handleCenterAroundBiggest = useCallback(() => {
    setPosition(
      produce(position, (draft) => {
        draft.coordinates = [chartData[0].lon, chartData[0].lat];
        draft.zoom = 3;
      })
    );
  }, [position, chartData]);

  const handleMoveEnd = useCallback((position: IPosition) => {
    setPosition(position);
  }, []);

  const maxValue = useMemo(() => {
    return Math.max(...chartData.map((o) => o.value || 0));
  }, [chartData]);

  const popScale = useMemo(() => {
    return scaleSqrt().domain([0, maxValue]).range([1.5, 18]);
  }, [maxValue]);

  useEffect(() => {
    if (!chartData || chartData.length === 0) {
      return;
    }

    handleCenterAroundBiggest();
  }, [chartData]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        // background: "pink",
      }}
    >
      <Stack
        spacing={2}
        sx={{
          position: "absolute",
          right: -10,
        }}
      >
        <Fab color="secondary" onClick={handleZoomIn} size="small">
          <ZoomIn />
        </Fab>
        <Fab color="secondary" onClick={handleZoomOut} size="small">
          <ZoomOut />
        </Fab>
        <Fab color="secondary" onClick={handleReCenter} size="small">
          <ZoomOutMap />
        </Fab>
        <Fab color="secondary" onClick={handleCenterAroundBiggest} size="small">
          <ZoomInMap />
        </Fab>
      </Stack>

      <Tooltip
        title={tooltipContent}
        followCursor
        // placeholder="left-start"
        hidden={tooltipContent !== ""}
        arrow
      >
        <ComposableMap
          // projectionConfig={{ rotate: [-10, 0, 0] }}
          style={{ width: "100%", height: "100%" }}
        >
          <ZoomableGroup
            zoom={position.zoom}
            center={position.coordinates}
            onMoveEnd={handleMoveEnd}
          >
            <Sphere
              id="rsm-sphere"
              fill={"transparent"}
              stroke="#E4E5E6"
              strokeWidth={0.5}
            />
            <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
            <Geographies geography={GeoMapJson}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={theme.palette.primary.dark}
                    style={{
                      default: { outline: "none" },
                      hover: { outline: "none" },
                      pressed: { outline: "none" },
                    }}
                  />
                ))
              }
            </Geographies>
            {chartData.map((d, i) => {
              return (
                <Marker
                  key={d.name}
                  coordinates={d.lonLat as Point}
                  onMouseEnter={() => {
                    setTooltipContent(
                      <Box>
                        <Typography variant="subtitle2" color={"inherit"}>
                          {d.country}
                        </Typography>
                        <Typography variant="body2" color={"inherit"}>
                          {d.city}
                        </Typography>
                        <Typography variant="body2" color={"inherit"}>
                          ({d.name})
                        </Typography>
                        <Box my={0.5}>
                          <Divider sx={{ backgroundColor: "white" }} />
                        </Box>

                        <Typography variant="subtitle2" color={"inherit"}>
                          {metricToName(metric)} :{" "}
                          {formatMetric(metric, d.value)}
                        </Typography>
                      </Box>
                    );
                  }}
                  onMouseLeave={() => {
                    setTooltipContent("");
                  }}
                >
                  <circle
                    fill={getChartColor(i, theme.palette.charts)}
                    stroke="#FFF"
                    strokeWidth={0.3}
                    r={popScale(d.value || 0)}
                  />
                </Marker>
              );
            })}
          </ZoomableGroup>
        </ComposableMap>
      </Tooltip>
    </Box>
  );
}

export default AnalyticsGeoMap;
