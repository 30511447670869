import { useSharedState } from "./useSharedState";
import {
  ApiChartAttribute,
  ApiChartResponse,
  ApiChartYAxisKey,
  ApiProject,
  ApiChartDisplayOptions,
  ApiTabChartResponse,
  ApiChartHaving,
} from "@incendium/api";
import { chartService } from "Apis";
import { IChart } from "Interfaces";
import { useCallback } from "react";
import produce from "immer";
import { parseChartAttributeToPrimaryFilters } from "Helpers/analytics";
import { saveChart } from "features/chartLibrary";

export const parseChartFromResponse = (res: ApiChartResponse): IChart => {
  // fe chart interaface and generated interface slightly diverge, we handle this here
  return {
    ...res,
    yAxisKeys: (res.yAxisKeys || []).map((v: ApiChartYAxisKey) => v),
    attributes: parseChartAttributeToPrimaryFilters(res.attributes || []),
  };
};

export const parseContextChartFromTabChart = (
  tabChart: ApiTabChartResponse
) => {
  const chart = { ...tabChart.chart };
  for (let i = 0; i < (tabChart.overridingFields || []).length; i++) {
    let field = (tabChart?.overridingFields || [])[i];
    if (!tabChart?.overridingChart?.hasOwnProperty(field)) {
      continue;
    }
    const value = tabChart?.overridingChart[field as keyof ApiChartResponse];
    if (value && chart[field as keyof ApiChartResponse]) {
      // :( have to ad this casting below as ts keeps erroring saying chart[field as keyof ApiChartResponse] can be undfined, even though we check that above
      (chart[field as keyof ApiChartResponse] as
        | string
        | Date
        | number
        | boolean
        | string[]
        | ApiChartAttribute[]
        | ApiChartDisplayOptions
        | ApiChartHaving[]
        | boolean
        | ApiChartYAxisKey[]) = value;
    }
  }

  return parseChartFromResponse(chart);
};

const fetchCharts = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const charts = await chartService.chartServiceListCharts({
      projectId: selectedProject.id as number,
    });
    return (charts.results || []).map(parseChartFromResponse);
  }
  return [];
};
export const useCharts = () => {
  const { state, setState, refetch, loading } = useSharedState(
    "charts",
    [],
    fetchCharts
  );

  const saveNewChart = useCallback(
    async (chart: IChart, selectedProject: ApiProject | null) => {
      const res = await saveChart(chart, selectedProject?.id as number);

      const parsed = parseChartFromResponse(res);
      setState(
        produce(state as IChart[], (draft) => {
          draft.push(parsed);
        })
      );
      return res;
    },
    [state, setState]
  );

  const updateChart = useCallback(
    async (chart: IChart, selectedProject: ApiProject | null) => {
      const res = await chartService.chartServiceUpdateChart({
        projectId: selectedProject?.id as number,
        chartId: chart.id as number,
        payload: {
          ...chart,
          yAxisKeys: (chart.yAxisKeys || []).filter(
            (v) => typeof v !== "string"
          ) as ApiChartYAxisKey[], // filter should not be needed but here yo guarantee type
        },
      });

      const parsed = parseChartFromResponse(res);
      setState(
        produce(state as IChart[], (draft) => {
          const idx = draft.findIndex((s) => s.id === parsed.id);
          if (idx >= 0) {
            draft[idx] = parsed;
          }
        })
      );
      return parsed;
    },
    [state, setState]
  );

  return {
    charts: state as IChart[],
    setCharts: setState,
    refetch,
    saveNewChart,
    updateChart,
    loading,
  };
};
