import { ApiConversionState, ApiConversionStateTrigger } from "@incendium/api";
import { IconButton } from "@mui/material";
import { AddCircle, Delete, Edit } from "@mui/icons-material";
import { Tag } from "Components/Tag";
import React from "react";

export const TriggerList = ({
  tags,
  onAdd,
  onEdit,
  onDelete,
  title,
}: {
  title: string;
  tags: ApiConversionState[];
  onAdd: () => void;
  onEdit: (tag: ApiConversionState) => void;
  onDelete: (tag: ApiConversionState) => void;
}) => {
  const renderSwitch = (tag: ApiConversionState) => {
    switch (tag.trigger) {
      case ApiConversionStateTrigger.CLICK:
        return (
          <Tag
            text={`click - ${tag.target || tag.targetInnerText}`}
            color="secondary"
            icons={
              <>
                <IconButton onClick={() => onEdit(tag)} size="small">
                  <Edit color="primary" />
                </IconButton>
                <IconButton onClick={() => onDelete(tag)} size="small">
                  <Delete color="secondary" />
                </IconButton>
              </>
            }
          />
        );
      case ApiConversionStateTrigger.SUBMIT:
        return (
          <Tag
            text={`submit - ${tag.target}`}
            color="secondary"
            icons={
              <>
                <IconButton onClick={() => onEdit(tag)} size="small">
                  <Edit color="primary" />
                </IconButton>
                <IconButton onClick={() => onDelete(tag)} size="small">
                  <Delete color="secondary" />
                </IconButton>
              </>
            }
          />
        );
      case ApiConversionStateTrigger.MANUAL:
        return (
          <Tag
            text={`manual - ${tag.target}`}
            color="secondary"
            icons={
              <>
                <IconButton onClick={() => onEdit(tag)} size="small">
                  <Edit color="primary" />
                </IconButton>
                <IconButton onClick={() => onDelete(tag)} size="small">
                  <Delete color="secondary" />
                </IconButton>
              </>
            }
          />
        );
      case ApiConversionStateTrigger.PAGE_VIEW:
        return (
          <Tag
            text={`time on page - ${tag.value} seconds`}
            color="secondary"
            icons={
              <>
                <IconButton onClick={() => onEdit(tag)} size="small">
                  <Edit color="primary" />
                </IconButton>
                <IconButton onClick={() => onDelete(tag)} size="small">
                  <Delete color="secondary" />
                </IconButton>
              </>
            }
          />
        );
      case ApiConversionStateTrigger.ONSITE_PAGEVIEWS:
        return (
          <Tag
            text={`onsite-session - ${tag.target}`}
            color="secondary"
            icons={
              <>
                <IconButton onClick={() => onEdit(tag)} size="small">
                  <Edit color="primary" />
                </IconButton>
                <IconButton onClick={() => onDelete(tag)} size="small">
                  <Delete color="secondary" />
                </IconButton>
              </>
            }
          />
        );
      case ApiConversionStateTrigger.TIME_ON_ELEMENT:
        return (
          <Tag
            text={`time-on-element - ${tag.target} - ${tag.value} seconds`}
            color="secondary"
            icons={
              <>
                <IconButton onClick={() => onEdit(tag)} size="small">
                  <Edit color="primary" />
                </IconButton>
                <IconButton onClick={() => onDelete(tag)} size="small">
                  <Delete color="secondary" />
                </IconButton>
              </>
            }
          />
        );
    }
  };

  return (
    <>
      <Tag
        text={title}
        color="primary"
        icons={
          <>
            <IconButton onClick={onAdd} size="small">
              <AddCircle color="primary" />
            </IconButton>
          </>
        }
      />
      {tags.map((tag, i) => {
        return <React.Fragment key={i}>{renderSwitch(tag)}</React.Fragment>;
      })}
    </>
  );
};
