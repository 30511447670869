/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiReservedPageviewMeta;
(function (ApiReservedPageviewMeta) {
    ApiReservedPageviewMeta["RESERVERED_UNSPECIFIED"] = "PAGEVIEW_META_RESERVERED_UNSPECIFIED";
    ApiReservedPageviewMeta["RESERVERED_OUT_OF_STOCK"] = "PAGEVIEW_META_RESERVERED_OUT_OF_STOCK";
    ApiReservedPageviewMeta["RESERVED_ORDER_ID"] = "PAGEVIEW_META_RESERVED_ORDER_ID";
})(ApiReservedPageviewMeta || (ApiReservedPageviewMeta = {}));
export function ApiReservedPageviewMetaFromJSON(json) {
    return ApiReservedPageviewMetaFromJSONTyped(json, false);
}
export function ApiReservedPageviewMetaFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiReservedPageviewMetaToJSON(value) {
    return value;
}
