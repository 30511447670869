/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiCreateOrFetchLeadKeyPayloadResponseFromJSON, ApiLeadInteractionPayloadToJSON, ApiLeadMetaKeyValueResponseFromJSON, ApiLeadStatusPayloadToJSON, InlineObject2ToJSON, } from '../models';
/**
 *
 */
export class LeadServiceApi extends runtime.BaseAPI {
    /**
     */
    leadServiceAppendLeadStatusRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectKey === null || requestParameters.projectKey === undefined) {
                throw new runtime.RequiredError('projectKey', 'Required parameter requestParameters.projectKey was null or undefined when calling leadServiceAppendLeadStatus.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling leadServiceAppendLeadStatus.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/lead/v1/projects/{projectKey}/leads:append-status`.replace(`{${"projectKey"}}`, encodeURIComponent(String(requestParameters.projectKey))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiLeadStatusPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    leadServiceAppendLeadStatus(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.leadServiceAppendLeadStatusRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    leadServiceAppendLeadStatusInteractionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectKey === null || requestParameters.projectKey === undefined) {
                throw new runtime.RequiredError('projectKey', 'Required parameter requestParameters.projectKey was null or undefined when calling leadServiceAppendLeadStatusInteraction.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling leadServiceAppendLeadStatusInteraction.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/lead/v1/projects/{projectKey}/leads:append-status-interaction`.replace(`{${"projectKey"}}`, encodeURIComponent(String(requestParameters.projectKey))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiLeadInteractionPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    leadServiceAppendLeadStatusInteraction(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.leadServiceAppendLeadStatusInteractionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * method to create a new or return existing lead, only returns lead key
     */
    leadServiceCreateOrFetchLeadKeyRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectKey === null || requestParameters.projectKey === undefined) {
                throw new runtime.RequiredError('projectKey', 'Required parameter requestParameters.projectKey was null or undefined when calling leadServiceCreateOrFetchLeadKey.');
            }
            if (requestParameters.body === null || requestParameters.body === undefined) {
                throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling leadServiceCreateOrFetchLeadKey.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/lead/v1/projects/{projectKey}/leads:create-or-fetch-key`.replace(`{${"projectKey"}}`, encodeURIComponent(String(requestParameters.projectKey))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: InlineObject2ToJSON(requestParameters.body),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiCreateOrFetchLeadKeyPayloadResponseFromJSON(jsonValue));
        });
    }
    /**
     * method to create a new or return existing lead, only returns lead key
     */
    leadServiceCreateOrFetchLeadKey(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.leadServiceCreateOrFetchLeadKeyRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * return all unique lead meta keys and all values
     */
    leadServiceListProjectLeadMetaKeyValuesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling leadServiceListProjectLeadMetaKeyValues.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/lead/v1/projects/{projectId}/leads:meta-key-value`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiLeadMetaKeyValueResponseFromJSON(jsonValue));
        });
    }
    /**
     * return all unique lead meta keys and all values
     */
    leadServiceListProjectLeadMetaKeyValues(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.leadServiceListProjectLeadMetaKeyValuesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
