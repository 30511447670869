import { CircularProgress } from "@mui/material";
import { IStoreState } from "Interfaces";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, RouteProps, useHistory } from "react-router-dom";

export const ProtectedRoute = (props: RouteProps) => {
  const tokens = useSelector((state: IStoreState) => state.auth.tokens);
  const status = useSelector((state: IStoreState) => state.auth.status);
  const history = useHistory();

  useEffect(() => {
    if (history) {
      if (!tokens) {
        history.push("/login", { from: window.location.pathname });
      }
    }
  }, [history, tokens, status]);

  return tokens ? <Route {...props} /> : <CircularProgress />;
};
