/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiCalcOverridePreferenceFromJSON, ApiCalcOverridePreferenceToJSON, } from './ApiCalcOverridePreference';
import { ApiClassificationGroupFromJSON, ApiClassificationGroupToJSON, } from './ApiClassificationGroup';
import { ApiClassificationItemFromJSON, ApiClassificationItemToJSON, } from './ApiClassificationItem';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
import { ApiStatusFromJSON, ApiStatusToJSON, } from './ApiStatus';
export function ApiClassificationFromJSON(json) {
    return ApiClassificationFromJSONTyped(json, false);
}
export function ApiClassificationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'status': !exists(json, 'status') ? undefined : ApiStatusFromJSON(json['status']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'googleId': !exists(json, 'googleId') ? undefined : json['googleId'],
        'classificationItems': !exists(json, 'classificationItems') ? undefined : (json['classificationItems'].map(ApiClassificationItemFromJSON)),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
        'pushedToGoogleAnalytics': !exists(json, 'pushedToGoogleAnalytics') ? undefined : json['pushedToGoogleAnalytics'],
        'googleCustomDimensionId': !exists(json, 'googleCustomDimensionId') ? undefined : json['googleCustomDimensionId'],
        'calcOverridePrefernce': !exists(json, 'calcOverridePrefernce') ? undefined : ApiCalcOverridePreferenceFromJSON(json['calcOverridePrefernce']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'group': !exists(json, 'group') ? undefined : ApiClassificationGroupFromJSON(json['group']),
        'classificationGroupId': !exists(json, 'classificationGroupId') ? undefined : json['classificationGroupId'],
    };
}
export function ApiClassificationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'project': ApiProjectToJSON(value.project),
        'name': value.name,
        'alias': value.alias,
        'description': value.description,
        'key': value.key,
        'status': ApiStatusToJSON(value.status),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'googleId': value.googleId,
        'classificationItems': value.classificationItems === undefined ? undefined : (value.classificationItems.map(ApiClassificationItemToJSON)),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'pushedToGoogleAnalytics': value.pushedToGoogleAnalytics,
        'googleCustomDimensionId': value.googleCustomDimensionId,
        'calcOverridePrefernce': ApiCalcOverridePreferenceToJSON(value.calcOverridePrefernce),
        'projectId': value.projectId,
        'group': ApiClassificationGroupToJSON(value.group),
        'classificationGroupId': value.classificationGroupId,
    };
}
