/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiClassificationRulePayloadFromJSON, ApiClassificationRulePayloadToJSON, } from './ApiClassificationRulePayload';
import { ApiOperatorFromJSON, ApiOperatorToJSON, } from './ApiOperator';
export function ApiAudienceConditionPayloadFromJSON(json) {
    return ApiAudienceConditionPayloadFromJSONTyped(json, false);
}
export function ApiAudienceConditionPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'classificationId': !exists(json, 'classificationId') ? undefined : json['classificationId'],
        'classificationItemId': !exists(json, 'classificationItemId') ? undefined : json['classificationItemId'],
        'operator': !exists(json, 'operator') ? undefined : ApiOperatorFromJSON(json['operator']),
        'classificationRule': !exists(json, 'classificationRule') ? undefined : ApiClassificationRulePayloadFromJSON(json['classificationRule']),
    };
}
export function ApiAudienceConditionPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'classificationId': value.classificationId,
        'classificationItemId': value.classificationItemId,
        'operator': ApiOperatorToJSON(value.operator),
        'classificationRule': ApiClassificationRulePayloadToJSON(value.classificationRule),
    };
}
