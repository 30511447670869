/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiLeadConversionStatus;
(function (ApiLeadConversionStatus) {
    ApiLeadConversionStatus["UNSPECIFIED"] = "LEAD_CONVERSION_STATUS_UNSPECIFIED";
    ApiLeadConversionStatus["FB_PUSHED"] = "LEAD_CONVERSION_STATUS_FB_PUSHED";
})(ApiLeadConversionStatus || (ApiLeadConversionStatus = {}));
export function ApiLeadConversionStatusFromJSON(json) {
    return ApiLeadConversionStatusFromJSONTyped(json, false);
}
export function ApiLeadConversionStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiLeadConversionStatusToJSON(value) {
    return value;
}
