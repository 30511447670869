import { Box } from "@mui/material";
import StyledDrawer from "Components/UI/StyledDrawer";
import React, { useMemo } from "react";

interface IWithInfoColumnProps {
  infoColumn: React.ReactNode;
  dense?: boolean;
}

const withInfoColumn = <P extends object>(Comp: React.ComponentType<P>) => {
  return (props: P & IWithInfoColumnProps) => {
    const { infoColumn, dense, ...restProps } = props;
    const infoWidth = useMemo(() => (dense ? 450 : 550), [dense]);

    return (
      <Box sx={{ display: "flex", height: "calc(100vh - 152px)" }}>
        <Box
          sx={{
            width: `calc(100% - ${infoWidth}px)`,
            overflowY: "auto",
          }}
        >
          <Comp {...(restProps as P)} />
        </Box>

        <StyledDrawer
          variant="permanent"
          anchor="right"
          maxWidth={infoWidth}
          sx={{
            width: infoWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: infoWidth,
              minWidth: infoWidth,
              boxSizing: "border-box",
              background: "transparent",
              borderWidth: 2,
              borderRadius: 0,
            },
          }}
        >
          {infoColumn}
        </StyledDrawer>
      </Box>
    );
  };
};

export default withInfoColumn;
