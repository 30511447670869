import { Typography } from "@mui/material";
import { useSelectedProject } from "Hooks";
import { useEffect, useMemo } from "react";
import ChartBuilderAttributes from "./ChartBuilderAttributes";
import ChartBuilderName from "./ChartBuilderName";
import ChartBuilderOrderLimit from "./ChartBuilderOrderLimit";
import {
  ChartBuilderSidebarBlock,
  ChartBuilderSidebarContainer,
} from "./ChartBuilderSidebarBlock";
import ChartBuilderYAxis from "./ChartBuilderYAxis";
import { useReportBuilderContext } from "Providers/ReportBuilderProvider";
import produce from "immer";
import { ApiChartAttribute, ApiDimension, ApiMetric } from "@incendium/api";

function ChartBuilderMap() {
  const { selectedProject } = useSelectedProject();
  const { chart, setChart } = useReportBuilderContext();

  const availableMetrics = useMemo(() => {
    return [
      {
        title: "Traffic & pageview counts",
        metrics: [
          ApiMetric.METRIC_SESSIONS_COUNT,
          ApiMetric.METRIC_SESSION_COUNT_PER_LEAD,
          ApiMetric.METRIC_NEW_SESSION_COUNT,
          ApiMetric.METRIC_RETURNING_SESSION_COUNT,
          ApiMetric.METRIC_SESSION_PAGEVIEW_COUNT,
          ApiMetric.METRIC_LEADS_COUNT,
          ApiMetric.METRIC_NEW_LEADS_COUNT,
          ApiMetric.METRIC_NEW_CUSTOMER_COUNT,
          ApiMetric.METRIC_RETURNING_LEADS_COUNT,
          ApiMetric.METRIC_RETURNING_LEAD_PERCENTAGE,
          ApiMetric.METRIC_RETURNING_NINETY_DAY_CUSTOMER_COUNT,
          ApiMetric.METRIC_RETURNING_NINETY_DAY_CUSTOMER_PERCENTAGE,
          ApiMetric.METRIC_PAGE_VIEWS_COUNT,
          ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
          ApiMetric.METRIC_LANDING_SESSION_COUNT,
          ApiMetric.METRIC_OUT_OF_STOCK_PAGEVIEW_COUNT,
          ApiMetric.METRIC_OUT_OF_STOCK_PAGES_COUNT,
        ],
      },
      {
        title: "Session engagement",
        metrics: [
          ApiMetric.METRIC_AVERAGE_PAGEVIEWS_PER_SESSION,
          ApiMetric.METRIC_AVERAGE_SESSION_DURATION,
          ApiMetric.METRIC_AVERAGE_DAYS_TO_SECOND_VISIT,
          ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT,
          ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD,
          ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
          ApiMetric.METRIC_EFFECTIVE_SESSION_TO_CONVERSION_RATE,
          ApiMetric.METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_COUNT_DURING_SESSION,
          ApiMetric.METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_RATE_DURING_SESSION,
          ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION,
          ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE_DURING_SESSION,
          ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE_DURING_SESSION,
          ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE_DURING_SESSION,
          ApiMetric.METRIC_SESSION_REVENUE,
          ApiMetric.METRIC_PRODUCT_REVENUE,
          ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_FIRST_VISIT_SESSION_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL,
          ApiMetric.METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL,
          ApiMetric.METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL,
          ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT,
          ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
          ApiMetric.METRIC_SESSION_MICRO_CONVERSION_RATE,
          ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE,
        ],
      },
      {
        title: "Page engagement",
        metrics: [
          ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_COUNT,
          ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
          ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
          ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE,
          ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE,
          ApiMetric.METRIC_AVERAGE_SCROLL_SPEED,
          ApiMetric.METRIC_PAGE_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_FIRST_VISIT_PAGE_MACRO_CONVERSION_COUNT,
          ApiMetric.METRI_THIRTY_DAY_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_THIRTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL,
          ApiMetric.METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL,
          ApiMetric.METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL,
          ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
          ApiMetric.METRIC_PAGE_MACRO_CONVERSION_RATE,
          ApiMetric.METRIC_PAGE_MICRO_CONVERSION_RATE,
          ApiMetric.METRIC_LEAD_MACRO_CONVERSION_PERCENTAGE,
          ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE_RANK,
          ApiMetric.METRICEFFECTIVE_PAGEVIEW_TO_CONVERSION_RATE,
        ],
      },
      {
        title: "Ecommerce",
        metrics: [
          ApiMetric.METRIC_SPEND,
          ApiMetric.METRIC_CPA,
          ApiMetric.METRIC_CAC,
          ApiMetric.METRIC_ECR,
          ApiMetric.METRIC_CPNV,
          // "linear_predicted_day30_ltv_revenue",
          // "linear_predicted_day60_ltv_revenue",
          // "linear_predicted_day90_ltv_revenue",
        ],
      },
    ];
  }, []);

  useEffect(() => {
    setChart((oldChart) =>
      produce(oldChart, (draft) => {
        draft.dimension = [
          ApiDimension.DIMENSION_LON_LAT,
          ApiDimension.DIMENSION_COUNTRY,
          ApiDimension.DIMENSION_CITY,
        ];
      })
    );
  }, []);

  return (
    <ChartBuilderSidebarContainer>
      <ChartBuilderSidebarBlock>
        <Typography variant="h3">Map Setup!</Typography>
      </ChartBuilderSidebarBlock>
      <ChartBuilderName />

      <ChartBuilderYAxis
        availableMetrics={availableMetrics}
        noControls
        noRemove
        noDrag
        y={"l"}
        title="Map Metric"
        subTitle="This is the value the data on your map to display"
      />
      <ChartBuilderOrderLimit
        title="Limit (See description)"
        subTitle="This limits the number of results returned, results are then grouped by nearist 300 km, this means that if you set a limit of 5 it does not mean you will have 5 points on the map."
        hideOrder
      />
      {selectedProject && (
        <ChartBuilderAttributes
          attributes={chart.attributes}
          setAttributes={(attributes) => {
            setChart((chart) =>
              produce(chart, (draft) => {
                draft.attributes = attributes as ApiChartAttribute[];
              })
            );
          }}
        />
      )}
    </ChartBuilderSidebarContainer>
  );
}

export default ChartBuilderMap;
