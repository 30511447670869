/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiUserActionConversionFromJSON, ApiUserActionConversionToJSON, } from './ApiUserActionConversion';
export function ApiUserActionPageViewFromJSON(json) {
    return ApiUserActionPageViewFromJSONTyped(json, false);
}
export function ApiUserActionPageViewFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'sessionId': !exists(json, 'sessionId') ? undefined : json['sessionId'],
        'conversions': !exists(json, 'conversions') ? undefined : (json['conversions'].map(ApiUserActionConversionFromJSON)),
        'averageScrollSpeed': !exists(json, 'averageScrollSpeed') ? undefined : json['averageScrollSpeed'],
        'timeOnPageSeconds': !exists(json, 'timeOnPageSeconds') ? undefined : json['timeOnPageSeconds'],
        'scrolledPercentage': !exists(json, 'scrolledPercentage') ? undefined : json['scrolledPercentage'],
        'viewedPercentage': !exists(json, 'viewedPercentage') ? undefined : json['viewedPercentage'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'isEffective': !exists(json, 'isEffective') ? undefined : json['isEffective'],
        'isSessionEffective': !exists(json, 'isSessionEffective') ? undefined : json['isSessionEffective'],
        'revenue': !exists(json, 'revenue') ? undefined : json['revenue'],
        'purchasedProductNames': !exists(json, 'purchasedProductNames') ? undefined : json['purchasedProductNames'],
        'locations': !exists(json, 'locations') ? undefined : json['locations'],
        'sessionAudiences': !exists(json, 'sessionAudiences') ? undefined : json['sessionAudiences'],
        'displayPage': !exists(json, 'displayPage') ? undefined : json['displayPage'],
    };
}
export function ApiUserActionPageViewToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sessionId': value.sessionId,
        'conversions': value.conversions === undefined ? undefined : (value.conversions.map(ApiUserActionConversionToJSON)),
        'averageScrollSpeed': value.averageScrollSpeed,
        'timeOnPageSeconds': value.timeOnPageSeconds,
        'scrolledPercentage': value.scrolledPercentage,
        'viewedPercentage': value.viewedPercentage,
        'source': value.source,
        'isEffective': value.isEffective,
        'isSessionEffective': value.isSessionEffective,
        'revenue': value.revenue,
        'purchasedProductNames': value.purchasedProductNames,
        'locations': value.locations,
        'sessionAudiences': value.sessionAudiences,
        'displayPage': value.displayPage,
    };
}
