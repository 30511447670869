import { ApiAlert } from "@incendium/api";
import { Box, CircularProgress, Portal } from "@mui/material";
import { alertService } from "Apis";
import AlertForm from "Components/Alerts/AlertForm";
import ConditionGroupProvider from "Components/ConditionalGroup/ConditionGroupProvider";
import { TypographyHelp } from "Components/UI/TypographyHelp";
import { useProjectSelector } from "Hooks";
import { useConversions } from "Hooks/useConversions";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useMount } from "react-use";

function AlertPage() {
  const project = useProjectSelector();
  const { alertId } = useParams<{ alertId: string }>();
  const [alert, setAlert] = useState<ApiAlert | undefined>(undefined);
  const { conversions } = useConversions();

  useMount(() => {
    if (!alertId) {
      return;
    }
    const loadEngagement = async () => {
      const res = await alertService.alertServiceReadAlert({
        projectId: project?.id as number,
        alertId: Number(alertId),
      });
      setAlert(res);
    };
    loadEngagement();
  });

  const filteredConversions = useMemo(() => {
    return [...conversions];
  }, [alert, conversions]);

  if (!project || !alert) {
    return <CircularProgress />;
  }

  return (
    <>
      <Portal container={() => document.getElementById("pageTitle")}>
        <TypographyHelp
          variant="h1"
          text="Alert Setup"
          width={370}
          tooltip={
            <>
              <p>
                If there is a change in the level of activity on your site, you
                may wish to be sent an alert. Here you can define the triggers
                that cause the alert to be sent to you.
              </p>
              <p>
                An alert can be triggered if a grouped set of events assigned by
                you occur (i.e. event 1 <strong>AND</strong> event 2) or a
                grouped set of different events occur (i.e. event 1 and event 2,{" "}
                <strong>OR</strong> event 3, 4 and 5). You can have as many or
                as few events as you want in each set of grouped events.
              </p>
              <strong>Example</strong>
              <p>
                You wish to be sent an alert if the average number of sessions
                drops by less than 5% <strong>AND</strong> the conversion rate
                drops by more than 30%, <strong>OR</strong> the conversion rate
                drops by more than 500.
              </p>
              <p>In the first group you would select:</p>
              <p>average number of sessions … decreased by under … 5 … %</p>
              <p>conversion rate … decreased by over … 30 … %</p>
              <p>
                You would then click <strong>OR</strong> to create a new group,
                and in the new group you would select
              </p>
              <p>conversion rate … decreased by over … 500 … #</p>
            </>
          }
        />
      </Portal>
      <Box mt={5}>
        <ConditionGroupProvider>
          <AlertForm
            alert={alert}
            setAlert={setAlert}
            project={project}
            conversions={filteredConversions}
          />
        </ConditionGroupProvider>
      </Box>
    </>
  );
}

export default AlertPage;
