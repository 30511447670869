/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiConversionStateTriggerFromJSON, ApiConversionStateTriggerToJSON, } from './ApiConversionStateTrigger';
export function ApiConversionStateInputFromJSON(json) {
    return ApiConversionStateInputFromJSONTyped(json, false);
}
export function ApiConversionStateInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'trigger': !exists(json, 'trigger') ? undefined : ApiConversionStateTriggerFromJSON(json['trigger']),
        'offline': !exists(json, 'offline') ? undefined : json['offline'],
        'target': !exists(json, 'target') ? undefined : json['target'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'offlineFieldName': !exists(json, 'offlineFieldName') ? undefined : json['offlineFieldName'],
        'repeatedSelector': !exists(json, 'repeatedSelector') ? undefined : json['repeatedSelector'],
        'preventSubmit': !exists(json, 'preventSubmit') ? undefined : json['preventSubmit'],
        'targetInnerText': !exists(json, 'targetInnerText') ? undefined : json['targetInnerText'],
    };
}
export function ApiConversionStateInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'trigger': ApiConversionStateTriggerToJSON(value.trigger),
        'offline': value.offline,
        'target': value.target,
        'value': value.value,
        'offlineFieldName': value.offlineFieldName,
        'repeatedSelector': value.repeatedSelector,
        'preventSubmit': value.preventSubmit,
        'targetInnerText': value.targetInnerText,
    };
}
