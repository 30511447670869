import { ApiAuthResponse } from "@incendium/api";
import { AuthToken } from "config";

export const saveAuthToken = (result: ApiAuthResponse) => {
    localStorage.setItem(AuthToken, JSON.stringify(result));
}
export const removeAuthToken = ()=> {
    localStorage.removeItem(AuthToken);
}
export const getAuthToken = (): ApiAuthResponse| null => {
    const data = localStorage.getItem(AuthToken);
    if(!data){
        return null
    }
    try {
        return JSON.parse(data);
    }catch(e){
        removeAuthToken();
        return null;
    }
}
