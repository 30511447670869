import { Box, Stack } from "@mui/material";
import { ChartLegend } from "features/analytics";
import StyledDoughnutChart from "Components/StyledDoughnutChart/StyledDoughnutChart";
import { useAnalyticsContext } from "Providers/AnalyticsProvider";
import { useCallback, useMemo } from "react";
import { IChartData, TChartData } from "Interfaces";
import { useState } from "react";

interface IPieProps {
  showTotalText?: string;
  formatTotalValue?: (n: number) => string;
  totalFontSize?: number;
}

interface IAnalyticsDoughnutWrapperProps {
  chartData: IChartData;
  totals?: TChartData;
  dataKey?: string;
  noAnimation?: boolean;
  showTotalText?: string;
  pieProps?: IPieProps;
}

function AnalyticsDoughnutWrapper({
  chartData,
  totals,
  dataKey,
  noAnimation,
  pieProps,
}: IAnalyticsDoughnutWrapperProps) {
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const onRefChange = useCallback((node) => {
    setRef(node);
  }, []);
  const { colourMap } = useAnalyticsContext();

  const isLandscape = useMemo(() => {
    if (!chartData || !ref) {
      return false;
    }

    return ref.clientWidth > ref.clientHeight;
  }, [ref, chartData]);

  const total = useMemo(() => {
    return totals && dataKey ? Number(totals[dataKey]) : undefined;
  }, [totals, dataKey]);

  return (
    <Stack
      sx={{ height: "100%", width: "100%" }}
      direction={isLandscape ? "row" : "column"}
      ref={onRefChange}
    >
      <Box
        sx={{
          margin: "0 auto",
          flex: 1,
          width: "100%",
          maxWidth: isLandscape ? "70%" : "100%",
          display: "flex",
        }}
      >
        <Box
          sx={{
            height: "100%",
            maxWidth: "100%",
            margin: "auto",
            display: "inline-block",
            position: "relative",
            // display: "flex",
          }}
        >
          {/* sets square aspect */}
          {true && (
            <img
              alt="aspect"
              src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              height="100%"
            />
          )}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <StyledDoughnutChart
              data={chartData.data}
              yAxisKeys={chartData.y}
              dataKey={dataKey}
              noAnimation={noAnimation}
              noLegend
              showTotalText={pieProps?.showTotalText}
              totalFontSize={pieProps?.totalFontSize}
              formatTotalValue={pieProps?.formatTotalValue}
              total={total}
            />
          </Box>
        </Box>
      </Box>
      <Box
        ml={isLandscape ? 1 : 0}
        mt={2}
        mb={1}
        sx={{ minWidth: isLandscape ? "30%" : "100%" }}
        px={isLandscape ? 0 : 2}
      >
        <ChartLegend
          items={(chartData.data || []).map((c) => c.name)}
          noFlex={isLandscape}
          colourMap={colourMap}
        />
      </Box>
    </Stack>
  );
}

export default AnalyticsDoughnutWrapper;
