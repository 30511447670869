/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiDiscountFromJSON, ApiDiscountToJSON, } from './ApiDiscount';
import { ApiProductFromJSON, ApiProductToJSON, } from './ApiProduct';
import { ApiProfitMarginFromJSON, ApiProfitMarginToJSON, } from './ApiProfitMargin';
import { ApiSaleFromJSON, ApiSaleToJSON, } from './ApiSale';
export function ApiSaleItemFromJSON(json) {
    return ApiSaleItemFromJSONTyped(json, false);
}
export function ApiSaleItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'sale': !exists(json, 'sale') ? undefined : ApiSaleFromJSON(json['sale']),
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'product': !exists(json, 'product') ? undefined : ApiProductFromJSON(json['product']),
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'saleId': !exists(json, 'saleId') ? undefined : json['saleId'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'discounts': !exists(json, 'discounts') ? undefined : (json['discounts'].map(ApiDiscountFromJSON)),
        'profitMargin': !exists(json, 'profitMargin') ? undefined : json['profitMargin'],
        'profitMargins': !exists(json, 'profitMargins') ? undefined : (json['profitMargins'].map(ApiProfitMarginFromJSON)),
        'subscriptionId': !exists(json, 'subscriptionId') ? undefined : json['subscriptionId'],
    };
}
export function ApiSaleItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'sale': ApiSaleToJSON(value.sale),
        'quantity': value.quantity,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'product': ApiProductToJSON(value.product),
        'productId': value.productId,
        'saleId': value.saleId,
        'price': value.price,
        'discounts': value.discounts === undefined ? undefined : (value.discounts.map(ApiDiscountToJSON)),
        'profitMargin': value.profitMargin,
        'profitMargins': value.profitMargins === undefined ? undefined : (value.profitMargins.map(ApiProfitMarginToJSON)),
        'subscriptionId': value.subscriptionId,
    };
}
