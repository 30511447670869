import { Theme, Button, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { leftMenuSelector } from "Selectors/leftMenuSelector";
import { openLeftMenu, setActive } from "Actions";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { KeyboardArrowUp } from "@mui/icons-material";
import Image from "mui-image";
import { findChildToProps } from "features/leftMenu/utils";
import { useLeftmenuProviderContext } from "features/leftMenu/providers/LeftmenuProvider";
import { useUser } from "Hooks/useUser";
import { ApiACLRole } from "@incendium/api";

const useLeftMenuItemStyles = makeStyles((theme: Theme) => ({
  link: {
    background: "transparent",
    textAlign: "left",
    display: "flex",
    borderRadius: 0,
    justifyContent: "center",
    textDecoration: "none",
    color: theme.palette.common.white,
    alignItems: "center",
    transition: "all 0.3s linear",
    height: 58,
    overflow: "hidden",
    fontSize: 14,
    "&:hover": {
      background: "#282746",
      textDecoration: "underline",
    },
    "&.active": {
      fontWeight: 700,
      background: "#282746",
    },

    "&.onRoute": {
      background: "#282746",
    },
    "&.isMenuOpen": {
      padding: "0 20px",
      justifyContent: "flex-start",
    },
    "&.isOpen": {
      "& .MuiButton-endIcon svg": {
        transform: "none",
      },
    },
    "& .MuiButton-endIcon svg": {
      transform: "rotate(180deg)",
      transition: "all 0.2s linear",
    },
  },
  hidden: {
    opacity: 0.5,
  },
  icon: {
    position: "relative",

    width: "25px",
    height: "25px",
  },
  text: {
    textTransform: "lowercase",
    "&:first-letter": {
      textTransform: "uppercase",
    },
    "&.isMenuOpen": {
      flex: 1,
    },
  },
  children: {
    backgroundColor: "#151530",
    maxHeight: 0,
    overflow: "hidden",
    willChange: "max-height",
    transition: "max-height 0.3s linear",
    transitionDelay: "0.001s",
    color: theme.palette.common.white,
    "&.isOpen": {
      transitionDelay: "0.12s",
      maxHeight: 700,
    },
  },
}));

export const linkVariants = {
  open: {
    x: 10,
    opacity: 1,
    transition: {
      x: { stiffness: 1000, velocity: -1000 },
      duration: 0.8,
    },
    width: "100%",
  },
  closed: {
    opacity: 0,
    x: -10,
    width: 0,
    transition: {
      x: { stiffness: 1000, velocity: 1000 },
      width: { delay: -100 },
      duration: 0.1,
    },
  },
};
export const subVariants = {
  open: {
    opacity: 1,
    x: 0,
  },
  closed: {
    opacity: 0,
    x: -100,
    transition: {
      duration: 0,
    },
  },
};

const LeftMenuItem = ({
  to,
  text,
  icon: Icon,
  children,
  active,
  onClick,
  defaultIsOpen,
  imagePath,
}: {
  to: string;
  text: string;
  active?: boolean;
  icon?: React.ComponentType<any>;
  children?: React.ReactNode;
  onClick?: () => void;
  defaultIsOpen?: boolean;
  imagePath?: string;
}) => {
  const { leftMenuLinkIsVisible } = useLeftmenuProviderContext();
  const { user } = useUser();
  const history = useHistory();
  const classes = useLeftMenuItemStyles();
  const dispatch = useDispatch();
  const openMenu = () => {
    dispatch(openLeftMenu());
  };
  const [isOpen, setIsOpen] = useState(false);
  const { isMenuOpen, key, debouncedIsHover } = useSelector(leftMenuSelector);
  useEffect(() => {
    if (!isMenuOpen) {
      dispatch(setActive(""));
    }
  }, [isMenuOpen, dispatch]);

  useEffect(() => {
    if (key === text) {
      setIsOpen(true);
      return;
    }
    setTimeout(() => {
      setIsOpen(defaultIsOpen || false);
    }, 10);
  }, [text, key, defaultIsOpen]);

  const isVisible = useMemo(() => {
    const childTos = findChildToProps(children);
    return (
      !children || childTos.filter((t) => leftMenuLinkIsVisible(t)).length > 0
    );
  }, [children, leftMenuLinkIsVisible]);

  const isSuper = useMemo(
    () =>
      (user.permissions || []).findIndex(
        (p) => p.role === ApiACLRole.SUPER_ADMIN
      ) >= 0,
    [user]
  );

  if (!isSuper && !isVisible) {
    return <></>;
  }

  return (
    <motion.div>
      <Button
        fullWidth
        endIcon={
          children && (isMenuOpen || debouncedIsHover) && <KeyboardArrowUp />
        }
        onClick={() => {
          if (onClick) {
            onClick();
            return;
          }

          if (to && to !== "#") {
            history.push(to);
          } else {
            openMenu();
          }

          dispatch(isOpen ? setActive("") : setActive(text));
        }}
        className={clsx(classes.link, {
          active: active || isOpen,
          onRoute: active,
          isOpen,
          isMenuOpen: isMenuOpen || debouncedIsHover,
          [classes.hidden]: !isVisible,
        })}
      >
        {Icon && (
          <Icon
            className={clsx(classes.icon, {
              isMenuOpen: isMenuOpen || debouncedIsHover,
            })}
          />
        )}
        {imagePath && (
          <Image height={25} width={25} src={imagePath} duration={50} />
        )}
        <motion.span
          className={clsx(classes.text, {
            isMenuOpen: isMenuOpen || debouncedIsHover,
          })}
          variants={linkVariants}
        >
          {text}
        </motion.span>
      </Button>
      {children && (
        <Box
          component={motion.div}
          variants={subVariants}
          className={clsx(classes.children, { isOpen })}
        >
          {children}
        </Box>
      )}
    </motion.div>
  );
};

export default LeftMenuItem;
