import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import produce from "immer";
import { useReportBuilderContext } from "Providers/ReportBuilderProvider";
import { useEffect, useMemo } from "react";
import { AccordianChartBuilderSidebarBlock } from "./ChartBuilderSidebarBlock";
import { metricToName } from "features/analytics";
import { ApiChartCrawler, ApiDimension, ApiMetric } from "@incendium/api";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";

interface IChartBuilderOrderLimitProps {
  overideMetrics?: string[];
  title?: string;
  subTitle?: string;
  hideOrder?: boolean;
  maxLimit?: number;
}
function ChartBuilderOrderLimit({
  overideMetrics,
  title,
  subTitle,
  hideOrder,
  maxLimit,
}: IChartBuilderOrderLimitProps) {
  const { chart, setChart } = useReportBuilderContext();

  const metrics = useMemo(() => {
    return (
      overideMetrics ||
      chart.yAxisKeys.reduce((agg: any, y) => {
        if (typeof y === "string") {
          return [...agg, y];
        }
        return [...agg, ...(y.fields || [])];
      }, [])
    );
  }, [chart.yAxisKeys, overideMetrics]);

  useEffect(() => {
    if (!chart.orderBy || metrics.includes(chart.orderBy)) {
      return;
    }
    setChart(
      produce(chart, (draft) => {
        draft.orderBy = undefined;
      })
    );
  }, [metrics]);

  return (
    <AccordianChartBuilderSidebarBlock
      title={title || "Confirgure your results"}
      subTitle={
        subTitle ||
        (hideOrder
          ? "Limit the number of results"
          : `
          Choose the metric for result ordering, set the order (descending or ascending), and define the maximum number of results. Additionally, select whether to include subdomains and enable or disable crawlers' inclusion`)
      }
    >
      <Grid container spacing={2}>
        {!hideOrder && (
          <Grid item xs={7}>
            <Autocomplete
              id="order"
              size="small"
              onChange={(e, value) => {
                setChart(
                  produce(chart, (draft) => {
                    draft.orderBy = value;
                  })
                );
              }}
              value={chart.orderBy}
              disableClearable
              options={metrics}
              getOptionLabel={(o) => metricToName(o as ApiMetric)}
              renderInput={(params) => (
                <TextField {...params} label="Order By" variant="outlined" />
              )}
            />
          </Grid>
        )}
        <Grid item xs={hideOrder ? 7 : 5}>
          <TextField
            label="Limit"
            type={"number"}
            size="small"
            fullWidth
            value={chart.limit || ""}
            inputProps={{
              max: maxLimit || 5000,
            }}
            onChange={(e) =>
              setChart(
                produce(chart, (draft) => {
                  const n = Number(e.target.value);
                  draft.limit = maxLimit && n > maxLimit ? maxLimit : n;
                })
              )
            }
          />
        </Grid>
        <Grid item xs={7}>
          <StyledFlexBox compact sx={{ maxWidth: 300 }}>
            <Box display="flex" alignItems="center">
              <Switch
                disabled={chart.dimension?.includes(
                  ApiDimension.DIMENSION_DOMAIN
                )}
                checked={chart.includeSubdomains}
                onChange={(e, checked) =>
                  setChart((chart) =>
                    produce(chart, (draft) => {
                      draft.includeSubdomains = checked;
                    })
                  )
                }
              />
              <Typography
                color={chart.includeSubdomains ? "primary" : "secondary"}
              >
                Include Subdomains
              </Typography>
            </Box>
            <ClickableTooltip
              placement="right"
              text={
                "Although we track all activity, our analytics display primarily focuses on the root domain. Enabling this option includes data from subdomains in your analytics reports."
              }
              icon={<StyledHelp />}
            />
          </StyledFlexBox>
        </Grid>

        <Grid item xs={7}>
          <StyledFlexBox compact sx={{ maxWidth: 300 }}>
            <Box display="flex" alignItems="center">
              <Switch
                checked={chart.includeEmpty}
                onChange={(e, checked) =>
                  setChart((chart) =>
                    produce(chart, (draft) => {
                      draft.includeEmpty = checked;
                    })
                  )
                }
              />
              <Typography color={chart.includeEmpty ? "primary" : "secondary"}>
                Include (Not Set)
              </Typography>
            </Box>
            <ClickableTooltip
              placement="right"
              text={
                "By default, we exclude 'not set' dimensions from the results. Enable this option to include them."
              }
              icon={<StyledHelp />}
            />
          </StyledFlexBox>
        </Grid>
        <Grid item xs={7}>
          <FormControl>
            <FormLabel id="crawler">Crawler (Bot) Settings</FormLabel>
            <RadioGroup
              aria-labelledby="crawler"
              defaultValue={ApiChartCrawler.NO_CRAWLERS}
              name="crawler-group"
              onChange={(_, v) => {
                setChart((chart) =>
                  produce(chart, (draft) => {
                    draft.crawler = v as ApiChartCrawler;
                  })
                );
              }}
              value={chart.crawler}
            >
              <FormControlLabel
                value={ApiChartCrawler.NO_CRAWLERS}
                control={<Radio />}
                label="No Crawlers"
              />
              <FormControlLabel
                value={ApiChartCrawler.WITH_CRAWLERS}
                control={<Radio />}
                label="Include Crawlers"
              />
              <FormControlLabel
                value={ApiChartCrawler.ONLY_CRAWLERS}
                control={<Radio />}
                label="Crawlers Only"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </AccordianChartBuilderSidebarBlock>
  );
}

export default ChartBuilderOrderLimit;
