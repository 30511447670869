import { ApiDimension } from "@incendium/api";
import { IChart } from "Interfaces";
import { filterNonTrendDimensions } from "features/analytics/utils/utils";
import { useMemo } from "react";

const useNonTrendDimensions = (chart: IChart) => {
  return useMemo(
    () => filterNonTrendDimensions(chart.dimension as ApiDimension[]),
    [chart.dimension]
  );
};

export default useNonTrendDimensions;
