/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import { ApiYAxisChartTypeFromJSON, ApiYAxisChartTypeToJSON, } from './ApiYAxisChartType';
export function ApiChartYAxisKeyFromJSON(json) {
    return ApiChartYAxisKeyFromJSONTyped(json, false);
}
export function ApiChartYAxisKeyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'key': !exists(json, 'key') ? undefined : json['key'],
        'fields': !exists(json, 'fields') ? undefined : json['fields'],
        'stackId': !exists(json, 'stackId') ? undefined : json['stackId'],
        'chart': !exists(json, 'chart') ? undefined : (mapValues(json['chart'], ApiYAxisChartTypeFromJSON)),
    };
}
export function ApiChartYAxisKeyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'key': value.key,
        'fields': value.fields,
        'stackId': value.stackId,
        'chart': value.chart === undefined ? undefined : (mapValues(value.chart, ApiYAxisChartTypeToJSON)),
    };
}
