/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiBucketPayloadToJSON, ApiBucketResponseFromJSON, ApiListBucketsResponseFromJSON, } from '../models';
/**
 *
 */
export class BucketServiceApi extends runtime.BaseAPI {
    /**
     */
    bucketServiceCreateBucketRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling bucketServiceCreateBucket.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling bucketServiceCreateBucket.');
            }
            const queryParameters = {};
            if (requestParameters.bucketId !== undefined) {
                queryParameters['bucketId'] = requestParameters.bucketId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/bucket/v1/projects/{projectId}/buckets`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiBucketPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiBucketResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    bucketServiceCreateBucket(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bucketServiceCreateBucketRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    bucketServiceDeleteBucketRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling bucketServiceDeleteBucket.');
            }
            if (requestParameters.bucketId === null || requestParameters.bucketId === undefined) {
                throw new runtime.RequiredError('bucketId', 'Required parameter requestParameters.bucketId was null or undefined when calling bucketServiceDeleteBucket.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadAttributionTags) {
                queryParameters['payload.attributionTags'] = requestParameters.payloadAttributionTags;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/bucket/v1/projects/{projectId}/buckets/{bucketId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"bucketId"}}`, encodeURIComponent(String(requestParameters.bucketId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    bucketServiceDeleteBucket(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bucketServiceDeleteBucketRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    bucketServiceListBucketsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling bucketServiceListBuckets.');
            }
            const queryParameters = {};
            if (requestParameters.bucketId !== undefined) {
                queryParameters['bucketId'] = requestParameters.bucketId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/bucket/v1/projects/{projectId}/buckets`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListBucketsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    bucketServiceListBuckets(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bucketServiceListBucketsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    bucketServiceReadBucketRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling bucketServiceReadBucket.');
            }
            if (requestParameters.bucketId === null || requestParameters.bucketId === undefined) {
                throw new runtime.RequiredError('bucketId', 'Required parameter requestParameters.bucketId was null or undefined when calling bucketServiceReadBucket.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadAttributionTags) {
                queryParameters['payload.attributionTags'] = requestParameters.payloadAttributionTags;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/bucket/v1/projects/{projectId}/buckets/{bucketId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"bucketId"}}`, encodeURIComponent(String(requestParameters.bucketId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiBucketResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    bucketServiceReadBucket(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bucketServiceReadBucketRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    bucketServiceUpdateBucketRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling bucketServiceUpdateBucket.');
            }
            if (requestParameters.bucketId === null || requestParameters.bucketId === undefined) {
                throw new runtime.RequiredError('bucketId', 'Required parameter requestParameters.bucketId was null or undefined when calling bucketServiceUpdateBucket.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling bucketServiceUpdateBucket.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/bucket/v1/projects/{projectId}/buckets/{bucketId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"bucketId"}}`, encodeURIComponent(String(requestParameters.bucketId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiBucketPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiBucketResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    bucketServiceUpdateBucket(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bucketServiceUpdateBucketRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
