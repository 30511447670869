import {
  ApiCampaignType,
  ApiComponentUISource,
  ApiProject,
  ApiSimpleCampaign,
  ApiSimpleComponent,
} from "@incendium/api";
import { Code, Delete, ViewComfy } from "@mui/icons-material";
import {
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SpacedList, {
  SpacedCell,
  SpacedLinkButton,
} from "Components/UI/SpacedList";
import { cell1Icon } from "consts";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

interface IComponentsListRowProps {
  project: ApiProject;
  component: ApiSimpleComponent;
  campaigns: ApiSimpleCampaign[];
  onGoToComponent: (component: ApiSimpleComponent) => void;
  onDeleteComponent: (component: ApiSimpleComponent) => void;
}
function ComponentsListRow({
  project,
  component,
  campaigns,
  onGoToComponent,
  onDeleteComponent,
}: IComponentsListRowProps) {
  const usedInCampaigns = useMemo(() => {
    return Array.from(
      new Set(
        campaigns.filter((c) =>
          component.personalisations?.map((p) => p.campaignId).includes(c.id)
        )
      )
    );
  }, [component, campaigns]);

  const toCampaign = useCallback(
    (campaign: ApiSimpleCampaign) => {
      return `/clients/${project.client?.id}/projects/${project.id}/publish/${
        campaign.type === ApiCampaignType.CAMPAIGN_TYPE_NONE
          ? "rezonate"
          : "conversion-lab"
      }`;
    },
    [project]
  );

  return (
    <TableRow key={component.id}>
      <SpacedCell className="primary" width={400}>
        <SpacedLinkButton onClick={() => onGoToComponent(component)}>
          {component.name}
        </SpacedLinkButton>

        <Stack
          direction={"row"}
          alignItems="center"
          spacing={1}
          sx={{ fontSize: 14 }}
        >
          {component.uiSource === ApiComponentUISource.GRAPES ? (
            <>
              <ViewComfy fontSize="inherit" />
              <Typography variant="body2">Builder</Typography>
            </>
          ) : (
            <>
              <Code fontSize="inherit" />
              <Typography variant="body2">Code</Typography>
            </>
          )}
        </Stack>
      </SpacedCell>
      <TableCell>{component.description}</TableCell>
      <TableCell>
        {usedInCampaigns.length > 0 && (
          <>
            <Typography variant="subtitle2">Component used in.</Typography>
            {usedInCampaigns.map((ca) => (
              <Link key={ca.id} to={() => toCampaign(ca)}>
                <Typography variant="body2">{ca.name}</Typography>
              </Link>
            ))}
          </>
        )}
      </TableCell>

      <TableCell align="right" style={{ width: cell1Icon }}>
        <IconButton
          size="small"
          color="secondary"
          onClick={() => onDeleteComponent(component)}
        >
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

interface IComponentsListProps {
  project: ApiProject;
  components: ApiSimpleComponent[];
  onGoToComponent: (component: ApiSimpleComponent) => void;
  onDeleteComponent: (component: ApiSimpleComponent) => void;
  campaigns: ApiSimpleCampaign[];
}

function ComponentsList({
  project,
  components,
  campaigns,
  onGoToComponent,
  onDeleteComponent,
}: IComponentsListProps) {
  return (
    <SpacedList>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Info</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {(components || []).map((component) => {
          return (
            <ComponentsListRow
              key={component.id}
              project={project}
              component={component}
              campaigns={campaigns}
              onGoToComponent={onGoToComponent}
              onDeleteComponent={onDeleteComponent}
            />
          );
        })}
      </TableBody>
    </SpacedList>
  );
}

export default ComponentsList;
