import { ApiUser } from "@incendium/api";
import { Grid } from "@mui/material";
import GlassCard from "Components/GlassCard/GlassCard";
import UserForm from "Components/Users/UserForm";
import UsersList from "Components/Users/UsersList";
import { useLayoutContext } from "Providers/LayoutProvider";
import { useLayoutEffect, useState } from "react";

function UsersPage() {
  const { setPageTitle } = useLayoutContext();
  const [user, setUser] = useState<ApiUser>({});

  useLayoutEffect(() => {
    setPageTitle("User Management");
    return () => {
      setPageTitle("");
    };
  }, [setPageTitle]);

  const onSelect = (user: ApiUser) => {
    setUser(user);
  };

  return (
    <Grid container spacing={6} alignItems="flex-start">
      <Grid item xs={6}>
        <GlassCard>
          <UserForm user={user} setUser={setUser} />
        </GlassCard>
      </Grid>
      <Grid item xs={6}>
        <UsersList onSelect={onSelect} />
      </Grid>
    </Grid>
  );
}

export default UsersPage;
