/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiConditionToJSON, ApiConditionGroupToJSON, ApiCreateUpdateReadConditionGroupResponseFromJSON, ApiCreateUpdateReadConditionResponseFromJSON, ApiListConditionGroupsResponseFromJSON, ApiListConditionsResponseFromJSON, } from '../models';
/**
 *
 */
export class CustomisationApi extends runtime.BaseAPI {
    /**
     * condition_groups
     */
    customisationCreateConditionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling customisationCreateCondition.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/conditions`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiConditionToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiCreateUpdateReadConditionResponseFromJSON(jsonValue));
        });
    }
    /**
     * condition_groups
     */
    customisationCreateCondition(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.customisationCreateConditionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * condition_groups
     */
    customisationCreateConditionGroupRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling customisationCreateConditionGroup.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/condition_groups`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiConditionGroupToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiCreateUpdateReadConditionGroupResponseFromJSON(jsonValue));
        });
    }
    /**
     * condition_groups
     */
    customisationCreateConditionGroup(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.customisationCreateConditionGroupRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    customisationDeleteConditionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling customisationDeleteCondition.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/conditions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    customisationDeleteCondition(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.customisationDeleteConditionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    customisationDeleteConditionGroupRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling customisationDeleteConditionGroup.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/condition_groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    customisationDeleteConditionGroup(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.customisationDeleteConditionGroupRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    customisationListConditionGroupsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/condition_groups`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListConditionGroupsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    customisationListConditionGroups(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.customisationListConditionGroupsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    customisationListConditionsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/conditions`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListConditionsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    customisationListConditions(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.customisationListConditionsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    customisationReadConditionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling customisationReadCondition.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/conditions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiCreateUpdateReadConditionResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    customisationReadCondition(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.customisationReadConditionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    customisationReadConditionGroupRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling customisationReadConditionGroup.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/condition_groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiCreateUpdateReadConditionGroupResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    customisationReadConditionGroup(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.customisationReadConditionGroupRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    customisationUpdateConditionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling customisationUpdateCondition.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/conditions`,
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiConditionToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiCreateUpdateReadConditionResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    customisationUpdateCondition(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.customisationUpdateConditionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    customisationUpdateConditionGroupRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling customisationUpdateConditionGroup.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/condition_groups`,
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiConditionGroupToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiCreateUpdateReadConditionGroupResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    customisationUpdateConditionGroup(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.customisationUpdateConditionGroupRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
