import { ApiUserActionType, ApiUserFilterType } from "@incendium/api";
import { ExtraUserActionType } from "features/leadAnalytics/types/types";

export const actionTypeToName = (
  t: ApiUserActionType | ExtraUserActionType
) => {
  switch (t) {
    case ExtraUserActionType.VISIT_START:
      return "Visit";
    case ExtraUserActionType.VISIT_END:
      return "Visit End";
    case ExtraUserActionType.GPROUPED_INTERACTION:
      return "Interactions";
    case ExtraUserActionType.MACRO_CONVERSION:
      return "Conversion";
    case ExtraUserActionType.MICRO_CONVERSION:
      return "Interactionn";
    case ApiUserActionType.PAGE_VIEW:
      return "Pageview";
    case ApiUserActionType.LEAD_SCORE:
      return "New Lead Score";
    case ApiUserActionType.LEAD_STATUS:
      return "New Lead Status";
    case ApiUserActionType.RETENTION:
      return "Retention Calculation";

    default:
      return "";
  }
};

export const leadDimensionToName = (t: ApiUserFilterType) => {
  switch (t) {
    case ApiUserFilterType.AUDIENCE:
      return "Audience";
    case ApiUserFilterType.DEVICE:
      return "Device";
    case ApiUserFilterType.LEAD_SCORE:
      return "Lead Score";
    case ApiUserFilterType.LTV:
      return "LTV";
    case ApiUserFilterType.MACRO_CONVERSION:
      return "Conversions";
    case ApiUserFilterType.MICRO_CONVERSION:
      return "Interactions";
    case ApiUserFilterType.PRODUCT:
      return "Seen Products";
    case ApiUserFilterType.SALES:
      return "No. Of Sales";
    case ApiUserFilterType.STATUS:
      return "Current Lead Status";

    default:
      return "";
  }
};
