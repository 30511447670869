import { ApiMedia, ApiMediaType, ApiProject } from "@incendium/api";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import StyledDrawer, {
  StyledDrawerTitle,
  StyledDrawerContainer,
} from "Components/UI/StyledDrawer";
import { createMedia, MediaManager, UploaderButton } from "features/media";
import { useMedias } from "Hooks/useMedia";
import produce from "immer";
import { GenericDialoagProps } from "Interfaces";
import { useCallback, useState } from "react";
import { S3Response } from "react-s3-uploader";

interface IMediaDrawerProps extends GenericDialoagProps {
  project: ApiProject;
}

function MediaDrawer({ project, open, setOpen }: IMediaDrawerProps) {
  const theme = useTheme();
  const [uploading, setUploading] = useState(false);
  const { setMedias } = useMedias();

  const onProgress = (percent: number, status: string, file: File) => {
    setUploading(status !== "Upload completed");
  };

  // todo: hanlde error ui
  const onError = (error: string) => {
    console.error(error);
    setUploading(false);
  };

  const onFinish = useCallback(
    async (result: S3Response, file: File) => {
      const res = await createMedia(
        result,
        file,
        project?.id as number,
        ApiMediaType.IMAGE_MEDIA
      );

      setMedias((preMedias) =>
        produce(preMedias as ApiMedia[], (draft) => {
          draft.push(res);
        })
      );
    },
    [setMedias, project]
  );

  return (
    <StyledDrawer
      maxWidth={1400}
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
    >
      <StyledDrawerTitle>
        <Stack
          sx={{ width: "100%" }}
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Box>
            <Typography variant="subtitle1">Manage media</Typography>
            <Typography variant="body2">
              Upload / select / amend your media
            </Typography>
          </Box>
          <UploaderButton
            uploading={uploading}
            text="Upload Image"
            onFinish={onFinish}
            onError={onError}
            hidden={true}
            path={`clients/${project.client?.id}/projects/${project?.id}`}
            accept="image/*"
            project={project || {}}
            onProgress={onProgress}
            multiple
          />
        </Stack>
      </StyledDrawerTitle>

      <StyledDrawerContainer
        sx={{
          width: 1400,
          maxWidth: "100%",
          background: theme.palette.grey[300],
        }}
      >
        <MediaManager />
      </StyledDrawerContainer>
    </StyledDrawer>
  );
}

export default MediaDrawer;
