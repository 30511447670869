import { ApiAudience } from "@incendium/api";
import { Stack, TextField, Typography } from "@mui/material";
import { SidebarContentWrapper, SiderBarHeader } from "Components/UI/Sidebar";
import produce from "immer";
import React from "react";
import { TextValidator } from "react-material-ui-form-validator";

interface IAudienceSidebarProps {
  audience: ApiAudience;
  setAudience: React.Dispatch<React.SetStateAction<ApiAudience>>;
}

function AudienceSidebar({ audience, setAudience }: IAudienceSidebarProps) {
  return (
    <>
      <SiderBarHeader>
        <Typography variant="h2">Audience Information</Typography>
        <Typography>Name and describe your Audience</Typography>
      </SiderBarHeader>
      <SidebarContentWrapper px={3}>
        <Stack spacing={3.5}>
          <TextValidator
            label="name of alert"
            variant="outlined"
            name="name"
            fullWidth
            validators={["required"]}
            errorMessages={["name is required"]}
            value={audience.name || ""}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setAudience(
                produce(audience, (draft) => {
                  draft.name = e.currentTarget.value;
                })
              );
            }}
          />
          <TextField
            label="description"
            variant="outlined"
            name="description"
            fullWidth
            value={audience.description || ""}
            multiline
            minRows={5}
            maxRows={8}
            onChange={(e) => {
              setAudience(
                produce(audience, (draft) => {
                  draft.description = e.currentTarget.value;
                })
              );
            }}
          />
        </Stack>
      </SidebarContentWrapper>
    </>
  );
}

export default AudienceSidebar;
