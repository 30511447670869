/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiLocationFromJSON, ApiLocationToJSON, } from './ApiLocation';
import { ApiPageTagFromJSON, ApiPageTagToJSON, } from './ApiPageTag';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
import { ApiReservedLocationPageTaggerFromJSON, ApiReservedLocationPageTaggerToJSON, } from './ApiReservedLocationPageTagger';
export function ApiLocationPageTaggerFromJSON(json) {
    return ApiLocationPageTaggerFromJSONTyped(json, false);
}
export function ApiLocationPageTaggerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'location': !exists(json, 'location') ? undefined : ApiLocationFromJSON(json['location']),
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'selector': !exists(json, 'selector') ? undefined : json['selector'],
        'regex': !exists(json, 'regex') ? undefined : json['regex'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'crawlSearchParams': !exists(json, 'crawlSearchParams') ? undefined : json['crawlSearchParams'],
        'pageTags': !exists(json, 'pageTags') ? undefined : (json['pageTags'].map(ApiPageTagFromJSON)),
        'reserved': !exists(json, 'reserved') ? undefined : ApiReservedLocationPageTaggerFromJSON(json['reserved']),
    };
}
export function ApiLocationPageTaggerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'project': ApiProjectToJSON(value.project),
        'projectId': value.projectId,
        'location': ApiLocationToJSON(value.location),
        'locationId': value.locationId,
        'name': value.name,
        'selector': value.selector,
        'regex': value.regex,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'crawlSearchParams': value.crawlSearchParams,
        'pageTags': value.pageTags === undefined ? undefined : (value.pageTags.map(ApiPageTagToJSON)),
        'reserved': ApiReservedLocationPageTaggerToJSON(value.reserved),
    };
}
