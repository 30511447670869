/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiMediaFromJSON, ApiMediaToJSON, } from './ApiMedia';
export function ApiMediaFileFromJSON(json) {
    return ApiMediaFileFromJSONTyped(json, false);
}
export function ApiMediaFileFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'fileType': !exists(json, 'fileType') ? undefined : json['fileType'],
        'filePath': !exists(json, 'filePath') ? undefined : json['filePath'],
        'fullPath': !exists(json, 'fullPath') ? undefined : json['fullPath'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
        'media': !exists(json, 'media') ? undefined : ApiMediaFromJSON(json['media']),
        'mediaId': !exists(json, 'mediaId') ? undefined : json['mediaId'],
    };
}
export function ApiMediaFileToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'fileName': value.fileName,
        'fileType': value.fileType,
        'filePath': value.filePath,
        'fullPath': value.fullPath,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'media': ApiMediaToJSON(value.media),
        'mediaId': value.mediaId,
    };
}
