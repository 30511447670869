/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiMediaType;
(function (ApiMediaType) {
    ApiMediaType["MEDIA_UNSPECIFIED"] = "MEDIA_UNSPECIFIED";
    ApiMediaType["IMAGE_MEDIA"] = "IMAGE_MEDIA";
    ApiMediaType["VIDEO_MEDIA"] = "VIDEO_MEDIA";
    ApiMediaType["CSV"] = "CSV";
})(ApiMediaType || (ApiMediaType = {}));
export function ApiMediaTypeFromJSON(json) {
    return ApiMediaTypeFromJSONTyped(json, false);
}
export function ApiMediaTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiMediaTypeToJSON(value) {
    return value;
}
