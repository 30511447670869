/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiPredictionType;
(function (ApiPredictionType) {
    ApiPredictionType["PREDICTION_TYPE_UNSPECIFIED"] = "PredictionTypeUnspecified";
    ApiPredictionType["PREDICTION_TYPE_LEAD_LTV30DAYS"] = "PredictionTypeLeadLTV30days";
    ApiPredictionType["PREDICTION_TYPE_LEAD_LTV60DAYS"] = "PredictionTypeLeadLTV60days";
    ApiPredictionType["PREDICTION_TYPE_LEAD_LTV90DAYS"] = "PredictionTypeLeadLTV90days";
})(ApiPredictionType || (ApiPredictionType = {}));
export function ApiPredictionTypeFromJSON(json) {
    return ApiPredictionTypeFromJSONTyped(json, false);
}
export function ApiPredictionTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiPredictionTypeToJSON(value) {
    return value;
}
