import { ApiChannel, ApiProject } from "@incendium/api";
import {
  Badge,
  Button,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { Delete, Reorder } from "@mui/icons-material";
import SpacedList, { SpacedLinkButton } from "Components/UI/SpacedList";
import { usePreviousDistinct, useUpdateEffect } from "react-use";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { channelService } from "Apis";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import { useAppDndContext } from "Providers/AppDndProvider";
import GroupsCell from "./GroupsCell";
import { cell1Icon } from "consts";

type onEditFn = (c: ApiChannel) => void;
type onDeleteFn = (c: ApiChannel) => void;

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -4,
    top: 12,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    color: "white",
  },
}))(Badge);

const ChannelRowDrag = ({
  channel,
  index,
  onEdit,
  onDelete,
}: {
  channel: ApiChannel;
  index: number;
  onEdit: onEditFn;
  onDelete: onDeleteFn;
}) => {
  return (
    <Draggable draggableId={(channel.id || 0).toString()} index={index}>
      {(provided, snapshot) => (
        <ChannelRow
          onEdit={onEdit}
          onDelete={onDelete}
          channel={channel}
          provided={provided}
          snapshot={snapshot}
        />
      )}
    </Draggable>
  );
};

const ChannelRow = ({
  channel,
  provided,
  snapshot,
  onEdit,
  onDelete,
}: {
  channel: ApiChannel;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  onEdit: onEditFn;
  onDelete: onDeleteFn;
}) => {
  const groups = channel?.channelGroups || [];

  const [showDragging, setShowDragging] = useState(false);

  useEffect(() => {
    if (snapshot.isDragging || snapshot.isDropAnimating) {
      setShowDragging(true);
      return;
    }
    setShowDragging(false);
  }, [snapshot]);

  return (
    <TableRow
      ref={provided.innerRef}
      {...provided.draggableProps}
      className={showDragging ? "dragging" : ""}
    >
      <TableCell className="primary" style={{ width: 100 }}>
        <div {...provided.dragHandleProps}>
          <StyledBadge badgeContent={channel.priority} color="secondary">
            <Reorder />
          </StyledBadge>
        </div>
      </TableCell>
      <TableCell style={{ width: 250 }}>
        <SpacedLinkButton onClick={() => onEdit(channel)}>
          {channel.name}
        </SpacedLinkButton>
        {channel.isPaidChannel && (
          <Typography variant="body2">(Is Paid Marketing 💵)</Typography>
        )}
      </TableCell>
      <GroupsCell groups={groups} />

      <TableCell align="right" style={{ width: cell1Icon }}>
        <IconButton
          size="small"
          color="secondary"
          onClick={() => onDelete(channel)}
        >
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const ChannelsList = ({
  project,
  channels,
  setChannels,
  onEdit,
  onDelete,
  onClick,
}: {
  project: ApiProject;
  channels: ApiChannel[];
  setChannels: React.Dispatch<React.SetStateAction<ApiChannel[]>>;
  onEdit: onEditFn;
  onDelete: onDeleteFn;
  onClick: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const prevChannels = usePreviousDistinct(channels);
  const [isActive, setIsActive] = useState(false);
  const [isDropping, setIsDrpping] = useState(false);
  const { setCallBacks } = useAppDndContext();

  useUpdateEffect(() => {
    setCallBacks({
      onDragStart,
      onDragEnd,
    });
  }, [channels]);

  const onDragStart = () => {
    setIsActive(true);
  };

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result) {
        return;
      }

      setIsDrpping(true);
      setIsActive(false);
      setTimeout(() => {
        setIsDrpping(false);
      }, 100);

      const { destination, source, draggableId } = result;
      if (!destination) {
        return;
      }
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      // lets reorder channels priority
      const newChannels = channels.map((channel, i) => {
        if (Number(draggableId) === channel.id) {
          return {
            ...channel,
            priority: destination.index + 1,
          };
        }

        // if destination is less then going up
        if (destination.index < source.index) {
          if (i > source.index || i < destination.index) return channel;

          return {
            ...channel,
            priority: (channel.priority as number) + 1,
          };
        }

        // else going down

        if (i < source.index || i > destination.index) return channel;
        return {
          ...channel,
          priority: (channel.priority as number) - 1,
        };
      });

      // one last check, we will take them all in order, the just set incremental
      updateChannelOrder(newChannels);
    },
    [channels]
  );

  useUpdateEffect(() => {
    if (!prevChannels?.length) {
      return;
    }
    // check id order is same or change is note due to new one added
    const prevOrder = prevChannels.map((pr) => `${pr.id}-${pr.priority}`);
    const currentOrder = channels.map((pr) => `${pr.id}-${pr.priority}`);
    if (
      prevOrder.join("-") === currentOrder.join("-") ||
      prevOrder.length < currentOrder.length
    ) {
      return;
    }

    const updateChannels = async () => {
      try {
        await channelService.channelServiceReorderChannels({
          projectId: project.id as number,
          payload: {
            input: channels.map((c) => ({
              channelId: c.id,
              priority: c.priority,
            })),
          },
        });

        enqueueSnackbar("Channels Order Updated", {
          variant: "success",
          autoHideDuration: 2000,
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
      } catch (error) {
        enqueueSnackbar(
          "There was an error updating you channels order, please try again",
          {
            variant: "error",
            autoHideDuration: 2000,
            anchorOrigin: { horizontal: "right", vertical: "top" },
          }
        );
      }
    };

    if (channels.length) {
      updateChannels();
    }
  }, [channels]);

  const updateChannelOrder = (newChannels: ApiChannel[]) => {
    setChannels(
      newChannels
        .sort((a, b) => (a.priority || 0) - (b.priority || 0))
        .map((c, i) => ({
          ...c,
          priority: i + 1,
        }))
    );
  };

  return (
    <SpacedList
      title="Channels List"
      action={<Button onClick={onClick}>New Channel</Button>}
    >
      <TableHead>
        <TableRow>
          <TableCell>Priority</TableCell>
          <TableCell>Channel Name</TableCell>
          <TableCell>Rules</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>

      <Droppable droppableId="channel-list">
        {(provided) => (
          <TableBody
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={clsx({ dragging: isActive, dropping: isDropping })}
          >
            {channels.map((channel, i) => {
              return (
                <ChannelRowDrag
                  key={channel.id}
                  channel={channel}
                  index={i}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              );
            })}
            {provided.placeholder}
          </TableBody>
        )}
      </Droppable>
    </SpacedList>
  );
};

export default ChannelsList;
