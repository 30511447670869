import { Box, Stack } from "@mui/material";
import { StatCardTypography } from "Components/UI/StatCard";
import { memo, useCallback } from "react";
import IncreaseDecrease from "Components/IncreaseDecrease/IncreaseDecrease";
import { TChartData } from "Interfaces";
import { useHistory } from "react-router-dom";
import { useSelectedProject } from "Hooks";
import {
  ApiChartHaving,
  ApiChartRank,
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiProduct,
} from "@incendium/api";
import {
  AnalyticsStatCard,
  biggestRegressionByMetric,
  formatMetric,
  mostImprovedByMetric,
  percentageChangeByRowByMetric,
} from "features/analytics";
import { ExploreConfig } from "features/explorer/types";
import { ExplorerTable, filterByRank } from "features/explorer";
import { AnalyticsSpacing } from "consts";

interface IProductRankingInnerProps {
  config: ExploreConfig;
  dimension: ApiDimension;
  products: ApiProduct[];
  rank: number;
  ranks: ApiChartRank[];
  having: ApiChartHaving[];
}

function ProductRankingInner({
  config,
  dimension,
  products,
  rank,
  ranks,
  having,
}: IProductRankingInnerProps) {
  const history = useHistory();
  const { selectedProject, selectedClient } = useSelectedProject();

  const onClick = useCallback(
    (v: string) => {
      switch (dimension) {
        case ApiDimension.DIMENSION_PRODUCT_NAME:
          history.push(
            `/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/product-deep-dive`,
            {
              selectedProductId: products.find((p) => p?.name === v)?.id,
            }
          );
          break;
        case ApiDimension.DIMENSION_PRODUCT_CATEGORY:
          history.push(
            `/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/product-category-deep-dive`,
            {
              selectedProductCategory: v,
            }
          );
          break;

        default:
          break;
      }
    },
    [history, selectedClient, selectedProject, dimension, products]
  );

  const rankFilter = useCallback(
    (data: TChartData[]) => {
      return filterByRank(
        data,
        rank,
        config.ranking ? config.ranking[0] : undefined
      );
    },
    [rank, config.ranking]
  );

  const buildStatChartByMetric = useCallback(
    (metric?: ApiMetric) => {
      return {
        name: "",
        dimension: [dimension],
        template: ApiChartTemplate.SNAPSHOT,
        type: ApiChartType.GRAPH,
        attributes: [],
        ranks,
        having,
        includeEmpty: true,
        yAxisKeys: [
          {
            key: "l",
            fields: metric
              ? [metric, ApiMetric.METRIC_RANK]
              : [ApiMetric.METRIC_PRODUCT_REVENUE, ApiMetric.METRIC_RANK],
          },
        ],
      };
    },
    [dimension, ranks, having]
  );

  return (
    <Stack sx={{ height: "100%" }}>
      {config.cards && (
        <>
          <Stack direction="row" spacing={AnalyticsSpacing} mb={1} p={2}>
            {config.cards.includes("improvement") && (
              <AnalyticsStatCard
                maxWidth={340}
                comparison
                chart={buildStatChartByMetric()}
                renderBody={(data, compData) => {
                  const mostImproved = mostImprovedByMetric(
                    rankFilter(data),
                    compData || [],
                    ApiMetric.METRIC_RANK
                  );

                  const change = percentageChangeByRowByMetric(
                    mostImproved,
                    compData || [],
                    ApiMetric.METRIC_RANK
                  );
                  return (
                    <>
                      <StatCardTypography size="small" opactity={0.8}>
                        Biggest improvement
                      </StatCardTypography>
                      <StatCardTypography size="medium" lines={1}>
                        {mostImproved.name}
                      </StatCardTypography>
                      <StatCardTypography size="small">
                        <IncreaseDecrease
                          direction={change >= 0 ? "up" : "down"}
                          value={change}
                          size={"large"}
                          fontSize={22}
                        />
                      </StatCardTypography>
                    </>
                  );
                }}
              />
            )}
            {config.cards.includes("ltv") && (
              <AnalyticsStatCard
                maxWidth={340}
                chart={buildStatChartByMetric(
                  ApiMetric.METRIC_PRODUCT_GATEWAY_LTV
                )}
                renderBody={(data) => {
                  return (
                    <>
                      <StatCardTypography size="small" opactity={0.8}>
                        Highest LTV
                      </StatCardTypography>
                      <StatCardTypography size="large" lines={1}>
                        {formatMetric(
                          ApiMetric.METRIC_PRODUCT_GATEWAY_LTV,
                          Number(data[0][ApiMetric.METRIC_PRODUCT_GATEWAY_LTV])
                        )}{" "}
                      </StatCardTypography>
                      <StatCardTypography size="medium" lines={1}>
                        {data[0].name}
                      </StatCardTypography>
                    </>
                  );
                }}
              />
            )}
            {config.cards.includes("top") && (
              <AnalyticsStatCard
                maxWidth={340}
                chart={buildStatChartByMetric(
                  ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE
                )}
                renderBody={(data) => {
                  if (!rankFilter(data)[0]) {
                    return;
                  }
                  return (
                    <>
                      <StatCardTypography size="small" opactity={0.8}>
                        Top Performer in CTR
                      </StatCardTypography>
                      <StatCardTypography size="medium" lines={1} mb={1}>
                        {rankFilter(data)[0]?.name}
                      </StatCardTypography>
                      <StatCardTypography size="small">
                        ({" "}
                        {formatMetric(
                          ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE,
                          Number(
                            rankFilter(data)[0][
                              ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE
                            ]
                          )
                        )}
                        )
                      </StatCardTypography>
                    </>
                  );
                }}
              />
            )}
            {config.cards.includes("top") && (
              <AnalyticsStatCard
                maxWidth={340}
                comparison
                chart={buildStatChartByMetric(ApiMetric.METRIC_PRODUCT_REVENUE)}
                renderBody={(data, compData) => {
                  const mostImproved = mostImprovedByMetric(
                    rankFilter(data),
                    compData || [],
                    ApiMetric.METRIC_PRODUCT_REVENUE
                  );

                  const change = percentageChangeByRowByMetric(
                    mostImproved,
                    compData || [],
                    ApiMetric.METRIC_PRODUCT_REVENUE
                  );

                  return (
                    <>
                      <StatCardTypography size="small" opactity={0.8}>
                        Hot Product
                      </StatCardTypography>
                      <StatCardTypography size="medium" lines={1} mb={1}>
                        {mostImproved.name}
                      </StatCardTypography>
                      <StatCardTypography size="small">
                        <Stack direction="row" alignItems={"center"}>
                          {formatMetric(
                            ApiMetric.METRIC_PRODUCT_REVENUE,
                            Number(
                              mostImproved[ApiMetric.METRIC_PRODUCT_REVENUE]
                            )
                          )}{" "}
                          <IncreaseDecrease
                            direction={change >= 0 ? "up" : "down"}
                            value={change}
                            size={"large"}
                            fontSize={22}
                          />
                        </Stack>
                      </StatCardTypography>
                    </>
                  );
                }}
              />
            )}

            {config.cards.includes("regression") && (
              <AnalyticsStatCard
                maxWidth={340}
                direction={"down"}
                comparison
                chart={buildStatChartByMetric()}
                setDirection={(data, compData) =>
                  percentageChangeByRowByMetric(
                    biggestRegressionByMetric(
                      rankFilter(data),
                      compData || [],
                      ApiMetric.METRIC_RANK
                    ),
                    compData || [],
                    ApiMetric.METRIC_RANK
                  ) > 0
                    ? "up"
                    : "down"
                }
                renderBody={(data, compData) => {
                  const regression = biggestRegressionByMetric(
                    rankFilter(data),
                    compData || [],
                    ApiMetric.METRIC_RANK
                  );

                  const change = percentageChangeByRowByMetric(
                    regression,
                    compData || [],
                    ApiMetric.METRIC_RANK
                  );
                  return (
                    <>
                      <StatCardTypography size="small" opactity={0.8} mb={1}>
                        Biggest Regression
                      </StatCardTypography>
                      <StatCardTypography size="medium" lines={1}>
                        {regression.name}
                      </StatCardTypography>
                      <StatCardTypography size="small">
                        <IncreaseDecrease
                          direction={change >= 0 ? "up" : "down"}
                          value={change}
                          size={"large"}
                          fontSize={22}
                        />
                      </StatCardTypography>
                    </>
                  );
                }}
              />
            )}
            {config.cards.includes("bottom") && (
              <AnalyticsStatCard
                maxWidth={340}
                chart={buildStatChartByMetric(
                  ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE
                )}
                renderBody={(data) => {
                  return (
                    <>
                      <StatCardTypography size="small" opactity={0.8} mb={1}>
                        Worst Performer in CTR
                      </StatCardTypography>
                      <StatCardTypography size="medium" lines={1} mb={1}>
                        {rankFilter(data)[0].name}
                      </StatCardTypography>
                      <StatCardTypography size="small">
                        (
                        {formatMetric(
                          ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE,
                          Number(
                            rankFilter(data)[0][
                              ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE
                            ]
                          ) || 0
                        )}
                        )
                      </StatCardTypography>
                    </>
                  );
                }}
              />
            )}
            {config.cards.includes("cold") && (
              <AnalyticsStatCard
                maxWidth={340}
                comparison
                chart={buildStatChartByMetric(ApiMetric.METRIC_PRODUCT_REVENUE)}
                renderBody={(data, compData) => {
                  const regression = biggestRegressionByMetric(
                    rankFilter(data),
                    compData || [],
                    ApiMetric.METRIC_PRODUCT_REVENUE
                  );

                  const change = percentageChangeByRowByMetric(
                    regression,
                    compData || [],
                    ApiMetric.METRIC_PRODUCT_REVENUE
                  );

                  return (
                    <>
                      <StatCardTypography size="small" opactity={0.8} mb={1}>
                        Cold Product
                      </StatCardTypography>
                      <StatCardTypography size="medium" lines={1} mb={1}>
                        {regression.name}
                      </StatCardTypography>
                      <StatCardTypography size="small">
                        <Stack direction="row" alignItems={"center"}>
                          {formatMetric(
                            ApiMetric.METRIC_PRODUCT_REVENUE,
                            Number(regression[ApiMetric.METRIC_PRODUCT_REVENUE])
                          )}{" "}
                          <IncreaseDecrease
                            direction={change >= 0 ? "up" : "down"}
                            value={change}
                            size={"large"}
                            fontSize={22}
                          />
                        </Stack>
                      </StatCardTypography>
                    </>
                  );
                }}
              />
            )}
          </Stack>
        </>
      )}

      <Box pr={1} sx={{ flex: 1 }}>
        <ExplorerTable
          onClick={{ [dimension]: onClick }}
          metrics={[
            ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
            ApiMetric.METRIC_SESSIONS_COUNT,
            ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
            ApiMetric.METRIC_PRODUCT_IMPRESSION_COUNT,
            ApiMetric.METRIC_PRODUCT_IMPRESSION_RATE,
            ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_COUNT,
            ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE,
            ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_COUNT,
            ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_RATE,
            ApiMetric.METRIC_SALES_COMPLETION_RATE,
            ApiMetric.METRIC_PRODUCT_REVENUE,
            ApiMetric.METRIC_SALES_REVENUE,

            ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE,
            ApiMetric.METRIC_PRODUCT_GATEWAY_LTV,
            ApiMetric.METRIC_RANK,
          ]}
        />
      </Box>
    </Stack>
  );
}

export default memo(ProductRankingInner);
