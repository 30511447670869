/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiDataFromJSON, ApiDataToJSON, } from './ApiData';
import { ApiPageviewMetaTaggerFromJSON, ApiPageviewMetaTaggerToJSON, } from './ApiPageviewMetaTagger';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiPageviewMetaFromJSON(json) {
    return ApiPageviewMetaFromJSONTyped(json, false);
}
export function ApiPageviewMetaFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'dataId': !exists(json, 'dataId') ? undefined : json['dataId'],
        'pageviewMetaTaggerId': !exists(json, 'pageviewMetaTaggerId') ? undefined : json['pageviewMetaTaggerId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'data': !exists(json, 'data') ? undefined : ApiDataFromJSON(json['data']),
        'tagger': !exists(json, 'tagger') ? undefined : ApiPageviewMetaTaggerFromJSON(json['tagger']),
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'tmpKey': !exists(json, 'tmpKey') ? undefined : json['tmpKey'],
    };
}
export function ApiPageviewMetaToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'value': value.value,
        'dataId': value.dataId,
        'pageviewMetaTaggerId': value.pageviewMetaTaggerId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'projectId': value.projectId,
        'data': ApiDataToJSON(value.data),
        'tagger': ApiPageviewMetaTaggerToJSON(value.tagger),
        'project': ApiProjectToJSON(value.project),
        'tmpKey': value.tmpKey,
    };
}
