import { ApiUserAction, ApiUserActionType } from "@incendium/api";

export enum ExtraUserActionType {
  VISIT_START = "VISIT_START",
  VISIT_END = "VISIT_END",
  GPROUPED_INTERACTION = "GPROUPED_INTERACTION",
  MACRO_CONVERSION = "MACRO_CONVERSION",
  MICRO_CONVERSION = "MICRO_CONVERSION",
}

export interface IUserAction extends Omit<ApiUserAction, "type"> {
  type: ApiUserActionType | ExtraUserActionType;
  sessionRevenue?: number;
  macroCount?: number;
  microCount?: number;
  sessionPagviewsCount?: number;
  sessionAvgTimOnPageSeconds?: number;
  blockId?: number;
  conversionName?: string;
  sessionPurchasedProducts?: string[];
  endDate?: Date;
}
