import { ApiSalesFlowMetric } from "@incendium/api";
import { secondsToHrMinText } from "Helpers/dates";
import { centsToDollars, formatNumber } from "Helpers/numbers";
import { TSalesMetricMap } from "features/salesFlow/types/types";
import { ReactComponent as Total } from "Assets/Images/Stats/icon-total.svg";
import { ReactComponent as NewLead } from "Assets/Images/Stats/icon-new.svg";
import { ReactComponent as Returning } from "Assets/Images/Stats/icon-returning_prospect.svg";
import { ReactComponent as ReturningCustomer } from "Assets/Images/Stats/icon-returning_customer.svg";
// import { ReactComponent as TopChannel } from "Assets/Images/Stats/icon-top_channel.svg";
import { ReactComponent as Enquiries } from "Assets/Images/Stats/icon-enquiries.svg";
import { ReactComponent as Time } from "Assets/Images/Stats/icon-time.svg";
import { ReactComponent as Target } from "Assets/Images/Stats/icon-target.svg";
// import { ReactComponent as Visits } from "Assets/Images/Stats/icon-visits.svg";
import { ReactComponent as EnquiryRate } from "Assets/Images/Stats/icon-enquiry_rate.svg";
import { ReactComponent as ViewRate } from "Assets/Images/Stats/icon-view_rate.svg";
import { ReactComponent as LostLead } from "Assets/Images/Stats/icon-lost_lead.svg";
import { ReactComponent as Quote } from "Assets/Images/Stats/icon-quote.svg";

const metricMap: { [key in ApiSalesFlowMetric]?: TSalesMetricMap } = {
  [ApiSalesFlowMetric.CURRENT_LEADS]: {
    name: (s) => `Current ${s}s`,
    description:
      "This is the number of leads where this is their current {status}",
    icon: Target,
  },
  [ApiSalesFlowMetric.NEW_SESSIONS_PERCENTAGE]: {
    name: "New Sessions Percentage",
    description:
      "percentage of sessions from leads in {status} that are new leads",
    format: (v) => `${formatNumber(Number(v))}%`,
    icon: NewLead,
  },
  [ApiSalesFlowMetric.RETURNING_SESSION_PERCENTAGE]: {
    name: "Returning Sessions Percentage",
    description:
      "percentage of sessions from leads in {status} that are returning leads",
    format: (v) => `${formatNumber(Number(v))}%`,
    icon: Returning,
  },
  [ApiSalesFlowMetric.RETURNING_CUSTOMER_PERCENTAGE]: {
    name: "Returning Customer Percentage",
    description:
      "percentage of sessions from leads that are returning customers",
    format: (v) => `${formatNumber(Number(v))}%`,
    icon: ReturningCustomer,
  },
  [ApiSalesFlowMetric.TIME_TO_STATUS]: {
    name: (s) => `Typical Time to ${s}`,
    description:
      "number of seconds from previous status to current {status} (will be formated on fe to hrs, mins)",
    format: (v) => secondsToHrMinText(Number(v)),
    icon: Time,
  },
  [ApiSalesFlowMetric.SESSION_COUNT]: {
    name: "Typical Session Count",
    description: "the average number of sessions per lead in this {status}",
    icon: ViewRate,
  },
  [ApiSalesFlowMetric.SESSION_TO_STATUS_RATE]: {
    name: "Session to Status Rate",
    description: "no. leads in {status} / total no. sessions",
    format: (v) => `${formatNumber(Number(v))}%`,
    icon: EnquiryRate,
  },
  [ApiSalesFlowMetric.SESSION_EFFECTIVE_RATE]: {
    name: "Session Effective Rate",
    description: "effective rate of sessions in this {status}",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiSalesFlowMetric.TO_STATUS_RATE]: {
    name: (s, s2) => `${s2} to ${s}`,
    description:
      "Number of Leads in {status} / No. Leads That Were in Previous Status",
    format: (v) => `${formatNumber(Number(v))}%`,
    icon: ViewRate,
  },
  [ApiSalesFlowMetric.NUMBER_OF_INTERACTIONS]: {
    name: "Typical Number of Interactions",
    description: "avg. number of interactions per lead in {status}",
    icon: Enquiries,
  },

  [ApiSalesFlowMetric.LEADS]: {
    name: "Leads",
    description:
      "the number of leads that were this {status} in the time frame",
  },
  [ApiSalesFlowMetric.SESSIONS]: {
    name: "Sessions",
    description: "number of sessions leads that were this {status}",
  },
  [ApiSalesFlowMetric.REJECTION_RATE]: {
    name: "Lead Rejection Rate",
    description:
      "no. of interactions with rejection type / no. leads been in {status} for the time frame",
    format: (v) => `${formatNumber(Number(v))}%`,
    icon: LostLead,
  },
  [ApiSalesFlowMetric.REJECTION_REASON]: {
    name: "Top Rejection Reason",
    description:
      "top interaction  subject where type is rejection for leads that have been in this {status} in the time frame",
  },
  [ApiSalesFlowMetric.RESPONSE_TIME]: {
    name: "Typical Response Time",
    description:
      "average time in seconds between lead entering status to first interaction whilst lead in {status} for leads that have been in this status in this time frame",
    format: (v) => secondsToHrMinText(Number(v)),
    icon: Time,
  },
  [ApiSalesFlowMetric.REVENUE]: {
    name: "Revenue",
    description:
      "the total revenue of leads that have been in that {status} in the selected time frame",
    format: (v) => `$${centsToDollars(Number(v))}`,
    icon: Quote,
  },
};

export const metricToName = (
  metric: ApiSalesFlowMetric,
  value?: string,
  secondValue?: string
) =>
  metricMap[metric]?.name
    ? typeof metricMap[metric]?.name === "function"
      ? (metricMap[metric]?.name as any)(value, secondValue)
      : metricMap[metric]?.name
    : metric;

export const metricDescription = (metric: ApiSalesFlowMetric) =>
  metricMap[metric]?.description || "";

export const metricIcon = (
  metric: ApiSalesFlowMetric
): React.ComponentType<any> => metricMap[metric]?.icon || Total;

export const formatMetric = (
  metric: ApiSalesFlowMetric,
  value?: number | string
) => {
  const m = metricMap[metric];
  return m && m.format
    ? m.format(value || 0)
    : typeof value === "number"
    ? formatNumber(value || 0)
    : value;
};
