/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiClassificationRulePeriodType;
(function (ApiClassificationRulePeriodType) {
    ApiClassificationRulePeriodType["DAYS"] = "DAYS";
    ApiClassificationRulePeriodType["MONTHS"] = "MONTHS";
    ApiClassificationRulePeriodType["YEARS"] = "YEARS";
})(ApiClassificationRulePeriodType || (ApiClassificationRulePeriodType = {}));
export function ApiClassificationRulePeriodTypeFromJSON(json) {
    return ApiClassificationRulePeriodTypeFromJSONTyped(json, false);
}
export function ApiClassificationRulePeriodTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiClassificationRulePeriodTypeToJSON(value) {
    return value;
}
