import {
  ApiReportResponse,
  ApiTabChartCSVPayload,
  ApiTabPayload,
} from "@incendium/api";
import { reportService } from "Apis";
import { base64ToArrayBuffer, downloadCsv } from "Helpers/download";

export const pinReportToHome = async (projectId: number, reportId: number) => {
  const res = await reportService.reportServicePinReport({
    projectId,
    reportId,
  });
  return res;
};

export const toggleReportFavourite = async (
  projectId: number,
  reportId: number
) => {
  const res = await reportService.reportServiceToggleFavouriteReport({
    projectId,
    reportId,
  });
  return res;
};

export const readReport = async (projectId: number, reportId: number) => {
  const res = await reportService.reportServiceReadReport({
    projectId,
    reportId,
  });
  return res;
};

export const saveReport = async (
  projectId: number,
  report: ApiReportResponse
) => {
  const payload = {
    name: report.name,
  };
  const fn = report.id
    ? reportService.reportServiceUpdateReport({
        projectId,
        reportId: report.id,
        payload,
      })
    : reportService.reportServiceCreateReport({
        projectId,
        payload,
      });

  const res = await fn;
  return res;
};

export const deleteReport = async (projectId: number, reportId: number) => {
  return reportService.reportServiceDeleteReport({
    projectId,
    reportId,
  });
};

export const downloadReport = async (
  projectId: number,
  reportId: number,
  tabId: number,
  chartId: number,
  payload: ApiTabChartCSVPayload,
  name?: string
) => {
  const res = await reportService.reportServiceReadTabChartCSV({
    projectId,
    reportId,
    tabId,
    chartId,
    payload,
  });
  if (res?.result?.result) {
    const buffer = base64ToArrayBuffer(res.result.result);
    downloadCsv(buffer, name || "export");
  }
};

export const listReportTabs = async (projectId: number, reportId: number) => {
  const res = await reportService.reportServiceListTabs({
    projectId,
    reportId,
  });
  return res;
};

export const saveReportTab = async (
  projectId: number,
  reportId: number,
  payload: ApiTabPayload,
  tabId?: number
) => {
  const input = {
    projectId,
    reportId,
    payload,
  };
  const fn = tabId
    ? reportService.reportServiceUpdateTab({
        ...input,
        tabId,
      })
    : reportService.reportServiceCreateTab({
        ...input,
      });

  const res = await fn;
  return res;
};
