import {
  ApiLocation,
  ApiPageType,
  ApiProject,
  ApiProjectType,
  ApiReservedPageTypes,
} from "@incendium/api";
import { Chip, TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { locationService } from "Apis";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import { useHasProjectType } from "features/project";
import { AnimatePresence, motion } from "framer-motion";
import { useLocations } from "Hooks";
import { usePageTypes } from "Hooks/usePageTypes";
import produce from "immer";
import { useCallback, useMemo } from "react";

interface IAppendLocationPageTypeFieldProps {
  project: ApiProject;
  location: ApiLocation;
  setLocation: (l: ApiLocation) => void;
  size: "small" | "medium" | undefined;
}

function AppendLocationPageTypeField({
  project,
  location,
  setLocation,
  size,
}: IAppendLocationPageTypeFieldProps) {
  const { pageTypes, setPageTypes } = usePageTypes();
  const { refetchLocations } = useLocations();
  const hasProjectType = useHasProjectType();

  const options = useMemo(() => {
    return pageTypes
      .filter(
        (option) =>
          !(location.pageTypes || []).map((pt) => pt.id).includes(option.id)
      )
      .filter((f) => {
        // remove project type related defaults, and re add based on project type
        if (!f._default || f._default === ApiReservedPageTypes.UNSPECIFIED) {
          return true;
        }
        return [
          ApiReservedPageTypes.PRODUCT_PAGE,
          ApiReservedPageTypes.PRODUCT_CATEGORY,
          ApiReservedPageTypes.CHECKOUT,
        ].includes(f._default)
          ? hasProjectType(ApiProjectType.TYPE_ECOMMERCE)
          : [
              ApiReservedPageTypes.JOB_PAGE,
              ApiReservedPageTypes.JOB_CATEGORY,
            ].includes(f._default)
          ? hasProjectType(ApiProjectType.TYPE_RECRUITMENT)
          : [
              ApiReservedPageTypes.SERVICE_CATEGORY,
              ApiReservedPageTypes.SERVICE_PAGE,
            ].includes(f._default)
          ? hasProjectType(ApiProjectType.TYPE_B2_B)
          : true;
      })
      .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
      .sort((a, b) => (a._default || "").localeCompare(b._default || ""));
  }, [pageTypes, location.pageTypes, hasProjectType]);

  const handleChange = useCallback(
    async (values: (string | ApiPageType)[]) => {
      const mappedPageTypes = await Promise.all(
        values.map(async (value) => {
          let pageType: ApiPageType;
          if (typeof value === "string") {
            const res = await locationService.locationServiceCreatePageType({
              projectId: project.id as number,
              payload: {
                name: value,
              },
            });
            pageType = res;
            setPageTypes(
              produce(pageTypes, (draft) => {
                draft.push(pageType);
              })
            );
          } else {
            pageType = value;
          }

          // check if already assigned
          const idx = (location.pageTypes || []).findIndex(
            (pt) => pt.id === pageType.id
          );
          if (idx >= 0) {
            return pageType;
          }

          await locationService.locationServiceAppendLocationPageType({
            projectId: project.id as number,
            locationId: location.id as number,
            pageTypeId: pageType.id as number,
          });
          return pageType;
        })
      );

      // check and remove any that are no longer in values
      location.pageTypes?.forEach(async (pageType) => {
        const idx = mappedPageTypes.findIndex((pt) => pt.id === pageType.id);
        if (idx === -1) {
          await locationService.locationServiceRemoveLocationPageType({
            projectId: project.id as number,
            locationId: location.id as number,
            pageTypeId: pageType.id as number,
          });
        }
      });

      setLocation(
        produce(location, (draft) => {
          draft.pageTypes = mappedPageTypes;
        })
      );
      refetchLocations();
    },
    [
      location,
      pageTypes,
      project.id,
      refetchLocations,
      setLocation,
      setPageTypes,
    ]
  );

  return (
    <Autocomplete
      multiple
      id="pageTypes"
      onChange={(e, value) => handleChange(value)}
      value={location.pageTypes || []}
      disableClearable
      freeSolo
      fullWidth
      size={size}
      options={options || []}
      getOptionLabel={(option) =>
        typeof option === "string"
          ? option
          : `${
              option._default &&
              option._default !== ApiReservedPageTypes.UNSPECIFIED
                ? "(Reserved)"
                : ""
            } ${option.name}` || ""
      }
      renderTags={(value, getTagProps) => (
        <AnimatePresence>
          {value.map((option, index) => (
            <Chip
              size={size}
              variant="outlined"
              component={motion.div}
              initial={{ scale: 0.2 }}
              exit={{ scale: 0.1 }}
              animate={{ scale: 1 }}
              label={option.name}
              {...getTagProps({ index })}
            />
          ))}
        </AnimatePresence>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          id="outlined-basic"
          label="Tag Location (optional)"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <ClickableTooltip
                placement="right"
                text="tags can help you group different locations in analytics"
                icon={<StyledHelp />}
              />
            ),
          }}
        />
      )}
    />
  );
}

export default AppendLocationPageTypeField;
