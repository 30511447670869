import {
  ADD_CLIENT,
  ADD_PROJECT,
  CLEAR,
  DELETE_CLIENT,
  FETCHING_STATE,
  FETCHING_STATE_ERROR,
  FETCHING_STATE_SUCCESS,
  INITIALIZE_CLIENTS,
  INITIALIZE_PROJECTS,
  LOAD_FULL_PROJECT,
  SET_APPLICATION_STATE,
  UPDATE_CLIENT,
  UPDATE_PROJECT,
} from "Actions/ClientActions/clientActionTypes";
import { IClientActions } from "Actions";
import { IStoreState } from "Interfaces";
import { Reducer } from "redux";
import produce from "immer";
import { get, set } from "lodash";

const initialState = {
  user: {},
  users: {},
  clients: { hasLoaded: false, list: [] },
  projects: {},
  locations: {},
  campaigns: {},
  channels: {},
  buckets: {},
  classificationGroups: {},
  classifications: {},
  engagements: {},
  effectivenessRules: {},
  sessionEffectivenessRules: {},
  personalizations: {},
  conversions: {},
  audiences: {},
  assets: {},
  assetComponents: {},
  pageTypes: {},
  alerts: {},
  leadStatusRules: {},
  leadScoreAdjustmentRules: {},
  charts: {},
  pageTaggers: {},
  reports: {},
  tabElements: {},
  apiTokens: {},
  CSVSessions: {},
  CSVRules: {},
  pageProducts: {},
  products: {},
  syncClients: {},
  externalSyncs: {},
  pageviewMetaTaggers: {},
  attributionTags: {},
  jobs: {},
  uniqueCountries: {},
  filterableDimensions: {},
  filterableAttributionDimensions: {},
  components: {},
  medias: {},
  subdomains: {},
  landers: {},
  domains: {},
  utms: {},
};

export const applicationReducer: Reducer<
  IStoreState["application"],
  IClientActions
> = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_CLIENTS: {
      return {
        ...state,
        clients: {
          list: action.payload.clients,
          hasLoaded: true,
        },
      };
    }
    case ADD_CLIENT: {
      const { client } = action.payload;

      return produce(state, (draft) => {
        draft.clients.list.push(client);
      });
    }

    case ADD_PROJECT: {
      const { project } = action.payload;

      return produce(state, (draft) => {
        draft.projects[project.clientId as number].list.push({
          project,
          hasFullyLoaded: true,
          locations: { list: [], hasLoaded: false },
        });
      });
    }
    case UPDATE_PROJECT: {
      const { project } = action.payload;
      const index = state.projects[project.clientId as number].list.findIndex(
        (item) => item.project.id === (project.id as number)
      );
      if (index === -1) {
        return state;
      }
      return produce(state, (draft) => {
        draft.projects[project.clientId as number].list[index].project =
          project;
        draft.projects[project.clientId as number].list[index].hasFullyLoaded =
          true;
      });
    }

    case UPDATE_CLIENT: {
      const { client } = action.payload;

      const index = state.clients.list.findIndex(
        (item) => item.id === client.id
      );

      return produce(state, (draft) => {
        if (index > -1) {
          draft.clients.list[index] = client;
        }
      });
    }

    case DELETE_CLIENT: {
      const { id } = action.payload;
      const index = state.clients.list.findIndex((item) => item.id === id);
      return produce(state, (draft) => {
        if (index > -1) {
          draft.clients.list.splice(index, 1);
        }
      });
    }
    case INITIALIZE_PROJECTS: {
      const { clientId, projects } = action.payload;

      return produce(state, (draft) => {
        draft.projects[clientId] = {
          hasLoaded: true,
          list: projects.map((item) => {
            return {
              project: item,
              hasFullyLoaded: false,
              locations: { list: [], hasLoaded: false },
            };
          }),
        };
      });
    }
    case FETCHING_STATE: {
      const { clientId, key, projectId, id } = action.payload;
      const path =
        typeof id !== "undefined"
          ? [key, clientId, projectId, id, "status"]
          : [key, clientId, projectId, "status"];
      return produce(state, (draft) => {
        set(draft, path, "FETCHING");
      });
    }
    case FETCHING_STATE_SUCCESS: {
      const { clientId, key, projectId, id } = action.payload;
      const path =
        typeof id !== "undefined"
          ? [key, clientId, projectId, id, "status"]
          : [key, clientId, projectId, "status"];
      return produce(state, (draft) => {
        set(draft, path, "FETCH_SUCCESS");
      });
    }
    case FETCHING_STATE_ERROR: {
      const { clientId, key, projectId, id } = action.payload;
      const path =
        typeof id !== "undefined"
          ? [key, clientId, projectId, id, "status"]
          : [key, clientId, projectId, "status"];
      return produce(state, (draft) => {
        set(draft, path, "FETCH_ERROR");
      });
    }
    case SET_APPLICATION_STATE: {
      const { clientId, state: appState, key, projectId, id } = action.payload;
      return produce(state, (draft) => {
        const path =
          typeof id !== "undefined"
            ? [key, clientId, projectId, id, "state"]
            : [key, clientId, projectId, "state"];
        if (typeof appState === "function") {
          const newState = appState(get(state, path));
          set(draft, path, newState);
        } else {
          set(draft, path, appState);
        }
      });
    }
    case LOAD_FULL_PROJECT: {
      const { clientId, projectId, project } = action.payload;

      const index = state.projects[clientId].list.findIndex(
        (item) => item.project.id === projectId
      );
      if (index === -1) {
        return state;
      }

      return produce(state, (draft) => {
        draft.projects[clientId].list[index] = {
          project,
          hasFullyLoaded: true,
          locations: { list: [], hasLoaded: false },
        };
      });
    }
    case CLEAR: {
      state = initialState;
      break;
    }
  }

  return state;
};
