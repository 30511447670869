import { ApiCSVUploadSession } from "@incendium/api";
import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { CSVService } from "Apis";
import SpacedList from "Components/UI/SpacedList";
import { cell1Icon } from "consts";
import { UploaderRulesDialog } from "features/csvManager";
import { formatEnumVal } from "Helpers/enumToText";
import { useSelectedProject } from "Hooks";
import { useCSVRules } from "Hooks/useCSVRules";
import { useCSVSessions } from "Hooks/useCSVSessions";
import produce from "immer";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useConfirmationContext } from "Providers/ConfirmationProvider";
import { useMemo, useState } from "react";

function DataManagerPage() {
  const { selectedProject } = useSelectedProject();
  const { CSVSessions, setCSVSessions } = useCSVSessions();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { enqueueSnackbar } = useSnackbar();
  const [dataType, setDataType] = useState<string>("all");
  const [open, setOpen] = useState(false);
  const { CSVRules } = useCSVRules();

  const dataTypes = useMemo(
    () => ["all", ...CSVRules.map((r) => r.id)],
    [CSVRules]
  );

  const deleteSessions = async (session: ApiCSVUploadSession) => {
    if (!session.id || !selectedProject?.id) {
      return;
    }
    openConfirmation({
      title: `Are you sure you want to delete these rows`,
      body: `This action will delete ${(session.rows || []).map(
        (r) =>
          `all ${(r.rows || 0).toLocaleString()} ${formatEnumVal(
            r.table || ""
          )} rows and`
      )} can not be undone.`,
      callback: async () => {
        if (!session.key) {
          return;
        }
        await CSVService.cSVServiceDeleteCSVSession({
          projectId: selectedProject?.id as number,
          sessionKey: session.key,
          ruleId: session.csvRule,
        });

        setCSVSessions(
          produce(CSVSessions, (draft) => {
            const idx = draft.findIndex((d) => d.id === session.id);
            draft.splice(idx, 1);
          })
        );

        enqueueSnackbar(`${session.name} Deleted`, {
          variant: "success",
          autoHideDuration: 2000,
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });

        closeConfirmation();
      },
    });
  };

  const tableData = useMemo(() => {
    return (CSVSessions || []).filter(
      (s) => dataType === "all" || s.csvRule === dataType
    );
  }, [dataType, CSVSessions]);

  const handleNewData = () => {
    setOpen(true);
  };

  return (
    <Box>
      <SpacedList
        title="Data Uploads"
        action={
          <Stack direction={"row"} spacing={2}>
            <TextField
              sx={{ width: 300 }}
              size="small"
              label="Data Type"
              select
              value={dataType}
              onChange={(e) => setDataType(e.target.value)}
            >
              {dataTypes.map((dt) => (
                <MenuItem key={dt} value={dt}>
                  {formatEnumVal(dt || "")}
                </MenuItem>
              ))}
            </TextField>

            <Button onClick={handleNewData}>Upload New Data</Button>
          </Stack>
        }
      >
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Data Type</TableCell>
            <TableCell>No. rows</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {tableData.map((s) => (
            <TableRow key={s.id}>
              <TableCell>{s.name}</TableCell>
              <TableCell>{moment(s.createdAt).format("lll")}</TableCell>
              <TableCell>{formatEnumVal(s.csvRule || "")}</TableCell>
              <TableCell>
                <List>
                  {(s.rows || []).map((r) => (
                    <ListItem key={r.table}>
                      {formatEnumVal(r.table || "")}:{" "}
                      {(r.rows || 0).toLocaleString()}
                    </ListItem>
                  ))}
                </List>
              </TableCell>
              <TableCell sx={{ width: cell1Icon }}>
                <IconButton onClick={() => deleteSessions(s)}>
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </SpacedList>
      <UploaderRulesDialog open={open} setOpen={setOpen} />
    </Box>
  );
}

export default DataManagerPage;
