import { ApiAudience, ApiProject } from "@incendium/api";
import { audienceService } from "Apis";
import { useSharedState } from "./useSharedState";

const fetchAudiences = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const res = await audienceService.audienceServiceListAudiences({
      projectId: selectedProject.id as number,
    });

    return res.results || [];
  }
  return [];
};
export const useAudiences = () => {
  const { state, setState, loading, refetch } = useSharedState(
    "audiences",
    [],
    fetchAudiences
  );

  return {
    audiences: state as ApiAudience[],
    setAudiences: setState,
    loading,
    refetchAudiences: refetch,
  };
};
