/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiLeadLinkFromJSON(json) {
    return ApiLeadLinkFromJSONTyped(json, false);
}
export function ApiLeadLinkFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'leadId': !exists(json, 'leadId') ? undefined : json['leadId'],
    };
}
export function ApiLeadLinkToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'externalId': value.externalId,
        'project': ApiProjectToJSON(value.project),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'projectId': value.projectId,
        'leadId': value.leadId,
    };
}
