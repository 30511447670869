import { ApiTabElementType } from "@incendium/api";
import { Stack, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DragEventHandler } from "react";

interface IElementDragCardProps {
  icon: React.ReactElement;
  title: string;
  type: ApiTabElementType;
  onDragStart: DragEventHandler<HTMLDivElement>;
}

const StyledWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: 10,
}));

function ElementDragCard({
  type,
  title,
  icon,
  onDragStart,
}: IElementDragCardProps) {
  return (
    <StyledWrapper>
      <Stack
        draggable={true}
        onDragStart={onDragStart}
        p={1}
        direction="row"
        spacing={2}
      >
        {icon}
        <Box>
          <Typography color={"white"} variant="body1">
            {title}
          </Typography>
        </Box>
      </Stack>
    </StyledWrapper>
  );
}

export default ElementDragCard;
