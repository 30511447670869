export const INITIALIZE_CLIENTS = "INITIALIZE_CLIENTS";
export const INITIALIZE_PROJECTS = "INITIALIZE_PROJECTS";
export const LOAD_FULL_PROJECT = "LOAD_FULL_PROJECT";
export const ADD_CLIENT = "ADD_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const ADD_PROJECT = "ADD_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const SET_APPLICATION_STATE = "SET_APPLICATION_STATE";
export const FETCHING_STATE = "FETCHING_APPLICATION_STATE";
export const FETCHING_STATE_SUCCESS = "FETCHING_STATE_SUCCESS";
export const FETCHING_STATE_ERROR = "FETCHING_STATE_SUCCESS";
export const SET_CHILDREN_LOADED = "SET_CHILDREN_LOADED";
export const CLEAR = "CLEAR";
