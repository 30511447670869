import { ApiACLRole, ApiProject } from "@incendium/api";
import {
  Button,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory, useParams } from "react-router-dom";
import SpacedList, { SpacedLinkButton } from "Components/UI/SpacedList";
import { useCallback, useState } from "react";
import AccessLevel from "Components/AccessLevel/AccessLevel";
import { ProjectDialog } from "features/project";
import { useConfirmationContext } from "Providers/ConfirmationProvider";
import { cell2Icons } from "consts";

interface IProjectsListProps {
  projects: ApiProject[];
  onClick: () => void;
}

function ProjectsList({ projects, onClick }: IProjectsListProps) {
  const { clientId } = useParams<{ clientId: string }>();
  const [project, setProject] = useState<ApiProject>();
  const [open, setOpen] = useState(false);
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const history = useHistory();

  const onEdit = useCallback((project) => {
    setProject(project);
    setOpen(true);
  }, []);

  const gotoProject = useCallback(
    (project) => {
      history.push(`/clients/${clientId}/projects/${project.id}`);
    },
    [history, clientId]
  );

  const onDelete = useCallback(
    (project) => {
      openConfirmation({
        title: `Cannot delete Project (${project.name})`,
        body: `If you would like this project deleting please contact support.`,
        callback: async () => {
          closeConfirmation();
        },
      });
    },
    [closeConfirmation, openConfirmation]
  );

  return (
    <>
      <SpacedList
        title="Project List"
        action={
          <AccessLevel role={ApiACLRole.PROJECT_ADMIN} write>
            <Button onClick={onClick}>New Project</Button>
          </AccessLevel>
        }
      >
        <TableHead>
          <TableRow>
            <TableCell>Project Name</TableCell>
            <TableCell>Key</TableCell>
            <TableCell>Domains</TableCell>
            <AccessLevel role={ApiACLRole.PROJECT_ADMIN} write>
              <TableCell></TableCell>
            </AccessLevel>
          </TableRow>
        </TableHead>

        <TableBody>
          {projects.map((project) => (
            <TableRow key={project.id}>
              <TableCell>
                <SpacedLinkButton onClick={() => gotoProject(project)}>
                  {project.name}
                </SpacedLinkButton>
              </TableCell>
              <TableCell>{project.key}</TableCell>
              <TableCell>
                {(project.domains || [])
                  .filter((domain) => domain.isPrimary)
                  .map((domain) => (
                    <a
                      key={domain.id}
                      href={domain.url || ""}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Typography variant="body2">{domain.url}</Typography>
                    </a>
                  ))}
              </TableCell>
              <AccessLevel role={ApiACLRole.PROJECT_ADMIN} write>
                <TableCell
                  align="right"
                  color="primary"
                  sx={{ width: cell2Icons }}
                >
                  <IconButton
                    onClick={() => onEdit(project)}
                    size="small"
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => onDelete(project)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </AccessLevel>
            </TableRow>
          ))}
        </TableBody>
      </SpacedList>
      {open && (
        <ProjectDialog project={project} open={open} setOpen={setOpen} />
      )}
    </>
  );
}

export default ProjectsList;
