import { createContext, useContext, useMemo, useState } from "react";
import {
  ApiChannelGroup,
  ApiChannelRule,
  ApiConditionGroup,
  ApiEffectivenessConditionGroup,
  ApiSessionEffectivenessConditionGroup,
} from "@incendium/api";

export interface IChannelRuleOverride extends ApiChannelRule {
  custom: any;
  conversionId: any;
}

export interface IChannelGroupOverride extends ApiChannelGroup {
  conditions: IChannelRuleOverride[];
}

type TConditionGroup =
  | ApiConditionGroup
  | ApiEffectivenessConditionGroup
  | ApiSessionEffectivenessConditionGroup
  | IChannelGroupOverride;

type TConditionGroupContext = {
  conditionGroups: TConditionGroup[];
  setConditionGroups: React.Dispatch<React.SetStateAction<TConditionGroup[]>>;
  rowComponent:
    | ((groupIdx: number, rowIdx: number) => React.ComponentType<any>)
    | undefined;
  setRowComponent: React.Dispatch<
    ((groupIdx: number, rowIdx: number) => React.ComponentType<any>) | undefined
  >;
};

export const ConditionGroupContext = createContext<TConditionGroupContext>({
  conditionGroups: [],
  setConditionGroups: () => [],
  rowComponent: undefined,
  setRowComponent: () => {},
});
export const useConditionGroupContext = () => useContext(ConditionGroupContext);

const ConditionGroupProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [conditionGroups, setConditionGroups] = useState<TConditionGroup[]>([]);
  const [rowComponent, setRowComponent] = useState<
    ((groupIdx: number, rowIdx: number) => React.ComponentType<any>) | undefined
  >(undefined);

  const state = useMemo(
    () => ({
      conditionGroups,
      setConditionGroups,
      rowComponent,
      setRowComponent,
    }),
    [conditionGroups, setConditionGroups, rowComponent, setRowComponent]
  );

  return (
    <ConditionGroupContext.Provider value={state}>
      {children}
    </ConditionGroupContext.Provider>
  );
};

export default ConditionGroupProvider;
