import { ApiCondition, ApiConditionGroup } from "@incendium/api";
import { Box } from "@mui/material";
import ConditionalGroup from "Components/ConditionalGroup/ConditionalGroup";
import { useConditionGroupContext } from "Components/ConditionalGroup/ConditionGroupProvider";
import produce from "immer";
import { useEffect, useMemo } from "react";
import AudienceRule from "./AudienceRule";

const Row = ({ groupIdx, rowIdx }: { groupIdx: number; rowIdx: number }) => {
  const { conditionGroups, setConditionGroups } = useConditionGroupContext();

  const condition = useMemo(() => {
    return (conditionGroups[groupIdx]?.conditions || [])[rowIdx];
  }, [conditionGroups, groupIdx, rowIdx]);

  return (
    <AudienceRule
      allowTraits
      condition={condition as ApiCondition}
      setCondition={(condition) => {
        setConditionGroups(
          produce(conditionGroups, (draft: ApiConditionGroup[]) => {
            (draft[groupIdx]?.conditions || [])[rowIdx] = condition;
          })
        );
      }}
      rule={(condition as ApiCondition)?.classificationRule || {}}
      setRule={(r) => {
        setConditionGroups(
          produce(conditionGroups, (draft: ApiConditionGroup[]) => {
            (draft[groupIdx]?.conditions || [])[rowIdx].classificationRule = r;
          })
        );
      }}
    />
  );
};

function AudienceRules() {
  const { setRowComponent } = useConditionGroupContext();

  useEffect(() => {
    setRowComponent(() => (groupIdx, rowIdx) => {
      return <Row groupIdx={groupIdx} rowIdx={rowIdx} />;
    });
  }, []);

  return (
    <Box>
      <ConditionalGroup />
    </Box>
  );
}

export default AudienceRules;
