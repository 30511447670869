import { IStoreState } from "Interfaces";
import { combineReducers } from "redux";
import { applicationReducer } from "./applicationReducer";
import { authReducer } from "./authReducer";
import { leftMenuReducer } from "./leftMenuReducer";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const authPersistConfig = {
  key: "auth",
  storage,
};

export const rootReducer = combineReducers<IStoreState>({
  auth: persistReducer(authPersistConfig, authReducer) as any,
  application: applicationReducer,
  leftMenu: leftMenuReducer,
});
