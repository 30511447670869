import { ApiMediaType } from "@incendium/api";
import { Grid } from "@mui/material";
import { mediaService } from "Apis";
import Loading from "Components/Loading/Loading";
import { MediaCard } from "features/media";
import { sortByDate } from "Helpers/arrays";
import { useDeleteConfirmation } from "Hooks/useDeleteConfirmation";
import { useMedias } from "Hooks/useMedia";
import produce from "immer";
import { Suspense, useCallback, useMemo } from "react";

function MediaManager() {
  const { medias, setMedias } = useMedias();
  const handleDelete = useDeleteConfirmation();
  const visualMedias = useMemo(
    () =>
      sortByDate(
        medias.filter((m) => [ApiMediaType.IMAGE_MEDIA].includes(m.type!)),
        "createdAt"
      ),
    [medias]
  );
  const onDelete = useCallback(
    async (media) => {
      handleDelete({
        title: `Are you sure you want to delete this media`,
        body: `This action will remove this piece of media and it will no longer be accessible.`,
        callback: async () => {
          await mediaService.mediaServiceDeleteMedia({
            projectId: media.projectId as number,
            mediaId: media.id as number,
          });

          setMedias(
            produce(medias, (draft) => {
              const idx = draft.findIndex((d) => d.id === media.id);
              if (idx >= 0) {
                draft.splice(idx, 1);
              }
            })
          );

          return "Media Deleted";
        },
      });
    },
    [medias, handleDelete, setMedias]
  );

  return (
    <Suspense fallback={<Loading />}>
      <Grid container spacing={3}>
        {visualMedias.map((media) => (
          <Grid item xs={4} key={media.id}>
            <MediaCard media={media} onDelete={onDelete} />
          </Grid>
        ))}
      </Grid>
    </Suspense>
  );
}

export default MediaManager;
