import { ApiSimpleUTM, ApiSyncType, ApiUTMMappingType } from "@incendium/api";
import {
  Cancel,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import {
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Portal,
  Button,
  Typography,
  Stack,
  Box,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import { TypographyHelp } from "Components/UI/TypographyHelp";
import {
  listSources,
  bulkSaveUTMs,
  bulkDeleteUTMs,
  IConsolidator,
  Consolidator,
  ConsolidationList,
} from "features/utms";
import { AnimatePresence, motion } from "framer-motion";
import { groupBy } from "Helpers/arrays";
import { useMount, useNotification, useSelectedProject } from "Hooks";
import { useUTMs } from "Hooks/useUTMs";
import produce from "immer";
import { useCallback, useMemo, useState } from "react";
import { useUpdateEffect } from "react-use";

function TrafficConsolidatePage() {
  const { selectedProject } = useSelectedProject();
  const [checked, setChecked] = useState<string[]>([]);
  const [checkedUTMs, setCheckedUTMs] = useState<ApiSimpleUTM[]>([]);
  const [consolidate, setConsolidate] = useState(false);
  const [hideMerged, setHideMerged] = useState(false);
  const [name, setName] = useState("");
  const [sourceValues, setSourceValues] = useState<string[]>([]);
  const { utms, setUTMs } = useUTMs();
  const { showSuccessNotification, showErrorNotification } = useNotification();

  useMount(() => {
    const fn = async () => {
      let res = await listSources(selectedProject?.id!);
      setSourceValues((res || []).map((r) => r.value!));
    };
    fn();
  });

  const groupedUTMs: IConsolidator[] = useMemo(() => {
    const grouped = groupBy(
      (utms || []).filter(
        (u) => u.type === ApiUTMMappingType.UTM_MAPPING_TYPE_ALIAS_SOURCE
      ),
      "utmSourceAlias"
    );
    return Object.keys(grouped).map((key) => ({
      name: key,
      children: grouped[key],
    }));
  }, [utms]);

  const handleToggle = useCallback(
    (value: string) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    },
    [checked]
  );

  const handleToToggle = useCallback(
    (value: ApiSimpleUTM) => {
      const currentIndex = checkedUTMs.findIndex((u) => u.id === value.id);
      const newChecked = [...checkedUTMs];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setCheckedUTMs(newChecked);
    },
    [checkedUTMs]
  );

  const consolidated = useCallback(
    (source) => {
      const idx = groupedUTMs.findIndex((c) =>
        c.children.map((c) => c.utmSource).includes(source)
      );
      return idx >= 0 ? groupedUTMs[idx] : null;
    },
    [groupedUTMs]
  );

  const sources = useMemo(() => {
    return hideMerged
      ? sourceValues.filter((t) => !consolidated(t))
      : sourceValues;
  }, [sourceValues, hideMerged, consolidated]);

  useUpdateEffect(() => {
    if (checked.length === 0) {
      setConsolidate(false);
    }
  }, [checked]);

  const onRemove = useCallback(async () => {
    const names = checkedUTMs.map((c) => c.utmSource).join(", ");
    try {
      await bulkDeleteUTMs(
        selectedProject!.id!,
        checkedUTMs.map((c) => c.id!)
      );
      setUTMs((utms) =>
        produce(utms, (draft) => {
          checkedUTMs.forEach((c) => {
            const idx = draft.findIndex((d) => d.id === c.id);
            if (idx >= 0) {
              draft.splice(idx, 1);
            }
          });
        })
      );
      showSuccessNotification(`${names} Removed.`);
      setCheckedUTMs([]);
    } catch (error) {
      showErrorNotification(`Error removing ${names}, please try again.`);
    }
  }, [
    checkedUTMs,
    selectedProject,
    setUTMs,
    showSuccessNotification,
    showErrorNotification,
  ]);

  const onConsolidate = useCallback(
    async (name: string) => {
      try {
        const res = await bulkSaveUTMs(
          selectedProject?.id!,
          checked.map((c) => ({
            utmSource: c,
            utmSourceAlias: name,
            type: ApiUTMMappingType.UTM_MAPPING_TYPE_ALIAS_SOURCE,
            syncType: ApiSyncType.UNSPECIFIED,
          }))
        );
        setUTMs((utms) =>
          produce(utms, (draft) => {
            res.forEach((r) => {
              const idx = draft.findIndex((d) => d.id === r!.id);
              if (idx >= 0) {
                draft[idx] = r!;
              } else {
                draft.push(r!);
              }
            });
          })
        );
        setChecked([]);
        setName("");
        showSuccessNotification(
          `${name} Consolidated. We shall start working on this in 30 minutes incase you change your mind.`
        );
      } catch (error) {
        showErrorNotification(`Failed Consolidating ${name}, Please try again`);
      }
    },
    [
      checked,
      selectedProject,
      setUTMs,
      showSuccessNotification,
      showErrorNotification,
    ]
  );

  return (
    <CenterPage calcHeight>
      <Portal container={() => document.getElementById("pageTitle")}>
        <TypographyHelp
          variant="h1"
          text={`${selectedProject!.name} Source Consolidation`}
          placement={"right"}
          tooltip={`Consolidate Sources by selecting from the list and giving an alias`}
        />
      </Portal>
      <Box sx={{ width: "100%", height: "calc(100vh - 550px)" }}>
        <Grid container spacing={4} sx={{ height: "100%" }}>
          <Grid item xs={5} sx={{ height: "100%" }}>
            <List
              sx={{ height: "100%" }}
              subheader={
                <Stack spacing={2} justifyContent={"center"} direction={"row"}>
                  <Typography variant="subtitle1">Sources</Typography>
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={
                      <Switch
                        size="small"
                        checked={hideMerged}
                        onChange={(e, v) => setHideMerged(v)}
                      />
                    }
                    label="Hide merged"
                  />
                </Stack>
              }
            >
              <Typography variant="subtitle2" fontWeight={400} mb={2}>
                Select Source(s) to consolidate into 1.
              </Typography>
              <Box
                sx={{ height: "100%", overflowY: "auto", overflowX: "hidden" }}
              >
                {sources.map((f) => (
                  <ListItem key={f} sx={{ py: 0 }}>
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(f)}
                      disabled={!!consolidated(f)?.name}
                    >
                      <ListItemText
                        id={f}
                        primary={f}
                        secondary={consolidated(f)?.name}
                      />

                      <Checkbox
                        size="small"
                        edge="end"
                        checked={checked.indexOf(f) !== -1}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </Box>
            </List>
          </Grid>
          <Grid item xs={2}>
            <Stack
              mt={20}
              spacing={2}
              justifyContent="center"
              alignItems={"center"}
            >
              <Button
                disabled={checked.length === 0}
                onClick={() => setConsolidate(!consolidate)}
                endIcon={
                  consolidate ? <Cancel /> : <KeyboardDoubleArrowRight />
                }
              >
                {consolidate ? "Cancel" : "Consolidate"}
              </Button>
              <Button
                disabled={checkedUTMs.length === 0}
                onClick={onRemove}
                startIcon={<KeyboardDoubleArrowLeft />}
              >
                Remove
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={5} sx={{ height: "100%" }}>
            <List
              sx={{ height: "100%" }}
              subheader={
                <Typography variant="subtitle1">
                  Consolidated Sources
                </Typography>
              }
            >
              <AnimatePresence mode="wait">
                {consolidate ? (
                  <Box
                    key={"edit"}
                    component={motion.div}
                    initial={{
                      x: -100,
                    }}
                    animate={{ x: 0 }}
                    sx={{ height: "100%" }}
                  >
                    <Typography variant="subtitle2" fontWeight={400} mb={2}>
                      Create New grouping or select existing to consolidate
                      source(s)
                    </Typography>
                    <Consolidator
                      onSubmit={onConsolidate}
                      name={name}
                      setName={setName}
                      items={groupedUTMs}
                      checked={checked}
                    />
                  </Box>
                ) : (
                  <Box
                    key="select"
                    component={motion.div}
                    initial={{
                      x: 100,
                    }}
                    animate={{ x: 0 }}
                    sx={{ height: "100%" }}
                  >
                    <Typography variant="subtitle2" fontWeight={400} mb={2}>
                      Select Sources to remove from groupings
                    </Typography>
                    <Box
                      sx={{
                        height: "100%",
                        overflowY: "auto",
                        overflowX: "hidden",
                      }}
                    >
                      {groupedUTMs.map((t) => (
                        <ConsolidationList
                          item={t}
                          key={t.name}
                          handleToggle={handleToToggle}
                          selectedItems={checkedUTMs}
                        />
                      ))}
                    </Box>
                  </Box>
                )}{" "}
              </AnimatePresence>
            </List>
          </Grid>
        </Grid>
      </Box>
    </CenterPage>
  );
}

export default TrafficConsolidatePage;
