/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiCampaignLeadStatusFromJSON, ApiCampaignLeadStatusToJSON, } from './ApiCampaignLeadStatus';
import { ApiPersonalisationFromJSON, ApiPersonalisationToJSON, } from './ApiPersonalisation';
export function ApiDataPersonalisationFromJSON(json) {
    return ApiDataPersonalisationFromJSONTyped(json, false);
}
export function ApiDataPersonalisationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'dataId': !exists(json, 'dataId') ? undefined : json['dataId'],
        'leadId': !exists(json, 'leadId') ? undefined : json['leadId'],
        'campaignId': !exists(json, 'campaignId') ? undefined : json['campaignId'],
        'personalisationId': !exists(json, 'personalisationId') ? undefined : json['personalisationId'],
        'componentId': !exists(json, 'componentId') ? undefined : json['componentId'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'status': !exists(json, 'status') ? undefined : ApiCampaignLeadStatusFromJSON(json['status']),
        'referrerLeadId': !exists(json, 'referrerLeadId') ? undefined : json['referrerLeadId'],
        'urlId': !exists(json, 'urlId') ? undefined : json['urlId'],
        'personalisation': !exists(json, 'personalisation') ? undefined : ApiPersonalisationFromJSON(json['personalisation']),
        'firstVisit': !exists(json, 'firstVisit') ? undefined : json['firstVisit'],
    };
}
export function ApiDataPersonalisationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'dataId': value.dataId,
        'leadId': value.leadId,
        'campaignId': value.campaignId,
        'personalisationId': value.personalisationId,
        'componentId': value.componentId,
        'projectId': value.projectId,
        'status': ApiCampaignLeadStatusToJSON(value.status),
        'referrerLeadId': value.referrerLeadId,
        'urlId': value.urlId,
        'personalisation': ApiPersonalisationToJSON(value.personalisation),
        'firstVisit': value.firstVisit,
    };
}
