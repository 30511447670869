/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiAttributionClickDateGroupingFromJSON, ApiAttributionClickDateGroupingToJSON, } from './ApiAttributionClickDateGrouping';
import { ApiCSVUploadSessionFromJSON, ApiCSVUploadSessionToJSON, } from './ApiCSVUploadSession';
import { ApiProductTaggerUniqueFieldFromJSON, ApiProductTaggerUniqueFieldToJSON, } from './ApiProductTaggerUniqueField';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiProfitMarginFromJSON(json) {
    return ApiProfitMarginFromJSONTyped(json, false);
}
export function ApiProfitMarginFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'uploadSession': !exists(json, 'uploadSession') ? undefined : ApiCSVUploadSessionFromJSON(json['uploadSession']),
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'uniqueValue': !exists(json, 'uniqueValue') ? undefined : json['uniqueValue'],
        'uniqueField': !exists(json, 'uniqueField') ? undefined : ApiProductTaggerUniqueFieldFromJSON(json['uniqueField']),
        'cost': !exists(json, 'cost') ? undefined : json['cost'],
        'percentage': !exists(json, 'percentage') ? undefined : json['percentage'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'dateGrouping': !exists(json, 'dateGrouping') ? undefined : ApiAttributionClickDateGroupingFromJSON(json['dateGrouping']),
    };
}
export function ApiProfitMarginToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'uploadSession': ApiCSVUploadSessionToJSON(value.uploadSession),
        'project': ApiProjectToJSON(value.project),
        'projectId': value.projectId,
        'uniqueValue': value.uniqueValue,
        'uniqueField': ApiProductTaggerUniqueFieldToJSON(value.uniqueField),
        'cost': value.cost,
        'percentage': value.percentage,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'dateGrouping': ApiAttributionClickDateGroupingToJSON(value.dateGrouping),
    };
}
