import { useSharedState } from "./useSharedState";
import { useSelectedProject } from "./useSelectedProject";
import { CSVService } from "Apis";
import { ApiCSVUploadSession } from "@incendium/api";

export const useCSVSessions = () => {
  const { selectedProject } = useSelectedProject();
  const fetchCSVSessions = async () => {
    if (selectedProject) {
      const CSVSessions = await CSVService.cSVServiceListCSVSessions({
        projectId: selectedProject.id as number,
      });
      return CSVSessions.sessions || [];
    }
    return [];
  };
  const { state, setState, refetch } = useSharedState(
    "CSVSessions",
    [],
    fetchCSVSessions
  );

  return {
    CSVSessions: state as ApiCSVUploadSession[],
    setCSVSessions: setState,
    refetchCSVSessions: refetch,
  };
};
