/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiProductTaggerUniqueFieldFromJSON, ApiProductTaggerUniqueFieldToJSON, } from './ApiProductTaggerUniqueField';
export function ApiProductTaggerPayloadFromJSON(json) {
    return ApiProductTaggerPayloadFromJSONTyped(json, false);
}
export function ApiProductTaggerPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'uniqueField': !exists(json, 'uniqueField') ? undefined : ApiProductTaggerUniqueFieldFromJSON(json['uniqueField']),
        'titleSelector': !exists(json, 'titleSelector') ? undefined : json['titleSelector'],
        'descriptionSelector': !exists(json, 'descriptionSelector') ? undefined : json['descriptionSelector'],
        'priceSelector': !exists(json, 'priceSelector') ? undefined : json['priceSelector'],
        'categorySelector': !exists(json, 'categorySelector') ? undefined : json['categorySelector'],
        'imageUrlSelector': !exists(json, 'imageUrlSelector') ? undefined : json['imageUrlSelector'],
        'productIdSelector': !exists(json, 'productIdSelector') ? undefined : json['productIdSelector'],
        'productSkuSelector': !exists(json, 'productSkuSelector') ? undefined : json['productSkuSelector'],
        'brandSelector': !exists(json, 'brandSelector') ? undefined : json['brandSelector'],
        'useLinkedData': !exists(json, 'useLinkedData') ? undefined : json['useLinkedData'],
    };
}
export function ApiProductTaggerPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'uniqueField': ApiProductTaggerUniqueFieldToJSON(value.uniqueField),
        'titleSelector': value.titleSelector,
        'descriptionSelector': value.descriptionSelector,
        'priceSelector': value.priceSelector,
        'categorySelector': value.categorySelector,
        'imageUrlSelector': value.imageUrlSelector,
        'productIdSelector': value.productIdSelector,
        'productSkuSelector': value.productSkuSelector,
        'brandSelector': value.brandSelector,
        'useLinkedData': value.useLinkedData,
    };
}
