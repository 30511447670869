import { ApiPageviewMetaTagger } from "@incendium/api";
import { pageviewMetaService } from "Apis";

export const savePageviewMeta = async (
  projectId: number,
  locationId: number,
  tagger: ApiPageviewMetaTagger
) => {
  const fn = tagger.id
    ? pageviewMetaService.pageviewMetaServiceUpdatePageviewMetaTagger({
        projectId,
        pageviewMetaId: tagger.id as number,
        payload: {
          reserved: tagger.reserved,
          selector: tagger.selector,
          selectorType: tagger.selectorType,
          value: tagger.value,
          locationId,
        },
      })
    : pageviewMetaService.pageviewMetaServiceCreatePageviewMetaTagger({
        projectId,
        payload: {
          reserved: tagger.reserved,
          selector: tagger.selector,
          selectorType: tagger.selectorType,
          value: tagger.value,
          locationId,
        },
      });
  const res = await fn;
  return res;
};
