/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiMetricFromJSON, ApiMetricToJSON, } from './ApiMetric';
export function ApiAdditionalMetricsListFromJSON(json) {
    return ApiAdditionalMetricsListFromJSONTyped(json, false);
}
export function ApiAdditionalMetricsListFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'key': !exists(json, 'key') ? undefined : ApiMetricFromJSON(json['key']),
        'metrics': !exists(json, 'metrics') ? undefined : (json['metrics'].map(ApiMetricFromJSON)),
    };
}
export function ApiAdditionalMetricsListToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'key': ApiMetricToJSON(value.key),
        'metrics': value.metrics === undefined ? undefined : (value.metrics.map(ApiMetricToJSON)),
    };
}
