import {
  Grid,
  TextField,
  Typography,
  Divider,
  CircularProgress,
  Box,
  Alert,
  Stack,
} from "@mui/material";
import { LocationRules } from "features/location";
import { RuleTypes } from "config";
import { TextHighlight } from "Components/TextHighlight";
import SearchIcon from "Assets/Images/icon-search.svg";
import {
  ApiLocation,
  ApiLocationItem,
  ApiPage,
  LocationItemLocationItemCondition,
  LocationItemLocationItemType,
} from "@incendium/api";
import { AnimatePresence, motion } from "framer-motion";
import Image from "mui-image";
import SavingButton from "Components/UI/SavingButton";
import { StyledSearchResults } from "Components/UI/StyledSearchResults";
import { CenterPage } from "Components/CenterPage/CenterPage";
import { useAddLocationStyles } from "../../../Components/Location/AddaLocation";
import { IRule } from "Interfaces";
import { useLocations, useSelectedProject } from "Hooks";
import { useMemo, useState } from "react";
import { useDebounce, useUpdateEffect } from "react-use";
import { locationService } from "Apis";
import GlassCard from "Components/GlassCard/GlassCard";
import produce from "immer";

function LocationRulesSetup({
  locationId,
  saving,
  saveLocation,
}: {
  locationId: number;
  saving: boolean;
  saveLocation: () => Promise<void>;
}) {
  const {
    caption,
    matchedLocationClass,
    searchWrapperClass,
    imageClass,
    matchedLinksLoading,
    matchedLink,
    rulesWrapper,
  } = useAddLocationStyles();
  const { location, setLocation } = useLocations(locationId);
  const { selectedProject: project } = useSelectedProject();
  const [search, setSearch] = useState("");
  const [debouncedLocation, setDebouncedLocation] = useState({});
  useDebounce(
    () => {
      setDebouncedLocation(location);
    },
    1000,
    [location]
  );

  const [matchingLocations, setMatchingLocations] = useState<ApiPage[]>([]);
  const [pagesLoading, setPagesLoading] = useState(false);

  const updateMatchingLocations = async (location: ApiLocation) => {
    setPagesLoading(true);
    const matchedPages =
      await locationService.locationServiceListLocationMatchingPages({
        projectId: project?.id as number,
        payload: { location },
      });
    if (matchedPages.results) {
      setMatchingLocations(matchedPages.results);
    }
    setPagesLoading(false);
  };

  useUpdateEffect(() => {
    if (location.urlMatch?.trim()) {
      updateMatchingLocations(location);
    }
  }, [debouncedLocation]);

  const addRules = (rule: IRule) => {
    setLocation(
      produce(location, (draft) => {
        draft.items = [
          {
            condition: rule.condition as LocationItemLocationItemCondition,
            value: rule.value,
            type: LocationItemLocationItemType.PATH,
          },
          ...(draft.items || []),
        ];
      })
    );
  };

  const setRules = (rules: ApiLocationItem[]) => {
    setLocation(
      produce(location, (draft) => {
        draft.items = rules;
      })
    );
  };

  const urlmatch =
    location.urlMatch ||
    project?.domains?.filter((d) => d.isPrimary)[0].url ||
    "";

  const filteredLocations = useMemo(() => {
    let pages = matchingLocations.map((ml) => ml.displayPage);
    if (search) {
      pages = (pages || []).filter((p) => p?.includes(search));
    }

    // unique
    pages = [...new Set(pages)];

    const count = pages.length;
    if (pages.length > 8) {
      return { pages: pages.slice(0, 9), count };
    }
    return { pages, count };
  }, [matchingLocations, search]);

  const hasRules = useMemo(() => {
    const validRules = (location.items || []).filter(
      (item) => item.value?.length
    );
    return validRules.length > 0;
  }, [location.items]);

  return (
    <CenterPage calcHeight>
      {location.urlMatch && (
        <>
          <Stack
            alignItems="flex-start"
            alignSelf={"flex-start"}
            component={motion.div}
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            mb={4}
          >
            <Typography variant="h2" mb={2}>
              Add rules
            </Typography>

            <TextHighlight
              options={RuleTypes}
              onSelect={(arg) => {
                addRules({ condition: arg.option, value: arg.text });
              }}
              text={urlmatch}
            />

            <Typography className={caption} variant="body2">
              highlight parts of this URL to generate rules or add them manually
              below
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            spacing={4}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Stack
              alignItems="flex-start"
              component={motion.div}
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              className={rulesWrapper}
            >
              <LocationRules
                rules={location.items || []}
                onChange={setRules}
                location={location}
              />

              <Stack
                mt={4}
                spacing={2}
                sx={{ width: "100%" }}
                alignItems="flex-end"
              >
                <Divider />

                <SavingButton
                  onSave={saveLocation}
                  disableElevation
                  variant="contained"
                  color="primary"
                  saving={saving}
                  size="small"
                  disabled={!hasRules}
                >
                  save and continue
                </SavingButton>
              </Stack>
            </Stack>
            <GlassCard
              className={matchedLocationClass}
              elevation={1}
              boxProps={{ p: 2 }}
              component={motion.div}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.3 }}
            >
              {location.urlMatch &&
                location.items &&
                location.items.length > 0 && (
                  <>
                    <Grid container justifyContent="space-between">
                      <Typography variant="body1">
                        {matchingLocations.length >= 500
                          ? `+499`
                          : matchingLocations.length}{" "}
                        pages matched
                      </Typography>
                      {search && filteredLocations.count > 0 && (
                        <Typography variant="body2" color="secondary">
                          See {filteredLocations.count} pages with "{search}"
                        </Typography>
                      )}
                    </Grid>
                    {(location.items || []).filter(
                      (it) => it.type === LocationItemLocationItemType.PAGEDATA
                    ).length > 0 && (
                      <Box my={2}>
                        <Alert severity="warning">
                          Locations that use Page data cannot be calculated on
                          the fly and therefore will not show matches here.
                        </Alert>
                      </Box>
                    )}

                    <Grid
                      container
                      className={searchWrapperClass}
                      spacing={0}
                      alignItems="center"
                    >
                      <Grid item xs={1}>
                        <Image
                          color="transparent"
                          className={imageClass}
                          src={SearchIcon}
                          alt="Search"
                        />
                      </Grid>
                      <Grid item xs={11}>
                        <TextField
                          label="browse matched locations"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <StyledSearchResults>
                      {pagesLoading && (
                        <AnimatePresence>
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            className={matchedLinksLoading}
                          >
                            <CircularProgress />
                          </motion.div>
                        </AnimatePresence>
                      )}
                      {filteredLocations.pages.map((page, i) => {
                        return (
                          <Typography
                            key={i}
                            className={matchedLink}
                            variant="body2"
                            noWrap
                          >
                            {page}
                          </Typography>
                        );
                      })}
                    </StyledSearchResults>
                    <Box mt={1}>
                      <Typography variant="body2">
                        *note - this is just a subset of matching pages. More
                        pages may match these rules
                      </Typography>
                    </Box>
                  </>
                )}
            </GlassCard>
          </Stack>
        </>
      )}
    </CenterPage>
  );
}

export default LocationRulesSetup;
