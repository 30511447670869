/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiLocationFromJSON, ApiLocationToJSON, } from './ApiLocation';
export function ApiGetProjectsLocationsByPageResponseFromJSON(json) {
    return ApiGetProjectsLocationsByPageResponseFromJSONTyped(json, false);
}
export function ApiGetProjectsLocationsByPageResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'matching': !exists(json, 'matching') ? undefined : (json['matching'].map(ApiLocationFromJSON)),
        'parent': !exists(json, 'parent') ? undefined : (json['parent'].map(ApiLocationFromJSON)),
    };
}
export function ApiGetProjectsLocationsByPageResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'matching': value.matching === undefined ? undefined : (value.matching.map(ApiLocationToJSON)),
        'parent': value.parent === undefined ? undefined : (value.parent.map(ApiLocationToJSON)),
    };
}
