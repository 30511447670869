/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiLeadStatusTypeFromJSON, ApiLeadStatusTypeToJSON, } from './ApiLeadStatusType';
export function ApiLeadStatusPayloadFromJSON(json) {
    return ApiLeadStatusPayloadFromJSONTyped(json, false);
}
export function ApiLeadStatusPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dfKey': !exists(json, 'dfKey') ? undefined : json['dfKey'],
        'status': !exists(json, 'status') ? undefined : ApiLeadStatusTypeFromJSON(json['status']),
        'leadKey': !exists(json, 'leadKey') ? undefined : json['leadKey'],
    };
}
export function ApiLeadStatusPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dfKey': value.dfKey,
        'status': ApiLeadStatusTypeToJSON(value.status),
        'leadKey': value.leadKey,
    };
}
