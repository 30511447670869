/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiDimensionFromJSON, ApiDimensionToJSON, } from './ApiDimension';
import { ApiMetricFromJSON, ApiMetricToJSON, } from './ApiMetric';
import { ApiRowFromJSON, ApiRowToJSON, } from './ApiRow';
export function ApiResultFromJSON(json) {
    return ApiResultFromJSONTyped(json, false);
}
export function ApiResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dimensions': !exists(json, 'dimensions') ? undefined : (json['dimensions'].map(ApiDimensionFromJSON)),
        'metrics': !exists(json, 'metrics') ? undefined : (json['metrics'].map(ApiMetricFromJSON)),
        'rows': !exists(json, 'rows') ? undefined : (json['rows'].map(ApiRowFromJSON)),
        'totalsRow': !exists(json, 'totalsRow') ? undefined : ApiRowFromJSON(json['totalsRow']),
    };
}
export function ApiResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dimensions': value.dimensions === undefined ? undefined : (value.dimensions.map(ApiDimensionToJSON)),
        'metrics': value.metrics === undefined ? undefined : (value.metrics.map(ApiMetricToJSON)),
        'rows': value.rows === undefined ? undefined : (value.rows.map(ApiRowToJSON)),
        'totalsRow': ApiRowToJSON(value.totalsRow),
    };
}
