import { logout } from "Actions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export const Logout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(logout());

    history.push("/");
  }, [dispatch, history]);

  return <div />;
};
