/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiLeadStatusRuleFromJSON, ApiLeadStatusRulePayloadToJSON, ApiListLeadStatusRulesResponseFromJSON, } from '../models';
/**
 *
 */
export class LeadStatusServiceApi extends runtime.BaseAPI {
    /**
     */
    leadStatusServiceCreateLeadStatusRuleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling leadStatusServiceCreateLeadStatusRule.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling leadStatusServiceCreateLeadStatusRule.');
            }
            const queryParameters = {};
            if (requestParameters.leadStatusRuleId !== undefined) {
                queryParameters['leadStatusRuleId'] = requestParameters.leadStatusRuleId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/lead_status/v1/projects/{projectId}/lead_status_rules`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiLeadStatusRulePayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiLeadStatusRuleFromJSON(jsonValue));
        });
    }
    /**
     */
    leadStatusServiceCreateLeadStatusRule(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.leadStatusServiceCreateLeadStatusRuleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    leadStatusServiceDeleteLeadStatusRuleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling leadStatusServiceDeleteLeadStatusRule.');
            }
            if (requestParameters.leadStatusRuleId === null || requestParameters.leadStatusRuleId === undefined) {
                throw new runtime.RequiredError('leadStatusRuleId', 'Required parameter requestParameters.leadStatusRuleId was null or undefined when calling leadStatusServiceDeleteLeadStatusRule.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            if (requestParameters.payloadAsDefault !== undefined) {
                queryParameters['payload.asDefault'] = requestParameters.payloadAsDefault;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/lead_status/v1/projects/{projectId}/lead_status_rules/{leadStatusRuleId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"leadStatusRuleId"}}`, encodeURIComponent(String(requestParameters.leadStatusRuleId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    leadStatusServiceDeleteLeadStatusRule(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.leadStatusServiceDeleteLeadStatusRuleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    leadStatusServiceListLeadStatusRulesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling leadStatusServiceListLeadStatusRules.');
            }
            const queryParameters = {};
            if (requestParameters.leadStatusRuleId !== undefined) {
                queryParameters['leadStatusRuleId'] = requestParameters.leadStatusRuleId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            if (requestParameters.payloadAsDefault !== undefined) {
                queryParameters['payload.asDefault'] = requestParameters.payloadAsDefault;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/lead_status/v1/projects/{projectId}/lead_status_rules`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListLeadStatusRulesResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    leadStatusServiceListLeadStatusRules(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.leadStatusServiceListLeadStatusRulesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    leadStatusServiceReadLeadStatusRuleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling leadStatusServiceReadLeadStatusRule.');
            }
            if (requestParameters.leadStatusRuleId === null || requestParameters.leadStatusRuleId === undefined) {
                throw new runtime.RequiredError('leadStatusRuleId', 'Required parameter requestParameters.leadStatusRuleId was null or undefined when calling leadStatusServiceReadLeadStatusRule.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            if (requestParameters.payloadAsDefault !== undefined) {
                queryParameters['payload.asDefault'] = requestParameters.payloadAsDefault;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/lead_status/v1/projects/{projectId}/lead_status_rules/{leadStatusRuleId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"leadStatusRuleId"}}`, encodeURIComponent(String(requestParameters.leadStatusRuleId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiLeadStatusRuleFromJSON(jsonValue));
        });
    }
    /**
     */
    leadStatusServiceReadLeadStatusRule(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.leadStatusServiceReadLeadStatusRuleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    leadStatusServiceUpdateLeadStatusRuleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling leadStatusServiceUpdateLeadStatusRule.');
            }
            if (requestParameters.leadStatusRuleId === null || requestParameters.leadStatusRuleId === undefined) {
                throw new runtime.RequiredError('leadStatusRuleId', 'Required parameter requestParameters.leadStatusRuleId was null or undefined when calling leadStatusServiceUpdateLeadStatusRule.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling leadStatusServiceUpdateLeadStatusRule.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/lead_status/v1/projects/{projectId}/lead_status_rules/{leadStatusRuleId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"leadStatusRuleId"}}`, encodeURIComponent(String(requestParameters.leadStatusRuleId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiLeadStatusRulePayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiLeadStatusRuleFromJSON(jsonValue));
        });
    }
    /**
     */
    leadStatusServiceUpdateLeadStatusRule(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.leadStatusServiceUpdateLeadStatusRuleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
/**
    * @export
    * @enum {string}
    */
export var LeadStatusServiceDeleteLeadStatusRulePayloadTypeEnum;
(function (LeadStatusServiceDeleteLeadStatusRulePayloadTypeEnum) {
    LeadStatusServiceDeleteLeadStatusRulePayloadTypeEnum["UNASSIGNED"] = "LEAD_STATUS_UNASSIGNED";
    LeadStatusServiceDeleteLeadStatusRulePayloadTypeEnum["PROSPECT"] = "LEAD_STATUS_PROSPECT";
    LeadStatusServiceDeleteLeadStatusRulePayloadTypeEnum["MQL"] = "LEAD_STATUS_MQL";
    LeadStatusServiceDeleteLeadStatusRulePayloadTypeEnum["SQL"] = "LEAD_STATUS_SQL";
    LeadStatusServiceDeleteLeadStatusRulePayloadTypeEnum["SAL"] = "LEAD_STATUS_SAL";
    LeadStatusServiceDeleteLeadStatusRulePayloadTypeEnum["CUSTOMER"] = "LEAD_STATUS_CUSTOMER";
})(LeadStatusServiceDeleteLeadStatusRulePayloadTypeEnum || (LeadStatusServiceDeleteLeadStatusRulePayloadTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LeadStatusServiceListLeadStatusRulesPayloadTypeEnum;
(function (LeadStatusServiceListLeadStatusRulesPayloadTypeEnum) {
    LeadStatusServiceListLeadStatusRulesPayloadTypeEnum["UNASSIGNED"] = "LEAD_STATUS_UNASSIGNED";
    LeadStatusServiceListLeadStatusRulesPayloadTypeEnum["PROSPECT"] = "LEAD_STATUS_PROSPECT";
    LeadStatusServiceListLeadStatusRulesPayloadTypeEnum["MQL"] = "LEAD_STATUS_MQL";
    LeadStatusServiceListLeadStatusRulesPayloadTypeEnum["SQL"] = "LEAD_STATUS_SQL";
    LeadStatusServiceListLeadStatusRulesPayloadTypeEnum["SAL"] = "LEAD_STATUS_SAL";
    LeadStatusServiceListLeadStatusRulesPayloadTypeEnum["CUSTOMER"] = "LEAD_STATUS_CUSTOMER";
})(LeadStatusServiceListLeadStatusRulesPayloadTypeEnum || (LeadStatusServiceListLeadStatusRulesPayloadTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LeadStatusServiceReadLeadStatusRulePayloadTypeEnum;
(function (LeadStatusServiceReadLeadStatusRulePayloadTypeEnum) {
    LeadStatusServiceReadLeadStatusRulePayloadTypeEnum["UNASSIGNED"] = "LEAD_STATUS_UNASSIGNED";
    LeadStatusServiceReadLeadStatusRulePayloadTypeEnum["PROSPECT"] = "LEAD_STATUS_PROSPECT";
    LeadStatusServiceReadLeadStatusRulePayloadTypeEnum["MQL"] = "LEAD_STATUS_MQL";
    LeadStatusServiceReadLeadStatusRulePayloadTypeEnum["SQL"] = "LEAD_STATUS_SQL";
    LeadStatusServiceReadLeadStatusRulePayloadTypeEnum["SAL"] = "LEAD_STATUS_SAL";
    LeadStatusServiceReadLeadStatusRulePayloadTypeEnum["CUSTOMER"] = "LEAD_STATUS_CUSTOMER";
})(LeadStatusServiceReadLeadStatusRulePayloadTypeEnum || (LeadStatusServiceReadLeadStatusRulePayloadTypeEnum = {}));
