import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Button,
  ButtonProps,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  Grid,
  Popper,
} from "@mui/material";
import GlassCard from "Components/GlassCard/GlassCard";
import { useAudiences } from "Hooks/useAudiences";
import produce from "immer";
import { useState } from "react";

interface IAudiencePickerProps {
  audienceNames: string[];
  setAudienceNames: (s: string[]) => void;
  btnProps?: ButtonProps;
}

function AudiencePicker({
  audienceNames,
  setAudienceNames,
  btnProps,
}: IAudiencePickerProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const { audiences } = useAudiences();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        {...btnProps}
      >
        Pick Audiences
      </Button>
      <Popper placement="bottom-end" open={open} anchorEl={anchorEl}>
        <GlassCard
          sx={{
            width: 500,
          }}
          boxProps={{
            p: 2,
          }}
        >
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Grid container spacing={1}>
              {audiences.map((a) => (
                <Grid item xs={6} key={a.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => {
                          setAudienceNames(
                            produce(audienceNames, (draft) => {
                              const idx = draft.findIndex(
                                (d) => d === a.name || ""
                              );
                              if (idx < 0) {
                                draft.push(a.name || "");
                              } else {
                                draft.splice(idx, 1);
                              }
                            })
                          );
                        }}
                        checked={
                          a.name !== "" && audienceNames.includes(a.name || "")
                        }
                      />
                    }
                    label={a.name || ""}
                  />
                </Grid>
              ))}
            </Grid>
          </ClickAwayListener>
        </GlassCard>
      </Popper>
    </>
  );
}

export default AudiencePicker;
