import { Typography } from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import Loading from "Components/Loading/Loading";
import { TabWrapper } from "Components/UI/TabContainer";
import { enumToArray } from "Helpers/enumToText";
import { useProducts } from "Hooks/useProducts";
import EcomInsights from "Pages/Analyse/EcomInsights/EcomInsights";
import MetricExplorer from "Pages/Analyse/EcomInsights/MetricExplorer";
import NewCustomerInsights from "Pages/Analyse/EcomInsights/NewCustomerInsights";
import PaidCampaignInsights from "Pages/Analyse/EcomInsights/PaidCampaignInsights";
import ProductInsights from "Pages/Analyse/EcomInsights/ProductInsights";
import ReturningCustomerInsights from "Pages/Analyse/EcomInsights/ReturningCustomerInsights";
import TabbedAnalysesPage from "Pages/Analyse/TabbedAnalysesPage";
import { useCallback, useMemo, useState } from "react";
import { Redirect, useParams } from "react-router-dom";

enum EcomInsight {
  overview = "Overview",
  new_customers = "New Customers",
  returning_customers = "Returning Customers",
  products = "Products",
  campaigns = "Campaigns",
  metric_explorer = "Metric Explorer",
}

function EcomInsightsPage() {
  const { insight } = useParams<{ insight: string }>();
  const [activeInsight, setActiveInsight] = useState(insight);
  const { products, loading } = useProducts();

  const tabs = useMemo(() => {
    if (products && products.length > 0) {
      return enumToArray(EcomInsight);
    }
    return enumToArray(EcomInsight).filter((f) => f !== EcomInsight.products);
  }, [products]);

  const renderSwitch = useCallback(() => {
    switch (activeInsight) {
      case EcomInsight.overview.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <EcomInsights />
          </TabWrapper>
        );
      case EcomInsight.new_customers.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <NewCustomerInsights />
          </TabWrapper>
        );
      case EcomInsight.returning_customers.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <ReturningCustomerInsights />
          </TabWrapper>
        );
      case EcomInsight.campaigns.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <PaidCampaignInsights />
          </TabWrapper>
        );
      case EcomInsight.products.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <ProductInsights />
          </TabWrapper>
        );
      case EcomInsight.metric_explorer.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <MetricExplorer />
          </TabWrapper>
        );

      default:
        return (
          <TabWrapper>
            <CenterPage calcHeight>
              <Typography variant="subtitle1">Not Implemented</Typography>
            </CenterPage>
          </TabWrapper>
        );
    }
  }, [activeInsight]);

  if (!insight || insight === "") {
    return <Redirect to={`./quick-insights-ecom/overview`} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <TabbedAnalysesPage
      title="Ecom Insights"
      subTitle={
        activeInsight ===
        EcomInsight.metric_explorer.toLowerCase().replace(" ", "_")
          ? "Select Metric from the Right to Start Exploring"
          : undefined
      }
      activeTab={activeInsight}
      setActiveTab={setActiveInsight}
      tabs={tabs}
      renderSwitch={renderSwitch}
    />
  );
}

export default EcomInsightsPage;
