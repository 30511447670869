import { useTheme, alpha, Box } from "@mui/material";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  ResponsiveContainer,
  Bar,
  ComposedChart,
  Area,
  Legend,
  Tooltip,
  Cell,
  ReferenceLine,
} from "recharts";
import { useMemo, useCallback, useState, memo } from "react";
import { useXHeight, useYWidth } from "Hooks/useAnalyticsAxisDimensions";
import { ApiMetric, ApiYAxisChartType } from "@incendium/api";
import {
  ChartLegendWrapper,
  formatDimension,
  formatMetric,
  mergeComparisonData,
} from "features/analytics";
import { AnalyticsCardCustomTooltip } from "features/analytics";
import { useAnalyticsContext } from "Providers/AnalyticsProvider";
import { IStyledChartProps, TChartData } from "Interfaces";
import ChartModel from "Models/Chart.model";
import NoData from "Components/NoData";
import { formatNumber } from "Helpers/numbers";
import { truncate } from "Helpers/truncate";
import { DIMENSION_TRUNCATE } from "features/analytics/constants";

export const defaultXProps = {
  angle: -60,
  tickMargin: 8,
  textAnchor: "end",
  minTickGap: 0,
  fontSize: 11,
  tickCount: 20,
};
export const defaultYProps = {
  allowDataOverflow: true,
  tickCount: 6,
  tickLine: false,
  tickMargin: 8,
  fontSize: 11,
};

function StyledComposedChart({
  data,
  children,
  yAxisProps,
  yAxisKeys,
  xAxisProps,
  height,
  aspect,
  noAnimation,
  noLegend,
  noReOrder,
  showTooltip,
  fills,
  showReferenceLineKey,
  tooltipProps,
  metricsOrder,
  layout,
  comparismentChart,
  dimensions,
  comparisonWindowDays,
  noAxis,
}: IStyledChartProps) {
  // todo, this should be outside of this component
  const chartModel = useMemo(
    () =>
      new ChartModel({
        yAxisKeys: yAxisKeys || [],
        attributes: [], // attributes not passed to this component, should be there when we move outside
      }),
    [yAxisKeys]
  );

  const { colourMap } = useAnalyticsContext();
  const theme = useTheme();
  const xHeight = useXHeight(
    data,
    {
      ...defaultXProps,
      ...xAxisProps,
    },
    layout
  );
  const yWidths = useYWidth(data, yAxisKeys, yAxisProps);
  const [refCurrent, setRefCurrent] = useState<any>(null);
  const ref = useCallback((node) => {
    if (node !== null) {
      setRefCurrent(node);
    }
  }, []);

  const chartDataKeys = useMemo(() => {
    return chartModel.getChartDataKeys(data, metricsOrder, noReOrder);
  }, [data, noReOrder, metricsOrder, chartModel]);

  const xInterval = useMemo(() => {
    if (!refCurrent || !data.length) {
      return 0;
    }
    const clientWidth = refCurrent?.current?.offsetWidth || 0;
    const totalYWidths = yWidths.reduce((p, v) => p + v, 0);
    const xWidth = clientWidth - totalYWidths;
    let i = 0;
    let tickWidths = (data.length / (i + 1)) * 25;
    if (xWidth < 0) {
      return 0;
    }
    while (tickWidths > xWidth) {
      i++;
      tickWidths = (data.length / (i + 1)) * 25;
    }
    return i;
  }, [refCurrent, data, yWidths]);

  const renderLines = useCallback(
    (data: TChartData[]) => {
      if (!data.length) {
        return null;
      }

      const styles = chartModel.getLinesStyles(
        data,
        colourMap,
        metricsOrder,
        true,
        fills,
        layout
      );

      return styles.map((style, i) => {
        const { key, id, stackId, fill, lineType, radius, cells } = style;
        switch (lineType as ApiYAxisChartType) {
          case ApiYAxisChartType.BAR:
            return (
              <Bar
                yAxisId={id}
                key={i}
                dataKey={key}
                fill={fill}
                radius={radius}
                stackId={stackId}
                isAnimationActive={!noAnimation}
              >
                {cells.map(({ key, fill, stroke }, index) => (
                  <Cell key={key} fill={fill} strokeWidth={2} stroke={stroke} />
                ))}
              </Bar>
            );
          case ApiYAxisChartType.AREA:
            return (
              <Area
                yAxisId={id}
                key={i}
                dataKey={key}
                fill={fill}
                stroke={fill}
                strokeWidth={2}
                fillOpacity={0.6}
                dot={false}
                stackId={stackId}
                isAnimationActive={!noAnimation}
              >
                {cells.map(({ key, fill, stroke }, index) => (
                  <Cell key={key} fill={fill} />
                ))}
              </Area>
            );

          case ApiYAxisChartType.DOT:
            return (
              <Line
                yAxisId={id}
                key={i}
                dataKey={key}
                strokeWidth={0}
                stroke={fill}
                dot={{ stroke: "white", fill, strokeWidth: 2, r: 5 }}
                activeDot={{
                  stroke: fill,
                  fill: "white",
                  strokeWidth: 2,
                  r: 5,
                }}
                isAnimationActive={false}
                type="monotoneX"
              >
                {cells.map(({ key, fill, stroke }, index) => (
                  <Cell key={key} fill={fill} />
                ))}
              </Line>
            );
          default:
            return (
              <Line
                yAxisId={id}
                key={i}
                dataKey={key}
                stroke={fill}
                strokeWidth={3}
                dot={false}
                isAnimationActive={false}
                type="monotoneX"
              >
                {cells.map(({ key, fill, stroke }, index) => (
                  <Cell key={key} fill={fill} />
                ))}
              </Line>
            );
        }
      });
    },
    [colourMap, chartModel, fills, layout, metricsOrder, noAnimation]
  );

  const renderYAxis = useCallback(() => {
    if (!data.length) {
      return null;
    }

    if (!yAxisKeys) {
      return (
        <YAxis
          {...defaultYProps}
          tickLine={
            layout === "vertical"
              ? {
                  stroke: theme.palette.primary.dark,
                }
              : undefined
          }
          dataKey={layout === "vertical" ? "name" : undefined}
          type={layout === "vertical" ? "category" : "number"}
          stroke={theme.palette.text.primary}
          axisLine={{ stroke: theme.palette.primary.dark, strokeWidth: 2 }}
          domain={
            yAxisProps && yAxisProps[0].domain
              ? yAxisProps[0].domain
              : ["dataMin", "dataMax"]
          }
          scale="linear"
          width={yWidths[0]}
          tickFormatter={
            yAxisProps && yAxisProps[0].tickFormatter
              ? yAxisProps[0].tickFormatter
              : (v) => {
                  return formatNumber(v) as string;
                }
          }
          {...(yAxisProps ? yAxisProps[0] : {})}
        />
      );
    }
    return yAxisKeys.map((y, i) => {
      const k = typeof y === "object" ? y.key : y;

      return (
        <YAxis
          key={k}
          orientation={i === 0 ? "left" : "right"}
          yAxisId={`${k}-y`}
          {...defaultYProps}
          tickLine={
            layout === "vertical"
              ? {
                  stroke: theme.palette.primary.dark,
                }
              : undefined
          }
          dataKey={layout === "vertical" ? "name" : undefined}
          type={layout === "vertical" ? "category" : "number"}
          axisLine={{ stroke: theme.palette.primary.dark, strokeWidth: 2 }}
          interval={"preserveStart"}
          domain={
            yAxisProps && yAxisProps[i] && yAxisProps[i].domain
              ? yAxisProps[i].domain
              : [
                  (dataMin: number) => {
                    return dataMin < 0 ? dataMin * 1.2 : 0;
                  },
                  (dataMax: number) => {
                    return dataMax * 1.2;
                  },
                ]
          }
          scale={layout === "vertical" ? "auto" : "linear"}
          width={layout === "vertical" ? xHeight : yWidths[i]}
          tickFormatter={
            yAxisProps && yAxisProps[i] && yAxisProps[i].tickFormatter
              ? yAxisProps[0].tickFormatter
              : (v, k) => {
                  if (layout === "vertical") {
                    if (dimensions) {
                      return formatDimension(dimensions[0], v);
                    }
                    return v;
                  }

                  const metric =
                    typeof y === "object" ? `${(y.fields || [])[0]}` : "";
                  return formatMetric(metric.split("\\").pop() as ApiMetric, v);
                }
          }
          {...(yAxisProps ? yAxisProps[i] : {})}
        />
      );
    });
  }, [
    data,
    theme,
    yAxisProps,
    yAxisKeys,
    yWidths,
    layout,
    xHeight,
    dimensions,
  ]);

  const mergedData = useMemo(() => {
    if (!comparismentChart?.data) {
      return data;
    }
    return mergeComparisonData(
      data,
      comparismentChart?.data,
      comparisonWindowDays
    );
  }, [data, comparismentChart?.data, comparisonWindowDays]);

  const total = useMemo(() => {
    return mergedData.reduce((agg, d) => {
      const { name, ...rest } = d;
      let t = 0;
      for (const key in rest) {
        t = t + Number(rest[key]);
      }
      return agg + t;
    }, 0);
  }, [mergedData]);

  if (total === 0) {
    return <NoData />;
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        "& .recharts-wrapper": {
          position: "absolute!important", // hack fix for revharts not resizing
        },
      }}
    >
      <ResponsiveContainer
        height={height ? height : aspect ? undefined : "100%"}
        width={"100%"}
        aspect={aspect}
        debounce={1}
        ref={ref}
      >
        <ComposedChart
          data={mergedData}
          barGap={6}
          maxBarSize={45}
          layout={layout}
        >
          {!noAxis && (
            <CartesianGrid
              vertical={layout === "vertical"}
              horizontal={layout !== "vertical"}
              strokeDasharray="6"
              stroke={alpha(theme.palette.info.main, 0.5)}
            />
          )}

          {children}
          {renderLines(mergedData)}
          {showReferenceLineKey && data.length > 0 && (
            <ReferenceLine
              yAxisId={`${showReferenceLineKey}-y`}
              y={0}
              stroke={theme.palette.text.primary}
              strokeWidth={2}
            />
          )}
          {showTooltip && (
            <Tooltip
              cursor={{
                strokeDasharray: "3 3",
                stroke: theme.palette.info.main,
              }}
              content={
                <AnalyticsCardCustomTooltip fills={fills} {...tooltipProps} />
              }
            />
          )}
          {!noLegend && (
            <Legend
              verticalAlign="top"
              align="left"
              wrapperStyle={{
                width: "auto",
              }}
              content={({ payload }) => (
                <ChartLegendWrapper colourMap={colourMap} payload={payload} />
              )}
            />
          )}

          {!noAxis && (
            <XAxis
              axisLine={{ stroke: theme.palette.primary.dark, strokeWidth: 2 }}
              dataKey={layout === "vertical" ? undefined : "name"}
              type={layout === "vertical" ? "number" : "category"}
              interval={xInterval}
              height={layout === "vertical" ? 40 : xHeight}
              {...defaultXProps}
              {...xAxisProps}
              tickLine={
                layout !== "vertical"
                  ? {
                      stroke: theme.palette.primary.dark,
                    }
                  : undefined
              }
              tickFormatter={(v, k) => {
                if (layout === "vertical") {
                  return formatMetric(
                    chartDataKeys[0].split("\\").pop() as ApiMetric,
                    v
                  );
                }

                if (xAxisProps?.tickFormatter) {
                  return xAxisProps?.tickFormatter(v, k);
                }

                if (dimensions) {
                  return formatDimension(
                    dimensions[0],
                    truncate(v, DIMENSION_TRUNCATE)
                  );
                }
                return truncate(v, DIMENSION_TRUNCATE);
              }}
            />
          )}
          {!noAxis && renderYAxis()}
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default memo(StyledComposedChart);
