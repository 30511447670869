/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiUserActionFromJSON, ApiUserActionToJSON, } from './ApiUserAction';
import { ApiUserActionUserFromJSON, ApiUserActionUserToJSON, } from './ApiUserActionUser';
export function ApiGetUserJourneyResponseFromJSON(json) {
    return ApiGetUserJourneyResponseFromJSONTyped(json, false);
}
export function ApiGetUserJourneyResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'user': !exists(json, 'user') ? undefined : ApiUserActionUserFromJSON(json['user']),
        'actions': !exists(json, 'actions') ? undefined : (json['actions'].map(ApiUserActionFromJSON)),
    };
}
export function ApiGetUserJourneyResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'user': ApiUserActionUserToJSON(value.user),
        'actions': value.actions === undefined ? undefined : (value.actions.map(ApiUserActionToJSON)),
    };
}
