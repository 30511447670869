import { useMemo, useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { usePrevious } from "react-use";

interface IUsePaginationParams {
  limit?: number;
  initialPage?: number;
  useParams?: boolean;
}

const usePagination = ({
  limit,
  useParams,
  initialPage: paramInitialPage,
}: IUsePaginationParams) => {
  const location = useLocation();

  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );
  const initialPage =
    paramInitialPage ||
    (useParams && params.get("page")
      ? parseInt(params.get("page") as string, 10)
      : 1);

  const [page, setPage] = useState(initialPage);
  const prvPage = usePrevious(page);
  const [responseToken, setReponseToken] = useState(""); // this is the token usually from the api, it will help us set total number of pages
  const [totalRecords, setTotalRecords] = useState(0);
  const [token, setToken] = useState("");

  useEffect(() => {
    if (!useParams) {
      return;
    }
    const newParams = new URLSearchParams(location.search);
    newParams.set("page", page.toString());

    // Use replaceState to update the URL without causing a re-render
    const newUrl = `${location.pathname}?${newParams.toString()}`;
    window.history.replaceState({}, "", newUrl);
  }, [page, location.search, location.pathname, useParams]);

  useEffect(() => {
    if (responseToken === "") {
      setTotalRecords(20);
      return;
    }

    const decodedBuffer = Buffer.from(responseToken, "base64");
    const decodedString = decodedBuffer.toString("utf-8"); // decoded string will be in format of {offset|limit|total}
    const parts = decodedString.split("|");

    setTotalRecords(Number(parts[2]));
  }, [responseToken]);

  const totalPages = useMemo(() => {
    return Math.floor(totalRecords / (limit || 20));
  }, [totalRecords, limit]);

  useEffect(() => {
    if (page === prvPage) {
      return;
    }

    setToken(
      btoa(`${(page - 1) * (limit || 20)}|${limit}|${totalRecords}`).replaceAll(
        "=",
        ""
      )
    );
  }, [limit, page, totalRecords, prvPage]);

  const resetPagination = useCallback(() => {
    setPage(1);
    setReponseToken("");
    setTotalRecords(0);
  }, []);

  return { token, totalPages, page, setPage, setReponseToken, resetPagination };
};

export default usePagination;
