/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiMediaCSVHeaderFieldFromJSON, ApiMediaCSVHeaderFieldToJSON, } from './ApiMediaCSVHeaderField';
export function ApiMediaCSVFromJSON(json) {
    return ApiMediaCSVFromJSONTyped(json, false);
}
export function ApiMediaCSVFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rows': !exists(json, 'rows') ? undefined : (json['rows'].map(ApiMediaCSVHeaderFieldFromJSON)),
        'headers': !exists(json, 'headers') ? undefined : json['headers'],
        'mappedHeaders': !exists(json, 'mappedHeaders') ? undefined : json['mappedHeaders'],
        'ignoreHeaders': !exists(json, 'ignoreHeaders') ? undefined : json['ignoreHeaders'],
        'mappedRows': !exists(json, 'mappedRows') ? undefined : (json['mappedRows'].map(ApiMediaCSVHeaderFieldFromJSON)),
        'numberOfValidRows': !exists(json, 'numberOfValidRows') ? undefined : json['numberOfValidRows'],
        'numberOfInvalidRows': !exists(json, 'numberOfInvalidRows') ? undefined : json['numberOfInvalidRows'],
    };
}
export function ApiMediaCSVToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'rows': value.rows === undefined ? undefined : (value.rows.map(ApiMediaCSVHeaderFieldToJSON)),
        'headers': value.headers,
        'mappedHeaders': value.mappedHeaders,
        'ignoreHeaders': value.ignoreHeaders,
        'mappedRows': value.mappedRows === undefined ? undefined : (value.mappedRows.map(ApiMediaCSVHeaderFieldToJSON)),
        'numberOfValidRows': value.numberOfValidRows,
        'numberOfInvalidRows': value.numberOfInvalidRows,
    };
}
