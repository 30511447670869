/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiPageFromJSON, ApiPageToJSON, } from './ApiPage';
export function ApiListPagesByLocationResponseFromJSON(json) {
    return ApiListPagesByLocationResponseFromJSONTyped(json, false);
}
export function ApiListPagesByLocationResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'results': !exists(json, 'results') ? undefined : (json['results'].map(ApiPageFromJSON)),
    };
}
export function ApiListPagesByLocationResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'results': value.results === undefined ? undefined : (value.results.map(ApiPageToJSON)),
    };
}
