import { useSelector } from "react-redux";
import { audienceSelector } from "Selectors/audienceSelector";
import { useClientIdFromUrl } from "./useClientIdFromUrl";
import { useProjectIdFromUrl } from "./useProjectIdFromUrl";
import { useMatchPath } from "Hooks/useMatchPath";

const useAudiencePath = () => {
  return useMatchPath<{
    clientId: string;
    projectId: string;
    audienceid: string;
  }>("/clients/:clientId/projects/:projectId/audience/audiences/:audienceid");
};

const useAudienceIdFromUrl = () => {
  const path = useAudiencePath();
  return path?.params.audienceid;
};

export const useAudienceSelector = () => {
  const clientId = useClientIdFromUrl();
  const projectId = useProjectIdFromUrl();
  const audienceId = useAudienceIdFromUrl();

  const audience = useSelector(
    audienceSelector(clientId, projectId, audienceId)
  );
  return audience;
};
