import { locationService } from "Apis";
import { useSharedState } from "./useSharedState";
import { ApiLocationPageTagger } from "@incendium/api";
import { useSelectedProject } from "./useSelectedProject";

export const usePageTaggers = () => {
  const { selectedProject } = useSelectedProject();

  const fetchPageTaggers = async () => {
    if (selectedProject) {
      const res =
        await locationService.locationServiceListAllLocationPageTaggers({
          projectId: selectedProject.id as number,
          includeValues: true,
        });
      return res.results || [];
    }
    return [];
  };
  const { state, setState, refetch } = useSharedState(
    "pageTaggers",
    [],
    fetchPageTaggers
  );

  return {
    pageTaggers: state as ApiLocationPageTagger[],
    setPageTaggers: setState,
    refetchPageTaggers: refetch,
  };
};
