import { Box, ClickAwayListener, Theme, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useState } from "react";

type TStyleProps = {
  width?: number;
};

const useClickableTooltipStyles = makeStyles<Theme, TStyleProps>({
  wrapper: {
    display: "inline-block",
  },
  root: (props) => ({
    maxWidth: props.width || 216,
    "& > *:not(.MuiTooltip-arrow)": {
      color: "white",
    },
  }),
});

interface IClickableTooltip {
  icon: React.ReactElement;
  text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal;
  width?: number;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  onHover?: boolean;
}

export const ClickableTooltip = ({
  icon,
  text,
  width,
  placement = "left",
  onHover,
}: IClickableTooltip) => {
  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const { root, wrapper } = useClickableTooltipStyles({ width });
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div className={wrapper}>
        <Tooltip
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
          placement={placement}
          title={text}
          classes={{ tooltip: root }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={(e) => {
              if (onHover) {
                return;
              }
              e.stopPropagation();
              setOpen(!open);
            }}
            onMouseEnter={() => onHover && setOpen(true)}
            onMouseLeave={() => onHover && setOpen(false)}
          >
            {icon}
          </Box>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};
