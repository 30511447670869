/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiCampaignFromJSON, ApiCampaignToJSON, } from './ApiCampaign';
import { ApiCampaignLeadStatusFromJSON, ApiCampaignLeadStatusToJSON, } from './ApiCampaignLeadStatus';
export function ApiCampaignLeadFromJSON(json) {
    return ApiCampaignLeadFromJSONTyped(json, false);
}
export function ApiCampaignLeadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'campaignId': !exists(json, 'campaignId') ? undefined : json['campaignId'],
        'leadId': !exists(json, 'leadId') ? undefined : json['leadId'],
        'status': !exists(json, 'status') ? undefined : ApiCampaignLeadStatusFromJSON(json['status']),
        'campaign': !exists(json, 'campaign') ? undefined : ApiCampaignFromJSON(json['campaign']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'urlId': !exists(json, 'urlId') ? undefined : json['urlId'],
    };
}
export function ApiCampaignLeadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'campaignId': value.campaignId,
        'leadId': value.leadId,
        'status': ApiCampaignLeadStatusToJSON(value.status),
        'campaign': ApiCampaignToJSON(value.campaign),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'urlId': value.urlId,
    };
}
