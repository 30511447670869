import { ApiAction } from "@incendium/api";
import { IconButton } from "@mui/material";
import { AddCircle, Delete, Edit } from "@mui/icons-material";
import { Tag } from "Components/Tag";

export const FormActionList = ({
  actions,
  onAdd,
  onEdit,
  onDelete,
}: {
  actions: ApiAction[];
  onAdd: () => void;
  onEdit: (action: ApiAction) => void;
  onDelete: (action: ApiAction) => void;
}) => {
  return (
    <>
      <Tag
        text="add new action"
        color="primary"
        icons={
          <>
            <IconButton onClick={onAdd} size="small">
              <AddCircle color="primary" />
            </IconButton>
          </>
        }
      />
      {actions.map((action) => {
        return (
          <Tag
            key={action.id}
            text={`${action.type?.toLowerCase()}: ${
              action.target
                ? (action.target as string)
                : (action.targetField as string)
            }`}
            color="secondary"
            icons={
              <>
                <IconButton onClick={() => onEdit(action)} size="small">
                  <Edit color="primary" />
                </IconButton>
                <IconButton onClick={() => onDelete(action)} size="small">
                  <Delete color="secondary" />
                </IconButton>
              </>
            }
          />
        );
      })}
    </>
  );
};
