/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiClassificationItemFromJSON, ApiClassificationItemToJSON, } from './ApiClassificationItem';
import { ApiClassificationRulePeriodTypeFromJSON, ApiClassificationRulePeriodTypeToJSON, } from './ApiClassificationRulePeriodType';
import { ApiClassificationVariableFromJSON, ApiClassificationVariableToJSON, } from './ApiClassificationVariable';
import { ApiConditionFromJSON, ApiConditionToJSON, } from './ApiCondition';
import { ApiConversionFromJSON, ApiConversionToJSON, } from './ApiConversion';
import { ApiOperatorFromJSON, ApiOperatorToJSON, } from './ApiOperator';
export function ApiClassificationRuleFromJSON(json) {
    return ApiClassificationRuleFromJSONTyped(json, false);
}
export function ApiClassificationRuleFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'variable': !exists(json, 'variable') ? undefined : ApiClassificationVariableFromJSON(json['variable']),
        'oldCondition': !exists(json, 'oldCondition') ? undefined : json['oldCondition'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'classificationItem': !exists(json, 'classificationItem') ? undefined : ApiClassificationItemFromJSON(json['classificationItem']),
        'key': !exists(json, 'key') ? undefined : json['key'],
        'conversion': !exists(json, 'conversion') ? undefined : ApiConversionFromJSON(json['conversion']),
        'conversionId': !exists(json, 'conversionId') ? undefined : json['conversionId'],
        'operator': !exists(json, 'operator') ? undefined : ApiOperatorFromJSON(json['operator']),
        'conditionId': !exists(json, 'conditionId') ? undefined : json['conditionId'],
        'periodType': !exists(json, 'periodType') ? undefined : ApiClassificationRulePeriodTypeFromJSON(json['periodType']),
        'period': !exists(json, 'period') ? undefined : json['period'],
        'locationPageTaggerId': !exists(json, 'locationPageTaggerId') ? undefined : json['locationPageTaggerId'],
        'pageTagValue': !exists(json, 'pageTagValue') ? undefined : json['pageTagValue'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'condition': !exists(json, 'condition') ? undefined : ApiConditionFromJSON(json['condition']),
        'leadStatusId': !exists(json, 'leadStatusId') ? undefined : json['leadStatusId'],
    };
}
export function ApiClassificationRuleToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'variable': ApiClassificationVariableToJSON(value.variable),
        'oldCondition': value.oldCondition,
        'value': value.value,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'classificationItem': ApiClassificationItemToJSON(value.classificationItem),
        'key': value.key,
        'conversion': ApiConversionToJSON(value.conversion),
        'conversionId': value.conversionId,
        'operator': ApiOperatorToJSON(value.operator),
        'conditionId': value.conditionId,
        'periodType': ApiClassificationRulePeriodTypeToJSON(value.periodType),
        'period': value.period,
        'locationPageTaggerId': value.locationPageTaggerId,
        'pageTagValue': value.pageTagValue,
        'productId': value.productId,
        'locationId': value.locationId,
        'condition': ApiConditionToJSON(value.condition),
        'leadStatusId': value.leadStatusId,
    };
}
