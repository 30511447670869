import { Autocomplete, OutlinedTextFieldProps, TextField } from "@mui/material";
import { useLeadStatusRules } from "Hooks/useLeadStatusRules";
import { useMemo } from "react";

interface ILeadStatusDropdownProps
  extends Omit<OutlinedTextFieldProps, "onChange"> {
  value?: number;
  onChange: (n?: number) => void;
}

function LeadStatusDropdown({
  label,
  size,
  variant,
  value,
  onChange,
  fullWidth,
  sx,
}: ILeadStatusDropdownProps) {
  const { leadStatusRules } = useLeadStatusRules();

  const selected = useMemo(
    () => (leadStatusRules || []).find((l) => l.id === value) || {},
    [leadStatusRules, value]
  );
  return (
    <Autocomplete
      sx={sx}
      fullWidth={fullWidth}
      options={leadStatusRules}
      value={selected}
      onChange={(e, v) => {
        onChange(v?.id);
      }}
      groupBy={(o) => o.name || ""}
      getOptionLabel={(o) => o.name || ""}
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant} size={size} />
      )}
    />
  );
}

export default LeadStatusDropdown;
