/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiLeadFromJSON, ApiLeadToJSON, } from './ApiLead';
import { ApiLeadInteractionTyeFromJSON, ApiLeadInteractionTyeToJSON, } from './ApiLeadInteractionTye';
import { ApiLeadStatusFromJSON, ApiLeadStatusToJSON, } from './ApiLeadStatus';
export function ApiLeadInteractionFromJSON(json) {
    return ApiLeadInteractionFromJSONTyped(json, false);
}
export function ApiLeadInteractionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : ApiLeadInteractionTyeFromJSON(json['type']),
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'status': !exists(json, 'status') ? undefined : ApiLeadStatusFromJSON(json['status']),
        'lead': !exists(json, 'lead') ? undefined : ApiLeadFromJSON(json['lead']),
        'leadStatusId': !exists(json, 'leadStatusId') ? undefined : json['leadStatusId'],
        'leadId': !exists(json, 'leadId') ? undefined : json['leadId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}
export function ApiLeadInteractionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'type': ApiLeadInteractionTyeToJSON(value.type),
        'subject': value.subject,
        'status': ApiLeadStatusToJSON(value.status),
        'lead': ApiLeadToJSON(value.lead),
        'leadStatusId': value.leadStatusId,
        'leadId': value.leadId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}
