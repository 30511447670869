import Loading from "Components/Loading/Loading";
import { usePaidChannelAttribute } from "features/analytics";

const withPaidChannelAttributes = (Comp: React.ComponentType<any>) => {
  return (props) => {
    const { paidChannelAttributes, loading } = usePaidChannelAttribute();
    if (loading) {
      return <Loading />;
    }
    return <Comp {...props} paidChannelAttributes={paidChannelAttributes} />;
  };
};

export default withPaidChannelAttributes;
