/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiCSVAllowedFieldFromJSON, ApiCSVAllowedFieldToJSON, } from './ApiCSVAllowedField';
import { ApiCSVFieldTypeFromJSON, ApiCSVFieldTypeToJSON, } from './ApiCSVFieldType';
export function ApiCSVFieldFromJSON(json) {
    return ApiCSVFieldFromJSONTyped(json, false);
}
export function ApiCSVFieldFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': !exists(json, 'type') ? undefined : ApiCSVFieldTypeFromJSON(json['type']),
        'required': !exists(json, 'required') ? undefined : json['required'],
        'must': !exists(json, 'must') ? undefined : json['must'],
        'userValueAllowed': !exists(json, 'userValueAllowed') ? undefined : json['userValueAllowed'],
        'forceUserValue': !exists(json, 'forceUserValue') ? undefined : json['forceUserValue'],
        'allowedValues': !exists(json, 'allowedValues') ? undefined : (json['allowedValues'].map(ApiCSVAllowedFieldFromJSON)),
    };
}
export function ApiCSVFieldToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'description': value.description,
        'type': ApiCSVFieldTypeToJSON(value.type),
        'required': value.required,
        'must': value.must,
        'userValueAllowed': value.userValueAllowed,
        'forceUserValue': value.forceUserValue,
        'allowedValues': value.allowedValues === undefined ? undefined : (value.allowedValues.map(ApiCSVAllowedFieldToJSON)),
    };
}
