/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiServiceFromJSON, ApiServiceToJSON, } from './ApiService';
import { ApiSyncFromJSON, ApiSyncToJSON, } from './ApiSync';
import { ApiUserFromJSON, ApiUserToJSON, } from './ApiUser';
export function ApiOauthClientFromJSON(json) {
    return ApiOauthClientFromJSONTyped(json, false);
}
export function ApiOauthClientFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'refreshToken': !exists(json, 'refreshToken') ? undefined : json['refreshToken'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'expiresAt': !exists(json, 'expiresAt') ? undefined : (new Date(json['expiresAt'])),
        'user': !exists(json, 'user') ? undefined : ApiUserFromJSON(json['user']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'service': !exists(json, 'service') ? undefined : ApiServiceFromJSON(json['service']),
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'userMeta': !exists(json, 'userMeta') ? undefined : json['userMeta'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'syncs': !exists(json, 'syncs') ? undefined : (json['syncs'].map(ApiSyncFromJSON)),
    };
}
export function ApiOauthClientToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'refreshToken': value.refreshToken,
        'token': value.token,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'expiresAt': value.expiresAt === undefined ? undefined : (value.expiresAt.toISOString()),
        'user': ApiUserToJSON(value.user),
        'userId': value.userId,
        'service': ApiServiceToJSON(value.service),
        'externalId': value.externalId,
        'userMeta': value.userMeta,
        'scope': value.scope,
        'syncs': value.syncs === undefined ? undefined : (value.syncs.map(ApiSyncToJSON)),
    };
}
