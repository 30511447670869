import { Avatar } from "@mui/material";

function HeaderAvatar({ name }: { name: string }) {
  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  };

  const stringAvatar = (name: string) => {
    const parts = name.split(" ");
    if (!parts || parts.length === 0) {
      return {};
    }

    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${parts[0][0]}${parts.length > 1 ? parts[1][0] : ""}`,
    };
  };
  return <Avatar {...stringAvatar(name)} />;
}

export default HeaderAvatar;
