import { Box, BoxProps, styled } from "@mui/material";
import { HTMLMotionProps } from "framer-motion";
import { TFramerAnimationProps } from "Interfaces";

interface IStyledBoxProps
  extends BoxProps,
    Pick<HTMLMotionProps<any>, TFramerAnimationProps> {
  compact?: boolean;
}

export const StyledFlexBox = styled(
  ({ alignItems, ...rest }: IStyledBoxProps) => (
    <Box alignItems={alignItems || "center"} {...rest} />
  )
)(({ theme, compact }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  padding: theme.spacing(compact ? 0 : 0.5, 1.5, compact ? 0 : 0.5, 1.5),
  borderRadius: theme.spacing(0.5),
  background: `#5E6AE31A`,
}));

// todo: redefine above

export const StyledRightAlignedBox = styled(
  ({ alignItems, ...rest }: IStyledBoxProps) => <Box {...rest} />
)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
}));

export const StyledMiddleBox = styled(
  ({ alignItems, ...rest }: IStyledBoxProps) => <Box {...rest} />
)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
