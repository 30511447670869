import { landingDomainService } from "Apis";
import { useSharedState } from "./useSharedState";
import { ApiDomainResponse, ApiProject } from "@incendium/api";

const fetchSubdomains = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const subdomains =
      await landingDomainService.landingDomainServiceListDomains({
        projectId: selectedProject.id as number,
      });
    return subdomains.results || [];
  }
  return [];
};
export const useSubdomains = () => {
  const { state, setState, refetch } = useSharedState<
    ApiDomainResponse,
    ApiDomainResponse[]
  >("subdomains", [], fetchSubdomains);

  return {
    subdomains: state,
    setSubdomains: setState,
    refetch,
  };
};
