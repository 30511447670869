import { ApiChartTemplate, ApiChartType } from "@incendium/api";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { ChartBuilder } from "features/chartLibrary";
import { ChartLibraryList, NewChartForm } from "features/chartLibrary";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import { useSelectedProject } from "Hooks";
import { parseChartFromResponse, useCharts } from "Hooks/useCharts";
import { GenericDialoagProps, IChart } from "Interfaces";
import {
  defaultChart,
  useReportBuilderContext,
} from "Providers/ReportBuilderProvider";
import { useCallback, useEffect, useState } from "react";

interface IChartLibraryDialogProps extends GenericDialoagProps {
  onSelect: (c: IChart) => void;
  hideTabs?: boolean;
  template?: ApiChartTemplate;
  type?: ApiChartType;
}

enum View {
  pick = "pick",
  create = "create",
  build = "build",
}

function ChartLibraryDialog({
  open,
  setOpen,
  onSelect,
  hideTabs,
  template,
  type,
}: IChartLibraryDialogProps) {
  const [view, setView] = useState<View>(View.pick);
  const { chart, setChart } = useReportBuilderContext();
  const { saveNewChart, updateChart } = useCharts();
  const { selectedProject } = useSelectedProject();
  const theme = useTheme();

  const createChart = useCallback(async () => {
    const res = await saveNewChart({ ...chart, type }, selectedProject);

    const o = parseChartFromResponse(res);
    setChart({
      ...o,
      yAxisKeys:
        o.yAxisKeys.length > 0
          ? o.yAxisKeys
          : [
              {
                key: "l",
                fields: [""],
              },
            ],
    });
    setView(View.build);
  }, [chart, saveNewChart, selectedProject, setChart, type]);

  const saveChart = async () => {
    const res = await updateChart(chart, selectedProject);

    onSelect(res);
  };

  const cancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      setChart(defaultChart);
      setView(View.pick);
    }
  }, [open, setChart]);

  return (
    <CustomDialog
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth={view === View.create ? "sm" : "xl"}
      fullScreen={view === View.build}
      open={open}
      showClose={view !== View.build}
    >
      {view !== View.build && <DialogTitle>Pick a chart</DialogTitle>}

      <>
        {view === View.pick ? (
          <DialogContent
            sx={{
              background: theme.palette.background.default,
            }}
          >
            <Box mt={2} mb={4}>
              <ChartLibraryList
                onClick={onSelect}
                onCreate={() => setView(View.create)}
                selectOnly
                hideTabs={hideTabs}
                template={template}
                type={type}
              />
            </Box>
          </DialogContent>
        ) : view === View.create ? (
          <DialogContent>
            <NewChartForm
              chart={chart}
              setChart={setChart}
              hideTemplateSelect={!!type || !!template}
            />
          </DialogContent>
        ) : (
          <Box
            sx={{
              background: theme.palette.background.default,
              height: "calc(100vh - 70px)",
              flex: 1,
              width: "100%",
            }}
          >
            <ChartBuilder />
          </Box>
        )}
      </>

      <DialogActions>
        <Button onClick={cancel} color="secondary">
          Cancel
        </Button>
        {view === View.create && (
          <Button
            onClick={createChart}
            disabled={
              chart.name === "" ||
              (!type &&
                !template &&
                (!chart.template ||
                  chart.template === ApiChartTemplate.UNDEFINED))
            }
          >
            Create Chart
          </Button>
        )}
        {view === View.build && (
          <Button onClick={saveChart}>Save and Add Chart</Button>
        )}
      </DialogActions>
    </CustomDialog>
  );
}

export default ChartLibraryDialog;
