/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiOpeStatus;
(function (ApiOpeStatus) {
    ApiOpeStatus["UNSPECIFIED"] = "OPE_STATUS_UNSPECIFIED";
    ApiOpeStatus["MISSING_DATA"] = "OPE_STATUS_MISSING_DATA";
    ApiOpeStatus["SET"] = "OPE_STATUS_SET";
    ApiOpeStatus["LEAD_SCORE_CALCULATED_DO_NOT_USE"] = "OPE_STATUS_LEAD_SCORE_CALCULATED_DO_NOT_USE";
    ApiOpeStatus["LEAD_SCORE_CALCULATED"] = "OPE_STATUS_LEAD_SCORE_CALCULATED";
    ApiOpeStatus["EFFECTIVENESS_CALCULATED"] = "OPE_STATUS_EFFECTIVENESS_CALCULATED";
})(ApiOpeStatus || (ApiOpeStatus = {}));
export function ApiOpeStatusFromJSON(json) {
    return ApiOpeStatusFromJSONTyped(json, false);
}
export function ApiOpeStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiOpeStatusToJSON(value) {
    return value;
}
