import { Box, styled } from "@mui/material";
import { Glass, IGlassProps } from "Components/Glass";
import { ReactComponent as Bg } from "Assets/Images/Illustrations/undraw_setup_wizard_re_nday.svg";

const StyledBox = styled(Glass)(() => ({
  minHeight: 400,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledContainer = styled(Box)(({ theme }) => ({
  textAlign: "center",
  maxWidth: 600,
  "& svg": {
    maxWidth: 300,
    height: "auto",
    marginBottom: theme.spacing(2),
  },
}));

function SetupNeeded({ children, ...rest }: IGlassProps) {
  return (
    <StyledBox {...rest}>
      <StyledContainer>
        <Bg />
        {children}
      </StyledContainer>
    </StyledBox>
  );
}

export default SetupNeeded;
