/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiDomainStatus;
(function (ApiDomainStatus) {
    ApiDomainStatus["DOMAIN_STATUS_NONE"] = "DomainStatusNone";
    ApiDomainStatus["DOMAIN_STATUS_CERTIFICATE_PENDING"] = "DomainStatusCertificatePending";
    ApiDomainStatus["DOMAIN_STATUS_DOMAIN_PENDING"] = "DomainStatusDomainPending";
    ApiDomainStatus["DOMAIN_STATUS_READY"] = "DomainStatusReady";
    ApiDomainStatus["DOMAIN_STATUS_DELETING"] = "DomainStatusDeleting";
})(ApiDomainStatus || (ApiDomainStatus = {}));
export function ApiDomainStatusFromJSON(json) {
    return ApiDomainStatusFromJSONTyped(json, false);
}
export function ApiDomainStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiDomainStatusToJSON(value) {
    return value;
}
