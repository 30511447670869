/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiAuthPayloadToJSON, ApiLinkAccountPayloadToJSON, ApiListAccountsResponseFromJSON, ApiListOauthClientsResponseFromJSON, ApiListSyncsResponseFromJSON, ApiSyncFromJSON, ApiUrlSyncResponseFromJSON, ApiUserOauthClientFromJSON, } from '../models';
/**
 *
 */
export class SyncServiceApi extends runtime.BaseAPI {
    /**
     */
    syncServiceAuthRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.service === null || requestParameters.service === undefined) {
                throw new runtime.RequiredError('service', 'Required parameter requestParameters.service was null or undefined when calling syncServiceAuth.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling syncServiceAuth.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/sync/v1/services/{service}/auth`.replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiAuthPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiUserOauthClientFromJSON(jsonValue));
        });
    }
    /**
     */
    syncServiceAuth(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.syncServiceAuthRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    syncServiceDeleteClientRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.oauthClientId === null || requestParameters.oauthClientId === undefined) {
                throw new runtime.RequiredError('oauthClientId', 'Required parameter requestParameters.oauthClientId was null or undefined when calling syncServiceDeleteClient.');
            }
            const queryParameters = {};
            if (requestParameters.service !== undefined) {
                queryParameters['service'] = requestParameters.service;
            }
            if (requestParameters.syncId !== undefined) {
                queryParameters['syncId'] = requestParameters.syncId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/sync/v1/clients/{oauthClientId}`.replace(`{${"oauthClientId"}}`, encodeURIComponent(String(requestParameters.oauthClientId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    syncServiceDeleteClient(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.syncServiceDeleteClientRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    syncServiceDeleteSyncRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling syncServiceDeleteSync.');
            }
            if (requestParameters.syncId === null || requestParameters.syncId === undefined) {
                throw new runtime.RequiredError('syncId', 'Required parameter requestParameters.syncId was null or undefined when calling syncServiceDeleteSync.');
            }
            const queryParameters = {};
            if (requestParameters.service !== undefined) {
                queryParameters['service'] = requestParameters.service;
            }
            if (requestParameters.oauthClientId !== undefined) {
                queryParameters['oauthClientId'] = requestParameters.oauthClientId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/sync/v1/projects/{projectId}/syncs/{syncId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"syncId"}}`, encodeURIComponent(String(requestParameters.syncId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    syncServiceDeleteSync(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.syncServiceDeleteSyncRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    syncServiceLinkAccountRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling syncServiceLinkAccount.');
            }
            if (requestParameters.oauthClientId === null || requestParameters.oauthClientId === undefined) {
                throw new runtime.RequiredError('oauthClientId', 'Required parameter requestParameters.oauthClientId was null or undefined when calling syncServiceLinkAccount.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling syncServiceLinkAccount.');
            }
            const queryParameters = {};
            if (requestParameters.service !== undefined) {
                queryParameters['service'] = requestParameters.service;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/sync/v1/projects/{projectId}/clients/{oauthClientId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"oauthClientId"}}`, encodeURIComponent(String(requestParameters.oauthClientId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiLinkAccountPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiSyncFromJSON(jsonValue));
        });
    }
    /**
     */
    syncServiceLinkAccount(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.syncServiceLinkAccountRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    syncServiceListAccountsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.oauthClientId === null || requestParameters.oauthClientId === undefined) {
                throw new runtime.RequiredError('oauthClientId', 'Required parameter requestParameters.oauthClientId was null or undefined when calling syncServiceListAccounts.');
            }
            const queryParameters = {};
            if (requestParameters.service !== undefined) {
                queryParameters['service'] = requestParameters.service;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/sync/v1/clients/{oauthClientId}`.replace(`{${"oauthClientId"}}`, encodeURIComponent(String(requestParameters.oauthClientId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListAccountsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    syncServiceListAccounts(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.syncServiceListAccountsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    syncServiceListClientsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.service === null || requestParameters.service === undefined) {
                throw new runtime.RequiredError('service', 'Required parameter requestParameters.service was null or undefined when calling syncServiceListClients.');
            }
            const queryParameters = {};
            if (requestParameters.oauthClientId !== undefined) {
                queryParameters['oauthClientId'] = requestParameters.oauthClientId;
            }
            if (requestParameters.syncId !== undefined) {
                queryParameters['syncId'] = requestParameters.syncId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/sync/v1/services/{service}`.replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListOauthClientsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    syncServiceListClients(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.syncServiceListClientsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    syncServiceListSyncsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling syncServiceListSyncs.');
            }
            const queryParameters = {};
            if (requestParameters.service !== undefined) {
                queryParameters['service'] = requestParameters.service;
            }
            if (requestParameters.oauthClientId !== undefined) {
                queryParameters['oauthClientId'] = requestParameters.oauthClientId;
            }
            if (requestParameters.syncId !== undefined) {
                queryParameters['syncId'] = requestParameters.syncId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/sync/v1/projects/{projectId}/syncs`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListSyncsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    syncServiceListSyncs(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.syncServiceListSyncsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    syncServiceUrlRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.service === null || requestParameters.service === undefined) {
                throw new runtime.RequiredError('service', 'Required parameter requestParameters.service was null or undefined when calling syncServiceUrl.');
            }
            const queryParameters = {};
            if (requestParameters.redirectUrl !== undefined) {
                queryParameters['redirectUrl'] = requestParameters.redirectUrl;
            }
            if (requestParameters.state !== undefined) {
                queryParameters['state'] = requestParameters.state;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/sync/v1/services/{service}/url`.replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiUrlSyncResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    syncServiceUrl(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.syncServiceUrlRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
/**
    * @export
    * @enum {string}
    */
export var SyncServiceAuthServiceEnum;
(function (SyncServiceAuthServiceEnum) {
    SyncServiceAuthServiceEnum["SERVICE_UNSPECIFIED"] = "service_unspecified";
    SyncServiceAuthServiceEnum["GOOGLE"] = "google";
    SyncServiceAuthServiceEnum["BING"] = "bing";
    SyncServiceAuthServiceEnum["FACEBOOK"] = "facebook";
    SyncServiceAuthServiceEnum["ECWID"] = "ecwid";
})(SyncServiceAuthServiceEnum || (SyncServiceAuthServiceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SyncServiceDeleteClientServiceEnum;
(function (SyncServiceDeleteClientServiceEnum) {
    SyncServiceDeleteClientServiceEnum["SERVICE_UNSPECIFIED"] = "service_unspecified";
    SyncServiceDeleteClientServiceEnum["GOOGLE"] = "google";
    SyncServiceDeleteClientServiceEnum["BING"] = "bing";
    SyncServiceDeleteClientServiceEnum["FACEBOOK"] = "facebook";
    SyncServiceDeleteClientServiceEnum["ECWID"] = "ecwid";
})(SyncServiceDeleteClientServiceEnum || (SyncServiceDeleteClientServiceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SyncServiceDeleteSyncServiceEnum;
(function (SyncServiceDeleteSyncServiceEnum) {
    SyncServiceDeleteSyncServiceEnum["SERVICE_UNSPECIFIED"] = "service_unspecified";
    SyncServiceDeleteSyncServiceEnum["GOOGLE"] = "google";
    SyncServiceDeleteSyncServiceEnum["BING"] = "bing";
    SyncServiceDeleteSyncServiceEnum["FACEBOOK"] = "facebook";
    SyncServiceDeleteSyncServiceEnum["ECWID"] = "ecwid";
})(SyncServiceDeleteSyncServiceEnum || (SyncServiceDeleteSyncServiceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SyncServiceLinkAccountServiceEnum;
(function (SyncServiceLinkAccountServiceEnum) {
    SyncServiceLinkAccountServiceEnum["SERVICE_UNSPECIFIED"] = "service_unspecified";
    SyncServiceLinkAccountServiceEnum["GOOGLE"] = "google";
    SyncServiceLinkAccountServiceEnum["BING"] = "bing";
    SyncServiceLinkAccountServiceEnum["FACEBOOK"] = "facebook";
    SyncServiceLinkAccountServiceEnum["ECWID"] = "ecwid";
})(SyncServiceLinkAccountServiceEnum || (SyncServiceLinkAccountServiceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SyncServiceListAccountsServiceEnum;
(function (SyncServiceListAccountsServiceEnum) {
    SyncServiceListAccountsServiceEnum["SERVICE_UNSPECIFIED"] = "service_unspecified";
    SyncServiceListAccountsServiceEnum["GOOGLE"] = "google";
    SyncServiceListAccountsServiceEnum["BING"] = "bing";
    SyncServiceListAccountsServiceEnum["FACEBOOK"] = "facebook";
    SyncServiceListAccountsServiceEnum["ECWID"] = "ecwid";
})(SyncServiceListAccountsServiceEnum || (SyncServiceListAccountsServiceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SyncServiceListClientsServiceEnum;
(function (SyncServiceListClientsServiceEnum) {
    SyncServiceListClientsServiceEnum["SERVICE_UNSPECIFIED"] = "service_unspecified";
    SyncServiceListClientsServiceEnum["GOOGLE"] = "google";
    SyncServiceListClientsServiceEnum["BING"] = "bing";
    SyncServiceListClientsServiceEnum["FACEBOOK"] = "facebook";
    SyncServiceListClientsServiceEnum["ECWID"] = "ecwid";
})(SyncServiceListClientsServiceEnum || (SyncServiceListClientsServiceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SyncServiceListSyncsServiceEnum;
(function (SyncServiceListSyncsServiceEnum) {
    SyncServiceListSyncsServiceEnum["SERVICE_UNSPECIFIED"] = "service_unspecified";
    SyncServiceListSyncsServiceEnum["GOOGLE"] = "google";
    SyncServiceListSyncsServiceEnum["BING"] = "bing";
    SyncServiceListSyncsServiceEnum["FACEBOOK"] = "facebook";
    SyncServiceListSyncsServiceEnum["ECWID"] = "ecwid";
})(SyncServiceListSyncsServiceEnum || (SyncServiceListSyncsServiceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SyncServiceUrlServiceEnum;
(function (SyncServiceUrlServiceEnum) {
    SyncServiceUrlServiceEnum["SERVICE_UNSPECIFIED"] = "service_unspecified";
    SyncServiceUrlServiceEnum["GOOGLE"] = "google";
    SyncServiceUrlServiceEnum["BING"] = "bing";
    SyncServiceUrlServiceEnum["FACEBOOK"] = "facebook";
    SyncServiceUrlServiceEnum["ECWID"] = "ecwid";
})(SyncServiceUrlServiceEnum || (SyncServiceUrlServiceEnum = {}));
