import { ApiChannel } from "@incendium/api";
import { Box, CircularProgress, Portal } from "@mui/material";
import { channelService } from "Apis";
import ConditionGroupProvider from "Components/ConditionalGroup/ConditionGroupProvider";
import { TypographyHelp } from "Components/UI/TypographyHelp";
import { ChanelForm } from "features/channels";
import { useSelectedProject } from "Hooks";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useMount } from "react-use";

function ChannelPage() {
  const [title, setTitle] = useState("");
  const { selectedProject } = useSelectedProject();
  const { channelId } = useParams<{ channelId: string }>();
  const [channel, setChannel] = useState<ApiChannel | undefined>(undefined);

  useMount(() => {
    if (!channelId) {
      return;
    }

    const load = async () => {
      const res = await channelService.channelServiceReadChannel({
        projectId: selectedProject?.id as number,
        channelId: Number(channelId),
      });
      setChannel(res);
      setTitle(res.name || "");
    };
    load();
  });

  if (!channel || !selectedProject) {
    return <CircularProgress />;
  }

  return (
    <Box pt={5}>
      <Portal container={() => document.getElementById("pageTitle")}>
        <TypographyHelp
          variant="h1"
          text={title}
          placement={"right"}
          width={400}
          tooltip={
            <>
              <p>
                Configure the rules which will define what channel a pageview
                belongs to.
              </p>
              <p>
                Field: this is what we are looking to find a match to; these are
                often, but not always, set as UTM params
              </p>
              <p>
                Operator
                <li>Equal - field exactly matches this value</li>
                <li>Not Equal - field does not exactly match this value </li>
                <li>
                  Is In - field exactly matches one of the values, seperated by
                  `|` e.g "cpc|ppc|paidsearch"
                </li>
                <li>
                  Not In - field does not exactly match any of the values
                  seperated by `|`
                </li>
                <li>Starts with - field starts with this value</li>
                <li>Contains - field contains this value</li>
              </p>
            </>
          }
        />
      </Portal>
      <ConditionGroupProvider>
        <ChanelForm
          channel={channel}
          setChannel={setChannel}
          project={selectedProject}
        />
      </ConditionGroupProvider>
    </Box>
  );
}

export default ChannelPage;
