import { IStoreState } from "Interfaces";

export const reportSelector =
  (clientId?: string, projectId?: string, reportId?: string) =>
  (state: IStoreState) => {
    if (!clientId || !projectId || !reportId) {
      return null;
    }

    const clientReports = state.application.reports[clientId];
    if (!clientReports) {
      return null;
    }
    const reports = clientReports[projectId].state;
    if (!reports) {
      return null;
    }
    const index = reports.findIndex((item) => item.id === parseInt(reportId));
    if (index === -1) {
      return null;
    }
    return reports[index];
  };
