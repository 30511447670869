import { Button, IconButton, styled } from "@mui/material";

export const StyledWhiteIconBtn = styled(IconButton)(({ theme, size }) => ({
  background: "white",
  "&:hover": {
    background: "rgba(255,255,255,0.7)",
  },
}));

export const StyledButtonNoTextTransform = styled(Button)(({ theme }) => ({
  textTransform: "none",
}));
