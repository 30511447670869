import { CircularProgress } from "@mui/material";
import { initializeClientsThunk } from "Actions";
import { EmptyComponent } from "Components/EmptyComponent";
import { ClientDialog, ClientsList } from "features/clients";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clientListSelector } from "Selectors/clientListSelector";

export const ClientsPage = () => {
  const state = useSelector(clientListSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeClientsThunk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isAddOpen, setIsAddOpen] = useState(false);

  const handleClose = () => setIsAddOpen(false);
  const handleOpen = () => setIsAddOpen(true);
  const renderData = () => {
    if (!state.hasLoaded) {
      return <CircularProgress />;
    }
    if (state.list.length === 0) {
      return (
        <>
          {isAddOpen && <ClientDialog onClose={handleClose} />}
          <EmptyComponent
            content="It looks like you don’t have any clients set up."
            onBtnClick={() => setIsAddOpen(true)}
            btnText="add client"
          />
        </>
      );
    } else {
      return (
        <>
          {isAddOpen && <ClientDialog onClose={handleClose} />}
          <ClientsList clients={state.list} onClick={handleOpen} />
        </>
      );
    }
  };

  return <>{renderData()}</>;
};
