import { ApiDomainResponse, ApiDomainStatus } from "@incendium/api";
import { Delete, Done, Edit } from "@mui/icons-material";
import {
  Box,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CopyClipboardBtn from "Components/CopyClipboardBtn/CopyClipboardBtn";
import GlassCard from "Components/GlassCard/GlassCard";
import { friendlyDateTime } from "Helpers/dates";
import { useMemo } from "react";
import { SubdomainStatusLabel } from "features/subdomains";
import { getRootDomain } from "features/subdomains/utils";
import useProjectTimezone from "Hooks/useProjectTimezone";

interface ISubdomainCardProps {
  subdomain: ApiDomainResponse;
  onEdit: (s: ApiDomainResponse) => void;
  onDelete: (s: ApiDomainResponse) => void;
}

function SubdomainCard({ subdomain, onEdit, onDelete }: ISubdomainCardProps) {
  const timezone = useProjectTimezone();
  const domain = useMemo(() => {
    return `.${getRootDomain(subdomain?.domain || "")}`;
  }, [subdomain.domain]);

  return (
    <GlassCard
      disabled={subdomain.status === ApiDomainStatus.DOMAIN_STATUS_DELETING}
    >
      <CardHeader
        titleTypographyProps={{
          variant: "subtitle1",
        }}
        action={
          <Stack direction={"row"}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => onEdit(subdomain)}
            >
              <Edit />
            </IconButton>
            <IconButton
              size="small"
              color="secondary"
              onClick={() => onDelete(subdomain)}
            >
              <Delete />
            </IconButton>
          </Stack>
        }
        title={
          <span>
            {subdomain.subdomain}
            <Typography color={"secondary"} display={"inline"}>
              {domain}
            </Typography>
          </span>
        }
        subheader={<SubdomainStatusLabel status={subdomain.status!} />}
      />

      <CardContent sx={{ paddingY: 0 }}>
        <Typography variant="body2" noWrap fontWeight={600}>
          {subdomain.name}
        </Typography>
        <Typography variant="body2" noWrap color={"secondary"}>
          <Tooltip arrow title={subdomain.description}>
            <span>{subdomain.description || "-"}</span>
          </Tooltip>
        </Typography>
      </CardContent>

      <CardContent>
        <Divider />
        <List dense>
          <Stack
            direction={"row"}
            spacing={1}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="subtitle2">
              Certificate CNAME{" "}
              {subdomain.certificateApproved && (
                <Done color="success" fontSize="inherit" />
              )}
            </Typography>
            {subdomain.certificateApproved && (
              <Typography variant="body2" color={"success.main"}>
                Approved{" "}
                {friendlyDateTime(subdomain.certificateApproved, timezone)}
              </Typography>
            )}
          </Stack>
          <Typography variant="body2" color={"secondary"}>
            Add this CNAME record to validate your domain ownership. This step
            confirms your domain ownership, allowing us to create the necessary
            records and generate the SSL certificate for you.
          </Typography>
          <ListItem disableGutters>
            <CopyClipboardBtn
              isIconButton
              disabled={!subdomain.certificateCname}
              variant="text"
              text={subdomain.certificateCname!}
              size="extraSmall"
              sx={{ textAlign: "left" }}
            />

            <ListItemText
              primary="Certificate CNAME Key"
              secondary={
                subdomain.certificateCname || "Processing - Check back soon"
              }
            />
          </ListItem>
          <ListItem disableGutters>
            <CopyClipboardBtn
              isIconButton
              disabled={!subdomain.certificateCnameValue}
              variant="text"
              text={subdomain.certificateCnameValue!}
              size="extraSmall"
              sx={{ textAlign: "left" }}
            />

            <ListItemText
              primary="Certificate CNAME Value"
              secondary={
                subdomain.certificateCnameValue ||
                "Processing - Check back soon"
              }
            />
          </ListItem>
        </List>
        <List dense>
          <Stack
            direction={"row"}
            spacing={1}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="subtitle2">
              Subdomain CNAME{" "}
              {subdomain.domainApproved && (
                <Done color="success" fontSize="inherit" />
              )}
            </Typography>
            {subdomain.domainApproved && (
              <Typography variant="body2" color={"success.main"}>
                Approved {friendlyDateTime(subdomain.domainApproved, timezone)}
              </Typography>
            )}
          </Stack>

          <Typography variant="body2" color={"secondary"}>
            Please add this CNAME record to activate the use of landing pages.
            However, this specific CNAME record cannot be created until the
            domain has been validated with the Certificate CNAME value.
          </Typography>
          <ListItem disableGutters>
            <CopyClipboardBtn
              isIconButton
              disabled={!subdomain.cnameName}
              variant="text"
              text={subdomain.cnameName!}
              size="extraSmall"
              sx={{ textAlign: "left" }}
            />

            <ListItemText
              primary="Domain CNAME Key"
              secondary={subdomain.cnameName || "Processing - Check back soon"}
            />
          </ListItem>
          <ListItem disableGutters>
            <CopyClipboardBtn
              isIconButton
              disabled={!subdomain.cnameValue}
              variant="text"
              text={subdomain.cnameValue!}
              size="extraSmall"
              sx={{ textAlign: "left" }}
            />

            <ListItemText
              primary="Domain CNAME Value"
              secondary={subdomain.cnameValue || "Processing - Check back soon"}
            />
          </ListItem>
        </List>
      </CardContent>
      <Box></Box>
    </GlassCard>
  );
}

export default SubdomainCard;
