import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import { styled, Typography } from "@mui/material";

export const StyledTimeline = styled(Timeline)(({ theme }) => ({
  transform: "translateY(-4px)",
}));
export const StyledTimelineConnector = styled(TimelineConnector)<{
  opacity?: number;
}>(({ theme, opacity = 1 }) => ({
  background: "#707070",
  opacity: opacity,
}));
export const StyledTimelineDot = styled(TimelineDot)<{
  size: "large" | "small";
  bg: string;
  isactive?: boolean;
}>(({ theme, size, bg, isactive }) => ({
  position: "relative",
  padding: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: bg,
  height: size === "large" ? 38 : 24,
  width: size === "large" ? 38 : 24,
  zIndex: 1,
  margin: `6px 0`,

  "&:hover":
    size === "large"
      ? {
          cursor: "pointer",

          "&:before": {
            display: "block",
            opacity: 0.5,
            content: "''",
            position: "absolute",
            height: "calc(100% + 12px)",
            width: "calc(100% + 12px)",
            borderLeft: `2px solid ${bg}`,
            borderRight: `2px solid ${bg}`,
            borderTop: `2px solid rgba(255,255,255,0)`,
            borderBottom: `2px solid rgba(255,255,255,0)`,
            borderRadius: "50%",
            transform: "rotate(-20deg)",
          },
          "&:after": {
            display: "block",
            opacity: 0.5,
            content: "''",
            position: "absolute",
            height: "calc(100% + 12px)",
            width: "calc(100% + 12px)",
            borderLeft: `2px solid ${bg}`,
            borderRight: `2px solid ${bg}`,
            borderTop: `2px solid rgba(255,255,255,0)`,
            borderBottom: `2px solid rgba(255,255,255,0)`,
            borderRadius: "50%",
            transform: "rotate(20deg)",
          },
        }
      : undefined,
  "&:before": {
    display: isactive ? "block" : "none",
    content: "''",
    position: "absolute",
    height: "calc(100% + 12px)",
    width: "calc(100% + 12px)",
    borderLeft: `2px solid ${bg}`,
    borderRight: `2px solid ${bg}`,
    borderTop: `2px solid rgba(255,255,255,0)`,
    borderBottom: `2px solid rgba(255,255,255,0)`,
    borderRadius: "50%",
    transform: "rotate(-20deg)",
  },
  "&:after": {
    display: isactive ? "block" : "none",
    content: "''",
    position: "absolute",
    height: "calc(100% + 12px)",
    width: "calc(100% + 12px)",
    borderLeft: `2px solid ${bg}`,
    borderRight: `2px solid ${bg}`,
    borderTop: `2px solid rgba(255,255,255,0)`,
    borderBottom: `2px solid rgba(255,255,255,0)`,
    borderRadius: "50%",
    transform: "rotate(20deg)",
  },
}));

export const StyledTimelineTitle = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: "bold",
  color: theme.palette.primary.dark,
}));
export const StyledTimelineSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.secondary.main,
}));
export const StyledTimelineContent = styled(TimelineContent)(
  ({ theme, flex = 1 }) => ({
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    overflow: "hidden",
    flex: 2,
  })
);

export const StyledTimelineOppositeContent = styled(TimelineOppositeContent)<{
  size?: "large" | "small";
}>(({ theme, size }) => ({
  margin: "auto 0",
  minWidth: size === "large" ? 149 : 156,
  maxWidth: size === "large" ? 149 : 156,
}));
