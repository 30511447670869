import { ApiProjectSettingKey, ApiProjectType } from "@incendium/api";
import { useSelectedProject } from "Hooks";
import { useCallback } from "react";

const useHasProjectType = () => {
  const { projectSettings } = useSelectedProject();

  return useCallback(
    (type: ApiProjectType | ApiProjectType[]) => {
      if (
        !projectSettings[ApiProjectSettingKey.PROJECT_TYPE] ||
        projectSettings[ApiProjectSettingKey.PROJECT_TYPE] ===
          ApiProjectType.TYPE_UNSPECIFIED
      ) {
        return true;
      }

      return Array.isArray(type)
        ? type.includes(
            projectSettings[ApiProjectSettingKey.PROJECT_TYPE] as ApiProjectType
          )
        : projectSettings[ApiProjectSettingKey.PROJECT_TYPE] === type;
    },
    [projectSettings]
  );
};

export default useHasProjectType;
