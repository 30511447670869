import { ApiChartType } from "@incendium/api";
import {
  BubbleChart,
  Dashboard,
  HexagonOutlined,
  InsertChart,
  PictureInPicture,
  PieChart,
  TableChart,
} from "@mui/icons-material";
import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import produce from "immer";
import { useReportBuilderContext } from "Providers/ReportBuilderProvider";
import { AccordianChartBuilderSidebarBlock } from "./ChartBuilderSidebarBlock";

interface IChartBuilderChartTypeProps {
  types?: ApiChartType[];
}

function ChartBuilderChartType({ types }: IChartBuilderChartTypeProps) {
  const { chart, setChart } = useReportBuilderContext();

  const icon = (t: ApiChartType) => {
    switch (t) {
      case ApiChartType.TABLE:
        return <TableChart />;
      case ApiChartType.GRAPH:
        return <InsertChart />;
      case ApiChartType.PIE:
        return <PieChart />;
      case ApiChartType.BUBBLE:
        return <BubbleChart />;

      case ApiChartType.RADAR:
        return <HexagonOutlined />;
      case ApiChartType.TREE_MAP:
        return <Dashboard />;
      case ApiChartType.STAT_CARD:
        return <PictureInPicture />;
    }
  };

  return (
    <AccordianChartBuilderSidebarBlock title="Chart Type">
      <Stack spacing={2}>
        <ToggleButtonGroup
          size="small"
          value={chart.type}
          exclusive
          color="secondary"
          onChange={(e, v) => {
            setChart(
              produce(chart, (draft) => {
                draft.type = v;
              })
            );
          }}
        >
          {[
            ApiChartType.TABLE,
            ApiChartType.GRAPH,
            ApiChartType.PIE,
            ApiChartType.BUBBLE,
            ApiChartType.RADAR,
            ApiChartType.TREE_MAP,
            ApiChartType.STAT_CARD,
          ]
            .filter((t) => !types || types.includes(t))
            .map((t) => (
              <ToggleButton key={t} value={t}>
                {icon(t)}
              </ToggleButton>
            ))}
        </ToggleButtonGroup>

        <Typography variant="body2">
          {chart.type === ApiChartType.PIE
            ? "Pie Chart: Use the pie chart to visualize compositions of segmented dimensions paired with any metrics."
            : chart.type === ApiChartType.GRAPH
            ? "Graph Chart: Use the graph chart to visualize trends of any metric in column, stacked, line and area graph form."
            : chart.type === ApiChartType.BUBBLE
            ? "Bubble Chart: Set a dimension and 3 metrics, the last metric will be the bubble size"
            : chart.type === ApiChartType.TABLE
            ? "Table Chart: Use for data table"
            : chart.type === ApiChartType.RADAR
            ? "Radar Chart: Use radar charts to plot variables as axes, with data points forming polygons to visually represent the dataset."
            : chart.type === ApiChartType.TREE_MAP
            ? "Treemap: Utilize this graph for hierarchical visualization and identifying outliers within your dataset."
            : chart.type === ApiChartType.STAT_CARD
            ? "Statcard: Give your report a headline figure."
            : ""}
        </Typography>
      </Stack>
    </AccordianChartBuilderSidebarBlock>
  );
}

export default ChartBuilderChartType;
