import { ApiChannel } from "@incendium/api";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
} from "@mui/material";
import AttributionToggle from "Components/AttributionToggle/AttributionToggle";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import { useChannels } from "Hooks/useChannels";
import produce from "immer";
import { GenericDialoagProps, TAttributionModel } from "Interfaces";
import { useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";

interface IChannelPickerProps extends GenericDialoagProps {
  initialSelected: ApiChannel[];
  initialModel: TAttributionModel;
  onSelected: (channels: ApiChannel[], model: TAttributionModel) => void;
}

function ChannelPicker({
  open,
  setOpen,
  onSelected,
  initialSelected,
  initialModel,
}: IChannelPickerProps) {
  const { channels } = useChannels();
  const [model, setModel] = useState<TAttributionModel>(
    initialModel || "linear"
  );
  const [selected, setSelected] = useState<ApiChannel[]>([...initialSelected]);

  const onClose = () => {
    setOpen(false);
  };

  const onChange = (c: ApiChannel) => {
    setSelected(
      produce(selected, (draft) => {
        const idx = selected.findIndex((ac) => ac.id === c.id);
        idx >= 0 ? draft.splice(idx, 1) : draft.push(c);
      })
    );
  };

  const onSubmit = () => {
    onSelected(selected, model);
    onClose();
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>
        <Box display={"flex"} justifyContent="space-between">
          <Box>Select Channels</Box>
          <Box
            sx={{
              maxWidth: 170,
            }}
          >
            <AttributionToggle
              models={[model]}
              setModels={(v: TAttributionModel[]) => {
                setModel(v[0]);
              }}
              show={["linear", "first"]}
            />
          </Box>
        </Box>
      </DialogTitle>
      <ValidatorForm onSubmit={onSubmit}>
        <DialogContent>
          <Box>
            <Grid container spacing={2}>
              {channels.map((c) => (
                <Grid item key={c.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => onChange(c)}
                        checked={(selected || [])
                          .map((s) => s.id)
                          .includes(c.id)}
                      />
                    }
                    label={c.name || ""}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Select
          </Button>
        </DialogActions>
      </ValidatorForm>
    </CustomDialog>
  );
}

export default ChannelPicker;
