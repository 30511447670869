import { Box, useTheme } from "@mui/material";
import {
  ChartLegendItem,
  formatMetric,
  getChartColor,
} from "features/analytics";
import { IStyledChartProps } from "Interfaces";
import { snakeToTitle } from "Helpers/camelToText";
import { formatEnumVal } from "Helpers/enumToText";
import { truncate } from "Helpers/truncate";
import { useAnalyticsContext } from "Providers/AnalyticsProvider";
import { useMemo, useState } from "react";
import {
  Cell,
  Label,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
} from "recharts";
import NoData from "Components/NoData";
import { ApiMetric } from "@incendium/api";

interface IStyledDoughnutChart extends IStyledChartProps {
  label?: { title: string; value: string | number };
  dataKey?: string;
  noShape?: boolean;
  showTotalText?: string;
  totalFontSize?: number;
  formatTotalValue?: (n: number) => string;
  total?: number;
}

const renderActiveShape = (props: any) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
    tooltipPayload,
  } = props;

  const charWidth = 12;
  const chars = Math.floor(cx / charWidth);

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={-4}
        fill={fill}
        fontWeight="bold"
        fontSize={chars > 7 ? 20 : 16}
        textAnchor="middle"
      >
        {truncate(formatEnumVal(payload.name), chars)}
      </text>
      <text
        x={cx}
        y={cy}
        dy={chars > 7 ? 18 : 16}
        fontSize={chars > 7 ? 16 : 12}
        textAnchor="middle"
        fill={fill}
      >{`${formatMetric(tooltipPayload[0].dataKey || "", value)}`}</text>
      <text
        x={cx}
        y={cy}
        dy={chars > 7 ? 36 : 30}
        textAnchor={"middle"}
        fill="#999"
        fontSize={chars > 7 ? 16 : 12}
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

const CustomLabel = ({ viewBox, title, value, fontSize, ...rest }: any) => {
  const theme = useTheme();

  const { cx, cy } = viewBox;
  const charWidth = 9;
  const chars = Math.floor(cx / charWidth);

  const smallerFont = 20;
  const smallestFont = 16;

  return (
    <text
      x={cx}
      y={cy}
      fill={theme.palette.primary.dark}
      textAnchor="middle"
      dominantBaseline="middle"
    >
      <tspan
        alignmentBaseline="middle"
        fontSize={cy > 150 ? smallerFont : smallestFont}
        x={cx}
        y={cy - (cy > 150 ? smallerFont : smallestFont) / 2 - 2}
        fontWeight={"bold"}
      >
        {truncate(title, chars)}
      </tspan>
      <tspan
        fontSize={fontSize}
        fontWeight={"bold"}
        x={cx}
        y={cy + fontSize / 2 + 2}
        fill={theme.palette.primary.dark}
      >
        <>{value}</>
      </tspan>
    </text>
  );
};

function StyledDoughnutChart({
  height,
  width,
  data,
  children,
  label,
  dataKey,
  noAnimation,
  noLegend,
  noShape,
  legendCols = 3,
  fills,
  showTotalText = "Total",
  totalFontSize = 26,
  formatTotalValue,
  total,
}: IStyledDoughnutChart) {
  const theme = useTheme();
  const chartData = useMemo(() => data.slice(), [data]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const { colourMap } = useAnalyticsContext();

  const calcTotal = useMemo(() => {
    return chartData.reduce((agg, d) => {
      return agg + Number(d[dataKey || "value"]);
    }, 0);
  }, [chartData, dataKey]);

  if (calcTotal === 0) {
    return <NoData />;
  }

  return (
    <ResponsiveContainer
      height={height || "100%"}
      width={width || "100%"}
      aspect={1}
    >
      <PieChart>
        <Pie
          dataKey={dataKey || "value"}
          data={chartData}
          innerRadius={"70%"}
          outerRadius={"90%"}
          startAngle={-270}
          endAngle={-630}
          fill={theme.palette.secondary.main}
          isAnimationActive={!noAnimation}
          activeIndex={activeIndex}
          activeShape={noShape ? undefined : renderActiveShape}
          onMouseEnter={(_, index) => setActiveIndex(index)}
          onMouseLeave={() => setActiveIndex(-1)}
        >
          {chartData.map((k, index) => {
            const c =
              colourMap && colourMap[k.name]
                ? colourMap[k.name]
                : fills && fills[k.name]
                ? fills[k.name]
                : getChartColor(index, theme.palette.charts);

            return (
              <Cell
                key={`cell-${index}`}
                strokeWidth={0}
                fill={c || theme.palette.secondary.main}
              />
            );
          })}
          {label && activeIndex === -1 && (
            <Label
              position="center"
              content={
                <CustomLabel
                  title={label.title}
                  value={label.value}
                  fontSize={totalFontSize}
                />
              }
            />
          )}
          {total && activeIndex === -1 && (
            <Label
              position="center"
              content={
                <CustomLabel
                  title={
                    showTotalText && showTotalText !== ""
                      ? showTotalText
                      : "Total"
                  }
                  value={
                    formatTotalValue
                      ? formatTotalValue(total || calcTotal)
                      : formatMetric(dataKey as ApiMetric, total || calcTotal)
                  }
                  fontSize={
                    totalFontSize && totalFontSize !== 0 ? totalFontSize : 26
                  }
                />
              }
            />
          )}
        </Pie>
        {children}
        {!noLegend && (
          <Legend
            verticalAlign="bottom"
            align="center"
            content={({ payload }) => (
              <Box
                mt={2}
                mb={2}
                sx={{
                  display: "grid",
                  placeItems: "center",
                  gridTemplateColumns: `repeat(${
                    (payload || []).length > legendCols
                      ? legendCols
                      : (payload || []).length
                  }, 1fr)`,
                }}
              >
                {(payload || []).map((entry, i) => (
                  <ChartLegendItem
                    fill={
                      colourMap && colourMap[entry.value]
                        ? colourMap[entry.value]
                        : undefined
                    }
                    item={snakeToTitle(entry.value.replace("\\", "_"))}
                    index={i}
                    key={i}
                  />
                ))}
              </Box>
            )}
          />
        )}
      </PieChart>
    </ResponsiveContainer>
  );
}

export default StyledDoughnutChart;
