/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiReservedPageviewMetaSelectorType;
(function (ApiReservedPageviewMetaSelectorType) {
    ApiReservedPageviewMetaSelectorType["CSS"] = "PAGEVIEW_META_SELECTOR_CSS";
    ApiReservedPageviewMetaSelectorType["PATH"] = "PAGEVIEW_META_SELECTOR_PATH";
    ApiReservedPageviewMetaSelectorType["QS"] = "PAGEVIEW_META_SELECTOR_QS";
    ApiReservedPageviewMetaSelectorType["JS"] = "PAGEVIEW_META_SELECTOR_JS";
})(ApiReservedPageviewMetaSelectorType || (ApiReservedPageviewMetaSelectorType = {}));
export function ApiReservedPageviewMetaSelectorTypeFromJSON(json) {
    return ApiReservedPageviewMetaSelectorTypeFromJSONTyped(json, false);
}
export function ApiReservedPageviewMetaSelectorTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiReservedPageviewMetaSelectorTypeToJSON(value) {
    return value;
}
