/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiAPITokenType;
(function (ApiAPITokenType) {
    ApiAPITokenType["API_TOKEN_TYPE_NONE"] = "APITokenTypeNone";
    ApiAPITokenType["API_TOKEN_TYPE_WOOCOMMERCE"] = "APITokenTypeWoocommerce";
    ApiAPITokenType["API_TOKEN_TYPE_SHOPIFY"] = "APITokenTypeShopify";
    ApiAPITokenType["API_TOKEN_TYPE_STRIPE"] = "APITokenTypeStripe";
    ApiAPITokenType["API_TOKEN_TYPE_ECWID"] = "APITokenTypeEcwid";
})(ApiAPITokenType || (ApiAPITokenType = {}));
export function ApiAPITokenTypeFromJSON(json) {
    return ApiAPITokenTypeFromJSONTyped(json, false);
}
export function ApiAPITokenTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiAPITokenTypeToJSON(value) {
    return value;
}
