/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiDomainInputFromJSON, ApiDomainInputToJSON, } from './ApiDomainInput';
import { ApiStatusFromJSON, ApiStatusToJSON, } from './ApiStatus';
export function ApiProjectInputFromJSON(json) {
    return ApiProjectInputFromJSONTyped(json, false);
}
export function ApiProjectInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'status': !exists(json, 'status') ? undefined : ApiStatusFromJSON(json['status']),
        'domains': !exists(json, 'domains') ? undefined : (json['domains'].map(ApiDomainInputFromJSON)),
        'sitemap': !exists(json, 'sitemap') ? undefined : json['sitemap'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
    };
}
export function ApiProjectInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'status': ApiStatusToJSON(value.status),
        'domains': value.domains === undefined ? undefined : (value.domains.map(ApiDomainInputToJSON)),
        'sitemap': value.sitemap,
        'timezone': value.timezone,
    };
}
