import {
  ApiCreateUpdateComponentPayload,
  ApiSimpleComponent,
} from "@incendium/api";
import { personalisationService } from "Apis";

export const saveComponent = async (
  projectId: number,
  component: ApiSimpleComponent,
  token?: string
) => {
  const payload: ApiCreateUpdateComponentPayload = {
    ...component,
  };

  const fn = component?.id
    ? personalisationService.personalisationServiceUpdateComponent({
        projectId,
        componentId: component.id,
        payload,
        token,
      })
    : personalisationService.personalisationServiceCreateComponent({
        projectId,
        payload,
        token,
      });

  const res = await fn;
  return res;
};

export const readComponent = async (projectId, componentId) => {
  const res = await personalisationService.personalisationServiceReadComponent({
    projectId,
    componentId,
  });
  return res;
};

export const deleteComponent = async (projectId, componentId, token?) => {
  const res =
    await personalisationService.personalisationServiceDeleteComponent({
      projectId,
      componentId,
      token,
    });
  return res;
};
