/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiRetentionStatus;
(function (ApiRetentionStatus) {
    ApiRetentionStatus["UNSPECIFIED"] = "RETENTION_STATUS_UNSPECIFIED";
    ApiRetentionStatus["DEAD"] = "RETENTION_STATUS_DEAD";
    ApiRetentionStatus["AT_RISK"] = "RETENTION_STATUS_AT_RISK";
    ApiRetentionStatus["WON_BACK"] = "RETENTION_STATUS_WON_BACK";
})(ApiRetentionStatus || (ApiRetentionStatus = {}));
export function ApiRetentionStatusFromJSON(json) {
    return ApiRetentionStatusFromJSONTyped(json, false);
}
export function ApiRetentionStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiRetentionStatusToJSON(value) {
    return value;
}
