import { UploaderSteps } from "features/csvManager";
import { useCSVRules } from "Hooks/useCSVRules";
import CSVUploaderProvider from "Providers/CSVUploaderProvider";
import { useLayoutContext } from "Providers/LayoutProvider";
import { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";

function DataUploadPage() {
  const { setPageTitle } = useLayoutContext();
  const { ruleId } = useParams<{ ruleId: string }>();
  const { CSVRules } = useCSVRules();

  useLayoutEffect(() => {
    setPageTitle(
      CSVRules.find((r) => `${r.id}` === ruleId)?.description || "Data Upload"
    );

    return () => {
      setPageTitle("");
    };
  }, [setPageTitle, ruleId, CSVRules]);

  return (
    <CSVUploaderProvider>
      <UploaderSteps ruleId={ruleId} />
    </CSVUploaderProvider>
  );
}

export default DataUploadPage;
