/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiClientFromJSON, ApiClientInputToJSON, ApiListClientsResponseFromJSON, } from '../models';
/**
 *
 */
export class ClientServiceApi extends runtime.BaseAPI {
    /**
     */
    clientServiceCreateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.body === null || requestParameters.body === undefined) {
                throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling clientServiceCreate.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/client/v1/clients`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiClientInputToJSON(requestParameters.body),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiClientFromJSON(jsonValue));
        });
    }
    /**
     */
    clientServiceCreate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.clientServiceCreateRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    clientServiceDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling clientServiceDelete.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/client/v1/clients/{clientId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    clientServiceDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.clientServiceDeleteRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    clientServiceListRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/client/v1/clients`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListClientsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    clientServiceList(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.clientServiceListRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    clientServiceReadRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling clientServiceRead.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/client/v1/clients/{clientId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiClientFromJSON(jsonValue));
        });
    }
    /**
     */
    clientServiceRead(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.clientServiceReadRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    clientServiceReadByProjectRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling clientServiceReadByProject.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/client/v1/clients/{clientId}:by-project`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiClientFromJSON(jsonValue));
        });
    }
    /**
     */
    clientServiceReadByProject(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.clientServiceReadByProjectRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    clientServiceUpdateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling clientServiceUpdate.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling clientServiceUpdate.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/client/v1/clients/{clientId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiClientInputToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiClientFromJSON(jsonValue));
        });
    }
    /**
     */
    clientServiceUpdate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.clientServiceUpdateRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
