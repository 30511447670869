export const downloadCsv = (csvData: string | Uint8Array, filename: string) => {
  const contentType = "text/csv";
  const csvFile = new Blob([csvData], { type: contentType });
  download(csvFile, `${filename}.csv`);
};

export const downloadZip = (data: string | Uint8Array, filename: string) => {
  const contentType = "application/octet-stream";
  const file = new Blob([data], { type: contentType });
  download(file, `${filename}.zip`);
};

export const base64ToArrayBuffer = (base64: string) => {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

const download = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  // the filename you want
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};
