import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  styled,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import GlassCard from "Components/GlassCard/GlassCard";
import Image from "mui-image";
import { useCallback, useMemo, useState } from "react";
import {
  Assignment,
  AssignmentTurnedIn,
  Check,
  Delete,
  Settings,
} from "@mui/icons-material";
import { useAPITokens } from "Hooks/useAPITokens";
import { ApiAPITokenType, ApiAPITokenValueType } from "@incendium/api";
import { useSave } from "Hooks/useSave";
import { APITokenService } from "Apis";
import produce from "immer";
import { motion } from "framer-motion";
import StyledDrawer, {
  StyledDrawerContainer,
} from "Components/UI/StyledDrawer";

const StyledCopyButton = styled(Button)(({}) => ({
  minWidth: "auto",
  whiteSpace: "nowrap",
}));

interface IAPITokenCardProps {
  type: ApiAPITokenType;
  clientId: number;
  projectId: number;
  logo: string;
  title: string;
  info: React.ReactChild;
  hideURL?: boolean;
  imageSx?: SxProps<Theme>;
}

function APITokenCard({
  type,
  clientId,
  projectId,
  logo,
  title,
  info,
  imageSx,
}: IAPITokenCardProps) {
  const [tokenCopied, setTokenCopied] = useState(false);
  const [urlCopied, setUrlCopied] = useState(false);
  const { tokens, setTokens } = useAPITokens();
  const { saving, save } = useSave();
  const token = useMemo(() => {
    return tokens.find((t) => t.type === type);
  }, [tokens, type]);
  const [openConfig, setOpenConfig] = useState(false);

  const newToken = useCallback(async () => {
    const res = await APITokenService.aPITokenServiceCreateAPIToken({
      clientId,
      payload: {
        type,
        values: [
          {
            type: ApiAPITokenValueType.API_TOKEN_VALUE_TYPE_PROJECT_ID,
            value: `${projectId as number}`,
          },
        ],
      },
    });
    setTokens(
      produce(tokens, (draft) => {
        draft.push(res);
      })
    );
  }, [clientId, projectId, setTokens, tokens, type]);

  const removeToken = useCallback(
    async (id: number) => {
      await APITokenService.aPITokenServiceDeleteAPIToken({
        clientId,
        tokenId: id,
      });
      setTokens(
        produce(tokens, (draft) => {
          const idx = tokens.findIndex((t) => t.id === id);
          draft.splice(idx, 1);
        })
      );
    },
    [clientId, tokens, setTokens]
  );

  const handleChecked = useCallback(
    async (checked: boolean) => {
      save(() => {
        if (checked) {
          newToken();
        } else if (token) {
          removeToken(token?.id as number);
        }
      });
    },
    [newToken, removeToken, save, token]
  );

  const handleOnConfigure = useCallback(async () => {
    setOpenConfig(true);
  }, []);

  return (
    <>
      <GlassCard boxProps={{ p: 2 }}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent={"space-between"}>
            <motion.div
              initial={{
                opacity: 0,
                x: -50,
              }}
              animate={{
                opacity: 1,
                x: 0,
              }}
              transition={{ delay: 0.6 }}
            >
              <Image
                height={40}
                src={logo}
                duration={50}
                fit={"contain"}
                objectPosition={"left"}
                sx={{ objectPosition: "left", ...imageSx }}
              />
            </motion.div>
            {!!token && (
              <Button
                size="small"
                variant="outlined"
                startIcon={<Settings />}
                onClick={handleOnConfigure}
              >
                configure
              </Button>
            )}

            {saving && <CircularProgress size={"28px"} />}
          </Stack>
          <Divider />
          <Box sx={{ height: 76 }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1">{title}</Typography>

              {!!token && <Check color={"success"} fontSize={"small"} />}
            </Stack>

            {info}
          </Box>
          <Divider />
          <Box>
            {!!token ? (
              <Button
                size="small"
                color="secondary"
                startIcon={<Delete />}
                onClick={() => handleChecked(false)}
              >
                Remove Webhook
              </Button>
            ) : (
              <Button size="small" onClick={() => handleChecked(true)}>
                Generate Webhook
              </Button>
            )}
          </Box>
        </Stack>
      </GlassCard>
      <StyledDrawer open={openConfig} onClose={() => setOpenConfig(false)}>
        <StyledDrawerContainer>
          {!!token && (
            <Stack spacing={1}>
              {token.url && (
                <Stack>
                  <Typography variant="subtitle2">URL</Typography>

                  <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems="center"
                    spacing={1}
                  >
                    <Typography noWrap variant="caption">
                      {token.url}
                    </Typography>
                    <StyledCopyButton
                      size="extraSmall"
                      onClick={() => {
                        setUrlCopied(true);
                        navigator.clipboard.writeText(token.url!);
                      }}
                      endIcon={
                        urlCopied ? <AssignmentTurnedIn /> : <Assignment />
                      }
                    >
                      Copy URL
                    </StyledCopyButton>
                  </Stack>
                </Stack>
              )}
              <Stack>
                <Typography variant="subtitle2">Token</Typography>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  alignItems="center"
                  spacing={1}
                >
                  <Tooltip title={token.value} arrow>
                    <Typography noWrap variant="caption">
                      {token.value}
                    </Typography>
                  </Tooltip>

                  <StyledCopyButton
                    size="extraSmall"
                    onClick={() => {
                      setTokenCopied(true);
                      navigator.clipboard.writeText(token?.value || "");
                    }}
                    endIcon={
                      tokenCopied ? <AssignmentTurnedIn /> : <Assignment />
                    }
                  >
                    Copy Token
                  </StyledCopyButton>
                </Stack>
              </Stack>
            </Stack>
          )}
        </StyledDrawerContainer>
      </StyledDrawer>
    </>
  );
}

export default APITokenCard;
