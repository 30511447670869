import { ApiProject } from "@incendium/api";
import { personalisationService } from "Apis";
import {
  handleForm,
  changeButtonType,
  setUpForms,
} from "features/uiBuilder/utils/utils";
import { Editor, ProjectData, StorageOptions } from "grapesjs";

export const customOnEditor = (
  editor: Editor,
  selectedProject: ApiProject,
  componentId: number,
  saveComponent: (
    editor: any
  ) => (data: ProjectData, options: StorageOptions) => any
) => {
  editor.StorageManager.add("custom", {
    load() {
      return personalisationService
        .personalisationServiceReadComponent({
          projectId: selectedProject?.id as number,
          componentId: Number(componentId),
        })
        .then((res) => {
          return res.component?.grapeJs
            ? JSON.parse(res.component?.grapeJs)
            : {};
        });
    },
    store: saveComponent(editor),
  });

  setUpForms(editor);

  // Show borders by default
  editor.Panels.getButton("options", "sw-visibility")?.set("active", 1);
  editor.on("load", (editor) => {
    editor.StorageManager.get("custom")!.store = saveComponent(editor);
  });
  editor.on("component:add", handleForm);
  editor.on("component:add", changeButtonType);
  return editor;
};
