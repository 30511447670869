/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiJobTaggerUniqueFieldFromJSON, ApiJobTaggerUniqueFieldToJSON, } from './ApiJobTaggerUniqueField';
import { ApiLocationFromJSON, ApiLocationToJSON, } from './ApiLocation';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiJobTaggerFromJSON(json) {
    return ApiJobTaggerFromJSONTyped(json, false);
}
export function ApiJobTaggerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'uniqueField': !exists(json, 'uniqueField') ? undefined : ApiJobTaggerUniqueFieldFromJSON(json['uniqueField']),
        'titleSelector': !exists(json, 'titleSelector') ? undefined : json['titleSelector'],
        'descriptionSelector': !exists(json, 'descriptionSelector') ? undefined : json['descriptionSelector'],
        'idSelector': !exists(json, 'idSelector') ? undefined : json['idSelector'],
        'categorySelector': !exists(json, 'categorySelector') ? undefined : json['categorySelector'],
        'salaryRangeSelector': !exists(json, 'salaryRangeSelector') ? undefined : json['salaryRangeSelector'],
        'contractTypeSelector': !exists(json, 'contractTypeSelector') ? undefined : json['contractTypeSelector'],
        'locationSelector': !exists(json, 'locationSelector') ? undefined : json['locationSelector'],
        'location': !exists(json, 'location') ? undefined : ApiLocationFromJSON(json['location']),
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}
export function ApiJobTaggerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'uniqueField': ApiJobTaggerUniqueFieldToJSON(value.uniqueField),
        'titleSelector': value.titleSelector,
        'descriptionSelector': value.descriptionSelector,
        'idSelector': value.idSelector,
        'categorySelector': value.categorySelector,
        'salaryRangeSelector': value.salaryRangeSelector,
        'contractTypeSelector': value.contractTypeSelector,
        'locationSelector': value.locationSelector,
        'location': ApiLocationToJSON(value.location),
        'locationId': value.locationId,
        'project': ApiProjectToJSON(value.project),
        'projectId': value.projectId,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}
