import { IStoreState } from "Interfaces";

export const chartSelector =
  (clientId?: string, projectId?: string, chartId?: string) =>
  (state: IStoreState) => {
    if (!clientId || !projectId || !chartId) {
      return null;
    }

    const clientCharts = state.application.charts[clientId];
    if (!clientCharts) {
      return null;
    }
    const charts = clientCharts[projectId].state;
    if (!charts) {
      return null;
    }
    const index = charts.findIndex((item) => item.id === parseInt(chartId));
    if (index === -1) {
      return null;
    }
    return charts[index];
  };
