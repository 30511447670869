import { Grid, Stack } from "@mui/material";
import {
  ApiChartCrawler,
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiYAxisChartType,
} from "@incendium/api";
import { StatCardTypography } from "Components/UI/StatCard";
import {
  AnalyticsCard,
  AnalyticsIncreaseDecreaseStatCard,
  AnalyticsStatCard,
  formatMetric,
} from "features/analytics";
import { AnalyticsSpacing } from "consts";

function Overview({ organicChannelName }: { organicChannelName: string }) {
  return (
    <Grid container spacing={AnalyticsSpacing} mb={4}>
      <Grid item xs={3}>
        <Stack spacing={AnalyticsSpacing}>
          <AnalyticsStatCard
            minHeight={100}
            chart={{
              name: "",
              dimension: [],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes: [
                {
                  key: ApiDimension.DIMENSION_CHANNEL,
                  value: organicChannelName,
                },
              ],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                },
              ],
            }}
            renderBody={(data) => (
              <>
                <StatCardTypography size="small" opactity={0.8}>
                  No. Organic Visits:
                </StatCardTypography>
                <StatCardTypography size="large" opactity={1}>
                  {formatMetric(
                    ApiMetric.METRIC_SESSIONS_COUNT,
                    Number(data[0][ApiMetric.METRIC_SESSIONS_COUNT])
                  )}
                </StatCardTypography>
              </>
            )}
          />

          <AnalyticsIncreaseDecreaseStatCard
            metric={ApiMetric.METRIC_SESSIONS_COUNT}
            chart={{
              name: "",
              dimension: [],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes: [
                {
                  key: ApiDimension.DIMENSION_CHANNEL,
                  value: organicChannelName,
                },
              ],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                },
              ],
            }}
          />
          <AnalyticsStatCard
            chart={{
              name: "",
              dimension: [],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes: [
                {
                  key: ApiDimension.DIMENSION_CHANNEL,
                  value: organicChannelName,
                },
              ],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT],
                },
              ],
            }}
            renderBody={(data) => (
              <>
                <StatCardTypography size="small" opactity={0.8}>
                  Which lead to
                </StatCardTypography>
                <StatCardTypography size="large" lines={1}>
                  {formatMetric(
                    ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
                    Number(
                      data[0][ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT]
                    )
                  )}
                </StatCardTypography>
                <StatCardTypography size="small" opactity={0.8}>
                  Conversions
                </StatCardTypography>
              </>
            )}
          />
          <AnalyticsStatCard
            chart={{
              name: "bots",
              dimension: [],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              crawler: ApiChartCrawler.ONLY_CRAWLERS,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                },
              ],
            }}
            renderBody={(data) => (
              <>
                <StatCardTypography size="small" opactity={0.8}>
                  No. of visits by bots in this
                  <br /> time period
                </StatCardTypography>
                <StatCardTypography size="large" lines={1}>
                  {formatMetric(
                    ApiMetric.METRIC_SESSIONS_COUNT,
                    Number(data[0][ApiMetric.METRIC_SESSIONS_COUNT])
                  )}
                </StatCardTypography>
              </>
            )}
          />
        </Stack>
      </Grid>
      <Grid item xs={9} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          chart={{
            name: "Organic Sessions vs Conversions",
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: organicChannelName,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                chart: {
                  [ApiMetric.METRIC_SESSIONS_COUNT]: ApiYAxisChartType.BAR,
                },
              },
              {
                key: "r",
                fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT],
              },
            ],
            displayOptions: {
              noFilters: true,
              metricsOrder: [
                ApiMetric.METRIC_SESSIONS_COUNT,
                ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
              ],
            },
          }}
        />
      </Grid>
      <Grid item xs={12} minHeight={550}>
        <AnalyticsCard
          chart={{
            name: "Device Performance",
            dimension: [
              ApiDimension.DIMENSION_SESSION_START_BY_DAY,
              ApiDimension.DIMENSION_DEVICE,
            ],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: organicChannelName,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                chart: {
                  [ApiMetric.METRIC_SESSIONS_COUNT]: ApiYAxisChartType.BAR,
                },
                stackId: "l",
              },
            ],
            displayOptions: {
              noFilters: true,
              availableMetrics: [
                ApiMetric.METRIC_SESSIONS_COUNT,
                ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                ApiMetric.METRIC_SESSION_SALES_COUNT,
                ApiMetric.METRIC_SESSION_REVENUE,
                ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
              ],
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default Overview;
