/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiClassificationFromJSON, ApiClassificationToJSON, } from './ApiClassification';
import { ApiClassificationRuleFromJSON, ApiClassificationRuleToJSON, } from './ApiClassificationRule';
import { ApiStatusFromJSON, ApiStatusToJSON, } from './ApiStatus';
export function ApiClassificationItemFromJSON(json) {
    return ApiClassificationItemFromJSONTyped(json, false);
}
export function ApiClassificationItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'classification': !exists(json, 'classification') ? undefined : ApiClassificationFromJSON(json['classification']),
        'value': !exists(json, 'value') ? undefined : json['value'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'rules': !exists(json, 'rules') ? undefined : (json['rules'].map(ApiClassificationRuleFromJSON)),
        'status': !exists(json, 'status') ? undefined : ApiStatusFromJSON(json['status']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
        'syncedWithFb': !exists(json, 'syncedWithFb') ? undefined : json['syncedWithFb'],
        'fbCustomAudienceId': !exists(json, 'fbCustomAudienceId') ? undefined : json['fbCustomAudienceId'],
        'classificationId': !exists(json, 'classificationId') ? undefined : json['classificationId'],
        'param': !exists(json, 'param') ? undefined : json['param'],
    };
}
export function ApiClassificationItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'classification': ApiClassificationToJSON(value.classification),
        'value': value.value,
        'description': value.description,
        'order': value.order,
        'rules': value.rules === undefined ? undefined : (value.rules.map(ApiClassificationRuleToJSON)),
        'status': ApiStatusToJSON(value.status),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'syncedWithFb': value.syncedWithFb,
        'fbCustomAudienceId': value.fbCustomAudienceId,
        'classificationId': value.classificationId,
        'param': value.param,
    };
}
