import { Link, matchPath, useLocation } from "react-router-dom";
import clsx from "clsx";
import { Box, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  LeftMenuVisabilityToggle,
  useLeftmenuProviderContext,
} from "features/leftMenu";
import { ApiACLRole } from "@incendium/api";
import AccessLevel from "Components/AccessLevel/AccessLevel";
import { useUser } from "Hooks/useUser";
import { useMemo } from "react";
import { Star } from "@mui/icons-material";

const useLeftMenuListItemStyles = makeStyles((theme: Theme) => ({
  dot: {
    display: "inline-block",
    position: "absolute",
    backgroundColor: theme.palette.info.main,
    width: 5,
    height: 5,
    borderRadius: "100%",
    top: "50%",
    transform: `translate(-15px, -2px)`,
    opacity: 0,
    transition: `opacity 0.3s linear`,
  },
  hidden: {
    opacity: 0.5,
    pointerEvents: "none",
  },
  listItem: {
    fontSize: 12,
    fontWeight: "normal",
    paddingLeft: "57px",
    paddingRight: "5px",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.common.white,
    textDecoration: "none",
    display: "block",
    position: "relative",
    textTransform: "capitalize",

    "&:hover": {
      "& $dot": {
        opacity: 0.7,
      },
    },
    "&:hover.active": {
      fontWeight: "bold",
      "& $dot": {
        opacity: 1,
      },
    },
    "&.active": {
      fontWeight: "bold",
      "& $dot": {
        opacity: 1,
      },
    },
  },
}));

interface ILeftMenuListItemProps {
  to: string;
  text: string;
  exact?: boolean;
  favourite?: boolean;
}

const LeftMenuListItem = ({
  to,
  text,
  exact,
  favourite,
}: ILeftMenuListItemProps) => {
  const { user } = useUser();
  const classes = useLeftMenuListItemStyles();
  const location = useLocation();
  const hasmatch = matchPath(location.pathname, {
    path: to,
    exact: exact || false,
    strict: false,
  });
  const { leftMenuLinkIsVisible, toggleVisibility } =
    useLeftmenuProviderContext();

  const visible = useMemo(() => {
    return leftMenuLinkIsVisible(to);
  }, [to, leftMenuLinkIsVisible]);

  const isSuper = useMemo(
    () =>
      (user.permissions || []).findIndex(
        (p) => p.role === ApiACLRole.SUPER_ADMIN
      ) >= 0,
    [user]
  );

  if (!isSuper && !visible) {
    return <></>;
  }

  return (
    <Link
      to={to}
      className={clsx(classes.listItem, {
        active: hasmatch,
        [classes.hidden]: !leftMenuLinkIsVisible(to),
      })}
    >
      <span className={classes.dot}></span>
      {text}
      {!favourite && (
        <AccessLevel role={ApiACLRole.SUPER_ADMIN}>
          <LeftMenuVisabilityToggle
            to={to}
            toggleVisible={toggleVisibility}
            visible={leftMenuLinkIsVisible(to)}
          />
        </AccessLevel>
      )}
      {favourite && (
        <Box sx={{ position: "absolute", right: 20, opacity: 0.4, top: 8 }}>
          <Star fontSize="extraSmall" />
        </Box>
      )}
    </Link>
  );
};

export default LeftMenuListItem;
