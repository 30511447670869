/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiActionFromJSON, ApiActionToJSON, } from './ApiAction';
import { ApiFormRuleFromJSON, ApiFormRuleToJSON, } from './ApiFormRule';
import { ApiLocationFromJSON, ApiLocationToJSON, } from './ApiLocation';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiFormFromJSON(json) {
    return ApiFormFromJSONTyped(json, false);
}
export function ApiFormFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'target': !exists(json, 'target') ? undefined : json['target'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'location': !exists(json, 'location') ? undefined : ApiLocationFromJSON(json['location']),
        'rules': !exists(json, 'rules') ? undefined : (json['rules'].map(ApiFormRuleFromJSON)),
        'preventSubmit': !exists(json, 'preventSubmit') ? undefined : json['preventSubmit'],
        'actions': !exists(json, 'actions') ? undefined : (json['actions'].map(ApiActionFromJSON)),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
    };
}
export function ApiFormToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'target': value.target,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'project': ApiProjectToJSON(value.project),
        'location': ApiLocationToJSON(value.location),
        'rules': value.rules === undefined ? undefined : (value.rules.map(ApiFormRuleToJSON)),
        'preventSubmit': value.preventSubmit,
        'actions': value.actions === undefined ? undefined : (value.actions.map(ApiActionToJSON)),
        'projectId': value.projectId,
    };
}
