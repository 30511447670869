import { Grid, Stack } from "@mui/material";
import {
  AnalyticsCard,
  AnalyticsChartStatCard,
  BucketsROICharts,
} from "features/analytics";
import {
  ApiChartLayout,
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiProjectSettingKey,
  ApiYAxisChartType,
} from "@incendium/api";
import { useSelectedProject } from "Hooks";
import { AnalyticsSpacing } from "consts";

function EcomInsights() {
  const { projectSettings } = useSelectedProject();
  return (
    <Grid container spacing={AnalyticsSpacing} mb={4}>
      <Grid item xs={4}>
        <AnalyticsChartStatCard
          chart={{
            name: "Overall Contribution Margin",
            dimension: [ApiDimension.DIMENSION_NOT_SET],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            comparison: true,
            attributes: [],
            displayOptions: {
              statTitle: "Overall Contribution Margin",
            },
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSION_CONTRIBUTION_MARGIN],
              },
            ],
          }}
          noToolbar
        />
      </Grid>
      <Grid item xs={4}>
        <AnalyticsChartStatCard
          chart={{
            name: "Overall ROI",
            dimension: [ApiDimension.DIMENSION_NOT_SET],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROI],
              },
            ],
            displayOptions: {
              statTitle: "Overall ROI",
            },
            comparison: true,
          }}
          noToolbar
        />
      </Grid>
      <Grid item xs={4}>
        <AnalyticsChartStatCard
          chart={{
            name: "Overall MER",
            dimension: [ApiDimension.DIMENSION_NOT_SET],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSION_EFFICIENCY_RATION],
              },
            ],
            displayOptions: {
              statTitle: "Overall MER",
            },
            comparison: true,
          }}
          noToolbar
        />
      </Grid>
      <Grid item xs={12} sx={{ height: 400 }}>
        <AnalyticsCard
          chart={{
            name: "Ecommerce conversion funnel",
            dimension: [],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.FUNNEL,
            comparison: true,
            attributes: [],
            displayOptions: {
              funnelUseLogFn: true,
              fullAttributesList: [ApiDimension.DIMENSION_NOT_SET],
              noAttributionToggle: true,
              funnelAdditonalMetrics: [
                {
                  key: ApiMetric.METRIC_SESSIONS_COUNT,
                  metrics: [
                    ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                    ApiMetric.METRIC_PAID_SESSION_COUNT,
                    ApiMetric.METRIC_ORGANIC_SESSION_COUNT,
                  ],
                },
                {
                  key: ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
                  metrics: [
                    ApiMetric.METRIC_PRODUCT_PAGE_EFFECTIVENESS_RATE,
                    ApiMetric.METRIC_PRODUCT_IMPRESSION_COUNT,
                  ],
                },
                {
                  key: ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_COUNT,
                  metrics: [
                    ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_RATE,
                    ApiMetric.METRIC_CART_ABANDONMENT_RATE,
                  ],
                },
                {
                  key: ApiMetric.METRIC_CHECKOUT_START_COUNT,
                  metrics: [
                    ApiMetric.METRIC_CHECKOUT_START_RATE,
                    ApiMetric.METRIC_CHECKOUT_ABANDONMENT_RATE,
                  ],
                },
                {
                  key: ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_SALES_COUNT,
                  metrics: [
                    ApiMetric.METRIC_SESSION_REVENUE,
                    ApiMetric.METRIC_NEW_CUSTOMER_COUNT,
                    ApiMetric.METRIC_AVERAGE_SALE_VALUE,
                  ],
                },
              ],
            },
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_PAID_SESSION_COUNT,
                  ApiMetric.METRIC_ORGANIC_SESSION_COUNT,
                  ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
                  ApiMetric.METRIC_PRODUCT_PAGE_EFFECTIVENESS_RATE,
                  ApiMetric.METRIC_PRODUCT_IMPRESSION_COUNT,
                  ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_COUNT,
                  ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_RATE,
                  ApiMetric.METRIC_CART_ABANDONMENT_RATE,
                  ApiMetric.METRIC_CHECKOUT_START_COUNT,
                  ApiMetric.METRIC_CHECKOUT_START_RATE,
                  ApiMetric.METRIC_CHECKOUT_ABANDONMENT_RATE,
                  ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_SALES_COUNT,
                  ApiMetric.METRIC_SESSION_REVENUE,
                  ApiMetric.METRIC_NEW_CUSTOMER_COUNT,
                  ApiMetric.METRIC_AVERAGE_SALE_VALUE,
                ],
              },
            ],
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: "Total Revenue",
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [],
            displayOptions: {
              noLegend: true,
            },
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSION_REVENUE],
                chart: {
                  [ApiMetric.METRIC_SESSION_REVENUE]: ApiYAxisChartType.BAR,
                },
              },
            ],
          }}
        />
      </Grid>
      <Grid item xs={8} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: "Contibution Margin Trend",
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSION_CONTRIBUTION_MARGIN],
              },
            ],
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          overrideDate={{
            lastNDays: 365,
            from: undefined,
            to: undefined,
          }}
          chart={{
            name: "Historical Contribution Margin",
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_MONTH],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSION_CONTRIBUTION_MARGIN],
                chart: {
                  [ApiMetric.METRIC_SESSION_CONTRIBUTION_MARGIN]:
                    ApiYAxisChartType.BAR,
                },
              },
            ],
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: "Marketing Effiency Ratio (MER)",
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSION_EFFICIENCY_RATION],
              },
            ],
          }}
        />
      </Grid>

      <Grid item xs={8} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: "Overall Revenue by Top 5 Channels",
            dimension: [ApiDimension.DIMENSION_CHANNEL],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [],
            limit: 5,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_REVENUE],
                chart: {
                  [ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_REVENUE]:
                    ApiYAxisChartType.BAR,
                },
              },
            ],
            displayOptions: {
              layout: ApiChartLayout.VERTICAL,
              noLegend: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 400 }}>
        <Stack spacing={AnalyticsSpacing}>
          <AnalyticsChartStatCard
            chart={{
              name: "Total Revenue",
              dimension: [ApiDimension.DIMENSION_NOT_SET],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSION_REVENUE],
                },
              ],
              displayOptions: {
                statTitle: "Total Revenue",
              },
              comparison: true,
            }}
            noToolbar
          />
          <AnalyticsChartStatCard
            chart={{
              name: "Existing customer revenue growth rate (ECRGR)",
              dimension: [ApiDimension.DIMENSION_NOT_SET],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [
                    ApiMetric.METRIC_EXISTING_CUSTOMER_REVENUE_GROWTH_RATE,
                  ],
                },
              ],
              displayOptions: {
                statTitle: "Existing customer revenue growth rate (ECRGR)",
              },
              comparison: true,
            }}
            noToolbar
          />
          <AnalyticsChartStatCard
            chart={{
              name: " Cost Per New visit",
              dimension: [ApiDimension.DIMENSION_NOT_SET],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_CPNV],
                },
              ],
              displayOptions: {
                statTitle: "Cost Per New visit",
              },
              comparison: true,
            }}
            noToolbar
          />
        </Stack>
      </Grid>
      <Grid item xs={8}>
        <AnalyticsCard
          chart={{
            name: "LTV:CAC Ratio",
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_LTVCAC_RATIO],
              },
            ],
          }}
        />
      </Grid>

      {projectSettings[
        ApiProjectSettingKey.SHOW_AWARENESS_ACQUISTION_RETENTION_INSIGHTS
      ] === "true" && (
        <Grid item xs={12}>
          <BucketsROICharts
            dimensions={[
              ApiDimension.DIMENSION_CHANNEL,
              ApiDimension.DIMENSION_SOURCE,
              ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
              ApiDimension.DIMENSION_TERM,
            ]}
          />
        </Grid>
      )}
      <Grid item xs={4} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: "Total Sales by Channel",
            dimension: [ApiDimension.DIMENSION_CHANNEL],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.PIE,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_SALES_COUNT],
              },
            ],
            displayOptions: {
              showTotals: true,
              noAttributionToggle: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: "Total New Customers by Channel",
            dimension: [ApiDimension.DIMENSION_CHANNEL],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.PIE,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_NEW_CUSTOMER_COUNT],
              },
            ],
            displayOptions: {
              showTotals: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: "Total Returning Customers by Channel",
            dimension: [ApiDimension.DIMENSION_CHANNEL],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.PIE,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_RETURNING_CUSTOMER_COUNT],
              },
            ],
            displayOptions: {
              showTotals: true,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default EcomInsights;
