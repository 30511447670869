/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiChartVisibility;
(function (ApiChartVisibility) {
    ApiChartVisibility["UNSPECIFIED"] = "CHART_VISIBILITY_UNSPECIFIED";
    ApiChartVisibility["REGULAR"] = "CHART_VISIBILITY_REGULAR";
    ApiChartVisibility["OVERRIDES"] = "CHART_VISIBILITY_OVERRIDES";
})(ApiChartVisibility || (ApiChartVisibility = {}));
export function ApiChartVisibilityFromJSON(json) {
    return ApiChartVisibilityFromJSONTyped(json, false);
}
export function ApiChartVisibilityFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiChartVisibilityToJSON(value) {
    return value;
}
