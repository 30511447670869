/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiOperatorFromJSON, ApiOperatorToJSON, } from './ApiOperator';
import { ApiSessionConditionCustomFromJSON, ApiSessionConditionCustomToJSON, } from './ApiSessionConditionCustom';
export function ApiSessionEffectivenessConditionPayloadFromJSON(json) {
    return ApiSessionEffectivenessConditionPayloadFromJSONTyped(json, false);
}
export function ApiSessionEffectivenessConditionPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'operator': !exists(json, 'operator') ? undefined : ApiOperatorFromJSON(json['operator']),
        'custom': !exists(json, 'custom') ? undefined : ApiSessionConditionCustomFromJSON(json['custom']),
        'value': !exists(json, 'value') ? undefined : json['value'],
        'conversionId': !exists(json, 'conversionId') ? undefined : json['conversionId'],
    };
}
export function ApiSessionEffectivenessConditionPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'operator': ApiOperatorToJSON(value.operator),
        'custom': ApiSessionConditionCustomToJSON(value.custom),
        'value': value.value,
        'conversionId': value.conversionId,
    };
}
