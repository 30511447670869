import {
  ApiLocation,
  ApiPageviewMetaTagger,
  ApiProject,
  ApiReservedPageviewMeta,
} from "@incendium/api";
import { useCallback, useState } from "react";
import { SidebarStyledDivider } from "Components/UI/Sidebar";
import { useNotification } from "Hooks";
import produce from "immer";
import { usePageviewMetaTaggers } from "Hooks/usePageviewMetaTaggers";
import { AccordianChartBuilderSidebarBlock } from "features/chartLibrary";
import {
  OutOfStockForm,
  OrderNumberForm,
  savePageviewMeta,
} from "features/pageMeta";

function PageMetaTab({
  project,
  location,
}: {
  location: ApiLocation;
  project: ApiProject;
}) {
  const [openType, setOpenType] = useState<ApiReservedPageviewMeta>(
    ApiReservedPageviewMeta.RESERVERED_UNSPECIFIED
  );
  const { pageviewMetaTaggers, setPageviewMetaTaggers } =
    usePageviewMetaTaggers(location.id);
  const { showSuccessNotification, showErrorNotification } = useNotification();

  const handleSubmit = useCallback(
    async (tagger: ApiPageviewMetaTagger) => {
      try {
        const res = await savePageviewMeta(
          project.id as number,
          location.id as number,
          tagger
        );
        setPageviewMetaTaggers(
          produce(pageviewMetaTaggers, (draft) => {
            const idx = draft.findIndex((d) => d.id === res.id);
            if (idx >= 0) {
              draft[idx] = res;
            } else {
              draft.push(res);
            }
          })
        );

        showSuccessNotification("Page Meta Set");
      } catch (e) {
        showErrorNotification("Failed to save Page Meta");
      }
    },
    [
      project,
      location,
      pageviewMetaTaggers,
      setPageviewMetaTaggers,
      showErrorNotification,
      showSuccessNotification,
    ]
  );

  const handleOnOpenClose = useCallback(
    (type: ApiReservedPageviewMeta) => (open: boolean) => {
      if (open && openType !== type) {
        setOpenType(type);
        return;
      }
      if (!open && openType === type) {
        setOpenType(ApiReservedPageviewMeta.RESERVERED_UNSPECIFIED);
      }
    },
    [openType]
  );

  return (
    <>
      <SidebarStyledDivider />
      <AccordianChartBuilderSidebarBlock
        defaultClosed={
          openType !== ApiReservedPageviewMeta.RESERVERED_OUT_OF_STOCK
        }
        sx={{ py: 2, px: 0 }}
        title="Out Of Stock"
        subTitle="Create a rule to let Incendium know if a pageview contains an out of stock item."
        onOpenClose={handleOnOpenClose(
          ApiReservedPageviewMeta.RESERVERED_OUT_OF_STOCK
        )}
      >
        {openType === ApiReservedPageviewMeta.RESERVERED_OUT_OF_STOCK && (
          <OutOfStockForm
            pageviewMetaTaggers={pageviewMetaTaggers}
            onSubmit={handleSubmit}
          />
        )}
      </AccordianChartBuilderSidebarBlock>
      <AccordianChartBuilderSidebarBlock
        defaultClosed={openType !== ApiReservedPageviewMeta.RESERVED_ORDER_ID}
        sx={{ py: 2, px: 0 }}
        title="Order Number"
        subTitle="Create a rule to let Incendium know if this page contains an order number. This can help us with attributing sales."
        onOpenClose={handleOnOpenClose(
          ApiReservedPageviewMeta.RESERVED_ORDER_ID
        )}
      >
        {openType === ApiReservedPageviewMeta.RESERVED_ORDER_ID && (
          <OrderNumberForm
            pageviewMetaTaggers={pageviewMetaTaggers}
            onSubmit={handleSubmit}
          />
        )}
      </AccordianChartBuilderSidebarBlock>
    </>
  );
}

export default PageMetaTab;
