import {
  CHECK_LOGIN,
  CHECK_LOGIN_FAILED,
  CHECK_LOGIN_SUCCESS,
  IAuthActions,
  LOGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  SET_USER,
  TOKEN_REFRESHED,
} from "Actions";
import { IAuthState } from "Interfaces";
import { Reducer } from "redux";

export const authReducer: Reducer<IAuthState, IAuthActions> = (
  state = { tokens: null, status: "INIT", user: null },
  action
): IAuthState => {
  switch (action.type) {
    case LOGIN:
    case CHECK_LOGIN: {
      return {
        ...state,
        status: CHECK_LOGIN,
        tokens: null,
      };
    }
    case CHECK_LOGIN_SUCCESS:
    case TOKEN_REFRESHED:
    case LOGIN_SUCCESS: {
      return { ...state, status: action.type, tokens: action.payload };
    }
    case LOGOUT:
    case CHECK_LOGIN_FAILED:
    case LOGIN_ERROR: {
      return { ...state, status: action.type, tokens: null };
    }
    case SET_USER: {
      return { ...state, status: action.type, user: action.payload };
    }
  }
  return state;
};
