import { ApiEffectivenessRule, ApiLocation } from "@incendium/api";
import { Box, Typography } from "@mui/material";
import {
  SidebarContentWrapper,
  SidebarStyledDivider,
  SiderBarHeader,
} from "Components/UI/Sidebar";
import produce from "immer";
import { useEffect, useState } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import LocationSidebarSelector from "Components/Location/LocationSidebarSelector";
import { TEffectivenessRule } from "Interfaces";

interface IEffectivenessSidebarProps {
  effectiveness: TEffectivenessRule;
  setEffectiveness: React.Dispatch<
    React.SetStateAction<TEffectivenessRule | undefined>
  >;
  isPageview: Boolean;
}

function EffectivenessSidebar({
  effectiveness,
  setEffectiveness,
  isPageview,
}: IEffectivenessSidebarProps) {
  const [locations, setLocations] = useState<ApiLocation[]>(
    isPageview ? (effectiveness as ApiEffectivenessRule).locations || [] : []
  );

  useEffect(() => {
    if (!isPageview) {
      return;
    }
    setEffectiveness(
      produce(effectiveness as ApiEffectivenessRule, (draft) => {
        draft.locations = locations;
      })
    );
  }, [locations]);

  return (
    <>
      <SiderBarHeader>
        <Typography variant="h2">{effectiveness.name} Settings</Typography>
        <Typography>
          Assign a name to this effectiveness rule, you can also select the
          location this rule applies to or leave blank to apply to all locations
        </Typography>
      </SiderBarHeader>
      <SidebarContentWrapper px={3}>
        <Box mb={3.5} mt={2}>
          <TextValidator
            label="name of rule"
            variant="outlined"
            name="name"
            fullWidth
            validators={["required"]}
            errorMessages={["name is required"]}
            value={effectiveness.name || ""}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setEffectiveness(
                produce(effectiveness, (draft) => {
                  draft.name = e.currentTarget.value;
                })
              );
            }}
          />
        </Box>

        {isPageview && (
          <>
            <Box mt={4.5} mb={2}>
              <SidebarStyledDivider />
            </Box>

            <LocationSidebarSelector
              locations={locations}
              setLocations={setLocations}
            />
          </>
        )}
      </SidebarContentWrapper>
    </>
  );
}

export default EffectivenessSidebar;
