import {
  ApiConditionCustom,
  ApiConditionGroup,
  ApiSessionConditionCustom,
} from "@incendium/api";
import { Box, Grid, InputAdornment, MenuItem, TextField } from "@mui/material";
import ConditionalGroup from "Components/ConditionalGroup/ConditionalGroup";
import { useConditionGroupContext } from "Components/ConditionalGroup/ConditionGroupProvider";
import ConversionsDropdown from "Components/Conversions/ConversionsDropdown";
import { engagementRuleType } from "Components/Engagement/EngagementRules";
import produce from "immer";
import { useMemo, useState } from "react";
import { useEffect } from "react";

const Row = ({
  groupIdx,
  rowIdx,
  types,
}: {
  groupIdx: number;
  rowIdx: number;
  types: string[];
}) => {
  const { conditionGroups, setConditionGroups } = useConditionGroupContext();
  const [type, setType] = useState<
    ApiConditionCustom | ApiSessionConditionCustom | undefined
  >(ApiConditionCustom.UNSPECIFIED);

  // todo: work out type
  const condition: any = useMemo(() => {
    return (conditionGroups[groupIdx]?.conditions || [])[rowIdx] || {};
  }, [conditionGroups, groupIdx, rowIdx]);

  useEffect(() => {
    setType(condition.custom || undefined);
  }, [condition]);

  const value = useMemo(() => {
    return (conditionGroups[groupIdx]?.conditions || [])[rowIdx]?.value || "";
  }, [conditionGroups, groupIdx, rowIdx]);

  const conversionId = useMemo(() => {
    return condition?.conversionId || "";
  }, [condition]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField
          select
          variant="outlined"
          fullWidth
          label="Type"
          value={type}
          onChange={(e) => {
            // setType(e.target.value);
            setConditionGroups(
              produce(conditionGroups, (draft: ApiConditionGroup[]) => {
                (draft[groupIdx]?.conditions || [])[rowIdx].custom = e.target
                  .value as ApiConditionCustom;
              })
            );
          }}
        >
          {types
            .sort((a, b) =>
              (engagementRuleType(a) || "").localeCompare(
                engagementRuleType(b) || ""
              )
            )
            .map((t) => (
              <MenuItem key={t} value={t}>
                {engagementRuleType(t)}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        {type &&
        [
          ApiConditionCustom.CONVERSION_TRIGGERED,
          ApiSessionConditionCustom.CONVERSION_TRIGGERED,
        ].includes(type) ? (
          <ConversionsDropdown
            label="Conversion"
            variant="outlined"
            fullWidth
            value={conversionId}
            disableClearable
            onChange={(id) => {
              setConditionGroups(
                produce(conditionGroups, (draft: ApiConditionGroup[]) => {
                  (draft[groupIdx]?.conditions || [])[rowIdx].conversionId = id;
                })
              );
            }}
          />
        ) : (
          <TextField
            label="value"
            value={value}
            fullWidth
            onChange={(e) => {
              setConditionGroups(
                produce(conditionGroups, (draft: ApiConditionGroup[]) => {
                  (draft[groupIdx]?.conditions || [])[rowIdx].value =
                    e.target.value;
                })
              );
            }}
            type={"number"}
            InputProps={{
              startAdornment: [
                ApiConditionCustom.TIME_ON_PAGE_PERCENTILE,
                ApiConditionCustom.SCROLL_RATE_PERCENTILE,
                ApiConditionCustom.VIEW_RATE_PERCENTILE,
              ].includes(type as ApiConditionCustom) ? (
                <InputAdornment position="start">%</InputAdornment>
              ) : (
                ""
              ),
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

function EffectivenessRules({ types }: { types: string[] }) {
  const { setRowComponent } = useConditionGroupContext();

  useEffect(() => {
    setRowComponent(() => (groupIdx, rowIdx) => {
      return <Row types={types} groupIdx={groupIdx} rowIdx={rowIdx} />;
    });
  }, []);

  return (
    <Box>
      <ConditionalGroup />
    </Box>
  );
}

export default EffectivenessRules;
