/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiChartFromJSON, ApiChartToJSON, } from './ApiChart';
import { ApiReportTabFromJSON, ApiReportTabToJSON, } from './ApiReportTab';
import { ApiTabElementFromJSON, ApiTabElementToJSON, } from './ApiTabElement';
export function ApiTabChartFromJSON(json) {
    return ApiTabChartFromJSONTyped(json, false);
}
export function ApiTabChartFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'chart': !exists(json, 'chart') ? undefined : ApiChartFromJSON(json['chart']),
        'chartId': !exists(json, 'chartId') ? undefined : json['chartId'],
        'tab': !exists(json, 'tab') ? undefined : ApiReportTabFromJSON(json['tab']),
        'tabId': !exists(json, 'tabId') ? undefined : json['tabId'],
        'attributes': !exists(json, 'attributes') ? undefined : json['attributes'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'overridingChart': !exists(json, 'overridingChart') ? undefined : ApiChartFromJSON(json['overridingChart']),
        'overridingChartId': !exists(json, 'overridingChartId') ? undefined : json['overridingChartId'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'tabElement': !exists(json, 'tabElement') ? undefined : ApiTabElementFromJSON(json['tabElement']),
        'tabElementId': !exists(json, 'tabElementId') ? undefined : json['tabElementId'],
    };
}
export function ApiTabChartToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'chart': ApiChartToJSON(value.chart),
        'chartId': value.chartId,
        'tab': ApiReportTabToJSON(value.tab),
        'tabId': value.tabId,
        'attributes': value.attributes,
        'order': value.order,
        'overridingChart': ApiChartToJSON(value.overridingChart),
        'overridingChartId': value.overridingChartId,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'tabElement': ApiTabElementToJSON(value.tabElement),
        'tabElementId': value.tabElementId,
    };
}
