/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiComponentUISourceFromJSON, ApiComponentUISourceToJSON, } from './ApiComponentUISource';
export function ApiCreateUpdateComponentPayloadFromJSON(json) {
    return ApiCreateUpdateComponentPayloadFromJSONTyped(json, false);
}
export function ApiCreateUpdateComponentPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'html': !exists(json, 'html') ? undefined : json['html'],
        'css': !exists(json, 'css') ? undefined : json['css'],
        'js': !exists(json, 'js') ? undefined : json['js'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'grapeJs': !exists(json, 'grapeJs') ? undefined : json['grapeJs'],
        'uiSource': !exists(json, 'uiSource') ? undefined : ApiComponentUISourceFromJSON(json['uiSource']),
        'head': !exists(json, 'head') ? undefined : json['head'],
        'seoTitle': !exists(json, 'seoTitle') ? undefined : json['seoTitle'],
        'seoDescrition': !exists(json, 'seoDescrition') ? undefined : json['seoDescrition'],
        'favicon': !exists(json, 'favicon') ? undefined : json['favicon'],
    };
}
export function ApiCreateUpdateComponentPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'html': value.html,
        'css': value.css,
        'js': value.js,
        'name': value.name,
        'description': value.description,
        'grapeJs': value.grapeJs,
        'uiSource': ApiComponentUISourceToJSON(value.uiSource),
        'head': value.head,
        'seoTitle': value.seoTitle,
        'seoDescrition': value.seoDescrition,
        'favicon': value.favicon,
    };
}
