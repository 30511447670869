/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiDiscountTypeFromJSON, ApiDiscountTypeToJSON, } from './ApiDiscountType';
export function ApiDiscountFromJSON(json) {
    return ApiDiscountFromJSONTyped(json, false);
}
export function ApiDiscountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : ApiDiscountTypeFromJSON(json['type']),
        'code': !exists(json, 'code') ? undefined : json['code'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'saleId': !exists(json, 'saleId') ? undefined : json['saleId'],
        'saleItemId': !exists(json, 'saleItemId') ? undefined : json['saleItemId'],
        'discountTotal': !exists(json, 'discountTotal') ? undefined : json['discountTotal'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
    };
}
export function ApiDiscountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'type': ApiDiscountTypeToJSON(value.type),
        'code': value.code,
        'projectId': value.projectId,
        'saleId': value.saleId,
        'saleItemId': value.saleItemId,
        'discountTotal': value.discountTotal,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}
