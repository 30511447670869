/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiCountryFromJSON, ApiCountryToJSON, } from './ApiCountry';
import { ApiRegionFromJSON, ApiRegionToJSON, } from './ApiRegion';
export function ApiCityFromJSON(json) {
    return ApiCityFromJSONTyped(json, false);
}
export function ApiCityFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'country': !exists(json, 'country') ? undefined : ApiCountryFromJSON(json['country']),
        'countryId': !exists(json, 'countryId') ? undefined : json['countryId'],
        'region': !exists(json, 'region') ? undefined : ApiRegionFromJSON(json['region']),
        'regionId': !exists(json, 'regionId') ? undefined : json['regionId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}
export function ApiCityToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'country': ApiCountryToJSON(value.country),
        'countryId': value.countryId,
        'region': ApiRegionToJSON(value.region),
        'regionId': value.regionId,
        'name': value.name,
    };
}
