import { ApiPageType, ApiProject } from "@incendium/api";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { locationService } from "Apis";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import { GenericDialoagProps } from "Interfaces";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

interface IPageTypeDialogProps extends GenericDialoagProps {
  project: ApiProject;
  pageType: ApiPageType;
  onSaved: (pageType: ApiPageType) => void;
}

function PageTypeDialog({
  open,
  setOpen,
  pageType,
  project,
  onSaved,
}: IPageTypeDialogProps) {
  const [name, setName] = useState<string>();

  useEffect(() => {
    setName(pageType.name || "");
  }, [open, pageType]);

  const onClose = () => {
    setOpen(false);
  };

  const onSave = async () => {
    const fn = pageType.id
      ? locationService.locationServiceUpdatePageType({
          projectId: project.id as number,
          pageTypeId: pageType.id,
          payload: {
            name,
          },
        })
      : locationService.locationServiceCreatePageType({
          projectId: project.id as number,
          payload: {
            name,
          },
        });
    const res = await fn;
    onSaved(res);
    onClose();
  };

  return (
    <CustomDialog onClose={onClose} open={open} fullWidth maxWidth="xs">
      <DialogTitle>
        {pageType.id ? `Update Location Tag` : "Add a Location Tag"}
      </DialogTitle>
      <ValidatorForm onSubmit={onSave}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                label="Name"
                validators={["required"]}
                name="name"
                value={name || ""}
                variant="outlined"
                size="small"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setName(e.target.value)
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {pageType.id ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </ValidatorForm>
    </CustomDialog>
  );
}

export default PageTypeDialog;
