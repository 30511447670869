import { Stack, Typography, TypographyProps } from "@mui/material";
import { Box } from "@mui/system";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";

interface ITypographyHelpProps extends TypographyProps {
  text: string;
  tooltip: React.ReactChild | React.ReactFragment | React.ReactPortal;
  width?: number;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  iconSize?: "extraSmall" | "small" | "inherit" | "medium" | "large";
  justifyContent?: string;
}

export const TypographyHelp = ({
  text,
  tooltip,
  width,
  iconSize,
  placement,
  justifyContent,
  ...rest
}: ITypographyHelpProps) => (
  <Stack
    direction={"row"}
    alignItems="center"
    spacing={1}
    justifyContent={justifyContent}
    sx={{
      width: "100%",
      "& .help": {
        marginBottom: rest.gutterBottom ? "0.35rem" : 0,
      },
    }}
  >
    <Typography {...rest}>{text}</Typography>
    <Box className="help" display="flex" alignItems="center">
      <ClickableTooltip
        text={tooltip}
        width={width}
        placement={placement}
        icon={<StyledHelp fontSize={iconSize} />}
      />
    </Box>
  </Stack>
);
