/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var LocationItemLocationItemType;
(function (LocationItemLocationItemType) {
    LocationItemLocationItemType["LOCATION_TYPE_UNSPECIFIED"] = "LOCATION_TYPE_UNSPECIFIED";
    LocationItemLocationItemType["PATH"] = "PATH";
    LocationItemLocationItemType["QUERY"] = "QUERY";
    LocationItemLocationItemType["PAGEDATA"] = "PAGEDATA";
    LocationItemLocationItemType["DOMAIN"] = "DOMAIN";
})(LocationItemLocationItemType || (LocationItemLocationItemType = {}));
export function LocationItemLocationItemTypeFromJSON(json) {
    return LocationItemLocationItemTypeFromJSONTyped(json, false);
}
export function LocationItemLocationItemTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function LocationItemLocationItemTypeToJSON(value) {
    return value;
}
