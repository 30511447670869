import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { fetchAllClientsAndProjectsThunk } from "Actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { projectSelector } from "Selectors/projectSelector";
import { ApiURL } from "config";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import { Glass } from "Components/Glass";
import { ProjectDialog, ProjectOptions } from "features/project";
import AccessLevel from "Components/AccessLevel/AccessLevel";
import { ApiACLRole } from "@incendium/api";
import CopyClipboardBtn from "Components/CopyClipboardBtn/CopyClipboardBtn";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { CenterPage } from "Components/CenterPage/CenterPage";
import Loading from "Components/Loading/Loading";
import { Edit, Settings } from "@mui/icons-material";
import { appendQueryParams } from "@incendium/inc-ts-helpers";
import withPagetitle from "HoC/withPagetitle";
import { useSelectedProject } from "Hooks";

const NoInitialLoadModeHelp = () => (
  <>
    <p>
      Turning no initial load on means that incendium js will not make the
      initial calls to track your pageview etc
    </p>
    <p>
      An example use case of this would be when using incendium on a SPA, where
      you want to manage the control of incendium via using a library or the inc
      function.
    </p>
  </>
);

function ProjectPageInner() {
  const { clientId, projectId } = useParams<{
    clientId: string;
    projectId: string;
  }>();
  const dispatch = useDispatch();
  const [noInitialLoadMode, setNoInitialLoadMode] = useState(false);
  const [code, setCode] = useState("");
  const [open, setOpen] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  const project = useSelector(projectSelector(clientId, projectId));
  useEffect(() => {
    dispatch(
      fetchAllClientsAndProjectsThunk({
        clientId: Number(clientId),
        projectId: Number(projectId),
      })
    );
  }, [clientId, projectId, dispatch]);

  useEffect(() => {
    let url = `${ApiURL}/firefly/${project?.key}/incendium.js`;

    if (noInitialLoadMode) {
      url = appendQueryParams(url, ["no_initial", "true"]);
    }

    setCode(
      `<script>\n\twindow.inc=window.inc||function(){\n\t\t(inc.args=inc.args||[]).push(arguments)\n\t};\n</script>\n<script src="${url}"></script>`
    );
  }, [project, noInitialLoadMode]);

  const renderData = () => {
    return (
      <CenterPage calcHeight sm={10}>
        {!project ? (
          <Loading />
        ) : (
          <>
            <Grid container spacing={6} alignItems="center">
              <Grid item xs={5}>
                <Typography variant="h1" mb={1}>
                  {project?.name}
                </Typography>

                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent="center"
                  mt={4}
                >
                  <Button
                    variant="outlined"
                    onClick={() => setOpen(true)}
                    startIcon={<Edit />}
                    color="primary"
                  >
                    Edit Project
                  </Button>
                  <AccessLevel role={ApiACLRole.PROJECT_ADMIN} write>
                    <Button
                      variant="outlined"
                      onClick={() => setOptionsOpen(true)}
                      startIcon={<Settings />}
                    >
                      Project Options
                    </Button>
                  </AccessLevel>
                </Stack>
              </Grid>
              <Grid item xs={7}>
                <Glass elevation={1}>
                  <Box p={2}>
                    <Grid container justifyContent="space-between">
                      <div>
                        <Grid container alignItems="center">
                          <Stack direction={"row"} spacing={2}>
                            <StyledFlexBox compact>
                              <Box display="flex" alignItems="center" mr={2}>
                                <Switch
                                  checked={noInitialLoadMode}
                                  onChange={(e, checked) =>
                                    setNoInitialLoadMode(checked)
                                  }
                                />
                                <Typography
                                  color={
                                    noInitialLoadMode ? "primary" : "secondary"
                                  }
                                >
                                  No Initial Load
                                </Typography>
                              </Box>
                              <ClickableTooltip
                                placement="right"
                                text={<NoInitialLoadModeHelp />}
                                icon={<StyledHelp />}
                              />
                            </StyledFlexBox>
                          </Stack>
                        </Grid>
                      </div>
                      <CopyClipboardBtn
                        copied={copied}
                        variant="outlined"
                        setCopied={setCopied}
                        text={code}
                        size="small"
                      >
                        Copy
                      </CopyClipboardBtn>
                    </Grid>
                    <br />
                    <Divider />
                    <br />
                    <pre>{code}</pre>
                  </Box>
                </Glass>
              </Grid>
            </Grid>
            {open && (
              <ProjectDialog project={project} open={open} setOpen={setOpen} />
            )}
            <ProjectOptions
              open={optionsOpen}
              setOpen={setOptionsOpen}
              project={project}
            />
          </>
        )}
      </CenterPage>
    );
  };

  return <Box>{renderData()}</Box>;
}

const EnhancedComponent = withPagetitle(ProjectPageInner);

function ProjectPage() {
  const { selectedProject } = useSelectedProject();
  return <EnhancedComponent title={`Manage ${selectedProject?.name}`} />;
}

export default ProjectPage;
