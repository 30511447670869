/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiEngagementPayloadToJSON, ApiEngagementRuleFromJSON, ApiListEngagementResponseFromJSON, } from '../models';
/**
 *
 */
export class EngagementServiceApi extends runtime.BaseAPI {
    /**
     */
    engagementServiceCreateEngagementRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling engagementServiceCreateEngagement.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling engagementServiceCreateEngagement.');
            }
            const queryParameters = {};
            if (requestParameters.engagementId !== undefined) {
                queryParameters['engagementId'] = requestParameters.engagementId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/engagement/v1/projects/{projectId}/engagements`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiEngagementPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiEngagementRuleFromJSON(jsonValue));
        });
    }
    /**
     */
    engagementServiceCreateEngagement(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.engagementServiceCreateEngagementRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    engagementServiceDeleteEngagementRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling engagementServiceDeleteEngagement.');
            }
            if (requestParameters.engagementId === null || requestParameters.engagementId === undefined) {
                throw new runtime.RequiredError('engagementId', 'Required parameter requestParameters.engagementId was null or undefined when calling engagementServiceDeleteEngagement.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadScore !== undefined) {
                queryParameters['payload.score'] = requestParameters.payloadScore;
            }
            if (requestParameters.payloadMaxScore !== undefined) {
                queryParameters['payload.maxScore'] = requestParameters.payloadMaxScore;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/engagement/v1/projects/{projectId}/engagements/{engagementId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"engagementId"}}`, encodeURIComponent(String(requestParameters.engagementId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    engagementServiceDeleteEngagement(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.engagementServiceDeleteEngagementRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    engagementServiceListEngagementsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling engagementServiceListEngagements.');
            }
            const queryParameters = {};
            if (requestParameters.engagementId !== undefined) {
                queryParameters['engagementId'] = requestParameters.engagementId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadScore !== undefined) {
                queryParameters['payload.score'] = requestParameters.payloadScore;
            }
            if (requestParameters.payloadMaxScore !== undefined) {
                queryParameters['payload.maxScore'] = requestParameters.payloadMaxScore;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/engagement/v1/projects/{projectId}/engagements`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListEngagementResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    engagementServiceListEngagements(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.engagementServiceListEngagementsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    engagementServiceReadEngagementRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling engagementServiceReadEngagement.');
            }
            if (requestParameters.engagementId === null || requestParameters.engagementId === undefined) {
                throw new runtime.RequiredError('engagementId', 'Required parameter requestParameters.engagementId was null or undefined when calling engagementServiceReadEngagement.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadScore !== undefined) {
                queryParameters['payload.score'] = requestParameters.payloadScore;
            }
            if (requestParameters.payloadMaxScore !== undefined) {
                queryParameters['payload.maxScore'] = requestParameters.payloadMaxScore;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/engagement/v1/projects/{projectId}/engagements/{engagementId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"engagementId"}}`, encodeURIComponent(String(requestParameters.engagementId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiEngagementRuleFromJSON(jsonValue));
        });
    }
    /**
     */
    engagementServiceReadEngagement(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.engagementServiceReadEngagementRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    engagementServiceUpdateEngagementRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling engagementServiceUpdateEngagement.');
            }
            if (requestParameters.engagementId === null || requestParameters.engagementId === undefined) {
                throw new runtime.RequiredError('engagementId', 'Required parameter requestParameters.engagementId was null or undefined when calling engagementServiceUpdateEngagement.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling engagementServiceUpdateEngagement.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/engagement/v1/projects/{projectId}/engagements/{engagementId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"engagementId"}}`, encodeURIComponent(String(requestParameters.engagementId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiEngagementPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiEngagementRuleFromJSON(jsonValue));
        });
    }
    /**
     */
    engagementServiceUpdateEngagement(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.engagementServiceUpdateEngagementRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
