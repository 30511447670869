import { ApiLeadScoreAdjustmentRule } from "@incendium/api";
import { Delete, Edit } from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { leadScoreAdjustmentService } from "Apis";
import GlassCard from "Components/GlassCard/GlassCard";
import { AnimatePresence, motion } from "framer-motion";
import { useSelectedProject } from "Hooks";
import { useLeadScoreAdjustmentRules } from "Hooks/useLeadStatusAdjustmentRules";
import produce from "immer";
import { useSnackbar } from "notistack";
import { useState } from "react";
import LeadScoreAdjustmentDialog from "./LeadScoreAdjustmentDialog";

const LeadScoreAdjustmentCardEmptyState = ({
  onClick,
}: {
  onClick: () => void;
}) => (
  <Box
    sx={{
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Typography
      align="center"
      variant="body1"
      fontWeight={"bold"}
      color={"secondary"}
      gutterBottom
    >
      No Decay Set
    </Typography>
    <Button size="small" onClick={onClick}>
      Add Decay Rule
    </Button>
  </Box>
);

const LeadScoreAdjustmentCardRow = ({
  leadScoreAdjustmentRule,
  onEdit,
  onRemove,
}: {
  leadScoreAdjustmentRule: ApiLeadScoreAdjustmentRule;
  onEdit: () => void;
  onRemove: () => void;
}) => {
  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ delay: 0.1 }}
      key={leadScoreAdjustmentRule.id}
    >
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Typography display={"inline"} variant="body1">
          Deduct{" "}
          <Typography
            display={"inline"}
            color="info.main"
            fontWeight={700}
            variant="subtitle1"
            component={"span"}
          >
            {/* remove minus a we use deduct in sentance */}
            {`${leadScoreAdjustmentRule.score}`.replace("-", "")}
          </Typography>{" "}
          points after{" "}
          <Typography
            display={"inline"}
            color="info.main"
            fontWeight={700}
            variant="subtitle1"
            component={"span"}
          >
            {leadScoreAdjustmentRule.value}
          </Typography>{" "}
          Days of Inactivity
        </Typography>
        <Stack direction={"row"}>
          <IconButton size="small" color="primary" onClick={onEdit}>
            <Edit />
          </IconButton>

          <IconButton size="small" color="secondary" onClick={onRemove}>
            <Delete />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  );
};
const LeadScoreAdjustmentCardList = ({
  leadScoreAdjustmentRules,
  onEdit,
  onRemove,
}: {
  leadScoreAdjustmentRules: ApiLeadScoreAdjustmentRule[];
  onEdit: (v: ApiLeadScoreAdjustmentRule) => void;
  onRemove: (v: ApiLeadScoreAdjustmentRule) => void;
}) => {
  return (
    <Box py={2}>
      <AnimatePresence>
        {leadScoreAdjustmentRules.map((l, i) => (
          <LeadScoreAdjustmentCardRow
            key={i}
            leadScoreAdjustmentRule={l}
            onEdit={() => onEdit(l)}
            onRemove={() => onRemove(l)}
          />
        ))}
      </AnimatePresence>
    </Box>
  );
};

function LeadScoreAdjustmentCard() {
  const { selectedProject } = useSelectedProject();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [leadScoreAdjustmentRule, setLeadScoreAdjustmentRule] = useState({});
  const { leadScoreAdjustmentRules, setLeadScoreAdjustmentRules } =
    useLeadScoreAdjustmentRules();

  const onEdit = (l: ApiLeadScoreAdjustmentRule) => {
    setLeadScoreAdjustmentRule(l);
    setOpen(true);
  };

  const onRemove = (l: ApiLeadScoreAdjustmentRule) => {
    try {
      leadScoreAdjustmentService.leadScoreAdjustmentRuleServiceDeleteRule({
        projectId: selectedProject?.id as number,
        ruleId: l.id as number,
      });
      setLeadScoreAdjustmentRules(
        produce(leadScoreAdjustmentRules, (draft) => {
          const idx = leadScoreAdjustmentRules.findIndex((r) => r.id === l.id);
          if (idx >= 0) {
            draft.splice(idx, 1);
          }
        })
      );
      enqueueSnackbar(`Lead Score Decay Removed`, {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    } catch (error) {
      enqueueSnackbar(`Failed to remove rule, please try again`, {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }
  };

  return (
    <GlassCard>
      <Box
        sx={{
          minHeight: 300,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        p={2}
      >
        <Typography variant="subtitle1">Lead Score Decay</Typography>
        <Typography variant="body2">
          Establish guidelines for gradually lowering a lead's score if they've
          shown inactivity on the site over time.
        </Typography>

        {leadScoreAdjustmentRules.length > 0 ? (
          <>
            <LeadScoreAdjustmentCardList
              leadScoreAdjustmentRules={leadScoreAdjustmentRules}
              onEdit={onEdit}
              onRemove={onRemove}
            />

            <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button size="small" onClick={() => setOpen(true)}>
                Add Decay Rule
              </Button>
            </Box>
          </>
        ) : (
          <LeadScoreAdjustmentCardEmptyState onClick={() => setOpen(true)} />
        )}
      </Box>
      <LeadScoreAdjustmentDialog
        open={open}
        setOpen={setOpen}
        leadScoreAdjustmentRule={leadScoreAdjustmentRule}
      />
    </GlassCard>
  );
}

export default LeadScoreAdjustmentCard;
