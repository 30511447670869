import { action } from "typesafe-actions";
import {
  CHECK_LOGIN,
  CHECK_LOGIN_FAILED,
  CHECK_LOGIN_SUCCESS,
  LOGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  SET_USER,
  TOKEN_REFRESHED,
} from "./authActionTypes";
import { ApiAuthResponse, ApiUser } from "@incendium/api";

export const login = (args: {
  email: string;
  password: string;
  onSuccess?: (res: ApiAuthResponse) => void;
  onFail?: (e: any) => void;
}) => action(LOGIN, args);
export const loginSuccess = (args: ApiAuthResponse) =>
  action(LOGIN_SUCCESS, args);

export const tokenRefreshed = (args: ApiAuthResponse) => {
  return action(TOKEN_REFRESHED, args);
};
export const checkLoginSuccess = (args: ApiAuthResponse) => {
  return action(CHECK_LOGIN_SUCCESS, args);
};
export const checkLoginFailed = () => {
  return action(CHECK_LOGIN_FAILED);
};
export const loginError = () => action(LOGIN_ERROR);
export const logout = () => action(LOGOUT);
export const checkLogin = () => action(CHECK_LOGIN);

export const setUser = (args: ApiUser) => action(SET_USER, args);
