import { Box, styled, Typography } from "@mui/material";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/theme-monokai";
import { useCallback, useMemo, useState } from "react";

const EditorContainer = styled(Box)(({ theme }) => ({
  background: "#1d1e22",
  color: "white",
  height: "100%",
}));

interface ICodeEditorProps {
  title: string;
  mode: string;
  height?: number;
  value: string;
  onChange: (value: string) => void;
}

function CodeEditor({
  title,
  mode,
  height,
  value,
  onChange,
}: ICodeEditorProps) {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const onRefChange = useCallback((node) => {
    setRef(node);
  }, []);
  const editorHeight = useMemo(() => {
    if (!height || !ref) {
      return undefined;
    }
    return `${Math.floor(height - ref.clientHeight - 4)}px`;
  }, [height, ref]);
  return (
    <EditorContainer>
      <Typography
        variant="subtitle2"
        color={"inherit"}
        px={1}
        ref={onRefChange}
      >
        {title}
      </Typography>

      <AceEditor
        mode={mode}
        theme="monokai"
        name={title}
        fontSize={12}
        width={"100%"}
        tabSize={4}
        height={editorHeight}
        setOptions={{
          useWorker: false,
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
        }}
        wrapEnabled
        showPrintMargin={false}
        value={value}
        onChange={(v) => onChange(v)}
      />
    </EditorContainer>
  );
}

export default CodeEditor;
