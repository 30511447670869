import { ApiForm } from '@incendium/api'
import { Tag } from "Components/Tag";
import { AddCircle, Delete, Edit } from "@mui/icons-material";
import { IconButton, } from "@mui/material";

export const FormList = ({
    forms,
    onAdd,
    onEdit,
    onDelete,
}: {
    forms: ApiForm[];
    onAdd: () => void;
    onEdit: (form: ApiForm) => void;
    onDelete: (form: ApiForm) => void;
}) => {
    return (
        <>
            <Tag
                text="add new form"
                color="primary"
                icons={
                    <>
                        <IconButton onClick={onAdd} size="small">
                            <AddCircle color="primary" />
                        </IconButton>
                    </>
                }
            />
            {forms.map((form) => {
                return (
                    <Tag
                        key={form.id}
                        text={form.name as string}
                        color="secondary"
                        icons={
                            <>
                                <IconButton onClick={() => onEdit(form)} size="small">
                                    <Edit color="primary" />
                                </IconButton>
                                <IconButton onClick={() => onDelete(form)} size="small">
                                    <Delete color="secondary" />
                                </IconButton>
                            </>
                        }
                    />
                );
            })}
        </>
    )
}