// @ts-nocheck
import { authMiddleware } from "Middlewares";
import { rootReducer } from "Reducers";
import { createStore, applyMiddleware, compose } from "redux";
import persistStore from "redux-persist/es/persistStore";
import thunk from "redux-thunk";

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const persistedState = localStorage.getItem("left-menu")
  ? { leftMenu: JSON.parse(localStorage.getItem("left-menu")) }
  : {};

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(thunk, authMiddleware))
);
store.subscribe(() => {
  localStorage.setItem("left-menu", JSON.stringify(store.getState().leftMenu));
});

export const persistor = persistStore(store);

export default store;
