import {
  appearDown,
  useClassificationsStyles,
} from "Pages/Audience/ClassificationsPage";
import { motion } from "framer-motion";
import clsx from "clsx";
import {
  Button,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from "@mui/material";
import {
  ArrowBack,
  CreateNewFolder,
  Delete,
  Edit,
  Folder,
} from "@mui/icons-material";
import { useState } from "react";
import {
  ApiClassification,
  ApiClassificationGroup,
  ApiClassificationItem,
  ApiProject,
} from "@incendium/api";
import { useConfirmationContext } from "Providers/ConfirmationProvider";
import { useSnackbar } from "notistack";
import { audienceService } from "Apis";
import produce from "immer";
import ClassificationGroupDialog from "Components/ClassificationDialog/ClassificationGroupDialog";
import { ColItem, ColsTitle } from "Components/ColsLayout/ColsLayout";
import Appear from "Components/Animate/Appear";

function ClassificationGroupsCol({
  selectedProject,
  selectedGroup,
  setSelectedGroup,
  setSelectedClassification,
  setSelectedClassificationItem,
  groups,
  setGroups,
  onSave,
  move,
  moveClassification,
}: {
  selectedProject: ApiProject;
  selectedGroup: ApiClassificationGroup | null;
  setSelectedGroup: React.Dispatch<
    React.SetStateAction<ApiClassificationGroup | null>
  >;
  setSelectedClassification: React.Dispatch<
    React.SetStateAction<ApiClassification | null>
  >;
  setSelectedClassificationItem: React.Dispatch<
    React.SetStateAction<ApiClassificationItem | null>
  >;
  groups: ApiClassificationGroup[];
  setGroups: React.Dispatch<React.SetStateAction<ApiClassificationGroup[]>>;
  onSave: (group: ApiClassificationGroup) => void;
  move: ApiClassification | null;
  moveClassification: (group: ApiClassificationGroup) => void;
}) {
  const extraGroup = {
    name: "Unassigned",
    description: "Contains traits which are in no grouping",
  };
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { enqueueSnackbar } = useSnackbar();
  const { groupRow } = useClassificationsStyles();

  const [open, setOpen] = useState(false);

  const selectGroup = (g: ApiClassificationGroup) => {
    setSelectedGroup(g);
    setSelectedClassification(null);
    setSelectedClassificationItem(null);
  };

  const onGroupDelete = (group: ApiClassificationGroup) => {
    openConfirmation({
      title: `Are you sure you want to delete this group`,
      body: `This action can not be undone`,
      callback: async () => {
        await audienceService.audienceServiceDeleteClassificationGroup({
          projectId: selectedProject?.id as number,
          classificationGroupId: group.id as number,
        });

        setGroups(
          produce(groups, (draft) => {
            const idx = groups.findIndex((ch) => ch.id === group.id);
            if (idx !== -1) draft.splice(idx, 1);
          })
        );
        setSelectedGroup(null);

        enqueueSnackbar(`${group.name} Deleted`, {
          variant: "success",
          autoHideDuration: 2000,
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });

        closeConfirmation();
      },
    });
  };

  const onGroupEdit = (group: ApiClassificationGroup) => {
    setSelectedGroup(group);
    setOpen(true);
  };

  return (
    <div>
      <ColsTitle>
        <Link variant="body1" display="block" noWrap>
          Groups
        </Link>
        {!selectedGroup ? (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpen(true)}
          >
            Add New Group
          </Button>
        ) : (
          <Tooltip title="Back To Groups" placement="top">
            <IconButton
              disabled={!selectedGroup}
              onClick={() => {
                setSelectedGroup(null);
                setSelectedClassification(null);
                setSelectedClassificationItem(null);
              }}
              size="large"
            >
              <ArrowBack />
            </IconButton>
          </Tooltip>
        )}
      </ColsTitle>
      <motion.div initial="hidden" animate="show" variants={appearDown}>
        <List disablePadding>
          {groups.map((group, i: number) => (
            <motion.div
              key={i}
              variants={{
                hidden: { opacity: 0 },
                show: { opacity: 1 },
              }}
            >
              <ColItem>
                <motion.div
                  animate={{ opacity: 1, y: 0 }}
                  initial={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.4, delay: 0.3 }}
                  style={{ overflow: "hidden" }}
                >
                  <ListItem
                    className={clsx(groupRow, {
                      active: group.name === selectedGroup?.name,
                    })}
                    button
                    onClick={() => selectGroup(group)}
                  >
                    <ListItemIcon>
                      {move && group.name !== selectedGroup?.name ? (
                        <IconButton
                          edge="start"
                          onClick={(e) => {
                            e.stopPropagation();
                            moveClassification(group);
                          }}
                          size="large"
                        >
                          <CreateNewFolder />
                        </IconButton>
                      ) : (
                        <Folder />
                      )}
                    </ListItemIcon>

                    <ListItemText
                      primary={group.name}
                      secondary={group.description || "..."}
                      primaryTypographyProps={{
                        noWrap: true,
                      }}
                      secondaryTypographyProps={{
                        noWrap: true,
                      }}
                    />

                    {!selectedGroup && group.id && (
                      <Appear>
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={() => onGroupEdit(group)}
                            color="secondary"
                            size="large"
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            onClick={() => onGroupDelete(group)}
                            color="secondary"
                            size="large"
                          >
                            <Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </Appear>
                    )}
                  </ListItem>
                  <Divider />
                </motion.div>
              </ColItem>
            </motion.div>
          ))}
          <ColItem>
            <motion.span
              animate={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.4, delay: 0.3 }}
              style={{ overflow: "hidden" }}
            >
              <ListItem
                button
                onClick={() => selectGroup(extraGroup)}
                className={clsx(groupRow, {
                  active: extraGroup.name === selectedGroup?.name,
                })}
              >
                <ListItemIcon>
                  <Folder />
                </ListItemIcon>

                <ListItemText
                  primary={extraGroup.name}
                  secondary={extraGroup.description}
                  primaryTypographyProps={{
                    noWrap: true,
                  }}
                  secondaryTypographyProps={{
                    noWrap: true,
                  }}
                />
              </ListItem>
              <Divider />
            </motion.span>
          </ColItem>
        </List>
      </motion.div>
      {open && (
        <ClassificationGroupDialog
          onClose={() => {
            setSelectedGroup(null);
            setOpen(false);
          }}
          group={selectedGroup}
          project={selectedProject}
          onSaved={(g) => {
            setOpen(false);
            onSave(g);
          }}
        />
      )}
    </div>
  );
}

export default ClassificationGroupsCol;
