/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiSessionStatus;
(function (ApiSessionStatus) {
    ApiSessionStatus["UNSPECIFIED"] = "SESSION_STATUS_UNSPECIFIED";
    ApiSessionStatus["EFFECTIVENESS_CALCULATED"] = "SESSION_STATUS_EFFECTIVENESS_CALCULATED";
    ApiSessionStatus["AUDIENCE_CALCULATED"] = "SESSION_STATUS_AUDIENCE_CALCULATED";
    ApiSessionStatus["SOURCE_ADJUSTED"] = "SESSION_STATUS_SOURCE_ADJUSTED";
    ApiSessionStatus["COST_CALCULATED"] = "SESSION_STATUS_COST_CALCULATED";
})(ApiSessionStatus || (ApiSessionStatus = {}));
export function ApiSessionStatusFromJSON(json) {
    return ApiSessionStatusFromJSONTyped(json, false);
}
export function ApiSessionStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiSessionStatusToJSON(value) {
    return value;
}
