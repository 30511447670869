import {
  ApiChartAttribute,
  ApiChartRankDrection,
  ApiDimension,
  ApiMetric,
  ApiOperator,
} from "@incendium/api";
import { Backspace } from "@mui/icons-material";
import { Button, Portal, Stack, Tooltip, Typography } from "@mui/material";
import { TabButton } from "Components/UI/TabButton";
import { TabContainer } from "Components/UI/TabContainer";
import {
  dimensionNameToEnum,
  dimensionToName,
  formatDimension,
} from "features/analytics";
import { ContentExplorer } from "features/explorer";
import { ExplorerProvider } from "features/explorer";
import { ExploreConfig, TExploreConfig } from "features/explorer/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSearchParam, useUpdateEffect } from "react-use";

type ContentTab = "top content" | "content to improve" | "explore";

function ContentPerformancePage() {
  const location = useLocation();
  const history = useHistory();
  const [deepDiveAttribute, setDeepDiveAttribute] =
    useState<ApiChartAttribute | null>(null);
  const config: TExploreConfig<ContentTab> = useMemo(() => {
    if (deepDiveAttribute?.key) {
      const settings: ExploreConfig = {
        title: `Content Analysis - Deep Dive`,
        desc: "Use this deep dive to surface actionable insights",
        ranking: [
          {
            defaultRank: 0.0001,
          },
        ],
        attributes: [deepDiveAttribute],
        analyseBy: {
          description: "Select how to split content",
          grouping: [
            {
              option: "landing Pages",
              dimensions: [
                ApiDimension.DIMENSION_BROWSER,
                ApiDimension.DIMENSION_BROWSER_WITH_VERSION,
                ApiDimension.DIMENSION_DEVICE,
                ApiDimension.DIMENSION_CHANNEL,
                ApiDimension.DIMENSION_SESSION_AUDIENCE,
              ],
            },
            {
              option: "Last Click Attribution",
              dimensions: [
                ApiDimension.DIMENSION_CHANNEL,
                ApiDimension.DIMENSION_SOURCE,
                ApiDimension.DIMENSION_MEDIUM,
                ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
                ApiDimension.DIMENSION_CONTENT,
                ApiDimension.DIMENSION_TERM,
              ],
            },
          ],
        },
      };
      return {
        "top content": settings,
        explore: settings,
        "content to improve": settings,
      };
    }
    return {
      "top content": {
        title: "Top Content",
        desc: "These are the most visited, best performing pages. Learn from them, or prioritize them in marketing and within your website hierarchy",
        ranking: [
          {
            type: "top",
            ranks: [
              {
                metric: ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                direction: ApiChartRankDrection.ASC,
                weight: 0.1,
              },
              {
                metric: ApiMetric.METRIC_PAGE_MACRO_CONVERSION_COUNT,
                direction: ApiChartRankDrection.ASC,
                weight: 0.1,
              },
              {
                metric: ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
                direction: ApiChartRankDrection.ASC,
                weight: 0.1,
              },
              {
                metric: ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
                direction: ApiChartRankDrection.ASC,
                weight: 0.1,
              },
              {
                metric: ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_RATE,
                direction: ApiChartRankDrection.ASC,
                weight: 0.1,
              },
              {
                metric: ApiMetric.METRIC_AVERAGE_CWV_PERFORMANCE_SCORE,
                direction: ApiChartRankDrection.ASC,
                weight: 0.1,
              },
            ],
          },
        ],
        analyseBy: {
          description: "Select how to split content",
          grouping: [
            {
              option: "landing Pages",
              dimensions: [
                ApiDimension.DIMENSION_LANDING_PAGE_LOCATION,
                ApiDimension.DIMENSION_LANDING_PAGE_TYPE,
                ApiDimension.DIMENSION_LANDING_PAGE_PATH,
              ],
            },
            {
              option: "All Pages",
              dimensions: [
                ApiDimension.DIMENSION_LOCATION,
                ApiDimension.DIMENSION_LOCATION_PAGE_TYPE,
                ApiDimension.DIMENSION_PATH,
              ],
            },
          ],
        },
        default: [
          ApiMetric.METRIC_PAGE_VIEWS_COUNT,
          ApiMetric.METRIC_PAGE_MACRO_CONVERSION_COUNT,
        ],

        having: {
          description: "Exclude content based on rank weights",
          havings: [
            {
              label: "Min No. of pageviews",
              metric: ApiMetric.METRIC_PAGE_VIEWS_COUNT,
              operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Max No. of pageviews",
              metric: ApiMetric.METRIC_PAGE_VIEWS_COUNT,
              operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Min No. of Conversions",
              metric: ApiMetric.METRIC_PAGE_MACRO_CONVERSION_COUNT,
              operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Max No. of Conversions",
              metric: ApiMetric.METRIC_PAGE_MACRO_CONVERSION_COUNT,
              operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Min No. of Interactions",
              metric: ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
              operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Max No. of Interactions",
              metric: ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
              operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Min effective rate",
              metric: ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
              operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Max effective rate",
              metric: ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
              operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Min add to cart rate",
              metric: ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_RATE,
              operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Max aff to cart rate",
              metric: ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_RATE,
              operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
              value: undefined,
            },
          ],
        },
      },
      "content to improve": {
        title: "Content to Improve",
        desc: "These are your worst performing pages; the most visited, but poorest performing in terms of conversion on engagement. Analyse the pages and consider changes to copy, visuals, pricing or perhaps review the traffic sources as they may be mismatched.",
        ranking: [
          {
            type: "bottom",
            ranks: [
              {
                metric: ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                direction: ApiChartRankDrection.ASC,
                weight: 0.1,
              },
              {
                metric: ApiMetric.METRIC_PAGE_MACRO_CONVERSION_COUNT,
                direction: ApiChartRankDrection.DESC,
                weight: 0.1,
              },
              {
                metric: ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
                direction: ApiChartRankDrection.DESC,
                weight: 0.1,
              },
              {
                metric: ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
                direction: ApiChartRankDrection.DESC,
                weight: 0.1,
              },
              {
                metric: ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_RATE,
                direction: ApiChartRankDrection.DESC,
                weight: 0.1,
              },
              {
                metric: ApiMetric.METRIC_AVERAGE_CWV_PERFORMANCE_SCORE,
                direction: ApiChartRankDrection.ASC,
                weight: 0.1,
              },
            ],
          },
        ],

        analyseBy: {
          description: "Select how to split content",
          grouping: [
            {
              option: "landing Pages",
              dimensions: [
                ApiDimension.DIMENSION_LANDING_PAGE_LOCATION,
                ApiDimension.DIMENSION_LANDING_PAGE_TYPE,
                ApiDimension.DIMENSION_LANDING_PAGE_PATH,
              ],
            },
            {
              option: "All Pages",
              dimensions: [
                ApiDimension.DIMENSION_LOCATION,
                ApiDimension.DIMENSION_LOCATION_PAGE_TYPE,
                ApiDimension.DIMENSION_PATH,
              ],
            },
          ],
        },
        default: [
          ApiMetric.METRIC_PAGE_VIEWS_COUNT,
          ApiMetric.METRIC_PAGE_MACRO_CONVERSION_COUNT,
        ],

        having: {
          description: "Exclude content based on rank weights",
          havings: [
            {
              label: "Min No. of pageviews",
              metric: ApiMetric.METRIC_PAGE_VIEWS_COUNT,
              operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Max No. of pageviews",
              metric: ApiMetric.METRIC_PAGE_VIEWS_COUNT,
              operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Min No. of Conversions",
              metric: ApiMetric.METRIC_PAGE_MACRO_CONVERSION_COUNT,
              operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Max No. of Conversions",
              metric: ApiMetric.METRIC_PAGE_MACRO_CONVERSION_COUNT,
              operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Min No. of Interactions",
              metric: ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
              operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Max No. of Interactions",
              metric: ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
              operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Min effective rate",
              metric: ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
              operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Max effective rate",
              metric: ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
              operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Min add to cart rate",
              metric: ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_RATE,
              operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Max aff to cart rate",
              metric: ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_RATE,
              operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
              value: undefined,
            },
          ],
        },
      },
      explore: {
        title: "Content Analysis",
        desc: "Use this tool to diagnose issues, and click to deep dive",
        ranking: [
          {
            ranks: [
              {
                metric: ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                direction: ApiChartRankDrection.ASC,
                weight: 0.1,
              },
              {
                metric: ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                direction: ApiChartRankDrection.DESC,
                weight: 0.1,
              },
              {
                metric: ApiMetric.METRIC_PAGE_MACRO_CONVERSION_COUNT,
                direction: ApiChartRankDrection.ASC,
                weight: 0.1,
              },
              {
                metric: ApiMetric.METRIC_PAGE_MACRO_CONVERSION_COUNT,
                direction: ApiChartRankDrection.DESC,
                weight: 0.1,
              },
              {
                metric: ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
                direction: ApiChartRankDrection.ASC,
                weight: 0.1,
              },
              {
                metric: ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
                direction: ApiChartRankDrection.DESC,
                weight: 0.1,
              },
              {
                metric: ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
                direction: ApiChartRankDrection.ASC,
                weight: 0.1,
              },
              {
                metric: ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
                direction: ApiChartRankDrection.DESC,
                weight: 0.1,
              },
              {
                metric: ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_RATE,
                direction: ApiChartRankDrection.ASC,
                weight: 0.1,
              },
              {
                metric: ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_RATE,
                direction: ApiChartRankDrection.DESC,
                weight: 0.1,
              },
              {
                metric: ApiMetric.METRIC_AVERAGE_CWV_PERFORMANCE_SCORE,
                direction: ApiChartRankDrection.ASC,
                weight: 0.1,
              },
              {
                metric: ApiMetric.METRIC_AVERAGE_CWV_PERFORMANCE_SCORE,
                direction: ApiChartRankDrection.DESC,
                weight: 0.1,
              },
            ],
          },
        ],
        type: "top",
        analyseBy: {
          description: "Select how to split content",
          grouping: [
            {
              option: "landing Pages",
              dimensions: [
                ApiDimension.DIMENSION_LANDING_PAGE_LOCATION,
                ApiDimension.DIMENSION_LANDING_PAGE_TYPE,
                ApiDimension.DIMENSION_LANDING_PAGE_PATH,
              ],
            },
            {
              option: "All Pages",
              dimensions: [
                ApiDimension.DIMENSION_LOCATION,
                ApiDimension.DIMENSION_LOCATION_PAGE_TYPE,
                ApiDimension.DIMENSION_PATH,
              ],
            },
          ],
        },
        default: [],

        having: {
          description: "Exclude content based on rank weights",
          havings: [
            {
              label: "Min No. of pageviews",
              metric: ApiMetric.METRIC_PAGE_VIEWS_COUNT,
              operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Max No. of pageviews",
              metric: ApiMetric.METRIC_PAGE_VIEWS_COUNT,
              operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Min No. of Conversions",
              metric: ApiMetric.METRIC_PAGE_MACRO_CONVERSION_COUNT,
              operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Max No. of Conversions",
              metric: ApiMetric.METRIC_PAGE_MACRO_CONVERSION_COUNT,
              operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Min No. of Interactions",
              metric: ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
              operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Max No. of Interactions",
              metric: ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
              operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Min effective rate",
              metric: ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
              operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Max effective rate",
              metric: ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
              operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Min add to cart rate",
              metric: ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_RATE,
              operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
              value: undefined,
            },
            {
              label: "Max aff to cart rate",
              metric: ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_RATE,
              operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
              value: undefined,
            },
          ],
        },
      },
    };
  }, [deepDiveAttribute]);

  const tab = useSearchParam("tab");
  const [type, setType] = useState<ContentTab>(
    (tab as ContentTab) || "top content"
  );

  useUpdateEffect(() => {
    if (!type || deepDiveAttribute) {
      return;
    }
    const newUrl = `?tab=${type}`;
    window.history.replaceState(
      {
        ...window.history.state,
        as: newUrl,
        url: newUrl,
      },
      "",
      newUrl
    );
  }, [type, deepDiveAttribute]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.forEach((v, k) => {
      if (k !== "tab") {
        setDeepDiveAttribute({
          key: dimensionNameToEnum(k),
          value: v,
        });
        return;
      }
    });
  }, [location.search]);

  const onGoBack = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.forEach((v, k) => {
      if (k !== "tab") {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete(
          dimensionToName(deepDiveAttribute?.key as ApiDimension) || ""
        );
        const updatedSearchString = searchParams.toString();
        history.push({ search: updatedSearchString });
      }
    });
  }, [deepDiveAttribute, location.search, history]);

  return (
    <>
      <Portal container={() => document.getElementById("pageTitle")}>
        <Typography variant="h1" mb={2}>
          Content Performance Analysis
        </Typography>
        {deepDiveAttribute?.key ? (
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Button
              size="small"
              startIcon={<Backspace fontSize="small" />}
              onClick={onGoBack}
            >
              back to {type}
            </Button>

            <Typography variant="subtitle1">
              Deep Dive:{" "}
              <Typography
                variant="subtitle1"
                display={"inline"}
                color={"secondary"}
              >
                {dimensionToName(deepDiveAttribute.key as ApiDimension)} -{" "}
                {formatDimension(
                  deepDiveAttribute.key as ApiDimension,
                  deepDiveAttribute.value || ""
                )}
              </Typography>
            </Typography>
          </Stack>
        ) : (
          <TabContainer sx={{ minWidth: 800 }}>
            {Object.keys(config).map((k) => (
              <TabButton
                onClick={() => setType(k as ContentTab)}
                isActive={type === k}
              >
                <Tooltip
                  arrow
                  title={
                    <>
                      <Typography variant="subtitle2" color={"inherit"}>
                        {config[k as ContentTab].title}
                      </Typography>
                      <Typography variant="body2" color={"inherit"}>
                        {config[k as ContentTab].desc}
                      </Typography>
                    </>
                  }
                >
                  <span>{config[k as ContentTab].title}</span>
                </Tooltip>
              </TabButton>
            ))}
          </TabContainer>
        )}
      </Portal>

      {type && config[type] && (
        <ExplorerProvider config={config[type]}>
          <ContentExplorer
            metrics={[
              ApiMetric.METRIC_PAGE_VIEWS_COUNT,
              ApiMetric.METRIC_SESSIONS_COUNT,
              ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
              ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
              ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
              ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION,
              ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE,
              ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE,
              ApiMetric.METRIC_PAGE_MACRO_CONVERSION_COUNT,
              ApiMetric.METRIC_PAGE_MACRO_CONVERSION_RATE,
              ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
              ApiMetric.METRIC_PAGE_MICRO_CONVERSION_RATE,
              ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_COUNT,
              ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_RATE,
              ApiMetric.METRIC_SESSION_REVENUE,
              ApiMetric.METRIC_AVERAGE_CWV_PERFORMANCE_SCORE,
              ApiMetric.METRIC_RANK,
            ]}
          />
        </ExplorerProvider>
      )}
    </>
  );
}

export default ContentPerformancePage;
