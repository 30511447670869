import { analyticsService } from "Apis";

export default async function getAnalyticsLeadJourney(
  projectId: number,
  leadId: number
): Promise<any> {
  return analyticsService.analyticsServiceGetUserJourney({
    projectId,
    leadId,
  });
}
