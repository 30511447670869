import {
  ApiLeadScoreAdjustmentReason,
  ApiLeadScoreAdjustmentRule,
  ApiRulePayload,
} from "@incendium/api";
import { Save } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { leadScoreAdjustmentService } from "Apis";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import { useSelectedProject } from "Hooks";
import { useLeadScoreAdjustmentRules } from "Hooks/useLeadStatusAdjustmentRules";
import produce from "immer";
import { GenericDialoagProps } from "Interfaces";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

interface ILeadScoreAdjustmentDialogProps extends GenericDialoagProps {
  leadScoreAdjustmentRule: ApiLeadScoreAdjustmentRule;
}

function LeadScoreAdjustmentDialog({
  open,
  setOpen,
  leadScoreAdjustmentRule,
}: ILeadScoreAdjustmentDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedProject } = useSelectedProject();
  const [saving, setSaving] = useState(false);
  const [value, setValue] = useState<number | string>("");
  const [score, setScore] = useState<number | string>("");
  const [displayScore, setDisplayScore] = useState<number | string>(""); // display score is positive version, score is negative

  const { leadScoreAdjustmentRules, setLeadScoreAdjustmentRules } =
    useLeadScoreAdjustmentRules();

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      setValue("");
      setDisplayScore("");
      setScore("");
      setSaving(false);
      return;
    }

    setValue(leadScoreAdjustmentRule.value || "");
    setDisplayScore(
      Number(`${leadScoreAdjustmentRule.score}`.replace("-", "")) || ""
    );
  }, [open]);

  useEffect(() => {
    setScore(Number(`-${displayScore}`));
  }, [displayScore]);

  const onSave = async () => {
    setSaving(true);
    const payload: ApiRulePayload = {
      score: Number(score),
      value: Number(value),
      reason:
        ApiLeadScoreAdjustmentReason.LEAD_SCORE_ADJUSTMENT_REASON_INACTIVITY,
    };

    try {
      const fn = leadScoreAdjustmentRule.id
        ? leadScoreAdjustmentService.leadScoreAdjustmentRuleServiceUpdateRule({
            projectId: selectedProject?.id as number,
            ruleId: leadScoreAdjustmentRule.id,
            payload,
          })
        : leadScoreAdjustmentService.leadScoreAdjustmentRuleServiceCreateRule({
            projectId: selectedProject?.id as number,
            payload,
          });

      const res = await fn;
      setLeadScoreAdjustmentRules(
        produce(leadScoreAdjustmentRules, (draft) => {
          const idx = draft.findIndex(
            (s) => s.id === leadScoreAdjustmentRule.id
          );
          if (idx >= 0) {
            draft[idx] = res;
          } else {
            draft.push(res);
          }
        })
      );

      enqueueSnackbar(`Lead Score Decay Rule Created`, {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    } catch (error) {
      enqueueSnackbar(`Lead Score Decay Creation Failed`, {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      setSaving(false);
      return;
    }
    setOpen(false);
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="xs">
      <DialogTitle>Create Lead Score Adjustment Rule</DialogTitle>
      <ValidatorForm onSubmit={onSave}>
        <DialogContent>
          <Box mb={4}>
            <Stack
              spacing={2}
              direction="row"
              sx={{
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <Typography>Deduct</Typography>
              <Box
                sx={{
                  transform: "translateY(5px)",
                  width: 70,
                  "& .MuiFormHelperText-root.Mui-error": {
                    marginBottom: "-23px",
                  },
                }}
              >
                <TextValidator
                  fullWidth
                  label="Points"
                  validators={["required", "minNumber:1"]}
                  errorMessages={["", "> than 1"]}
                  name="score"
                  value={displayScore}
                  type="number"
                  variant="standard"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setDisplayScore(e.target.value)
                  }
                />
              </Box>
              <Typography>After </Typography>
              <Box
                sx={{
                  transform: "translateY(5px)",
                  width: 60,
                  "& .MuiFormHelperText-root.Mui-error": {
                    marginBottom: "-23px",
                  },
                }}
              >
                <TextValidator
                  fullWidth
                  label="Days "
                  validators={["required", "minNumber:1"]}
                  errorMessages={["", "> than 1"]}
                  name="value"
                  value={value}
                  type="number"
                  variant="standard"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setValue(e.target.value)
                  }
                />
              </Box>
              <Typography>of Inactivity</Typography>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={value === "" || score === ""}
            startIcon={
              saving ? <CircularProgress color="inherit" size={20} /> : <Save />
            }
          >
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </CustomDialog>
  );
}

export default LeadScoreAdjustmentDialog;
