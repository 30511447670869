import { useSharedState } from "./useSharedState";
import { ApiReportResponse } from "@incendium/api";
import { useSelectedProject } from "./useSelectedProject";
import { reportService } from "Apis";

export const useReports = () => {
  const { selectedProject } = useSelectedProject();
  const fetchReports = async () => {
    if (selectedProject) {
      const reports = await reportService.reportServiceListReports({
        projectId: selectedProject.id as number,
      });
      return reports.results || [];
    }
    return [];
  };
  const { state, setState, refetch } = useSharedState(
    "reports",
    [],
    fetchReports
  );

  return {
    reports: state as ApiReportResponse[],
    setReports: setState,
    refetchReports: refetch,
  };
};
