import { useSelector } from "react-redux";
import { projectSelector } from "Selectors/projectSelector";
import { useClientIdFromUrl } from "./useClientIdFromUrl";
import { useProjectIdFromUrl } from "./useProjectIdFromUrl";
import { useLocation } from "react-router-dom";

export const useProjectSelector = () => {
  const location = useLocation();
  let clientId = useClientIdFromUrl();
  let projectId = useProjectIdFromUrl();

  const searchParams = new URLSearchParams(location.search);
  if (searchParams.has("cp")) {
    const decodedString = atob(searchParams.get("cp") || "");
    [clientId, projectId] = decodedString.split("|");
  }

  const project = useSelector(projectSelector(clientId, projectId));
  return project;
};
