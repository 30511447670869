import { Box, Button, Portal, Step, StepLabel, Stepper } from "@mui/material";
import { CSVService } from "Apis";
import FullWidthPage from "Components/Page/FullWidthPage";
import { StyledMiddleBox } from "Components/UI/StylesFlexBox";
import { AnimatePresence, motion } from "framer-motion";
import { useSelectedProject } from "Hooks";
import { useCSVUploaderContext } from "Providers/CSVUploaderProvider";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import UploaderStepFourFinished from "./UploaderStepFourFinished";
import UploaderStepThreeValidation from "./UploaderStepThreeValidation";
import UploadMapFieldsStepTwo from "./UploadMapFieldsStepTwo";
import UploadStepOne from "./UploadStepOne";

interface IUploaderStepsProps {
  ruleId: string;
}

function UploaderSteps({ ruleId }: IUploaderStepsProps) {
  const { selectedProject } = useSelectedProject();
  const { setRule, step, rule, reset } = useCSVUploaderContext();
  const history = useHistory();

  useEffect(() => {
    if (!ruleId || (rule && Object.keys(rule).length > 0)) {
      return;
    }
    const load = async () => {
      const res = await CSVService.cSVServiceReadRule({
        ruleId,
      });
      setRule(res);
    };
    load();
  }, [ruleId, rule, setRule]);

  const cancel = useCallback(() => {
    if (!selectedProject) {
      return;
    }
    reset();
    history.push(
      `/clients/${selectedProject.clientId}/projects/${selectedProject?.id}/connect/data-manager/`
    );
  }, [selectedProject, history, reset]);

  return (
    <FullWidthPage>
      <Portal container={() => document.getElementById("pageAction")}>
        <Button onClick={cancel} color="secondary">
          Cancel
        </Button>
      </Portal>
      <StyledMiddleBox>
        <Box sx={{ width: 800 }}>
          <Stepper activeStep={step} alternativeLabel>
            <Step active={step === 1} completed={step > 1}>
              <StepLabel>Upload CSV</StepLabel>
            </Step>
            <Step active={step === 2} completed={step > 2}>
              <StepLabel>Map your fields</StepLabel>
            </Step>
            <Step active={step === 3} completed={step > 3}>
              <StepLabel>Validate your upload</StepLabel>
            </Step>
            <Step active={step === 4} completed={step > 3}>
              <StepLabel>Finished</StepLabel>
            </Step>
          </Stepper>
        </Box>
      </StyledMiddleBox>
      <AnimatePresence mode="wait">
        {step === 1 && (
          <motion.div
            key="step-1"
            style={{ height: "100%" }}
            initial={{ y: -200, opacity: 0 }}
            exit={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
          >
            <UploadStepOne />
          </motion.div>
        )}
        {step === 2 && (
          <motion.div
            key="step-2"
            style={{ height: "100%" }}
            initial={{ y: -200, opacity: 0 }}
            exit={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
          >
            <UploadMapFieldsStepTwo />
          </motion.div>
        )}
        {step === 3 && (
          <motion.div
            key="step-3"
            style={{ height: "100%" }}
            initial={{ y: -200, opacity: 0 }}
            exit={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
          >
            <UploaderStepThreeValidation />
          </motion.div>
        )}
        {step === 4 && (
          <motion.div
            key="step-4"
            style={{ height: "100%" }}
            initial={{ y: -200, opacity: 0 }}
            exit={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
          >
            <UploaderStepFourFinished />
          </motion.div>
        )}
      </AnimatePresence>
    </FullWidthPage>
  );
}

export default UploaderSteps;
