/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiBucketFromJSON, ApiBucketToJSON, } from './ApiBucket';
import { ApiBucketRuleFromJSON, ApiBucketRuleToJSON, } from './ApiBucketRule';
export function ApiBucketGroupFromJSON(json) {
    return ApiBucketGroupFromJSONTyped(json, false);
}
export function ApiBucketGroupFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'rules': !exists(json, 'rules') ? undefined : (json['rules'].map(ApiBucketRuleFromJSON)),
        'bucket': !exists(json, 'bucket') ? undefined : ApiBucketFromJSON(json['bucket']),
        'bucketId': !exists(json, 'bucketId') ? undefined : json['bucketId'],
    };
}
export function ApiBucketGroupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'rules': value.rules === undefined ? undefined : (value.rules.map(ApiBucketRuleToJSON)),
        'bucket': ApiBucketToJSON(value.bucket),
        'bucketId': value.bucketId,
    };
}
