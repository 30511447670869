/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiScheduledReportPayloadFromJSON, ApiScheduledReportPayloadToJSON, } from '../models';
/**
 *
 */
export class ScheduledReportServiceApi extends runtime.BaseAPI {
    /**
     */
    scheduledReportServiceReadScheduledReportRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling scheduledReportServiceReadScheduledReport.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling scheduledReportServiceReadScheduledReport.');
            }
            const queryParameters = {};
            if (requestParameters.payloadIntervalType !== undefined) {
                queryParameters['payload.intervalType'] = requestParameters.payloadIntervalType;
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            if (requestParameters.payloadEmails) {
                queryParameters['payload.emails'] = requestParameters.payloadEmails;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/scheduled_report/v1/projects/{projectId}/report/{reportId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiScheduledReportPayloadFromJSON(jsonValue));
        });
    }
    /**
     */
    scheduledReportServiceReadScheduledReport(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.scheduledReportServiceReadScheduledReportRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    scheduledReportServiceUpdateScheduledReportRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling scheduledReportServiceUpdateScheduledReport.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling scheduledReportServiceUpdateScheduledReport.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling scheduledReportServiceUpdateScheduledReport.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/scheduled_report/v1/projects/{projectId}/report/{reportId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiScheduledReportPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiScheduledReportPayloadFromJSON(jsonValue));
        });
    }
    /**
     */
    scheduledReportServiceUpdateScheduledReport(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.scheduledReportServiceUpdateScheduledReportRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
/**
    * @export
    * @enum {string}
    */
export var ScheduledReportServiceReadScheduledReportPayloadIntervalTypeEnum;
(function (ScheduledReportServiceReadScheduledReportPayloadIntervalTypeEnum) {
    ScheduledReportServiceReadScheduledReportPayloadIntervalTypeEnum["SCHEDULER_INTERVAL_NONE"] = "SchedulerIntervalNone";
    ScheduledReportServiceReadScheduledReportPayloadIntervalTypeEnum["SCHEDULER_INTERVAL_TYPE_DAILY"] = "SchedulerIntervalTypeDaily";
    ScheduledReportServiceReadScheduledReportPayloadIntervalTypeEnum["SCHEDULER_INTERVAL_TYPE_WEEKLY"] = "SchedulerIntervalTypeWeekly";
    ScheduledReportServiceReadScheduledReportPayloadIntervalTypeEnum["SCHEDULER_INTERVAL_TYPE_MONTHLY"] = "SchedulerIntervalTypeMonthly";
    ScheduledReportServiceReadScheduledReportPayloadIntervalTypeEnum["SCHEDULER_INTERVAL_TYPE_FORTNIGHTLY"] = "SchedulerIntervalTypeFortnightly";
})(ScheduledReportServiceReadScheduledReportPayloadIntervalTypeEnum || (ScheduledReportServiceReadScheduledReportPayloadIntervalTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ScheduledReportServiceReadScheduledReportPayloadTypeEnum;
(function (ScheduledReportServiceReadScheduledReportPayloadTypeEnum) {
    ScheduledReportServiceReadScheduledReportPayloadTypeEnum["SCHEDULED_REPORT_TYPE_NONE"] = "ScheduledReportTypeNone";
    ScheduledReportServiceReadScheduledReportPayloadTypeEnum["SCHEDULED_REPORT_TYPE_CSV"] = "ScheduledReportTypeCSV";
    ScheduledReportServiceReadScheduledReportPayloadTypeEnum["SCHEDULED_REPORT_TYPE_XLS"] = "ScheduledReportTypeXLS";
    ScheduledReportServiceReadScheduledReportPayloadTypeEnum["SCHEDULED_REPORT_TYPE_LINK"] = "ScheduledReportTypeLink";
    ScheduledReportServiceReadScheduledReportPayloadTypeEnum["SCHEDULED_REPORT_TYPE_CSV_AND_XLS"] = "ScheduledReportTypeCSVAndXLS";
    ScheduledReportServiceReadScheduledReportPayloadTypeEnum["SCHEDULED_REPORT_TYPE_CSV_AND_LINK"] = "ScheduledReportTypeCSVAndLink";
    ScheduledReportServiceReadScheduledReportPayloadTypeEnum["SCHEDULED_REPORT_TYPE_XLS_AND_LINK"] = "ScheduledReportTypeXLSAndLink";
    ScheduledReportServiceReadScheduledReportPayloadTypeEnum["SCHEDULED_REPORT_TYPE_CSV_AND_XLS_AND_LINK"] = "ScheduledReportTypeCSVAndXLSAndLink";
})(ScheduledReportServiceReadScheduledReportPayloadTypeEnum || (ScheduledReportServiceReadScheduledReportPayloadTypeEnum = {}));
