import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import { GenericDialoagProps } from "Interfaces";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

interface INameAndDescriptionDialogProps
  extends GenericDialoagProps,
    React.HTMLAttributes<HTMLDivElement> {
  title: string;
  onSaved: (name: string, description: string) => void;
  name?: string;
  description?: string;
  footer?: React.ReactNode;
}

function NameAndDescriptionDialog({
  open,
  setOpen,
  title,
  onSaved,
  name: originalName,
  description: originalDescription,
  children,
  footer,
}: INameAndDescriptionDialogProps) {
  const [name, setName] = useState(originalName || "");
  const [description, setDescrition] = useState(originalDescription || "");

  const save = () => {
    onSaved(name, description);
    setOpen(false);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setName(originalName || "");
    setDescrition(originalDescription || "");
  }, [originalName, originalDescription, open]);

  return (
    <CustomDialog open={open} fullWidth maxWidth="sm">
      <ValidatorForm onSubmit={save}>
        <DialogTitle>{title}</DialogTitle>
        {children && <DialogContent>{children}</DialogContent>}

        <DialogContent>
          <Box mb={2}>
            <TextValidator
              fullWidth
              label="name*"
              name="name"
              size="small"
              value={name}
              variant="outlined"
              validators={["required"]}
              errorMessages={["Name is required"]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
            />
          </Box>
          <Box>
            <TextField
              fullWidth
              multiline
              label="description"
              name="description"
              value={description}
              variant="outlined"
              minRows={2}
              maxRows={6}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setDescrition(e.target.value)
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          {footer ? (
            <Stack
              direction={"row"}
              spacing={1}
              sx={{ width: "100%" }}
              justifyContent={"space-between"}
            >
              {footer}
              <Stack direction={"row"} spacing={1}>
                <Button onClick={onClose} color="secondary">
                  Cancel
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </Stack>
            </Stack>
          ) : (
            <>
              <Button onClick={onClose} color="secondary">
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </>
          )}
        </DialogActions>
      </ValidatorForm>
    </CustomDialog>
  );
}

export default NameAndDescriptionDialog;
