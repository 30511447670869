import { Box } from "@mui/material";
import { useConditionGroupContext } from "./ConditionGroupProvider";

function ConditionalGroupRow({
  groupIdx,
  rowIdx,
}: {
  groupIdx: number;
  rowIdx: number;
}) {
  const { rowComponent: Component } = useConditionGroupContext();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        // display: "flex",
      }}
    >
      <>{Component && Component(groupIdx, rowIdx)}</>
    </Box>
  );
}

export default ConditionalGroupRow;
