import { Box, CircularProgress, Step, StepButton } from "@mui/material";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router";
import { AddaLocation } from "Components/Location/AddaLocation";
import { TagPages } from "Components/TagPages";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ApiLocation, ApiProject } from "@incendium/api";
import { locationService } from "Apis";
import { useLocations, useNotification, useSelectedProject } from "Hooks";
import { useLocationIdFromUrl } from "Hooks/useLocationIdFromUrl";
import { StyledMiddleBox } from "Components/UI/StylesFlexBox";
import { LocationRulesSetup } from "features/location";
import produce from "immer";
import LocationTypes from "Components/Location/LocationTypes";
import { StyledStepper } from "Components/UI/StyledStepper";

type ITrainPageTabs = {
  comp: React.ReactElement;
  path: string;
  visible: boolean;
  locationRequired?: boolean;
};

const TrainsPageLayout = ({
  items,
  basePath,
  baseUrl,
  isReady,
  location,
}: {
  isReady?: boolean;
  items: ITrainPageTabs[];
  basePath: string;
  baseUrl: string;
  location: ApiLocation;
}) => {
  const history = useHistory();
  const routerLocation = useLocation();
  const step = useMemo(() => {
    if (routerLocation.pathname.includes("location-tagging")) {
      return 3;
    }

    if (routerLocation.pathname.includes("location-rules-setup")) {
      return 2;
    }

    if (routerLocation.pathname.includes("location-types")) {
      return 1;
    }

    return 0;
  }, [routerLocation]);

  const hasTagging = useMemo(() => {
    return (
      [
        ...(location.conversions || []).filter((c) => c.id),
        ...(location.taggers || []).filter((c) => c.id),
        ...(location.conversions || []).filter((c) => c.id),
        ...(location.productTaggers || []).filter((c) => c.id),
        ...(location.formTaggers || []).filter((c) => c.id),
      ].length > 0
    );
  }, [location]);

  const goTo = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  if (!isReady) {
    return <CircularProgress />;
  }
  return (
    <>
      <StyledMiddleBox>
        <Box sx={{ width: 800 }}>
          <StyledStepper alternativeLabel activeStep={step} nonLinear>
            <Step completed={!!location.id}>
              <StepButton onClick={() => goTo("location-setup")}>
                Setup Location
              </StepButton>
            </Step>
            <Step
              disabled={!location?.id}
              completed={
                (location.pageTypes || []).filter((it) => it.id).length > 0
              }
            >
              <StepButton onClick={() => goTo("location-types")}>
                Add Location Types
              </StepButton>
            </Step>
            <Step
              disabled={!location?.id}
              completed={
                (location.items || []).filter((it) => it.id).length > 0
              }
            >
              <StepButton onClick={() => goTo("location-rules-setup")}>
                Add Location Rules
              </StepButton>
            </Step>

            <Step disabled={!location?.id} completed={hasTagging}>
              <StepButton onClick={() => goTo("location-tagging")}>
                Tag Your Location
              </StepButton>
            </Step>
          </StyledStepper>
        </Box>
      </StyledMiddleBox>
      <Switch>
        {items.map((item) => {
          return item.locationRequired && !location.id ? (
            <CircularProgress />
          ) : (
            <Route key={item.path} path={`${basePath}${item.path}`}>
              {item.comp}
            </Route>
          );
        })}
      </Switch>
    </>
  );
};

export const LocationPage = () => {
  const args = useRouteMatch();
  const locationId = useLocationIdFromUrl();
  const history = useHistory();
  const { locations, setLocations, location, setLocation, resetEmpty } =
    useLocations(Number(locationId));

  const [saving, setSaving] = useState(false);
  const { showErrorNotification, showSuccessNotification } = useNotification();
  const { selectedProject: project, selectedClient } = useSelectedProject();
  const [isReady, setIsReady] = useState(false);
  const routerLocation = useLocation();

  const step = useMemo(() => {
    if (routerLocation.pathname.includes("location-types")) {
      return 1;
    }

    if (routerLocation.pathname.includes("location-rules-setup")) {
      return 2;
    }

    if (routerLocation.pathname.includes("location-tagging")) {
      return 3;
    }

    return 0;
  }, [routerLocation]);

  // 🤢 replace
  const isNew = useMemo(() => {
    return !location?.id && locationId === "new";
  }, [locationId, location]);

  const nextStep = useCallback(
    (id: number) => {
      if (step === 0) {
        history.push(
          `/clients/${selectedClient?.id}/projects/${project?.id}/train/locations/${id}/location-types`
        );
      }
      if (step === 1) {
        history.push(
          `/clients/${selectedClient?.id}/projects/${project?.id}/train/locations/${id}/location-rules-setup`
        );
      }
      if (step === 2) {
        history.push(
          `/clients/${selectedClient?.id}/projects/${project?.id}/train/locations/${id}/location-tagging`
        );
      }
    },
    [step, history, selectedClient, project]
  );

  const saveLocation = useCallback(async () => {
    setSaving(true);

    try {
      const fn =
        !isNew && location
          ? locationService.locationServiceUpdateLocation({
              projectId: project?.id as number,
              locationId: location?.id as number,
              payload: { location },
            })
          : locationService.locationServiceCreateLocation({
              projectId: project?.id as number,
              payload: { location },
            });

      const res = await fn;
      setLocation(res.result as ApiLocation);
      setLocations(
        produce(locations, (draft) => {
          const idx = locations.findIndex((l) => l.id === location.id);
          if (idx >= 0) {
            draft[idx] = res.result || {};
          } else {
            draft.push(res.result as ApiLocation);
          }
        })
      );

      showSuccessNotification("Location Saved");
      resetEmpty();
      nextStep(res.result?.id as number);
    } catch (e) {
      showErrorNotification("Something Went Wrong");
    }

    setSaving(false);
  }, [
    location,
    isNew,
    locations,
    project,
    setLocation,
    showErrorNotification,
    showSuccessNotification,
    setLocations,
    nextStep,
    resetEmpty,
  ]);

  useEffect(() => {
    if (project) {
      if (locationId === "new") {
        setIsReady(true);
        return;
      }
      if (location) {
        setIsReady(true);
      }
    }
  }, [project, locationId, location]);

  return (
    <>
      <TrainsPageLayout
        isReady={isReady}
        location={location}
        items={[
          {
            comp: (
              <AddaLocation
                locationId={Number(locationId)}
                saveLocation={saveLocation}
                saving={saving}
              />
            ),
            path: "/location-setup",
            visible: true,
          },
          {
            comp: (
              <LocationTypes
                locationId={location.id as number}
                saveLocation={saveLocation}
                saving={saving}
              />
            ),
            path: "/location-types",
            visible: true,
            locationRequired: true,
          },
          {
            comp: (
              <LocationRulesSetup
                locationId={location.id as number}
                saveLocation={saveLocation}
                saving={saving}
              />
            ),
            path: "/location-rules-setup",
            visible: true,
            locationRequired: true,
          },
          {
            comp: (
              <TagPages
                location={location}
                setLocation={setLocation}
                project={project as ApiProject}
              />
            ),
            path: "/location-tagging",
            visible: !!location.id,
            locationRequired: true,
          },
          // {
          //   comp: (
          //     <AddaLocation location={location} setLocation={setLocation} />
          //   ),
          //   path: "/addsublocation",
          //   title: "add sublocations",
          // },
        ]}
        basePath={args.path}
        baseUrl={args.url}
      />
      {args.isExact && <Redirect to={`${args.url}/location-setup`} />}
    </>
  );
};
