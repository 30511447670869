/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiClassificationFromJSON, ApiClassificationToJSON, } from './ApiClassification';
import { ApiClassificationItemFromJSON, ApiClassificationItemToJSON, } from './ApiClassificationItem';
import { ApiClassificationRuleFromJSON, ApiClassificationRuleToJSON, } from './ApiClassificationRule';
import { ApiConditionCustomFromJSON, ApiConditionCustomToJSON, } from './ApiConditionCustom';
import { ApiConditionGroupFromJSON, ApiConditionGroupToJSON, } from './ApiConditionGroup';
import { ApiConditionValueTypeFromJSON, ApiConditionValueTypeToJSON, } from './ApiConditionValueType';
import { ApiConversionFromJSON, ApiConversionToJSON, } from './ApiConversion';
import { ApiOperatorFromJSON, ApiOperatorToJSON, } from './ApiOperator';
export function ApiConditionFromJSON(json) {
    return ApiConditionFromJSONTyped(json, false);
}
export function ApiConditionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'conditionGroup': !exists(json, 'conditionGroup') ? undefined : ApiConditionGroupFromJSON(json['conditionGroup']),
        'classification': !exists(json, 'classification') ? undefined : ApiClassificationFromJSON(json['classification']),
        'operator': !exists(json, 'operator') ? undefined : ApiOperatorFromJSON(json['operator']),
        'classificationValue': !exists(json, 'classificationValue') ? undefined : ApiClassificationItemFromJSON(json['classificationValue']),
        'classificationId': !exists(json, 'classificationId') ? undefined : json['classificationId'],
        'classificationItemId': !exists(json, 'classificationItemId') ? undefined : json['classificationItemId'],
        'conversion': !exists(json, 'conversion') ? undefined : ApiConversionFromJSON(json['conversion']),
        'conversionId': !exists(json, 'conversionId') ? undefined : json['conversionId'],
        'custom': !exists(json, 'custom') ? undefined : ApiConditionCustomFromJSON(json['custom']),
        'value': !exists(json, 'value') ? undefined : json['value'],
        'valueType': !exists(json, 'valueType') ? undefined : ApiConditionValueTypeFromJSON(json['valueType']),
        'classificationRule': !exists(json, 'classificationRule') ? undefined : ApiClassificationRuleFromJSON(json['classificationRule']),
    };
}
export function ApiConditionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'conditionGroup': ApiConditionGroupToJSON(value.conditionGroup),
        'classification': ApiClassificationToJSON(value.classification),
        'operator': ApiOperatorToJSON(value.operator),
        'classificationValue': ApiClassificationItemToJSON(value.classificationValue),
        'classificationId': value.classificationId,
        'classificationItemId': value.classificationItemId,
        'conversion': ApiConversionToJSON(value.conversion),
        'conversionId': value.conversionId,
        'custom': ApiConditionCustomToJSON(value.custom),
        'value': value.value,
        'valueType': ApiConditionValueTypeToJSON(value.valueType),
        'classificationRule': ApiClassificationRuleToJSON(value.classificationRule),
    };
}
