import { OpenInNew } from "@mui/icons-material";
import {
  Stack,
  styled,
  Tooltip,
  Typography,
  TypographyProps,
} from "@mui/material";
import { MouseEventHandler, ReactNode, useCallback } from "react";

const StyledTypography = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  transition: "all 0.3s",
  "&:hover": {
    transform: "scale(1.2)",
    textDecoration: "underline",
  },
}));

interface IClickableCellProps extends TypographyProps {
  tooltip: ReactNode;
  onClick: MouseEventHandler<HTMLSpanElement>;
}

function ClickableCell({
  tooltip,
  children,
  onClick,
  ...rest
}: IClickableCellProps) {
  const renderBody = useCallback(() => {
    return (
      <StyledTypography {...rest} onClick={onClick}>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"center"}
          spacing={0.5}
        >
          <span>{children}</span>
          <OpenInNew fontSize="inherit" />
        </Stack>
      </StyledTypography>
    );
  }, [children, rest, onClick]);

  return tooltip ? (
    <Tooltip title={tooltip} placement="top" arrow>
      {renderBody()}
    </Tooltip>
  ) : (
    renderBody()
  );
}

export default ClickableCell;
