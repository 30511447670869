/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiCSVUploadSessionFromJSON, ApiCSVUploadSessionToJSON, } from './ApiCSVUploadSession';
import { ApiDiscountFromJSON, ApiDiscountToJSON, } from './ApiDiscount';
import { ApiLeadFromJSON, ApiLeadToJSON, } from './ApiLead';
import { ApiLeadConversionFromJSON, ApiLeadConversionToJSON, } from './ApiLeadConversion';
import { ApiProductFromJSON, ApiProductToJSON, } from './ApiProduct';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
import { ApiSaleItemFromJSON, ApiSaleItemToJSON, } from './ApiSaleItem';
import { ApiSaleSourceFromJSON, ApiSaleSourceToJSON, } from './ApiSaleSource';
export function ApiSaleFromJSON(json) {
    return ApiSaleFromJSONTyped(json, false);
}
export function ApiSaleFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'lead': !exists(json, 'lead') ? undefined : ApiLeadFromJSON(json['lead']),
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'transactionId': !exists(json, 'transactionId') ? undefined : json['transactionId'],
        'store': !exists(json, 'store') ? undefined : json['store'],
        'gross': !exists(json, 'gross') ? undefined : json['gross'],
        'shipping': !exists(json, 'shipping') ? undefined : json['shipping'],
        'tax': !exists(json, 'tax') ? undefined : json['tax'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'items': !exists(json, 'items') ? undefined : (json['items'].map(ApiSaleItemFromJSON)),
        'pushedToGoogleAnalytics': !exists(json, 'pushedToGoogleAnalytics') ? undefined : json['pushedToGoogleAnalytics'],
        'conversion': !exists(json, 'conversion') ? undefined : ApiLeadConversionFromJSON(json['conversion']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'leadId': !exists(json, 'leadId') ? undefined : json['leadId'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'products': !exists(json, 'products') ? undefined : (json['products'].map(ApiProductFromJSON)),
        'uploadSession': !exists(json, 'uploadSession') ? undefined : ApiCSVUploadSessionFromJSON(json['uploadSession']),
        'discounts': !exists(json, 'discounts') ? undefined : (json['discounts'].map(ApiDiscountFromJSON)),
        'profitMargin': !exists(json, 'profitMargin') ? undefined : json['profitMargin'],
        'marginSynced': !exists(json, 'marginSynced') ? undefined : (new Date(json['marginSynced'])),
        'dataId': !exists(json, 'dataId') ? undefined : json['dataId'],
        'saleSource': !exists(json, 'saleSource') ? undefined : ApiSaleSourceFromJSON(json['saleSource']),
        'webhookId': !exists(json, 'webhookId') ? undefined : json['webhookId'],
    };
}
export function ApiSaleToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'lead': ApiLeadToJSON(value.lead),
        'project': ApiProjectToJSON(value.project),
        'transactionId': value.transactionId,
        'store': value.store,
        'gross': value.gross,
        'shipping': value.shipping,
        'tax': value.tax,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'items': value.items === undefined ? undefined : (value.items.map(ApiSaleItemToJSON)),
        'pushedToGoogleAnalytics': value.pushedToGoogleAnalytics,
        'conversion': ApiLeadConversionToJSON(value.conversion),
        'projectId': value.projectId,
        'leadId': value.leadId,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'products': value.products === undefined ? undefined : (value.products.map(ApiProductToJSON)),
        'uploadSession': ApiCSVUploadSessionToJSON(value.uploadSession),
        'discounts': value.discounts === undefined ? undefined : (value.discounts.map(ApiDiscountToJSON)),
        'profitMargin': value.profitMargin,
        'marginSynced': value.marginSynced === undefined ? undefined : (value.marginSynced.toISOString()),
        'dataId': value.dataId,
        'saleSource': ApiSaleSourceToJSON(value.saleSource),
        'webhookId': value.webhookId,
    };
}
