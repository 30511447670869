import { Autocomplete, TextField, OutlinedTextFieldProps } from "@mui/material";
import { useProducts } from "Hooks/useProducts";
import { useMemo } from "react";

export interface IProductCategoriesDropdownProps
  extends Omit<OutlinedTextFieldProps, "onChange"> {
  value?: string;
  onChange: (c?: string) => void;
  disableClearable?: boolean;
}

function ProductCategoriesDropdown({
  label,
  size,
  variant,
  value,
  onChange,
  fullWidth,
  sx,
  disableClearable,
}: IProductCategoriesDropdownProps) {
  const { products } = useProducts();

  const categories = useMemo(
    () => Array.from([...new Set(products.map((p) => p.category || ""))]),
    [products]
  );

  const selected = useMemo(
    () => (categories || []).find((l) => l === value) || "",
    [categories, value]
  );
  return (
    <Autocomplete
      sx={sx}
      fullWidth={fullWidth}
      options={[...categories].sort((a, b) => (a || "").localeCompare(b || ""))}
      value={selected}
      onChange={(e, v) => {
        onChange(v || "");
      }}
      disableClearable={disableClearable}
      getOptionLabel={(o: string) => o || ""}
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant} size={size} />
      )}
    />
  );
}

export default ProductCategoriesDropdown;
