import { styled } from "@mui/material";
import GjsEditor from "@grapesjs/react";

// GjsEditor
const StyledGrapesjsEditor = styled(GjsEditor)(({ theme }) => ({
  "& .gjs-one-bg": {
    background: theme.palette.primary.dark,
    color: "white",
  },
  "& .gjs-four-color, .gjs-four-color-h:hover": {
    color: theme.palette.info.main,
  },
}));
export default StyledGrapesjsEditor;
