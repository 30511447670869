import { Grid } from "@mui/material";
import { useAudienceAnalyticsContext } from "Providers/AudienceAnalyticsProvider";
import {
  ApiChartAttribute,
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiYAxisChartType,
} from "@incendium/api";
import { useMemo } from "react";
import { StatCardTypography } from "Components/UI/StatCard";
import {
  AnalyticsCard,
  AnalyticsStatCard,
  dimensionToName,
  formatMetric,
} from "features/analytics";

interface IAudienceUserJourneyReportPageProps {
  dimension: ApiDimension;
  countryName?: string;
  channelName?: string;
  userType?: string;
  device?: string;
  campaign?: string;
}

function AudienceUserJourneyReportPage({
  dimension,
  countryName,
  channelName,
  userType,
  device,
  campaign,
}: IAudienceUserJourneyReportPageProps) {
  const { currentAudience } = useAudienceAnalyticsContext();

  const attributes = useMemo(() => {
    let filter: ApiChartAttribute[] = [];
    if (currentAudience && currentAudience !== "") {
      filter.push({
        key: ApiDimension.DIMENSION_LEAD_AUDIENCE,
        value: currentAudience,
      });
    }
    if (countryName && countryName !== "") {
      filter.push({
        key: ApiDimension.DIMENSION_COUNTRY,
        value: countryName,
      });
    }
    if (channelName && channelName !== "") {
      filter.push({
        key: ApiDimension.DIMENSION_CHANNEL,
        value: channelName,
      });
    }
    if (userType && userType !== "") {
      filter.push({
        key: ApiDimension.DIMENSION_USER_TYPE,
        value: userType,
      });
    }
    if (campaign && campaign !== "") {
      filter.push({
        key: ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
        value: campaign,
      });
    }
    if (device && device !== "") {
      filter.push({
        key: ApiDimension.DIMENSION_DEVICE,
        value: device,
      });
    }

    return filter;
  }, [currentAudience, countryName, device, channelName, userType, campaign]);

  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs={3} container spacing={2}>
        <Grid item xs={12}>
          <AnalyticsStatCard
            minHeight={100}
            chart={{
              name: "",
              dimension: [],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_LEADS_COUNT],
                },
              ],
            }}
            renderBody={(data) => (
              <>
                <StatCardTypography size="medium" mb={1}>
                  {formatMetric(
                    ApiMetric.METRIC_LEADS_COUNT,
                    Number(data[0][ApiMetric.METRIC_LEADS_COUNT]) || 0
                  )}
                </StatCardTypography>
                <StatCardTypography size="xs">
                  Leads in Audience
                </StatCardTypography>
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} sx={{ minHeight: 340 }}>
          <AnalyticsCard
            chart={{
              name: `Recorded Audience Composition`,
              dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.PIE,
              attributes: attributes.filter(
                (a) => a.key !== ApiDimension.DIMENSION_LEAD_AUDIENCE
              ),
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_LEADS_COUNT],
                },
              ],
              displayOptions: {
                noFilters: true,
                showTotals: true,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={9}>
        <AnalyticsCard
          chart={{
            name: `Top ${
              dimension !== ApiDimension.DIMENSION_NOT_SET
                ? dimensionToName(dimension)
                : ""
            } Trend`,
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY, dimension],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: attributes.filter(
              (a) => a.key === ApiDimension.DIMENSION_LEAD_AUDIENCE
            ),
            limit: 5,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={5} sx={{ minHeight: 468 }}>
        <AnalyticsCard
          chart={{
            name: `Conversion Journey on the Channel Level`,
            dimension: [
              ApiDimension.DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY,
            ],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT],
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={7}>
        <AnalyticsCard
          chart={{
            name: `Conversion Journey on the Pageview Level`,
            dimension: [ApiDimension.DIMENSION_CONVERTING_SESSION_JOURNEY],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT],
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={6} sx={{ minHeight: 350 }}>
        <AnalyticsCard
          chart={{
            name: `No. of Sessions Prior Conversions`,
            dimension: [
              ApiDimension.DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION,
            ],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes,
            orderBy: `${ApiDimension.DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION}`,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT],
                chart: {
                  [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT]:
                    ApiYAxisChartType.BAR,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={6} sx={{ minHeight: 350 }}>
        <AnalyticsCard
          chart={{
            name: `No. of Days Prior Conversions`,
            dimension: [
              ApiDimension.DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION,
            ],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes,
            orderBy: `${ApiDimension.DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION}`,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT],
                chart: {
                  [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT]:
                    ApiYAxisChartType.BAR,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default AudienceUserJourneyReportPage;
