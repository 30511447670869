/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiCampaignFromJSON, ApiCampaignToJSON, } from './ApiCampaign';
import { ApiComponentFromJSON, ApiComponentToJSON, } from './ApiComponent';
import { ApiLandingDomainFromJSON, ApiLandingDomainToJSON, } from './ApiLandingDomain';
import { ApiMediaFileFromJSON, ApiMediaFileToJSON, } from './ApiMediaFile';
import { ApiPathStatusFromJSON, ApiPathStatusToJSON, } from './ApiPathStatus';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiLandingPathFromJSON(json) {
    return ApiLandingPathFromJSONTyped(json, false);
}
export function ApiLandingPathFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'componentId': !exists(json, 'componentId') ? undefined : json['componentId'],
        'component': !exists(json, 'component') ? undefined : ApiComponentFromJSON(json['component']),
        'landingDomainId': !exists(json, 'landingDomainId') ? undefined : json['landingDomainId'],
        'subdomain': !exists(json, 'subdomain') ? undefined : ApiLandingDomainFromJSON(json['subdomain']),
        'status': !exists(json, 'status') ? undefined : ApiPathStatusFromJSON(json['status']),
        'noIndex': !exists(json, 'noIndex') ? undefined : json['noIndex'],
        'canonical': !exists(json, 'canonical') ? undefined : json['canonical'],
        'pending': !exists(json, 'pending') ? undefined : (new Date(json['pending'])),
        'pendingSubdomainId': !exists(json, 'pendingSubdomainId') ? undefined : json['pendingSubdomainId'],
        'pendingComponentId': !exists(json, 'pendingComponentId') ? undefined : json['pendingComponentId'],
        'pendingPath': !exists(json, 'pendingPath') ? undefined : json['pendingPath'],
        'pendingStatus': !exists(json, 'pendingStatus') ? undefined : ApiPathStatusFromJSON(json['pendingStatus']),
        'pendingComponent': !exists(json, 'pendingComponent') ? undefined : ApiComponentFromJSON(json['pendingComponent']),
        'pendingSubdomain': !exists(json, 'pendingSubdomain') ? undefined : ApiLandingDomainFromJSON(json['pendingSubdomain']),
        'deferIncendiumjs': !exists(json, 'deferIncendiumjs') ? undefined : json['deferIncendiumjs'],
        'screenshot': !exists(json, 'screenshot') ? undefined : ApiMediaFileFromJSON(json['screenshot']),
        'screenshotId': !exists(json, 'screenshotId') ? undefined : json['screenshotId'],
        'invalidationRequestId': !exists(json, 'invalidationRequestId') ? undefined : json['invalidationRequestId'],
        'campaignId': !exists(json, 'campaignId') ? undefined : json['campaignId'],
        'campaign': !exists(json, 'campaign') ? undefined : ApiCampaignFromJSON(json['campaign']),
    };
}
export function ApiLandingPathToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'projectId': value.projectId,
        'project': ApiProjectToJSON(value.project),
        'description': value.description,
        'path': value.path,
        'componentId': value.componentId,
        'component': ApiComponentToJSON(value.component),
        'landingDomainId': value.landingDomainId,
        'subdomain': ApiLandingDomainToJSON(value.subdomain),
        'status': ApiPathStatusToJSON(value.status),
        'noIndex': value.noIndex,
        'canonical': value.canonical,
        'pending': value.pending === undefined ? undefined : (value.pending.toISOString()),
        'pendingSubdomainId': value.pendingSubdomainId,
        'pendingComponentId': value.pendingComponentId,
        'pendingPath': value.pendingPath,
        'pendingStatus': ApiPathStatusToJSON(value.pendingStatus),
        'pendingComponent': ApiComponentToJSON(value.pendingComponent),
        'pendingSubdomain': ApiLandingDomainToJSON(value.pendingSubdomain),
        'deferIncendiumjs': value.deferIncendiumjs,
        'screenshot': ApiMediaFileToJSON(value.screenshot),
        'screenshotId': value.screenshotId,
        'invalidationRequestId': value.invalidationRequestId,
        'campaignId': value.campaignId,
        'campaign': ApiCampaignToJSON(value.campaign),
    };
}
