import { Divider, DrawerProps, Typography } from "@mui/material";

import PagesWithNoLocationList from "./PagesWithNoLocationList";
import StyledDrawer, {
  StyledDrawerContainer,
} from "Components/UI/StyledDrawer";

interface IPagesWithNoLocationDrawerProps extends DrawerProps {
  open: boolean;
  setClose: React.Dispatch<React.SetStateAction<boolean>>;
}

function PagesWithNoLocationDrawer({
  open,
  setClose,
}: IPagesWithNoLocationDrawerProps) {
  return (
    <StyledDrawer anchor="right" open={open} onClose={() => setClose(true)}>
      <StyledDrawerContainer sx={{ width: 600 }}>
        <Typography variant="h3" gutterBottom>
          Unasigned Pages
        </Typography>
        <Typography variant="body1">
          These are found pages that no location covers. (** Note this only
          applies to locations that are set up to show in analytics)
        </Typography>
      </StyledDrawerContainer>
      <Divider />
      <StyledDrawerContainer sx={{ width: 600 }}>
        <PagesWithNoLocationList />
      </StyledDrawerContainer>
    </StyledDrawer>
  );
}

export default PagesWithNoLocationDrawer;
