import { ApiMetric } from "@incendium/api";
import { TAttributionClickMetricMap } from "features/attributionAnalytics/types";
import { formatNumber } from "Helpers/numbers";

const metricMap: {
  [key in ApiMetric]?: TAttributionClickMetricMap;
} = {
  [ApiMetric.METRIC_PUBLISHER_CLICKS]: {
    name: "Publisher Clicks",
    description: "Number of Clicks registered with publisher",
  },
  [ApiMetric.METRIC_PUBLISHER_CPC]: {
    name: "CPC's",
    description: "Average CPC",
    format: (n) => `$${formatNumber(n / 100)}`,
  },
  [ApiMetric.METRIC_SPEND]: {
    name: "Total Spend",
    description: "Total Spend",
    format: (n) => `$${formatNumber(n / 100)}`,
  },
  [ApiMetric.METRIC_PUBLISHER_CONVERSIONS]: {
    name: "Conversions",
    description:
      "Conversions (Data will be excluded from the Conversions report column for any conversion goal with the ExcludeFromBidding property set to true)",
  },
  [ApiMetric.METRIC_PUBLISHER_ALL_CONVERSIONS]: {
    name: "All Conversions",
    description:
      "Conversions (Including Conversions with the ExcludeFromBidding property set to true)",
  },
};

export const formatAttributionMetric = (metric: ApiMetric, value?: number) => {
  const m = metricMap[((metric as string) || "").split("\\")[0] as ApiMetric];
  return m && m.format ? m.format(value || 0) : formatNumber(value || 0);
};
