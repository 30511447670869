/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import { ApiCSVRowFromJSON, ApiCSVRowToJSON, } from './ApiCSVRow';
export function ApiCSVMappingResponseFromJSON(json) {
    return ApiCSVMappingResponseFromJSONTyped(json, false);
}
export function ApiCSVMappingResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'mapping': !exists(json, 'mapping') ? undefined : (mapValues(json['mapping'], ApiCSVRowFromJSON)),
        'headers': !exists(json, 'headers') ? undefined : json['headers'],
        'rows': !exists(json, 'rows') ? undefined : (json['rows'].map(ApiCSVRowFromJSON)),
    };
}
export function ApiCSVMappingResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'mapping': value.mapping === undefined ? undefined : (mapValues(value.mapping, ApiCSVRowToJSON)),
        'headers': value.headers,
        'rows': value.rows === undefined ? undefined : (value.rows.map(ApiCSVRowToJSON)),
    };
}
