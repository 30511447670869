import {
  ApiChartAttribute,
  ApiChartCrawler,
  ApiChartHaving,
  ApiYAxisChartType,
} from "@incendium/api";
import { alpha } from "@mui/material";
import { getChartColor } from "features/analytics";
import { IChart, IChartModel, IYKey, TChartData, TKeyValue } from "Interfaces";
import { LayoutType } from "recharts/types/util/types";
import theme from "theme";

// this model contains all business logic related to charts
// todo: move over all busniness logic related to charts 😜
// this includes things like what type of chart shoudl be rendered, if its bars, lines etc, spacing between bars and so on.
export default class ChartModel implements IChartModel {
  chart: IChart;

  constructor(chart: IChart) {
    this.chart = chart;
  }

  get yAxisKeys(): IYKey[] {
    return this.chart.yAxisKeys;
  }

  set yAxisKeys(value: IYKey[]) {
    this.chart.yAxisKeys = value;
  }

  get attributes(): ApiChartAttribute[] {
    return this.chart.attributes;
  }

  set attributes(value: ApiChartAttribute[]) {
    this.chart.attributes = value;
  }
  get crawler(): ApiChartCrawler | undefined {
    return this.chart.crawler;
  }

  set botType(value: ApiChartCrawler | undefined) {
    this.chart.crawler = value;
  }

  get having(): ApiChartHaving[] | undefined {
    return this.chart.having;
  }

  set having(value: ApiChartHaving[] | undefined) {
    this.chart.having = value;
  }

  // todo: refactor, currently just copied from old code in component
  public getChartDataKeys(
    data: TChartData[],
    metricsOrder?: string[],
    noReOrder?: boolean
  ) {
    const rest = new Set(data.map(Object.keys).flat());
    rest.delete("name");
    rest.delete("fill");
    return Array.from(rest)
      .sort((a, b) =>
        metricsOrder
          ? metricsOrder.indexOf(a) - metricsOrder.indexOf(b)
          : noReOrder
          ? 0
          : a.localeCompare(b)
      )
      .slice(0, 18); // todo: make a const or prop or maybe just remove
  }

  // todo: refactor, currently just copied from old code in component
  public getLinesStyles(
    data: TChartData[],
    colourMap: TKeyValue,
    metricsOrder?: string[],
    noReOrder?: boolean,
    fills?: TKeyValue,
    layout?: LayoutType
  ) {
    const chartDataKeys = this.getChartDataKeys(data, metricsOrder, noReOrder);

    const styles = chartDataKeys.map((key, i) => {
      let id = `${key}-y`;
      let lineType = this.getLineType(key);
      let stackId: string | undefined = undefined;
      if (this.yAxisKeys) {
        this.yAxisKeys.forEach((y, i) => {
          if (typeof y === "object" && (y.fields || []).includes(key)) {
            id = `${y.key}-y`;
            stackId = y.stackId !== "" ? y.stackId : undefined;
            return;
          }
        });
      }

      // fill logic, use colour map if set, otherwise fills if set (todo: remove one of these),
      // if these are not set we just use the keys index in theme, charts
      let fill =
        this.getFill(key, colourMap, fills) ||
        getChartColor(i, theme.palette.charts);

      const radius: [number, number, number, number] = stackId
        ? [0, 0, 0, 0]
        : layout === "vertical"
        ? [0, 5, 5, 0]
        : [5, 5, 0, 0];

      const cells = data.map((entry, index) => {
        let cellFill = entry.fill
          ? (entry.fill as string)
          : getCellFillByDimension(entry.name, colourMap, fill);
        return {
          key: `cell-${index}`,
          fill: key.includes("previous") ? alpha(cellFill, 0.6) : cellFill,
          stroke: cellFill,
        };
      });

      return {
        id,
        key,
        lineType,
        stackId,
        fill,
        radius,
        cells,
      };
    });

    return styles;
  }

  private getLineType(key: string) {
    let type = ApiYAxisChartType.LINE;

    if (this.yAxisKeys) {
      this.yAxisKeys.forEach((y, i) => {
        if (typeof y === "object" && (y.fields || []).includes(key)) {
          if (y.stackId && y.stackId !== "") {
            type =
              typeof y.chart === "object" && y.chart[key]
                ? y.chart[key]
                : ApiYAxisChartType.BAR;
          } else if (typeof y.chart === "string") {
            type = y.chart || ApiYAxisChartType.LINE;
          } else if (typeof y.chart === "object" && y.chart[key]) {
            type = y.chart[key];
          }
          return;
        }
      });
    }
    return type;
  }

  private getFill(key: string, colourMap: TKeyValue, fills?: TKeyValue) {
    return colourMap && colourMap[key.split("\\")[0]]
      ? colourMap[key.split("\\")[0]]
      : fills && fills[key]
      ? fills[key]
      : undefined;
  }
}

export function getCellFillByDimension(
  name: string,
  colourMap: TKeyValue,
  fill: string
) {
  let cellFill =
    name && colourMap && colourMap[name.split("\\")[0]]
      ? colourMap[name.split("\\")[0]]
      : fill;
  return cellFill;
}
