import { ApiClassificationGroup, ApiProject } from "@incendium/api";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { audienceService } from "Apis";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

function ClassificationGroupDialog({
  onClose,
  onSaved,
  group,
  project,
}: {
  onClose: () => void;
  onSaved: (c: ApiClassificationGroup) => void;
  group?: ApiClassificationGroup | null;
  project: ApiProject;
}) {
  const [name, setName] = useState(group?.name || "");
  const [description, setDescription] = useState(group?.description || "");
  const [disabled, setDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = async () => {
    setDisabled(true);
    const payload = { name, description };

    try {
      const fn =
        group && group.id
          ? audienceService.audienceServiceUpdateClassificationGroup({
              projectId: project.id as number,
              classificationGroupId: group.id,
              payload,
            })
          : audienceService.audienceServiceCreateClassificationGroup({
              projectId: project.id as number,
              payload,
            });
      const res = await fn;
      enqueueSnackbar(`${group?.name} Saves`, {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      onSaved(res);
    } catch (error) {
      enqueueSnackbar("Something Went Wrong", {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }
    setDisabled(false);
  };

  return (
    <CustomDialog onClose={onClose} fullWidth maxWidth="sm" open>
      <ValidatorForm onSubmit={onSubmit}>
        <DialogTitle>
          {group ? `Edit ${group.name}` : `Add New Group`}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextValidator
                variant="outlined"
                autoFocus={true}
                fullWidth
                name="name"
                label="Group Name *"
                value={name}
                disabled={disabled}
                validators={["required"]}
                errorMessages={["Group Name is required"]}
                onChange={(e: any) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                name="description"
                label="Group Description "
                value={description}
                disabled={disabled}
                multiline
                rows={3}
                minRows={3}
                maxRows={8}
                onChange={(e: any) => setDescription(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={disabled} onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={disabled}
            variant="contained"
            color="primary"
            type="submit"
          >
            {group ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </ValidatorForm>
    </CustomDialog>
  );
}

export default ClassificationGroupDialog;
