import { Typography, TypographyProps, styled } from "@mui/material";

interface IStatusLabelProps extends TypographyProps {
  mapping: {
    [key: string]:
      | "primary"
      | "secondary"
      | "success.main"
      | "error.main"
      | "warning.main"
      | "info.main";
  };
  value: string;
}

const StyledStatus = styled(Typography)<IStatusLabelProps>(
  ({ theme, mapping, value }) => {
    let bg = theme.palette.primary.dark;
    if (mapping[value]) {
      const parts = mapping[value].split(".");
      bg =
        parts.length > 1
          ? theme.palette[parts[0]][parts[1]]
          : theme.palette[parts[0]]["main"];
    }

    return {
      position: "relative",
      paddingLeft: 15,
      "&::before": {
        content: "''",
        height: 10,
        width: 10,
        background: bg,
        position: "absolute",
        left: 0,
        top: "50%",
        transform: "translateY(-5px)",
        borderRadius: 5,
      },
    };
  }
);

function StatusLabel({ value, mapping, children, ...rest }: IStatusLabelProps) {
  return (
    <StyledStatus
      {...rest}
      color={mapping[value]}
      value={value}
      mapping={mapping}
    >
      {children}
    </StyledStatus>
  );
}

export default StatusLabel;
