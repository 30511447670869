/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiClientFromJSON, ApiClientToJSON, } from './ApiClient';
export function ApiOAuthKeyFromJSON(json) {
    return ApiOAuthKeyFromJSONTyped(json, false);
}
export function ApiOAuthKeyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'client': !exists(json, 'client') ? undefined : ApiClientFromJSON(json['client']),
        'apiKey': !exists(json, 'apiKey') ? undefined : json['apiKey'],
        'apiSecret': !exists(json, 'apiSecret') ? undefined : json['apiSecret'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
    };
}
export function ApiOAuthKeyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'client': ApiClientToJSON(value.client),
        'apiKey': value.apiKey,
        'apiSecret': value.apiSecret,
        'domain': value.domain,
        'clientId': value.clientId,
    };
}
