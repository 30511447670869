/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiProductTaggerUniqueField;
(function (ApiProductTaggerUniqueField) {
    ApiProductTaggerUniqueField["TITLE"] = "PRODUCT_FIELD_TITLE";
    ApiProductTaggerUniqueField["DESCRIPTION"] = "PRODUCT_FIELD_DESCRIPTION";
    ApiProductTaggerUniqueField["SKU"] = "PRODUCT_FIELD_SKU";
    ApiProductTaggerUniqueField["CATEGORY"] = "PRODUCT_FIELD_CATEGORY";
    ApiProductTaggerUniqueField["PRICE"] = "PRODUCT_FIELD_PRICE";
    ApiProductTaggerUniqueField["IMAGE"] = "PRODUCT_FIELD_IMAGE";
    ApiProductTaggerUniqueField["PRODUCT_ID"] = "PRODUCT_FIELD_PRODUCT_ID";
    ApiProductTaggerUniqueField["BRAND"] = "PRODUCT_FIELD_BRAND";
})(ApiProductTaggerUniqueField || (ApiProductTaggerUniqueField = {}));
export function ApiProductTaggerUniqueFieldFromJSON(json) {
    return ApiProductTaggerUniqueFieldFromJSONTyped(json, false);
}
export function ApiProductTaggerUniqueFieldFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiProductTaggerUniqueFieldToJSON(value) {
    return value;
}
