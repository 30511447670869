/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiSaleSource;
(function (ApiSaleSource) {
    ApiSaleSource["SALE_SOURCE_UNSPECIFIED"] = "SaleSourceUnspecified";
    ApiSaleSource["SALE_SOURCE_WEBHOOK"] = "SaleSourceWebhook";
})(ApiSaleSource || (ApiSaleSource = {}));
export function ApiSaleSourceFromJSON(json) {
    return ApiSaleSourceFromJSONTyped(json, false);
}
export function ApiSaleSourceFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiSaleSourceToJSON(value) {
    return value;
}
