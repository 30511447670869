/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiAttributionTagReserved;
(function (ApiAttributionTagReserved) {
    ApiAttributionTagReserved["NONE"] = "ATTRIBUTION_TAG_NONE";
    ApiAttributionTagReserved["AWARENESS"] = "ATTRIBUTION_TAG_AWARENESS";
    ApiAttributionTagReserved["ACQUISITION"] = "ATTRIBUTION_TAG_ACQUISITION";
    ApiAttributionTagReserved["RETENTION"] = "ATTRIBUTION_TAG_RETENTION";
})(ApiAttributionTagReserved || (ApiAttributionTagReserved = {}));
export function ApiAttributionTagReservedFromJSON(json) {
    return ApiAttributionTagReservedFromJSONTyped(json, false);
}
export function ApiAttributionTagReservedFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiAttributionTagReservedToJSON(value) {
    return value;
}
