import {
  ApiChartYAxisKey,
  ApiDimension,
  ApiChartDisplayOptions,
  ApiChartTemplate,
} from "@incendium/api";
import StyledComposedChart from "Components/StyledComposedChart/StyledComposedChart";
import { groupBy } from "Helpers/arrays";
import { IChartData, TChartData } from "Interfaces";
import { useFromToContext } from "Providers/FromToProvider";
import {
  filterNonTrendDimensions,
  getUniqueMetrics,
  mergeApiChartYAxisKeys,
  remapYaxisFromUniqueMetrics,
  removeFirstDimension,
} from "features/analytics";
import { memo, useEffect, useMemo, useState } from "react";

interface IAnalyticsComposedBridgeProps {
  displayOptions?: ApiChartDisplayOptions;
  chartData: IChartData | null;
  comparisonChartData?: IChartData;
  dimensions: ApiDimension[];
  chartTemplate?: ApiChartTemplate;
  customDataFn?: (arr: TChartData[]) => TChartData[];
}

function AnalyticsComposedBridge({
  displayOptions,
  chartData,
  comparisonChartData,
  dimensions,
  chartTemplate,
  customDataFn,
}: IAnalyticsComposedBridgeProps) {
  const { comparisonWindowDays } = useFromToContext();
  const [bridgedChartData, setBridgedChartData] = useState<IChartData | null>(
    null
  );

  const lastNonTrendDimension = useMemo(() => {
    const filtered = filterNonTrendDimensions(dimensions);
    return filtered[filtered.length - 1];
  }, [dimensions]);

  useEffect(() => {
    if (!chartData) {
      return;
    }

    // due to back compatability issues we convert the first dimension to name, becouse of this we need to remove the fist dimension
    const data = removeFirstDimension(chartData.data, dimensions);
    if (dimensions.length === 1) {
      setBridgedChartData({
        data,
        y: chartData.y,
      });
      return;
    }

    // for backwards compatabilty, we split second dimension in a chart to multiple metrics,
    // e.g say second dimension is channel and the channels are direct and organic, and metric is lead_count, we create direct//lead_count and organic//lead_count
    const groupedData = groupBy(data, "name");
    const trendData = Object.keys(groupedData).map((key) => {
      let o: any = {
        name: key,
      };
      groupedData[key].forEach((g) => {
        const { name, ...rest } = g;
        Object.keys(rest).forEach((metricKey) => {
          if (metricKey === lastNonTrendDimension) {
            return;
          }
          o[`${rest[lastNonTrendDimension]}\\${metricKey}`] = rest[metricKey];
        });
      });
      return o;
    });

    //  we need all the unique metrics
    const uniqueMetrics = getUniqueMetrics(trendData);

    // putting timeout resolves the issue of composed rendering, but whyyyyyyyy
    setTimeout(() => {
      setBridgedChartData({
        data: trendData,
        y: remapYaxisFromUniqueMetrics(
          (chartData.y || []) as ApiChartYAxisKey[],
          uniqueMetrics
        ),
      });
    }, 1);
  }, [chartData, dimensions, lastNonTrendDimension]);

  const bridgedComparisonChartData = useMemo(() => {
    if (!comparisonChartData) {
      return undefined;
    }
    const data = removeFirstDimension(comparisonChartData.data, dimensions);
    let remappedData = data.map((d) => {
      const { name, ...rest } = d;
      let o: any = {
        name,
      };
      Object.keys(rest).forEach((key) => {
        o[`previous_${key}`] = rest[key];
      });
      return o;
    });
    const uniqueMetrics = getUniqueMetrics(remappedData);

    return {
      data: remappedData,
      y: remapYaxisFromUniqueMetrics(
        (comparisonChartData.y || []) as ApiChartYAxisKey[],
        uniqueMetrics
      ),
    };
  }, [comparisonChartData, dimensions]);

  const mergedYAxis = useMemo(
    () =>
      mergeApiChartYAxisKeys(
        (bridgedChartData?.y || []) as ApiChartYAxisKey[],
        (bridgedComparisonChartData?.y || []) as ApiChartYAxisKey[]
      ),
    [bridgedChartData, bridgedComparisonChartData]
  );

  const comparisonWindow = useMemo(() => {
    if (
      !bridgedComparisonChartData ||
      chartTemplate !== ApiChartTemplate.TREND
    ) {
      return;
    }
    return comparisonWindowDays;
  }, [bridgedComparisonChartData, chartTemplate, comparisonWindowDays]);

  if (!bridgedChartData) {
    return <></>;
  }

  return (
    <StyledComposedChart
      data={
        customDataFn
          ? customDataFn(bridgedChartData.data)
          : bridgedChartData.data
      }
      yAxisKeys={mergedYAxis}
      comparismentChart={bridgedComparisonChartData}
      // yAxisProps={chartProps?.yAxisProps}
      // noAnimation={noAnimation}
      noLegend={displayOptions?.noLegend}
      noReOrder={displayOptions?.noReorder}
      metricsOrder={displayOptions?.metricsOrder}
      layout={displayOptions?.layout}
      // xAxisProps={chartProps?.xAxisProps}
      fills={displayOptions?.fills}
      // tooltipProps={tooltipProps}
      showTooltip
      dimensions={dimensions}
      comparisonWindowDays={comparisonWindow}
      noAxis={displayOptions?.noAxis}
    />
  );
}

export default memo(AnalyticsComposedBridge);
