/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiAttributionClickFromJSON, ApiAttributionClickToJSON, } from './ApiAttributionClick';
import { ApiAudienceFromJSON, ApiAudienceToJSON, } from './ApiAudience';
import { ApiDataFromJSON, ApiDataToJSON, } from './ApiData';
import { ApiGeoLocationFromJSON, ApiGeoLocationToJSON, } from './ApiGeoLocation';
import { ApiLeadFromJSON, ApiLeadToJSON, } from './ApiLead';
import { ApiLeadConversionFromJSON, ApiLeadConversionToJSON, } from './ApiLeadConversion';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
import { ApiSessionStatusFromJSON, ApiSessionStatusToJSON, } from './ApiSessionStatus';
import { ApiSourceFromJSON, ApiSourceToJSON, } from './ApiSource';
export function ApiSessionFromJSON(json) {
    return ApiSessionFromJSONTyped(json, false);
}
export function ApiSessionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'data': !exists(json, 'data') ? undefined : (json['data'].map(ApiDataFromJSON)),
        'lead': !exists(json, 'lead') ? undefined : ApiLeadFromJSON(json['lead']),
        'sessionStart': !exists(json, 'sessionStart') ? undefined : (new Date(json['sessionStart'])),
        'sessionEnd': !exists(json, 'sessionEnd') ? undefined : (new Date(json['sessionEnd'])),
        'source': !exists(json, 'source') ? undefined : ApiSourceFromJSON(json['source']),
        'landingPage': !exists(json, 'landingPage') ? undefined : ApiDataFromJSON(json['landingPage']),
        'geoLocation': !exists(json, 'geoLocation') ? undefined : ApiGeoLocationFromJSON(json['geoLocation']),
        'audiences': !exists(json, 'audiences') ? undefined : (json['audiences'].map(ApiAudienceFromJSON)),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'conversions': !exists(json, 'conversions') ? undefined : (json['conversions'].map(ApiLeadConversionFromJSON)),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'leadId': !exists(json, 'leadId') ? undefined : json['leadId'],
        'sourceId': !exists(json, 'sourceId') ? undefined : json['sourceId'],
        'landingPageId': !exists(json, 'landingPageId') ? undefined : json['landingPageId'],
        'isEffective': !exists(json, 'isEffective') ? undefined : json['isEffective'],
        'status': !exists(json, 'status') ? undefined : ApiSessionStatusFromJSON(json['status']),
        'cost': !exists(json, 'cost') ? undefined : json['cost'],
        'acost': !exists(json, 'acost') ? undefined : json['acost'],
        'analyticsSynced': !exists(json, 'analyticsSynced') ? undefined : json['analyticsSynced'],
        'attributionClicks': !exists(json, 'attributionClicks') ? undefined : (json['attributionClicks'].map(ApiAttributionClickFromJSON)),
        'crawlerId': !exists(json, 'crawlerId') ? undefined : json['crawlerId'],
    };
}
export function ApiSessionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'project': ApiProjectToJSON(value.project),
        'data': value.data === undefined ? undefined : (value.data.map(ApiDataToJSON)),
        'lead': ApiLeadToJSON(value.lead),
        'sessionStart': value.sessionStart === undefined ? undefined : (value.sessionStart.toISOString()),
        'sessionEnd': value.sessionEnd === undefined ? undefined : (value.sessionEnd.toISOString()),
        'source': ApiSourceToJSON(value.source),
        'landingPage': ApiDataToJSON(value.landingPage),
        'geoLocation': ApiGeoLocationToJSON(value.geoLocation),
        'audiences': value.audiences === undefined ? undefined : (value.audiences.map(ApiAudienceToJSON)),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'conversions': value.conversions === undefined ? undefined : (value.conversions.map(ApiLeadConversionToJSON)),
        'projectId': value.projectId,
        'leadId': value.leadId,
        'sourceId': value.sourceId,
        'landingPageId': value.landingPageId,
        'isEffective': value.isEffective,
        'status': ApiSessionStatusToJSON(value.status),
        'cost': value.cost,
        'acost': value.acost,
        'analyticsSynced': value.analyticsSynced,
        'attributionClicks': value.attributionClicks === undefined ? undefined : (value.attributionClicks.map(ApiAttributionClickToJSON)),
        'crawlerId': value.crawlerId,
    };
}
