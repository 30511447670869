export function getSelectedText() {
  if (typeof window === "undefined") {
    return "";
  }
  if (window.getSelection) {
    // all browsers, except IE before version 9
    var range = window.getSelection();
    if (range) {
      return range.toString();
    }
  } else {
    //@ts-ignore
    if (document.selection.createRange) {
      // Internet Explorer
      //@ts-ignore
      var range = document.selection.createRange();
      //@ts-ignore
      return range.text;
    }
  }
}

