/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function ApiApiSourceFromJSON(json) {
    return ApiApiSourceFromJSONTyped(json, false);
}
export function ApiApiSourceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'apiKey': !exists(json, 'apiKey') ? undefined : json['apiKey'],
        'apiSecret': !exists(json, 'apiSecret') ? undefined : json['apiSecret'],
        'createDatetime': !exists(json, 'createDatetime') ? undefined : (new Date(json['createDatetime'])),
        'updatedDatetime': !exists(json, 'updatedDatetime') ? undefined : (new Date(json['updatedDatetime'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
    };
}
export function ApiApiSourceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'apiKey': value.apiKey,
        'apiSecret': value.apiSecret,
        'createDatetime': value.createDatetime === undefined ? undefined : (value.createDatetime.toISOString()),
        'updatedDatetime': value.updatedDatetime === undefined ? undefined : (value.updatedDatetime.toISOString()),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
    };
}
