import {
  ApiLeadStatusRule,
  ApiConversion,
  ApiProject,
  LeadStatusServiceUpdateLeadStatusRuleRequest,
  ApiOperator,
  ApiConditionGroup,
} from "@incendium/api";
import { leadStatusService } from "Apis";
import { useConditionGroupContext } from "Components/ConditionalGroup/ConditionGroupProvider";
import ConditionGroupsFormWrapper from "Components/ConditionalGroup/ConditionGroupsFormWrapper";
import { isEmpty } from "Helpers/isEmpty";
import { useLeadStatusRules } from "Hooks/useLeadStatusRules";
import produce from "immer";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import LeadStatusRuleRules from "./LeadStatusRuleRules";
import LeadStatusRuleSidebar from "./LeadStatusRuleSidebar";

interface ILeadStatusRuleFormProps {
  leadStatusRule: ApiLeadStatusRule;
  setLeadStatusRule: React.Dispatch<
    React.SetStateAction<ApiLeadStatusRule | undefined>
  >;
  project: ApiProject;
  conversions: ApiConversion[];
}

function LeadStatusRuleForm({
  leadStatusRule,
  setLeadStatusRule,
  project,
  conversions,
}: ILeadStatusRuleFormProps) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { conditionGroups, setConditionGroups } = useConditionGroupContext();
  const { leadStatusRules, setLeadStatusRules } = useLeadStatusRules();

  useEffect(() => {
    if (!leadStatusRule || conditionGroups.length) {
      return;
    }
    const groups = leadStatusRule.rules || [];
    setConditionGroups(
      groups && groups.length > 0
        ? [...groups]
        : [
            {
              conditions: [{}],
            },
          ]
    );
  }, [leadStatusRule, setConditionGroups, conditionGroups]);

  const onCancel = () => {
    history.push(
      `/clients/${project.clientId}/projects/${project?.id}/train/leads-status`
    );
  };
  const onSubmit = async () => {
    const req: LeadStatusServiceUpdateLeadStatusRuleRequest = {
      projectId: project.id as number,
      leadStatusRuleId: leadStatusRule.id as number,
      payload: {
        name: leadStatusRule.name,
        type: leadStatusRule.type,
        asDefault: leadStatusRule.asDefault,
        rules: (conditionGroups as ApiConditionGroup[])
          .filter((c) => {
            return (
              !isEmpty(c) &&
              (c.conditions || []).filter((co) => !isEmpty(co)).length > 0
            );
          })
          .map((c) => ({
            conditions: (c.conditions || []).map((condition) => {
              if (condition.conversionId) {
                return {
                  ...condition,
                  operator: ApiOperator.IS_IN,
                };
              }

              return {
                ...condition,
              };
            }),
          })),
      },
    };
    const res = await leadStatusService.leadStatusServiceUpdateLeadStatusRule(
      req
    );
    enqueueSnackbar(`${res.name} Saved`, {
      variant: "success",
      autoHideDuration: 2000,
      anchorOrigin: { horizontal: "right", vertical: "top" },
    });

    setLeadStatusRules(
      produce(leadStatusRules, (draft) => {
        const idx = leadStatusRules.findIndex((e) => e.id === res.id);
        if (idx < 0) {
          return;
        }
        draft[idx] = res;
      })
    );
  };

  return (
    <>
      <ConditionGroupsFormWrapper
        onSubmit={onSubmit}
        onCancel={onCancel}
        rules={<LeadStatusRuleRules conversions={conversions} />}
      >
        <LeadStatusRuleSidebar
          leadStatusRule={leadStatusRule}
          setLeadStatusRule={setLeadStatusRule}
          project={project}
        />
      </ConditionGroupsFormWrapper>
    </>
  );
}

export default LeadStatusRuleForm;
