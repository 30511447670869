/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiBucketGroupFromJSON, ApiBucketGroupToJSON, } from './ApiBucketGroup';
import { ApiOperatorFromJSON, ApiOperatorToJSON, } from './ApiOperator';
export function ApiBucketRuleFromJSON(json) {
    return ApiBucketRuleFromJSONTyped(json, false);
}
export function ApiBucketRuleFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'field': !exists(json, 'field') ? undefined : json['field'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'operator': !exists(json, 'operator') ? undefined : ApiOperatorFromJSON(json['operator']),
        'group': !exists(json, 'group') ? undefined : ApiBucketGroupFromJSON(json['group']),
        'bucketGroupId': !exists(json, 'bucketGroupId') ? undefined : json['bucketGroupId'],
    };
}
export function ApiBucketRuleToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'field': value.field,
        'value': value.value,
        'operator': ApiOperatorToJSON(value.operator),
        'group': ApiBucketGroupToJSON(value.group),
        'bucketGroupId': value.bucketGroupId,
    };
}
