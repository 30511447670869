import { ApiChannelGroup, ApiBucketGroupPayload } from "@incendium/api";
import { TableCell, Typography } from "@mui/material";
import { friendlyOperator } from "Helpers/operators";
import React from "react";

interface IGroupsCellProps {
  groups: (ApiChannelGroup | ApiBucketGroupPayload)[];
}

function GroupsCell({ groups }: IGroupsCellProps) {
  return (
    <TableCell>
      {groups.map((group, idx) => {
        const rules = group?.rules || [];
        return (
          <div key={idx}>
            <Typography
              display="inline"
              variant="caption"
              color="textSecondary"
            >
              {rules.map((rule, i) => (
                <React.Fragment key={`${idx}-${i}`}>
                  <Typography
                    display="inline"
                    variant="caption"
                    color="textSecondary"
                  >
                    {rule.field}{" "}
                    <strong>{friendlyOperator(rule.operator)}</strong>{" "}
                    {rule.value}
                  </Typography>
                  {i + 1 < rules.length && <strong> & </strong>}
                </React.Fragment>
              ))}
            </Typography>
            {idx + 1 < groups.length && (
              <Typography
                variant="body2"
                color="textSecondary"
                fontWeight={"bold"}
              >
                -- Or --
              </Typography>
            )}
          </div>
        );
      })}
    </TableCell>
  );
}

export default GroupsCell;
