import {
  ApiLocationPageTagger,
  ApiConversion,
  ApiConversionType,
} from "@incendium/api";
import { IconButton } from "@mui/material";
import { AddCircle, Delete, Edit } from "@mui/icons-material";
import { Tag } from "Components/Tag";

type TTag = ApiLocationPageTagger | ApiConversion;

export const TagList = ({
  tags,
  onAdd,
  onEdit,
  onDelete,
  title,
}: {
  title: string;
  tags: TTag[];
  onAdd: () => void;
  onEdit: (tag: TTag) => void;
  onDelete: (tag: TTag) => void;
}) => {
  return (
    <>
      <Tag
        text={title}
        color="primary"
        icons={
          <>
            <IconButton onClick={onAdd} size="small">
              <AddCircle color="primary" />
            </IconButton>
          </>
        }
      />
      {tags.map((tag) => {
        return (
          <Tag
            key={tag.id}
            text={`${
              (tag as ApiConversion).type
                ? ((tag as ApiConversion).type === ApiConversionType.MACRO
                    ? "Conversion"
                    : "Interaction") + " - "
                : ""
            } ${tag.name}`}
            color="secondary"
            icons={
              <>
                <IconButton onClick={() => onEdit(tag)} size="small">
                  <Edit color="primary" />
                </IconButton>
                <IconButton onClick={() => onDelete(tag)} size="small">
                  <Delete color="secondary" />
                </IconButton>
              </>
            }
          />
        );
      })}
    </>
  );
};
