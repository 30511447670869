import { Box, Stack, Typography } from "@mui/material";
import { useSelectedProject } from "Hooks";
import { useState } from "react";
import { S3Response } from "react-s3-uploader";
import { useCSVUploaderContext } from "Providers/CSVUploaderProvider";
import { createMedia } from "features/media";
import { ApiMediaType } from "@incendium/api";
import { UploaderButton } from "features/media";

function UploadStepOne() {
  const { selectedClient, selectedProject } = useSelectedProject();
  const [uploading, setUploading] = useState(false);
  const { step, setStep, setMedia } = useCSVUploaderContext();

  const onProgress = (percent: number, status: string, file: File) => {
    setUploading(status !== "Upload completed");
  };

  // todo: hanlde error ui
  const onError = (error: string) => {
    console.error(error);
    setUploading(false);
  };

  const onFinish = async (result: S3Response, file: File) => {
    // add as media
    const res = await createMedia(
      result,
      file,
      selectedProject?.id as number,
      ApiMediaType.CSV
    );

    setMedia(res || {});
    setStep(step + 1);
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      pb={20}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        maxWidth={350}
      >
        <Stack spacing={2}>
          <Typography variant="h3" align="center">
            Upload CSV to get started.
          </Typography>

          <UploaderButton
            uploading={uploading}
            text="Upload CSV"
            onFinish={onFinish}
            onError={onError}
            hidden={true}
            path={`clients/${selectedClient?.id}/projects/${selectedProject?.id}`}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            project={selectedProject || {}}
            onProgress={onProgress}
            buttonProps={{
              size: "large",
              fullWidth: true,
            }}
          />
        </Stack>
      </Box>
    </Box>
  );
}

export default UploadStepOne;
