import {
  ApiCSVDateGrouping,
  ApiCSVField,
  ApiCSVFieldType,
} from "@incendium/api";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import produce from "immer";
import { useCSVUploaderContext } from "Providers/CSVUploaderProvider";
import { useEffect, useMemo } from "react";

interface IUploaderRowValidationProps {
  field: ApiCSVField;
}

function UploaderRowValidation({ field }: IUploaderRowValidationProps) {
  const { rule, mapping, userValue, dateGrouping, setValidation } =
    useCSVUploaderContext();

  const isRequired = useMemo(() => {
    return field.required;
  }, [field]);

  const isMustHaveOne = useMemo(() => {
    if (field.must === "") {
      return false;
    }
    return (rule.fields || []).filter((f) => f.must === field.must).length > 1;
  }, [rule, field]);

  const isValid = useMemo(() => {
    const isMapped =
      Object.keys(
        Object.fromEntries(
          Object.entries(mapping).filter(
            (a) => a[0] === field.id && (a[1].columns || []).length > 0
          )
        )
      ).length > 0 || (userValue[field.id || ""] || "").length > 0;

    return (
      isMapped &&
      (field.type === ApiCSVFieldType.CSV_TYPE_DATETIME &&
      rule.dateGroupingRequired
        ? dateGrouping &&
          dateGrouping !== ApiCSVDateGrouping.CSV_DATE_GROUPING_NO_GROUPING
        : true)
    );
  }, [mapping, field, userValue, dateGrouping, rule]);

  const mustMatching = useMemo(() => {
    if (field.must === "") {
      return false;
    }
    const same = (rule.fields || [])
      .filter((f) => f.must === field.must)
      .map((f) => f.id || "");
    const matching = Object.keys(mapping)
      .filter((f) => same.filter((k) => k === f).length > 0)
      .map((f) => mapping[f])
      .filter((f) => (f.columns || []).length > 0);

    return matching.length > 0;
  }, [mapping, rule, field]);

  useEffect(() => {
    setValidation((validation) =>
      produce(validation, (draft) => {
        draft[field.id || ""] = isValid;
      })
    );
  }, [isValid, field]);

  return (
    <Tooltip
      title={
        isRequired
          ? "Required."
          : isMustHaveOne
          ? "One of Required."
          : "Optional."
      }
      arrow
      placement="top"
    >
      {isValid ? (
        <CheckCircle color={"success"} fontSize="large" />
      ) : mustMatching ? (
        <CheckCircle color={"warning"} fontSize="large" />
      ) : (
        <Cancel
          fontSize="large"
          color={isRequired ? "error" : isMustHaveOne ? "warning" : "disabled"}
        />
      )}
    </Tooltip>
  );
}

export default UploaderRowValidation;
