import { useMemo } from "react";
import { Helmet } from "react-helmet";

export interface IWithDocumentTitleProps {
  documentTitle?: string;
}

const withDocumentTitle = <T extends IWithDocumentTitleProps>(
  Comp: React.ComponentType<T>
) => {
  return (props: T) => {
    const { documentTitle } = props;

    const title = useMemo(() => {
      return documentTitle ? `${documentTitle} | Incendium` : "Incendium";
    }, [documentTitle]);

    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Comp {...(props as T)} />
      </>
    );
  };
};

export default withDocumentTitle;
