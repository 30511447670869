import { ApiLeadStatusType } from "@incendium/api";
import { analyticsService } from "Apis";
import { TSalesFlowData } from "Interfaces";

export default async function getSalesB2BAnalytics(
  projectId: number,
  leadStatus: ApiLeadStatusType,
  closedFunnel: boolean,
  from?: Date,
  to?: Date,
  lastNDays?: number
): Promise<any> {
  const res = await analyticsService.analyticsServiceGetSalesFlow({
    projectId,
    payload: {
      from,
      to,
      lastNDays,
      leadStatus,
      closedFunnel,
    },
  });

  if (!res.metrics || !res.values || res.values?.length === 0) {
    return [];
  }

  const result: TSalesFlowData = {};
  for (let i = 0; i < (res.metrics || []).length; i++) {
    const metric = res.metrics[i];
    const { type, value } = res.values[i];
    result[metric] = type?.includes("Int") ? Number(value) : `${value}`;
  }

  return result;
}
