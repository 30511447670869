/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiDomainPayloadToJSON, ApiListDomainsResponseFromJSON, ApiReadDomainResponseFromJSON, } from '../models';
/**
 *
 */
export class LandingDomainServiceApi extends runtime.BaseAPI {
    /**
     */
    landingDomainServiceCreateDomainRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling landingDomainServiceCreateDomain.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling landingDomainServiceCreateDomain.');
            }
            const queryParameters = {};
            if (requestParameters.domainId !== undefined) {
                queryParameters['domainId'] = requestParameters.domainId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/domain/v1/projects/{projectId}/domains`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiDomainPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadDomainResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    landingDomainServiceCreateDomain(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.landingDomainServiceCreateDomainRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    landingDomainServiceDeleteDomainRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling landingDomainServiceDeleteDomain.');
            }
            if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling landingDomainServiceDeleteDomain.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadSubdomain !== undefined) {
                queryParameters['payload.subdomain'] = requestParameters.payloadSubdomain;
            }
            if (requestParameters.payloadDomain !== undefined) {
                queryParameters['payload.domain'] = requestParameters.payloadDomain;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/domain/v1/projects/{projectId}/domains/{domainId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"domainId"}}`, encodeURIComponent(String(requestParameters.domainId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    landingDomainServiceDeleteDomain(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.landingDomainServiceDeleteDomainRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    landingDomainServiceListDomainsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling landingDomainServiceListDomains.');
            }
            const queryParameters = {};
            if (requestParameters.domainId !== undefined) {
                queryParameters['domainId'] = requestParameters.domainId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadSubdomain !== undefined) {
                queryParameters['payload.subdomain'] = requestParameters.payloadSubdomain;
            }
            if (requestParameters.payloadDomain !== undefined) {
                queryParameters['payload.domain'] = requestParameters.payloadDomain;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/domain/v1/projects/{projectId}/domains`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListDomainsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    landingDomainServiceListDomains(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.landingDomainServiceListDomainsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    landingDomainServiceReadDomainRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling landingDomainServiceReadDomain.');
            }
            if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling landingDomainServiceReadDomain.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadSubdomain !== undefined) {
                queryParameters['payload.subdomain'] = requestParameters.payloadSubdomain;
            }
            if (requestParameters.payloadDomain !== undefined) {
                queryParameters['payload.domain'] = requestParameters.payloadDomain;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/domain/v1/projects/{projectId}/domains/{domainId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"domainId"}}`, encodeURIComponent(String(requestParameters.domainId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadDomainResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    landingDomainServiceReadDomain(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.landingDomainServiceReadDomainRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    landingDomainServiceUpdateDomainRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling landingDomainServiceUpdateDomain.');
            }
            if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
                throw new runtime.RequiredError('domainId', 'Required parameter requestParameters.domainId was null or undefined when calling landingDomainServiceUpdateDomain.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling landingDomainServiceUpdateDomain.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/domain/v1/projects/{projectId}/domains/{domainId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"domainId"}}`, encodeURIComponent(String(requestParameters.domainId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiDomainPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadDomainResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    landingDomainServiceUpdateDomain(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.landingDomainServiceUpdateDomainRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
