import { Box } from "@mui/system";
import { chunk } from "Helpers/arrays";
import { snakeToTitle } from "Helpers/camelToText";
import { TColourMap } from "Providers/AnalyticsProvider";
import { ChartLegendItem } from "features/analytics";
import { useMemo } from "react";

interface IChartLegendProps {
  items: string[];
  noFlex?: boolean;
  noTooltip?: boolean;
  flexAfter?: number;
  colourMap?: TColourMap;
}

function ChartLegend({
  items,
  noFlex,
  noTooltip,
  flexAfter,
  colourMap,
}: IChartLegendProps) {
  const chartItems = useMemo(() => {
    return items.map((s) => {
      return snakeToTitle((s || "").replace("\\", "_"));
    });
  }, [items]);

  const chunked = useMemo(() => {
    return chunk(chartItems, flexAfter || 2);
  }, [chartItems, flexAfter]);

  let n = -1;

  return (
    <Box
      sx={{
        display: noFlex ? "block" : "flex",
        flexWrap: "wrap",
      }}
    >
      {chunked.map((chunk, idx) => {
        return (
          <Box
            key={idx}
            sx={{
              flex: 1,
              maxWidth: noFlex || chartItems.length < 6 ? "100%" : "33.333%",
            }}
          >
            <Box mr={(idx + 1) % 3 === 0 ? 0 : 2}>
              {chunk.map((item, i) => {
                n++;
                return (
                  <ChartLegendItem
                    item={item}
                    index={n}
                    key={i}
                    noTooltip={noTooltip}
                    fill={
                      colourMap && colourMap[item.split("\\")[0]]
                        ? colourMap[item.split("\\")[0]]
                        : undefined
                    }
                  />
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

export default ChartLegend;
