/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function ApiAccountFromJSON(json) {
    return ApiAccountFromJSONTyped(json, false);
}
export function ApiAccountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}
export function ApiAccountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'externalId': value.externalId,
        'name': value.name,
    };
}
