import { useMemo } from "react";
import { metricTitles } from "Interfaces/enums";
import InteractionWheel from "Components/InteractionsWheel";

import {
  metricTitlesToJobDislpayName,
  metricTitlesToJobWheelPosition,
} from "Helpers/analytics";
import { useSelectedProject } from "Hooks";
import { useFromToContext } from "Providers/FromToProvider";
import { ApiChartAttribute, ApiDimension, ApiMetric } from "@incendium/api";
import { useChartData } from "features/analytics";

type TChartDataValue = {
  name: string | null;
  value: number;
};

interface IJobInteractionsWheelProps {
  attributes: ApiChartAttribute[];
}

function JobInteractionsWheel({ attributes }: IJobInteractionsWheelProps) {
  const { selectedProject } = useSelectedProject();
  const { chartOutput } = useFromToContext();

  const chart = useMemo(
    () => ({
      name: "",
      dimension: [ApiDimension.DIMENSION_CHANNEL],
      yAxisKeys: [
        {
          fields: [
            ApiMetric.METRIC_JOB_IMPRESSION_COUNT,
            ApiMetric.METRIC_JOB_IMPRESSION_RATE,
            ApiMetric.METRIC_JOB_CLICK_THROUGH_COUNT,
            ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_COUNT,
            ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_RATE,
            ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT,
            ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_RATE,
            ApiMetric.METRIC_JOB_CLICK_THROUGH_RATE,
            ApiMetric.METRIC_VISIT_TO_APPLICATION_RATE,
          ],
        },
      ],
      attributes,
    }),
    [attributes]
  );

  const { chartData: fetchedData, loading } = useChartData(
    selectedProject,
    chart,
    chartOutput
  );

  const data = useMemo(
    () =>
      [...(fetchedData?.data || [])].sort((a, b) =>
        (a.name || "").localeCompare(b.name || "")
      ),
    [fetchedData]
  );

  // use map to guarntee order
  const chartData = useMemo(() => {
    const m = new Map<metricTitles, TChartDataValue[]>();
    m.set(
      metricTitles.Application,
      data.map((d) => ({
        name: d.name,
        value: Number(d[ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT]),
      }))
    );
    m.set(
      metricTitles.Apply_Click,
      data.map((d) => ({
        name: d.name,
        value: Number(d[ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_COUNT]),
      }))
    );

    m.set(
      metricTitles.Click_Throughs,
      data.map((d) => ({
        name: d.name,
        value: Number(d[ApiMetric.METRIC_JOB_CLICK_THROUGH_COUNT]),
      }))
    );
    m.set(
      metricTitles.Impressions,
      data.map((d) => ({
        name: d.name,
        value: Number(d[ApiMetric.METRIC_JOB_IMPRESSION_COUNT]),
      }))
    );

    return m;
  }, [data]);

  const ratesData = useMemo(() => {
    const m = new Map<metricTitles, TChartDataValue[]>();
    m.set(
      metricTitles.Application,
      data.map((d) => ({
        name: d.name,
        value: Number(d[ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_RATE]),
      }))
    );
    m.set(
      metricTitles.Apply_Click,
      data.map((d) => ({
        name: d.name,
        value: Number(d[ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_RATE]),
      }))
    );

    m.set(
      metricTitles.Click_Throughs,
      data.map((d) => ({
        name: d.name,
        value: Number(d[ApiMetric.METRIC_JOB_CLICK_THROUGH_RATE]),
      }))
    );
    m.set(
      metricTitles.Impressions,
      data.map((d) => ({
        name: d.name,
        value: Number(d[ApiMetric.METRIC_JOB_IMPRESSION_RATE]),
      }))
    );

    return m;
  }, [data]);

  const channels = useMemo(() => {
    return Array.from(new Set(data.map((d) => d.name || "")));
  }, [data]);

  return (
    <>
      <InteractionWheel
        loading={loading}
        channels={channels}
        chartData={chartData}
        ratesData={ratesData}
        displayMetricFn={metricTitlesToJobDislpayName}
        positionMetricFn={metricTitlesToJobWheelPosition}
      />
    </>
  );
}

export default JobInteractionsWheel;
