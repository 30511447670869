import { Box, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useBreadcrumb } from "Hooks";
import { useProtectedRoutes } from "Hooks/useProtectedRoutes";
import { useUser } from "Hooks/useUser";
import { IBreadcrumb } from "Interfaces";
import { useMemo } from "react";
import { Link } from "react-router-dom";

const useBreadcrumbStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    color: theme.palette.secondary.main,
  },
  link: {
    textDecoration: "none",
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    fontSize: 14,
    display: "flex",
    textTransform: "lowercase",
    "& p": {
      fontSize: 14,
      fontWeight: 600,
    },
    "&:hover p": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
    "&.disabled": {
      "&:hover p": {
        color: "inherit",
        textDecoration: "none",
      },
    },
  },
}));

const BreadLink = ({
  breadCrumb,
  breadcrumbs,
  i,
}: {
  breadCrumb: IBreadcrumb;
  breadcrumbs: IBreadcrumb[];
  i: number;
}) => {
  const classes = useBreadcrumbStyles();
  const protectedRoutes = useProtectedRoutes();
  const { hasPermission } = useUser();

  // try find link in routes
  const route = useMemo(() => {
    return protectedRoutes.find(
      (pr) =>
        ((pr.path as string) || "").replaceAll(/:[a-z]*Id/g, "_") ===
        breadCrumb.link.replaceAll(/\d+/g, "_")
    );
  }, [protectedRoutes, breadCrumb.link]);

  const renderBody = () => (
    <>
      {breadCrumb.icon ? (
        <breadCrumb.icon
          color={i + 1 === breadcrumbs.length ? "primary.dark" : "info"}
          sx={{
            fontSize: 18,
          }}
        />
      ) : (
        <Typography
          noWrap
          color={i + 1 === breadcrumbs.length ? "info.main" : "primary.dark"}
        >
          {breadCrumb.text}
        </Typography>
      )}
    </>
  );
  return (
    <>
      {route && (!route.minRole || hasPermission(route.minRole)) ? (
        <>
          <Link
            key={breadCrumb.text + i}
            className={classes.link}
            to={breadCrumb.link}
          >
            {renderBody()}
          </Link>
          /
        </>
      ) : i + 1 === breadcrumbs.length ? (
        <>
          <span className={`${classes.link} disabled`}>{renderBody()}</span>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

function Breadcrumb() {
  const breadcrumbs = useBreadcrumb();

  const classes = useBreadcrumbStyles();

  const transformedBreadCrumbs = useMemo(() => {
    return breadcrumbs.reduce((prev, breadCrumb, i) => {
      const crumb = (
        <BreadLink
          key={i}
          breadCrumb={breadCrumb}
          i={i}
          breadcrumbs={breadcrumbs}
        />
      );
      if (i + 1 !== breadcrumbs.length) {
        return [...prev, crumb];
      }
      return [...prev, crumb];
    }, [] as any[]);
  }, [breadcrumbs]);

  return (
    <div className={classes.root}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {transformedBreadCrumbs}
      </Box>
    </div>
  );
}

export default Breadcrumb;
