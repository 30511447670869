import { ApiProjectSettingKey } from "@incendium/api";
import { useClientSelector } from "./useClientSelector";
import { useProjectSelector } from "./useProjectSelector";
import { enumToArray } from "Helpers/enumToText";
import { useState } from "react";
import { useEffect } from "react";
import produce from "immer";

export type TProjectSettings = { [key in ApiProjectSettingKey]?: string };

export const useSelectedProject = () => {
  const [projectSettings, setProjectSettings] = useState<TProjectSettings>({});
  const selectedProject = useProjectSelector();
  const client = useClientSelector();

  useEffect(() => {
    setProjectSettings((settings) =>
      produce(settings, (draft) => {
        enumToArray(ApiProjectSettingKey).forEach((key) => {
          let value = selectedProject?.settings?.find(
            (s) => s.key === key
          )?.value;
          if (value) {
            draft[key] = value;
          }
        });
      })
    );
  }, [selectedProject?.settings]);

  return {
    selectedProject,
    selectedClient: client,
    projectSettings,
    setProjectSettings,
  };
};
