/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiLeadStatusTypeFromJSON, ApiLeadStatusTypeToJSON, } from './ApiLeadStatusType';
export function ApiUserActionUserFromJSON(json) {
    return ApiUserActionUserFromJSONTyped(json, false);
}
export function ApiUserActionUserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'key': !exists(json, 'key') ? undefined : json['key'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'device': !exists(json, 'device') ? undefined : json['device'],
        'status': !exists(json, 'status') ? undefined : ApiLeadStatusTypeFromJSON(json['status']),
        'score': !exists(json, 'score') ? undefined : json['score'],
        'audiences': !exists(json, 'audiences') ? undefined : json['audiences'],
        'totalRevenue': !exists(json, 'totalRevenue') ? undefined : json['totalRevenue'],
        'lastActivity': !exists(json, 'lastActivity') ? undefined : (new Date(json['lastActivity'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'os': !exists(json, 'os') ? undefined : json['os'],
        'browser': !exists(json, 'browser') ? undefined : json['browser'],
    };
}
export function ApiUserActionUserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'key': value.key,
        'email': value.email,
        'device': value.device,
        'status': ApiLeadStatusTypeToJSON(value.status),
        'score': value.score,
        'audiences': value.audiences,
        'totalRevenue': value.totalRevenue,
        'lastActivity': value.lastActivity === undefined ? undefined : (value.lastActivity.toISOString()),
        'id': value.id,
        'os': value.os,
        'browser': value.browser,
    };
}
