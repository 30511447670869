/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiPageFromJSON, ApiPageToJSON, } from './ApiPage';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiDomainFromJSON(json) {
    return ApiDomainFromJSONTyped(json, false);
}
export function ApiDomainFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'secure': !exists(json, 'secure') ? undefined : json['secure'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'pages': !exists(json, 'pages') ? undefined : (json['pages'].map(ApiPageFromJSON)),
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'domainNameId': !exists(json, 'domainNameId') ? undefined : json['domainNameId'],
        'isPrimary': !exists(json, 'isPrimary') ? undefined : json['isPrimary'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'crossDomainEnabled': !exists(json, 'crossDomainEnabled') ? undefined : json['crossDomainEnabled'],
    };
}
export function ApiDomainToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'domain': value.domain,
        'secure': value.secure,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'pages': value.pages === undefined ? undefined : (value.pages.map(ApiPageToJSON)),
        'project': ApiProjectToJSON(value.project),
        'projectId': value.projectId,
        'domainNameId': value.domainNameId,
        'isPrimary': value.isPrimary,
        'url': value.url,
        'crossDomainEnabled': value.crossDomainEnabled,
    };
}
