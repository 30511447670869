import { ApiLeadStatusType, ApiSalesFlowMetric } from "@incendium/api";
import { useLeadStatusRules } from "Hooks/useLeadStatusRules";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

export type TSalesType = {
  type: ApiLeadStatusType;
  label: string;
  short: string;
  path: string;
  name: string;
  highlightMetrics: ApiSalesFlowMetric[];
  title?: string;
};

type TSalesFunnelContext = {
  types: TSalesType[];
  current: TSalesType | undefined;
};

export const SalesFunnelContext = createContext<TSalesFunnelContext>({
  types: [],
  current: undefined,
});

export const useSalesFunnelContext = () => useContext(SalesFunnelContext);

const SalesFunnelProvider = ({
  children,
  location,
}: {
  children: React.ReactNode;
  location: any;
}) => {
  const [current, setCurrent] = useState<TSalesType | undefined>(undefined);
  const { leadStatusRules, loading } = useLeadStatusRules();
  const [types, setTypes] = useState<TSalesType[]>([]);

  useEffect(() => {
    if (loading || leadStatusRules.length === 0) {
      return;
    }

    setTypes([
      {
        type: ApiLeadStatusType.PROSPECT,
        name:
          leadStatusRules.find((l) => l.type === ApiLeadStatusType.PROSPECT)
            ?.name || "",
        label: "Prospect",
        short: "Prospect",
        path: "prospect",
        highlightMetrics: [
          ApiSalesFlowMetric.SESSIONS,
          ApiSalesFlowMetric.NEW_SESSIONS_PERCENTAGE,
          ApiSalesFlowMetric.RETURNING_SESSION_PERCENTAGE,
          ApiSalesFlowMetric.RETURNING_CUSTOMER_PERCENTAGE, // ??
          ApiSalesFlowMetric.SESSION_COUNT,
        ],
      },
      {
        type: ApiLeadStatusType.MQL,
        name:
          leadStatusRules.find((l) => l.type === ApiLeadStatusType.MQL)?.name ||
          "",
        label: "Conversion Rate 1",
        short: "CR1",
        path: "conversion-rate-1",
        title: "Prospects to MQL",
        highlightMetrics: [
          ApiSalesFlowMetric.CURRENT_LEADS,
          ApiSalesFlowMetric.TIME_TO_STATUS,
          ApiSalesFlowMetric.SESSION_COUNT,
          ApiSalesFlowMetric.TO_STATUS_RATE,
          ApiSalesFlowMetric.RESPONSE_TIME,
          ApiSalesFlowMetric.REJECTION_RATE,
        ],
      },
      {
        type: ApiLeadStatusType.SQL,
        name:
          leadStatusRules.find((l) => l.type === ApiLeadStatusType.SQL)?.name ||
          "",
        label: "Conversion Rate 2",
        short: "CR2",
        path: "conversion-rate-2",
        title: "MQLs to SQL",
        highlightMetrics: [
          ApiSalesFlowMetric.CURRENT_LEADS,
          ApiSalesFlowMetric.TIME_TO_STATUS,
          ApiSalesFlowMetric.TO_STATUS_RATE,
          ApiSalesFlowMetric.NUMBER_OF_INTERACTIONS,
          ApiSalesFlowMetric.RESPONSE_TIME,
          ApiSalesFlowMetric.REJECTION_RATE,
        ],
      },
      {
        type: ApiLeadStatusType.SAL,
        name:
          leadStatusRules.find((l) => l.type === ApiLeadStatusType.SAL)?.name ||
          "",
        label: "Conversion Rate 3",
        short: "CR3",
        path: "conversion-rate-3",
        title: "SQL to SAL",
        highlightMetrics: [
          ApiSalesFlowMetric.CURRENT_LEADS,
          ApiSalesFlowMetric.TIME_TO_STATUS,
          ApiSalesFlowMetric.TO_STATUS_RATE,
          ApiSalesFlowMetric.NUMBER_OF_INTERACTIONS,
          ApiSalesFlowMetric.RESPONSE_TIME,
          ApiSalesFlowMetric.REJECTION_RATE,
        ],
      },
      {
        type: ApiLeadStatusType.CUSTOMER,
        name:
          leadStatusRules.find((l) => l.type === ApiLeadStatusType.CUSTOMER)
            ?.name || "",
        label: "Acquisitions",
        short: "CR4",
        path: "acquisitions",
        title: "Acquisitions",
        highlightMetrics: [
          ApiSalesFlowMetric.CURRENT_LEADS,
          ApiSalesFlowMetric.TIME_TO_STATUS,
          ApiSalesFlowMetric.TO_STATUS_RATE,
          ApiSalesFlowMetric.NUMBER_OF_INTERACTIONS,
          ApiSalesFlowMetric.REVENUE,
        ],
      },
    ]);
  }, [leadStatusRules, loading]);

  useEffect(() => {
    let crKey: string | undefined = undefined;
    if (location.pathname.includes("sales-flow-analysis-report")) {
      crKey = location.pathname.split("sales-flow-analysis-report/")[1];
    }
    if (!crKey) {
      return;
    }
    const idx = types.findIndex((t) => t.path === crKey);
    if (idx >= 0) {
      setCurrent(types[idx]);
    }
  }, [types, location]);

  const state = useMemo(() => {
    return {
      types,
      current,
    };
  }, [types, current]);

  return (
    <SalesFunnelContext.Provider value={state}>
      {children}
    </SalesFunnelContext.Provider>
  );
};

export default SalesFunnelProvider;
