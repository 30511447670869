import { ApiClassification, ApiProject } from "@incendium/api";
import { audienceService } from "Apis";
import { useSharedState } from "./useSharedState";

const fetchClassifications = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const classifications =
      await audienceService.audienceServiceListClassifications({
        projectId: selectedProject.id as number,
      });
    return classifications.results || [];
  }
  return [];
};
export const useClassifications = () => {
  const { state, setState, loading, refetch } = useSharedState(
    "classifications",
    [],
    fetchClassifications
  );

  return {
    classifications: state as ApiClassification[],
    setClassifications: setState,
    refetchClassifications: refetch,
    loading,
  };
};
