export const camelToText = (s: string): string => {
  const t = s.replace(/([A-Z])/g, " $1");
  return t.charAt(0).toUpperCase() + t.slice(1);
};

export const camelAndSnakeToText = (s: string): string => {
  const t = s.replace(/([A-Z])/g, " $1").replace(/_/g, "");
  return t.charAt(0).toUpperCase() + t.slice(1);
};

export const toCamelCase = (text: string): string => {
  text = text.toLowerCase();
  text = text.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""));
  return text.substr(0, 1).toLowerCase() + text.substr(1);
};

export const snakeToTitle = (text: string): string => {
  return text
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase()) // First char after each -/_
    .replace(/(\d+)/g, function (_, s) {
      return " " + s + " ";
    });
};

export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const toTitleCase = (text: string): string =>
  text.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
