import { alpha, Box, styled } from "@mui/material";

const StyledFormWrapper = styled(Box)(({ theme }) => ({
  borderRadius: 10,
  width: 800,
  border: `2px solid ${alpha(theme.palette.primary.main, 0.5)}`,
  boxShadow: `0px 0px 40px rgba(255,255,255,0.11)`,
  padding: 60,
  paddingBottom: 40,
  overflow: "hidden",
  "& .MuiFormLabel-root": {
    color: theme.palette.common.white,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 500,
    marginBottom: 4,
    display: "block",
  },
  "& .MuiInputBase-root": {
    background: theme.palette.common.white,
    borderRadius: 4,
    height: 50,
    padding: 10,
    paddingLeft: 20,
    "&:before": {
      display: "none",
    },
  },
  "& .Mui-focused": {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  "& input:-webkit-autofill, input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active":
    {
      transition:
        "background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s",
    },
}));

export const WashedForm = ({ children }: { children: React.ReactNode }) => {
  return <StyledFormWrapper>{children}</StyledFormWrapper>;
};
