/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiAudienceConditionGroupPayloadFromJSON, ApiAudienceConditionGroupPayloadToJSON, } from './ApiAudienceConditionGroupPayload';
export function ApiAudiencePayloadFromJSON(json) {
    return ApiAudiencePayloadFromJSONTyped(json, false);
}
export function ApiAudiencePayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'conditionGroups': !exists(json, 'conditionGroups') ? undefined : (json['conditionGroups'].map(ApiAudienceConditionGroupPayloadFromJSON)),
    };
}
export function ApiAudiencePayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
        'conditionGroups': value.conditionGroups === undefined ? undefined : (value.conditionGroups.map(ApiAudienceConditionGroupPayloadToJSON)),
    };
}
