/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiSalesFlowMetric;
(function (ApiSalesFlowMetric) {
    ApiSalesFlowMetric["CURRENT_LEADS"] = "FlowMetric_CurrentLeads";
    ApiSalesFlowMetric["NEW_SESSIONS_PERCENTAGE"] = "FlowMetric_NewSessionsPercentage";
    ApiSalesFlowMetric["RETURNING_SESSION_PERCENTAGE"] = "FlowMetric_ReturningSessionPercentage";
    ApiSalesFlowMetric["RETURNING_CUSTOMER_PERCENTAGE"] = "FlowMetric_ReturningCustomerPercentage";
    ApiSalesFlowMetric["TIME_TO_STATUS"] = "FlowMetric_TimeToStatus";
    ApiSalesFlowMetric["SESSION_COUNT"] = "FlowMetric_SessionCount";
    ApiSalesFlowMetric["SESSION_TO_STATUS_RATE"] = "FlowMetric_SessionToStatusRate";
    ApiSalesFlowMetric["SESSION_EFFECTIVE_RATE"] = "FlowMetric_SessionEffectiveRate";
    ApiSalesFlowMetric["TO_STATUS_RATE"] = "FlowMetric_ToStatusRate";
    ApiSalesFlowMetric["NUMBER_OF_INTERACTIONS"] = "FlowMetric_NumberOfInteractions";
    ApiSalesFlowMetric["LEADS"] = "FlowMetric_Leads";
    ApiSalesFlowMetric["SESSIONS"] = "FlowMetric_Sessions";
    ApiSalesFlowMetric["REJECTION_RATE"] = "FlowMetric_RejectionRate";
    ApiSalesFlowMetric["REJECTION_REASON"] = "FlowMetric_RejectionReason";
    ApiSalesFlowMetric["RESPONSE_TIME"] = "FlowMetric_ResponseTime";
    ApiSalesFlowMetric["REVENUE"] = "FlowMetric_Revenue";
})(ApiSalesFlowMetric || (ApiSalesFlowMetric = {}));
export function ApiSalesFlowMetricFromJSON(json) {
    return ApiSalesFlowMetricFromJSONTyped(json, false);
}
export function ApiSalesFlowMetricFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiSalesFlowMetricToJSON(value) {
    return value;
}
