import LeftMenuListItem from "features/leftMenu/components/LeftMenuListItem";
import { Children, isValidElement } from "react";

export const findChildToProps = (children) => {
  const toProps: string[] = [];
  Children.forEach(children, (child) => {
    if (isValidElement(child)) {
      if (child.type === LeftMenuListItem) {
        toProps.push((child.props as any).to);
      } else if (child.props && (child.props as any).children) {
        toProps.push(...findChildToProps((child.props as any).children));
      }
    }
  });

  return toProps;
};
