import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
  useTheme,
} from "@mui/material";
import { formatPercentage } from "Helpers/percentage";

interface ICircularProgressBarProps extends CircularProgressProps {
  children?: React.ReactNode;
  customColour?: string;
}

function CircularProgressBar({
  customColour,
  children,
  ...rest
}: ICircularProgressBarProps) {
  const theme = useTheme();
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <Box
        sx={{
          top: 5,
          left: 5,
          bottom: 5,
          right: 5,
          borderRadius: "50%",
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: `1px solid ${theme.palette.secondary.main}`,
        }}
      >
        <Typography variant="h1" component="div">
          {formatPercentage(rest.value || 0)}
        </Typography>
      </Box>
      <CircularProgress
        size={120}
        variant="determinate"
        color="primary"
        thickness={4}
        sx={{
          color: customColour,
        }}
        {...rest}
      />
    </Box>
  );
}

export default CircularProgressBar;
