import { useState } from "react";
import {
  Box,
  Button,
  FormLabel,
  Input,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { authService } from "Apis/api";
import { useSnackbar } from "notistack";
import { WashedForm } from "Components/UI/WashedForm";

const PasswordReset = ({ code }: { code: string }) => {
  const [newPassword, setNewPassword] = useState("");
  const [newRetypePassword, setNewRetypePassword] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const handleSubmit = async () => {
    setInProgress(true);
    try {
      await authService.authValidateResetPasswordCode({ body: { code } });
      await authService.authChangePasswordWithResetCode({
        body: {
          code,
          password: newPassword,
          confirmation: newRetypePassword,
        },
      });
      history.push("/login");
    } catch (e) {
      enqueueSnackbar("Internal Server Error", {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    } finally {
      setInProgress(false);
    }
  };

  return (
    <>
      <WashedForm>
        <Stack spacing={2}>
          <div>
            <FormLabel>New Password</FormLabel>
            <Input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              fullWidth
              disabled={inProgress}
            />
          </div>
          <div>
            <FormLabel>Confirm Password</FormLabel>
            <Input
              type="password"
              value={newRetypePassword}
              onChange={(e) => setNewRetypePassword(e.target.value)}
              fullWidth
              disabled={inProgress}
            />
          </div>
        </Stack>
        <Box mt={4} mb={2}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            disabled={inProgress}
            color="primary"
            fullWidth
            size="large"
          >
            Submit
          </Button>
        </Box>
        <Box mt={1} display={"flex"} justifyContent={"flex-start"}>
          <Typography color={"white"} fontWeight={500}>
            <Link to="/login">{"<"} Go Back</Link>
          </Typography>
        </Box>
      </WashedForm>
    </>
  );
};
export default PasswordReset;
