import {
  ApiClient,
  ApiMedia,
  ApiMediaPayload,
  ApiMediaType,
  ApiProject,
} from "@incendium/api";
import { mediaService } from "Apis";
import produce from "immer";
import { CallbackOrVal } from "Interfaces";
import { S3Response } from "react-s3-uploader";
import S3Upload from "react-s3-uploader/s3upload";

export const customAssetManager = (
  selectedClient: ApiClient,
  selectedProject: ApiProject,
  assets: string[],
  setMedias: (val: CallbackOrVal<ApiMedia[]>) => void
) => {
  const onGetSignedUrl = async (file: File, path: string) => {
    const { url } = await mediaService.mediaServiceGetPresignedURL({
      projectId: selectedProject?.id as number,
      payload: {
        name: file.name,
        path,
      },
    });
    return url;
  };

  const customFetch = async (
    url: string,
    options: Record<string, any>
  ): Promise<string> => {
    if (!(options.body as FormData).has("files")) {
      return Promise.reject(" cancelled");
    }

    const uploadFile = (options.body as FormData).get("files") as File;

    const presignedURL = await onGetSignedUrl(uploadFile, url);
    const result = await new Promise<S3Response>(async (resolve, reject) => {
      const client = new S3Upload({
        onFinishS3Put(result) {
          return resolve(result);
        },
      });
      client.uploadToS3(uploadFile, {
        filename: uploadFile.name,
        fileKey: "",
        signedUrl: presignedURL || "",
        publicUrl: presignedURL?.split("?").shift() || "",
      });
    });

    const payload: ApiMediaPayload = {
      name: result.filename,
      type: ApiMediaType.IMAGE_MEDIA,
      files: [
        {
          fileName: result.filename,
          fullPath: result.publicUrl,
          fileType: uploadFile.type,
        },
      ],
    };

    const res = await mediaService.mediaServiceCreateMedia({
      projectId: selectedProject?.id as number,
      payload,
    });

    setMedias((prevMedias) =>
      produce(prevMedias as ApiMedia[], (draft) => {
        draft.push(res);
      })
    );

    return Promise.resolve((res.files || [])[0].fullPath || "");
  };

  return {
    upload: `clients/${selectedClient?.id}/projects/${selectedProject?.id}`,
    multiUpload: false,
    customFetch: customFetch as any, // casting as any as expects promise<void> but looking into code should be promise<string>
    assets,
  };
};
