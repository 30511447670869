import { ApiPathStatus, ApiReadLanderResponse } from "@incendium/api";
import { Button, Grid, Portal, Typography } from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import Loading from "Components/Loading/Loading";
import { useNotification, useSelectedProject } from "Hooks";
import { useLanders } from "Hooks/useLanders";
import {
  LandingPathSetup,
  LightningLanderCard,
} from "features/lightningLanders";
import produce from "immer";
import { Suspense, useCallback, useState } from "react";
import { useDeleteConfirmation } from "Hooks/useDeleteConfirmation";
import { landersService } from "Apis";
import { useDebounce } from "react-use";

function LightningLandersPage() {
  const { selectedProject } = useSelectedProject();
  const { landers, setLanders, loading, refetch } = useLanders();
  const [open, setOpen] = useState(false);
  const [landingPath, setLandingPath] = useState<ApiReadLanderResponse>({});
  const handleDelete = useDeleteConfirmation();
  const { showErrorNotification, showSuccessNotification } = useNotification();

  useDebounce(
    () => {
      if (!open && landers.filter((s) => s.pending).length > 0) {
        refetch();
      }
    },
    5000,
    [landers, refetch, open]
  );

  const newLandingPath = useCallback(() => {
    setOpen(true);
    setLandingPath({});
  }, []);
  const editLandingPath = useCallback((lander) => {
    setOpen(true);
    setLandingPath(lander);
  }, []);

  const deleteLandingPath = useCallback(
    (lander) => {
      handleDelete({
        title: "Are you sure you want to delete this Lander",
        body: `Deleting the Lander with path ${lander.path} will mean this page will not be reachable anymore, Are you happy to continue`,
        callback: async () => {
          await landersService.landersServiceDeleteLander({
            projectId: selectedProject?.id as number,
            landerId: lander.id as number,
          });

          setLanders(
            produce(landers, (draft) => {
              const idx = draft.findIndex((d) => d.id === lander.id);
              if (idx >= 0) {
                draft[idx].status = ApiPathStatus.PATH_STATUS_DELETING;
              }
            })
          );

          return `Lander ${lander.path} set to delete, this may take awhile, please check back later`;
        },
      });
    },
    [handleDelete, selectedProject, landers, setLanders]
  );

  const onActivateSwitch = useCallback(
    (activate: boolean) => async (lander) => {
      try {
        const fn = activate
          ? landersService.landersServiceActivateLander({
              projectId: selectedProject?.id as number,
              landerId: lander.id as number,
            })
          : landersService.landersServiceDeactivateLander({
              projectId: selectedProject?.id as number,
              landerId: lander.id as number,
            });
        await fn;

        showSuccessNotification(
          `${lander.path} ${activate ? "Activated" : "Deactivated"}`
        );
        // due to a few chnges and ednpojt not returning full lander, we just refetch them all
        refetch();
      } catch (error) {
        showErrorNotification("Internal error, please try again");
      }
    },
    [selectedProject, showErrorNotification, showSuccessNotification, refetch]
  );

  const onSaved = useCallback(
    (res: ApiReadLanderResponse) => {
      setLanders(
        produce(landers, (draft) => {
          const idx = draft.findIndex((d) => d.id === res.id);
          if (idx >= 0) {
            draft[idx] = res;
          } else {
            draft.push(res);
          }
        })
      );
    },
    [landers, setLanders]
  );

  return (
    <>
      <Portal container={() => document.getElementById("pageAction")}>
        <Button onClick={newLandingPath}>New Lightning Lander</Button>
      </Portal>
      <Suspense fallback={<Loading />}>
        {!loading && landers.length === 0 ? (
          <CenterPage calcHeight>
            <Typography variant="subtitle1">
              You don't have any Lightning Landers yet.
            </Typography>
            <Typography variant="subtitle1" mb={2} color={"secondary"}>
              Click the button below to get started!
            </Typography>
            <Button onClick={newLandingPath}>New Lightning Lander</Button>
          </CenterPage>
        ) : (
          <Grid container spacing={2}>
            {landers.map((lp) => (
              <Grid key={lp.id} item xs={4}>
                <LightningLanderCard
                  lander={lp}
                  project={selectedProject!}
                  onEdit={editLandingPath}
                  onDelete={deleteLandingPath}
                  onActivate={onActivateSwitch(true)}
                  onDeactivate={onActivateSwitch(false)}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Suspense>
      <LandingPathSetup
        open={open}
        setOpen={setOpen}
        landingPath={landingPath}
        setLandingPath={setLandingPath}
        onSaved={onSaved}
      />
    </>
  );
}

export default LightningLandersPage;
