/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiContactFromJSON, ApiContactToJSON, } from './ApiContact';
import { ApiFieldMappingFromJSON, ApiFieldMappingToJSON, } from './ApiFieldMapping';
import { ApiIntegrationServiceFromJSON, ApiIntegrationServiceToJSON, } from './ApiIntegrationService';
import { ApiMediumFromJSON, ApiMediumToJSON, } from './ApiMedium';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiAutomatedMarketingMessageFromJSON(json) {
    return ApiAutomatedMarketingMessageFromJSONTyped(json, false);
}
export function ApiAutomatedMarketingMessageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'templateId': !exists(json, 'templateId') ? undefined : json['templateId'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'templateData': !exists(json, 'templateData') ? undefined : (json['templateData'].map(ApiFieldMappingFromJSON)),
        'integrationService': !exists(json, 'integrationService') ? undefined : ApiIntegrationServiceFromJSON(json['integrationService']),
        'medium': !exists(json, 'medium') ? undefined : ApiMediumFromJSON(json['medium']),
        'to': !exists(json, 'to') ? undefined : ApiContactFromJSON(json['to']),
        'fromEmail': !exists(json, 'fromEmail') ? undefined : json['fromEmail'],
        'fromName': !exists(json, 'fromName') ? undefined : json['fromName'],
        'replyToEmail': !exists(json, 'replyToEmail') ? undefined : json['replyToEmail'],
        'replyToName': !exists(json, 'replyToName') ? undefined : json['replyToName'],
        'dynamicTemplateData': !exists(json, 'dynamicTemplateData') ? undefined : json['dynamicTemplateData'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'smsTemplate': !exists(json, 'smsTemplate') ? undefined : json['smsTemplate'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'integrationServiceId': !exists(json, 'integrationServiceId') ? undefined : json['integrationServiceId'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
    };
}
export function ApiAutomatedMarketingMessageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'templateId': value.templateId,
        'subject': value.subject,
        'templateData': value.templateData === undefined ? undefined : (value.templateData.map(ApiFieldMappingToJSON)),
        'integrationService': ApiIntegrationServiceToJSON(value.integrationService),
        'medium': ApiMediumToJSON(value.medium),
        'to': ApiContactToJSON(value.to),
        'fromEmail': value.fromEmail,
        'fromName': value.fromName,
        'replyToEmail': value.replyToEmail,
        'replyToName': value.replyToName,
        'dynamicTemplateData': value.dynamicTemplateData,
        'project': ApiProjectToJSON(value.project),
        'smsTemplate': value.smsTemplate,
        'name': value.name,
        'integrationServiceId': value.integrationServiceId,
        'projectId': value.projectId,
    };
}
