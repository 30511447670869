/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiConditionValueType;
(function (ApiConditionValueType) {
    ApiConditionValueType["UNSPECIFIED"] = "VALUE_TYPE_UNSPECIFIED";
    ApiConditionValueType["NUMBER_DIFFRENCE"] = "VALUE_TYPE_NUMBER_DIFFRENCE";
    ApiConditionValueType["PERCENTAGE_DIFFRENCE"] = "VALUE_TYPE_PERCENTAGE_DIFFRENCE";
})(ApiConditionValueType || (ApiConditionValueType = {}));
export function ApiConditionValueTypeFromJSON(json) {
    return ApiConditionValueTypeFromJSONTyped(json, false);
}
export function ApiConditionValueTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiConditionValueTypeToJSON(value) {
    return value;
}
