/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiAuthResponseFromJSON, ApiChangePasswordWithCodeRequestToJSON, ApiForgotPasswordRequestToJSON, ApiShareParametersFromJSON, ApiShareParametersToJSON, ApiShareResponseFromJSON, ApiShareResponseToJSON, ApiUserToJSON, ApiValidateResetPasswordCodeRequestToJSON, } from '../models';
/**
 *
 */
export class AuthApi extends runtime.BaseAPI {
    /**
     */
    authAuthenticateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.body === null || requestParameters.body === undefined) {
                throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling authAuthenticate.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/authenticate`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiUserToJSON(requestParameters.body),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiAuthResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    authAuthenticate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authAuthenticateRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    authChangePasswordWithResetCodeRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.body === null || requestParameters.body === undefined) {
                throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling authChangePasswordWithResetCode.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/change-password`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiChangePasswordWithCodeRequestToJSON(requestParameters.body),
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    authChangePasswordWithResetCode(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authChangePasswordWithResetCodeRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    authForgotPasswordRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.body === null || requestParameters.body === undefined) {
                throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling authForgotPassword.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/forgot-password`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiForgotPasswordRequestToJSON(requestParameters.body),
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    authForgotPassword(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authForgotPasswordRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    authShareTokenRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.body === null || requestParameters.body === undefined) {
                throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling authShareToken.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/share`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiShareParametersToJSON(requestParameters.body),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiShareResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    authShareToken(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authShareTokenRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    authValidateResetPasswordCodeRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.body === null || requestParameters.body === undefined) {
                throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling authValidateResetPasswordCode.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/validate-reset-code`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiValidateResetPasswordCodeRequestToJSON(requestParameters.body),
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    authValidateResetPasswordCode(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authValidateResetPasswordCodeRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    authValidateTokenRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.body === null || requestParameters.body === undefined) {
                throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling authValidateToken.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/validate`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiShareResponseToJSON(requestParameters.body),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiShareParametersFromJSON(jsonValue));
        });
    }
    /**
     */
    authValidateToken(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authValidateTokenRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
