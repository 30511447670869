/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiSyncTypeFromJSON, ApiSyncTypeToJSON, } from './ApiSyncType';
export function ApiLinkAccountPayloadFromJSON(json) {
    return ApiLinkAccountPayloadFromJSONTyped(json, false);
}
export function ApiLinkAccountPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'syncId': !exists(json, 'syncId') ? undefined : json['syncId'],
        'type': !exists(json, 'type') ? undefined : ApiSyncTypeFromJSON(json['type']),
    };
}
export function ApiLinkAccountPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'externalId': value.externalId,
        'syncId': value.syncId,
        'type': ApiSyncTypeToJSON(value.type),
    };
}
