/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiListPageviewMetaTaggersByPageResponseFromJSON, ApiListPageviewMetaTaggersResponseFromJSON, ApiPageviewMetaTaggerFromJSON, ApiPageviewMetaTaggerPayloadToJSON, ApiTrackMetaPayloadToJSON, } from '../models';
/**
 *
 */
export class PageviewMetaServiceApi extends runtime.BaseAPI {
    /**
     * Pageview Meta Tagger.
     */
    pageviewMetaServiceCreatePageviewMetaTaggerRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling pageviewMetaServiceCreatePageviewMetaTagger.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling pageviewMetaServiceCreatePageviewMetaTagger.');
            }
            const queryParameters = {};
            if (requestParameters.locationId !== undefined) {
                queryParameters['locationId'] = requestParameters.locationId;
            }
            if (requestParameters.pageviewMetaId !== undefined) {
                queryParameters['pageviewMetaId'] = requestParameters.pageviewMetaId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/page/v1/projects/{projectId}/pageview_meta`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiPageviewMetaTaggerPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiPageviewMetaTaggerFromJSON(jsonValue));
        });
    }
    /**
     * Pageview Meta Tagger.
     */
    pageviewMetaServiceCreatePageviewMetaTagger(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.pageviewMetaServiceCreatePageviewMetaTaggerRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    pageviewMetaServiceDeletePageviewMetaTaggerRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling pageviewMetaServiceDeletePageviewMetaTagger.');
            }
            if (requestParameters.pageviewMetaId === null || requestParameters.pageviewMetaId === undefined) {
                throw new runtime.RequiredError('pageviewMetaId', 'Required parameter requestParameters.pageviewMetaId was null or undefined when calling pageviewMetaServiceDeletePageviewMetaTagger.');
            }
            const queryParameters = {};
            if (requestParameters.locationId !== undefined) {
                queryParameters['locationId'] = requestParameters.locationId;
            }
            if (requestParameters.payloadReserved !== undefined) {
                queryParameters['payload.reserved'] = requestParameters.payloadReserved;
            }
            if (requestParameters.payloadSelector !== undefined) {
                queryParameters['payload.selector'] = requestParameters.payloadSelector;
            }
            if (requestParameters.payloadValue !== undefined) {
                queryParameters['payload.value'] = requestParameters.payloadValue;
            }
            if (requestParameters.payloadLocationId !== undefined) {
                queryParameters['payload.locationId'] = requestParameters.payloadLocationId;
            }
            if (requestParameters.payloadSelectorType !== undefined) {
                queryParameters['payload.selectorType'] = requestParameters.payloadSelectorType;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/page/v1/projects/{projectId}/pageview_meta/{pageviewMetaId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"pageviewMetaId"}}`, encodeURIComponent(String(requestParameters.pageviewMetaId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    pageviewMetaServiceDeletePageviewMetaTagger(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.pageviewMetaServiceDeletePageviewMetaTaggerRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    pageviewMetaServiceListPageviewMetaTaggersRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling pageviewMetaServiceListPageviewMetaTaggers.');
            }
            const queryParameters = {};
            if (requestParameters.locationId !== undefined) {
                queryParameters['locationId'] = requestParameters.locationId;
            }
            if (requestParameters.pageviewMetaId !== undefined) {
                queryParameters['pageviewMetaId'] = requestParameters.pageviewMetaId;
            }
            if (requestParameters.payloadReserved !== undefined) {
                queryParameters['payload.reserved'] = requestParameters.payloadReserved;
            }
            if (requestParameters.payloadSelector !== undefined) {
                queryParameters['payload.selector'] = requestParameters.payloadSelector;
            }
            if (requestParameters.payloadValue !== undefined) {
                queryParameters['payload.value'] = requestParameters.payloadValue;
            }
            if (requestParameters.payloadLocationId !== undefined) {
                queryParameters['payload.locationId'] = requestParameters.payloadLocationId;
            }
            if (requestParameters.payloadSelectorType !== undefined) {
                queryParameters['payload.selectorType'] = requestParameters.payloadSelectorType;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/page/v1/projects/{projectId}/pageview_meta`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListPageviewMetaTaggersResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    pageviewMetaServiceListPageviewMetaTaggers(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.pageviewMetaServiceListPageviewMetaTaggersRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * fetch taggers based on projectand page (external use)
     */
    pageviewMetaServiceListPageviewMetaTaggersByPageRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectKey === null || requestParameters.projectKey === undefined) {
                throw new runtime.RequiredError('projectKey', 'Required parameter requestParameters.projectKey was null or undefined when calling pageviewMetaServiceListPageviewMetaTaggersByPage.');
            }
            const queryParameters = {};
            if (requestParameters.queryPage !== undefined) {
                queryParameters['query.page'] = requestParameters.queryPage;
            }
            if (requestParameters.queryName !== undefined) {
                queryParameters['query.name'] = requestParameters.queryName;
            }
            if (requestParameters.queryParent !== undefined) {
                queryParameters['query.parent'] = requestParameters.queryParent;
            }
            if (requestParameters.queryPagetypes) {
                queryParameters['query.pagetypes'] = requestParameters.queryPagetypes;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/page/v1/projects/{projectKey}/pageview_meta:configs`.replace(`{${"projectKey"}}`, encodeURIComponent(String(requestParameters.projectKey))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListPageviewMetaTaggersByPageResponseFromJSON(jsonValue));
        });
    }
    /**
     * fetch taggers based on projectand page (external use)
     */
    pageviewMetaServiceListPageviewMetaTaggersByPage(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.pageviewMetaServiceListPageviewMetaTaggersByPageRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    pageviewMetaServiceReadPageviewMetaTaggerRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling pageviewMetaServiceReadPageviewMetaTagger.');
            }
            if (requestParameters.pageviewMetaId === null || requestParameters.pageviewMetaId === undefined) {
                throw new runtime.RequiredError('pageviewMetaId', 'Required parameter requestParameters.pageviewMetaId was null or undefined when calling pageviewMetaServiceReadPageviewMetaTagger.');
            }
            const queryParameters = {};
            if (requestParameters.locationId !== undefined) {
                queryParameters['locationId'] = requestParameters.locationId;
            }
            if (requestParameters.payloadReserved !== undefined) {
                queryParameters['payload.reserved'] = requestParameters.payloadReserved;
            }
            if (requestParameters.payloadSelector !== undefined) {
                queryParameters['payload.selector'] = requestParameters.payloadSelector;
            }
            if (requestParameters.payloadValue !== undefined) {
                queryParameters['payload.value'] = requestParameters.payloadValue;
            }
            if (requestParameters.payloadLocationId !== undefined) {
                queryParameters['payload.locationId'] = requestParameters.payloadLocationId;
            }
            if (requestParameters.payloadSelectorType !== undefined) {
                queryParameters['payload.selectorType'] = requestParameters.payloadSelectorType;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/page/v1/projects/{projectId}/pageview_meta/{pageviewMetaId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"pageviewMetaId"}}`, encodeURIComponent(String(requestParameters.pageviewMetaId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiPageviewMetaTaggerFromJSON(jsonValue));
        });
    }
    /**
     */
    pageviewMetaServiceReadPageviewMetaTagger(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.pageviewMetaServiceReadPageviewMetaTaggerRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * track meta
     */
    pageviewMetaServiceTrackMetaRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectKey === null || requestParameters.projectKey === undefined) {
                throw new runtime.RequiredError('projectKey', 'Required parameter requestParameters.projectKey was null or undefined when calling pageviewMetaServiceTrackMeta.');
            }
            if (requestParameters.pageviewKey === null || requestParameters.pageviewKey === undefined) {
                throw new runtime.RequiredError('pageviewKey', 'Required parameter requestParameters.pageviewKey was null or undefined when calling pageviewMetaServiceTrackMeta.');
            }
            if (requestParameters.taggerKey === null || requestParameters.taggerKey === undefined) {
                throw new runtime.RequiredError('taggerKey', 'Required parameter requestParameters.taggerKey was null or undefined when calling pageviewMetaServiceTrackMeta.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling pageviewMetaServiceTrackMeta.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/page/v1/projects/{projectKey}/pageview/{pageviewKey}/pageview_meta/{taggerKey}`.replace(`{${"projectKey"}}`, encodeURIComponent(String(requestParameters.projectKey))).replace(`{${"pageviewKey"}}`, encodeURIComponent(String(requestParameters.pageviewKey))).replace(`{${"taggerKey"}}`, encodeURIComponent(String(requestParameters.taggerKey))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiTrackMetaPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * track meta
     */
    pageviewMetaServiceTrackMeta(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.pageviewMetaServiceTrackMetaRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    pageviewMetaServiceUpdatePageviewMetaTaggerRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling pageviewMetaServiceUpdatePageviewMetaTagger.');
            }
            if (requestParameters.pageviewMetaId === null || requestParameters.pageviewMetaId === undefined) {
                throw new runtime.RequiredError('pageviewMetaId', 'Required parameter requestParameters.pageviewMetaId was null or undefined when calling pageviewMetaServiceUpdatePageviewMetaTagger.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling pageviewMetaServiceUpdatePageviewMetaTagger.');
            }
            const queryParameters = {};
            if (requestParameters.locationId !== undefined) {
                queryParameters['locationId'] = requestParameters.locationId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/page/v1/projects/{projectId}/pageview_meta/{pageviewMetaId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"pageviewMetaId"}}`, encodeURIComponent(String(requestParameters.pageviewMetaId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiPageviewMetaTaggerPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiPageviewMetaTaggerFromJSON(jsonValue));
        });
    }
    /**
     */
    pageviewMetaServiceUpdatePageviewMetaTagger(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.pageviewMetaServiceUpdatePageviewMetaTaggerRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
/**
    * @export
    * @enum {string}
    */
export var PageviewMetaServiceDeletePageviewMetaTaggerPayloadReservedEnum;
(function (PageviewMetaServiceDeletePageviewMetaTaggerPayloadReservedEnum) {
    PageviewMetaServiceDeletePageviewMetaTaggerPayloadReservedEnum["RESERVERED_UNSPECIFIED"] = "PAGEVIEW_META_RESERVERED_UNSPECIFIED";
    PageviewMetaServiceDeletePageviewMetaTaggerPayloadReservedEnum["RESERVERED_OUT_OF_STOCK"] = "PAGEVIEW_META_RESERVERED_OUT_OF_STOCK";
    PageviewMetaServiceDeletePageviewMetaTaggerPayloadReservedEnum["RESERVED_ORDER_ID"] = "PAGEVIEW_META_RESERVED_ORDER_ID";
})(PageviewMetaServiceDeletePageviewMetaTaggerPayloadReservedEnum || (PageviewMetaServiceDeletePageviewMetaTaggerPayloadReservedEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PageviewMetaServiceDeletePageviewMetaTaggerPayloadSelectorTypeEnum;
(function (PageviewMetaServiceDeletePageviewMetaTaggerPayloadSelectorTypeEnum) {
    PageviewMetaServiceDeletePageviewMetaTaggerPayloadSelectorTypeEnum["CSS"] = "PAGEVIEW_META_SELECTOR_CSS";
    PageviewMetaServiceDeletePageviewMetaTaggerPayloadSelectorTypeEnum["PATH"] = "PAGEVIEW_META_SELECTOR_PATH";
    PageviewMetaServiceDeletePageviewMetaTaggerPayloadSelectorTypeEnum["QS"] = "PAGEVIEW_META_SELECTOR_QS";
    PageviewMetaServiceDeletePageviewMetaTaggerPayloadSelectorTypeEnum["JS"] = "PAGEVIEW_META_SELECTOR_JS";
})(PageviewMetaServiceDeletePageviewMetaTaggerPayloadSelectorTypeEnum || (PageviewMetaServiceDeletePageviewMetaTaggerPayloadSelectorTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PageviewMetaServiceListPageviewMetaTaggersPayloadReservedEnum;
(function (PageviewMetaServiceListPageviewMetaTaggersPayloadReservedEnum) {
    PageviewMetaServiceListPageviewMetaTaggersPayloadReservedEnum["RESERVERED_UNSPECIFIED"] = "PAGEVIEW_META_RESERVERED_UNSPECIFIED";
    PageviewMetaServiceListPageviewMetaTaggersPayloadReservedEnum["RESERVERED_OUT_OF_STOCK"] = "PAGEVIEW_META_RESERVERED_OUT_OF_STOCK";
    PageviewMetaServiceListPageviewMetaTaggersPayloadReservedEnum["RESERVED_ORDER_ID"] = "PAGEVIEW_META_RESERVED_ORDER_ID";
})(PageviewMetaServiceListPageviewMetaTaggersPayloadReservedEnum || (PageviewMetaServiceListPageviewMetaTaggersPayloadReservedEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PageviewMetaServiceListPageviewMetaTaggersPayloadSelectorTypeEnum;
(function (PageviewMetaServiceListPageviewMetaTaggersPayloadSelectorTypeEnum) {
    PageviewMetaServiceListPageviewMetaTaggersPayloadSelectorTypeEnum["CSS"] = "PAGEVIEW_META_SELECTOR_CSS";
    PageviewMetaServiceListPageviewMetaTaggersPayloadSelectorTypeEnum["PATH"] = "PAGEVIEW_META_SELECTOR_PATH";
    PageviewMetaServiceListPageviewMetaTaggersPayloadSelectorTypeEnum["QS"] = "PAGEVIEW_META_SELECTOR_QS";
    PageviewMetaServiceListPageviewMetaTaggersPayloadSelectorTypeEnum["JS"] = "PAGEVIEW_META_SELECTOR_JS";
})(PageviewMetaServiceListPageviewMetaTaggersPayloadSelectorTypeEnum || (PageviewMetaServiceListPageviewMetaTaggersPayloadSelectorTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PageviewMetaServiceReadPageviewMetaTaggerPayloadReservedEnum;
(function (PageviewMetaServiceReadPageviewMetaTaggerPayloadReservedEnum) {
    PageviewMetaServiceReadPageviewMetaTaggerPayloadReservedEnum["RESERVERED_UNSPECIFIED"] = "PAGEVIEW_META_RESERVERED_UNSPECIFIED";
    PageviewMetaServiceReadPageviewMetaTaggerPayloadReservedEnum["RESERVERED_OUT_OF_STOCK"] = "PAGEVIEW_META_RESERVERED_OUT_OF_STOCK";
    PageviewMetaServiceReadPageviewMetaTaggerPayloadReservedEnum["RESERVED_ORDER_ID"] = "PAGEVIEW_META_RESERVED_ORDER_ID";
})(PageviewMetaServiceReadPageviewMetaTaggerPayloadReservedEnum || (PageviewMetaServiceReadPageviewMetaTaggerPayloadReservedEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PageviewMetaServiceReadPageviewMetaTaggerPayloadSelectorTypeEnum;
(function (PageviewMetaServiceReadPageviewMetaTaggerPayloadSelectorTypeEnum) {
    PageviewMetaServiceReadPageviewMetaTaggerPayloadSelectorTypeEnum["CSS"] = "PAGEVIEW_META_SELECTOR_CSS";
    PageviewMetaServiceReadPageviewMetaTaggerPayloadSelectorTypeEnum["PATH"] = "PAGEVIEW_META_SELECTOR_PATH";
    PageviewMetaServiceReadPageviewMetaTaggerPayloadSelectorTypeEnum["QS"] = "PAGEVIEW_META_SELECTOR_QS";
    PageviewMetaServiceReadPageviewMetaTaggerPayloadSelectorTypeEnum["JS"] = "PAGEVIEW_META_SELECTOR_JS";
})(PageviewMetaServiceReadPageviewMetaTaggerPayloadSelectorTypeEnum || (PageviewMetaServiceReadPageviewMetaTaggerPayloadSelectorTypeEnum = {}));
