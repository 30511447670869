import { ISyncConfigComponentProps } from "features/dataConnectors/types";
import SyncAccountConfigDrawer from "features/dataConnectors/components/SyncAccountConfigDrawer";
import { ApiService, ApiSyncType } from "@incendium/api";

function BingAds({
  oauthClient,
  syncs,
  open,
  setOpen,
}: ISyncConfigComponentProps) {
  return (
    <SyncAccountConfigDrawer
      oauthClient={oauthClient}
      syncs={syncs}
      provider="bing"
      type={ApiSyncType.BING_ADS}
      open={open}
      setOpen={setOpen}
      service={ApiService.BING}
    />
  );
}

export default BingAds;
