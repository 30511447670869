import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiYAxisChartType,
} from "@incendium/api";
import { Autocomplete, Grid, Portal, TextField } from "@mui/material";
import { AnalyticsSpacing, PageSubAction } from "consts";
import { AnalyticsCard, metricToName, table5Rows } from "features/analytics";
import { useState } from "react";

function MetricExplorer() {
  const [selectedMetric, setSelectedMetric] = useState(
    ApiMetric.METRIC_AVERAGE_SALE_VALUE
  );
  const options = [
    ApiMetric.METRIC_AVERAGE_SALE_VALUE,
    ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_RATE,
    ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
    ApiMetric.METRIC_SALES_COMPLETION_RATE,
    ApiMetric.METRIC_SESSION_REVENUE,
  ];
  return (
    <>
      <Portal container={() => document.getElementById(PageSubAction)}>
        <Autocomplete
          sx={{ width: 250 }}
          options={options}
          value={selectedMetric}
          getOptionLabel={(o) => metricToName(o)}
          onChange={(e, v) => {
            setSelectedMetric(v || ApiMetric.METRIC_AVERAGE_SALE_VALUE);
          }}
          renderInput={(params) => (
            <TextField {...params} label={"Select Metric To Explore"} />
          )}
        />
      </Portal>
      <Grid container spacing={AnalyticsSpacing} mb={4}>
        <Grid item xs={8} sx={{ minHeight: 400 }}>
          <AnalyticsCard
            chart={{
              name: "Over Time",
              dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
              template: ApiChartTemplate.TREND,
              type: ApiChartType.GRAPH,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [selectedMetric],
                },
              ],
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <AnalyticsCard
            chart={{
              name: "By Week",
              dimension: [ApiDimension.DIMENSION_SESSION_START_BY_WEEK],
              template: ApiChartTemplate.TREND,
              type: ApiChartType.GRAPH,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [selectedMetric],
                  chart: {
                    [selectedMetric]: ApiYAxisChartType.BAR,
                  },
                },
              ],
            }}
          />
        </Grid>
        <Grid item xs={4} sx={{ minHeight: 400 }}>
          <AnalyticsCard
            chart={{
              name: "New vs Returning",
              dimension: [ApiDimension.DIMENSION_IS_RETURNING_CUSTOMER],
              template: ApiChartTemplate.TREND,
              type: ApiChartType.GRAPH,
              orderBy: `${ApiDimension.DIMENSION_IS_RETURNING_CUSTOMER}`,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [selectedMetric],
                  chart: {
                    [selectedMetric]: ApiYAxisChartType.BAR,
                  },
                },
              ],
              displayOptions: {
                noFilters: true,
              },
            }}
          />
        </Grid>
        <Grid item xs={8} sx={{ minHeight: 400 }}>
          <AnalyticsCard
            chart={{
              name: "By Channel",
              dimension: [ApiDimension.DIMENSION_CHANNEL],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [selectedMetric],
                  chart: {
                    [selectedMetric]: ApiYAxisChartType.BAR,
                  },
                },
              ],
              displayOptions: {
                noFilters: true,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ minHeight: 400 }}>
          <AnalyticsCard
            chart={{
              name: "By Device",
              dimension: [
                ApiDimension.DIMENSION_SESSION_START_BY_DAY,
                ApiDimension.DIMENSION_DEVICE,
              ],
              template: ApiChartTemplate.TREND,
              type: ApiChartType.GRAPH,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [selectedMetric],
                },
              ],
              displayOptions: {
                noFilters: true,
              },
            }}
          />
        </Grid>
        <Grid item xs={6} sx={{ minHeight: table5Rows }}>
          <AnalyticsCard
            chart={{
              name: "By Landing Page",
              dimension: [ApiDimension.DIMENSION_LANDING_PAGE_PATH],
              template: ApiChartTemplate.TABLE,
              type: ApiChartType.TABLE,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSIONS_COUNT, selectedMetric],
                },
              ],
              displayOptions: {
                noFilters: true,
                rowsPerPage: 5,
              },
            }}
          />
        </Grid>
        <Grid item xs={6} sx={{ minHeight: table5Rows }}>
          <AnalyticsCard
            chart={{
              name: "By City",
              dimension: [ApiDimension.DIMENSION_CITY],
              template: ApiChartTemplate.TABLE,
              type: ApiChartType.TABLE,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSIONS_COUNT, selectedMetric],
                },
              ],
              displayOptions: {
                noFilters: true,
                rowsPerPage: 5,
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default MetricExplorer;
