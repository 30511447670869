/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiReportFromJSON, ApiReportToJSON, } from './ApiReport';
import { ApiTabChartFromJSON, ApiTabChartToJSON, } from './ApiTabChart';
import { ApiTabElementFromJSON, ApiTabElementToJSON, } from './ApiTabElement';
export function ApiReportTabFromJSON(json) {
    return ApiReportTabFromJSONTyped(json, false);
}
export function ApiReportTabFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'report': !exists(json, 'report') ? undefined : ApiReportFromJSON(json['report']),
        'reportId': !exists(json, 'reportId') ? undefined : json['reportId'],
        'charts': !exists(json, 'charts') ? undefined : (json['charts'].map(ApiTabChartFromJSON)),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'elements': !exists(json, 'elements') ? undefined : (json['elements'].map(ApiTabElementFromJSON)),
    };
}
export function ApiReportTabToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'report': ApiReportToJSON(value.report),
        'reportId': value.reportId,
        'charts': value.charts === undefined ? undefined : (value.charts.map(ApiTabChartToJSON)),
        'name': value.name,
        'order': value.order,
        'elements': value.elements === undefined ? undefined : (value.elements.map(ApiTabElementToJSON)),
    };
}
