import { useIframeContext } from "Components/SmartIframe";
import { useEffect } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import { useDebounce } from "react-use";

interface ICssSelectorProps {
  selector?: string;
  onChange: (v: string) => void;
}

function CssSelector({ selector, onChange }: ICssSelectorProps) {
  const { activateIframe, deactivateIframe, selectedSelector } =
    useIframeContext();

  useEffect(() => {
    deactivateIframe();
    activateIframe(undefined);
    return () => deactivateIframe();
  }, []);

  useEffect(() => {
    if (selectedSelector === "" || selectedSelector === selector) {
      return;
    }

    onChange(selectedSelector || "");
  }, [selectedSelector]);

  useDebounce(
    () => {
      activateIframe(undefined, selector);
    },
    500,
    [selector]
  );
  return (
    <TextValidator
      value={selector || ""}
      variant="outlined"
      name="selector"
      label="Selector"
      fullWidth
      validators={["required"]}
      errorMessages={["selector is required"]}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChange(e.target.value)
      }
    />
  );
}

export default CssSelector;
