import { Divider, Stack, Typography } from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import AppendLocationPageTypeField from "Components/PageTypes/AppendLocationPageTypeField";
import SavingButton from "Components/UI/SavingButton";
import { useLocations, useSelectedProject } from "Hooks";

interface ILocationTypesProps {
  locationId: number;
  saveLocation: () => Promise<void>;
  saving: boolean;
}

function LocationTypes({
  locationId,
  saveLocation,
  saving,
}: ILocationTypesProps) {
  const { selectedProject } = useSelectedProject();
  const { location, setLocation } = useLocations(locationId);
  return (
    <CenterPage calcHeight>
      <Stack alignItems={"center"}>
        <Stack sx={{ maxWidth: 500 }} mb={7}>
          <Typography variant="h2" mb={2}>
            Add your location tags
          </Typography>
          <Typography variant="body1" mb={2}>
            Add location tags to group & view combined insights in Analyse.
            Create your own or select from the 'Reserved Tags' list for extra
            features.
          </Typography>
          <Typography variant="body1">
            Tagging pages as one of the Reserved types unlocks performance
            reports, e.g. Product Performance, & specific data tagging options,
            e.g. Name & Price.
          </Typography>
        </Stack>

        <AppendLocationPageTypeField
          size="medium"
          project={selectedProject || {}}
          location={location}
          setLocation={setLocation}
        />
        <Stack mt={4} spacing={2} sx={{ width: "100%" }} alignItems="flex-end">
          <Divider />

          <SavingButton
            onSave={saveLocation}
            disableElevation
            variant="contained"
            color="primary"
            saving={saving}
            size="small"
          >
            save and continue
          </SavingButton>
        </Stack>
      </Stack>
    </CenterPage>
  );
}

export default LocationTypes;
