import { ApiCSVDateGrouping, ApiCSVFieldType } from "@incendium/api";
import { Clear } from "@mui/icons-material";
import {
  Box,
  IconButton,
  MenuItem,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import GlassCard from "Components/GlassCard/GlassCard";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { formatEnumVal } from "Helpers/enumToText";
import produce from "immer";
import { useCSVUploaderContext } from "Providers/CSVUploaderProvider";
import { useMemo, useState } from "react";
import { usePreviousDistinct, useUpdateEffect } from "react-use";
import UploaderManual from "./UploaderManual";
import UploaderRowValidation from "./UploaderRowValidation";
import { useEffect } from "react";

interface IUploaderMappingRowProps {
  index: number;
}

function UploaderMappingRow({ index }: IUploaderMappingRowProps) {
  const [useSuggested, setUseSuggested] = useState(false);
  const [useManual, setUseManual] = useState(false);
  const prevUseManual = usePreviousDistinct(useManual);
  const [mappedField, setMappedField] = useState("");
  const {
    mappingResponse,
    mapping,
    setMapping,
    userValue,
    setUserValue,
    dateGrouping,
    setDateGroup,
    orderedFields,
    rule,
  } = useCSVUploaderContext();

  const field = useMemo(() => {
    if (!orderedFields || orderedFields.length === 0) {
      return {};
    }
    return orderedFields[index];
  }, [orderedFields, index]);

  const suggestedMapping = useMemo(() => {
    if (
      !mappingResponse ||
      !mappingResponse.mapping ||
      !mappingResponse.mapping.hasOwnProperty(field.id || "")
    ) {
      return "";
    }
    return (mappingResponse?.mapping[field.id || ""].columns || [])[0] || "";
  }, [mappingResponse, field]);

  const handleUseSuggested = (field: string, checked: boolean) => {
    setUseSuggested(checked);
    setMappedField(checked ? field : "");
  };
  const handleUseManual = (checked: boolean) => {
    if (useSuggested && checked) {
      setUseSuggested(false);
    }
    setUseManual(checked);
    setMappedField("");
  };

  // reset user value on changing to manual
  useUpdateEffect(() => {
    if (prevUseManual !== useManual) {
      setUserValue(
        produce(userValue, (draft) => {
          const name = field.id || "";
          delete draft[name];
        })
      );
    }
  }, [prevUseManual, useManual]);

  // todo: handle types
  useUpdateEffect(() => {
    setMapping(
      produce(mapping, (draft) => {
        const name = field.id || "";
        if (!draft[name]) {
          draft[name] = {};
        }
        // todo, how to handle joins here?
        if (
          mappedField === "" &&
          field.type !== ApiCSVFieldType.CSV_TYPE_JOIN
        ) {
          draft[name].columns = [];
          return;
        }

        // only join can have more than 1 column
        draft[name].columns = [
          ...(field.type === ApiCSVFieldType.CSV_TYPE_JOIN
            ? draft[name].columns || []
            : []),
          mappedField,
        ];
      })
    );
  }, [mappedField, useManual]);

  const showDateGroupingField = useMemo(() => {
    return (
      field.type === ApiCSVFieldType.CSV_TYPE_DATETIME &&
      rule.dateGroupingRequired
    );
  }, [field, rule]);

  useEffect(() => {
    if (field.forceUserValue) {
      setUseManual(true);
    }
  }, [field.forceUserValue]);

  return (
    <Stack
      direction="row"
      alignItems={"center"}
      spacing={3}
      justifyContent="spaced-between"
    >
      <Box mr={3}>
        <UploaderRowValidation field={field} />
      </Box>

      <GlassCard sx={{ width: 600 }}>
        <Stack
          p={2}
          direction="row"
          justifyContent={"space-between"}
          spacing={2}
        >
          <Stack justifyContent={"space-between"}>
            <Box>
              <Typography variant="h6">
                {formatEnumVal(field.id || "")} {field.required && `*`}
              </Typography>
              <Typography variant="body2">{field.description}</Typography>
            </Box>
            {field.userValueAllowed && !field.forceUserValue && (
              <Box display="flex" alignItems="center">
                <Switch
                  checked={useManual}
                  onChange={(e, checked) => handleUseManual(checked)}
                />
                <Box>
                  <Typography
                    variant="body2"
                    color={useSuggested ? "primary" : "secondary"}
                  >
                    Use Manual input
                  </Typography>
                </Box>
              </Box>
            )}
          </Stack>

          <Box sx={{ minWidth: 300, flex: 0 }}>
            {useManual ? (
              <UploaderManual
                field={field}
                userValue={userValue}
                setUserValue={setUserValue}
              />
            ) : (
              <>
                <StyledFlexBox mb={2} sx={{ minHeight: 70 }}>
                  {suggestedMapping && suggestedMapping !== "" ? (
                    <Box display="flex" alignItems="center">
                      <Switch
                        checked={useSuggested}
                        onChange={(e, checked) =>
                          handleUseSuggested(suggestedMapping, checked)
                        }
                      />
                      <Box>
                        <Typography
                          variant="body2"
                          color={useSuggested ? "primary" : "secondary"}
                        >
                          We found this column might match a internal field.
                        </Typography>
                        <Typography
                          color={useSuggested ? "primary" : "secondary"}
                          variant="body2"
                        >
                          Use <strong>{suggestedMapping}</strong>?
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="body2" color={"secondary"}>
                        We could not find a match for this field
                      </Typography>
                    </Box>
                  )}
                </StyledFlexBox>
                <TextField
                  fullWidth
                  size="small"
                  select
                  label="Map Column To"
                  disabled={useSuggested}
                  value={mappedField}
                  InputProps={{
                    endAdornment: (
                      <Box mr={1}>
                        <IconButton
                          onClick={() => {
                            setUseSuggested(false);
                            setMappedField("");
                          }}
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      </Box>
                    ),
                  }}
                  onChange={(e) => setMappedField(e.target.value)}
                >
                  {(mappingResponse.headers || []).map((h) => (
                    <MenuItem key={h} value={h}>
                      {h}
                    </MenuItem>
                  ))}
                </TextField>
                {showDateGroupingField && (
                  <Box mt={2}>
                    <ToggleButtonGroup
                      size="small"
                      value={dateGrouping}
                      exclusive
                      onChange={(e, v) => {
                        setDateGroup(v);
                      }}
                    >
                      <ToggleButton
                        value={ApiCSVDateGrouping.CSV_DATE_GROUPING_DAY}
                      >
                        Row per Day
                      </ToggleButton>
                      <ToggleButton
                        value={ApiCSVDateGrouping.CSV_DATE_GROUPING_MONTH}
                      >
                        Row per Month
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Stack>
      </GlassCard>
    </Stack>
  );
}

export default UploaderMappingRow;
