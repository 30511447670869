import { ApiGetUsersFilter, ApiGetUsersResponse } from "@incendium/api";
import { useSelectedProject } from "Hooks";
import { getAnalytisUsers } from "features/leadAnalytics";
import { useCallback, useState } from "react";
import { useDebounce } from "react-use";

const useLeadAnalyticsData = (pageToken: string) => {
  const { selectedProject } = useSelectedProject();
  const [chartData, setChartData] = useState<ApiGetUsersResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>(undefined);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<ApiGetUsersFilter[]>([]);

  const loadData = useCallback(
    async (
      projectId: number,
      pageToken: string,
      filters?: ApiGetUsersFilter[],
      search?: string
    ) => {
      setLoading(true);
      setError(undefined);

      try {
        const res = await getAnalytisUsers(
          projectId,
          20,
          pageToken,
          filters,
          search
        );

        setChartData(res);
        setTimeout(() => {
          setLoading(false);
          setError(undefined);
        }, 1);
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    },
    []
  );

  useDebounce(
    () => {
      if (!selectedProject?.id) {
        return;
      }
      loadData(selectedProject.id, pageToken, filters, search);
    },
    300,
    [selectedProject?.id, search, loadData, filters, pageToken]
  );

  return { chartData, loading, error, search, setSearch, filters, setFilters };
};

export default useLeadAnalyticsData;
