/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiEngagementRuleFromJSON, ApiEngagementRuleToJSON, } from './ApiEngagementRule';
import { ApiLeadFromJSON, ApiLeadToJSON, } from './ApiLead';
import { ApiLeadScoreAdjustmentRuleFromJSON, ApiLeadScoreAdjustmentRuleToJSON, } from './ApiLeadScoreAdjustmentRule';
import { ApiLeadScoreReasonFromJSON, ApiLeadScoreReasonToJSON, } from './ApiLeadScoreReason';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiLeadScoreFromJSON(json) {
    return ApiLeadScoreFromJSONTyped(json, false);
}
export function ApiLeadScoreFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'lead': !exists(json, 'lead') ? undefined : ApiLeadFromJSON(json['lead']),
        'reason': !exists(json, 'reason') ? undefined : ApiLeadScoreReasonFromJSON(json['reason']),
        'score': !exists(json, 'score') ? undefined : json['score'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'leadId': !exists(json, 'leadId') ? undefined : json['leadId'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'engagementRule': !exists(json, 'engagementRule') ? undefined : ApiEngagementRuleFromJSON(json['engagementRule']),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'adjustmentRule': !exists(json, 'adjustmentRule') ? undefined : ApiLeadScoreAdjustmentRuleFromJSON(json['adjustmentRule']),
        'engagementRuleId': !exists(json, 'engagementRuleId') ? undefined : json['engagementRuleId'],
        'adjustmentRuleId': !exists(json, 'adjustmentRuleId') ? undefined : json['adjustmentRuleId'],
    };
}
export function ApiLeadScoreToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'lead': ApiLeadToJSON(value.lead),
        'reason': ApiLeadScoreReasonToJSON(value.reason),
        'score': value.score,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'leadId': value.leadId,
        'project': ApiProjectToJSON(value.project),
        'projectId': value.projectId,
        'engagementRule': ApiEngagementRuleToJSON(value.engagementRule),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'adjustmentRule': ApiLeadScoreAdjustmentRuleToJSON(value.adjustmentRule),
        'engagementRuleId': value.engagementRuleId,
        'adjustmentRuleId': value.adjustmentRuleId,
    };
}
