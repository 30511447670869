/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiApiSourceFromJSON, ApiApiSourceToJSON, } from './ApiApiSource';
import { ApiOAuthKeyFromJSON, ApiOAuthKeyToJSON, } from './ApiOAuthKey';
import { ApiOAuthTokenFromJSON, ApiOAuthTokenToJSON, } from './ApiOAuthToken';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiClientFromJSON(json) {
    return ApiClientFromJSONTyped(json, false);
}
export function ApiClientFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'addedBy': !exists(json, 'addedBy') ? undefined : json['addedBy'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'businessName': !exists(json, 'businessName') ? undefined : json['businessName'],
        'abn': !exists(json, 'abn') ? undefined : json['abn'],
        'billingAddress': !exists(json, 'billingAddress') ? undefined : json['billingAddress'],
        'invoiceAddressTo': !exists(json, 'invoiceAddressTo') ? undefined : json['invoiceAddressTo'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'hostName': !exists(json, 'hostName') ? undefined : json['hostName'],
        'pushedToDataflare': !exists(json, 'pushedToDataflare') ? undefined : json['pushedToDataflare'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'ip': !exists(json, 'ip') ? undefined : json['ip'],
        'apiSource': !exists(json, 'apiSource') ? undefined : ApiApiSourceFromJSON(json['apiSource']),
        'projects': !exists(json, 'projects') ? undefined : (json['projects'].map(ApiProjectFromJSON)),
        'oauthKey': !exists(json, 'oauthKey') ? undefined : ApiOAuthKeyFromJSON(json['oauthKey']),
        'oauthToken': !exists(json, 'oauthToken') ? undefined : ApiOAuthTokenFromJSON(json['oauthToken']),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
    };
}
export function ApiClientToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'addedBy': value.addedBy,
        'key': value.key,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'businessName': value.businessName,
        'abn': value.abn,
        'billingAddress': value.billingAddress,
        'invoiceAddressTo': value.invoiceAddressTo,
        'website': value.website,
        'hostName': value.hostName,
        'pushedToDataflare': value.pushedToDataflare,
        'timezone': value.timezone,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'ip': value.ip,
        'apiSource': ApiApiSourceToJSON(value.apiSource),
        'projects': value.projects === undefined ? undefined : (value.projects.map(ApiProjectToJSON)),
        'oauthKey': ApiOAuthKeyToJSON(value.oauthKey),
        'oauthToken': ApiOAuthTokenToJSON(value.oauthToken),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
    };
}
