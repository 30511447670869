import { Box, BoxProps, Stack } from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { useState } from "react";

export const InputGroupItem = ({ children, ...rest }: BoxProps) => {
  const [focused, setFocused] = useState(false);

  return (
    <Box
      className={focused ? "focused" : ""}
      onBlur={() => {
        setFocused(false);
      }}
      onFocus={() => {
        setFocused(true);
      }}
      component={motion.div}
      initial={{ flexGrow: 0, opacity: 0 }}
      animate={{ flexGrow: 1, opacity: 1 }}
      {...rest}
    >
      {children}
    </Box>
  );
};

const InputGroup = styled(Stack)(({ theme }) => ({
  "& > div": {
    flexGrow: 1,
    transition: "all 0.3s",
  },
  "& > div:hover,& > div.focused": {
    flexGrow: "2!important", // mate using important but needed so motion does not override
  },
  "& > div:not(:first-child)  .MuiInputBase-root": {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    transform: "translateX(-1px)",
    width: "calc(100% + 1px)",
  },
  "& > div:not(:last-child) .MuiInputBase-root": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  "& > div:only-child .MuiInputBase-root": {
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
}));

InputGroup.defaultProps = {
  direction: "row",
};

export default InputGroup;
