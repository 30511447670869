import { Box, CircularProgress, Typography } from "@mui/material";
import FullWidthHeightGlass from "Components/Page/FullWidthHeightGlass";
import { useProducts } from "Hooks/useProducts";
import { useState } from "react";
import { useUpdateEffect } from "react-use";
import { CenterPage } from "Components/CenterPage/CenterPage";
import ProductRankingInner from "./ProductRankingInner";
import { ExplorerSidebar, useExplorerContext } from "features/explorer";

function ProductsRanking({}) {
  const { products } = useProducts();
  const [showLoadingState, setShowLoadingState] = useState(false);
  const { config, rank, dimension, selectedRanks, having } =
    useExplorerContext();

  useUpdateEffect(() => {
    setShowLoadingState(true);
    setTimeout(() => {
      setShowLoadingState(false);
    }, 500);
  }, [selectedRanks, rank, having]);

  return (
    <FullWidthHeightGlass height={"calc(100vh - 165px)"}>
      <Box
        sx={{
          display: "grid",
          columnGap: 1,
          gridTemplateColumns: `73% 27%`,
          height: "100%",
        }}
      >
        <Box>
          {showLoadingState ? (
            <CenterPage>
              <CircularProgress />
              <Typography variant="h3" mt={2}>
                Fetching Product's Data
              </Typography>
            </CenterPage>
          ) : (
            <ProductRankingInner
              products={products}
              config={config}
              dimension={dimension}
              rank={rank}
              ranks={selectedRanks}
              having={having}
            />
          )}
        </Box>

        <ExplorerSidebar />
      </Box>
    </FullWidthHeightGlass>
  );
}

export default ProductsRanking;
