/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiSourceFunnel;
(function (ApiSourceFunnel) {
    ApiSourceFunnel["NONE"] = "MEDIUM_FUNNEL_NONE";
    ApiSourceFunnel["UTM"] = "MEDIUM_FUNNEL_UTM";
    ApiSourceFunnel["GOOGLE"] = "MEDIUM_FUNNEL_GOOGLE";
    ApiSourceFunnel["SEARCH_ENGINE"] = "MEDIUM_FUNNEL_SEARCH_ENGINE";
    ApiSourceFunnel["SOCIAL_NETWORK"] = "MEDIUM_FUNNEL_SOCIAL_NETWORK";
    ApiSourceFunnel["REFERRAL"] = "MEDIUM_FUNNEL_REFERRAL";
    ApiSourceFunnel["FACEBOOK"] = "MEDIUM_FUNNEL_FACEBOOK";
    ApiSourceFunnel["BING"] = "MEDIUM_FUNNEL_BING";
})(ApiSourceFunnel || (ApiSourceFunnel = {}));
export function ApiSourceFunnelFromJSON(json) {
    return ApiSourceFunnelFromJSONTyped(json, false);
}
export function ApiSourceFunnelFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiSourceFunnelToJSON(value) {
    return value;
}
