import {
  Box,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import GlassCard from "Components/GlassCard/GlassCard";
import produce from "immer";
import { useState } from "react";
import {
  ApiProject,
  ApiProjectSetting,
  ApiProjectSettingKey,
  ProjectsServiceReadProjectSettingByKeyKeyEnum,
} from "@incendium/api";
import {
  useDebounce,
  useEffectOnce,
  usePrevious,
  useUpdateEffect,
} from "react-use";
import { projectService } from "Apis";
import { useSelectedProject } from "Hooks";
import { TypographyHelp } from "Components/UI/TypographyHelp";
import { AnalyticsSpacing } from "consts";

function MaxLeadScoreCard() {
  const { selectedProject: project } = useSelectedProject();
  const [maxScoreSaving, setMaxScoreSaving] = useState(false);
  const [maxLeadScoreRes, setMaxLeadScoreRes] = useState<ApiProjectSetting>({});
  const prevMaxLeadScoreRes = usePrevious(maxLeadScoreRes);
  const [debouncedMaxLeadScore, setDebouncedMaxLeadScore] =
    useState<ApiProjectSetting>({});
  useDebounce(
    () => {
      if (maxLeadScoreRes.value !== prevMaxLeadScoreRes?.value) {
        setDebouncedMaxLeadScore(maxLeadScoreRes);
      }
    },
    1000,
    [maxLeadScoreRes]
  );
  useEffectOnce(() => {
    if (!project) {
      return;
    }
    getMaxScore(project);
  });

  useUpdateEffect(() => {
    saveProjectSetting(debouncedMaxLeadScore);
  }, [debouncedMaxLeadScore]);

  const getMaxScore = async (project: ApiProject) => {
    const res = await projectService.projectsServiceReadProjectSettingByKey({
      projectId: project.id as number,
      key: ProjectsServiceReadProjectSettingByKeyKeyEnum.LEAD_SCORE_MAX,
    });

    if (res) {
      setMaxLeadScoreRes(res);
    }
  };

  const saveProjectSetting = async (maxLeadScoreRes: ApiProjectSetting) => {
    setMaxScoreSaving(true);

    const fn = maxLeadScoreRes?.id
      ? projectService.projectsServiceUpdateProjectSetting({
          clientId: project?.clientId as number,
          projectId: project?.id as number,
          settingId: maxLeadScoreRes.id,
          payload: {
            ...maxLeadScoreRes,
          },
        })
      : projectService.projectsServiceCreateProjectSetting({
          clientId: project?.clientId as number,
          projectId: project?.id as number,
          payload: {
            value: maxLeadScoreRes.value,
            key: ApiProjectSettingKey.LEAD_SCORE_MAX,
          },
        });
    const res = await fn;
    setMaxLeadScoreRes(res);

    // have timeout for setting saving as false to allow for animation to show
    setTimeout(() => {
      setMaxScoreSaving(false);
    }, 600);
  };

  return (
    <GlassCard
      boxProps={{
        p: 2,
      }}
    >
      <Stack spacing={AnalyticsSpacing}>
        <Stack justifyContent={"space-between"} direction={"row"}>
          <Box>
            <TypographyHelp
              variant="subtitle1"
              placement="right"
              text="Set Lead max score"
              iconSize="inherit"
              tooltip={`Setting this value means that any lead score in the future will be capped at this value`}
            />
            <Typography variant="body2">
              This is the max score any lead can be
            </Typography>
          </Box>
          {maxScoreSaving && <CircularProgress size={"20px"} />}
        </Stack>

        <Box mt={1}>
          <TextField
            label="max lead score"
            value={maxLeadScoreRes?.value || ""}
            onChange={(e) =>
              setMaxLeadScoreRes(
                produce(maxLeadScoreRes, (draft) => {
                  draft.value = e.target.value;
                })
              )
            }
            fullWidth
            size="small"
            type={"number"}
          />
        </Box>
      </Stack>
    </GlassCard>
  );
}

export default MaxLeadScoreCard;
