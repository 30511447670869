import {
  ApiCSVDateGrouping,
  ApiCSVField,
  ApiCSVMappingResponse,
  ApiCSVRow,
  ApiCSVRule,
  ApiCSVTestResult,
  ApiMedia,
} from "@incendium/api";
import { createContext, useContext, useMemo, useState } from "react";

type TCSVUploaderContext = {
  media: ApiMedia;
  setMedia: React.Dispatch<React.SetStateAction<ApiMedia>>;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  mappingResponse: ApiCSVMappingResponse;
  setMappingResponse: React.Dispatch<
    React.SetStateAction<ApiCSVMappingResponse>
  >;
  rule: ApiCSVRule;
  setRule: React.Dispatch<React.SetStateAction<ApiCSVRule>>;
  mapping: { [key: string]: ApiCSVRow };
  setMapping: React.Dispatch<
    React.SetStateAction<{
      [key: string]: ApiCSVRow;
    }>
  >;
  userValue: { [key: string]: string };
  setUserValue: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
  dateGrouping: ApiCSVDateGrouping;
  setDateGroup: React.Dispatch<React.SetStateAction<ApiCSVDateGrouping>>;
  validation: { [col: string]: boolean };
  setValidation: React.Dispatch<
    React.SetStateAction<{ [col: string]: boolean }>
  >;
  testResponse: ApiCSVTestResult;
  setTestResponse: React.Dispatch<React.SetStateAction<ApiCSVTestResult>>;
  filteredMapping: {
    [k: string]: ApiCSVRow;
  };
  orderedFields: ApiCSVField[];
  reset: () => void;
};

export const CSVUploaderContext = createContext<TCSVUploaderContext>(
  {} as TCSVUploaderContext
);

export const useCSVUploaderContext = () => useContext(CSVUploaderContext);

const CSVUploaderProvider = ({ children }: { children: React.ReactNode }) => {
  const [step, setStep] = useState(1);
  const [media, setMedia] = useState<ApiMedia>({});
  const [mappingResponse, setMappingResponse] = useState<ApiCSVMappingResponse>(
    {}
  );
  const [testResponse, setTestResponse] = useState<ApiCSVTestResult>({});
  const [mapping, setMapping] = useState<{ [key: string]: ApiCSVRow }>({});
  const [userValue, setUserValue] = useState<{ [key: string]: string }>({});
  const [dateGrouping, setDateGroup] = useState<ApiCSVDateGrouping>(
    ApiCSVDateGrouping.CSV_DATE_GROUPING_NO_GROUPING
  );
  const [rule, setRule] = useState<ApiCSVRule>({});
  const [validation, setValidation] = useState<{ [col: string]: boolean }>({});

  const filteredMapping = useMemo(() => {
    return Object.fromEntries(
      Object.entries(mapping).filter((a) => (a[1].columns || []).length > 0)
    );
  }, [mapping]);

  const orderedFields = useMemo(() => {
    return (rule.fields || [])
      .filter((a) => a.id !== "name")
      .sort((a, b) => (a.id || "").localeCompare(b.id || ""))
      .sort((a, b) => (b.must || "").length - (a.must || "").length)
      .sort((a, b) => (b.required ? 1 : 0) - (a.required ? 1 : 0));
  }, [rule]);

  const state = useMemo(() => {
    const reset = () => {
      setStep(1);
      setMedia({});
      setMappingResponse({});
      setTestResponse({});
      setMapping({});
      setUserValue({});
      setDateGroup(ApiCSVDateGrouping.CSV_DATE_GROUPING_NO_GROUPING);
      setRule({});
      setValidation({});
    };
    return {
      step,
      setStep,
      media,
      setMedia,
      mappingResponse,
      setMappingResponse,
      testResponse,
      setTestResponse,
      mapping,
      setMapping,
      userValue,
      setUserValue,
      dateGrouping,
      setDateGroup,
      rule,
      setRule,
      validation,
      setValidation,
      filteredMapping,
      orderedFields,
      reset,
    };
  }, [
    step,
    setStep,
    media,
    setMedia,
    mappingResponse,
    setMappingResponse,
    testResponse,
    setTestResponse,
    mapping,
    setMapping,
    userValue,
    setUserValue,
    dateGrouping,
    setDateGroup,
    rule,
    setRule,
    validation,
    setValidation,
    filteredMapping,
    orderedFields,
  ]);

  return (
    <CSVUploaderContext.Provider value={state}>
      {children}
    </CSVUploaderContext.Provider>
  );
};

export default CSVUploaderProvider;
