import { ApiProduct, ApiProject } from "@incendium/api";
import { productService } from "Apis";
import { useSharedState } from "./useSharedState";

const fetchProducts = async (selectedProject: ApiProject | null) => {
  if (!selectedProject) {
    return [];
  }
  const res = await productService.productServiceListProducts({
    projectId: selectedProject.id as number,
  });
  return res.results || [];
};

export const useProducts = () => {
  const { state, setState, refetch, loading } = useSharedState(
    "products",
    [],
    fetchProducts
  );

  return {
    products: state as ApiProduct[],
    setProducts: setState,
    refetch,
    loading,
  };
};
