import { Typography } from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import { TabWrapper } from "Components/UI/TabContainer";
import { enumToArray } from "Helpers/enumToText";
import { useCallback, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import TabbedAnalysesPage from "Pages/Analyse/TabbedAnalysesPage";
import EmailOverview from "./EmailOverview";

enum Insight {
  overview = "Overview",
}

function MarketingPerformanceEmailPage() {
  const { insight } = useParams<{ insight: string }>();
  const [activeInsight, setActiveInsight] = useState(insight);

  const renderSwitch = useCallback(() => {
    switch (activeInsight) {
      case Insight.overview.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <EmailOverview />{" "}
          </TabWrapper>
        );

      default:
        return (
          <TabWrapper>
            <CenterPage calcHeight>
              <Typography variant="subtitle1">Not Implemented</Typography>
            </CenterPage>
          </TabWrapper>
        );
    }
  }, [activeInsight]);

  if (!insight || insight === "") {
    return <Redirect to={`./email-performance/overview`} />;
  }

  return (
    <TabbedAnalysesPage
      title="Marketing Performance - Email"
      activeTab={activeInsight}
      setActiveTab={setActiveInsight}
      tabs={enumToArray(Insight)}
      renderSwitch={renderSwitch}
    />
  );
}

export default MarketingPerformanceEmailPage;
