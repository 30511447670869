import {
  ApiCampaignStatus,
  ApiCampaignType,
  ApiCreateUpdateCampaignPayload,
  ApiSimpleCampaign,
  ApiSimplePersonalisation,
} from "@incendium/api";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Avatar,
  Box,
  Button,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import StyledDrawer, {
  StyledDrawerActions,
  StyledDrawerTitle,
} from "Components/UI/StyledDrawer";
import { GenericDialoagProps } from "Interfaces";
import produce from "immer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import moment from "moment";
import { StyledSlider } from "Components/UI/StyledSlider";
import { useDebounce, usePrevious } from "react-use";
import { AccordianChartBuilderSidebarBlock } from "features/chartLibrary";
import { CampaignSplitUrls, PersonalisationDialog } from "features/campaigns";
import { Add, Code, Delete, Edit } from "@mui/icons-material";
import { personalisationService } from "Apis";
import { useNotification, useSelectedProject } from "Hooks";
import { useCampaigns } from "Hooks/useCampaigns";
import { useConfirmationContext } from "Providers/ConfirmationProvider";
import AudenceMultiSelect from "Components/Audience/AudenceMultiSelect";
import { SubdomainSelector } from "features/subdomains";
import { testPersonalisationParam } from "@incendium/inc-ts-helpers";

interface ICampaignSetupProps extends GenericDialoagProps {
  campaign: ApiSimpleCampaign;
  setCampaign: (c: ApiSimpleCampaign) => void;
}

function CampaignSetup({
  open,
  setOpen,
  campaign,
  setCampaign,
}: ICampaignSetupProps) {
  const { refetch } = useCampaigns();
  const { selectedProject } = useSelectedProject();
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const [probability, setProbability] = useState(
    campaign.probability ? campaign.probability * 100 : 100
  );
  const [personalisation, setPersonalisation] =
    useState<ApiSimplePersonalisation>({});
  const [openPersonalisation, setOpenPersonalisation] = useState(false);
  const prevOpen = usePrevious(open);
  const { openConfirmation, closeConfirmation } = useConfirmationContext();

  useEffect(() => {
    if (!prevOpen && open) {
      setProbability(campaign.probability ? campaign.probability * 100 : 100);
    }
  }, [open, prevOpen, campaign]);

  useDebounce(
    () => {
      setCampaign(
        produce(campaign, (draft) => {
          draft.probability = probability / 100;
        })
      );
    },
    200,
    [probability]
  );

  const marks = useMemo(
    () => [
      { value: 0, label: "0%" },
      { value: 20, label: "20%" },
      { value: 40, label: "40%" },
      { value: 60, label: "60%" },
      { value: 80, label: "80%" },
      { value: 100, label: "100%" },
    ],
    []
  );

  const newPersonalisation = useCallback(() => {
    setPersonalisation({});
    setOpenPersonalisation(true);
  }, []);

  const editPersonalisation = useCallback((personalisation) => {
    setPersonalisation(personalisation);
    setOpenPersonalisation(true);
  }, []);

  const onClose = useCallback(() => {
    setCampaign({});
    setProbability(100);
    setOpen(false);
  }, [setOpen, setCampaign]);

  const personalisationOnSaved = useCallback(
    (personalisation: ApiSimplePersonalisation, remove?: boolean) => {
      const newCampaign = produce(campaign, (draft) => {
        if (!draft.personalisations || draft.personalisations.length === 0) {
          draft.personalisations = [personalisation];
          return;
        }

        const idx = draft.personalisations.findIndex(
          (p) => p.id === personalisation.id
        );

        if (idx >= 0) {
          if (remove) {
            draft.personalisations.splice(idx, 1);
          } else {
            draft.personalisations[idx] = personalisation;
          }
        } else {
          draft.personalisations.push(personalisation);
        }
      });

      setCampaign(newCampaign);
      // refetch campaigns as there state is dirty
      refetch();
    },
    [campaign, setCampaign, refetch]
  );

  const deletePersonalisation = useCallback(
    async (personalisation) => {
      try {
        await personalisationService.personalisationServiceDeletePersonalisation(
          {
            projectId: selectedProject?.id as number,
            personalisationId: personalisation.id as number,
          }
        );
        showSuccessNotification(`${personalisation.name} Removed`);
        personalisationOnSaved(personalisation, true);
      } catch (error: any) {
        const t = await error.json();
        if (t.code === 9) {
          openConfirmation({
            title: `This personalisation is used in active campaign.`,
            body: "Deleting this personalization will deactivate the campaign. Are you sure you want to proceed?",
            callback: async () => {
              try {
                await personalisationService.personalisationServiceDeletePersonalisation(
                  {
                    projectId: selectedProject?.id as number,
                    personalisationId: personalisation.id as number,
                    token: t.details[0].message,
                  }
                );
                showSuccessNotification(`${personalisation.name} Removed`);
                personalisationOnSaved(personalisation, true);
              } catch (error) {
                showErrorNotification(
                  `Internal Error deleting personalisation, please try again`
                );
              }
              closeConfirmation();
            },
          });
          return;
        }

        showErrorNotification(
          `Internal Error deleting personalisation, please try again`
        );
      }
    },
    [
      selectedProject?.id,
      showSuccessNotification,
      personalisationOnSaved,
      showErrorNotification,
      openConfirmation,
      closeConfirmation,
    ]
  );

  const onSubmit = useCallback(
    async (e: React.FormEvent, token?: string) => {
      e.preventDefault();
      if (!selectedProject) {
        return;
      }
      try {
        const payload: ApiCreateUpdateCampaignPayload = {
          name: campaign.name,
          description: campaign.description,
          startDate: campaign.startDate,
          endDate: campaign.endDate,
          maxNumberOfLeads: campaign.maxNumberOfLeads,
          probability: campaign.probability || 1,
          type: campaign.type || ApiCampaignType.CAMPAIGN_TYPE_NONE,
          audienceIds: campaign.audienceIds,
          fallbackUrl: campaign.fallbackUrl,
          subdomainId: campaign.subdomainId,
          noCache: campaign.noCache,
          urls: (campaign.urls || [])
            .map((u) => u.url || "")
            .filter((u) => u !== ""),
        };

        const fn = campaign?.id
          ? personalisationService.personalisationServiceUpdateCampaign({
              projectId: selectedProject.id as number,
              campaignId: campaign.id,
              payload,
              token,
            })
          : personalisationService.personalisationServiceCreateCampaign({
              projectId: selectedProject.id as number,
              payload,
            });
        const res = await fn;

        // we then see if any of the personalisations attached have no campaign id and update these
        const personalisationsToUpdate = (
          campaign.personalisations || []
        ).filter((p) => !p.campaignId);
        const requests = personalisationsToUpdate.map((p) =>
          personalisationService.personalisationServiceUpdatePersonalisation({
            projectId: selectedProject.id as number,
            personalisationId: p.id as number,
            payload: {
              ...p,
              campaignId: res.campaign?.id as number,
            },
          })
        );
        await Promise.all(requests);

        // refetch campaigns as there state is dirty
        await refetch();
        showSuccessNotification(`${res.campaign?.name || campaign.name} Saved`);
      } catch (error: any) {
        const t = await error.json();
        if (t.code === 9) {
          openConfirmation({
            title: `This campaign is active.`,
            body: "Editing this campaign might result in inaccuracies in your analytics. Do you wish to proceed?",
            callback: async () => {
              onSubmit(e, t.details[0].message);
              closeConfirmation();
            },
          });
          return;
        }
        showErrorNotification(
          `Internal Error saving Campaign settings, please try again`
        );
      }

      onClose();
    },
    [
      campaign,
      selectedProject,
      showErrorNotification,
      showSuccessNotification,
      onClose,
      refetch,
      openConfirmation,
      closeConfirmation,
    ]
  );

  return (
    <StyledDrawer open={open} onClose={onClose} maxWidth={600}>
      <StyledDrawerTitle>
        <Typography variant="subtitle1">Campaign Setup</Typography>
        <Typography variant="body2">
          Give your campaign a recognizable name and define its schedule.
        </Typography>
      </StyledDrawerTitle>
      <ValidatorForm onSubmit={(e) => onSubmit(e)}>
        <AccordianChartBuilderSidebarBlock
          title="General Settings"
          subTitle="Specify both the maximum number of leads this campaign can
            accommodate and the likelihood that a new lead will be included in
            this campaign."
        >
          <Stack mt={1} spacing={3}>
            <TextValidator
              variant="outlined"
              size="small"
              fullWidth
              label="Name"
              name="name"
              validators={["required"]}
              errorMessages={["Campaign Name is required"]}
              helperText="Give you campaign a recognizable name"
              value={campaign.name || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCampaign(
                  produce(campaign, (draft) => {
                    draft.name = e.target.value;
                  })
                );
              }}
            />

            <TextValidator
              variant="outlined"
              fullWidth
              label="Description"
              name="description"
              helperText="Describe your campaign"
              multiline
              rows={3}
              validators={["maxStringLength:250"]}
              errorMessages={[
                "Campaign description can only be 250 characters long",
              ]}
              value={campaign.description || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCampaign(
                  produce(campaign, (draft) => {
                    draft.description = e.target.value;
                  })
                );
              }}
            />
            <Stack direction={"row"} spacing={4}>
              <DatePicker
                disabled={
                  campaign.status === ApiCampaignStatus.CAMPAIGN_STATUS_ACTIVE
                }
                label="Campaign Start Date"
                value={campaign.startDate ? moment(campaign.startDate) : null}
                minDate={
                  campaign.startDate || (moment().utc().startOf("day") as any)
                }
                maxDate={
                  campaign.endDate
                    ? (moment(campaign.endDate).subtract(1, "day") as any)
                    : undefined
                }
                onChange={(d) => {
                  setCampaign(
                    produce(campaign, (draft) => {
                      draft.startDate = d || undefined;
                    })
                  );
                }}
                slotProps={{
                  field: {
                    clearable: true,
                    onClear: () =>
                      setCampaign(
                        produce(campaign, (draft) => {
                          draft.startDate = undefined;
                        })
                      ),
                  },
                  textField: {
                    fullWidth: true,
                    size: "small",
                  },
                }}
              />
              <DatePicker
                label="Campaign End Date"
                value={campaign.endDate ? moment(campaign.endDate) : null}
                minDate={moment(campaign.startDate).add(1, "day") as any}
                onChange={(d) => {
                  setCampaign(
                    produce(campaign, (draft) => {
                      draft.endDate = d || undefined;
                    })
                  );
                }}
                slotProps={{
                  field: {
                    clearable: true,
                    onClear: () =>
                      setCampaign(
                        produce(campaign, (draft) => {
                          draft.startDate = undefined;
                        })
                      ),
                  },
                  textField: {
                    fullWidth: true,
                    size: "small",
                  },
                }}
              />
            </Stack>
            <Stack spacing={0.5}>
              <FormControlLabel
                componentsProps={{
                  typography: {
                    variant: "body1",
                  },
                }}
                control={
                  <Switch
                    checked={campaign.noCache || false}
                    onChange={(e, checked) => {
                      setCampaign(
                        produce(campaign, (draft) => {
                          draft.noCache = checked;
                        })
                      );
                    }}
                  />
                }
                label="No Cache"
              />
              <Typography variant="body2">
                By default, when a lead is added to a campaign, the result is
                cached to ensure faster campaign delivery on their next page
                view or visit. You can disable caching, allowing Incendium to
                evaluate the lead for campaign eligibility on every page view.
                This is useful if the lead's audience changes frequently.
                However, note that this may increase the delay in delivering
                personalized content.
              </Typography>
            </Stack>
          </Stack>
        </AccordianChartBuilderSidebarBlock>
        {campaign.type !== ApiCampaignType.CAMPAIGN_TYPE_SPLIT && (
          <AccordianChartBuilderSidebarBlock
            title="Campaign Tweaks"
            subTitle="  Specify both the maximum number of leads this campaign can
            accommodate and the likelihood that a new lead will be included in
            this campaign."
          >
            <Stack spacing={3}>
              <Box px={2}>
                <StyledSlider
                  align="left"
                  title="Select probability that a new lead will be part of campaign."
                  valueLabelDisplay="auto"
                  valueLabelFormat={(v) => `${v}%`}
                  marks={marks}
                  value={probability || 100}
                  onChange={(_, v) => setProbability(v as number)}
                />
              </Box>
              <Box sx={{ width: "60%" }}>
                <TextValidator
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Max No. of Leads"
                  name="maxNumberOfLeads"
                  validators={["minNumber:1"]}
                  errorMessages={[
                    "Campaign requires at least 1 lead, if you are wanting no limit leave this field blank",
                  ]}
                  helperText="Set the maximum number of leads, if you are wanting no limit leave this field blank"
                  type="number"
                  value={campaign.maxNumberOfLeads || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setCampaign(
                      produce(campaign, (draft) => {
                        draft.maxNumberOfLeads = Number(e.target.value);
                      })
                    );
                  }}
                />
              </Box>
              <Box sx={{ width: "60%" }}>
                <AudenceMultiSelect
                  label="Select audiences"
                  helperText="Choose one or multiple audiences for this campaign's targeting. Leaving this section blank means the campaign will run for all audiences, without targeting specific ones."
                  size={"small"}
                  fullWidth
                  variant="outlined"
                  disableClearable
                  values={(campaign.audienceIds || []).map((id) => ({
                    id,
                  }))}
                  onChange={(audiences) => {
                    setCampaign(
                      produce(campaign, (draft) => {
                        draft.audienceIds = (audiences || []).map(
                          (a) => a.id as number
                        );
                      })
                    );
                  }}
                />
              </Box>
            </Stack>
          </AccordianChartBuilderSidebarBlock>
        )}
        {campaign.type !== ApiCampaignType.CAMPAIGN_TYPE_SPLIT && (
          <AccordianChartBuilderSidebarBlock
            title="Personalisation"
            subTitle="Enhance this campaign by incorporating personalization components, and specify the locations and methods for their integration."
          >
            <Stack spacing={3}>
              <List disablePadding>
                {(campaign.personalisations || []).map((p) => (
                  <ListItem
                    key={p.id}
                    secondaryAction={
                      <>
                        <IconButton onClick={() => editPersonalisation(p)}>
                          <Edit />
                        </IconButton>
                        <IconButton
                          edge="end"
                          onClick={() => deletePersonalisation(p)}
                        >
                          <Delete />
                        </IconButton>
                      </>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Code />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={p.name}
                      secondary={
                        <>
                          <Typography variant="body2" noWrap>
                            {p.description}
                          </Typography>

                          <Typography variant="body2" fontWeight={"bold"}>
                            How to test.
                          </Typography>
                          <Typography variant="body2">
                            Devtools : Incendium.testPersonalisation({p.id})
                          </Typography>
                          <Typography variant="body2">
                            Search Param : {testPersonalisationParam}={p.id}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>

              <Box>
                <Button
                  size="small"
                  startIcon={<Add />}
                  onClick={newPersonalisation}
                >
                  Add New Personlisation
                </Button>
              </Box>
            </Stack>
          </AccordianChartBuilderSidebarBlock>
        )}
        {campaign.type === ApiCampaignType.CAMPAIGN_TYPE_SPLIT && (
          <AccordianChartBuilderSidebarBlock
            title="URLs"
            subTitle="Select a subdomain and enter the URLS that this campaign will be split between, this can be one of your landing pages or any other page from your main site."
          >
            <Stack spacing={6}>
              <SubdomainSelector
                subdomainId={campaign.subdomainId}
                setSubdomainId={(v) =>
                  setCampaign(
                    produce(campaign, (draft) => {
                      draft.subdomainId = v;
                    })
                  )
                }
                helperText={`Select a subdomain that you shall use as the root for your split campaign. Once saved we shall generate the path for you.`}
              />
              <CampaignSplitUrls
                campaign={campaign}
                setCampaign={setCampaign}
              />
              <TextValidator
                size="small"
                variant="outlined"
                fullWidth
                label="Fallback URL"
                name="fallbackUrl"
                helperText="The Campaigns Fallback URL is used for once th campaign has finished, this will direct people to the given URL so the user does not end up in a dead end."
                validators={["isDomain"]}
                errorMessages={[
                  "Split requires a valid Domain for it's fallback URL",
                ]}
                value={campaign.fallbackUrl || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCampaign(
                    produce(campaign, (draft) => {
                      draft.fallbackUrl = e.target.value;
                    })
                  );
                }}
              />
            </Stack>
          </AccordianChartBuilderSidebarBlock>
        )}

        <StyledDrawerActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
        </StyledDrawerActions>
      </ValidatorForm>
      {campaign.type !== ApiCampaignType.CAMPAIGN_TYPE_SPLIT && (
        <PersonalisationDialog
          personlisation={personalisation}
          open={openPersonalisation}
          setOpen={setOpenPersonalisation}
          onSaved={personalisationOnSaved}
          campaignId={campaign.id}
        />
      )}
    </StyledDrawer>
  );
}

export default CampaignSetup;
