import { ApiFormRule } from "@incendium/api";
import { IconButton, } from "@mui/material";
import { AddCircle, Delete, Edit } from "@mui/icons-material";
import { Tag } from "Components/Tag";

export const FormFieldsList = ({
    fields,
    onAdd,
    onEdit,
    onDelete,
}: {
    fields: ApiFormRule[];
    onAdd: () => void;
    onEdit: (field: ApiFormRule) => void;
    onDelete: (field: ApiFormRule) => void;
}) => {
    return (
        <>
            <Tag
                text="add new field"
                color="primary"
                icons={
                    <>
                        <IconButton onClick={onAdd} size="small">
                            <AddCircle color="primary" />
                        </IconButton>
                    </>
                }
            />
            {fields.map((field) => {
                return (
                    <Tag
                        key={field.id || Math.random()}
                        text={field.mapping as string}
                        color="secondary"
                        icons={
                            <>
                                <IconButton onClick={() => onEdit(field)} size="small">
                                    <Edit color="primary" />
                                </IconButton>
                                <IconButton onClick={() => onDelete(field)} size="small">
                                    <Delete color="secondary" />
                                </IconButton>
                            </>
                        }
                    />
                );
            })}
        </>
    );
};
