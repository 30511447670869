import { ISyncConfigComponentProps } from "features/dataConnectors/types";
import SyncAccountConfigDrawer from "features/dataConnectors/components/SyncAccountConfigDrawer";
import { ApiService, ApiSyncType } from "@incendium/api";

function MetaAds({
  oauthClient,
  syncs,
  open,
  setOpen,
}: ISyncConfigComponentProps) {
  return (
    <SyncAccountConfigDrawer
      oauthClient={oauthClient}
      syncs={syncs}
      provider="meta"
      type={ApiSyncType.FACEBOOK_ADS}
      open={open}
      setOpen={setOpen}
      service={ApiService.FACEBOOK}
    />
  );
}

export default MetaAds;
