/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiChartPayloadFromJSON, ApiChartPayloadToJSON, } from './ApiChartPayload';
export function ApiPrimaryRequestPayloadFromJSON(json) {
    return ApiPrimaryRequestPayloadFromJSONTyped(json, false);
}
export function ApiPrimaryRequestPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'chart': !exists(json, 'chart') ? undefined : ApiChartPayloadFromJSON(json['chart']),
        'from': !exists(json, 'from') ? undefined : (new Date(json['from'])),
        'to': !exists(json, 'to') ? undefined : (new Date(json['to'])),
        'lastNDays': !exists(json, 'lastNDays') ? undefined : json['lastNDays'],
        'leadId': !exists(json, 'leadId') ? undefined : json['leadId'],
    };
}
export function ApiPrimaryRequestPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'chart': ApiChartPayloadToJSON(value.chart),
        'from': value.from === undefined ? undefined : (value.from.toISOString()),
        'to': value.to === undefined ? undefined : (value.to.toISOString()),
        'lastNDays': value.lastNDays,
        'leadId': value.leadId,
    };
}
