/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiAttributionClickStatus;
(function (ApiAttributionClickStatus) {
    ApiAttributionClickStatus["UNSPECIFIED"] = "ATTRIBUTION_CLICK_STATUS_UNSPECIFIED";
    ApiAttributionClickStatus["MATCHED"] = "ATTRIBUTION_CLICK_STATUS_MATCHED";
    ApiAttributionClickStatus["COST_DISTRIBUTED"] = "ATTRIBUTION_CLICK_STATUS_COST_DISTRIBUTED";
})(ApiAttributionClickStatus || (ApiAttributionClickStatus = {}));
export function ApiAttributionClickStatusFromJSON(json) {
    return ApiAttributionClickStatusFromJSONTyped(json, false);
}
export function ApiAttributionClickStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiAttributionClickStatusToJSON(value) {
    return value;
}
