/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiChartAttributeFromJSON, ApiChartAttributeToJSON, } from './ApiChartAttribute';
import { ApiChartPayloadFromJSON, ApiChartPayloadToJSON, } from './ApiChartPayload';
export function ApiTabChartPayloadFromJSON(json) {
    return ApiTabChartPayloadFromJSONTyped(json, false);
}
export function ApiTabChartPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'extraAttributes': !exists(json, 'extraAttributes') ? undefined : (json['extraAttributes'].map(ApiChartAttributeFromJSON)),
        'chartId': !exists(json, 'chartId') ? undefined : json['chartId'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'overridingChart': !exists(json, 'overridingChart') ? undefined : ApiChartPayloadFromJSON(json['overridingChart']),
        'removeOverridingChart': !exists(json, 'removeOverridingChart') ? undefined : json['removeOverridingChart'],
    };
}
export function ApiTabChartPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'extraAttributes': value.extraAttributes === undefined ? undefined : (value.extraAttributes.map(ApiChartAttributeToJSON)),
        'chartId': value.chartId,
        'order': value.order,
        'overridingChart': ApiChartPayloadToJSON(value.overridingChart),
        'removeOverridingChart': value.removeOverridingChart,
    };
}
