/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiActionFieldModelFromJSON, ApiActionFieldModelToJSON, } from './ApiActionFieldModel';
import { ApiActionTypeFromJSON, ApiActionTypeToJSON, } from './ApiActionType';
export function ApiActionFromJSON(json) {
    return ApiActionFromJSONTyped(json, false);
}
export function ApiActionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : ApiActionTypeFromJSON(json['type']),
        'target': !exists(json, 'target') ? undefined : json['target'],
        'model': !exists(json, 'model') ? undefined : ApiActionFieldModelFromJSON(json['model']),
        'targetField': !exists(json, 'targetField') ? undefined : json['targetField'],
    };
}
export function ApiActionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'type': ApiActionTypeToJSON(value.type),
        'target': value.target,
        'model': ApiActionFieldModelToJSON(value.model),
        'targetField': value.targetField,
    };
}
