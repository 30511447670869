import { channelService } from "Apis";
import { useSharedState } from "./useSharedState";
import { ApiChannel, ApiProject } from "@incendium/api";

const fetchChannels = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const channels = await channelService.channelServiceListChannels({
      projectId: selectedProject.id as number,
    });
    return channels.results || [];
  }
  return [];
};
export const useChannels = () => {
  const { state, setState, loading } = useSharedState(
    "channels",
    [],
    fetchChannels
  );

  return {
    channels: state as ApiChannel[],
    setChannels: setState as React.Dispatch<React.SetStateAction<ApiChannel[]>>,
    loading,
  };
};
