/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiSessionEffectivenessConditionFromJSON, ApiSessionEffectivenessConditionToJSON, } from './ApiSessionEffectivenessCondition';
import { ApiSessionEffectivenessRuleFromJSON, ApiSessionEffectivenessRuleToJSON, } from './ApiSessionEffectivenessRule';
export function ApiSessionEffectivenessConditionGroupFromJSON(json) {
    return ApiSessionEffectivenessConditionGroupFromJSONTyped(json, false);
}
export function ApiSessionEffectivenessConditionGroupFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'rule': !exists(json, 'rule') ? undefined : ApiSessionEffectivenessRuleFromJSON(json['rule']),
        'ruleId': !exists(json, 'ruleId') ? undefined : json['ruleId'],
        'conditions': !exists(json, 'conditions') ? undefined : (json['conditions'].map(ApiSessionEffectivenessConditionFromJSON)),
    };
}
export function ApiSessionEffectivenessConditionGroupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'rule': ApiSessionEffectivenessRuleToJSON(value.rule),
        'ruleId': value.ruleId,
        'conditions': value.conditions === undefined ? undefined : (value.conditions.map(ApiSessionEffectivenessConditionToJSON)),
    };
}
