import {
  ApiEffectivenessConditionGroup,
  ApiOperator,
  ApiProject,
  ApiEffectivenessRule,
  ApiConditionCustom,
  ApiSessionConditionCustom,
  EffectivenessRuleServiceUpdateRuleRequest,
  SessionEffectivenessRuleServiceUpdateRuleRequest,
} from "@incendium/api";
import {
  effectivenessRuleService,
  sessionEffectivenessRuleService,
} from "Apis";
import { useConditionGroupContext } from "Components/ConditionalGroup/ConditionGroupProvider";
import ConditionGroupsFormWrapper from "Components/ConditionalGroup/ConditionGroupsFormWrapper";
import { enumToArray } from "Helpers/enumToText";
import { isEmpty } from "Helpers/isEmpty";
import { useEffectivenessRules } from "Hooks/useEffectivenessRules";
import { useSessionEffectivenessRules } from "Hooks/useSessionEffectivenessRules";
import produce from "immer";
import { TEffectivenessRule } from "Interfaces";
import { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import EffectivenessRules from "./EffectivenessRules";
import EffectivenessSidebar from "./EffectivenessSidebar";
import { useNotification } from "Hooks";

interface IEffectivenessFormProps {
  rule: TEffectivenessRule;
  setRule: React.Dispatch<React.SetStateAction<TEffectivenessRule | undefined>>;
  project: ApiProject;
  isPageview: boolean;
}

function EffectivenessForm({
  rule,
  setRule,
  project,
  isPageview,
}: IEffectivenessFormProps) {
  const { conditionGroups, setConditionGroups } = useConditionGroupContext();
  const { effectivenessRules, setEffectivenessRules } = useEffectivenessRules();
  const { sessionEffectivenessRules, setSessionEffectivenessRules } =
    useSessionEffectivenessRules();
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const history = useHistory();

  const types = useMemo(() => {
    if (isPageview) {
      return [
        ApiConditionCustom.CONVERSION_TRIGGERED,
        ApiConditionCustom.CONVERSION_COUNT,
        ApiConditionCustom.CONVERSION_MACRO_COUNT,
        ApiConditionCustom.CONVERSION_MICRO_COUNT,
        ApiConditionCustom.TIME_ON_PAGE_OVER,
        ApiConditionCustom.SCROLL_RATE_OVER,
        ApiConditionCustom.VIEW_RATE_OVER,
        ApiConditionCustom.TIME_ON_PAGE_PERCENTILE,
        ApiConditionCustom.SCROLL_RATE_PERCENTILE,
        ApiConditionCustom.VIEW_RATE_PERCENTILE,
        ApiConditionCustom.PAGE_SCORE,
      ];
    }
    return enumToArray(ApiSessionConditionCustom).filter(
      (e) => e !== ApiSessionConditionCustom.UNSPECIFIED
    );
  }, [isPageview]);

  useEffect(() => {
    return () => {
      setConditionGroups([]);
      setRule(undefined);
    };
  }, []);

  useEffect(() => {
    if (!rule || conditionGroups.length) {
      return;
    }

    const groups = rule.conditionGroups || [];
    setConditionGroups(
      groups && groups.length > 0
        ? [...groups]
        : [
            {
              conditions: [{}],
            },
          ]
    );
  }, [rule, setConditionGroups, conditionGroups]);

  const onSubmit = async () => {
    const req:
      | EffectivenessRuleServiceUpdateRuleRequest
      | SessionEffectivenessRuleServiceUpdateRuleRequest = {
      projectId: project.id as number,
      ruleId: rule.id as number,
      payload: {
        name: rule.name,
        conditionGroups: (conditionGroups as ApiEffectivenessConditionGroup[])
          .filter((c) => {
            return (
              !isEmpty(c) &&
              (c.conditions || []).filter((co) => !isEmpty(co)).length > 0
            );
          })
          .map((c) => ({
            conditions: (c.conditions || []).map((condition) => {
              if (
                condition.custom !== ApiConditionCustom.CONVERSION_TRIGGERED
              ) {
                return {
                  ...condition,
                  operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
                };
              }

              return {
                ...condition,
                operator: ApiOperator.EQUAL,
              };
            }),
          })),
      },
    };

    if (isPageview) {
      req.payload.locations = (
        (rule as ApiEffectivenessRule).locations || []
      ).map((l) => l.id as number);
    }

    try {
      let res: any;
      switch (isPageview) {
        case true:
          res =
            await effectivenessRuleService.effectivenessRuleServiceUpdateRule(
              req as EffectivenessRuleServiceUpdateRuleRequest
            );
          // update list
          setEffectivenessRules(
            produce(effectivenessRules, (draft) => {
              const idx = draft.findIndex((e) => e.id === rule.id);
              if (idx < 0) {
                return;
              }
              draft[idx] = res;
            })
          );
          break;

        case false:
          res =
            await sessionEffectivenessRuleService.sessionEffectivenessRuleServiceUpdateRule(
              req as SessionEffectivenessRuleServiceUpdateRuleRequest
            );
          setSessionEffectivenessRules(
            produce(sessionEffectivenessRules, (draft) => {
              const idx = draft.findIndex((e) => e.id === rule.id);
              if (idx < 0) {
                return;
              }
              draft[idx] = res;
            })
          );
          break;
      }

      showSuccessNotification(`${res.name} Saved`);
    } catch (error) {
      const resp = await (error as any).json();
      showErrorNotification(resp.message);
    }
  };

  const onCancel = () => {
    history.push(
      `/clients/${project.clientId}/projects/${project?.id}/train/effectiveness`
    );
  };

  return (
    <ConditionGroupsFormWrapper
      onSubmit={onSubmit}
      onCancel={onCancel}
      rules={<EffectivenessRules types={types} />}
    >
      <EffectivenessSidebar
        effectiveness={rule}
        setEffectiveness={setRule}
        isPageview={isPageview}
      />
    </ConditionGroupsFormWrapper>
  );
}

export default EffectivenessForm;
