import { ApiPageType } from "@incendium/api";
import {
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import SpacedList, { SpacedLinkButton } from "Components/UI/SpacedList";
import { cell1Icon } from "consts";

interface IPageTypesListProps {
  pageTypes: ApiPageType[];
  onEdit: (pageType: ApiPageType) => void;
  onDelete: (pageType: ApiPageType) => void;
}

function PageTypesList({ pageTypes, onEdit, onDelete }: IPageTypesListProps) {
  return (
    <SpacedList>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {pageTypes.map((pt) => (
          <TableRow key={pt.id}>
            <TableCell>
              <SpacedLinkButton onClick={() => onEdit(pt)}>
                {pt.name || ""}
              </SpacedLinkButton>
            </TableCell>
            <TableCell align="right" style={{ width: cell1Icon }}>
              <IconButton
                size="small"
                color="secondary"
                onClick={() => onDelete(pt)}
              >
                <Delete />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </SpacedList>
  );
}

export default PageTypesList;
