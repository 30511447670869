import { ApiService, ApiSyncStatus, ApiSyncType } from "@incendium/api";
import { Delete } from "@mui/icons-material";
import { Button, Stack, SxProps, Theme, Typography } from "@mui/material";
import { syncService } from "Apis";
import StyledDrawer from "Components/UI/StyledDrawer";
import { SyncCardUi, ConnectButton } from "features/dataConnectors";
import { ISyncConfigComponentProps } from "features/dataConnectors/types";
import { useNotification } from "Hooks";
import { useExternalSyncs } from "Hooks/useExternalSyncs";
import { useSyncClients } from "Hooks/useSyncClients";
import { useUser } from "Hooks/useUser";
import produce from "immer";
import { useConfirmationContext } from "Providers/ConfirmationProvider";
import { ComponentType, useCallback, useMemo, useState } from "react";

interface ISyncCardProps {
  logo: string;
  title: string;
  description: string;
  service: ApiService;
  type: ApiSyncType;
  configComponent: ComponentType<ISyncConfigComponentProps>;
  imageSx?: SxProps<Theme>;
  state?: string;
}

function SyncCard({
  logo,
  title,
  description,
  service,
  type,
  configComponent: ConfigComponent,
  imageSx,
  state,
}: ISyncCardProps) {
  const { syncClients, setSyncClients } = useSyncClients();
  const { externalSyncs, setExternalSyncs } = useExternalSyncs();
  const { user } = useUser();

  const { showSuccessNotification, showErrorNotification } = useNotification();
  const [openConfig, setOpenConfig] = useState(false);
  const { openConfirmation, closeConfirmation } = useConfirmationContext();

  const connection = useMemo(() => {
    if (!syncClients) {
      return undefined;
    }

    return syncClients[service]?.find((f) => f.user?.id === user.id);
  }, [syncClients, service, user]);

  const isReady = useMemo(() => {
    return connection?.readyToTypes?.includes(type);
  }, [connection, type]);

  const syncs = useMemo(() => {
    return externalSyncs.filter((ex) => ex.type === type);
  }, [externalSyncs, type]);

  const handleOnConfigure = useCallback(() => {
    setOpenConfig(true);
  }, []);

  const onDeleteConnection = useCallback(
    (id: number) => {
      openConfirmation({
        title: `Are you sure you want to delete this connection?`,
        body: `This action will remove this connection and any data related to it`,
        callback: async () => {
          try {
            await syncService.syncServiceDeleteClient({
              oauthClientId: id,
            });
            setSyncClients(
              produce(syncClients, (draft) => {
                const idx = draft[service]?.findIndex((f) => f.id === id);
                if (idx >= 0) {
                  draft[service].splice(idx, 1);
                  if (draft[service].length === 0) {
                    delete draft[service];
                  }
                }
              })
            );

            setExternalSyncs(
              produce(externalSyncs, (draft) => {
                const ids = syncs.map((s) => s.id);
                return draft.filter((d) => !ids.includes(d.id));
              })
            );

            showSuccessNotification("Connection removed.");
          } catch (error) {
            showErrorNotification(
              "Failed to remove connection, please try again."
            );
          }

          closeConfirmation();
        },
      });
    },
    [
      syncs,
      externalSyncs,
      setExternalSyncs,
      openConfirmation,
      closeConfirmation,
      showSuccessNotification,
      showErrorNotification,
      syncClients,
      setSyncClients,
      service,
    ]
  );

  return (
    <>
      <SyncCardUi
        logo={logo}
        imageSx={imageSx}
        onConfigureClick={handleOnConfigure}
        title={title}
        description={description}
        isConnected={!!connection}
        isReady={!!isReady}
        footer={
          <Stack direction={"row"} alignItems="center" spacing={2}>
            {(connection || syncs.length > 0) && (
              <>
                {connection && (
                  <>
                    <Button
                      size="small"
                      color="secondary"
                      startIcon={<Delete />}
                      onClick={() =>
                        onDeleteConnection(connection.id as number)
                      }
                    >
                      Remove
                    </Button>
                    {syncs.length > 0 &&
                    syncs[0].status === ApiSyncStatus.ERROR ? (
                      <Typography
                        variant="body2"
                        color={"error"}
                        fontWeight={600}
                      >
                        The Connection is invalid, please reconnect from within
                        confirgure
                      </Typography>
                    ) : isReady ? (
                      <Typography variant="body2">
                        Remove {title} from {user.name}
                      </Typography>
                    ) : (
                      <Typography variant="body2">
                        Incorrect permissions granted, please remove and
                        reconnect
                      </Typography>
                    )}
                  </>
                )}
              </>
            )}
            {!connection && (syncs || []).length === 0 && (
              <>
                <ConnectButton
                  service={service}
                  syncClients={syncClients}
                  setSyncClients={setSyncClients}
                  state={state}
                />
                <Typography variant="body2">
                  Connect {title} to {user.name}
                </Typography>
              </>
            )}
          </Stack>
        }
      />
      <StyledDrawer open={openConfig} onClose={() => setOpenConfig(false)}>
        <ConfigComponent
          oauthClient={connection}
          syncs={syncs}
          open={openConfig}
          setOpen={setOpenConfig}
        />
      </StyledDrawer>
    </>
  );
}

export default SyncCard;
