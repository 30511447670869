import { ApiClassificationGroup, ApiProject } from "@incendium/api";
import { audienceService } from "Apis";
import { useSharedState } from "./useSharedState";

const fetch = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const res = await audienceService.audienceServiceListClassificationGroups({
      projectId: selectedProject.id as number,
    });
    return res.results || [];
  }
  return [];
};
export const useClassificationGroups = () => {
  const { state, setState, loading } = useSharedState(
    "classificationGroups",
    [],
    fetch
  );

  return {
    classificationGroups: state as ApiClassificationGroup[],
    setClassificationGroups: setState as React.Dispatch<
      React.SetStateAction<ApiClassificationGroup[]>
    >,
    loading,
  };
};
