/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiMetricFromJSON, ApiMetricToJSON, } from './ApiMetric';
import { ApiOperatorFromJSON, ApiOperatorToJSON, } from './ApiOperator';
export function ApiChartHavingFromJSON(json) {
    return ApiChartHavingFromJSONTyped(json, false);
}
export function ApiChartHavingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'metric': !exists(json, 'metric') ? undefined : ApiMetricFromJSON(json['metric']),
        'operator': !exists(json, 'operator') ? undefined : ApiOperatorFromJSON(json['operator']),
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}
export function ApiChartHavingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'metric': ApiMetricToJSON(value.metric),
        'operator': ApiOperatorToJSON(value.operator),
        'value': value.value,
    };
}
