/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiChannelFromJSON, ApiChannelToJSON, } from './ApiChannel';
import { ApiSourceFromJSON, ApiSourceToJSON, } from './ApiSource';
export function ApiCalculateChannelFromSourceRequestFromJSON(json) {
    return ApiCalculateChannelFromSourceRequestFromJSONTyped(json, false);
}
export function ApiCalculateChannelFromSourceRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'source': !exists(json, 'source') ? undefined : ApiSourceFromJSON(json['source']),
        'channels': !exists(json, 'channels') ? undefined : (json['channels'].map(ApiChannelFromJSON)),
    };
}
export function ApiCalculateChannelFromSourceRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'source': ApiSourceToJSON(value.source),
        'channels': value.channels === undefined ? undefined : (value.channels.map(ApiChannelToJSON)),
    };
}
