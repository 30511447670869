import { Grid } from "@mui/material";
import { AnalyticsCard, table5Rows } from "features/analytics";
import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiYAxisChartType,
} from "@incendium/api";
import { AnalyticsSpacing } from "consts";

function AudienceNewVsReturningTab() {
  return (
    <Grid container spacing={AnalyticsSpacing}>
      <Grid item xs={8} sx={{ minHeight: table5Rows }}>
        <AnalyticsCard
          chart={{
            name: "New & Returning Users - Primary Metrics",
            dimension: [ApiDimension.DIMENSION_USER_TYPE],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                  ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                  ApiMetric.METRIC_SESSION_MICRO_CONVERSION_RATE,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
              filterDropdowns: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
            },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <AnalyticsCard
          chart={{
            name: "New & Returning Users - Sessions & Conversions",
            dimension: [ApiDimension.DIMENSION_USER_TYPE],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                chart: {
                  [ApiMetric.METRIC_SESSIONS_COUNT]: ApiYAxisChartType.BAR,
                },
              },
              {
                key: "r",
                fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE],
                chart: {
                  [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE]:
                    ApiYAxisChartType.DOT,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
              filterDropdowns: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
            },
          }}
        />
      </Grid>

      <Grid item xs={8} container spacing={AnalyticsSpacing}>
        <Grid item xs={6} sx={{ minHeight: 450 }}>
          <AnalyticsCard
            chart={{
              name: "New & Returning Users Sessions Trend",
              dimension: [
                ApiDimension.DIMENSION_SESSION_START_BY_DAY,
                ApiDimension.DIMENSION_USER_TYPE,
              ],
              template: ApiChartTemplate.TREND,
              type: ApiChartType.GRAPH,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                },
              ],
              displayOptions: {
                noFilters: true,
                filterDropdowns: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
              },
            }}
          />
        </Grid>
        <Grid item xs={6} sx={{ minHeight: 450 }}>
          <AnalyticsCard
            chart={{
              name: "New & Returning Users Effective Rate Trend",
              dimension: [
                ApiDimension.DIMENSION_SESSION_START_BY_DAY,
                ApiDimension.DIMENSION_USER_TYPE,
              ],
              template: ApiChartTemplate.TREND,
              type: ApiChartType.GRAPH,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_EFFECTIVE_SESSION_RATE],
                },
              ],
              displayOptions: {
                noFilters: true,
                filterDropdowns: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
              },
            }}
          />
        </Grid>
        <Grid item xs={6} sx={{ minHeight: 450 }}>
          <AnalyticsCard
            chart={{
              name: "New & Returning Users Time Spent Trend",
              dimension: [
                ApiDimension.DIMENSION_SESSION_START_BY_DAY,
                ApiDimension.DIMENSION_USER_TYPE,
              ],
              template: ApiChartTemplate.TREND,
              type: ApiChartType.GRAPH,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_AVERAGE_SESSION_DURATION],
                },
              ],
              displayOptions: {
                noFilters: true,
                filterDropdowns: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
              },
            }}
          />
        </Grid>
        <Grid item xs={6} sx={{ minHeight: 450 }}>
          <AnalyticsCard
            chart={{
              name: "New & Returning Users Conversions Trend",
              dimension: [
                ApiDimension.DIMENSION_SESSION_START_BY_DAY,
                ApiDimension.DIMENSION_USER_TYPE,
              ],
              template: ApiChartTemplate.TREND,
              type: ApiChartType.GRAPH,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT],
                },
              ],
              displayOptions: {
                noFilters: true,
                filterDropdowns: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <AnalyticsCard
          chart={{
            name: "Top Interactions by User Type",
            dimension: [
              ApiDimension.DIMENSION_USER_TYPE,
              ApiDimension.DIMENSION_MICRO_CONVERSION,
            ],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [],
            orderBy: `${ApiDimension.DIMENSION_USER_TYPE}`,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT],
              },
            ],
            displayOptions: {
              noFilters: true,
              filterDropdowns: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
              rowsPerPage: 12,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default AudienceNewVsReturningTab;
