import { ApiACLRole, ApiClient, ApiProject } from "@incendium/api";
import { useUser } from "Hooks/useUser";
import React from "react";

type TAccessType = "admin" | "observer";

interface IAccessLevelProps extends React.HTMLAttributes<HTMLDivElement> {
  noAccess?: React.ReactNode;
  role?: ApiACLRole;
  write?: boolean;
  type?: TAccessType;
  client?: ApiClient;
  project?: ApiProject;
}

function AccessLevel({
  role,
  children,
  noAccess,
  write,
  type,
  client,
  project,
}: IAccessLevelProps) {
  const { hasPermission, isAdmin } = useUser();
  if (type && type === "admin" && !role) {
    return <>{isAdmin() ? children : noAccess ? noAccess : <></>}</>;
  }
  if (type && type === "observer" && !role) {
    return <>{!isAdmin() ? children : noAccess ? noAccess : <></>}</>;
  }

  if (!role) {
    return <>No Role Set In Access Level</>;
  }
  return (
    <>
      {hasPermission(role, write, client, project) &&
      (!type ||
        (type && type === "admin" && isAdmin()) ||
        (type && type === "observer" && !isAdmin())) ? (
        children
      ) : noAccess ? (
        noAccess
      ) : (
        <></>
      )}
    </>
  );
}

export default AccessLevel;
