/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiReservedPageTypes;
(function (ApiReservedPageTypes) {
    ApiReservedPageTypes["UNSPECIFIED"] = "RESERVED_PAGETYPE_UNSPECIFIED";
    ApiReservedPageTypes["CONTACT_US"] = "RESERVED_PAGETYPE_CONTACT_US";
    ApiReservedPageTypes["HOMEPAGE"] = "RESERVED_PAGETYPE_HOMEPAGE";
    ApiReservedPageTypes["BLOG"] = "RESERVED_PAGETYPE_BLOG";
    ApiReservedPageTypes["BLOG_CATEGORY"] = "RESERVED_PAGETYPE_BLOG_CATEGORY";
    ApiReservedPageTypes["CONTENT_MARKETING_CATEGORY"] = "RESERVED_PAGETYPE_CONTENT_MARKETING_CATEGORY";
    ApiReservedPageTypes["CONTENT_MARKETING_PAGE"] = "RESERVED_PAGETYPE_CONTENT_MARKETING_PAGE";
    ApiReservedPageTypes["PRODUCT_PAGE"] = "RESERVED_PAGETYPE_PRODUCT_PAGE";
    ApiReservedPageTypes["PRODUCT_CATEGORY"] = "RESERVED_PAGETYPE_PRODUCT_CATEGORY";
    ApiReservedPageTypes["SERVICE_PAGE"] = "RESERVED_PAGETYPE_SERVICE_PAGE";
    ApiReservedPageTypes["SERVICE_CATEGORY"] = "RESERVED_PAGETYPE_SERVICE_CATEGORY";
    ApiReservedPageTypes["JOB_PAGE"] = "RESERVED_PAGETYPE_JOB_PAGE";
    ApiReservedPageTypes["JOB_CATEGORY"] = "RESERVED_PAGETYPE_JOB_CATEGORY";
    ApiReservedPageTypes["CHECKOUT"] = "RESERVED_PAGETYPE_CHECKOUT";
})(ApiReservedPageTypes || (ApiReservedPageTypes = {}));
export function ApiReservedPageTypesFromJSON(json) {
    return ApiReservedPageTypesFromJSONTyped(json, false);
}
export function ApiReservedPageTypesFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiReservedPageTypesToJSON(value) {
    return value;
}
