import {
  ApiACLRole,
  ApiAuthResponse,
  ApiClient,
  ApiProject,
  ApiUser,
} from "@incendium/api";
import { useCallback, useMemo } from "react";
import { useSelectedProject } from "./useSelectedProject";
import { useSelector } from "react-redux";
import { IStoreState } from "Interfaces";

export const useUser = (t?: ApiAuthResponse | null) => {
  const user = useSelector((state: IStoreState) => state.auth.user);
  const { selectedProject, selectedClient } = useSelectedProject();

  const isRole = useCallback(
    (role: ApiACLRole, clientId?: number, projectId?: number) =>
      ((user as ApiUser).permissions || []).findIndex(
        (p) =>
          p.role === role &&
          (!clientId || p.clientId === clientId) &&
          (!projectId || p.projectId === projectId)
      ) >= 0,
    [user]
  );

  const isAdmin = useCallback(() => {
    if (!user) {
      return false;
    }
    return ((user as ApiUser).permissions || []).some((p) =>
      p.role?.toLowerCase().includes("admin")
    );
  }, [user]);

  const hasPermission = useCallback(
    (
      role: ApiACLRole,
      write?: boolean,
      client?: ApiClient,
      project?: ApiProject
    ) => {
      if (!user) {
        return false;
      }

      if (isRole(ApiACLRole.SUPER_ADMIN)) {
        return true;
      }

      const selClient = client || selectedClient;
      const selProject = project || selectedProject;

      switch (role) {
        case ApiACLRole.DOMAIN_ADMIN:
          return isRole(ApiACLRole.DOMAIN_ADMIN);
        case ApiACLRole.CLIENT_ADMIN:
          return (
            isRole(ApiACLRole.DOMAIN_ADMIN) ||
            isRole(ApiACLRole.CLIENT_ADMIN, selClient?.id)
          );
        case ApiACLRole.CLIENT_OBSERVER:
          return (
            isRole(ApiACLRole.DOMAIN_ADMIN) ||
            isRole(ApiACLRole.CLIENT_ADMIN, selClient?.id) ||
            isRole(ApiACLRole.CLIENT_OBSERVER, selClient?.id)
          );
        case ApiACLRole.PROJECT_ADMIN:
          return (
            isRole(ApiACLRole.DOMAIN_ADMIN) ||
            isRole(ApiACLRole.CLIENT_ADMIN, selClient?.id) ||
            (!write && isRole(ApiACLRole.CLIENT_OBSERVER, selClient?.id)) ||
            isRole(ApiACLRole.PROJECT_ADMIN, undefined, selProject?.id)
          );
        case ApiACLRole.PROJECT_OBSERVER:
          return (
            isRole(ApiACLRole.DOMAIN_ADMIN) ||
            isRole(ApiACLRole.CLIENT_ADMIN, selClient?.id) ||
            isRole(ApiACLRole.CLIENT_OBSERVER, selClient?.id) ||
            isRole(ApiACLRole.PROJECT_ADMIN, undefined, selProject?.id) ||
            isRole(ApiACLRole.PROJECT_OBSERVER, undefined, selProject?.id)
          );
        case ApiACLRole.ANALYTICS_ADMIN:
          return (
            isRole(ApiACLRole.DOMAIN_ADMIN) ||
            isRole(ApiACLRole.CLIENT_ADMIN, selClient?.id) ||
            (!write && isRole(ApiACLRole.CLIENT_OBSERVER, selClient?.id)) ||
            isRole(ApiACLRole.PROJECT_ADMIN, undefined, selProject?.id) ||
            (!write &&
              isRole(ApiACLRole.PROJECT_OBSERVER, undefined, selProject?.id)) ||
            isRole(ApiACLRole.ANALYTICS_ADMIN, undefined, selProject?.id)
          );
        case ApiACLRole.ANALYTICS_OBSERVER:
          return (
            isRole(ApiACLRole.DOMAIN_ADMIN) ||
            isRole(ApiACLRole.CLIENT_ADMIN, selClient?.id) ||
            isRole(ApiACLRole.CLIENT_OBSERVER, selClient?.id) ||
            isRole(ApiACLRole.PROJECT_ADMIN, undefined, selProject?.id) ||
            isRole(ApiACLRole.PROJECT_OBSERVER, undefined, selProject?.id) ||
            isRole(ApiACLRole.ANALYTICS_ADMIN, undefined, selProject?.id) ||
            isRole(ApiACLRole.ANALYTICS_OBSERVER, undefined, selProject?.id)
          );

        default:
          return false;
      }
    },
    [isRole, selectedProject, selectedClient, user]
  );

  return useMemo(
    () => ({
      user: user as ApiUser,
      hasPermission,
      isAdmin,
    }),
    [user, hasPermission, isAdmin]
  );
};
