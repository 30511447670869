/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * - XXX: XXX = 999; //Changed to 0 because proto3 requires the default to be 0
 *  - ALL: XTS = 963; //Codes specifically reserved for testing purposes
 *
 * Stands for Albania, not all ;-)
 * @export
 * @enum {string}
 */
export var ApiCurrency;
(function (ApiCurrency) {
    ApiCurrency["XXX"] = "XXX";
    ApiCurrency["ALL"] = "ALL";
    ApiCurrency["DZD"] = "DZD";
    ApiCurrency["ARS"] = "ARS";
    ApiCurrency["AUD"] = "AUD";
    ApiCurrency["BSD"] = "BSD";
    ApiCurrency["BHD"] = "BHD";
    ApiCurrency["BDT"] = "BDT";
    ApiCurrency["AMD"] = "AMD";
    ApiCurrency["BBD"] = "BBD";
    ApiCurrency["BMD"] = "BMD";
    ApiCurrency["BTN"] = "BTN";
    ApiCurrency["BOB"] = "BOB";
    ApiCurrency["BWP"] = "BWP";
    ApiCurrency["BZD"] = "BZD";
    ApiCurrency["SBD"] = "SBD";
    ApiCurrency["BND"] = "BND";
    ApiCurrency["MMK"] = "MMK";
    ApiCurrency["BIF"] = "BIF";
    ApiCurrency["KHR"] = "KHR";
    ApiCurrency["CAD"] = "CAD";
    ApiCurrency["CVE"] = "CVE";
    ApiCurrency["KYD"] = "KYD";
    ApiCurrency["LKR"] = "LKR";
    ApiCurrency["CLP"] = "CLP";
    ApiCurrency["CNY"] = "CNY";
    ApiCurrency["COP"] = "COP";
    ApiCurrency["KMF"] = "KMF";
    ApiCurrency["CRC"] = "CRC";
    ApiCurrency["HRK"] = "HRK";
    ApiCurrency["CUP"] = "CUP";
    ApiCurrency["CZK"] = "CZK";
    ApiCurrency["DKK"] = "DKK";
    ApiCurrency["DOP"] = "DOP";
    ApiCurrency["SVC"] = "SVC";
    ApiCurrency["ETB"] = "ETB";
    ApiCurrency["ERN"] = "ERN";
    ApiCurrency["FKP"] = "FKP";
    ApiCurrency["FJD"] = "FJD";
    ApiCurrency["DJF"] = "DJF";
    ApiCurrency["GMD"] = "GMD";
    ApiCurrency["GIP"] = "GIP";
    ApiCurrency["GTQ"] = "GTQ";
    ApiCurrency["GNF"] = "GNF";
    ApiCurrency["GYD"] = "GYD";
    ApiCurrency["HTG"] = "HTG";
    ApiCurrency["HNL"] = "HNL";
    ApiCurrency["HKD"] = "HKD";
    ApiCurrency["HUF"] = "HUF";
    ApiCurrency["ISK"] = "ISK";
    ApiCurrency["INR"] = "INR";
    ApiCurrency["IDR"] = "IDR";
    ApiCurrency["IRR"] = "IRR";
    ApiCurrency["IQD"] = "IQD";
    ApiCurrency["ILS"] = "ILS";
    ApiCurrency["JMD"] = "JMD";
    ApiCurrency["JPY"] = "JPY";
    ApiCurrency["KZT"] = "KZT";
    ApiCurrency["JOD"] = "JOD";
    ApiCurrency["KES"] = "KES";
    ApiCurrency["KPW"] = "KPW";
    ApiCurrency["KRW"] = "KRW";
    ApiCurrency["KWD"] = "KWD";
    ApiCurrency["KGS"] = "KGS";
    ApiCurrency["LAK"] = "LAK";
    ApiCurrency["LBP"] = "LBP";
    ApiCurrency["LSL"] = "LSL";
    ApiCurrency["LRD"] = "LRD";
    ApiCurrency["LYD"] = "LYD";
    ApiCurrency["LTL"] = "LTL";
    ApiCurrency["MOP"] = "MOP";
    ApiCurrency["MWK"] = "MWK";
    ApiCurrency["MYR"] = "MYR";
    ApiCurrency["MVR"] = "MVR";
    ApiCurrency["MRO"] = "MRO";
    ApiCurrency["MUR"] = "MUR";
    ApiCurrency["MXN"] = "MXN";
    ApiCurrency["MNT"] = "MNT";
    ApiCurrency["MDL"] = "MDL";
    ApiCurrency["MAD"] = "MAD";
    ApiCurrency["OMR"] = "OMR";
    ApiCurrency["NAD"] = "NAD";
    ApiCurrency["NPR"] = "NPR";
    ApiCurrency["ANG"] = "ANG";
    ApiCurrency["AWG"] = "AWG";
    ApiCurrency["VUV"] = "VUV";
    ApiCurrency["NZD"] = "NZD";
    ApiCurrency["NIO"] = "NIO";
    ApiCurrency["NGN"] = "NGN";
    ApiCurrency["NOK"] = "NOK";
    ApiCurrency["PKR"] = "PKR";
    ApiCurrency["PAB"] = "PAB";
    ApiCurrency["PGK"] = "PGK";
    ApiCurrency["PYG"] = "PYG";
    ApiCurrency["PEN"] = "PEN";
    ApiCurrency["PHP"] = "PHP";
    ApiCurrency["QAR"] = "QAR";
    ApiCurrency["RUB"] = "RUB";
    ApiCurrency["RWF"] = "RWF";
    ApiCurrency["SHP"] = "SHP";
    ApiCurrency["STD"] = "STD";
    ApiCurrency["SAR"] = "SAR";
    ApiCurrency["SCR"] = "SCR";
    ApiCurrency["SLL"] = "SLL";
    ApiCurrency["SGD"] = "SGD";
    ApiCurrency["VND"] = "VND";
    ApiCurrency["SOS"] = "SOS";
    ApiCurrency["ZAR"] = "ZAR";
    ApiCurrency["SSP"] = "SSP";
    ApiCurrency["SZL"] = "SZL";
    ApiCurrency["SEK"] = "SEK";
    ApiCurrency["CHF"] = "CHF";
    ApiCurrency["SYP"] = "SYP";
    ApiCurrency["THB"] = "THB";
    ApiCurrency["TOP"] = "TOP";
    ApiCurrency["TTD"] = "TTD";
    ApiCurrency["AED"] = "AED";
    ApiCurrency["TND"] = "TND";
    ApiCurrency["UGX"] = "UGX";
    ApiCurrency["MKD"] = "MKD";
    ApiCurrency["EGP"] = "EGP";
    ApiCurrency["GBP"] = "GBP";
    ApiCurrency["TZS"] = "TZS";
    ApiCurrency["USD"] = "USD";
    ApiCurrency["UYU"] = "UYU";
    ApiCurrency["UZS"] = "UZS";
    ApiCurrency["WST"] = "WST";
    ApiCurrency["YER"] = "YER";
    ApiCurrency["TWD"] = "TWD";
    ApiCurrency["CUC"] = "CUC";
    ApiCurrency["ZWL"] = "ZWL";
    ApiCurrency["TMT"] = "TMT";
    ApiCurrency["GHS"] = "GHS";
    ApiCurrency["VEF"] = "VEF";
    ApiCurrency["SDG"] = "SDG";
    ApiCurrency["UYI"] = "UYI";
    ApiCurrency["RSD"] = "RSD";
    ApiCurrency["MZN"] = "MZN";
    ApiCurrency["AZN"] = "AZN";
    ApiCurrency["RON"] = "RON";
    ApiCurrency["CHE"] = "CHE";
    ApiCurrency["CHW"] = "CHW";
    ApiCurrency["TRY"] = "TRY";
    ApiCurrency["XAF"] = "XAF";
    ApiCurrency["XCD"] = "XCD";
    ApiCurrency["XOF"] = "XOF";
    ApiCurrency["XPF"] = "XPF";
    ApiCurrency["XBA"] = "XBA";
    ApiCurrency["XBB"] = "XBB";
    ApiCurrency["XBC"] = "XBC";
    ApiCurrency["XBD"] = "XBD";
    ApiCurrency["XAU"] = "XAU";
    ApiCurrency["XDR"] = "XDR";
    ApiCurrency["XAG"] = "XAG";
    ApiCurrency["XPT"] = "XPT";
    ApiCurrency["XPD"] = "XPD";
    ApiCurrency["XUA"] = "XUA";
    ApiCurrency["ZMW"] = "ZMW";
    ApiCurrency["SRD"] = "SRD";
    ApiCurrency["MGA"] = "MGA";
    ApiCurrency["COU"] = "COU";
    ApiCurrency["AFN"] = "AFN";
    ApiCurrency["TJS"] = "TJS";
    ApiCurrency["AOA"] = "AOA";
    ApiCurrency["BYR"] = "BYR";
    ApiCurrency["BGN"] = "BGN";
    ApiCurrency["CDF"] = "CDF";
    ApiCurrency["BAM"] = "BAM";
    ApiCurrency["EUR"] = "EUR";
    ApiCurrency["MXV"] = "MXV";
    ApiCurrency["UAH"] = "UAH";
    ApiCurrency["GEL"] = "GEL";
    ApiCurrency["BOV"] = "BOV";
    ApiCurrency["PLN"] = "PLN";
    ApiCurrency["BRL"] = "BRL";
    ApiCurrency["CLF"] = "CLF";
    ApiCurrency["XSU"] = "XSU";
    ApiCurrency["USN"] = "USN";
})(ApiCurrency || (ApiCurrency = {}));
export function ApiCurrencyFromJSON(json) {
    return ApiCurrencyFromJSONTyped(json, false);
}
export function ApiCurrencyFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiCurrencyToJSON(value) {
    return value;
}
