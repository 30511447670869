import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import { sortAlpha } from "Helpers/arrays";
import { formatEnumVal } from "Helpers/enumToText";
import { useSelectedProject } from "Hooks";
import { useCSVRules } from "Hooks/useCSVRules";
import { GenericDialoagProps } from "Interfaces";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

function UploaderRulesDialog({ open, setOpen }: GenericDialoagProps) {
  const { CSVRules } = useCSVRules();
  const [rule, setRule] = useState<string | null>(null);
  const history = useHistory();
  const { selectedProject } = useSelectedProject();

  const go = useCallback(() => {
    if (!selectedProject) {
      return;
    }
    history.push(
      `/clients/${selectedProject.clientId}/projects/${selectedProject?.id}/connect/data-manager/${rule}`
    );
  }, [selectedProject, history, rule]);

  return (
    <CustomDialog
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth="sm"
      open={open}
    >
      <DialogTitle>What type of data would you like to upload?</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          select
          label="data type"
          value={rule}
          onChange={(e) => setRule(e.target.value)}
        >
          {sortAlpha([...CSVRules], "id").map((r) => (
            <MenuItem key={r.id} value={r.id}>
              {formatEnumVal(r.id || "")} - ({r.description})
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="secondary">
          Cancel
        </Button>
        <Button
          disabled={!rule}
          variant="contained"
          color="primary"
          type="submit"
          onClick={go}
        >
          Get Started
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}

export default UploaderRulesDialog;
