import { Box, Stack, Typography, useTheme } from "@mui/material";
import { formatPercentage, percentageOf } from "Helpers/percentage";
import { useMemo } from "react";
import IncreaseDecrease, {
  incDec,
} from "Components/IncreaseDecrease/IncreaseDecrease";
import { formatNumber } from "Helpers/numbers";
import { ApiMetric } from "@incendium/api";
import { formatMetric } from "features/analytics";
import { PillInner, PillWrapper } from "Components/ComparisonPill/PillBar";

interface IComparisonPillProps {
  title: string;
  value: number;
  comparisonValue: number;
  isPercentage?: boolean;
  metric?: ApiMetric;
}
function ComparisonPill({
  title,
  value,
  comparisonValue,
  isPercentage,
  metric,
}: IComparisonPillProps) {
  const theme = useTheme();
  const currentPercentage = useMemo(() => {
    if (comparisonValue === 0) {
      return value === 0 ? 50 : 100;
    }

    return percentageOf(value, value + comparisonValue);
  }, [value, comparisonValue]);

  return (
    <Box py={0.75}>
      <Stack mb={0.5} direction="row" spacing={1}>
        <Typography variant="subtitle2">{title}</Typography>
        <IncreaseDecrease
          size="inherit"
          direction={incDec(comparisonValue, value, isPercentage).direction}
          value={incDec(comparisonValue, value, isPercentage).value}
          useThemeColours
        />
      </Stack>
      <PillWrapper>
        <PillInner percentage={currentPercentage} />
      </PillWrapper>
      <Stack direction={"row"} justifyContent="space-between">
        <Typography
          variant="caption"
          sx={{ color: theme.palette.charts[1] }}
          fontWeight={600}
        >
          {metric
            ? formatMetric(metric, value)
            : isPercentage
            ? formatPercentage(value)
            : formatNumber(value)}
        </Typography>
        <Typography
          variant="caption"
          sx={{ color: theme.palette.charts[3] }}
          fontWeight={600}
        >
          {metric
            ? formatMetric(metric, comparisonValue)
            : isPercentage
            ? formatPercentage(comparisonValue)
            : formatNumber(comparisonValue)}
        </Typography>
      </Stack>
    </Box>
  );
}

export default ComparisonPill;
