import { Box, styled } from "@mui/system";

export const StyledSearchResults = styled(Box)(({ theme }) => ({
  backgroundColor: "#e1e8fe",
  borderRadius: 4,
  border: `1px solid rgba(201, 211, 244, 0.5)`,
  padding: theme.spacing(1.5),
  overflowWrap: "break-word",
  position: "relative",
  minHeight: theme.spacing(10),
  width: "100%",
}));
