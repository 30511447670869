/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiChannelGroupPayloadFromJSON, ApiChannelGroupPayloadToJSON, } from './ApiChannelGroupPayload';
export function ApiChannelPayloadFromJSON(json) {
    return ApiChannelPayloadFromJSONTyped(json, false);
}
export function ApiChannelPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'groups': !exists(json, 'groups') ? undefined : (json['groups'].map(ApiChannelGroupPayloadFromJSON)),
        'isPaidChannel': !exists(json, 'isPaidChannel') ? undefined : json['isPaidChannel'],
    };
}
export function ApiChannelPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'groups': value.groups === undefined ? undefined : (value.groups.map(ApiChannelGroupPayloadToJSON)),
        'isPaidChannel': value.isPaidChannel,
    };
}
