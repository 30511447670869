import { ApiChartTemplate, ApiChartType, ApiDimension } from "@incendium/api";
import { TChartData } from "Interfaces";
import { IChart } from "Interfaces";
import { createContext, useContext, useMemo, useState } from "react";
import { useUpdateEffect } from "react-use";
import { hasTrendDimensions } from "features/analytics";
import produce from "immer";

type TGridDimensions = {
  h: number;
  w: number;
} | null;

type TReportBuilderContext = {
  chart: IChart;
  setChart: React.Dispatch<React.SetStateAction<IChart>>;
  chartData: TChartData[];
  setChartData: React.Dispatch<React.SetStateAction<TChartData[]>>;
  metrics: string[];
  showLib: boolean;
  setShowLib: React.Dispatch<React.SetStateAction<boolean>>;
  gridDimensions: TGridDimensions;
  setGridDimensions: (v: TGridDimensions) => void;
};

export const defaultChart = {
  name: "",
  type: ApiChartType.TABLE,
  yAxisKeys: [],
  dimension: [],
  attributes: [],
  orderBy: "",
};

export const ReportBuilderContext = createContext<TReportBuilderContext>({
  chart: defaultChart,
  setChart: () => {},
  chartData: [],
  setChartData: () => {},
  metrics: [],
  showLib: false,
  setShowLib: () => {},
  gridDimensions: null,
  setGridDimensions: () => {},
});
export const useReportBuilderContext = () => useContext(ReportBuilderContext);

const ReportBuilderProvider = ({ children }: { children: React.ReactNode }) => {
  const [chart, setChart] = useState<IChart>(defaultChart);
  const [chartData, setChartData] = useState<TChartData[]>([]);
  const [showLib, setShowLib] = useState<boolean>(false);
  const [gridDimensions, setGridDimensions] = useState<TGridDimensions>(null);

  useUpdateEffect(() => {
    if (
      hasTrendDimensions((chart.dimension || []) as ApiDimension[]) &&
      chart.type !== ApiChartType.STAT_CARD &&
      chart.template !== ApiChartTemplate.TREND
    ) {
      setChart(
        produce(chart, (draft) => {
          draft.template = ApiChartTemplate.TREND;
        })
      );
    }
    if (
      !hasTrendDimensions((chart.dimension || []) as ApiDimension[]) &&
      chart.template === ApiChartTemplate.TREND
    ) {
      setChart(
        produce(chart, (draft) => {
          draft.template = ApiChartTemplate.SNAPSHOT;
        })
      );
    }
  }, [chart.dimension]);

  const metrics = useMemo(() => {
    return chart.yAxisKeys.reduce((agg: any, y) => {
      if (typeof y === "string") {
        return [...agg, y];
      }
      return [...agg, ...(y.fields || [])];
    }, []);
  }, [chart.yAxisKeys]);

  const state = useMemo(
    () => ({
      chart,
      setChart,
      chartData,
      setChartData,
      metrics,
      showLib,
      setShowLib,
      gridDimensions,
      setGridDimensions,
    }),
    [
      chart,
      setChart,
      chartData,
      setChartData,
      metrics,
      showLib,
      setShowLib,
      gridDimensions,
      setGridDimensions,
    ]
  );

  return (
    <ReportBuilderContext.Provider value={state}>
      {children}
    </ReportBuilderContext.Provider>
  );
};

export default ReportBuilderProvider;
