/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiDomainFromJSON, ApiDomainToJSON, } from './ApiDomain';
import { ApiDomainStatusFromJSON, ApiDomainStatusToJSON, } from './ApiDomainStatus';
import { ApiDomainTypeFromJSON, ApiDomainTypeToJSON, } from './ApiDomainType';
import { ApiLandingPathFromJSON, ApiLandingPathToJSON, } from './ApiLandingPath';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiLandingDomainFromJSON(json) {
    return ApiLandingDomainFromJSONTyped(json, false);
}
export function ApiLandingDomainFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'readyAt': !exists(json, 'readyAt') ? undefined : (new Date(json['readyAt'])),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'subdomain': !exists(json, 'subdomain') ? undefined : json['subdomain'],
        'status': !exists(json, 'status') ? undefined : ApiDomainStatusFromJSON(json['status']),
        'cnameName': !exists(json, 'cnameName') ? undefined : json['cnameName'],
        'cnameValue': !exists(json, 'cnameValue') ? undefined : json['cnameValue'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'certificateCname': !exists(json, 'certificateCname') ? undefined : json['certificateCname'],
        'certificateCnameValue': !exists(json, 'certificateCnameValue') ? undefined : json['certificateCnameValue'],
        'bucketName': !exists(json, 'bucketName') ? undefined : json['bucketName'],
        'certificateApproved': !exists(json, 'certificateApproved') ? undefined : (new Date(json['certificateApproved'])),
        'domainApproved': !exists(json, 'domainApproved') ? undefined : (new Date(json['domainApproved'])),
        'paths': !exists(json, 'paths') ? undefined : (json['paths'].map(ApiLandingPathFromJSON)),
        'type': !exists(json, 'type') ? undefined : ApiDomainTypeFromJSON(json['type']),
        'domainId': !exists(json, 'domainId') ? undefined : json['domainId'],
        'domain': !exists(json, 'domain') ? undefined : ApiDomainFromJSON(json['domain']),
    };
}
export function ApiLandingDomainToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'readyAt': value.readyAt === undefined ? undefined : (value.readyAt.toISOString()),
        'projectId': value.projectId,
        'name': value.name,
        'description': value.description,
        'subdomain': value.subdomain,
        'status': ApiDomainStatusToJSON(value.status),
        'cnameName': value.cnameName,
        'cnameValue': value.cnameValue,
        'project': ApiProjectToJSON(value.project),
        'certificateCname': value.certificateCname,
        'certificateCnameValue': value.certificateCnameValue,
        'bucketName': value.bucketName,
        'certificateApproved': value.certificateApproved === undefined ? undefined : (value.certificateApproved.toISOString()),
        'domainApproved': value.domainApproved === undefined ? undefined : (value.domainApproved.toISOString()),
        'paths': value.paths === undefined ? undefined : (value.paths.map(ApiLandingPathToJSON)),
        'type': ApiDomainTypeToJSON(value.type),
        'domainId': value.domainId,
        'domain': ApiDomainToJSON(value.domain),
    };
}
