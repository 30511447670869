import { ApiMetric, ApiYAxisChartType } from "@incendium/api";
import {
  BarChart,
  Delete,
  Eject,
  ShowChart,
  StackedBarChart,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import produce from "immer";
import { IChart } from "Interfaces";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IGroupedMetrics } from "./ChartBuilderYAxis";
import { metricOriginalNameToEnum, metricToName } from "features/analytics";

function ChartBuilderMetric({
  chart,
  setChart,
  metrics,
  index,
  yKey,
  noControls,
  noRemove,
}: {
  chart: IChart;
  setChart: React.Dispatch<React.SetStateAction<IChart>>;
  metrics: IGroupedMetrics[];
  index: number;
  yKey: string;
  noControls?: boolean;
  noRemove?: boolean;
}) {
  const [selectedOption, setSelectedOption] = useState<
    { key: string; value: string } | undefined
  >(undefined);
  const yAxisIdx: any = useMemo(() => {
    return chart.yAxisKeys.findIndex((y: any) => y.key === yKey);
  }, [chart, yKey]);

  // todo: type better
  const yAxis: any = useMemo(() => {
    if (yAxisIdx < 0) {
      return null;
    }

    return chart.yAxisKeys[yAxisIdx];
  }, [yAxisIdx, chart.yAxisKeys]);

  const lineType = useMemo(() => {
    if (!yAxis) {
      return ApiYAxisChartType.LINE;
    }

    if (
      yAxis?.stackId &&
      yAxis?.chart[yAxis.fields[index]] === ApiYAxisChartType.BAR
    ) {
      return yAxis?.chart[yAxis.fields[index]] + "-stacked";
    }
    return yAxis?.chart && yAxis?.chart[yAxis.fields[index]]
      ? yAxis?.chart[yAxis.fields[index]]
      : ApiYAxisChartType.LINE;
  }, [yAxis, index]);

  const parsedMetrics = useMemo(() => {
    return metrics
      .map((v) => {
        return v.metrics.map((m) => ({
          key: v.title,
          value: m,
        }));
      })
      .flat();
  }, [metrics]);

  const filteredMetrics = useMemo(() => {
    return parsedMetrics.filter((m) => !(yAxis.fields || []).includes(m));
  }, [yAxis, parsedMetrics]);

  useEffect(() => {
    const idx = parsedMetrics.findIndex(
      (f) =>
        f.value === yAxis?.fields[index] ||
        f.value === metricOriginalNameToEnum(yAxis?.fields[index])
    );

    if (idx < 0) {
      return;
    }
    setSelectedOption(parsedMetrics[idx]);
  }, [yAxis, parsedMetrics, index]);

  const onRemove = useCallback(() => {
    setSelectedOption({ key: "", value: "" });
    setChart(
      produce(chart, (draft) => {
        if (yAxisIdx >= 0) {
          (draft.yAxisKeys[yAxisIdx] as any).fields.splice(index, 1);

          return;
        }
      })
    );
  }, [chart, index, setChart, yAxisIdx]);

  const onChange = useCallback(
    (e, v) => {
      setChart(
        produce(chart, (draft) => {
          if (yAxisIdx >= 0) {
            (draft.yAxisKeys[yAxisIdx] as any).fields[index] = v?.value;
            return;
          }
          draft.yAxisKeys.push({
            key: "l",
            fields: [v?.value || ""],
          });
        })
      );
    },
    [chart, index, setChart, yAxisIdx]
  );

  return (
    <Box sx={{ display: "flex", flex: 1 }}>
      {noRemove || (
        <Box mr={2}>
          <IconButton size="small" onClick={onRemove}>
            <Delete />
          </IconButton>
        </Box>
      )}

      <Autocomplete
        id="y"
        size="small"
        onChange={onChange}
        fullWidth
        options={filteredMetrics}
        groupBy={(option) => option.key}
        getOptionLabel={(o) => metricToName(o.value as ApiMetric)}
        value={selectedOption || { key: "", value: "" }}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option.value === value.value}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Metric"
            variant="outlined"
            size="small"
          />
        )}
      />

      {noControls || (
        <Box ml={2}>
          <ToggleButtonGroup
            size="small"
            value={lineType}
            exclusive
            color="secondary"
            onChange={(e, v: string | null) => {
              if (!v) {
                return;
              }

              const value = (v || "").replace("-stacked", "");
              setChart(
                produce(chart, (draft) => {
                  if (yAxisIdx >= 0) {
                    if (!(draft.yAxisKeys[yAxisIdx] as any).chart) {
                      (draft.yAxisKeys[yAxisIdx] as any).chart = {};
                    }
                  }
                  (draft.yAxisKeys[yAxisIdx] as any).chart[
                    yAxis.fields[index]
                  ] = value;
                  if ((v || "").includes("-stacked")) {
                    (draft.yAxisKeys[yAxisIdx] as any).stackId = yKey;
                  } else {
                    (draft.yAxisKeys[yAxisIdx] as any).stackId = undefined;
                  }
                })
              );
            }}
          >
            <ToggleButton value={ApiYAxisChartType.LINE}>
              <ShowChart />
            </ToggleButton>
            <ToggleButton value={ApiYAxisChartType.AREA}>
              <Eject />
            </ToggleButton>
            <ToggleButton value={ApiYAxisChartType.BAR}>
              <BarChart />
            </ToggleButton>
            <ToggleButton value={ApiYAxisChartType.BAR + "-stacked"}>
              <StackedBarChart />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}
    </Box>
  );
}

export default ChartBuilderMetric;
