import { Portal, Typography } from "@mui/material";
import AudienceAnalyticsProvider, {
  useAudienceAnalyticsContext,
} from "Providers/AudienceAnalyticsProvider";
import { TabWrapper } from "Components/UI/TabContainer";
import { Redirect, useParams } from "react-router-dom";
import { useCallback, useState } from "react";
import { CenterPage } from "Components/CenterPage/CenterPage";
import { enumToArray } from "Helpers/enumToText";
import TabbedAnalysesPage from "Pages/Analyse/TabbedAnalysesPage";
import AudienceNewAndReturningReportPage from "./AudienceNewAndReturningReportPage";
import { AnalyticsFilterDropdown } from "features/analytics";
import { ApiDimension } from "@incendium/api";
import { PageSubAction } from "consts";

enum Insight {
  new_lead = "New User Acquisition",
  returning_lead = "Returning User Acquisition",
  new_customer = "New Customer Acquisition",
  returning_cutomer = "Returning Customer Acquisition",
}

function AudienceNewAndReturningPageInner() {
  const { setCurrentAudience } = useAudienceAnalyticsContext();
  const { insight } = useParams<{ insight: string }>();
  const [activeInsight, setActiveInsight] = useState(insight);

  const renderSwitch = useCallback(() => {
    switch (activeInsight) {
      case Insight.new_lead.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <AudienceNewAndReturningReportPage userType="New Lead" />
          </TabWrapper>
        );
      case Insight.returning_lead.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <AudienceNewAndReturningReportPage userType="Returning Lead" />
          </TabWrapper>
        );
      case Insight.new_customer.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <AudienceNewAndReturningReportPage userType="New Customer" />
          </TabWrapper>
        );
      case Insight.returning_cutomer.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <AudienceNewAndReturningReportPage userType="Returning Customer" />
          </TabWrapper>
        );
      default:
        return (
          <TabWrapper>
            <CenterPage calcHeight>
              <Typography variant="subtitle1">Not Implemented</Typography>
            </CenterPage>
          </TabWrapper>
        );
    }
  }, [activeInsight]);

  if (!insight || insight === "") {
    return (
      <Redirect
        to={`./audience-insights-new-and-returning/${Insight.new_lead
          .toLowerCase()
          .replace(" ", "_")}`}
      />
    );
  }

  return (
    <>
      <Portal container={() => document.getElementById(PageSubAction)}>
        <AnalyticsFilterDropdown
          dimension={ApiDimension.DIMENSION_LEAD_AUDIENCE}
          defaultIndex={-1}
          maxWidth={250}
          onChange={(v) => {
            setCurrentAudience(v);
          }}
        />
      </Portal>
      <TabbedAnalysesPage
        title="New & Returning"
        activeTab={activeInsight}
        setActiveTab={setActiveInsight}
        tabs={enumToArray(Insight)}
        renderSwitch={renderSwitch}
      />
    </>
  );
}

function AudienceNewAndReturningPage() {
  return (
    <AudienceAnalyticsProvider>
      <AudienceNewAndReturningPageInner />
    </AudienceAnalyticsProvider>
  );
}

export default AudienceNewAndReturningPage;
