import {
  ApiDomain,
  ApiLocation,
  ApiLocationItem,
  ApiLocationPageTagger,
  LocationItemLocationItemCondition,
  LocationItemLocationItemType,
} from "@incendium/api";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { RuleTypes } from "config";
import { formatEnumVal } from "Helpers/enumToText";
import produce from "immer";
import { locationService } from "Apis";
import { useEffect, useMemo, useState } from "react";
import { useSelectedProject } from "Hooks";
import { useUpdateEffect } from "react-use";
import { AnimatePresence, motion } from "framer-motion";
import { useDomains } from "Hooks/useDomains";

const Rule = ({
  rule,
  onChange,
  onDelete,
  tags,
  domains,
}: {
  rule: ApiLocationItem;
  onChange: (rule: ApiLocationItem) => void;
  onDelete: () => void;
  tags: ApiLocationPageTagger[];
  domains: ApiDomain[];
}) => {
  const { condition, value, key, pageTaggerId } = rule;

  const changeCondition = (c: string) => {
    onChange({
      ...rule,
      condition: c as any,
    });
  };
  const changeValue = (val: string) => {
    onChange({ ...rule, value: val });
  };
  const changeKey = (val: string) => {
    onChange({ ...rule, key: val });
  };
  const changePageDataId = (val: string) => {
    onChange({ ...rule, pageTaggerId: parseInt(val) });
  };

  useUpdateEffect(() => {
    onChange(
      produce(rule, (draft) => {
        draft.condition = undefined;
        draft.key = undefined;
        draft.value = undefined;
      })
    );
  }, [rule.type]);

  const availableTypes = useMemo(() => {
    return RuleTypes.filter((t) =>
      rule.type === LocationItemLocationItemType.DOMAIN
        ? ![
            LocationItemLocationItemCondition.PAGE_DATA,
            LocationItemLocationItemCondition.REPEATED_CHARACTER,
          ].includes(t)
        : true
    );
  }, [rule.type]);

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={2.5}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="type">Rule Type</InputLabel>
            <Select
              value={rule.type}
              labelId="type"
              onChange={(e) =>
                onChange(
                  produce(rule, (draft) => {
                    const t = e.target.value as LocationItemLocationItemType;
                    draft.type = t;
                    if (t === LocationItemLocationItemType.PAGEDATA) {
                      draft.condition =
                        LocationItemLocationItemCondition.PAGE_DATA;
                    }
                  })
                )
              }
              label="Rule Type"
            >
              <MenuItem value={LocationItemLocationItemType.PATH}>
                Path
              </MenuItem>
              <MenuItem value={LocationItemLocationItemType.DOMAIN}>
                Domain
              </MenuItem>
              <MenuItem value={LocationItemLocationItemType.PAGEDATA}>
                Page Data
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {rule.type !== LocationItemLocationItemType.PAGEDATA && (
          <Grid item xs={2.5}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="rule">select</InputLabel>
              <Select
                value={condition}
                labelId="rule"
                onChange={(e) => changeCondition(e.target.value as string)}
                label="select"
              >
                {availableTypes.map((rule) => {
                  return (
                    <MenuItem key={rule} value={rule}>
                      {formatEnumVal(rule)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}
        {condition === LocationItemLocationItemCondition.REPEATED_CHARACTER && (
          <Grid item xs={3}>
            <TextField
              fullWidth
              value={key || ""}
              label="Repeated Character"
              variant="outlined"
              onChange={(e) => changeKey(e.target.value)}
            />
          </Grid>
        )}
        {condition === LocationItemLocationItemCondition.PAGE_DATA && (
          <Grid item xs={9.5}>
            <TextField
              fullWidth
              value={pageTaggerId || ""}
              label="Page Data?"
              variant="outlined"
              select
              onChange={(e) => changePageDataId(e.target.value)}
            >
              {tags.map((tag) => (
                <MenuItem key={tag.id} value={tag.id}>
                  {tag.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
        <Grid
          item
          xs={
            condition === LocationItemLocationItemCondition.REPEATED_CHARACTER
              ? 5
              : condition === LocationItemLocationItemCondition.PAGE_DATA
              ? 12
              : 7
          }
        >
          {rule.type === LocationItemLocationItemType.DOMAIN ? (
            <Stack direction={"row"}>
              <Autocomplete
                freeSolo
                autoSelect
                fullWidth
                size="medium"
                onChange={(e, value) => {
                  changeValue(value || "");
                }}
                value={value}
                options={domains.map((d) => d.domain || "")}
                renderInput={(params) => (
                  <TextField {...params} value={value} variant="outlined" />
                )}
              />
              <IconButton onClick={onDelete} size="large">
                <Delete color="secondary" />
              </IconButton>
            </Stack>
          ) : (
            <TextField
              fullWidth
              value={value}
              label={
                condition ===
                LocationItemLocationItemCondition.REPEATED_CHARACTER
                  ? "Number Of Times"
                  : condition === LocationItemLocationItemCondition.PAGE_DATA
                  ? "Text"
                  : "section of URL"
              }
              variant="outlined"
              type={
                condition ===
                LocationItemLocationItemCondition.REPEATED_CHARACTER
                  ? "number"
                  : "text"
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onDelete} size="large">
                      <Delete color="secondary" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => changeValue(e.target.value)}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const LocationRules = ({
  rules,
  onChange,
  location,
}: {
  rules: ApiLocationItem[];
  onChange: (rules: ApiLocationItem[]) => void;
  location: ApiLocation;
}) => {
  const { selectedProject } = useSelectedProject();
  const [tags, setTags] = useState<ApiLocationPageTagger[]>([]);
  const [tagsLoaded, setTagsLoaded] = useState(false);
  const { domains } = useDomains();

  useEffect(() => {
    if (!selectedProject?.id || !location.id || tags.length > 0 || tagsLoaded) {
      return;
    }
    const loadTags = async () => {
      const res = await locationService.locationServiceListLocationPageTaggers({
        locationId: location.id as number,
        projectId: selectedProject?.id as number,
      });
      setTags(res.results || []);
      setTagsLoaded(true);
    };

    loadTags();
  }, [location.id, tags, selectedProject, tagsLoaded]);

  const addNew = () => {
    onChange([
      ...rules,
      {
        value: "",
        condition: RuleTypes[0],
        type: LocationItemLocationItemType.PATH,
      },
    ]);
  };

  const onChangeIndex = (i: number) => (rule: ApiLocationItem) => {
    onChange(
      produce(rules, (draft) => {
        draft[i] = rule;
      })
    );
  };
  const handleDelete = (i: number) => () => {
    onChange(
      produce(rules, (draft) => {
        draft.splice(i, 1);
      })
    );
  };

  const listVariants = {
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
      },
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        when: "beforeChildren",
      },
    },
  };

  const ruleVariants = {
    hidden: { x: -50, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  return (
    <AnimatePresence>
      <Grid container spacing={3}>
        <Stack
          component={motion.div}
          variants={listVariants}
          initial="hidden"
          animate="visible"
          spacing={3}
          sx={{ width: "100%" }}
        >
          {rules.map((rule, i) => {
            return (
              <motion.div
                key={rule.id || (rule.condition as string) + i}
                variants={ruleVariants}
              >
                <Rule
                  onDelete={handleDelete(i)}
                  rule={rule}
                  onChange={onChangeIndex(i)}
                  tags={tags}
                  domains={domains}
                />
              </motion.div>
            );
          })}
        </Stack>

        <Grid container justifyContent="flex-end" xs={12} item>
          <Grid item>
            <Button
              disableElevation
              variant="contained"
              size="small"
              color="secondary"
              onClick={addNew}
            >
              {rules.length === 0 ? "add a rule" : "add another rule"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </AnimatePresence>
  );
};

export default LocationRules;
