import { useSnackbar } from "notistack";
import { useCallback } from "react";

export const useNotification = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showSuccessNotification = useCallback((msg: string) => {
    return enqueueSnackbar(msg, {
      variant: "success",
      autoHideDuration: 2000,
      anchorOrigin: { horizontal: "right", vertical: "top" },
    });
  }, []);

  const showErrorNotification = useCallback((msg: string) => {
    return enqueueSnackbar(msg, {
      variant: "error",
      autoHideDuration: 2000,
      anchorOrigin: { horizontal: "right", vertical: "top" },
    });
  }, []);

  const showInfoNotification = useCallback((msg: string) => {
    return enqueueSnackbar(msg, {
      variant: "info",
      autoHideDuration: 2000,
      anchorOrigin: { horizontal: "right", vertical: "top" },
    });
  }, []);

  return {
    showErrorNotification,
    showSuccessNotification,
    showInfoNotification,
  };
};
