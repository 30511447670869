/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiClientFromJSON, ApiClientToJSON, } from './ApiClient';
export function ApiOAuthTokenFromJSON(json) {
    return ApiOAuthTokenFromJSONTyped(json, false);
}
export function ApiOAuthTokenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'client': !exists(json, 'client') ? undefined : ApiClientFromJSON(json['client']),
        'apiToken': !exists(json, 'apiToken') ? undefined : json['apiToken'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'tokenExpires': !exists(json, 'tokenExpires') ? undefined : json['tokenExpires'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
    };
}
export function ApiOAuthTokenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'client': ApiClientToJSON(value.client),
        'apiToken': value.apiToken,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'tokenExpires': value.tokenExpires,
        'clientId': value.clientId,
    };
}
