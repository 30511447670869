import { ApiSimpleUTM } from "@incendium/api";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { IConsolidator } from "features/utms";
import { useCallback, useState } from "react";

function ConsolidationList({
  item,
  handleToggle,
  selectedItems,
}: {
  item: IConsolidator;
  handleToggle: (v: ApiSimpleUTM) => void;
  selectedItems: ApiSimpleUTM[];
}) {
  const [open, setOpen] = useState(true);
  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemText
          primaryTypographyProps={{ fontWeight: 600 }}
          primary={item.name}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense>
          {item.children.map((c) => (
            <ListItem key={c.id} sx={{ py: 0, px: 2.1 }}>
              <ListItemButton
                role={undefined}
                onClick={() => handleToggle(c)}
                disableGutters
              >
                <ListItemText primary={c.utmSource} />

                <Checkbox
                  size="small"
                  edge="end"
                  checked={selectedItems.indexOf(c) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
}

export default ConsolidationList;
