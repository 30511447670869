import { formatNumber } from "Helpers/numbers";
import { ResponsiveContainer, Tooltip, Treemap } from "recharts";
import { useMemo } from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import { getChartColor } from "features/analytics";
import { AnalyticsCardCustomTooltip } from "features/analytics";

const TreeContent = ({
  depth,
  x,
  y,
  width,
  height,
  colors,
  name,
  value,
  diff,
  formatValue,
  noGrouping,
}: any) => {
  let color = "#ffffff00";
  if (depth < 2 && Object.prototype.hasOwnProperty.call(colors, name)) {
    color = colors[name];
  }

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill: color,
          stroke: "#fff",
          strokeWidth: 2 / (depth + 1e-10),
          strokeOpacity: 1 / (depth + 1e-10),
        }}
      />
      {!noGrouping && depth === 1 ? (
        <text x={x + 8} y={y + 28} fill="#fff" fontSize={18}>
          {name}
        </text>
      ) : null}
      {depth === 2 && height > 5 && width > 20 ? (
        <>
          <text
            x={x + width / 2}
            y={y + height / 2 + 7}
            textAnchor="middle"
            fill="#fff"
            fontSize={16}
          >
            {name}
          </text>

          <text
            x={x + width / 2}
            y={y + height / 2 + 7}
            dy={20}
            textAnchor="middle"
            fill="#fff"
            fontSize={14}
          >
            {formatValue ? formatValue(value) : formatNumber(value)}
            {diff && <>({formatNumber(diff)}%)</>}
          </text>
        </>
      ) : null}
    </g>
  );
};

export interface ITreeMapChartData {
  name: string;
  children: {
    name: string | null;
    metric: number;
    diff?: number;
  }[];
}

interface IStyledTreeMapProps {
  chartData: ITreeMapChartData[];
  formatValue?: (v: any) => string;
  noGrouping?: boolean;
}

function StyledTreeMap({
  chartData,
  formatValue,
  noGrouping,
}: IStyledTreeMapProps) {
  const theme = useTheme();

  const colourMap = useMemo(() => {
    const o: { [k: string]: string } = {};
    for (let idx = 0; idx < chartData.length; idx++) {
      o[chartData[idx].name] = getChartColor(idx, theme.palette.charts, 0.85);
    }
    return o;
  }, [chartData, theme]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <Treemap
        animationDuration={300}
        data={chartData}
        dataKey="metric"
        content={
          <TreeContent
            colors={colourMap}
            formatValue={formatValue}
            noGrouping={noGrouping}
          />
        }
      >
        <Tooltip
          content={
            <AnalyticsCardCustomTooltip
              renderValueFn={(p, v, payload) => {
                return (
                  <Stack>
                    <Typography mb={1} variant="body2" gutterBottom>
                      {formatValue ? formatValue(v) : formatNumber(v)}
                    </Typography>
                    <Typography
                      color={"secondary"}
                      variant="body2"
                      gutterBottom
                    >
                      ({payload.payload.root.name})
                    </Typography>
                  </Stack>
                );
              }}
            />
          }
        />
      </Treemap>
    </ResponsiveContainer>
  );
}

export default StyledTreeMap;
