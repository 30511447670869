import { Autocomplete, TextField, OutlinedTextFieldProps } from "@mui/material";
import { useJobs } from "Hooks/useJobs";
import { useMemo } from "react";

interface IJobCategoriesDropdownProps
  extends Omit<OutlinedTextFieldProps, "onChange"> {
  value?: string;
  onChange: (c?: string) => void;
}

function JobCategoriesDropdown({
  label,
  size,
  variant,
  value,
  onChange,
  fullWidth,
  sx,
}: IJobCategoriesDropdownProps) {
  const { jobs } = useJobs();

  const categories = useMemo(
    () => Array.from([...new Set(jobs.map((p) => p.category || ""))]),
    [jobs]
  );

  const selected = useMemo(
    () => (categories || []).find((l) => l === value) || "",
    [categories, value]
  );
  return (
    <Autocomplete
      sx={sx}
      fullWidth={fullWidth}
      options={[...categories].sort((a, b) => (a || "").localeCompare(b || ""))}
      value={selected}
      onChange={(e, v) => {
        onChange(v || "");
      }}
      getOptionLabel={(o: string) => o || ""}
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant} size={size} />
      )}
    />
  );
}

export default JobCategoriesDropdown;
