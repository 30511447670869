import { ApiCSVRule, ApiProject } from "@incendium/api";
import { CSVService } from "Apis";
import { useSharedState } from "./useSharedState";

const fetchCSVRules = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const res = await CSVService.cSVServiceListRules();
    return res.rules || [];
  }
  return [];
};

export const useCSVRules = () => {
  const { state, setState } = useSharedState("CSVRules", [], fetchCSVRules);

  return {
    CSVRules: state as ApiCSVRule[],
    setCSVRules: setState,
  };
};
