/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiACLRoleFromJSON, ApiACLRoleToJSON, } from './ApiACLRole';
import { ApiClientFromJSON, ApiClientToJSON, } from './ApiClient';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
import { ApiUserFromJSON, ApiUserToJSON, } from './ApiUser';
export function ApiPermissionFromJSON(json) {
    return ApiPermissionFromJSONTyped(json, false);
}
export function ApiPermissionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'user': !exists(json, 'user') ? undefined : ApiUserFromJSON(json['user']),
        'role': !exists(json, 'role') ? undefined : ApiACLRoleFromJSON(json['role']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'client': !exists(json, 'client') ? undefined : ApiClientFromJSON(json['client']),
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
    };
}
export function ApiPermissionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'user': ApiUserToJSON(value.user),
        'role': ApiACLRoleToJSON(value.role),
        'userId': value.userId,
        'client': ApiClientToJSON(value.client),
        'clientId': value.clientId,
        'project': ApiProjectToJSON(value.project),
        'projectId': value.projectId,
    };
}
