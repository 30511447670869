import {
  ApiInsertionType,
  ApiOperator,
  LocationItemLocationItemCondition,
  ApiProductTaggerUniqueField,
  ApiReservedConversion,
} from "@incendium/api";
import { enumToArray } from "Helpers/enumToText";

export const AuthToken = "IncendiumAuthToken";

const location = window.location;
const host = location.host;
export const BaseURL = host.includes("localhost")
  ? "http://localhost:7778"
  : `${location.protocol}//rest.${host}`;
// export const BaseURL = "https://rest.wildfire.incendium.ai";

export const ApiURL = host.includes("localhost")
  ? "http://localhost:9091"
  : `${location.protocol}//api.${host}`;
export const PlatformURL = host.includes("localhost")
  ? "http://localhost:3000"
  : `${location.protocol}//${host}`;

export const RuleTypes: LocationItemLocationItemCondition[] = enumToArray(
  LocationItemLocationItemCondition
).filter(
  (item) =>
    item !==
    LocationItemLocationItemCondition.LOCATION_ITEM_CONDITION_UNSPECIFIED
);

export const OpertatorTypes: ApiOperator[] = enumToArray(ApiOperator).filter(
  (item) => item !== ApiOperator.OPERATOR_UNASSIGNED
);
export const InsertionTypes: ApiInsertionType[] = enumToArray(
  ApiInsertionType
).filter((item) => item !== ApiInsertionType.IT_UNSPECIFIED);

export const ProductTaggerUniqueFields: ApiProductTaggerUniqueField[] =
  enumToArray(ApiProductTaggerUniqueField);

export const ReservedConversionFields: ApiReservedConversion[] = enumToArray(
  ApiReservedConversion
).filter((i) => i !== ApiReservedConversion.NOT_RESERVED);
