/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiConditionCustom;
(function (ApiConditionCustom) {
    ApiConditionCustom["UNSPECIFIED"] = "CONDITION_UNSPECIFIED";
    ApiConditionCustom["TIME_ON_PAGE_OVER"] = "CONDITION_TIME_ON_PAGE_OVER";
    ApiConditionCustom["VIEW_RATE_OVER"] = "CONDITION_VIEW_RATE_OVER";
    ApiConditionCustom["SCROLL_RATE_OVER"] = "CONDITION_SCROLL_RATE_OVER";
    ApiConditionCustom["TIME_ON_PAGE_PERCENTILE"] = "CONDITION_TIME_ON_PAGE_PERCENTILE";
    ApiConditionCustom["VIEW_RATE_PERCENTILE"] = "CONDITION_VIEW_RATE_PERCENTILE";
    ApiConditionCustom["SCROLL_RATE_PERCENTILE"] = "CONDITION_SCROLL_RATE_PERCENTILE";
    ApiConditionCustom["AVERGAGE_SESSIONS_COUNT"] = "CONDITION_AVERGAGE_SESSIONS_COUNT";
    ApiConditionCustom["PAGE_SCORE"] = "CONDITION_PAGE_SCORE";
    ApiConditionCustom["CONVERSION_TRIGGERED"] = "CONDITION_CONVERSION_TRIGGERED";
    ApiConditionCustom["CONVERSION_COUNT"] = "CONDITION_CONVERSION_COUNT";
    ApiConditionCustom["CONVERSION_MACRO_COUNT"] = "CONDITION_CONVERSION_MACRO_COUNT";
    ApiConditionCustom["CONVERSION_MICRO_COUNT"] = "CONDITION_CONVERSION_MICRO_COUNT";
})(ApiConditionCustom || (ApiConditionCustom = {}));
export function ApiConditionCustomFromJSON(json) {
    return ApiConditionCustomFromJSONTyped(json, false);
}
export function ApiConditionCustomFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiConditionCustomToJSON(value) {
    return value;
}
