/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function ApiCreateOrFetchLeadKeyPayloadResponseFromJSON(json) {
    return ApiCreateOrFetchLeadKeyPayloadResponseFromJSONTyped(json, false);
}
export function ApiCreateOrFetchLeadKeyPayloadResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'leadKey': !exists(json, 'leadKey') ? undefined : json['leadKey'],
        'broprintjsKey': !exists(json, 'broprintjsKey') ? undefined : json['broprintjsKey'],
        'fingerprintjsKey': !exists(json, 'fingerprintjsKey') ? undefined : json['fingerprintjsKey'],
    };
}
export function ApiCreateOrFetchLeadKeyPayloadResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'leadKey': value.leadKey,
        'broprintjsKey': value.broprintjsKey,
        'fingerprintjsKey': value.fingerprintjsKey,
    };
}
