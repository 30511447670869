import {
  Box,
  Portal,
  Stack,
  Typography,
  Switch as UISwitch,
} from "@mui/material";
import { motion } from "framer-motion";
import { useLayoutContext } from "Providers/LayoutProvider";
import { useLayoutEffect, useState } from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
// import SalesCRPage from "./SalesCRPage";
import { SalesFlowFunnel } from "features/salesFlow";
import SalesFunnelProvider from "Providers/SalesFunnelProvider";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import { PageActionPreSlot } from "consts";

const SalesFlowAnalysisPageInner = () => {
  const { setPageTitle } = useLayoutContext();
  const [closedFunnel, setClosedFunnel] = useState(true);

  useLayoutEffect(() => {
    setPageTitle(`Sales Flow Analysis Funnel`);
    return () => {
      setPageTitle("");
    };
  }, []);

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <Portal container={() => document.getElementById(PageActionPreSlot)}>
        <Stack spacing={2} sx={{ minWidth: 210 }}>
          <StyledFlexBox compact>
            <Box display="flex" alignItems="center">
              <UISwitch
                checked={closedFunnel}
                onChange={(e, checked) => setClosedFunnel(checked)}
              />
              <Typography color={closedFunnel ? "primary" : "secondary"}>
                Closed Funnel
              </Typography>
            </Box>
            <ClickableTooltip
              placement="right"
              text={
                <>
                  <p>
                    Open - Stats will reflect leads who have been these statuses
                    within the timeframe. For example, 'customers' could have
                    been MQLs before the time period but were converted within
                    the time period.
                  </p>
                  <p>
                    Closed - Stats will only reflect leads who began their
                    funnel withing the timeframe. This gives a better picture of
                    your conversion rates through the funnel stages, within a
                    timeframe.
                  </p>
                </>
              }
              icon={<StyledHelp />}
            />
          </StyledFlexBox>
        </Stack>
      </Portal>
      <Box mt={10}>
        <SalesFlowFunnel closedFunnel={closedFunnel} />
      </Box>
    </Box>
  );
};

function SalesFlowAnalysisPage() {
  const { path } = useRouteMatch();
  const location = useLocation();
  return (
    <SalesFunnelProvider location={location}>
      <Switch>
        <Route path="/clients/:clientId/projects/:projectId/analyse/standard-reports/sales-flow-analysis-report/:crKey">
          todo
          {/* <SalesCRPage /> */}
        </Route>
        <Route exact path={path}>
          <SalesFlowAnalysisPageInner />
        </Route>
      </Switch>
    </SalesFunnelProvider>
  );
}

export default SalesFlowAnalysisPage;
