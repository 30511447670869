import { ApiAlert, ApiProject } from "@incendium/api";
import { alertService } from "Apis";
import { useSharedState } from "./useSharedState";

const fetchAlerts = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const alerts = await alertService.alertServiceListAlerts({
      projectId: selectedProject.id as number,
    });
    return alerts.results || [];
  }
  return [];
};

export const useAlerts = () => {
  const { state, setState } = useSharedState("alerts", [], fetchAlerts);

  return {
    alerts: state as ApiAlert[],
    setAlerts: setState,
  };
};
