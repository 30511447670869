import {
  ApiLocation,
  ApiProject,
  ApiConversion,
  ApiConversionType,
  ApiConversionState,
} from "@incendium/api";
import { useState, useEffect, useMemo } from "react";
import {
  Box,
  CircularProgress,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useClientIdFromUrl } from "Hooks";
import { useNotification } from "Hooks";
import { TagList } from "Components/TagList";
import { ConversionForm } from "Components/ConversionForm";
import { useConversions } from "Hooks/useConversions";
import { useLocation } from "react-router-dom";
import { sortAlpha } from "Helpers/arrays";
import {
  deleteConversion,
  listLocationConversions,
  saveConversionAndStates,
} from "features/location";

export const ConversionTab = ({
  location,
  project,
  isMobile,
}: {
  location: ApiLocation;
  project: ApiProject;
  isMobile: boolean;
}) => {
  const routerLocation = useLocation<ApiConversion>();
  const clientId = useClientIdFromUrl();
  const [isReady, setIsReady] = useState(false);
  const [tags, setTags] = useState<ApiConversion[]>([]);
  const [selectedConversion, setSelectedConversion] = useState<
    string | null | ApiConversion
  >(routerLocation.state || null);
  const [filterType, setFilterType] = useState<ApiConversionType>(
    ApiConversionType.UNSPECIFIED
  );
  // fetch all conversions aswell incase we delete or add one we can for a refetch
  const { refetch } = useConversions();

  const fetchAllConversions = async () => {
    const res = await listLocationConversions(
      Number(clientId),
      project.id!,
      location.id!
    );
    setTags(res.results || []);
    setIsReady(true);
  };

  const sortedFilteredTags = useMemo(
    () =>
      sortAlpha(
        sortAlpha(
          tags.filter(
            (a) =>
              filterType === ApiConversionType.UNSPECIFIED ||
              a.type === filterType
          ),
          "name"
        ),
        "type"
      ),
    [tags, filterType]
  );

  useEffect(() => {
    fetchAllConversions();
  }, []);

  const { showSuccessNotification, showErrorNotification } = useNotification();

  const handleEditConversion = async (
    conversion: ApiConversion,
    states: ApiConversionState[]
  ) => {
    try {
      await saveConversionAndStates(
        Number(clientId),
        project.id!,
        location.id!,
        conversion,
        states
      );
      await fetchAllConversions();
      setSelectedConversion(null);
      showSuccessNotification("Conversion Updated");
    } catch (e) {
      showErrorNotification("Failed to update Conversion");
    } finally {
      refetch();
    }
  };

  const handleAddConversion = async (
    conversion: ApiConversion,
    states: ApiConversionState[]
  ) => {
    try {
      await saveConversionAndStates(
        Number(clientId),
        project.id!,
        location.id!,
        conversion,
        states
      );
      await fetchAllConversions();
      setSelectedConversion(null);
      showSuccessNotification("Conversion Added");
    } catch (e) {
      showErrorNotification("Failed to add Conversion");
    }
    refetch();
  };

  const handleConversionDelete = async (conversion: ApiConversion) => {
    setIsReady(false);
    try {
      await deleteConversion(
        Number(clientId),
        project.id!,
        location.id!,
        conversion.id!
      );
      await fetchAllConversions();
      showSuccessNotification("Conversion deleted");
    } catch (e) {
      showErrorNotification("Failed to delete Conversion");
    }
    refetch();
  };

  if (!isReady) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={"2rem"} />
      </Box>
    );
  }

  if (selectedConversion === null) {
    return (
      <Stack spacing={2}>
        <ToggleButtonGroup
          size="small"
          fullWidth
          value={filterType}
          exclusive
          color="secondary"
          onChange={(e, v) => {
            setFilterType(v);
          }}
        >
          <ToggleButton value={ApiConversionType.UNSPECIFIED}>All</ToggleButton>
          <ToggleButton value={ApiConversionType.MACRO}>
            Conversion
          </ToggleButton>
          <ToggleButton value={ApiConversionType.MICRO}>
            Interaction
          </ToggleButton>
        </ToggleButtonGroup>
        <div>
          <TagList
            title="add new conversion"
            tags={sortedFilteredTags}
            onAdd={() => setSelectedConversion("new")}
            onEdit={(tag) => {
              setSelectedConversion(tag as ApiConversion);
            }}
            onDelete={(tag) => handleConversionDelete(tag as ApiConversion)}
          />
        </div>
      </Stack>
    );
  }

  if (selectedConversion) {
    return (
      <ConversionForm
        project={project}
        location={location}
        conversion={
          typeof selectedConversion === "string"
            ? undefined
            : selectedConversion
        }
        onEdit={handleEditConversion}
        onCancel={() => setSelectedConversion(null)}
        onAdd={handleAddConversion}
        isMobile={isMobile}
        initialConversionType={filterType}
      />
    );
  }

  return null;
};
