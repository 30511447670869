import { Box } from "@mui/material";
import { useSalesFunnelContext } from "Providers/SalesFunnelProvider";
import { SaleFlowFunnelBlock } from "features/salesFlow";
import { motion } from "framer-motion";

interface ISalesFlowFunnelProps {
  closedFunnel: boolean;
}

function SalesFlowFunnel({ closedFunnel }: ISalesFlowFunnelProps) {
  const { types } = useSalesFunnelContext();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
      component={motion.div}
      variants={{
        hidden: {
          opacity: 0,
          transition: { staggerChildren: 0.04, delayChildren: 0.1 },
        },
        show: {
          opacity: 1,
          transition: {
            staggerChildren: 0.15,
          },
        },
      }}
      initial="hidden"
      animate="show"
    >
      <Box
        sx={{
          position: "absolute",
          height: 9,
          width: "100%",
          opacity: 0.6,
          // top: 265,
          top: 210,
          background: "#7C97C6",
        }}
      />

      {types.map((t, i) => (
        <SaleFlowFunnelBlock
          key={i}
          idx={i}
          width="20%"
          totalSize={types.length}
          status={t}
          statuses={types}
          closedFunnel={closedFunnel}
        />
      ))}
    </Box>
  );
}

export default SalesFlowFunnel;
