import {
  SidebarContainer,
  SiderBarHeader,
  SidebarContentWrapper,
  SidebarStyledDivider,
  SidebarSubtitle,
} from "Components/UI/Sidebar";
import { Box, Button, Switch, Typography } from "@mui/material";
import { TextValidator } from "react-material-ui-form-validator";
import {
  ApiLeadStatusRule,
  ApiProject,
  ApiLeadStatusType,
} from "@incendium/api";
import produce from "immer";
import { Assignment, AssignmentTurnedIn } from "@mui/icons-material";
import { useState } from "react";
import { ApiURL } from "config";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";

interface ILeadStatusRuleSidebarProps {
  leadStatusRule: ApiLeadStatusRule;
  setLeadStatusRule: React.Dispatch<
    React.SetStateAction<ApiLeadStatusRule | undefined>
  >;
  project: ApiProject;
}

function LeadStatusRuleSidebar({
  leadStatusRule,
  setLeadStatusRule,
  project,
}: ILeadStatusRuleSidebarProps) {
  const apiURL = `${ApiURL}/services/lead/v1/projects/${project.key}/leads:append-status`;
  const incSnippet = `inc("append_lead_status", ${leadStatusRule.type})`;

  const [apiCopied, setApiCopied] = useState(false);
  const [incCopied, setIncCopied] = useState(false);
  return (
    <>
      <SidebarContainer>
        <SiderBarHeader>
          <Typography variant="h2">LeadStatusRule Information</Typography>
        </SiderBarHeader>
        <SidebarContentWrapper px={3}>
          <Box mb={3.5}>
            <TextValidator
              label="name of lead Status Rule"
              variant="outlined"
              name="name"
              fullWidth
              validators={["required"]}
              errorMessages={["name is required"]}
              value={leadStatusRule.name || ""}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setLeadStatusRule(
                  produce(leadStatusRule, (draft) => {
                    draft.name = e.currentTarget.value;
                  })
                );
              }}
            />
          </Box>
          {leadStatusRule.type === ApiLeadStatusType.PROSPECT && (
            <StyledFlexBox mb={4}>
              <Box display="flex" alignItems="center">
                <Switch
                  checked={leadStatusRule.asDefault || false}
                  onChange={(e, checked) => {
                    setLeadStatusRule(
                      produce(leadStatusRule, (draft) => {
                        draft.asDefault = checked;
                      })
                    );
                  }}
                />
                <Typography color={"primary"}>Set as default</Typography>
              </Box>
              <ClickableTooltip
                placement="bottom"
                width={335}
                text="Should a new lead be this stayus as default?"
                icon={<StyledHelp />}
              />
            </StyledFlexBox>
          )}

          <Box mb={2}>
            <SidebarStyledDivider />
          </Box>
          <Box mb={2}>
            <SidebarSubtitle>Offline</SidebarSubtitle>
          </Box>
          <Box mb={2}>
            <Button
              fullWidth
              onClick={() => {
                setApiCopied(true);
                navigator.clipboard.writeText(apiURL);
              }}
              endIcon={apiCopied ? <AssignmentTurnedIn /> : <Assignment />}
            >
              Copy API URL
            </Button>
          </Box>
          <Box mb={2}>
            <Button
              fullWidth
              onClick={() => {
                setIncCopied(true);
                navigator.clipboard.writeText(incSnippet);
              }}
              endIcon={incCopied ? <AssignmentTurnedIn /> : <Assignment />}
            >
              Copy INC Function
            </Button>
          </Box>
        </SidebarContentWrapper>
      </SidebarContainer>
    </>
  );
}

export default LeadStatusRuleSidebar;
