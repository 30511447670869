/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiOperator;
(function (ApiOperator) {
    ApiOperator["OPERATOR_UNASSIGNED"] = "OPERATOR_UNASSIGNED";
    ApiOperator["EQUAL"] = "EQUAL";
    ApiOperator["NOT_EQUAL"] = "NOT_EQUAL";
    ApiOperator["IS_IN"] = "IS_IN";
    ApiOperator["NOT_IN"] = "NOT_IN";
    ApiOperator["STARTS_WITH"] = "STARTS_WITH";
    ApiOperator["CONTAINS"] = "CONTAINS";
    ApiOperator["IS_GREATER_THAN"] = "IS_GREATER_THAN";
    ApiOperator["IS_LESS_THAN"] = "IS_LESS_THAN";
    ApiOperator["IS_GREATER_THAN_OR_EQUAL"] = "IS_GREATER_THAN_OR_EQUAL";
    ApiOperator["IS_LESS_THAN_OR_EQUAL"] = "IS_LESS_THAN_OR_EQUAL";
    ApiOperator["ONE_OF"] = "ONE_OF";
    ApiOperator["NOT_CONTAINS"] = "NOT_CONTAINS";
})(ApiOperator || (ApiOperator = {}));
export function ApiOperatorFromJSON(json) {
    return ApiOperatorFromJSONTyped(json, false);
}
export function ApiOperatorFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiOperatorToJSON(value) {
    return value;
}
