/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiLeadFromJSON, ApiLeadToJSON, } from './ApiLead';
import { ApiLeadInteractionFromJSON, ApiLeadInteractionToJSON, } from './ApiLeadInteraction';
import { ApiLeadStatusRuleFromJSON, ApiLeadStatusRuleToJSON, } from './ApiLeadStatusRule';
export function ApiLeadStatusFromJSON(json) {
    return ApiLeadStatusFromJSONTyped(json, false);
}
export function ApiLeadStatusFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'leadStatusRule': !exists(json, 'leadStatusRule') ? undefined : ApiLeadStatusRuleFromJSON(json['leadStatusRule']),
        'lead': !exists(json, 'lead') ? undefined : ApiLeadFromJSON(json['lead']),
        'leadStatusRuleId': !exists(json, 'leadStatusRuleId') ? undefined : json['leadStatusRuleId'],
        'leadId': !exists(json, 'leadId') ? undefined : json['leadId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'interactions': !exists(json, 'interactions') ? undefined : (json['interactions'].map(ApiLeadInteractionFromJSON)),
    };
}
export function ApiLeadStatusToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'leadStatusRule': ApiLeadStatusRuleToJSON(value.leadStatusRule),
        'lead': ApiLeadToJSON(value.lead),
        'leadStatusRuleId': value.leadStatusRuleId,
        'leadId': value.leadId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'interactions': value.interactions === undefined ? undefined : (value.interactions.map(ApiLeadInteractionToJSON)),
    };
}
