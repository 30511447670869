/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiAttributionClickFromJSON, ApiAttributionClickToJSON, } from './ApiAttributionClick';
import { ApiBucketFromJSON, ApiBucketToJSON, } from './ApiBucket';
import { ApiChannelFromJSON, ApiChannelToJSON, } from './ApiChannel';
import { ApiExternalAdGroupFromJSON, ApiExternalAdGroupToJSON, } from './ApiExternalAdGroup';
import { ApiExternalCampaignFromJSON, ApiExternalCampaignToJSON, } from './ApiExternalCampaign';
import { ApiLeadFromJSON, ApiLeadToJSON, } from './ApiLead';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
import { ApiSessionFromJSON, ApiSessionToJSON, } from './ApiSession';
import { ApiSourceFunnelFromJSON, ApiSourceFunnelToJSON, } from './ApiSourceFunnel';
export function ApiSourceFromJSON(json) {
    return ApiSourceFromJSONTyped(json, false);
}
export function ApiSourceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'leads': !exists(json, 'leads') ? undefined : (json['leads'].map(ApiLeadFromJSON)),
        'sessions': !exists(json, 'sessions') ? undefined : (json['sessions'].map(ApiSessionFromJSON)),
        'source': !exists(json, 'source') ? undefined : json['source'],
        'medium': !exists(json, 'medium') ? undefined : json['medium'],
        'campaign': !exists(json, 'campaign') ? undefined : json['campaign'],
        'term': !exists(json, 'term') ? undefined : json['term'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'channel': !exists(json, 'channel') ? undefined : ApiChannelFromJSON(json['channel']),
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'sourceTitle': !exists(json, 'sourceTitle') ? undefined : json['sourceTitle'],
        'sourceDomain': !exists(json, 'sourceDomain') ? undefined : json['sourceDomain'],
        'buckets': !exists(json, 'buckets') ? undefined : (json['buckets'].map(ApiBucketFromJSON)),
        'funnel': !exists(json, 'funnel') ? undefined : ApiSourceFunnelFromJSON(json['funnel']),
        'externalCampaign': !exists(json, 'externalCampaign') ? undefined : ApiExternalCampaignFromJSON(json['externalCampaign']),
        'externalCampaignId': !exists(json, 'externalCampaignId') ? undefined : json['externalCampaignId'],
        'initialSource': !exists(json, 'initialSource') ? undefined : ApiSourceFromJSON(json['initialSource']),
        'initialSourceId': !exists(json, 'initialSourceId') ? undefined : json['initialSourceId'],
        'attributionClicks': !exists(json, 'attributionClicks') ? undefined : (json['attributionClicks'].map(ApiAttributionClickFromJSON)),
        'channelId': !exists(json, 'channelId') ? undefined : json['channelId'],
        'externalAdGroup': !exists(json, 'externalAdGroup') ? undefined : ApiExternalAdGroupFromJSON(json['externalAdGroup']),
        'externalAdGroupId': !exists(json, 'externalAdGroupId') ? undefined : json['externalAdGroupId'],
        'campaignId': !exists(json, 'campaignId') ? undefined : json['campaignId'],
        'adGroupId': !exists(json, 'adGroupId') ? undefined : json['adGroupId'],
        'sourceNameId': !exists(json, 'sourceNameId') ? undefined : json['sourceNameId'],
        'mediumNameId': !exists(json, 'mediumNameId') ? undefined : json['mediumNameId'],
        'campaignNameId': !exists(json, 'campaignNameId') ? undefined : json['campaignNameId'],
        'termNameId': !exists(json, 'termNameId') ? undefined : json['termNameId'],
        'contentNameId': !exists(json, 'contentNameId') ? undefined : json['contentNameId'],
    };
}
export function ApiSourceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'leads': value.leads === undefined ? undefined : (value.leads.map(ApiLeadToJSON)),
        'sessions': value.sessions === undefined ? undefined : (value.sessions.map(ApiSessionToJSON)),
        'source': value.source,
        'medium': value.medium,
        'campaign': value.campaign,
        'term': value.term,
        'content': value.content,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'channel': ApiChannelToJSON(value.channel),
        'project': ApiProjectToJSON(value.project),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'projectId': value.projectId,
        'sourceTitle': value.sourceTitle,
        'sourceDomain': value.sourceDomain,
        'buckets': value.buckets === undefined ? undefined : (value.buckets.map(ApiBucketToJSON)),
        'funnel': ApiSourceFunnelToJSON(value.funnel),
        'externalCampaign': ApiExternalCampaignToJSON(value.externalCampaign),
        'externalCampaignId': value.externalCampaignId,
        'initialSource': ApiSourceToJSON(value.initialSource),
        'initialSourceId': value.initialSourceId,
        'attributionClicks': value.attributionClicks === undefined ? undefined : (value.attributionClicks.map(ApiAttributionClickToJSON)),
        'channelId': value.channelId,
        'externalAdGroup': ApiExternalAdGroupToJSON(value.externalAdGroup),
        'externalAdGroupId': value.externalAdGroupId,
        'campaignId': value.campaignId,
        'adGroupId': value.adGroupId,
        'sourceNameId': value.sourceNameId,
        'mediumNameId': value.mediumNameId,
        'campaignNameId': value.campaignNameId,
        'termNameId': value.termNameId,
        'contentNameId': value.contentNameId,
    };
}
