import { ApiCSVDateGrouping, ApiCSVRow } from "@incendium/api";
import { CSVService } from "Apis";

export const suggestMapping = async (ruleId: string, path: string) => {
  return CSVService.cSVServiceSuggestMapping({
    ruleId,
    payload: {
      csvUrl: path,
    },
  });
};

export const testMapping = async (
  ruleId: string,
  path: string,
  filteredMapping: {
    [k: string]: ApiCSVRow;
  },
  userValue: {
    [k: string]: string;
  },
  dateGrouping: ApiCSVDateGrouping
) => {
  return await CSVService.cSVServiceTestMapping({
    ruleId: ruleId,
    payload: {
      csvUrl: path,
      mapping: {
        ...filteredMapping,
      },
      userValue,
      grouping: dateGrouping,
      limit: 1000,
    },
  });
};

export const saveCSV = async (
  ruleId: string,
  path: string,
  filteredMapping: {
    [k: string]: ApiCSVRow;
  },
  userValue: {
    [k: string]: string;
  },
  dateGrouping: ApiCSVDateGrouping,
  projectId: number
) => {
  return CSVService.cSVServiceSave({
    ruleId,
    projectId,
    payload: {
      csvUrl: path,
      mapping: {
        ...filteredMapping,
      },
      userValue,
      grouping: dateGrouping,
    },
  });
};
