/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiJobTaggerUniqueFieldFromJSON, ApiJobTaggerUniqueFieldToJSON, } from './ApiJobTaggerUniqueField';
export function ApiJobTaggerPayloadFromJSON(json) {
    return ApiJobTaggerPayloadFromJSONTyped(json, false);
}
export function ApiJobTaggerPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'uniqueField': !exists(json, 'uniqueField') ? undefined : ApiJobTaggerUniqueFieldFromJSON(json['uniqueField']),
        'titleSelector': !exists(json, 'titleSelector') ? undefined : json['titleSelector'],
        'descriptionSelector': !exists(json, 'descriptionSelector') ? undefined : json['descriptionSelector'],
        'idSelector': !exists(json, 'idSelector') ? undefined : json['idSelector'],
        'categorySelector': !exists(json, 'categorySelector') ? undefined : json['categorySelector'],
        'salaryRangeSelector': !exists(json, 'salaryRangeSelector') ? undefined : json['salaryRangeSelector'],
        'contractTypeSelector': !exists(json, 'contractTypeSelector') ? undefined : json['contractTypeSelector'],
        'locationSelector': !exists(json, 'locationSelector') ? undefined : json['locationSelector'],
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
    };
}
export function ApiJobTaggerPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'uniqueField': ApiJobTaggerUniqueFieldToJSON(value.uniqueField),
        'titleSelector': value.titleSelector,
        'descriptionSelector': value.descriptionSelector,
        'idSelector': value.idSelector,
        'categorySelector': value.categorySelector,
        'salaryRangeSelector': value.salaryRangeSelector,
        'contractTypeSelector': value.contractTypeSelector,
        'locationSelector': value.locationSelector,
        'locationId': value.locationId,
    };
}
