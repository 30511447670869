/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiTabChartPayloadFromJSON, ApiTabChartPayloadToJSON, } from './ApiTabChartPayload';
import { ApiTabElementTypeFromJSON, ApiTabElementTypeToJSON, } from './ApiTabElementType';
export function ApiTabElementPayloadFromJSON(json) {
    return ApiTabElementPayloadFromJSONTyped(json, false);
}
export function ApiTabElementPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': !exists(json, 'type') ? undefined : ApiTabElementTypeFromJSON(json['type']),
        'chart': !exists(json, 'chart') ? undefined : ApiTabChartPayloadFromJSON(json['chart']),
    };
}
export function ApiTabElementPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
        'type': ApiTabElementTypeToJSON(value.type),
        'chart': ApiTabChartPayloadToJSON(value.chart),
    };
}
