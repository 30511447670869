import { Box, CircularProgress, Portal } from "@mui/material";
import { effectivenessRuleService } from "Apis";
import ConditionGroupProvider from "Components/ConditionalGroup/ConditionGroupProvider";
import EffectivenessForm from "Components/Effectiveness/EffectivenessForm";
import { TypographyHelp } from "Components/UI/TypographyHelp";
import { useSelectedProject } from "Hooks";
import { TEffectivenessRule } from "Interfaces";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useMount } from "react-use";

function PageviewEffectivenessRulesPage() {
  const { selectedProject } = useSelectedProject();
  const { ruleId } = useParams<{ ruleId: string }>();
  const [title, setTitle] = useState("");
  const [effectivenessRule, setEffectivenessRule] = useState<
    TEffectivenessRule | undefined
  >(undefined);

  useMount(() => {
    if (!ruleId) {
      return;
    }
    const load = async () => {
      const res =
        await effectivenessRuleService.effectivenessRuleServiceReadRule({
          projectId: selectedProject?.id as number,
          ruleId: Number(ruleId),
        });
      setEffectivenessRule(res);
      setTitle(res.name || "");
    };
    load();
  });

  if (!selectedProject || !effectivenessRule) {
    return <CircularProgress />;
  }

  return (
    <Box pt={5}>
      <Portal container={() => document.getElementById("pageTitle")}>
        <TypographyHelp
          variant="h1"
          text={title}
          placement={"right"}
          tooltip={
            "Configure the rules which will define if a page visit was successful / effective"
          }
        />
      </Portal>
      <ConditionGroupProvider>
        <EffectivenessForm
          rule={effectivenessRule}
          setRule={setEffectivenessRule}
          project={selectedProject}
          isPageview={true}
        />
      </ConditionGroupProvider>
    </Box>
  );
}

export default PageviewEffectivenessRulesPage;
