import { ApiSyncType } from "@incendium/api";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import {
  BingAdsTrackingSetup,
  BingAdsTrackingTemplate,
  GoogleAdsTrackingSetup,
  GoogleAdsTrackingTemplate,
  MetaAdsTrackingSetup,
  MetaAdsTrackingTemplate,
} from "features/utms";
import { useState } from "react";

interface IUtmGuideProps {
  syncType?: ApiSyncType;
}

function UtmGuide({ syncType }: IUtmGuideProps) {
  const [open, setOpen] = useState(false);
  return (
    <div>
      {syncType === ApiSyncType.GOOGLE_ADS && <GoogleAdsTrackingTemplate />}
      {syncType === ApiSyncType.BING_ADS && <BingAdsTrackingTemplate />}
      {syncType === ApiSyncType.FACEBOOK_ADS && <MetaAdsTrackingTemplate />}
      <Stack mt={2} direction="row" alignItems={"center"} spacing={2}>
        <Typography>To see a more detailed setup guide click</Typography>
        <Button size="extraSmall" onClick={() => setOpen(!open)}>
          here
        </Button>
      </Stack>
      <Dialog open={open} maxWidth="md" onClose={() => setOpen(false)}>
        <DialogContent>
          {syncType === ApiSyncType.GOOGLE_ADS && (
            <GoogleAdsTrackingSetup onFinish={() => setOpen(false)} />
          )}
          {syncType === ApiSyncType.BING_ADS && (
            <BingAdsTrackingSetup onFinish={() => setOpen(false)} />
          )}
          {syncType === ApiSyncType.FACEBOOK_ADS && (
            <MetaAdsTrackingSetup onFinish={() => setOpen(false)} />
          )}

          <DialogActions>
            <Button onClick={() => setOpen(false)}>Close</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default UtmGuide;
