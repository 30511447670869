import { ApiProject, ApiSimpleUTM } from "@incendium/api";
import { Typography } from "@mui/material";
import { fetchAllClientsAndProjectsThunk } from "Actions";
import StyledDrawer, { StyledDrawerTitle } from "Components/UI/StyledDrawer";
import {
  ProjectOptionsDrawerForm,
  saveProjectSettings,
} from "features/project";
import { bulkDeleteUTMs, bulkSaveUTMs } from "features/utms";
import { compareUtms } from "features/utms/utils";
import { useNotification, useSelectedProject } from "Hooks";
import { useUTMs } from "Hooks/useUTMs";
import { GenericDialoagProps } from "Interfaces";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

interface IProjectOptionstDialogProps extends GenericDialoagProps {
  project: ApiProject;
}

function ProjectOptions({
  open,
  setOpen,
  project,
}: IProjectOptionstDialogProps) {
  const { projectSettings, setProjectSettings } = useSelectedProject();
  const { utms, setUTMs, refetch: refetchUtms } = useUTMs();
  const [originalUtms, setOriginalUtms] = useState<ApiSimpleUTM[] | null>(null);
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const dispatch = useDispatch();

  useEffect(() => {
    if (open && utms && !originalUtms) {
      setOriginalUtms(utms);
    }
  }, [open, utms, originalUtms]);

  const onClose = useCallback(() => {
    if (originalUtms) {
      setUTMs(originalUtms);
    }

    setOriginalUtms(null);
    setOpen(false);
  }, [originalUtms, setOpen, setUTMs]);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await saveProjectSettings(projectSettings, project);
      const { utmsToSave, utmsToDelete } = compareUtms(
        originalUtms || [],
        utms
      );
      if (utmsToSave && utmsToSave.length > 0) {
        await bulkSaveUTMs(project.id!, utmsToSave);
      }
      if (utmsToDelete && utmsToDelete.length > 0) {
        await bulkDeleteUTMs(
          project.id!,
          utmsToDelete.map((u) => u.id!)
        );
      }
      refetchUtms();
      showSuccessNotification(`${project.name} Settings Saved`);
      dispatch(
        fetchAllClientsAndProjectsThunk({
          clientId: project.clientId as number,
          projectId: project.id as number,
        })
      );
    } catch (error) {
      showErrorNotification(
        `Internal Error saving Project settings, please try again`
      );
    }

    onClose();
  };

  return (
    <StyledDrawer open={open} onClose={onClose} maxWidth={600}>
      <StyledDrawerTitle>
        <Typography variant="subtitle1">
          Configure {project.name} project settings
        </Typography>
        <Typography variant="body2">
          These settings can be customized to suit your needs and adapt to the
          functionality of your site, providing a tailored experience.
        </Typography>
      </StyledDrawerTitle>
      <ProjectOptionsDrawerForm
        onSubmit={onSubmit}
        onClose={onClose}
        projectSettings={projectSettings}
        setProjectSettings={setProjectSettings}
      />
    </StyledDrawer>
  );
}

export default ProjectOptions;
