/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiIntegrationActionType;
(function (ApiIntegrationActionType) {
    ApiIntegrationActionType["UNSPECIFIED"] = "UNSPECIFIED";
    ApiIntegrationActionType["MESSAGE"] = "MESSAGE";
})(ApiIntegrationActionType || (ApiIntegrationActionType = {}));
export function ApiIntegrationActionTypeFromJSON(json) {
    return ApiIntegrationActionTypeFromJSONTyped(json, false);
}
export function ApiIntegrationActionTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiIntegrationActionTypeToJSON(value) {
    return value;
}
