import { friendlyDate } from "Helpers/dates";
import moment, { Moment } from "moment";
import { createContext, useContext, useMemo, useState } from "react";

export interface IFromToOutput {
  from: string | undefined;
  to: string | undefined;
  lastNDays: number | undefined;
}
export interface IFromToChartOutput {
  from: Date | undefined;
  to: Date | undefined;
  lastNDays: number | undefined;
}

type TFromToContext = {
  from: Moment | null;
  setFrom: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  to: Moment | null;
  setTo: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  lastNDays: number | null;
  setLastNDays: React.Dispatch<React.SetStateAction<number | null>>;
  output: IFromToOutput;
  chartOutput: IFromToChartOutput;
  chartComparisonOutput: IFromToChartOutput;
  formatedString: string;
  comparisonWindowDays: number | undefined;
  calculatedFrom: Moment;
};

export const FromToContext = createContext<TFromToContext>({
  from: null,
  setFrom: () => null,
  to: null,
  setTo: () => null,
  lastNDays: null,
  setLastNDays: () => null,
  output: {
    from: undefined,
    to: undefined,
    lastNDays: undefined,
  },
  chartOutput: {
    from: undefined,
    to: undefined,
    lastNDays: undefined,
  },
  chartComparisonOutput: {
    from: undefined,
    to: undefined,
    lastNDays: undefined,
  },
  formatedString: "",
  comparisonWindowDays: undefined,
  calculatedFrom: moment(),
});

export const useFromToContext = () => useContext(FromToContext);

const FromToProvider = ({
  children,
  initialLastNDays,
}: {
  initialLastNDays?: number;
  children: React.ReactNode;
}) => {
  const [from, setFrom] = useState<moment.Moment | null>(null);
  const [to, setTo] = useState<moment.Moment | null>(null);
  const [lastNDays, setLastNDays] = useState<number | null>(
    initialLastNDays || 30
  );

  const comparisonWindowDays = useMemo(() => {
    if (lastNDays) {
      return lastNDays;
    }
    const duration = moment.duration(moment(to).diff(from));
    const diff = Math.round(duration.asDays());
    return diff;
  }, [lastNDays, from, to]);

  const compToFrom = useMemo(() => {
    if (lastNDays) {
      return {
        from: moment()
          .clone()
          .subtract(comparisonWindowDays * 2, "days")
          .utc()
          .startOf("day")
          .toDate(),
        to: moment()
          .clone()
          .subtract(comparisonWindowDays, "days")
          .utc()
          .startOf("day")
          .toDate(),
      };
    }

    return {
      from: from
        ?.clone()
        .subtract(comparisonWindowDays, "days")
        .utc()
        .startOf("day")
        .toDate(),
      to: to
        ?.clone()
        .subtract(comparisonWindowDays, "days")
        .utc()
        .endOf("day")
        .toDate(),
    };
  }, [comparisonWindowDays, to, from, lastNDays]);

  const calculatedFrom = useMemo(() => {
    return from || moment().utc().subtract(lastNDays, "days").startOf("day");
  }, [from, lastNDays]);

  const state = useMemo(
    () => ({
      from,
      setFrom,
      to,
      setTo,
      lastNDays,
      setLastNDays,
      output: {
        from: from ? from?.clone().startOf("day").format() : undefined,
        to: to ? to?.clone().endOf("day").format() : undefined,
        lastNDays: lastNDays || undefined,
      },
      chartOutput: {
        from: from ? from?.clone().startOf("day").toDate() : undefined,
        to: to ? to?.clone().endOf("day").toDate() : undefined,
        lastNDays: lastNDays || undefined,
      },
      chartComparisonOutput: {
        ...compToFrom,
        lastNDays: undefined,
      },
      formatedString: lastNDays
        ? `recorded in the Last ${lastNDays} days`
        : `${friendlyDate(from || "")} - ${friendlyDate(to || "")}`,
      comparisonWindowDays,
      calculatedFrom,
    }),
    [
      to,
      setTo,
      from,
      setFrom,
      lastNDays,
      setLastNDays,
      compToFrom,
      comparisonWindowDays,
      calculatedFrom,
    ]
  );

  return (
    <FromToContext.Provider value={state}>{children}</FromToContext.Provider>
  );
};

export default FromToProvider;
