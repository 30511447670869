import {
  checkLoginFailed,
  checkLoginSuccess,
  CHECK_LOGIN,
  clearState,
  IAuthActions,
  LOGIN,
  loginError,
  loginSuccess,
  LOGOUT,
  setUser,
} from "Actions";
import { Middleware } from "redux";
import { AuthToken } from "config";
import { authService, userService } from "Apis/api";
import { saveAuthToken } from "Helpers/saveAuthToken";
import { IStoreState } from "Interfaces";

export const authMiddleware: Middleware<{}, IStoreState> =
  (store) => (next) => async (action: IAuthActions) => {
    const dispatch = store.dispatch;
    const state = store.getState();
    next(action);

    switch (action.type) {
      case LOGIN: {
        const { email, password, onSuccess, onFail } = action.payload;

        try {
          const result = await authService.authAuthenticate({
            body: {
              email,
              password,
            },
          });
          saveAuthToken(result);
          dispatch(loginSuccess(result));

          // handle user
          const u = await userService.userServiceGetAuthUser();
          dispatch(setUser(u));

          onSuccess && onSuccess(result);
        } catch (e) {
          console.error(e);
          dispatch(loginError());
          onFail && onFail(e);
        }
        break;
      }
      case CHECK_LOGIN: {
        const token = state.auth.tokens;
        if (token) {
          try {
            const res = await userService.userServiceRefreshToken({
              body: { refreshToken: token.refreshToken },
            });
            saveAuthToken(res);
            dispatch(checkLoginSuccess(res));
          } catch (e: any) {
            if (e.status === 401) {
              dispatch(checkLoginFailed());
            }
          }
        }

        break;
      }
      case LOGOUT: {
        localStorage.removeItem(AuthToken);
        dispatch(clearState());
        break;
      }
    }
  };
