import { ApiChartAttribute, ApiDimension, ApiOperator } from "@incendium/api";
import { Add } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  TextField,
  MenuItem,
  Typography,
  Grid,
  Divider,
  Stack,
  Button,
} from "@mui/material";
import { useState, useMemo, useCallback, useEffect } from "react";
import {
  AccordianChartBuilderSidebarBlock,
  ChartBuilderAttributionPill,
} from "../";
import { CallbackOrVal, TFilterableDimensions } from "Interfaces";
import produce from "immer";
import { usePrevious, useUpdateEffect } from "react-use";
import { formatEnumVal } from "Helpers/enumToText";
import { dimensionToName, useFilterableDimensions } from "features/analytics";
import Loading from "Components/Loading/Loading";
import { TypographyHelp } from "Components/UI/TypographyHelp";
import { filterObjectByKeys } from "Helpers/objects";

const ChartBuilderAttributesRow = ({
  options,
  passedAtribute,
  setPassedAtribute,
  attributes,
  setAttributes,
}: {
  options?: TFilterableDimensions;
  passedAtribute?: ApiChartAttribute;
  setPassedAtribute?: (att: ApiChartAttribute | undefined) => void;
  attributes: ApiChartAttribute[];
  setAttributes: (att: ApiChartAttribute[]) => void;
}) => {
  const [key, setKey] = useState<ApiDimension>();
  const prevKey = usePrevious(key);
  const [values, setValues] = useState<string[]>([]);
  const [operator, setOperator] = useState(ApiOperator.EQUAL);

  useEffect(() => {
    if (passedAtribute) {
      setKey(passedAtribute.key as ApiDimension);
      setOperator(passedAtribute.operator as ApiOperator);
      setValues([passedAtribute.value as string]);
    }
  }, [passedAtribute]);

  const optionLabels = useMemo(
    () => Object.keys(options || {}) as ApiDimension[],
    [options]
  );

  const availableOptions = useMemo(() => {
    if (!options || !key) {
      return [];
    }

    const selected = attributes
      .filter((f) => f.key === key && (!f.operator || f.operator === operator))
      .map((v) => v.value);

    return options[key].filter((v) => !selected.includes(v));
  }, [options, operator, key, attributes]);

  useUpdateEffect(() => {
    if (!passedAtribute && key !== prevKey) {
      setValues([]);
    }
  }, [key]);

  const addAtt = useCallback(() => {
    setAttributes(
      produce(attributes, (draft) => {
        values.forEach((value) => {
          const idx = draft.findIndex(
            (d) => d.key === key && d.operator === operator && d.value === value
          );
          if (idx < 0) {
            draft.push({
              key,
              operator,
              value,
            });
          }
        });
      })
    );
    setKey(undefined);
    setOperator(ApiOperator.EQUAL);
    setValues([]);
    setPassedAtribute && setPassedAtribute(undefined);
  }, [values, operator, key, setAttributes, attributes, setPassedAtribute]);

  return (
    <Stack spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Autocomplete
            id="att-dim"
            size="small"
            key={key}
            onChange={(e, value) => {
              setKey(value);
            }}
            value={key}
            disableClearable
            fullWidth
            options={optionLabels || []}
            getOptionLabel={(o) => dimensionToName(o)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Dimension"
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiFormLabel-root": {
                    maxWidth: "calc(100% - 34px)",
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            select
            label="Operator"
            size="small"
            fullWidth
            value={operator}
            onChange={(e) => {
              setOperator(e.target.value as ApiOperator);
            }}
          >
            {[
              ApiOperator.EQUAL,
              ApiOperator.NOT_EQUAL,
              ApiOperator.CONTAINS,
              ApiOperator.NOT_CONTAINS,
            ].map((o) => (
              <MenuItem key={o} value={o}>
                {formatEnumVal(o)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={5}>
          {[ApiOperator.EQUAL, ApiOperator.NOT_EQUAL].includes(operator) ? (
            <Autocomplete
              id="att-value"
              multiple
              onChange={(e, value) => {
                setValues(value);
              }}
              value={values}
              filterSelectedOptions
              disableClearable
              fullWidth
              options={availableOptions || []}
              ChipProps={{
                size: "small",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Value"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          ) : (
            <TextField
              label="Value contains "
              size="small"
              fullWidth
              value={values[0] || ""}
              onChange={(e) => {
                setValues([e.target.value]);
              }}
            />
          )}
        </Grid>
      </Grid>
      <Box pl={2}>
        <Button
          color="secondary"
          size="extraSmall"
          endIcon={<Add />}
          onClick={addAtt}
          disabled={!key || values.length === 0}
        >
          Add Attribute
        </Button>
      </Box>
    </Stack>
  );
};

interface IChartBuilderAttributesProps {
  title?: string;
  attributes: ApiChartAttribute[];
  setAttributes: (val: CallbackOrVal<ApiChartAttribute[]>) => void;
  dimensions?: ApiDimension[];
}

function ChartBuilderAttributes({
  title,
  attributes,
  setAttributes,
  dimensions,
}: IChartBuilderAttributesProps) {
  const { filterableDimensions, loading } = useFilterableDimensions();
  const [selectedAttribute, setSelectedAttribute] = useState<
    ApiChartAttribute | undefined
  >();

  const onPillClick = useCallback((attribute) => {
    setSelectedAttribute(attribute);
  }, []);

  const selectableFilters = useMemo(() => {
    if (
      !dimensions ||
      dimensions.length === 0 ||
      (dimensions.length === 1 &&
        dimensions[0] === ApiDimension.DIMENSION_NOT_SET)
    ) {
      return filterableDimensions;
    }
    return filterObjectByKeys(filterableDimensions, dimensions);
  }, [filterableDimensions, dimensions]);

  return (
    <AccordianChartBuilderSidebarBlock
      title={title || "Chart Attributes"}
      subTitle={
        <>
          <TypographyHelp
            iconSize="extraSmall"
            variant="body2"
            text="Chart Attributes are permanent filters, click the question mark to find out more"
            tooltip={
              <>
                <Typography variant="body2" mb={1}>
                  Chart Attributes are permanent filters, for example if you
                  added the "{dimensionToName(ApiDimension.DIMENSION_DEVICE)} =
                  mobile" attribute, this chart will exclusively display data
                  related to mobile devices.
                </Typography>
                <Typography variant="body2" mb={1}>
                  When selecting multiple attributes within the same dimension,
                  they will be treated as an "OR" condition. For instance, if
                  you choose {dimensionToName(ApiDimension.DIMENSION_CHANNEL)} =
                  direct and {dimensionToName(ApiDimension.DIMENSION_CHANNEL)} =
                  organic, the chart will display data where the{" "}
                  {dimensionToName(ApiDimension.DIMENSION_CHANNEL)} is either
                  direct or organic.
                </Typography>
                <Typography variant="body2">
                  To apply filters, select your desired dimension and value, and
                  click the "Add" button to add the filter.
                </Typography>
              </>
            }
          />
        </>
      }
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <Box mb={2}>
            <ChartBuilderAttributesRow
              options={selectableFilters}
              attributes={attributes}
              setAttributes={setAttributes}
              passedAtribute={selectedAttribute}
              setPassedAtribute={setSelectedAttribute}
            />
          </Box>
          <Divider />
          <Box mt={2} display={"flex"} sx={{ flexWrap: "wrap" }}>
            {attributes.map((a, i) => (
              <Box key={i} mr={1} mb={1}>
                <ChartBuilderAttributionPill
                  attribute={a}
                  attributes={attributes}
                  setAttributes={setAttributes}
                  onClick={onPillClick}
                />
              </Box>
            ))}
          </Box>
        </>
      )}
    </AccordianChartBuilderSidebarBlock>
  );
}

export default ChartBuilderAttributes;
