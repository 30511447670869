import {
  ApiProject,
  ApiSimpleCampaign,
  ApiSimpleComponent,
} from "@incendium/api";
import { Button, Stack, Typography } from "@mui/material";
import SavingButton from "Components/UI/SavingButton";
import StyledDrawer, {
  StyledDrawerActions,
  StyledDrawerTitle,
} from "Components/UI/StyledDrawer";
import { saveComponent } from "features/campaigns";
import { AccordianChartBuilderSidebarBlock } from "features/chartLibrary";
import { CodeEditor } from "features/uiBuilder";
import { useNotification } from "Hooks";
import { CallbackOrVal, GenericDialoagProps } from "Interfaces";
import { useCallback, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

interface IHeadDrawerProps extends GenericDialoagProps {
  project: ApiProject;
  saveToken: string | null;
  component: ApiSimpleComponent;
  setComponent: (val: CallbackOrVal<ApiSimpleCampaign | undefined>) => void;
}

function HeadDrawer({
  project,
  saveToken,
  open,
  setOpen,
  component,
  setComponent,
}: IHeadDrawerProps) {
  const [saving, setSaving] = useState(false);
  const [htmlValue, setHtmlValue] = useState(component.head || "");
  const [favicon, setFavicon] = useState(component.favicon || "");
  const [seoTitle, setSeoTitle] = useState(component.seoTitle || "");
  const [seoDescrition, setSeoDescrition] = useState(
    component.seoDescrition || ""
  );
  const { showErrorNotification, showSuccessNotification } = useNotification();

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onChange = useCallback((v) => {
    setHtmlValue(v);
  }, []);

  const patchComponent = useCallback(async () => {
    setSaving(true);

    try {
      const res = await saveComponent(
        project.id as number,
        {
          ...component,
          head: htmlValue,
          favicon,
          seoTitle,
          seoDescrition,
        },
        saveToken ? saveToken : undefined
      );

      setComponent(res.component);
      showSuccessNotification(`${component.name} Head Saved`);
    } catch (error) {
      showErrorNotification(
        `Internal Error connecting subdomain, please try again`
      );
    }

    setSaving(false);
    onClose();
  }, [
    project,
    component,
    saveToken,
    htmlValue,
    favicon,
    seoTitle,
    seoDescrition,
    showSuccessNotification,
    showErrorNotification,
    onClose,
    setComponent,
  ]);

  return (
    <StyledDrawer anchor="right" open={open} onClose={onClose}>
      <StyledDrawerTitle>
        <Typography variant="subtitle1">Manage Head Area</Typography>
        <Typography variant="body2">
          Incorporate custom code into the HTML page's head section, set SEO
          properties and link your website's favicon.
        </Typography>
      </StyledDrawerTitle>

      <ValidatorForm onSubmit={patchComponent}>
        <AccordianChartBuilderSidebarBlock
          title="General Settings"
          subTitle="Set SEO and favicon settings for your html page."
        >
          <Stack mt={3} spacing={3}>
            <TextValidator
              variant="outlined"
              size="small"
              fullWidth
              label="Favicon Link"
              helperText="This is a small icon displayed in the browser's address bar or tab, representing a website for easy identification"
              name={"favicon"}
              value={favicon}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFavicon(e.target.value)
              }
            />
            <TextValidator
              variant="outlined"
              size="small"
              fullWidth
              label="SEO title"
              helperText="Craft a compelling SEO title to enhance your website's search engine visibility."
              name={"seoTitle"}
              value={seoTitle}
              validators={["maxStringLength:60"]}
              errorMessages={[
                "Please ensure your SEO title does not exceed 60 characters.",
              ]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSeoTitle(e.target.value)
              }
            />
            <TextValidator
              variant="outlined"
              size="small"
              fullWidth
              label="SEO description"
              helperText="Compose a concise SEO description to provide search engines with relevant information about your website."
              name={"seoDescrition"}
              value={seoDescrition}
              validators={["maxStringLength:160"]}
              errorMessages={[
                "Please ensure your SEO description does not exceed 160 characters.",
              ]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSeoDescrition(e.target.value)
              }
            />
          </Stack>
        </AccordianChartBuilderSidebarBlock>
        <AccordianChartBuilderSidebarBlock
          title="Custom Head HTML"
          subTitle="Add custom code to be added to your pages head section."
        >
          <CodeEditor
            mode="html"
            title={"Custom head"}
            onChange={onChange}
            value={htmlValue}
          />
        </AccordianChartBuilderSidebarBlock>
        <StyledDrawerActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <SavingButton
            variant="contained"
            color="primary"
            saving={saving}
            type="submit"
          >
            Save
          </SavingButton>
        </StyledDrawerActions>
      </ValidatorForm>
    </StyledDrawer>
  );
}

export default HeadDrawer;
