import { useState } from "react";

export function useSave() {
  const [saving, setSaving] = useState(false);

  const save = async (fn: () => void) => {
    const start = Date.now();
    setSaving(true);
    await fn();
    setTimeout(() => {
      setSaving(false);
    }, 1000 - (Date.now() - start));
  };

  return {
    save,
    saving,
  };
}
