/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiIntegrationActionTypeFromJSON, ApiIntegrationActionTypeToJSON, } from './ApiIntegrationActionType';
import { ApiIntegrationServiceFromJSON, ApiIntegrationServiceToJSON, } from './ApiIntegrationService';
import { ApiKeyValueFromJSON, ApiKeyValueToJSON, } from './ApiKeyValue';
import { ApiMediumFromJSON, ApiMediumToJSON, } from './ApiMedium';
import { ApiProviderFromJSON, ApiProviderToJSON, } from './ApiProvider';
export function ApiIntegrationServiceChoiceFromJSON(json) {
    return ApiIntegrationServiceChoiceFromJSONTyped(json, false);
}
export function ApiIntegrationServiceChoiceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'provider': !exists(json, 'provider') ? undefined : ApiProviderFromJSON(json['provider']),
        'integrationActionType': !exists(json, 'integrationActionType') ? undefined : ApiIntegrationActionTypeFromJSON(json['integrationActionType']),
        'integrationServiceSettings': !exists(json, 'integrationServiceSettings') ? undefined : (json['integrationServiceSettings'].map(ApiKeyValueFromJSON)),
        'medium': !exists(json, 'medium') ? undefined : ApiMediumFromJSON(json['medium']),
        'integrationService': !exists(json, 'integrationService') ? undefined : ApiIntegrationServiceFromJSON(json['integrationService']),
    };
}
export function ApiIntegrationServiceChoiceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'provider': ApiProviderToJSON(value.provider),
        'integrationActionType': ApiIntegrationActionTypeToJSON(value.integrationActionType),
        'integrationServiceSettings': value.integrationServiceSettings === undefined ? undefined : (value.integrationServiceSettings.map(ApiKeyValueToJSON)),
        'medium': ApiMediumToJSON(value.medium),
        'integrationService': ApiIntegrationServiceToJSON(value.integrationService),
    };
}
