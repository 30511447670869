/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiChartType;
(function (ApiChartType) {
    ApiChartType["UNDEFINED"] = "CHART_TYPE_UNDEFINED";
    ApiChartType["PIE"] = "CHART_TYPE_PIE";
    ApiChartType["GRAPH"] = "CHART_TYPE_GRAPH";
    ApiChartType["RADAR"] = "CHART_TYPE_RADAR";
    ApiChartType["TABLE"] = "CHART_TYPE_TABLE";
    ApiChartType["BUBBLE"] = "CHART_TYPE_BUBBLE";
    ApiChartType["TREE_MAP"] = "CHART_TYPE_TREE_MAP";
    ApiChartType["STAT_CARD"] = "CHART_TYPE_STAT_CARD";
    ApiChartType["FUNNEL"] = "CHART_TYPE_FUNNEL";
})(ApiChartType || (ApiChartType = {}));
export function ApiChartTypeFromJSON(json) {
    return ApiChartTypeFromJSONTyped(json, false);
}
export function ApiChartTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiChartTypeToJSON(value) {
    return value;
}
