import { ApiProduct } from "@incendium/api";
import { Search } from "@mui/icons-material";
import {
  CircularProgress,
  Grid,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Box } from "@mui/system";
import { locationService } from "Apis";
import { StyledSearchResults } from "Components/UI/StyledSearchResults";
import { useSelectedProject } from "Hooks";
import { useEffect, useMemo, useState } from "react";

function ProductsWithNoLocationList({ limit }: { limit?: number }) {
  const { selectedProject: project } = useSelectedProject();

  const [products, setProducts] = useState<ApiProduct[]>([]);
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    loadProducts();
  }, []);

  const filteredProducts = useMemo(() => {
    return (products || [])
      .filter((p) => {
        if ((p.name || p.sku || "").includes(search)) {
          return true;
        }
        try {
          return new RegExp(search).test(p.name || p.sku || "");
        } catch (e) {
          return false;
        }
      })
      .slice(0, limit || 500);
  }, [search, products]);

  const loadProducts = async () => {
    setLoading(true);
    const matchedProducts =
      await locationService.locationServiceListProductsMissingLocations({
        projectId: project?.id as number,
      });
    if (matchedProducts.results) {
      setProducts(matchedProducts.results || []);
    }
    setLoaded(true);
    setLoading(false);
  };

  if (loaded && products.length === 0) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: 240, width: "100%" }}
      >
        <Typography variant="subtitle2" color="secondary">
          Great news
        </Typography>
        <Typography variant="body1">
          It looks like there are no Products not linked to locations
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body2" align="right" gutterBottom>
          Showing {filteredProducts.length} of {products.length}
        </Typography>
        <TextField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          label="Search"
          fullWidth
          variant="outlined"
          size="small"
          helperText="Search by product name or sku"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledSearchResults mt={1}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid
              container
              spacing={1}
              item
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              {filteredProducts.map((p) => (
                <Grid item xs={12} key={p.id}>
                  <Typography variant="body2" noWrap component="li">
                    {p.name} (SKU : {p.sku || "?"})
                  </Typography>
                </Grid>
              ))}
            </Grid>
          )}
        </StyledSearchResults>
      </Grid>
    </>
  );
}
export default ProductsWithNoLocationList;
