import { ApiJob, ApiProject } from "@incendium/api";
import { jobService } from "Apis";
import { useSharedState } from "./useSharedState";

const fetchJobs = async (selectedProject: ApiProject | null) => {
  if (!selectedProject) {
    return [];
  }
  const res = await jobService.jobServiceListJobs({
    projectId: selectedProject.id as number,
  });
  return res.results || [];
};

export const useJobs = () => {
  const { state, setState, refetch } = useSharedState("jobs", [], fetchJobs);

  return {
    jobs: state as ApiJob[],
    setJobs: setState,
    refetch,
  };
};
