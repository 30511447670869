import { Box, BoxProps, ButtonGroup, styled } from "@mui/material";
import { motion } from "framer-motion";

export const TabContainer = styled(ButtonGroup)<{ noGrow?: boolean }>(
  ({ theme, noGrow }) => ({
    overflow: "hidden",
    borderRadius: 18,
    maxWidth: "100%",
    "& .MuiButtonGroup-grouped:not(:last-of-type)": {
      borderColor: "white",
      borderWidth: 2,
    },
    "& .MuiButtonBase-root.MuiButtonGroup-grouped": noGrow
      ? {
          minWidth: 0,
        }
      : {},
  })
);

TabContainer.defaultProps = {
  variant: "contained",
  size: "small",
  color: "secondary",
  disableElevation: true,
};

export const TabWrapper = ({ children, ...rest }: BoxProps) => (
  <Box
    component={motion.div}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    {...rest}
  >
    {children}
  </Box>
);
