import { Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Header } from "Components/Header/header";
import { Leftmenu } from "features/leftMenu";
import { Glass } from "Components/Glass";
import { useLayoutContext } from "Providers/LayoutProvider";
import { motion } from "framer-motion";
import { Box } from "@mui/system";
import { useProjectTypeTransition } from "features/project";
import { CenterPage } from "Components/CenterPage/CenterPage";
import { memo } from "react";

const useDashboardStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(0),
    display: "flex",
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      display: "block",
      overflow: "hidden",
    },
    background: theme.palette.primary.dark,
    overflow: "hidden",
  },
  main: {
    minWidth: 0,
    flex: 1,
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    overflow: "hidden",
    backgroundColor: theme.palette.background.default,
  },
  sideBar: {
    maxWidth: 356,
    background: theme.palette.background.default,
    height: "100vh",
    boxShadow: `inset 2px 0 0 #d8e0f8, inset 6px 0 0 #9ea4c6`,
  },
  fillSpace: {
    flex: 1,
  },
  content: {
    padding: theme.spacing(0, 5),
    flex: 1,
  },
}));
const DashboardLayout = ({
  children,
  hideNav,
  hideBreadcrumbs,
  hideUser,
}: {
  children: React.ReactNode;
  hideNav?: boolean;
  hideBreadcrumbs?: boolean;
  hideUser?: boolean;
}) => {
  const classes = useDashboardStyles();
  const { contentRight } = useLayoutContext();
  const projectTypeTransitioning = useProjectTypeTransition();

  const variants = {
    open: {
      opacity: 1,
      width: contentRight,
      height: "auto",
      transition: {
        opacity: {
          duration: 0.8,
        },
        width: {
          duration: 0.2,
        },
        height: {
          duration: 0,
        },
      },
    },
    closed: {
      opacity: 0,
      width: 0,
      overflow: "hidden",
      height: 0,
      transition: {
        opacity: {
          duration: 0.1,
        },
        width: {
          duration: 0.3,
        },
        height: {
          duration: 0,
          delay: 0.3,
        },
      },
    },
  };

  if (projectTypeTransitioning) {
    return (
      <CenterPage calcHeight>
        <Typography variant="h1" mb={1}>
          Project Type Updated!
        </Typography>
        <Typography>
          The UI is now adapting to reflect your new selection. Regenerating
          data, please wait a moment.
        </Typography>
      </CenterPage>
    );
  }

  return (
    <motion.div
      key="dash"
      initial="closed"
      animate={contentRight > 0 ? "open" : "closed"}
    >
      <Box
        className={classes.container}
        p={hideNav ? 1 : 0}
        pl={hideNav ? 1 : 0}
      >
        {!hideNav && <Leftmenu hideUser={hideUser} />}
        <Box className={classes.main}>
          <Header hideBreadcrumbs={hideBreadcrumbs} />
          <div className={classes.content}>{children}</div>
        </Box>
        <Glass
          id="rightSideBar"
          className={classes.sideBar}
          blur={2}
          square
          noBorder
        >
          <motion.div key="right-side" variants={variants}></motion.div>
        </Glass>
      </Box>
    </motion.div>
  );
};

export default memo(DashboardLayout);
