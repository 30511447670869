/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiDataFromJSON, ApiDataToJSON, } from './ApiData';
import { ApiPageFromJSON, ApiPageToJSON, } from './ApiPage';
import { ApiProductBrandFromJSON, ApiProductBrandToJSON, } from './ApiProductBrand';
import { ApiProductCategoryFromJSON, ApiProductCategoryToJSON, } from './ApiProductCategory';
import { ApiProductNameFromJSON, ApiProductNameToJSON, } from './ApiProductName';
import { ApiProfitMarginFromJSON, ApiProfitMarginToJSON, } from './ApiProfitMargin';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiProductFromJSON(json) {
    return ApiProductFromJSONTyped(json, false);
}
export function ApiProductFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'sku': !exists(json, 'sku') ? undefined : json['sku'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'displayPrice': !exists(json, 'displayPrice') ? undefined : json['displayPrice'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'pages': !exists(json, 'pages') ? undefined : (json['pages'].map(ApiPageFromJSON)),
        'pageViewImpressions': !exists(json, 'pageViewImpressions') ? undefined : (json['pageViewImpressions'].map(ApiDataFromJSON)),
        'brand': !exists(json, 'brand') ? undefined : json['brand'],
        'productName': !exists(json, 'productName') ? undefined : ApiProductNameFromJSON(json['productName']),
        'productCategory': !exists(json, 'productCategory') ? undefined : ApiProductCategoryFromJSON(json['productCategory']),
        'productBrand': !exists(json, 'productBrand') ? undefined : ApiProductBrandFromJSON(json['productBrand']),
        'productNameId': !exists(json, 'productNameId') ? undefined : json['productNameId'],
        'productCategoryId': !exists(json, 'productCategoryId') ? undefined : json['productCategoryId'],
        'productBrandId': !exists(json, 'productBrandId') ? undefined : json['productBrandId'],
        'profitMargins': !exists(json, 'profitMargins') ? undefined : (json['profitMargins'].map(ApiProfitMarginFromJSON)),
        'marginsSynced': !exists(json, 'marginsSynced') ? undefined : (new Date(json['marginsSynced'])),
        'analyticsSynced': !exists(json, 'analyticsSynced') ? undefined : json['analyticsSynced'],
    };
}
export function ApiProductToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'sku': value.sku,
        'description': value.description,
        'price': value.price,
        'displayPrice': value.displayPrice,
        'category': value.category,
        'imageUrl': value.imageUrl,
        'productId': value.productId,
        'url': value.url,
        'project': ApiProjectToJSON(value.project),
        'projectId': value.projectId,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'pages': value.pages === undefined ? undefined : (value.pages.map(ApiPageToJSON)),
        'pageViewImpressions': value.pageViewImpressions === undefined ? undefined : (value.pageViewImpressions.map(ApiDataToJSON)),
        'brand': value.brand,
        'productName': ApiProductNameToJSON(value.productName),
        'productCategory': ApiProductCategoryToJSON(value.productCategory),
        'productBrand': ApiProductBrandToJSON(value.productBrand),
        'productNameId': value.productNameId,
        'productCategoryId': value.productCategoryId,
        'productBrandId': value.productBrandId,
        'profitMargins': value.profitMargins === undefined ? undefined : (value.profitMargins.map(ApiProfitMarginToJSON)),
        'marginsSynced': value.marginsSynced === undefined ? undefined : (value.marginsSynced.toISOString()),
        'analyticsSynced': value.analyticsSynced,
    };
}
