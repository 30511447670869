/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiChartCrawlerFromJSON, ApiChartCrawlerToJSON, } from './ApiChartCrawler';
import { ApiChartTemplateFromJSON, ApiChartTemplateToJSON, } from './ApiChartTemplate';
import { ApiChartTypeFromJSON, ApiChartTypeToJSON, } from './ApiChartType';
import { ApiChartVisibilityFromJSON, ApiChartVisibilityToJSON, } from './ApiChartVisibility';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiChartFromJSON(json) {
    return ApiChartFromJSONTyped(json, false);
}
export function ApiChartFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : ApiChartTypeFromJSON(json['type']),
        'yAxisKeys': !exists(json, 'yAxisKeys') ? undefined : json['yAxisKeys'],
        'dimension': !exists(json, 'dimension') ? undefined : json['dimension'],
        'attributes': !exists(json, 'attributes') ? undefined : json['attributes'],
        'orderBy': !exists(json, 'orderBy') ? undefined : json['orderBy'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'template': !exists(json, 'template') ? undefined : ApiChartTemplateFromJSON(json['template']),
        'dimensionDecorator': !exists(json, 'dimensionDecorator') ? undefined : json['dimensionDecorator'],
        'visibility': !exists(json, 'visibility') ? undefined : ApiChartVisibilityFromJSON(json['visibility']),
        'displayOptions': !exists(json, 'displayOptions') ? undefined : json['displayOptions'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'crawler': !exists(json, 'crawler') ? undefined : ApiChartCrawlerFromJSON(json['crawler']),
        'comparison': !exists(json, 'comparison') ? undefined : json['comparison'],
        'includeEmpty': !exists(json, 'includeEmpty') ? undefined : json['includeEmpty'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'ranks': !exists(json, 'ranks') ? undefined : json['ranks'],
        'having': !exists(json, 'having') ? undefined : json['having'],
        'includeSubdomains': !exists(json, 'includeSubdomains') ? undefined : json['includeSubdomains'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'withTotals': !exists(json, 'withTotals') ? undefined : json['withTotals'],
    };
}
export function ApiChartToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'type': ApiChartTypeToJSON(value.type),
        'yAxisKeys': value.yAxisKeys,
        'dimension': value.dimension,
        'attributes': value.attributes,
        'orderBy': value.orderBy,
        'limit': value.limit,
        'project': ApiProjectToJSON(value.project),
        'projectId': value.projectId,
        'template': ApiChartTemplateToJSON(value.template),
        'dimensionDecorator': value.dimensionDecorator,
        'visibility': ApiChartVisibilityToJSON(value.visibility),
        'displayOptions': value.displayOptions,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'crawler': ApiChartCrawlerToJSON(value.crawler),
        'comparison': value.comparison,
        'includeEmpty': value.includeEmpty,
        'description': value.description,
        'ranks': value.ranks,
        'having': value.having,
        'includeSubdomains': value.includeSubdomains,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'withTotals': value.withTotals,
    };
}
