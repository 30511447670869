import { ApiShareParameters } from "@incendium/api";
import { Typography } from "@mui/material";
import { authService, userService } from "Apis";
import { CenterPage } from "Components/CenterPage/CenterPage";
import moment from "moment";
import FromToProvider, { useFromToContext } from "Providers/FromToProvider";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMount } from "react-use";
import ReportPage from "./ReportPage";
import DashboardLayout from "Components/DashboardLayout/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllClientsAndProjectsThunk,
  loginSuccess,
  setUser,
} from "Actions";
import { projectSelector } from "Selectors/projectSelector";
import { saveAuthToken } from "Helpers/saveAuthToken";
import Loading from "Components/Loading/Loading";

const SingleReportPageInner = ({ hash }: { hash: string }) => {
  const [params, setParams] = useState<ApiShareParameters | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState(false);

  useMount(() => {
    const load = async () => {
      try {
        const valid = await authService.authValidateToken({
          body: {
            token: hash,
          },
        });

        if (valid) {
          const res = {
            token: hash,
          };
          saveAuthToken(res);
          const u = await userService.userServiceGetAuthUser();
          dispatch(setUser(u));
          dispatch(loginSuccess(res));

          dispatch(
            fetchAllClientsAndProjectsThunk({
              clientId: Number(valid.clientId),
              projectId: Number(valid.projectId),
            })
          );

          setParams(valid);
          const currentLocation = history.location;

          const newSearch = new URLSearchParams(currentLocation.search);
          newSearch.set("cp", btoa(`${valid.clientId}|${valid.projectId}`));

          history.push({
            pathname: currentLocation.pathname,
            search: `?${newSearch.toString()}`,
          });
        }
      } catch (error) {
        setError(true);
      }
    };

    if (hash) {
      load();
    }
  });

  if (error) {
    return (
      <CenterPage calcHeight>
        <Typography variant="h2" gutterBottom>
          There was an error loading this report
        </Typography>
        <Typography variant="body1">
          Unfortunately we can't load this report, this may be because your link
          <br />
          has expired. Please contact your account manager for a new one
        </Typography>
      </CenterPage>
    );
  }

  return (
    <>
      {!params ? (
        <Loading fullHeight size={60} text="Fetching Report" />
      ) : (
        <SingleReportPageLoaded valid={params} />
      )}
    </>
  );
};
const SingleReportPageLoaded = ({ valid }: { valid: ApiShareParameters }) => {
  const project = useSelector(
    projectSelector(`${valid.clientId}`, `${valid.projectId}`)
  );
  const { setFrom, setTo, setLastNDays } = useFromToContext();

  useMount(() => {
    setFrom(valid.from ? moment(valid.from) : null);
    setTo(valid.to ? moment(valid.to) : null);
    setLastNDays(valid.lastNDays as number);
  });

  return (
    <>
      {!project ? (
        <Loading fullHeight size={60} text="Fetching Report" />
      ) : (
        <DashboardLayout hideNav hideBreadcrumbs hideUser>
          <ReportPage
            readOnly
            passedProject={project}
            passedClient={{ id: valid.clientId }}
            passedReportID={valid.reportId}
          />
        </DashboardLayout>
      )}
    </>
  );
};

function SingleReportPage() {
  const { hash } = useParams<{ hash: string }>();

  return (
    <FromToProvider>
      <SingleReportPageInner hash={hash} />
    </FromToProvider>
  );
}

export default SingleReportPage;
