import { createContext, ReactNode, useContext, useMemo, useState } from "react";

type TLayoutContext = {
  contentRight: number;
  setContentRight: React.Dispatch<React.SetStateAction<number>>;
  hasPadding: boolean;
  setHasPadding: React.Dispatch<React.SetStateAction<boolean>>;
  pageTitle: string | ReactNode;
  setPageTitle: React.Dispatch<React.SetStateAction<string | ReactNode>>;
  titleAction?: ReactNode;
  setTitleAction: React.Dispatch<React.SetStateAction<ReactNode | undefined>>;
};

export const LayoutContext = createContext<TLayoutContext>({
  contentRight: 0,
  setContentRight: () => {},
  hasPadding: true,
  setHasPadding: () => {},
  pageTitle: "",
  setPageTitle: () => {},
  titleAction: "",
  setTitleAction: () => {},
});
export const useLayoutContext = () => useContext(LayoutContext);

const LayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const [contentRight, setContentRight] = useState(0);
  const [hasPadding, setHasPadding] = useState(true);
  const [pageTitle, setPageTitle] = useState<string | ReactNode>("");
  const [titleAction, setTitleAction] = useState<ReactNode | undefined>();
  const state = useMemo(
    () => ({
      contentRight,
      setContentRight,
      hasPadding,
      setHasPadding,
      pageTitle,
      setPageTitle,
      titleAction,
      setTitleAction,
    }),
    [
      contentRight,
      hasPadding,
      pageTitle,
      setPageTitle,
      titleAction,
      setTitleAction,
    ]
  );

  return (
    <LayoutContext.Provider value={state}>{children}</LayoutContext.Provider>
  );
};

export default LayoutProvider;
