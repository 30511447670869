/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiConversionFromJSON, ApiConversionToJSON, } from './ApiConversion';
import { ApiOperatorFromJSON, ApiOperatorToJSON, } from './ApiOperator';
import { ApiSessionConditionCustomFromJSON, ApiSessionConditionCustomToJSON, } from './ApiSessionConditionCustom';
import { ApiSessionEffectivenessConditionGroupFromJSON, ApiSessionEffectivenessConditionGroupToJSON, } from './ApiSessionEffectivenessConditionGroup';
export function ApiSessionEffectivenessConditionFromJSON(json) {
    return ApiSessionEffectivenessConditionFromJSONTyped(json, false);
}
export function ApiSessionEffectivenessConditionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'conditionGroup': !exists(json, 'conditionGroup') ? undefined : ApiSessionEffectivenessConditionGroupFromJSON(json['conditionGroup']),
        'conditionGroupId': !exists(json, 'conditionGroupId') ? undefined : json['conditionGroupId'],
        'operator': !exists(json, 'operator') ? undefined : ApiOperatorFromJSON(json['operator']),
        'custom': !exists(json, 'custom') ? undefined : ApiSessionConditionCustomFromJSON(json['custom']),
        'value': !exists(json, 'value') ? undefined : json['value'],
        'conversion': !exists(json, 'conversion') ? undefined : ApiConversionFromJSON(json['conversion']),
        'conversionId': !exists(json, 'conversionId') ? undefined : json['conversionId'],
    };
}
export function ApiSessionEffectivenessConditionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'conditionGroup': ApiSessionEffectivenessConditionGroupToJSON(value.conditionGroup),
        'conditionGroupId': value.conditionGroupId,
        'operator': ApiOperatorToJSON(value.operator),
        'custom': ApiSessionConditionCustomToJSON(value.custom),
        'value': value.value,
        'conversion': ApiConversionToJSON(value.conversion),
        'conversionId': value.conversionId,
    };
}
