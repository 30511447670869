import { Box, styled, Typography } from "@mui/material";

const StyledTypography = styled(Typography)(({ theme }) => ({
  width: "calc(100% - 60px)",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.secondary.main}`,
  "&:last-of-type": {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  color: theme.palette.secondary.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0.8, 1.6),
}));

export const Tag = ({
  text,
  icons,
  color,
}: {
  text: string;
  color: "primary" | "secondary";
  icons: React.ReactElement;
}) => {
  return (
    <StyledBox>
      <StyledTypography variant="body2" color={color}>
        {text}
      </StyledTypography>
      <Box
        style={{
          minWidth: "60px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {icons}
      </Box>
    </StyledBox>
  );
};
