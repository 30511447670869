import { useSelectedProject } from "Hooks";
import { useReports } from "Hooks/useReports";
import { matchPath, useLocation } from "react-router-dom";
import Standard from "Assets/icons/Standard-reports-icon.png";
import Optimize from "Assets/icons/optimize.png";
import IconChartLib from "Assets/icons/Chart-library-logo.png";
import { ReactComponent as IconAudienceSecondTier } from "Assets/Images/icon-nav2-audience.svg";
import { ReactComponent as IconProductsTier } from "Assets/Images/icon-nav2-products.svg";
import { ReactComponent as IconJobsTier } from "Assets/Images/icon-nav2-jobs.svg";
import { ReactComponent as IconLocationsSecondTier } from "Assets/Images/icon-nav2-locations.svg";
import { ReactComponent as IconSalesSecondTier } from "Assets/Images/icon-nav2-sales.svg";
import { ReactComponent as IconQuickInsightsSecondTier } from "Assets/Images/icon-nav2-quick-insights.svg";
import { ReactComponent as IconMarketingPerformanceSecondTier } from "Assets/Images/icon-nav2-marketing-performance.svg";
import AccessLevel from "Components/AccessLevel/AccessLevel";
import { ApiACLRole, ApiProjectType } from "@incendium/api";
import { useHasProjectType } from "features/project";
import {
  LeftMenuItem,
  LeftMenuListItem,
  LeftMenuSubItem,
} from "features/leftMenu";

function AnalyticsMenu() {
  const location = useLocation();
  const { selectedProject, selectedClient } = useSelectedProject();
  const { reports } = useReports();
  const hasProjectType = useHasProjectType();

  const isStandard = matchPath(location.pathname, {
    path: "/clients/:clientId/projects/:projectId/analyse/standard-reports",
    exact: false,
    strict: false,
  });
  const isReports = matchPath(location.pathname, {
    path: "/clients/:clientId/projects/:projectId/analyse/reports",
    exact: false,
    strict: false,
  });
  const isOptimization = matchPath(location.pathname, {
    path: "/clients/:clientId/projects/:projectId/analyse/optimization",
    exact: false,
    strict: false,
  });

  return (
    <>
      <LeftMenuItem
        text="Analytics"
        active={!!isStandard}
        imagePath={IconChartLib}
        to="#"
      >
        <LeftMenuSubItem
          text="Quick Insights"
          icon={<IconQuickInsightsSecondTier />}
          to={
            hasProjectType(ApiProjectType.TYPE_ECOMMERCE)
              ? `/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/quick-insights-ecom`
              : `/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/quick-insights`
          }
        >
          {hasProjectType(ApiProjectType.TYPE_ECOMMERCE) && (
            <LeftMenuListItem
              text="Ecommerce"
              to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/quick-insights-ecom`}
            />
          )}
          <LeftMenuListItem
            text="Overview"
            to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/quick-insights`}
          />
        </LeftMenuSubItem>
        <LeftMenuSubItem
          text="Marketing Performance"
          icon={<IconMarketingPerformanceSecondTier />}
          to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/marketing-performance/paid-media`}
        >
          <LeftMenuListItem
            text="Paid Media"
            to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/marketing-performance/paid-media`}
          />
          <LeftMenuListItem
            text="Organic Search"
            to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/marketing-performance/organic`}
          />
          <LeftMenuListItem
            text="Email Marketing"
            to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/marketing-performance/email-performance`}
          />
          <LeftMenuListItem
            text="Organic Social"
            to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/marketing-performance/organic-social`}
          />
        </LeftMenuSubItem>
        <LeftMenuSubItem
          text="Audience"
          icon={<IconAudienceSecondTier />}
          to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/audience-comparison`}
        >
          <LeftMenuListItem
            text="Comparison"
            to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/audience-comparison`}
          />
          <LeftMenuListItem
            text="Deep dive"
            to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/audience-deep-dive`}
          />
          <LeftMenuListItem
            text="New & Returning"
            to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/audience-insights-new-and-returning`}
          />
          <LeftMenuListItem
            text="User Journey"
            to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/audience-insights-user-journey`}
          />
          <LeftMenuListItem
            text="User Dashboard"
            to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/user-dashboard`}
          />
        </LeftMenuSubItem>
        {hasProjectType(ApiProjectType.TYPE_ECOMMERCE) && (
          <LeftMenuSubItem
            text="Products"
            icon={<IconProductsTier />}
            to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/products-overview`}
          >
            <LeftMenuListItem
              text="Products Overview"
              to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/products-overview`}
            />
            <LeftMenuListItem
              text="Product Deep Dive"
              to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/product-deep-dive`}
            />
            <LeftMenuListItem
              text="Product category deep dive"
              to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/product-category-deep-dive`}
            />
            <LeftMenuListItem
              text="Product brand deep dive"
              to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/product-brand-deep-dive`}
            />

            <LeftMenuListItem
              text="Products performance map"
              to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/products-performance-map`}
            />
          </LeftMenuSubItem>
        )}
        {hasProjectType(ApiProjectType.TYPE_RECRUITMENT) && (
          <LeftMenuSubItem
            text="Jobs"
            icon={<IconJobsTier />}
            to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/jobs-overview`}
          >
            <LeftMenuListItem
              text="Jobs Overview"
              to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/jobs-overview`}
            />
            <LeftMenuListItem
              text="Jobs deep dive"
              to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/jobs-deep-dive`}
            />
            <LeftMenuListItem
              text="Jobs category deep dive"
              to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/jobs-category-deep-dive`}
            />
            <LeftMenuListItem
              text="Jobs performance map"
              to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/jobs-performance-map`}
            />
          </LeftMenuSubItem>
        )}
        <LeftMenuSubItem
          text="Content"
          icon={<IconLocationsSecondTier />}
          to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/locations-overview`}
        >
          <LeftMenuListItem
            text="Locations Overview"
            to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/locations-overview`}
          />

          <LeftMenuListItem
            text="User Journey Explorer"
            to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/journey-explorer`}
          />
        </LeftMenuSubItem>
        {hasProjectType([
          ApiProjectType.TYPE_B2_B,
          ApiProjectType.TYPE_RECRUITMENT,
        ]) && (
          <LeftMenuSubItem
            text="Sales"
            icon={<IconSalesSecondTier />}
            to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/sales-flow-analysis-report`}
          >
            <LeftMenuListItem
              text="Sales flow analysis"
              to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/sales-flow-analysis-report`}
            />
          </LeftMenuSubItem>
        )}
      </LeftMenuItem>

      <LeftMenuItem
        text="Optimization"
        active={!!isOptimization}
        imagePath={Optimize}
        to="#"
      >
        <LeftMenuListItem
          text="Marketing Performance Analysis"
          to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/optimization/marketing-performance-analysis`}
        />
        {hasProjectType(ApiProjectType.TYPE_ECOMMERCE) && (
          <LeftMenuListItem
            text="Product Performance Analysis"
            to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/optimization/product-performance-analysis`}
          />
        )}
        <LeftMenuListItem
          text="Content Performance Analysis"
          to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/optimization/content-performance-analysis`}
        />
        <LeftMenuListItem
          text="Conversion Lab"
          to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/optimization/conversion-lab`}
        />
        <LeftMenuListItem
          text="Lightning Landers"
          to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/optimization/lightning-landers`}
        />
        <LeftMenuListItem
          text="Rezonate Campaigns"
          to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/optimization/rezonate-report`}
        />
      </LeftMenuItem>
      <LeftMenuItem
        text="Reporting"
        active={!!isReports}
        imagePath={Standard}
        to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/reports`}
      >
        <AccessLevel role={ApiACLRole.ANALYTICS_ADMIN} write>
          <LeftMenuListItem
            text="Chart Library"
            to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/reports/chart-library`}
          />
        </AccessLevel>
        <LeftMenuListItem
          exact
          text="Reports"
          to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/reports`}
        />

        {reports
          .filter((r) => r.favourite)
          .map((r) => (
            <LeftMenuListItem
              key={r.id}
              to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/reports/${r.id}`}
              text={r.name || ""}
              favourite
            />
          ))}
      </LeftMenuItem>
    </>
  );
}

export default AnalyticsMenu;
