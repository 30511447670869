/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiACLRole;
(function (ApiACLRole) {
    ApiACLRole["UNSPECIFIED"] = "ACL_ROLE_UNSPECIFIED";
    ApiACLRole["SUPER_ADMIN"] = "ACL_ROLE_SUPER_ADMIN";
    ApiACLRole["DOMAIN_ADMIN"] = "ACL_ROLE_DOMAIN_ADMIN";
    ApiACLRole["CLIENT_ADMIN"] = "ACL_ROLE_CLIENT_ADMIN";
    ApiACLRole["CLIENT_OBSERVER"] = "ACL_ROLE_CLIENT_OBSERVER";
    ApiACLRole["PROJECT_ADMIN"] = "ACL_ROLE_PROJECT_ADMIN";
    ApiACLRole["PROJECT_OBSERVER"] = "ACL_ROLE_PROJECT_OBSERVER";
    ApiACLRole["ANALYTICS_ADMIN"] = "ACL_ROLE_ANALYTICS_ADMIN";
    ApiACLRole["ANALYTICS_OBSERVER"] = "ACL_ROLE_ANALYTICS_OBSERVER";
})(ApiACLRole || (ApiACLRole = {}));
export function ApiACLRoleFromJSON(json) {
    return ApiACLRoleFromJSONTyped(json, false);
}
export function ApiACLRoleFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiACLRoleToJSON(value) {
    return value;
}
