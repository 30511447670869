/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiUserActionLeadScoreFromJSON, ApiUserActionLeadScoreToJSON, } from './ApiUserActionLeadScore';
import { ApiUserActionPageViewFromJSON, ApiUserActionPageViewToJSON, } from './ApiUserActionPageView';
import { ApiUserActionRetentionFromJSON, ApiUserActionRetentionToJSON, } from './ApiUserActionRetention';
import { ApiUserActionStatusFromJSON, ApiUserActionStatusToJSON, } from './ApiUserActionStatus';
import { ApiUserActionTypeFromJSON, ApiUserActionTypeToJSON, } from './ApiUserActionType';
export function ApiUserActionFromJSON(json) {
    return ApiUserActionFromJSONTyped(json, false);
}
export function ApiUserActionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'pageView': !exists(json, 'pageView') ? undefined : ApiUserActionPageViewFromJSON(json['pageView']),
        'leadScore': !exists(json, 'leadScore') ? undefined : ApiUserActionLeadScoreFromJSON(json['leadScore']),
        'leadStatus': !exists(json, 'leadStatus') ? undefined : ApiUserActionStatusFromJSON(json['leadStatus']),
        'retention': !exists(json, 'retention') ? undefined : ApiUserActionRetentionFromJSON(json['retention']),
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
        'type': !exists(json, 'type') ? undefined : ApiUserActionTypeFromJSON(json['type']),
    };
}
export function ApiUserActionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'pageView': ApiUserActionPageViewToJSON(value.pageView),
        'leadScore': ApiUserActionLeadScoreToJSON(value.leadScore),
        'leadStatus': ApiUserActionStatusToJSON(value.leadStatus),
        'retention': ApiUserActionRetentionToJSON(value.retention),
        'timestamp': value.timestamp,
        'type': ApiUserActionTypeToJSON(value.type),
    };
}
