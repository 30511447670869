/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function ApiCreatePageMetricsPayloadFromJSON(json) {
    return ApiCreatePageMetricsPayloadFromJSONTyped(json, false);
}
export function ApiCreatePageMetricsPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'averageScrollSpeed': !exists(json, 'averageScrollSpeed') ? undefined : json['averageScrollSpeed'],
        'timeOnPageSeconds': !exists(json, 'timeOnPageSeconds') ? undefined : json['timeOnPageSeconds'],
        'scrolledPercentage': !exists(json, 'scrolledPercentage') ? undefined : json['scrolledPercentage'],
        'viewedPercentage': !exists(json, 'viewedPercentage') ? undefined : json['viewedPercentage'],
        'clsValue': !exists(json, 'clsValue') ? undefined : json['clsValue'],
        'clsScore': !exists(json, 'clsScore') ? undefined : json['clsScore'],
        'inpValue': !exists(json, 'inpValue') ? undefined : json['inpValue'],
        'inpScore': !exists(json, 'inpScore') ? undefined : json['inpScore'],
        'lcpValue': !exists(json, 'lcpValue') ? undefined : json['lcpValue'],
        'lcpScore': !exists(json, 'lcpScore') ? undefined : json['lcpScore'],
        'fcpValue': !exists(json, 'fcpValue') ? undefined : json['fcpValue'],
        'fcpScore': !exists(json, 'fcpScore') ? undefined : json['fcpScore'],
    };
}
export function ApiCreatePageMetricsPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'averageScrollSpeed': value.averageScrollSpeed,
        'timeOnPageSeconds': value.timeOnPageSeconds,
        'scrolledPercentage': value.scrolledPercentage,
        'viewedPercentage': value.viewedPercentage,
        'clsValue': value.clsValue,
        'clsScore': value.clsScore,
        'inpValue': value.inpValue,
        'inpScore': value.inpScore,
        'lcpValue': value.lcpValue,
        'lcpScore': value.lcpScore,
        'fcpValue': value.fcpValue,
        'fcpScore': value.fcpScore,
    };
}
