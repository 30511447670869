import { ApiGetUserJourneyResponse } from "@incendium/api";
import { useSelectedProject } from "Hooks";
import { getAnalyticsLeadJourney } from "features/leadAnalytics";
import { useCallback, useState } from "react";
import { useDebounce } from "react-use";

const useLeadJourneyData = (leadId: number) => {
  const { selectedProject } = useSelectedProject();
  const [chartData, setChartData] = useState<ApiGetUserJourneyResponse | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>(undefined);
  const [search, setSearch] = useState<string>("");

  const loadData = useCallback(async (projectId: number, leadId: number) => {
    setLoading(true);
    setError(undefined);

    try {
      const res = await getAnalyticsLeadJourney(projectId, leadId);
      setChartData(res);

      setLoading(false);
      setError(undefined);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }, []);

  useDebounce(
    () => {
      if (!selectedProject?.id) {
        return;
      }
      loadData(selectedProject.id, leadId);
    },
    100,
    [selectedProject?.id, search, loadData]
  );

  return { chartData, loading, error, search, setSearch };
};

export default useLeadJourneyData;
