/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiJobTaggerUniqueField;
(function (ApiJobTaggerUniqueField) {
    ApiJobTaggerUniqueField["TITLE"] = "JOB_FIELD_TITLE";
    ApiJobTaggerUniqueField["DESCRIPTION"] = "JOB_FIELD_DESCRIPTION";
    ApiJobTaggerUniqueField["ID"] = "JOB_FIELD_ID";
    ApiJobTaggerUniqueField["CATEGORY"] = "JOB_FIELD_CATEGORY";
    ApiJobTaggerUniqueField["SALARY_RANGE"] = "JOB_FIELD_SALARY_RANGE";
    ApiJobTaggerUniqueField["CONTRACT_TYPE"] = "JOB_FIELD_CONTRACT_TYPE";
    ApiJobTaggerUniqueField["LOCATION"] = "JOB_FIELD_LOCATION";
})(ApiJobTaggerUniqueField || (ApiJobTaggerUniqueField = {}));
export function ApiJobTaggerUniqueFieldFromJSON(json) {
    return ApiJobTaggerUniqueFieldFromJSONTyped(json, false);
}
export function ApiJobTaggerUniqueFieldFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiJobTaggerUniqueFieldToJSON(value) {
    return value;
}
