import { ApiProject, ApiSimpleComponent } from "@incendium/api";
import Loading from "Components/Loading/Loading";
import { Suspense, useCallback, useState } from "react";
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Portal,
  Stack,
  styled,
} from "@mui/material";
import { useDebounce } from "react-use";
import { PageAction } from "consts";
import SavingButton from "Components/UI/SavingButton";
import { useNotification } from "Hooks";
import {
  ArrowBack,
  CloseFullscreen,
  Laptop,
  OpenInFull,
  PhoneAndroid,
  Tablet,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import CodeEditor from "features/uiBuilder/components/CodeEditor";
import { saveComponent } from "features/campaigns";

interface ICodeComponentBuilderProps {
  component: ApiSimpleComponent;
  saveToken: string | null;
  project: ApiProject;
  onSaved: (v: ApiSimpleComponent) => void;
}
const JavascriptEditor = ({ height, onChange, value }) => {
  return (
    <CodeEditor
      mode="javascript"
      title={"JS"}
      height={height}
      onChange={onChange}
      value={value}
    />
  );
};

const HtmlEditor = ({ height, onChange, value }) => {
  return (
    <CodeEditor
      mode="html"
      title={"HTML (body content only)"}
      height={height}
      onChange={onChange}
      value={value}
    />
  );
};

const CssEditor = ({ height, onChange, value }) => {
  return (
    <CodeEditor
      mode="css"
      title={"CSS"}
      height={height}
      onChange={onChange}
      value={value}
    />
  );
};

const PreviewContainer = styled(Box)<{ fullscreen?: boolean }>(
  ({ theme, fullscreen }) => ({
    background: "#1d1e22",
    height: "100%",
    position: fullscreen ? "fixed" : "relative",
    top: fullscreen ? 0 : undefined,
    left: fullscreen ? 0 : undefined,
    zIndex: fullscreen ? 9999 : undefined,
    width: "100%",
    "& iframe": {
      width: "100%",
      height: "100vh",
      border: 0,
    },
  })
);

const StyledAllotmentWrapper = styled(Allotment)(({ theme }) => ({
  border: `4px solid ${theme.palette.secondary.dark}`,
  "& .sash": {
    background: theme.palette.secondary.dark,
    "&:hover,&.sash-hover": {
      "&:before": {
        background: theme.palette.info.main,
      },
    },
  },
  "& .sash-vertical": {
    width: "4px!important",
  },
  "& .sash-horizontal": {
    height: "4px!important",
  },
}));

function CodeComponentBuilder({
  component,
  project,
  saveToken,
  onSaved,
}: ICodeComponentBuilderProps) {
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const { showErrorNotification, showSuccessNotification } = useNotification();
  const [heightValue, setHeightValue] = useState(485);
  const [debouncedHeightValue, setDebouncedHeightValue] = useState(heightValue);
  useDebounce(
    () => {
      setDebouncedHeightValue(heightValue);
    },
    300,
    [heightValue]
  );
  const [htmlValue, setHtmlValue] = useState(component.html || "");
  const [jsValue, setJsValue] = useState(component.js || "");
  const [cssValue, setCssValue] = useState(component.css || "");
  const [output, setOutput] = useState("");
  const [display, setDisplay] = useState<"desktop" | "tablet" | "mobile">(
    "desktop"
  );
  const [fullScreen, setFullScreen] = useState(false);

  const patchComponent = useCallback(async () => {
    setSaving(true);

    try {
      const res = await saveComponent(
        project?.id as number,
        {
          ...component,
          html: htmlValue,
          css: cssValue,
          js: jsValue,
        },
        saveToken || undefined
      );
      onSaved(res.component!);
      showSuccessNotification(`${component.name} Saved`);
    } catch (error) {
      showErrorNotification(
        `Internal Error connecting subdomain, please try again`
      );
    }

    setSaving(false);
  }, [
    project,
    component,
    saveToken,
    htmlValue,
    cssValue,
    jsValue,
    showSuccessNotification,
    showErrorNotification,
    onSaved,
  ]);

  const goBack = useCallback(() => {
    history.push(".");
  }, [history]);

  const saveAndBack = useCallback(async () => {
    await patchComponent();
    goBack();
  }, [patchComponent, goBack]);

  useDebounce(
    () => {
      setOutput(`<html>
    <head>
    ${component.head}
    <style>
    ${cssValue}
    </style>
    </head>
    <body>
    ${htmlValue}
    <script type="text/javascript">
    ${jsValue}
    </script>
    </body>
  </html>`);
    },
    1000,
    [htmlValue, cssValue, jsValue, component]
  );

  return (
    <>
      <Portal container={() => document.getElementById(PageAction)}>
        <Stack direction={"row"} spacing={1}>
          <Button
            startIcon={<ArrowBack />}
            variant="outlined"
            color="primary"
            size="small"
            onClick={goBack}
          >
            Back to list
          </Button>
          <SavingButton
            variant="contained"
            color="primary"
            saving={saving}
            size="small"
            onClick={patchComponent}
          >
            Save
          </SavingButton>
          <SavingButton
            variant="contained"
            color="primary"
            saving={saving}
            size="small"
            onClick={saveAndBack}
          >
            Save & back
          </SavingButton>
        </Stack>
      </Portal>
      <Suspense fallback={<Loading />}>
        <StyledAllotmentWrapper
          vertical
          onDragEnd={(sizes) => {
            setHeightValue(sizes[0]);
          }}
        >
          <Allotment.Pane minSize={200} preferredSize={485}>
            <Allotment>
              <Allotment.Pane minSize={300}>
                <HtmlEditor
                  height={debouncedHeightValue}
                  onChange={setHtmlValue}
                  value={htmlValue}
                />
              </Allotment.Pane>
              <Allotment.Pane minSize={300}>
                <CssEditor
                  height={debouncedHeightValue}
                  onChange={setCssValue}
                  value={cssValue}
                />
              </Allotment.Pane>
              <Allotment.Pane minSize={300}>
                <JavascriptEditor
                  height={debouncedHeightValue}
                  onChange={setJsValue}
                  value={jsValue}
                />
              </Allotment.Pane>
            </Allotment>
          </Allotment.Pane>
          <Allotment.Pane minSize={200}>
            <PreviewContainer fullscreen={fullScreen}>
              <Stack direction="row" justifyContent={"space-between"}>
                <ButtonGroup variant="outlined">
                  <IconButton
                    size="small"
                    color={display === "desktop" ? "info" : "secondary"}
                    onClick={() => setDisplay("desktop")}
                  >
                    <Laptop fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    color={display === "tablet" ? "info" : "secondary"}
                    onClick={() => setDisplay("tablet")}
                  >
                    <Tablet fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    color={display === "mobile" ? "info" : "secondary"}
                    onClick={() => setDisplay("mobile")}
                  >
                    <PhoneAndroid fontSize="small" />
                  </IconButton>
                </ButtonGroup>

                <IconButton
                  size="small"
                  onClick={() => setFullScreen(!fullScreen)}
                >
                  {fullScreen ? (
                    <CloseFullscreen fontSize="small" />
                  ) : (
                    <OpenInFull fontSize="small" />
                  )}
                </IconButton>
              </Stack>
              <Box
                sx={{
                  background: "white",
                  maxWidth:
                    display === "tablet"
                      ? 900
                      : display === "mobile"
                      ? 400
                      : "100%",
                  margin: "auto",
                }}
              >
                <iframe title="output" srcDoc={output} />
              </Box>
            </PreviewContainer>
          </Allotment.Pane>
        </StyledAllotmentWrapper>
      </Suspense>
    </>
  );
}

export default CodeComponentBuilder;
