/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiUserFilterType;
(function (ApiUserFilterType) {
    ApiUserFilterType["UNSPECIFIED"] = "UserFilterType_Unspecified";
    ApiUserFilterType["AUDIENCE"] = "UserFilterType_Audience";
    ApiUserFilterType["MICRO_CONVERSION"] = "UserFilterType_MicroConversion";
    ApiUserFilterType["MACRO_CONVERSION"] = "UserFilterType_MacroConversion";
    ApiUserFilterType["SALES"] = "UserFilterType_Sales";
    ApiUserFilterType["DEVICE"] = "UserFilterType_Device";
    ApiUserFilterType["PRODUCT"] = "UserFilterType_Product";
    ApiUserFilterType["STATUS"] = "UserFilterType_Status";
    ApiUserFilterType["LEAD_SCORE"] = "UserFilterType_LeadScore";
    ApiUserFilterType["LTV"] = "UserFilterType_LTV";
})(ApiUserFilterType || (ApiUserFilterType = {}));
export function ApiUserFilterTypeFromJSON(json) {
    return ApiUserFilterTypeFromJSONTyped(json, false);
}
export function ApiUserFilterTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiUserFilterTypeToJSON(value) {
    return value;
}
