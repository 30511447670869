import {
  StatCard,
  StatCardCenterContainer,
  StatCardTypography,
} from "Components/UI/StatCard";
import { useSelectedProject } from "Hooks";
import { useFromToContext } from "Providers/FromToProvider";
import useChartData from "features/analytics/hooks/useChartData";
import { IAnalyticsStatCardProps } from "features/analytics/types/types";
import { ApiChartTemplate, ApiDimension } from "@incendium/api";

function AnalyticsStatCard({
  chart,
  renderBody,
  comparison,
  setDirection,
  chartOutput: propChartOutput,
  includeTrendedSpark,
  noBg,
  ...rest
}: IAnalyticsStatCardProps) {
  const { chartOutput, chartComparisonOutput } = useFromToContext();
  const { selectedProject } = useSelectedProject();

  const { chartData, loading } = useChartData(
    selectedProject,
    {
      ...chart,
      template: ApiChartTemplate.SNAPSHOT,
    },
    propChartOutput || chartOutput
  );

  const { chartData: trendedChartData, loading: trendedLoading } = useChartData(
    selectedProject,
    {
      ...chart,
      dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
      template: ApiChartTemplate.TREND,
    },
    propChartOutput || chartOutput,
    !includeTrendedSpark
  );
  const { chartData: comparismentChartData, loading: comparsimentLoading } =
    useChartData(selectedProject, chart, chartComparisonOutput, !comparison);

  return (
    <StatCard
      bgIndex={chart.displayOptions?.statDesignIndex}
      noBg={noBg}
      loading={loading || comparsimentLoading || trendedLoading}
      showRecordedIn
      direction={
        setDirection && chartData?.data
          ? setDirection(chartData.data, comparismentChartData?.data)
          : rest.direction
      }
      {...rest}
    >
      <StatCardCenterContainer>
        {chartData?.data &&
        chartData?.data.length > 0 &&
        (!comparison || comparismentChartData) ? (
          renderBody(
            chartData.data,
            comparismentChartData?.data,
            trendedChartData?.data
          )
        ) : (
          <StatCardTypography size="small">No Data</StatCardTypography>
        )}
      </StatCardCenterContainer>
    </StatCard>
  );
}

export default AnalyticsStatCard;
