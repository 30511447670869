import {
  ApiACLRole,
  ApiClient,
  ApiProject,
  ApiReportResponse,
  ApiScheduledReportType,
  ApiTabResponse,
} from "@incendium/api";
import {
  AccessTime,
  Assignment,
  Download,
  LibraryAdd,
  Send,
} from "@mui/icons-material";
import Edit from "@mui/icons-material/Edit";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Portal,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { closeSecondaryMenu, openSecondaryMenu, toggleMenu } from "Actions";
import { authService, reportService } from "Apis";
import { DatePickerDropdown } from "features/analytics";
import {
  listReportTabs,
  pinReportToHome,
  readReport,
  ReportBuilderSidebar,
  ReportTab,
  ReportTabTitle,
  saveReport,
  saveReportTab,
  ScheduleReportDialog,
} from "features/reports";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { TabContainer } from "Components/UI/TabContainer";
import { useMount, useNotification, useSelectedProject } from "Hooks";
import { useReports } from "Hooks/useReports";
import { useSave } from "Hooks/useSave";
import { useUrlQuery } from "Hooks/useUrlQuery";
import produce from "immer";
import { useSnackbar } from "notistack";
import { useConfirmationContext } from "Providers/ConfirmationProvider";
import { useFromToContext } from "Providers/FromToProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { usePrevious, useUpdateEffect } from "react-use";
import { leftMenuSelector } from "Selectors/leftMenuSelector";
import { base64ToArrayBuffer, downloadZip } from "Helpers/download";
import AccessLevel from "Components/AccessLevel/AccessLevel";
import { PlatformURL } from "config";
import moment from "moment";
import { friendlyDate } from "Helpers/dates";
import { useFilterableDimensions } from "features/analytics";
import CreateNameDialog from "Components/CreateNameDialog/CreateNameDialog";

export const ReportTabPanel = ({
  value,
  index,
  project,
  client,
  report,
  tab,
  editMode,
  setEditMode,
}: {
  children?: React.ReactNode;
  index: any;
  value: any;
  project: ApiProject;
  client: ApiClient;
  report: ApiReportResponse;
  tab: ApiTabResponse;
  editMode: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Box role="tabpanel" hidden={value !== index}>
      {value === index && (
        <ReportTab
          project={project}
          client={client}
          report={report}
          tab={tab}
          editMode={editMode}
          setEditMode={setEditMode}
          activeTab={value}
        />
      )}
    </Box>
  );
};

interface IReportPageProps {
  readOnly?: boolean;
  disableEditMode?: boolean;
  passedReportID?: number;
  passedProject?: ApiProject;
  passedClient?: ApiClient;
}

function ReportPage({
  readOnly,
  disableEditMode,
  passedReportID,
  passedProject,
  passedClient,
}: IReportPageProps) {
  const dispatch = useDispatch();
  const { setReports } = useReports();
  const { save, saving } = useSave();
  const { reportId } = useParams<{ reportId: string }>();
  const query = useUrlQuery();
  const { selectedProject, selectedClient } = useSelectedProject();
  const [report, setReport] = useState<ApiReportResponse>({});
  const [edit, setEdit] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const prevEditMode = usePrevious(editMode);
  const [scheduleOpen, setScheduleOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const [newName, setNewName] = useState(report.name);
  const [tabs, setTabs] = useState<ApiTabResponse[]>([]);
  const { from, to, lastNDays } = useFromToContext();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { enqueueSnackbar } = useSnackbar();
  const { isSecondaryOpen, isMenuOpen } = useSelector(leftMenuSelector);
  const [isExporting, setIsExporting] = useState(false);
  const {} = useFilterableDimensions(); // pre cache filters
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const [initialMenuOpen] = useState(isMenuOpen);
  const [tabOpen, setTabOpen] = useState(false);

  const project = useMemo(
    () => passedProject || selectedProject,
    [selectedProject, passedProject]
  );
  const client = useMemo(
    () => passedClient || selectedClient,
    [selectedClient, passedClient]
  );

  useMount(() => {
    const rID = passedReportID || reportId;
    if (!rID) {
      return;
    }

    setEditMode(query.has("edit"));
    const load = async () => {
      const res = await readReport(project?.id as number, Number(rID));
      setReport(res);

      const tabsRes = await listReportTabs(project?.id as number, Number(rID));
      setTabs(tabsRes.results || []);
    };
    load();
  });

  useEffect(() => {
    setNewName(report.name);
  }, [edit]);

  const saveNewName = () => {
    setReport(
      produce(report, (draft) => {
        draft.name = newName;
      })
    );
  };

  useUpdateEffect(() => {
    if (!report?.id) {
      return;
    }
    onSaveReport(report);
  }, [report]);

  useEffect(() => {
    if (editMode && !prevEditMode) {
      dispatch(openSecondaryMenu());
      if (!isMenuOpen) {
        dispatch(toggleMenu());
      }

      return;
    }
    if (!editMode && isSecondaryOpen) {
      dispatch(closeSecondaryMenu());
      if (!initialMenuOpen && isMenuOpen) {
        dispatch(toggleMenu());
      }
      return;
    }
  }, [
    editMode,
    dispatch,
    initialMenuOpen,
    isMenuOpen,
    isSecondaryOpen,
    prevEditMode,
  ]);

  const onSaveReport = async (report: ApiReportResponse) => {
    if (!editMode) {
      return;
    }
    save(async () => {
      const res = await saveReport(project?.id as number, report);
      setReports((prevReports) =>
        produce(prevReports, (draft) => {
          const idx = draft.findIndex((r) => r.id === res.id);
          if (idx >= 0) {
            draft[idx] = res;
          }
        })
      );

      setEdit(false);
    });
  };

  const onPin = useCallback(
    async (checked) => {
      try {
        const res = await pinReportToHome(
          selectedProject?.id as number,
          report.id as number
        );
        setReport(res);
        setReports((preReports) =>
          produce(preReports, (draft) => {
            draft.forEach((r) => {
              r.pinToHome = checked && r.id === res.id;
            });
          })
        );
        showSuccessNotification(
          `${res.name} ${
            checked ? "Added to" : "Removed From"
          } Analyse Homepage`
        );
      } catch (error) {
        showErrorNotification(
          `Internal Error pinning report, please try again`
        );
      }
    },
    [
      showSuccessNotification,
      showErrorNotification,
      report,
      setReports,
      selectedProject,
    ]
  );

  const addTab = async (name: string) => {
    if (!editMode) {
      return;
    }
    save(async () => {
      const res = await saveReportTab(
        project?.id as number,
        report.id as number,
        {
          name,
          order: tabs.length,
        }
      );

      setTabs(
        produce(tabs, (draft) => {
          draft.push(res);
        })
      );
    });
  };

  const onRemoveTab = (reportTab: ApiTabResponse) => {
    openConfirmation({
      title: `Are you sure you want to delete ${reportTab.name}`,
      body: `This action can not be undone`,
      callback: async () => {
        try {
          await reportService.reportServiceDeleteTab({
            projectId: project?.id as number,
            reportId: report.id as number,
            tabId: reportTab.id as number,
          });

          const idx = tabs.findIndex((d) => d.id === reportTab.id);
          setTabs(
            produce(tabs, (draft) => {
              if (idx >= 0) {
                draft.splice(idx, 1);
              }
            })
          );
          setTab(
            tab !== idx ? tab : tab === 0 && tabs.length > 1 ? tab + 1 : tab - 1
          );

          enqueueSnackbar(`${reportTab.name} Deleted`, {
            variant: "success",
            autoHideDuration: 2000,
            anchorOrigin: { horizontal: "right", vertical: "top" },
          });

          closeConfirmation();
        } catch (error) {
          enqueueSnackbar(
            `Error Removing ${reportTab.name}, please try again`,
            {
              variant: "error",
              autoHideDuration: 2000,
              anchorOrigin: { horizontal: "right", vertical: "top" },
            }
          );
        }
      },
    });
  };

  const onExport = async () => {
    setIsExporting(true);
    try {
      const res = await reportService.reportServiceReadReportZIP({
        projectId: project?.id as number,
        reportId: report.id as number,
        payload: {
          from: from ? from?.clone().utc().startOf("day").toDate() : undefined,
          to: to ? to?.clone().utc().endOf("day").toDate() : undefined,
          lastNDays: lastNDays ? lastNDays : undefined,
          timezone: client?.timezone,
          type: ApiScheduledReportType.SCHEDULED_REPORT_TYPE_CSV_AND_XLS,
        },
      });
      if (res?.result?.result) {
        const buffer = base64ToArrayBuffer(res.result.result);
        downloadZip(buffer, report.name || "Report Export");
      }
    } catch (error) {
      enqueueSnackbar("Internal Server Error", {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }

    setIsExporting(false);
  };
  const share = async () => {
    const res = await authService.authShareToken({
      body: {
        clientId: client?.id as number,
        projectId: project?.id as number,
        reportId: report.id as number,
        timezone: client?.timezone || undefined,
        from: from ? from?.clone().utc().startOf("day").toDate() : undefined,
        to: to ? to?.clone().utc().endOf("day").toDate() : undefined,
        lastNDays: lastNDays ? lastNDays : undefined,
      },
    });

    openConfirmation({
      title: `Use this link`,
      body: (
        <Stack spacing={2}>
          <Button
            variant="outlined"
            onClick={() => {
              navigator.clipboard.writeText(
                `${PlatformURL}/reports/${res.token}`
              );
            }}
            endIcon={<Assignment />}
          >
            Copy Link
          </Button>

          <Typography
            variant="caption"
            noWrap
          >{`${PlatformURL}/reports/${res.token}`}</Typography>
        </Stack>
      ),
      callback: async () => {
        closeConfirmation();
      },
    });
  };

  const toggelEditMode = useCallback(
    (checked: boolean) => {
      setEditMode(checked);
      if (checked) {
        const newUrl = `${reportId}?edit`;
        window.history.replaceState(
          {
            ...window.history.state,
            as: newUrl,
            url: newUrl,
          },
          "",
          newUrl
        );
      } else {
        window.history.replaceState({}, "", `${reportId}`);
      }
    },
    [reportId]
  );

  if (!project || !client || !report || !report.id) {
    return <CircularProgress />;
  }

  return (
    <>
      {editMode && <ReportBuilderSidebar />}
      <Portal container={() => document.getElementById("pageTitle")}>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          {edit ? (
            <>
              <Box mr={1} sx={{ minWidth: 250 }}>
                <TextField
                  fullWidth
                  size="small"
                  label="Report Name"
                  value={newName || ""}
                  onChange={(e) => setNewName(e.target.value)}
                />
              </Box>
              <Box mr={1}>
                <Button onClick={saveNewName}>Save</Button>
              </Box>
              <Button color="secondary" onClick={() => setEdit(false)}>
                Cancel
              </Button>
            </>
          ) : (
            <Stack direction="row" spacing={2} alignItems={"center"}>
              <Stack direction="row" alignItems={"center"}>
                <Typography variant="h1">{report.name}</Typography>
                {editMode && (
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => setEdit(true)}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                )}
              </Stack>
              {!disableEditMode && (
                <AccessLevel role={ApiACLRole.ANALYTICS_ADMIN} write>
                  <StyledFlexBox ml={2}>
                    <Box display="flex" alignItems="center">
                      <Switch
                        size="small"
                        checked={editMode}
                        onChange={(e, checked) => toggelEditMode(checked)}
                      />
                      <Typography color={editMode ? "primary" : "secondary"}>
                        Edit Mode
                      </Typography>
                    </Box>
                  </StyledFlexBox>
                </AccessLevel>
              )}
            </Stack>
          )}
        </Box>
      </Portal>
      <Portal container={() => document.getElementById("pageAction")}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Stack ml={2} spacing={2}>
            <Box sx={{ minWidth: readOnly ? 0 : 260 }}>
              {readOnly ? (
                <>
                  <Typography>
                    {lastNDays
                      ? `${friendlyDate(
                          moment().subtract(lastNDays, "days").startOf("day")
                        )} - ${friendlyDate(moment().endOf("day"))}`
                      : `${friendlyDate(from || "")} - ${friendlyDate(
                          to || ""
                        )}`}
                  </Typography>
                </>
              ) : (
                <Stack direction={"row"} spacing={2}>
                  <FormControlLabel
                    sx={{ minWidth: 190 }}
                    componentsProps={{
                      typography: {
                        variant: "body1",
                      },
                    }}
                    control={
                      <Switch
                        checked={report.pinToHome}
                        onChange={(e, checked) => {
                          onPin(checked);
                        }}
                      />
                    }
                    label="Pin To Analyse Home"
                  />
                  <DatePickerDropdown size="small" label="select date range" />
                </Stack>
              )}
            </Box>
          </Stack>
          {saving && <CircularProgress />}
        </Box>
      </Portal>

      <Box>
        <Box id="report-tab-actions"></Box>
        <Stack
          direction="row-reverse"
          justifyContent={"space-between"}
          spacing={5}
          alignItems="end"
        >
          <AccessLevel
            role={ApiACLRole.ANALYTICS_ADMIN}
            write
            noAccess={
              <Button
                variant="outlined"
                color="secondary"
                onClick={onExport}
                disabled={isExporting}
                endIcon={
                  isExporting ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <Download />
                  )
                }
              >
                Export
              </Button>
            }
          >
            <Box sx={{ minWidth: 265 }}>
              <ButtonGroup>
                <Button
                  color="secondary"
                  onClick={onExport}
                  disabled={isExporting}
                  endIcon={
                    isExporting ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      <Download />
                    )
                  }
                >
                  Export
                </Button>

                <Button
                  color="secondary"
                  onClick={() => setScheduleOpen(true)}
                  endIcon={<AccessTime />}
                >
                  Schedule Report
                </Button>
                <Button color="secondary" onClick={share} endIcon={<Send />}>
                  Share
                </Button>
              </ButtonGroup>
            </Box>
          </AccessLevel>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ minWidth: 0, maxWidth: "100%" }}
          >
            {(editMode || tabs.length > 1) && (
              <TabContainer sx={{ minWidth: 0, flex: 1 }}>
                {tabs.map((t, i) => (
                  <ReportTabTitle
                    active={i === tab}
                    setTab={setTab}
                    key={i}
                    index={i}
                    project={project}
                    report={report}
                    reportTab={t}
                    tabs={tabs}
                    setTabs={setTabs}
                    editMode={editMode}
                    onRemoveTab={onRemoveTab}
                  />
                ))}
              </TabContainer>
            )}

            {editMode && (
              <Button onClick={() => setTabOpen(true)}>
                <LibraryAdd />
              </Button>
            )}
          </Stack>
        </Stack>
      </Box>
      {tabs.map((t, i) => (
        <ReportTabPanel
          key={i}
          value={tab}
          index={i}
          project={project}
          client={client}
          report={report}
          tab={t}
          editMode={editMode}
          setEditMode={setEditMode}
        >
          {t.name}
        </ReportTabPanel>
      ))}
      <ScheduleReportDialog
        open={scheduleOpen}
        setOpen={setScheduleOpen}
        project={project}
        report={report}
      />
      <CreateNameDialog
        open={tabOpen}
        setOpen={setTabOpen}
        title="Add a new tab"
        onSaved={addTab}
      />
    </>
  );
}

export default ReportPage;
