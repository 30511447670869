import { Component, Editor } from "grapesjs";

const emailFn = async function (event) {
  event.preventDefault();
  const form = event.target;
  const foundAlert = form.querySelector("#inc-form-alert");
  if (foundAlert) {
    foundAlert.remove();
  }

  const submitButton = form.querySelector('button[type="submit"]');
  if (submitButton) {
    submitButton.disabled = true;
  }
  // error by default
  const alert = document.createElement("div");
  alert.id = "inc-form-alert";
  alert.style.marginTop = "5px";
  alert.style.padding = "3px 5px";
  alert.style.border = "2px solid #D54747";
  alert.style.borderRadius = "10px";
  alert.style.background = "#d54747c2";
  alert.style.color = "#fff";
  alert.className = "error";
  alert.innerHTML = "Internal Error - Please try again";

  try {
    const formData = new FormData(form);
    const body = JSON.stringify(Object.fromEntries(formData.entries()));
    // @ts-ignore
    await window.inc("event_sent_email", {
      to: form.getAttribute("data-inc-to"),
      body,
    });

    alert.style.border = "2px solid #008D16";
    alert.style.background = "#008d16c4";
    alert.className = "success";
    alert.innerHTML = "Success - Message Sent";
  } catch (error) {
    console.error(error);
  }
  form.appendChild(alert);
  if (submitButton) {
    submitButton.disabled = false;
  }
  return false;
};

const emailFnText = `return (${emailFn})(event);`;

export const setUpForms = (editor: Editor) => {
  editor.DomComponents.getTypes().forEach((type) => {
    if (type.id !== "form") {
      return;
    }

    editor.DomComponents.addType(type.id, {
      model: {
        defaults: {
          traits: [
            {
              name: "onsubmit",
              type: "select",
              label: "On Submit",
              options: [{ name: "Email Details", value: emailFnText }],
            },
            {
              name: "data-inc-to",
              label: "Email Address",
            },
            ...editor.DomComponents.getType(type.id)!.model.prototype.defaults
              .traits,
          ],
        },
      },
    });
  });
};

export const handleForm = (component: Component) => {
  if (component.attributes.tagName !== "form") {
    return;
  }
  // check if already setup
  const methodTrait = component.getTrait("method");
  if (!methodTrait) {
    return;
  }
  const hasBlankMethod = methodTrait.getOption("blank");
  if (hasBlankMethod) {
    return;
  }

  methodTrait.set("options", [
    { id: "blank", value: "", name: "blank" },
    ...methodTrait.getOptions(),
  ]);

  // adjust attributes
  component.setAttributes({ method: "", onsubmit: emailFnText });

  if (!isDefaultDragForm(component)) {
    return;
  }

  // remove gender
  component.attributes.components!.models[2].remove();
  // this is the particular order for the automatic creation of a form
  // 0 name
  // 1 email
  // 2 message
  for (let i = 0; i < component.attributes.components!.models.length; i++) {
    const model = component.attributes.components!.models[i];
    if (model.attributes.components!.models[0].attributes.tagName === "label") {
      model.attributes.components!.models[1].setAttributes({
        name: i === 0 ? "name" : i === 1 ? "email" : i === 2 ? "message" : "",
      });
    }
  }
};

export const changeButtonType = (component: Component) => {
  if (component.attributes.tagName !== "button") {
    return;
  }

  if (!isDefaultDragForm(component)) {
    return;
  }
  // try find if button is part of a form
  const formParentIdx = component
    .parents()
    .findIndex((p) => p.attributes.tagName === "form");
  if (formParentIdx >= 0) {
    component.setAttributes({ type: "submit" });
  }
};

const isDefaultDragForm = (component: Component) => {
  if (component.attributes.tagName !== "form") {
    return false;
  }
  if (component.attributes.components!.models.length !== 5) {
    return false;
  }

  const names = component.attributes.components!.models.map((model) => {
    if (
      model.attributes.components!.models[0].attributes.tagName === "label" &&
      model.attributes.components!.models[0].attributes.components?.models[0]
        .attributes.content
    ) {
      return model.attributes.components!.models[0].attributes.components
        ?.models[0].attributes.content;
    }
    if (
      model.attributes.components!.models[0].attributes.tagName === "button" &&
      model.attributes.components!.models[0].attributes.components?.models[0]
        .attributes.content
    ) {
      return model.attributes.components!.models[0].attributes.components
        ?.models[0].attributes.content;
    }
    return "";
  });

  return (
    JSON.stringify(names) ===
    JSON.stringify(["Name", "Email", "Gender", "Message", "Send"])
  );
};
