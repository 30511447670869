/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiLeadStatusTypeFromJSON, ApiLeadStatusTypeToJSON, } from './ApiLeadStatusType';
import { ApiSalesFlowMetricFromJSON, ApiSalesFlowMetricToJSON, } from './ApiSalesFlowMetric';
import { ProtobufAnyFromJSON, ProtobufAnyToJSON, } from './ProtobufAny';
export function ApiSalesFlowResultFromJSON(json) {
    return ApiSalesFlowResultFromJSONTyped(json, false);
}
export function ApiSalesFlowResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'status': !exists(json, 'status') ? undefined : ApiLeadStatusTypeFromJSON(json['status']),
        'metrics': !exists(json, 'metrics') ? undefined : (json['metrics'].map(ApiSalesFlowMetricFromJSON)),
        'values': !exists(json, 'values') ? undefined : (json['values'].map(ProtobufAnyFromJSON)),
    };
}
export function ApiSalesFlowResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'status': ApiLeadStatusTypeToJSON(value.status),
        'metrics': value.metrics === undefined ? undefined : (value.metrics.map(ApiSalesFlowMetricToJSON)),
        'values': value.values === undefined ? undefined : (value.values.map(ProtobufAnyToJSON)),
    };
}
