import { ApiACLRole, ApiClient } from "@incendium/api";
import {
  Button,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router-dom";
import SpacedList, { SpacedLinkButton } from "Components/UI/SpacedList";
import { useCallback, useState } from "react";
import AccessLevel from "Components/AccessLevel/AccessLevel";
import { friendlyDate } from "Helpers/dates";
import { cell2Icons } from "consts";
import { useDeleteConfirmation } from "Hooks/useDeleteConfirmation";
import { deleteClient } from "features/clients";
import { useDispatch } from "react-redux";
import { ClientDialog } from "features/clients";
import { deleteClient as storeDeleteAClient } from "Actions";
import { sortAlpha } from "Helpers/arrays";

interface IClientsListProps {
  clients: ApiClient[];
  onClick: () => void;
}

function ClientsList({ clients, onClick }: IClientsListProps) {
  const [selectedClient, setSelectedClient] = useState<ApiClient | null>(null);
  const handleDelete = useDeleteConfirmation();
  const dispatch = useDispatch();
  const history = useHistory();

  const gotoClient = useCallback(
    (client) => {
      history.push(`/clients/${client.id}`);
    },
    [history]
  );

  const onDelete = useCallback(
    async (client) => {
      handleDelete({
        title: `Are you sure you want to delete this client`,
        body: `This action will remove this client and any projects connected to it.`,
        callback: async () => {
          await deleteClient(client.id as number);
          dispatch(storeDeleteAClient(client.id));
          return "Client Deleted";
        },
      });
    },
    [dispatch, handleDelete]
  );

  return (
    <SpacedList
      title="Clients List"
      action={
        <AccessLevel role={ApiACLRole.CLIENT_ADMIN} write>
          <Button onClick={onClick}>New Client</Button>
        </AccessLevel>
      }
    >
      <TableHead>
        <TableRow>
          <TableCell>Business Name</TableCell>
          <TableCell>Key</TableCell>
          <TableCell>Created On</TableCell>
          <AccessLevel role={ApiACLRole.CLIENT_ADMIN}>
            <TableCell></TableCell>
          </AccessLevel>
        </TableRow>
      </TableHead>
      <TableBody>
        {selectedClient && (
          <ClientDialog
            client={selectedClient}
            onClose={() => setSelectedClient(null)}
          />
        )}
        {sortAlpha(clients, "businessName").map((client) => (
          <TableRow key={client.id}>
            <TableCell className="primary">
              <SpacedLinkButton onClick={() => gotoClient(client)}>
                {client.businessName}
              </SpacedLinkButton>
            </TableCell>
            <TableCell>{client.key}</TableCell>
            <TableCell>{friendlyDate(client.createdAt)}</TableCell>
            <AccessLevel role={ApiACLRole.CLIENT_ADMIN}>
              <TableCell align="right" style={{ width: cell2Icons }}>
                <IconButton
                  onClick={() => setSelectedClient(client)}
                  size="small"
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={() => onDelete(client)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </AccessLevel>
          </TableRow>
        ))}
      </TableBody>
    </SpacedList>
  );
}

export default ClientsList;
