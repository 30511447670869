import { Box, styled } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { ReactComponent as Logo } from "Assets/Images/logo-no-symbol.svg";
import slice1 from "Assets/backgrounds/login/slice-1.png";
import slice2 from "Assets/backgrounds/login/slice-2.png";
import slice3 from "Assets/backgrounds/login/slice-3.png";

const StyledWrapper1 = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  backgroundImage: `url(${slice1})`,
  backgroundPositionX: -125,
  backgroundPositionY: "top",
  backgroundRepeat: "no-repeat",
  backgroundSize: "550px, 550px",
  overflow: "hidden",
}));
const StyledWrapper2 = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${slice2})`,
  backgroundPositionX: -125,
  backgroundPositionY: "top",
  backgroundRepeat: "no-repeat",
  backgroundSize: "450px, 450px",
}));
const StyledWrapper3 = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${slice3})`,
  backgroundPositionX: "calc(100%)",
  backgroundPositionY: "calc(100% + 170px)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "350px, 350px",
}));

const LoginLayout = ({
  form,
  animateLogo,
}: {
  form: React.ReactElement;
  animateLogo: boolean;
}) => {
  return (
    <AnimatePresence>
      <StyledWrapper1>
        <StyledWrapper2>
          <StyledWrapper3>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
              component={motion.div}
              initial={{ scale: 0.7, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
            >
              <Box sx={{ minWidth: 450, transform: "translateY(-50px)" }}>
                <Box display={"flex"} justifyContent={"center"} mb={6}>
                  <Logo height={50} width={300} />
                </Box>
                {form}
              </Box>
            </Box>
          </StyledWrapper3>
        </StyledWrapper2>
      </StyledWrapper1>
    </AnimatePresence>
  );
};

LoginLayout.defaultProps = {
  animateLogo: true,
};

export default LoginLayout;
