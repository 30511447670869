/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiCSVFieldType;
(function (ApiCSVFieldType) {
    ApiCSVFieldType["CSV_TYPE_STRING"] = "CSVTypeString";
    ApiCSVFieldType["CSV_TYPE_INT"] = "CSVTypeInt";
    ApiCSVFieldType["CSV_TYPE_FLOAT"] = "CSVTypeFloat";
    ApiCSVFieldType["CSV_TYPE_DATETIME"] = "CSVTypeDatetime";
    ApiCSVFieldType["CSV_TYPE_CURRENCY"] = "CSVTypeCurrency";
    ApiCSVFieldType["CSV_TYPE_JOIN"] = "CSVTypeJoin";
    ApiCSVFieldType["CSV_TYPE_EMAIL"] = "CSVTypeEmail";
})(ApiCSVFieldType || (ApiCSVFieldType = {}));
export function ApiCSVFieldTypeFromJSON(json) {
    return ApiCSVFieldTypeFromJSONTyped(json, false);
}
export function ApiCSVFieldTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiCSVFieldTypeToJSON(value) {
    return value;
}
