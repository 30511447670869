/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiDomainStatusFromJSON, ApiDomainStatusToJSON, } from './ApiDomainStatus';
export function ApiDomainResponseFromJSON(json) {
    return ApiDomainResponseFromJSONTyped(json, false);
}
export function ApiDomainResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'subdomain': !exists(json, 'subdomain') ? undefined : json['subdomain'],
        'status': !exists(json, 'status') ? undefined : ApiDomainStatusFromJSON(json['status']),
        'cnameName': !exists(json, 'cnameName') ? undefined : json['cnameName'],
        'cnameValue': !exists(json, 'cnameValue') ? undefined : json['cnameValue'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'readyAt': !exists(json, 'readyAt') ? undefined : (new Date(json['readyAt'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'certificateCname': !exists(json, 'certificateCname') ? undefined : json['certificateCname'],
        'certificateCnameValue': !exists(json, 'certificateCnameValue') ? undefined : json['certificateCnameValue'],
        'certificateApproved': !exists(json, 'certificateApproved') ? undefined : (new Date(json['certificateApproved'])),
        'domainApproved': !exists(json, 'domainApproved') ? undefined : (new Date(json['domainApproved'])),
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}
export function ApiDomainResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
        'subdomain': value.subdomain,
        'status': ApiDomainStatusToJSON(value.status),
        'cnameName': value.cnameName,
        'cnameValue': value.cnameValue,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'readyAt': value.readyAt === undefined ? undefined : (value.readyAt.toISOString()),
        'id': value.id,
        'certificateCname': value.certificateCname,
        'certificateCnameValue': value.certificateCnameValue,
        'certificateApproved': value.certificateApproved === undefined ? undefined : (value.certificateApproved.toISOString()),
        'domainApproved': value.domainApproved === undefined ? undefined : (value.domainApproved.toISOString()),
        'domain': value.domain,
        'url': value.url,
    };
}
