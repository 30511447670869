/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiLocationFromJSON, ApiLocationToJSON, } from './ApiLocation';
import { LocationItemLocationItemConditionFromJSON, LocationItemLocationItemConditionToJSON, } from './LocationItemLocationItemCondition';
import { LocationItemLocationItemTypeFromJSON, LocationItemLocationItemTypeToJSON, } from './LocationItemLocationItemType';
export function ApiLocationItemFromJSON(json) {
    return ApiLocationItemFromJSONTyped(json, false);
}
export function ApiLocationItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'location': !exists(json, 'location') ? undefined : ApiLocationFromJSON(json['location']),
        'type': !exists(json, 'type') ? undefined : LocationItemLocationItemTypeFromJSON(json['type']),
        'key': !exists(json, 'key') ? undefined : json['key'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'condition': !exists(json, 'condition') ? undefined : LocationItemLocationItemConditionFromJSON(json['condition']),
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'pageTaggerId': !exists(json, 'pageTaggerId') ? undefined : json['pageTaggerId'],
    };
}
export function ApiLocationItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'location': ApiLocationToJSON(value.location),
        'type': LocationItemLocationItemTypeToJSON(value.type),
        'key': value.key,
        'value': value.value,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'condition': LocationItemLocationItemConditionToJSON(value.condition),
        'locationId': value.locationId,
        'pageTaggerId': value.pageTaggerId,
    };
}
