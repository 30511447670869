import { Box, Button, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import emptycreature from "Assets/Images/empty-creature.svg";
import { CenterPage } from "Components/CenterPage/CenterPage";
import Image from "mui-image";

const useEmptyCompStyles = makeStyles((theme: Theme) => ({
  img: {
    minHeight: 260,
    maxWidth: "100%",
  },
}));

export const EmptyComponent = ({
  content,
  btnText,
  onBtnClick,
}: {
  content: string;
  btnText: string;
  onBtnClick: () => void;
}) => {
  const classes = useEmptyCompStyles();

  return (
    <CenterPage>
      <Typography variant="h1">nothing in here yet…</Typography>
      <Box p={4}>
        <Image
          className={classes.img}
          alt="empty-creature"
          src={emptycreature}
          showLoading
          witdth={260}
          duration={500}
        />
      </Box>

      <Typography variant="body1" gutterBottom>
        {content}
      </Typography>
      <br />
      <Button
        onClick={onBtnClick}
        variant="contained"
        color="secondary"
        disableElevation
      >
        {btnText}
      </Button>
    </CenterPage>
  );
};
