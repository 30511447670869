import { ApiProduct } from "@incendium/api";
import { Autocomplete, TextField, OutlinedTextFieldProps } from "@mui/material";
import { useProducts } from "Hooks/useProducts";
import { useMemo } from "react";

interface IProductsDropdownProps
  extends Omit<OutlinedTextFieldProps, "onChange"> {
  value?: number;
  onChange: (n?: number, p?: ApiProduct) => void;
}

function ProductsDropdown({
  label,
  size,
  variant,
  value,
  onChange,
  fullWidth,
  sx,
}: IProductsDropdownProps) {
  const { products } = useProducts();

  const selected = useMemo(
    () => (products || []).find((l) => l.id === value) || {},
    [products, value]
  );
  return (
    <Autocomplete
      sx={sx}
      fullWidth={fullWidth}
      options={[...products].sort((a, b) =>
        (a.name || "").localeCompare(b.name || "")
      )}
      value={selected}
      onChange={(e, v) => {
        onChange(v?.id, v ? v : undefined);
      }}
      getOptionLabel={(o: ApiProduct) => o.name || ""}
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant} size={size} />
      )}
    />
  );
}

export default ProductsDropdown;
