import { Box, Button, Grid, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useCSVUploaderContext } from "Providers/CSVUploaderProvider";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSelectedProject } from "Hooks";
import { useCSVSessions } from "Hooks/useCSVSessions";

function UploaderStepFourFinished() {
  const { reset } = useCSVUploaderContext();
  const history = useHistory();
  const { selectedProject } = useSelectedProject();
  const { refetchCSVSessions } = useCSVSessions();

  const toManager = useCallback(() => {
    if (!selectedProject) {
      return;
    }
    reset();
    refetchCSVSessions();
    history.push(
      `/clients/${selectedProject.clientId}/projects/${selectedProject?.id}/connect/data-manager/`
    );
  }, [selectedProject, history, reset, refetchCSVSessions]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      pb={20}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        maxWidth={500}
      >
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" align="center" gutterBottom>
              Complete
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
              We have started processing your CSV, <br />
              note this might take some time and will not show up in the data
              manager straight away.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={toManager}
              startIcon={<ArrowBack />}
            >
              Back To Data Manager
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default UploaderStepFourFinished;
