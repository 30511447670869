import { ApiMedia } from "@incendium/api";
import { mediaService } from "Apis";

// todo, file type should be enum so can fetch things like thumb etc
export const getPresignedUrlByMediaType = async (
  media: ApiMedia,
  type: string
): Promise<string> => {
  const file = (media.files || []).find((f) => f.fileType === type);
  if (!file) {
    return "";
  }
  const res = await mediaService.mediaServiceMediaFilePresignedURL({
    projectId: media.projectId as number,
    mediaFileId: file.id as number,
  });
  return res.url || "";
};
