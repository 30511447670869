/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiSimpleUTMFromJSON, ApiSimpleUTMToJSON, } from './ApiSimpleUTM';
export function ApiReadUTMResponseFromJSON(json) {
    return ApiReadUTMResponseFromJSONTyped(json, false);
}
export function ApiReadUTMResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'utm': !exists(json, 'utm') ? undefined : ApiSimpleUTMFromJSON(json['utm']),
    };
}
export function ApiReadUTMResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'utm': ApiSimpleUTMToJSON(value.utm),
    };
}
