import { useSharedState } from "./useSharedState";
import { ApiLeadScoreAdjustmentRule, ApiProject } from "@incendium/api";
import { leadScoreAdjustmentService } from "Apis";

const fetchLeadScoreAdjustmentRules = async (
  selectedProject: ApiProject | null
) => {
  if (selectedProject) {
    const leadScoreAdjustmentRules =
      await leadScoreAdjustmentService.leadScoreAdjustmentRuleServiceListRules({
        projectId: selectedProject.id as number,
      });
    return leadScoreAdjustmentRules.results || [];
  }
  return [];
};
export const useLeadScoreAdjustmentRules = () => {
  const { state, setState } = useSharedState(
    "leadScoreAdjustmentRules",
    [],
    fetchLeadScoreAdjustmentRules
  );

  return {
    leadScoreAdjustmentRules: state as ApiLeadScoreAdjustmentRule[],
    setLeadScoreAdjustmentRules: setState,
  };
};
