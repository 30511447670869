import { ApiTabChartResponse } from "@incendium/api";
import { CircularProgress } from "@mui/material";
import { ChartBuilder } from "features/chartLibrary";
import { isEqual } from "lodash";
import { useReportBuilderContext } from "Providers/ReportBuilderProvider";
import { memo } from "react";

interface IEditReportChartProps {
  loadedTabChart?: ApiTabChartResponse;
}

function EditReportChart({ loadedTabChart }: IEditReportChartProps) {
  const { chart } = useReportBuilderContext();

  if (!loadedTabChart) {
    return <CircularProgress />;
  }

  if (!chart) {
    return <>Chart not found</>;
  }

  return <ChartBuilder />;
}

export default memo(EditReportChart, (o, n) => isEqual(o, n));
