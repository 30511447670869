import { Box, TextField, Typography } from "@mui/material";
import {
  I_CAMPAIGNID,
  I_CONTENTID,
  I_MEDIUM,
  I_SOURCE,
  I_TERM,
} from "features/utms/constants";
import { useMemo } from "react";

export const googleIAIMapping = {
  [I_SOURCE]: "google",
  [I_MEDIUM]: "cpc",
  [I_CAMPAIGNID]: "{campaignid}",
  [I_CONTENTID]: "{adgroupid}",
  [I_TERM]: "{keyword}",
};

function GoogleAdsTrackingTemplate() {
  const params = useMemo(() => {
    return Object.keys(googleIAIMapping)
      .map((k) => `${k}=${googleIAIMapping[k]}`)
      .join("&");
  }, []);

  return (
    <Box>
      <Typography gutterBottom>
        If you dont yet have a tracking temnplate in place, in the{" "}
        <strong>Tracking template</strong> field, enter the following:
      </Typography>
      <TextField
        fullWidth
        multiline
        variant="outlined"
        value={`{lpurl}?${params}`}
        InputProps={{
          readOnly: true,
        }}
      />
      <Typography my={2}>
        If you already have a template, add the following to the end of your
        template:
      </Typography>
      <TextField
        fullWidth
        multiline
        variant="outlined"
        value={`&${params}`}
        InputProps={{
          readOnly: true,
        }}
      />
    </Box>
  );
}

export default GoogleAdsTrackingTemplate;
