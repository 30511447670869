import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import AttributionToggle from "Components/AttributionToggle/AttributionToggle";
import GlassCard from "Components/GlassCard/GlassCard";
import StyledAreaChart from "Components/StyledAreaChart/StyledAreaChart";
import StyledBarChart from "Components/StyledBarChart/StyledBarChart";
import { TAttributionModel } from "Interfaces";
import { useState } from "react";
import { Tooltip } from "recharts";

function StylesPage() {
  const [models, setModels] = useState<TAttributionModel[]>([]);
  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h1">Heading 1 default</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="h1" color={"primary"}>
          Heading 1 primary
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="h1" color={"secondary"}>
          Heading 1 secondary
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="h1">Heading 1</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="h2">Heading 2</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="h3">Heading 3</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="h4">Heading 4</Typography>
      </Box>
      {/* <Box mb={2}>
        <Typography variant="h3">Heading 5</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="h2">Heading 6</Typography>
      </Box> */}
      <Box mb={2}>
        <Divider />
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle1">Sub Title 1</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle2">Sub Title 2</Typography>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      <Box mb={2}>
        <Typography variant="body1">Body 1 text.primary</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body1" color="text.secondary">
          Body 1 text.secondary
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body2">Body 2 text.primary</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body2" color="text.secondary">
          Body 2 text.secondary
        </Typography>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      <Box mb={2}>
        <Button color="primary" size="large">
          Button primary Large
        </Button>
      </Box>
      <Box mb={2}>
        <Button color="primary" size="medium">
          Button primary medium
        </Button>
      </Box>
      <Box mb={2}>
        <Button color="primary" size="small">
          Button primary small
        </Button>
      </Box>
      <Box mb={2}>
        <Button color="secondary" size="large">
          Button secondary large
        </Button>
      </Box>
      <Box mb={2}>
        <Button color="secondary" size="medium">
          Button secondary medium
        </Button>
      </Box>
      <Box mb={2}>
        <Button color="secondary" size="small">
          Button secondary small
        </Button>
      </Box>
      <Box mb={2}>
        <Button color="primary" size="extraSmall">
          Button extra small
        </Button>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      <Box mb={2}>
        <TextField label="normal" />
      </Box>
      <Box mb={2}>
        <TextField label="small" size="small" />
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      <Box mb={2}>
        <StyledBarChart
          layout="vertical"
          aspect={2}
          data={[
            {
              visits: Math.round(Math.random() * 100),
              name: "a",
            },
            {
              visits: Math.round(Math.random() * 100),
              name: "b",
            },
            {
              visits: Math.round(Math.random() * 100),
              name: "c",
            },
            {
              visits: Math.round(Math.random() * 100),
              name: "d",
            },
            {
              visits: Math.round(Math.random() * 100),
              name: "e",
            },
            {
              visits: Math.round(Math.random() * 100),
              name: "f",
            },
          ]}
        />
      </Box>
      <Box mb={2}>
        <StyledAreaChart
          aspect={2}
          data={[
            {
              o: Math.round(Math.random() * 100),
              d: Math.round(Math.random() * 100),
              y: Math.round(Math.random() * 100),
              f: Math.round(Math.random() * 100),
              name: "01-01-2022",
            },
            {
              o: Math.round(Math.random() * 100),
              d: Math.round(Math.random() * 100),
              y: Math.round(Math.random() * 100),
              f: Math.round(Math.random() * 100),
              name: "02-01-2022",
            },
            {
              o: Math.round(Math.random() * 100),
              d: Math.round(Math.random() * 100),
              y: Math.round(Math.random() * 100),
              f: Math.round(Math.random() * 100),
              name: "03-01-2022",
            },
            {
              o: Math.round(Math.random() * 100),
              d: Math.round(Math.random() * 100),
              y: Math.round(Math.random() * 100),
              f: Math.round(Math.random() * 100),
              name: "04-01-2022",
            },
            {
              o: Math.round(Math.random() * 100),
              d: Math.round(Math.random() * 100),
              y: Math.round(Math.random() * 100),
              f: Math.round(Math.random() * 100),
              name: "05-01-2022",
            },
          ]}
        >
          <Tooltip
            formatter={(value, key) => {
              return key === "rate"
                ? `${(value as number).toFixed(2)}%`
                : `${(value as number).toFixed(2)}`;
            }}
          />
        </StyledAreaChart>
      </Box>
      <Box mt={3}>
        <GlassCard>
          <AttributionToggle models={models} multiple setModels={setModels} />
        </GlassCard>
      </Box>
    </Box>
  );
}

export default StylesPage;
