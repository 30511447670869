/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiActionFromJSON, ApiConversionInputToJSON, ApiConversionResponseFromJSON, ApiConversionStateInputToJSON, ApiConversionStateResponseFromJSON, ApiCreateUpdateReadLocationResponseFromJSON, ApiFormFromJSON, ApiFormActionInputToJSON, ApiFormInputToJSON, ApiFormRuleFromJSON, ApiFormRuleInputToJSON, ApiGetProjectsLocationsByPageResponseFromJSON, ApiListConversionsResponseFromJSON, ApiListDomainResponseFromJSON, ApiListFormsResponseFromJSON, ApiListLocationResponseFromJSON, ApiListPageTaggersResponseFromJSON, ApiListPageTypeResponseFromJSON, ApiListPagesByLocationResponseFromJSON, ApiListProductTaggerResponseFromJSON, ApiListProductsByLocationResponseFromJSON, ApiLocationPageTaggerFromJSON, ApiLocationPageTaggerPayloadToJSON, ApiLocationRequestPayloadToJSON, ApiLocationValidationRequestToJSON, ApiLocationValidationResponseFromJSON, ApiPageTypeFromJSON, ApiPageTypePayloadToJSON, ApiProductTaggerFromJSON, ApiProductTaggerPayloadToJSON, InlineObject3ToJSON, } from '../models';
/**
 *
 */
export class LocationServiceApi extends runtime.BaseAPI {
    /**
     */
    locationServiceAppendLocationPageTypeRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceAppendLocationPageType.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceAppendLocationPageType.');
            }
            if (requestParameters.pageTypeId === null || requestParameters.pageTypeId === undefined) {
                throw new runtime.RequiredError('pageTypeId', 'Required parameter requestParameters.pageTypeId was null or undefined when calling locationServiceAppendLocationPageType.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/locations/{locationId}/page-types/{pageTypeId}/action:append`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"pageTypeId"}}`, encodeURIComponent(String(requestParameters.pageTypeId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    locationServiceAppendLocationPageType(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceAppendLocationPageTypeRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * creates conversion
     */
    locationServiceCreateConversionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling locationServiceCreateConversion.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceCreateConversion.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceCreateConversion.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling locationServiceCreateConversion.');
            }
            const queryParameters = {};
            if (requestParameters.conversionId !== undefined) {
                queryParameters['conversionId'] = requestParameters.conversionId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/conversion/v1/clients/{clientId}/projects/{projectId}/locations/{locationId}/conversions`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiConversionInputToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiConversionResponseFromJSON(jsonValue));
        });
    }
    /**
     * creates conversion
     */
    locationServiceCreateConversion(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceCreateConversionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * creates a single conversion state
     */
    locationServiceCreateConversionStateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling locationServiceCreateConversionState.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceCreateConversionState.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceCreateConversionState.');
            }
            if (requestParameters.conversionId === null || requestParameters.conversionId === undefined) {
                throw new runtime.RequiredError('conversionId', 'Required parameter requestParameters.conversionId was null or undefined when calling locationServiceCreateConversionState.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling locationServiceCreateConversionState.');
            }
            const queryParameters = {};
            if (requestParameters.conversionStateId !== undefined) {
                queryParameters['conversionStateId'] = requestParameters.conversionStateId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/conversion/v1/clients/{clientId}/projects/{projectId}/locations/{locationId}/conversions/{conversionId}/states`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"conversionId"}}`, encodeURIComponent(String(requestParameters.conversionId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiConversionStateInputToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiConversionStateResponseFromJSON(jsonValue));
        });
    }
    /**
     * creates a single conversion state
     */
    locationServiceCreateConversionState(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceCreateConversionStateRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * create single form entity
     */
    locationServiceCreateFormRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling locationServiceCreateForm.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceCreateForm.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceCreateForm.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling locationServiceCreateForm.');
            }
            const queryParameters = {};
            if (requestParameters.formId !== undefined) {
                queryParameters['formId'] = requestParameters.formId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/lead-management/v1/clients/{clientId}/projects/{projectId}/locations/{locationId}/forms`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiFormInputToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiFormFromJSON(jsonValue));
        });
    }
    /**
     * create single form entity
     */
    locationServiceCreateForm(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceCreateFormRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * creates single action for given form
     */
    locationServiceCreateFormActionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling locationServiceCreateFormAction.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceCreateFormAction.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceCreateFormAction.');
            }
            if (requestParameters.formId === null || requestParameters.formId === undefined) {
                throw new runtime.RequiredError('formId', 'Required parameter requestParameters.formId was null or undefined when calling locationServiceCreateFormAction.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling locationServiceCreateFormAction.');
            }
            const queryParameters = {};
            if (requestParameters.formActionId !== undefined) {
                queryParameters['formActionId'] = requestParameters.formActionId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/lead-management/v1/clients/{clientId}/projects/{projectId}/locations/{locationId}/forms/{formId}/actions`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"formId"}}`, encodeURIComponent(String(requestParameters.formId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiFormActionInputToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiActionFromJSON(jsonValue));
        });
    }
    /**
     * creates single action for given form
     */
    locationServiceCreateFormAction(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceCreateFormActionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * creates single rule for given form
     */
    locationServiceCreateFormRuleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling locationServiceCreateFormRule.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceCreateFormRule.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceCreateFormRule.');
            }
            if (requestParameters.formId === null || requestParameters.formId === undefined) {
                throw new runtime.RequiredError('formId', 'Required parameter requestParameters.formId was null or undefined when calling locationServiceCreateFormRule.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling locationServiceCreateFormRule.');
            }
            const queryParameters = {};
            if (requestParameters.formRuleId !== undefined) {
                queryParameters['formRuleId'] = requestParameters.formRuleId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/lead-management/v1/clients/{clientId}/projects/{projectId}/locations/{locationId}/forms/{formId}/rules`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"formId"}}`, encodeURIComponent(String(requestParameters.formId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiFormRuleInputToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiFormRuleFromJSON(jsonValue));
        });
    }
    /**
     * creates single rule for given form
     */
    locationServiceCreateFormRule(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceCreateFormRuleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceCreateFromPageRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectKey === null || requestParameters.projectKey === undefined) {
                throw new runtime.RequiredError('projectKey', 'Required parameter requestParameters.projectKey was null or undefined when calling locationServiceCreateFromPage.');
            }
            if (requestParameters.body === null || requestParameters.body === undefined) {
                throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling locationServiceCreateFromPage.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectKey}/locations:from-page`.replace(`{${"projectKey"}}`, encodeURIComponent(String(requestParameters.projectKey))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: InlineObject3ToJSON(requestParameters.body),
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    locationServiceCreateFromPage(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceCreateFromPageRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceCreateLocationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceCreateLocation.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling locationServiceCreateLocation.');
            }
            const queryParameters = {};
            if (requestParameters.locationId !== undefined) {
                queryParameters['locationId'] = requestParameters.locationId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/locations`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiLocationRequestPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiCreateUpdateReadLocationResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    locationServiceCreateLocation(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceCreateLocationRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * --------------------------------------------------------------------------
     */
    locationServiceCreateLocationPageTaggerRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceCreateLocationPageTagger.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceCreateLocationPageTagger.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling locationServiceCreateLocationPageTagger.');
            }
            const queryParameters = {};
            if (requestParameters.pageTaggerId !== undefined) {
                queryParameters['pageTaggerId'] = requestParameters.pageTaggerId;
            }
            if (requestParameters.includeValues !== undefined) {
                queryParameters['includeValues'] = requestParameters.includeValues;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/locations/{locationId}/page-taggers`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiLocationPageTaggerPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiLocationPageTaggerFromJSON(jsonValue));
        });
    }
    /**
     * --------------------------------------------------------------------------
     */
    locationServiceCreateLocationPageTagger(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceCreateLocationPageTaggerRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceCreatePageTypeRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceCreatePageType.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling locationServiceCreatePageType.');
            }
            const queryParameters = {};
            if (requestParameters.pageTypeId !== undefined) {
                queryParameters['pageTypeId'] = requestParameters.pageTypeId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/page-types`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiPageTypePayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiPageTypeFromJSON(jsonValue));
        });
    }
    /**
     */
    locationServiceCreatePageType(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceCreatePageTypeRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * --------------------------------------------------------------------------
     */
    locationServiceCreateProductTaggerRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceCreateProductTagger.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceCreateProductTagger.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling locationServiceCreateProductTagger.');
            }
            const queryParameters = {};
            if (requestParameters.productTaggerId !== undefined) {
                queryParameters['productTaggerId'] = requestParameters.productTaggerId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/location/{locationId}/product-taggger`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiProductTaggerPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiProductTaggerFromJSON(jsonValue));
        });
    }
    /**
     * --------------------------------------------------------------------------
     */
    locationServiceCreateProductTagger(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceCreateProductTaggerRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * deletes specified conversions and its ConversionState\'s
     */
    locationServiceDeleteConversionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling locationServiceDeleteConversion.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceDeleteConversion.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceDeleteConversion.');
            }
            if (requestParameters.conversionId === null || requestParameters.conversionId === undefined) {
                throw new runtime.RequiredError('conversionId', 'Required parameter requestParameters.conversionId was null or undefined when calling locationServiceDeleteConversion.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/conversion/v1/clients/{clientId}/projects/{projectId}/locations/{locationId}/conversions/{conversionId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"conversionId"}}`, encodeURIComponent(String(requestParameters.conversionId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * deletes specified conversions and its ConversionState\'s
     */
    locationServiceDeleteConversion(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceDeleteConversionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * deletes specified conversion state
     */
    locationServiceDeleteConversionStateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling locationServiceDeleteConversionState.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceDeleteConversionState.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceDeleteConversionState.');
            }
            if (requestParameters.conversionId === null || requestParameters.conversionId === undefined) {
                throw new runtime.RequiredError('conversionId', 'Required parameter requestParameters.conversionId was null or undefined when calling locationServiceDeleteConversionState.');
            }
            if (requestParameters.conversionStateId === null || requestParameters.conversionStateId === undefined) {
                throw new runtime.RequiredError('conversionStateId', 'Required parameter requestParameters.conversionStateId was null or undefined when calling locationServiceDeleteConversionState.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/conversion/v1/clients/{clientId}/projects/{projectId}/locations/{locationId}/conversions/{conversionId}/states/{conversionStateId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"conversionId"}}`, encodeURIComponent(String(requestParameters.conversionId))).replace(`{${"conversionStateId"}}`, encodeURIComponent(String(requestParameters.conversionStateId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * deletes specified conversion state
     */
    locationServiceDeleteConversionState(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceDeleteConversionStateRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * deletes a form and its FormRule\'s
     */
    locationServiceDeleteFormRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling locationServiceDeleteForm.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceDeleteForm.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceDeleteForm.');
            }
            if (requestParameters.formId === null || requestParameters.formId === undefined) {
                throw new runtime.RequiredError('formId', 'Required parameter requestParameters.formId was null or undefined when calling locationServiceDeleteForm.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadTarget !== undefined) {
                queryParameters['payload.target'] = requestParameters.payloadTarget;
            }
            if (requestParameters.payloadPreventSubmit !== undefined) {
                queryParameters['payload.preventSubmit'] = requestParameters.payloadPreventSubmit;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/lead-management/v1/clients/{clientId}/projects/{projectId}/locations/{locationId}/forms/{formId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"formId"}}`, encodeURIComponent(String(requestParameters.formId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * deletes a form and its FormRule\'s
     */
    locationServiceDeleteForm(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceDeleteFormRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * deleted specific form action
     */
    locationServiceDeleteFormActionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling locationServiceDeleteFormAction.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceDeleteFormAction.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceDeleteFormAction.');
            }
            if (requestParameters.formId === null || requestParameters.formId === undefined) {
                throw new runtime.RequiredError('formId', 'Required parameter requestParameters.formId was null or undefined when calling locationServiceDeleteFormAction.');
            }
            if (requestParameters.formActionId === null || requestParameters.formActionId === undefined) {
                throw new runtime.RequiredError('formActionId', 'Required parameter requestParameters.formActionId was null or undefined when calling locationServiceDeleteFormAction.');
            }
            const queryParameters = {};
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            if (requestParameters.payloadTarget !== undefined) {
                queryParameters['payload.target'] = requestParameters.payloadTarget;
            }
            if (requestParameters.payloadTargetField !== undefined) {
                queryParameters['payload.targetField'] = requestParameters.payloadTargetField;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/lead-management/v1/clients/{clientId}/projects/{projectId}/locations/{locationId}/forms/{formId}/actions/{formActionId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"formId"}}`, encodeURIComponent(String(requestParameters.formId))).replace(`{${"formActionId"}}`, encodeURIComponent(String(requestParameters.formActionId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * deleted specific form action
     */
    locationServiceDeleteFormAction(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceDeleteFormActionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * deleted specific form rule
     */
    locationServiceDeleteFormRuleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling locationServiceDeleteFormRule.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceDeleteFormRule.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceDeleteFormRule.');
            }
            if (requestParameters.formId === null || requestParameters.formId === undefined) {
                throw new runtime.RequiredError('formId', 'Required parameter requestParameters.formId was null or undefined when calling locationServiceDeleteFormRule.');
            }
            if (requestParameters.formRuleId === null || requestParameters.formRuleId === undefined) {
                throw new runtime.RequiredError('formRuleId', 'Required parameter requestParameters.formRuleId was null or undefined when calling locationServiceDeleteFormRule.');
            }
            const queryParameters = {};
            if (requestParameters.payloadTarget !== undefined) {
                queryParameters['payload.target'] = requestParameters.payloadTarget;
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            if (requestParameters.payloadMapping !== undefined) {
                queryParameters['payload.mapping'] = requestParameters.payloadMapping;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/lead-management/v1/clients/{clientId}/projects/{projectId}/locations/{locationId}/forms/{formId}/rules/{formRuleId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"formId"}}`, encodeURIComponent(String(requestParameters.formId))).replace(`{${"formRuleId"}}`, encodeURIComponent(String(requestParameters.formRuleId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * deleted specific form rule
     */
    locationServiceDeleteFormRule(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceDeleteFormRuleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceDeleteLocationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceDeleteLocation.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceDeleteLocation.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/locations/{locationId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    locationServiceDeleteLocation(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceDeleteLocationRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceDeleteLocationPageTaggerRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceDeleteLocationPageTagger.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceDeleteLocationPageTagger.');
            }
            if (requestParameters.pageTaggerId === null || requestParameters.pageTaggerId === undefined) {
                throw new runtime.RequiredError('pageTaggerId', 'Required parameter requestParameters.pageTaggerId was null or undefined when calling locationServiceDeleteLocationPageTagger.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadSelector !== undefined) {
                queryParameters['payload.selector'] = requestParameters.payloadSelector;
            }
            if (requestParameters.payloadRegex !== undefined) {
                queryParameters['payload.regex'] = requestParameters.payloadRegex;
            }
            if (requestParameters.payloadCrawlSearchParams !== undefined) {
                queryParameters['payload.crawlSearchParams'] = requestParameters.payloadCrawlSearchParams;
            }
            if (requestParameters.payloadReserved !== undefined) {
                queryParameters['payload.reserved'] = requestParameters.payloadReserved;
            }
            if (requestParameters.includeValues !== undefined) {
                queryParameters['includeValues'] = requestParameters.includeValues;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/locations/{locationId}/page-taggers/{pageTaggerId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"pageTaggerId"}}`, encodeURIComponent(String(requestParameters.pageTaggerId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    locationServiceDeleteLocationPageTagger(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceDeleteLocationPageTaggerRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceDeletePageTypeRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceDeletePageType.');
            }
            if (requestParameters.pageTypeId === null || requestParameters.pageTypeId === undefined) {
                throw new runtime.RequiredError('pageTypeId', 'Required parameter requestParameters.pageTypeId was null or undefined when calling locationServiceDeletePageType.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/page-types/{pageTypeId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"pageTypeId"}}`, encodeURIComponent(String(requestParameters.pageTypeId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    locationServiceDeletePageType(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceDeletePageTypeRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceDeleteProductTaggerRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceDeleteProductTagger.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceDeleteProductTagger.');
            }
            if (requestParameters.productTaggerId === null || requestParameters.productTaggerId === undefined) {
                throw new runtime.RequiredError('productTaggerId', 'Required parameter requestParameters.productTaggerId was null or undefined when calling locationServiceDeleteProductTagger.');
            }
            const queryParameters = {};
            if (requestParameters.payloadUniqueField !== undefined) {
                queryParameters['payload.uniqueField'] = requestParameters.payloadUniqueField;
            }
            if (requestParameters.payloadTitleSelector !== undefined) {
                queryParameters['payload.titleSelector'] = requestParameters.payloadTitleSelector;
            }
            if (requestParameters.payloadDescriptionSelector !== undefined) {
                queryParameters['payload.descriptionSelector'] = requestParameters.payloadDescriptionSelector;
            }
            if (requestParameters.payloadPriceSelector !== undefined) {
                queryParameters['payload.priceSelector'] = requestParameters.payloadPriceSelector;
            }
            if (requestParameters.payloadCategorySelector !== undefined) {
                queryParameters['payload.categorySelector'] = requestParameters.payloadCategorySelector;
            }
            if (requestParameters.payloadImageUrlSelector !== undefined) {
                queryParameters['payload.imageUrlSelector'] = requestParameters.payloadImageUrlSelector;
            }
            if (requestParameters.payloadProductIdSelector !== undefined) {
                queryParameters['payload.productIdSelector'] = requestParameters.payloadProductIdSelector;
            }
            if (requestParameters.payloadProductSkuSelector !== undefined) {
                queryParameters['payload.productSkuSelector'] = requestParameters.payloadProductSkuSelector;
            }
            if (requestParameters.payloadBrandSelector !== undefined) {
                queryParameters['payload.brandSelector'] = requestParameters.payloadBrandSelector;
            }
            if (requestParameters.payloadUseLinkedData !== undefined) {
                queryParameters['payload.useLinkedData'] = requestParameters.payloadUseLinkedData;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/location/{locationId}/product-taggger/{productTaggerId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"productTaggerId"}}`, encodeURIComponent(String(requestParameters.productTaggerId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    locationServiceDeleteProductTagger(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceDeleteProductTaggerRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * method that returns a list of locations for a given project works out locations from page
     */
    locationServiceGetProjectsLocationsByPageRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectKey === null || requestParameters.projectKey === undefined) {
                throw new runtime.RequiredError('projectKey', 'Required parameter requestParameters.projectKey was null or undefined when calling locationServiceGetProjectsLocationsByPage.');
            }
            const queryParameters = {};
            if (requestParameters.queryPage !== undefined) {
                queryParameters['query.page'] = requestParameters.queryPage;
            }
            if (requestParameters.queryName !== undefined) {
                queryParameters['query.name'] = requestParameters.queryName;
            }
            if (requestParameters.queryParent !== undefined) {
                queryParameters['query.parent'] = requestParameters.queryParent;
            }
            if (requestParameters.queryPagetypes) {
                queryParameters['query.pagetypes'] = requestParameters.queryPagetypes;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectKey}/locations:search`.replace(`{${"projectKey"}}`, encodeURIComponent(String(requestParameters.projectKey))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiGetProjectsLocationsByPageResponseFromJSON(jsonValue));
        });
    }
    /**
     * method that returns a list of locations for a given project works out locations from page
     */
    locationServiceGetProjectsLocationsByPage(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceGetProjectsLocationsByPageRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceListAllLocationPageTaggersRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceListAllLocationPageTaggers.');
            }
            const queryParameters = {};
            if (requestParameters.locationId !== undefined) {
                queryParameters['locationId'] = requestParameters.locationId;
            }
            if (requestParameters.pageTaggerId !== undefined) {
                queryParameters['pageTaggerId'] = requestParameters.pageTaggerId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadSelector !== undefined) {
                queryParameters['payload.selector'] = requestParameters.payloadSelector;
            }
            if (requestParameters.payloadRegex !== undefined) {
                queryParameters['payload.regex'] = requestParameters.payloadRegex;
            }
            if (requestParameters.payloadCrawlSearchParams !== undefined) {
                queryParameters['payload.crawlSearchParams'] = requestParameters.payloadCrawlSearchParams;
            }
            if (requestParameters.payloadReserved !== undefined) {
                queryParameters['payload.reserved'] = requestParameters.payloadReserved;
            }
            if (requestParameters.includeValues !== undefined) {
                queryParameters['includeValues'] = requestParameters.includeValues;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/page-taggers`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListPageTaggersResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    locationServiceListAllLocationPageTaggers(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceListAllLocationPageTaggersRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * returns list of conversion with conversion ConversionState\'s for given client - project
     */
    locationServiceListConversionsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling locationServiceListConversions.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceListConversions.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceListConversions.');
            }
            const queryParameters = {};
            if (requestParameters.conversionId !== undefined) {
                queryParameters['conversionId'] = requestParameters.conversionId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/conversion/v1/clients/{clientId}/projects/{projectId}/locations/{locationId}/conversions`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListConversionsResponseFromJSON(jsonValue));
        });
    }
    /**
     * returns list of conversion with conversion ConversionState\'s for given client - project
     */
    locationServiceListConversions(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceListConversionsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceListDomainsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceListDomains.');
            }
            const queryParameters = {};
            if (requestParameters.locationId !== undefined) {
                queryParameters['locationId'] = requestParameters.locationId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/domains`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListDomainResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    locationServiceListDomains(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceListDomainsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * list form and FormRule\'s for client - project
     */
    locationServiceListFormsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling locationServiceListForms.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceListForms.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceListForms.');
            }
            const queryParameters = {};
            if (requestParameters.formId !== undefined) {
                queryParameters['formId'] = requestParameters.formId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadTarget !== undefined) {
                queryParameters['payload.target'] = requestParameters.payloadTarget;
            }
            if (requestParameters.payloadPreventSubmit !== undefined) {
                queryParameters['payload.preventSubmit'] = requestParameters.payloadPreventSubmit;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/lead-management/v1/clients/{clientId}/projects/{projectId}/locations/{locationId}/forms`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListFormsResponseFromJSON(jsonValue));
        });
    }
    /**
     * list form and FormRule\'s for client - project
     */
    locationServiceListForms(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceListFormsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * request where you send a location struct and returns pages that would match it note the reason we send location in struct is becouse it works before saving location so can test location before saving
     */
    locationServiceListLocationMatchingPagesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceListLocationMatchingPages.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling locationServiceListLocationMatchingPages.');
            }
            const queryParameters = {};
            if (requestParameters.locationId !== undefined) {
                queryParameters['locationId'] = requestParameters.locationId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/pages:by-location`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiLocationRequestPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListPagesByLocationResponseFromJSON(jsonValue));
        });
    }
    /**
     * request where you send a location struct and returns pages that would match it note the reason we send location in struct is becouse it works before saving location so can test location before saving
     */
    locationServiceListLocationMatchingPages(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceListLocationMatchingPagesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceListLocationPageTaggersRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceListLocationPageTaggers.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceListLocationPageTaggers.');
            }
            const queryParameters = {};
            if (requestParameters.pageTaggerId !== undefined) {
                queryParameters['pageTaggerId'] = requestParameters.pageTaggerId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadSelector !== undefined) {
                queryParameters['payload.selector'] = requestParameters.payloadSelector;
            }
            if (requestParameters.payloadRegex !== undefined) {
                queryParameters['payload.regex'] = requestParameters.payloadRegex;
            }
            if (requestParameters.payloadCrawlSearchParams !== undefined) {
                queryParameters['payload.crawlSearchParams'] = requestParameters.payloadCrawlSearchParams;
            }
            if (requestParameters.payloadReserved !== undefined) {
                queryParameters['payload.reserved'] = requestParameters.payloadReserved;
            }
            if (requestParameters.includeValues !== undefined) {
                queryParameters['includeValues'] = requestParameters.includeValues;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/locations/{locationId}/page-taggers`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListPageTaggersResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    locationServiceListLocationPageTaggers(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceListLocationPageTaggersRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceListLocationsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceListLocations.');
            }
            const queryParameters = {};
            if (requestParameters.locationId !== undefined) {
                queryParameters['locationId'] = requestParameters.locationId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/locations`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListLocationResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    locationServiceListLocations(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceListLocationsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceListPageTypesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceListPageTypes.');
            }
            const queryParameters = {};
            if (requestParameters.pageTypeId !== undefined) {
                queryParameters['pageTypeId'] = requestParameters.pageTypeId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/page-types`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListPageTypeResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    locationServiceListPageTypes(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceListPageTypesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * returns list of pages that do not have a location assigned groups location by path
     */
    locationServiceListPagesMissingLocationsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceListPagesMissingLocations.');
            }
            const queryParameters = {};
            if (requestParameters.locationId !== undefined) {
                queryParameters['locationId'] = requestParameters.locationId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/pages:missing-location`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListPagesByLocationResponseFromJSON(jsonValue));
        });
    }
    /**
     * returns list of pages that do not have a location assigned groups location by path
     */
    locationServiceListPagesMissingLocations(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceListPagesMissingLocationsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceListProductTaggersRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceListProductTaggers.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceListProductTaggers.');
            }
            const queryParameters = {};
            if (requestParameters.productTaggerId !== undefined) {
                queryParameters['productTaggerId'] = requestParameters.productTaggerId;
            }
            if (requestParameters.payloadUniqueField !== undefined) {
                queryParameters['payload.uniqueField'] = requestParameters.payloadUniqueField;
            }
            if (requestParameters.payloadTitleSelector !== undefined) {
                queryParameters['payload.titleSelector'] = requestParameters.payloadTitleSelector;
            }
            if (requestParameters.payloadDescriptionSelector !== undefined) {
                queryParameters['payload.descriptionSelector'] = requestParameters.payloadDescriptionSelector;
            }
            if (requestParameters.payloadPriceSelector !== undefined) {
                queryParameters['payload.priceSelector'] = requestParameters.payloadPriceSelector;
            }
            if (requestParameters.payloadCategorySelector !== undefined) {
                queryParameters['payload.categorySelector'] = requestParameters.payloadCategorySelector;
            }
            if (requestParameters.payloadImageUrlSelector !== undefined) {
                queryParameters['payload.imageUrlSelector'] = requestParameters.payloadImageUrlSelector;
            }
            if (requestParameters.payloadProductIdSelector !== undefined) {
                queryParameters['payload.productIdSelector'] = requestParameters.payloadProductIdSelector;
            }
            if (requestParameters.payloadProductSkuSelector !== undefined) {
                queryParameters['payload.productSkuSelector'] = requestParameters.payloadProductSkuSelector;
            }
            if (requestParameters.payloadBrandSelector !== undefined) {
                queryParameters['payload.brandSelector'] = requestParameters.payloadBrandSelector;
            }
            if (requestParameters.payloadUseLinkedData !== undefined) {
                queryParameters['payload.useLinkedData'] = requestParameters.payloadUseLinkedData;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/location/{locationId}/product-taggger`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListProductTaggerResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    locationServiceListProductTaggers(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceListProductTaggersRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * returns list of products that do not have a location assigned
     */
    locationServiceListProductsMissingLocationsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceListProductsMissingLocations.');
            }
            const queryParameters = {};
            if (requestParameters.locationId !== undefined) {
                queryParameters['locationId'] = requestParameters.locationId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/products:missing-location`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListProductsByLocationResponseFromJSON(jsonValue));
        });
    }
    /**
     * returns list of products that do not have a location assigned
     */
    locationServiceListProductsMissingLocations(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceListProductsMissingLocationsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * returns requested conversion with conversion ConversionState\'s
     */
    locationServiceReadConversionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling locationServiceReadConversion.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceReadConversion.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceReadConversion.');
            }
            if (requestParameters.conversionId === null || requestParameters.conversionId === undefined) {
                throw new runtime.RequiredError('conversionId', 'Required parameter requestParameters.conversionId was null or undefined when calling locationServiceReadConversion.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/conversion/v1/clients/{clientId}/projects/{projectId}/locations/{locationId}/conversions/{conversionId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"conversionId"}}`, encodeURIComponent(String(requestParameters.conversionId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiConversionResponseFromJSON(jsonValue));
        });
    }
    /**
     * returns requested conversion with conversion ConversionState\'s
     */
    locationServiceReadConversion(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceReadConversionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * returns single form entity with FormRule\'s
     */
    locationServiceReadFormRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling locationServiceReadForm.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceReadForm.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceReadForm.');
            }
            if (requestParameters.formId === null || requestParameters.formId === undefined) {
                throw new runtime.RequiredError('formId', 'Required parameter requestParameters.formId was null or undefined when calling locationServiceReadForm.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadTarget !== undefined) {
                queryParameters['payload.target'] = requestParameters.payloadTarget;
            }
            if (requestParameters.payloadPreventSubmit !== undefined) {
                queryParameters['payload.preventSubmit'] = requestParameters.payloadPreventSubmit;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/lead-management/v1/clients/{clientId}/projects/{projectId}/locations/{locationId}/forms/{formId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"formId"}}`, encodeURIComponent(String(requestParameters.formId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiFormFromJSON(jsonValue));
        });
    }
    /**
     * returns single form entity with FormRule\'s
     */
    locationServiceReadForm(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceReadFormRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceReadLocationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceReadLocation.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceReadLocation.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/locations/{locationId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiCreateUpdateReadLocationResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    locationServiceReadLocation(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceReadLocationRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceReadLocationPageTaggerRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceReadLocationPageTagger.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceReadLocationPageTagger.');
            }
            if (requestParameters.pageTaggerId === null || requestParameters.pageTaggerId === undefined) {
                throw new runtime.RequiredError('pageTaggerId', 'Required parameter requestParameters.pageTaggerId was null or undefined when calling locationServiceReadLocationPageTagger.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadSelector !== undefined) {
                queryParameters['payload.selector'] = requestParameters.payloadSelector;
            }
            if (requestParameters.payloadRegex !== undefined) {
                queryParameters['payload.regex'] = requestParameters.payloadRegex;
            }
            if (requestParameters.payloadCrawlSearchParams !== undefined) {
                queryParameters['payload.crawlSearchParams'] = requestParameters.payloadCrawlSearchParams;
            }
            if (requestParameters.payloadReserved !== undefined) {
                queryParameters['payload.reserved'] = requestParameters.payloadReserved;
            }
            if (requestParameters.includeValues !== undefined) {
                queryParameters['includeValues'] = requestParameters.includeValues;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/locations/{locationId}/page-taggers/{pageTaggerId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"pageTaggerId"}}`, encodeURIComponent(String(requestParameters.pageTaggerId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiLocationPageTaggerFromJSON(jsonValue));
        });
    }
    /**
     */
    locationServiceReadLocationPageTagger(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceReadLocationPageTaggerRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceReadPageTypeRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceReadPageType.');
            }
            if (requestParameters.pageTypeId === null || requestParameters.pageTypeId === undefined) {
                throw new runtime.RequiredError('pageTypeId', 'Required parameter requestParameters.pageTypeId was null or undefined when calling locationServiceReadPageType.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/page-types/{pageTypeId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"pageTypeId"}}`, encodeURIComponent(String(requestParameters.pageTypeId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiPageTypeFromJSON(jsonValue));
        });
    }
    /**
     */
    locationServiceReadPageType(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceReadPageTypeRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceReadProductTaggerRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceReadProductTagger.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceReadProductTagger.');
            }
            if (requestParameters.productTaggerId === null || requestParameters.productTaggerId === undefined) {
                throw new runtime.RequiredError('productTaggerId', 'Required parameter requestParameters.productTaggerId was null or undefined when calling locationServiceReadProductTagger.');
            }
            const queryParameters = {};
            if (requestParameters.payloadUniqueField !== undefined) {
                queryParameters['payload.uniqueField'] = requestParameters.payloadUniqueField;
            }
            if (requestParameters.payloadTitleSelector !== undefined) {
                queryParameters['payload.titleSelector'] = requestParameters.payloadTitleSelector;
            }
            if (requestParameters.payloadDescriptionSelector !== undefined) {
                queryParameters['payload.descriptionSelector'] = requestParameters.payloadDescriptionSelector;
            }
            if (requestParameters.payloadPriceSelector !== undefined) {
                queryParameters['payload.priceSelector'] = requestParameters.payloadPriceSelector;
            }
            if (requestParameters.payloadCategorySelector !== undefined) {
                queryParameters['payload.categorySelector'] = requestParameters.payloadCategorySelector;
            }
            if (requestParameters.payloadImageUrlSelector !== undefined) {
                queryParameters['payload.imageUrlSelector'] = requestParameters.payloadImageUrlSelector;
            }
            if (requestParameters.payloadProductIdSelector !== undefined) {
                queryParameters['payload.productIdSelector'] = requestParameters.payloadProductIdSelector;
            }
            if (requestParameters.payloadProductSkuSelector !== undefined) {
                queryParameters['payload.productSkuSelector'] = requestParameters.payloadProductSkuSelector;
            }
            if (requestParameters.payloadBrandSelector !== undefined) {
                queryParameters['payload.brandSelector'] = requestParameters.payloadBrandSelector;
            }
            if (requestParameters.payloadUseLinkedData !== undefined) {
                queryParameters['payload.useLinkedData'] = requestParameters.payloadUseLinkedData;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/location/{locationId}/product-taggger/{productTaggerId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"productTaggerId"}}`, encodeURIComponent(String(requestParameters.productTaggerId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiProductTaggerFromJSON(jsonValue));
        });
    }
    /**
     */
    locationServiceReadProductTagger(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceReadProductTaggerRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceRemoveLocationPageTypeRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceRemoveLocationPageType.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceRemoveLocationPageType.');
            }
            if (requestParameters.pageTypeId === null || requestParameters.pageTypeId === undefined) {
                throw new runtime.RequiredError('pageTypeId', 'Required parameter requestParameters.pageTypeId was null or undefined when calling locationServiceRemoveLocationPageType.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/locations/{locationId}/page-types/{pageTypeId}/action:remove`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"pageTypeId"}}`, encodeURIComponent(String(requestParameters.pageTypeId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    locationServiceRemoveLocationPageType(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceRemoveLocationPageTypeRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * updates specified conversion
     */
    locationServiceUpdateConversionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling locationServiceUpdateConversion.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceUpdateConversion.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceUpdateConversion.');
            }
            if (requestParameters.conversionId === null || requestParameters.conversionId === undefined) {
                throw new runtime.RequiredError('conversionId', 'Required parameter requestParameters.conversionId was null or undefined when calling locationServiceUpdateConversion.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling locationServiceUpdateConversion.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/conversion/v1/clients/{clientId}/projects/{projectId}/locations/{locationId}/conversions/{conversionId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"conversionId"}}`, encodeURIComponent(String(requestParameters.conversionId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiConversionInputToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiConversionResponseFromJSON(jsonValue));
        });
    }
    /**
     * updates specified conversion
     */
    locationServiceUpdateConversion(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceUpdateConversionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * updates specified conversion state
     */
    locationServiceUpdateConversionStateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling locationServiceUpdateConversionState.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceUpdateConversionState.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceUpdateConversionState.');
            }
            if (requestParameters.conversionId === null || requestParameters.conversionId === undefined) {
                throw new runtime.RequiredError('conversionId', 'Required parameter requestParameters.conversionId was null or undefined when calling locationServiceUpdateConversionState.');
            }
            if (requestParameters.conversionStateId === null || requestParameters.conversionStateId === undefined) {
                throw new runtime.RequiredError('conversionStateId', 'Required parameter requestParameters.conversionStateId was null or undefined when calling locationServiceUpdateConversionState.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling locationServiceUpdateConversionState.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/conversion/v1/clients/{clientId}/projects/{projectId}/locations/{locationId}/conversions/{conversionId}/states/{conversionStateId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"conversionId"}}`, encodeURIComponent(String(requestParameters.conversionId))).replace(`{${"conversionStateId"}}`, encodeURIComponent(String(requestParameters.conversionStateId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiConversionStateInputToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiConversionStateResponseFromJSON(jsonValue));
        });
    }
    /**
     * updates specified conversion state
     */
    locationServiceUpdateConversionState(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceUpdateConversionStateRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * updates single form entity
     */
    locationServiceUpdateFormRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling locationServiceUpdateForm.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceUpdateForm.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceUpdateForm.');
            }
            if (requestParameters.formId === null || requestParameters.formId === undefined) {
                throw new runtime.RequiredError('formId', 'Required parameter requestParameters.formId was null or undefined when calling locationServiceUpdateForm.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling locationServiceUpdateForm.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/lead-management/v1/clients/{clientId}/projects/{projectId}/locations/{locationId}/forms/{formId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"formId"}}`, encodeURIComponent(String(requestParameters.formId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiFormInputToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiFormFromJSON(jsonValue));
        });
    }
    /**
     * updates single form entity
     */
    locationServiceUpdateForm(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceUpdateFormRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * updates specific form action
     */
    locationServiceUpdateFormActionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling locationServiceUpdateFormAction.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceUpdateFormAction.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceUpdateFormAction.');
            }
            if (requestParameters.formId === null || requestParameters.formId === undefined) {
                throw new runtime.RequiredError('formId', 'Required parameter requestParameters.formId was null or undefined when calling locationServiceUpdateFormAction.');
            }
            if (requestParameters.formActionId === null || requestParameters.formActionId === undefined) {
                throw new runtime.RequiredError('formActionId', 'Required parameter requestParameters.formActionId was null or undefined when calling locationServiceUpdateFormAction.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling locationServiceUpdateFormAction.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/lead-management/v1/clients/{clientId}/projects/{projectId}/locations/{locationId}/forms/{formId}/actions/{formActionId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"formId"}}`, encodeURIComponent(String(requestParameters.formId))).replace(`{${"formActionId"}}`, encodeURIComponent(String(requestParameters.formActionId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiFormActionInputToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiActionFromJSON(jsonValue));
        });
    }
    /**
     * updates specific form action
     */
    locationServiceUpdateFormAction(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceUpdateFormActionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * updates specific form rule
     */
    locationServiceUpdateFormRuleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling locationServiceUpdateFormRule.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceUpdateFormRule.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceUpdateFormRule.');
            }
            if (requestParameters.formId === null || requestParameters.formId === undefined) {
                throw new runtime.RequiredError('formId', 'Required parameter requestParameters.formId was null or undefined when calling locationServiceUpdateFormRule.');
            }
            if (requestParameters.formRuleId === null || requestParameters.formRuleId === undefined) {
                throw new runtime.RequiredError('formRuleId', 'Required parameter requestParameters.formRuleId was null or undefined when calling locationServiceUpdateFormRule.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling locationServiceUpdateFormRule.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/lead-management/v1/clients/{clientId}/projects/{projectId}/locations/{locationId}/forms/{formId}/rules/{formRuleId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"formId"}}`, encodeURIComponent(String(requestParameters.formId))).replace(`{${"formRuleId"}}`, encodeURIComponent(String(requestParameters.formRuleId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiFormRuleInputToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiFormRuleFromJSON(jsonValue));
        });
    }
    /**
     * updates specific form rule
     */
    locationServiceUpdateFormRule(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceUpdateFormRuleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceUpdateLocationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceUpdateLocation.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceUpdateLocation.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling locationServiceUpdateLocation.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/locations/{locationId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiLocationRequestPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiCreateUpdateReadLocationResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    locationServiceUpdateLocation(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceUpdateLocationRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceUpdateLocationPageTaggerRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceUpdateLocationPageTagger.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceUpdateLocationPageTagger.');
            }
            if (requestParameters.pageTaggerId === null || requestParameters.pageTaggerId === undefined) {
                throw new runtime.RequiredError('pageTaggerId', 'Required parameter requestParameters.pageTaggerId was null or undefined when calling locationServiceUpdateLocationPageTagger.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling locationServiceUpdateLocationPageTagger.');
            }
            const queryParameters = {};
            if (requestParameters.includeValues !== undefined) {
                queryParameters['includeValues'] = requestParameters.includeValues;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/locations/{locationId}/page-taggers/{pageTaggerId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"pageTaggerId"}}`, encodeURIComponent(String(requestParameters.pageTaggerId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiLocationPageTaggerPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiLocationPageTaggerFromJSON(jsonValue));
        });
    }
    /**
     */
    locationServiceUpdateLocationPageTagger(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceUpdateLocationPageTaggerRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceUpdatePageTypeRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceUpdatePageType.');
            }
            if (requestParameters.pageTypeId === null || requestParameters.pageTypeId === undefined) {
                throw new runtime.RequiredError('pageTypeId', 'Required parameter requestParameters.pageTypeId was null or undefined when calling locationServiceUpdatePageType.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling locationServiceUpdatePageType.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/page-types/{pageTypeId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"pageTypeId"}}`, encodeURIComponent(String(requestParameters.pageTypeId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiPageTypePayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiPageTypeFromJSON(jsonValue));
        });
    }
    /**
     */
    locationServiceUpdatePageType(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceUpdatePageTypeRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceUpdateProductTaggerRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling locationServiceUpdateProductTagger.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling locationServiceUpdateProductTagger.');
            }
            if (requestParameters.productTaggerId === null || requestParameters.productTaggerId === undefined) {
                throw new runtime.RequiredError('productTaggerId', 'Required parameter requestParameters.productTaggerId was null or undefined when calling locationServiceUpdateProductTagger.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling locationServiceUpdateProductTagger.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/location/v1/projects/{projectId}/location/{locationId}/product-taggger/{productTaggerId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"productTaggerId"}}`, encodeURIComponent(String(requestParameters.productTaggerId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiProductTaggerPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiProductTaggerFromJSON(jsonValue));
        });
    }
    /**
     */
    locationServiceUpdateProductTagger(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceUpdateProductTaggerRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    locationServiceValidateLocationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.body === null || requestParameters.body === undefined) {
                throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling locationServiceValidateLocation.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/location/v1/validate`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiLocationValidationRequestToJSON(requestParameters.body),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiLocationValidationResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    locationServiceValidateLocation(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.locationServiceValidateLocationRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
/**
    * @export
    * @enum {string}
    */
export var LocationServiceDeleteFormActionPayloadTypeEnum;
(function (LocationServiceDeleteFormActionPayloadTypeEnum) {
    LocationServiceDeleteFormActionPayloadTypeEnum["EMAIL_CONTACT"] = "EMAIL_CONTACT";
    LocationServiceDeleteFormActionPayloadTypeEnum["REDIRECT"] = "REDIRECT";
})(LocationServiceDeleteFormActionPayloadTypeEnum || (LocationServiceDeleteFormActionPayloadTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LocationServiceDeleteFormRulePayloadTypeEnum;
(function (LocationServiceDeleteFormRulePayloadTypeEnum) {
    LocationServiceDeleteFormRulePayloadTypeEnum["INPUT"] = "INPUT";
    LocationServiceDeleteFormRulePayloadTypeEnum["SEARCH_PARAM"] = "SEARCH_PARAM";
    LocationServiceDeleteFormRulePayloadTypeEnum["FILE"] = "FILE";
})(LocationServiceDeleteFormRulePayloadTypeEnum || (LocationServiceDeleteFormRulePayloadTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LocationServiceDeleteLocationPageTaggerPayloadReservedEnum;
(function (LocationServiceDeleteLocationPageTaggerPayloadReservedEnum) {
    LocationServiceDeleteLocationPageTaggerPayloadReservedEnum["TAGGER_NOT_RESERVED"] = "TAGGER_NOT_RESERVED";
    LocationServiceDeleteLocationPageTaggerPayloadReservedEnum["NAME"] = "NAME";
    LocationServiceDeleteLocationPageTaggerPayloadReservedEnum["DESCRIPTION"] = "DESCRIPTION";
})(LocationServiceDeleteLocationPageTaggerPayloadReservedEnum || (LocationServiceDeleteLocationPageTaggerPayloadReservedEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LocationServiceDeleteProductTaggerPayloadUniqueFieldEnum;
(function (LocationServiceDeleteProductTaggerPayloadUniqueFieldEnum) {
    LocationServiceDeleteProductTaggerPayloadUniqueFieldEnum["TITLE"] = "PRODUCT_FIELD_TITLE";
    LocationServiceDeleteProductTaggerPayloadUniqueFieldEnum["DESCRIPTION"] = "PRODUCT_FIELD_DESCRIPTION";
    LocationServiceDeleteProductTaggerPayloadUniqueFieldEnum["SKU"] = "PRODUCT_FIELD_SKU";
    LocationServiceDeleteProductTaggerPayloadUniqueFieldEnum["CATEGORY"] = "PRODUCT_FIELD_CATEGORY";
    LocationServiceDeleteProductTaggerPayloadUniqueFieldEnum["PRICE"] = "PRODUCT_FIELD_PRICE";
    LocationServiceDeleteProductTaggerPayloadUniqueFieldEnum["IMAGE"] = "PRODUCT_FIELD_IMAGE";
    LocationServiceDeleteProductTaggerPayloadUniqueFieldEnum["PRODUCT_ID"] = "PRODUCT_FIELD_PRODUCT_ID";
    LocationServiceDeleteProductTaggerPayloadUniqueFieldEnum["BRAND"] = "PRODUCT_FIELD_BRAND";
})(LocationServiceDeleteProductTaggerPayloadUniqueFieldEnum || (LocationServiceDeleteProductTaggerPayloadUniqueFieldEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LocationServiceListAllLocationPageTaggersPayloadReservedEnum;
(function (LocationServiceListAllLocationPageTaggersPayloadReservedEnum) {
    LocationServiceListAllLocationPageTaggersPayloadReservedEnum["TAGGER_NOT_RESERVED"] = "TAGGER_NOT_RESERVED";
    LocationServiceListAllLocationPageTaggersPayloadReservedEnum["NAME"] = "NAME";
    LocationServiceListAllLocationPageTaggersPayloadReservedEnum["DESCRIPTION"] = "DESCRIPTION";
})(LocationServiceListAllLocationPageTaggersPayloadReservedEnum || (LocationServiceListAllLocationPageTaggersPayloadReservedEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LocationServiceListLocationPageTaggersPayloadReservedEnum;
(function (LocationServiceListLocationPageTaggersPayloadReservedEnum) {
    LocationServiceListLocationPageTaggersPayloadReservedEnum["TAGGER_NOT_RESERVED"] = "TAGGER_NOT_RESERVED";
    LocationServiceListLocationPageTaggersPayloadReservedEnum["NAME"] = "NAME";
    LocationServiceListLocationPageTaggersPayloadReservedEnum["DESCRIPTION"] = "DESCRIPTION";
})(LocationServiceListLocationPageTaggersPayloadReservedEnum || (LocationServiceListLocationPageTaggersPayloadReservedEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LocationServiceListProductTaggersPayloadUniqueFieldEnum;
(function (LocationServiceListProductTaggersPayloadUniqueFieldEnum) {
    LocationServiceListProductTaggersPayloadUniqueFieldEnum["TITLE"] = "PRODUCT_FIELD_TITLE";
    LocationServiceListProductTaggersPayloadUniqueFieldEnum["DESCRIPTION"] = "PRODUCT_FIELD_DESCRIPTION";
    LocationServiceListProductTaggersPayloadUniqueFieldEnum["SKU"] = "PRODUCT_FIELD_SKU";
    LocationServiceListProductTaggersPayloadUniqueFieldEnum["CATEGORY"] = "PRODUCT_FIELD_CATEGORY";
    LocationServiceListProductTaggersPayloadUniqueFieldEnum["PRICE"] = "PRODUCT_FIELD_PRICE";
    LocationServiceListProductTaggersPayloadUniqueFieldEnum["IMAGE"] = "PRODUCT_FIELD_IMAGE";
    LocationServiceListProductTaggersPayloadUniqueFieldEnum["PRODUCT_ID"] = "PRODUCT_FIELD_PRODUCT_ID";
    LocationServiceListProductTaggersPayloadUniqueFieldEnum["BRAND"] = "PRODUCT_FIELD_BRAND";
})(LocationServiceListProductTaggersPayloadUniqueFieldEnum || (LocationServiceListProductTaggersPayloadUniqueFieldEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LocationServiceReadLocationPageTaggerPayloadReservedEnum;
(function (LocationServiceReadLocationPageTaggerPayloadReservedEnum) {
    LocationServiceReadLocationPageTaggerPayloadReservedEnum["TAGGER_NOT_RESERVED"] = "TAGGER_NOT_RESERVED";
    LocationServiceReadLocationPageTaggerPayloadReservedEnum["NAME"] = "NAME";
    LocationServiceReadLocationPageTaggerPayloadReservedEnum["DESCRIPTION"] = "DESCRIPTION";
})(LocationServiceReadLocationPageTaggerPayloadReservedEnum || (LocationServiceReadLocationPageTaggerPayloadReservedEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LocationServiceReadProductTaggerPayloadUniqueFieldEnum;
(function (LocationServiceReadProductTaggerPayloadUniqueFieldEnum) {
    LocationServiceReadProductTaggerPayloadUniqueFieldEnum["TITLE"] = "PRODUCT_FIELD_TITLE";
    LocationServiceReadProductTaggerPayloadUniqueFieldEnum["DESCRIPTION"] = "PRODUCT_FIELD_DESCRIPTION";
    LocationServiceReadProductTaggerPayloadUniqueFieldEnum["SKU"] = "PRODUCT_FIELD_SKU";
    LocationServiceReadProductTaggerPayloadUniqueFieldEnum["CATEGORY"] = "PRODUCT_FIELD_CATEGORY";
    LocationServiceReadProductTaggerPayloadUniqueFieldEnum["PRICE"] = "PRODUCT_FIELD_PRICE";
    LocationServiceReadProductTaggerPayloadUniqueFieldEnum["IMAGE"] = "PRODUCT_FIELD_IMAGE";
    LocationServiceReadProductTaggerPayloadUniqueFieldEnum["PRODUCT_ID"] = "PRODUCT_FIELD_PRODUCT_ID";
    LocationServiceReadProductTaggerPayloadUniqueFieldEnum["BRAND"] = "PRODUCT_FIELD_BRAND";
})(LocationServiceReadProductTaggerPayloadUniqueFieldEnum || (LocationServiceReadProductTaggerPayloadUniqueFieldEnum = {}));
