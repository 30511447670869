import { Breakpoint } from "@mui/material";
import ConfirmationModal from "Components/Confirmation/Confirmation";
import { createContext, useContext, useMemo, useState } from "react";

export interface IConfirmationContext {
  open: boolean;
  title: string | React.Component<any>;
  body?: string;
  callback: any;
  size: Breakpoint;
}

type TConfirmationContext = {
  openConfirmation: Function;
  closeConfirmation: Function;
};

const ConfirmationContext = createContext<TConfirmationContext>({
  openConfirmation: () => {},
  closeConfirmation: () => {},
});
export const useConfirmationContext = () => useContext(ConfirmationContext);

const initialConfirmation: IConfirmationContext = {
  open: false,
  callback: () => {},
  title: "",
  body: "",
  size: "xs",
};

const ConfirmationProvider = ({ children }: any) => {
  const [confirmation, setConfirmation] = useState(initialConfirmation);
  const openConfirmation = ({
    title,
    body,
    callback,
    size,
  }: IConfirmationContext) => {
    setConfirmation({
      open: true,
      title,
      body,
      callback,
      size,
    });
  };
  const closeConfirmation = () => {
    setConfirmation(initialConfirmation);
  };

  const setOpen = (open: boolean) => {
    setConfirmation({
      ...confirmation,
      open,
    });
  };

  const state = useMemo(
    () => ({
      openConfirmation,
      closeConfirmation,
    }),
    []
  );

  return (
    <ConfirmationContext.Provider value={state}>
      <>
        {children}
        <ConfirmationModal
          open={confirmation.open}
          setOpen={setOpen}
          callback={confirmation.callback}
          title={confirmation.title}
          body={confirmation.body}
          size={confirmation.size}
        />
      </>
    </ConfirmationContext.Provider>
  );
};

export default ConfirmationProvider;
