import { ApiCSVMappingResponse, ApiCSVRule } from "@incendium/api";
import {
  Box,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import GlassCard from "Components/GlassCard/GlassCard";
import { IndentDivider } from "Components/IndentDivider/IndentDivider";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { useMemo, useState } from "react";

interface IUploaderMappingCardProps {
  header: string;
  index: number;
  mappingResponse: ApiCSVMappingResponse;
  rule: ApiCSVRule;
}

function UploaderMappingCard({
  header,
  index,
  mappingResponse,
  rule,
}: IUploaderMappingCardProps) {
  const [useSuggested, setUseSuggested] = useState(false);
  const [mappedField, setMappedField] = useState("");

  const suggestedMapping = useMemo(() => {
    return Object.keys(mappingResponse.mapping || {}).find((k) => {
      if ((mappingResponse.mapping || {}).hasOwnProperty(k)) {
        return ((mappingResponse.mapping || {})[k].columns || []).includes(
          header
        );
      }
    });
  }, [header, mappingResponse]);

  const rows = useMemo(() => {
    return (mappingResponse.rows || [])
      .map((row) => (row.columns || [])[index])
      .slice(0, 5);
  }, [mappingResponse, index]);

  const options = useMemo(() => {
    return (rule.fields || []).map((f) => f.id || "");
  }, [rule]);

  const handleUseSuggested = (field: string, checked: boolean) => {
    setUseSuggested(checked);
    setMappedField(checked ? field : "");
  };

  return (
    <GlassCard>
      <Box p={2}>
        <Box mb={1} sx={{ minHeight: 112 }}>
          <Stack direction="row" justifyContent={"space-between"}>
            <Typography variant="h6">{header}</Typography>
          </Stack>

          <Box>
            <StyledFlexBox mt={1} sx={{ minHeight: 70 }}>
              {suggestedMapping && suggestedMapping !== "" ? (
                <Box display="flex" alignItems="center">
                  <Switch
                    checked={useSuggested}
                    onChange={(e, checked) =>
                      handleUseSuggested(suggestedMapping, checked)
                    }
                  />
                  <Box>
                    <Typography
                      variant="body2"
                      color={useSuggested ? "primary" : "secondary"}
                    >
                      We found this column might match a internal field.
                    </Typography>
                    <Typography
                      color={useSuggested ? "primary" : "secondary"}
                      variant="body2"
                    >
                      Use <strong>{suggestedMapping}</strong>?
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Typography variant="body2" color={"secondary"}>
                    We could not find a match for this field
                  </Typography>
                </Box>
              )}
            </StyledFlexBox>
          </Box>
        </Box>

        <Box my={1}>
          <TextField
            fullWidth
            size="small"
            select
            label="Map Column To"
            disabled={useSuggested}
            value={mappedField}
            onChange={(e) => setMappedField(e.target.value)}
          >
            {options.map((o) => (
              <MenuItem key={o} value={o}>
                {o}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <IndentDivider />
        <Box mt={1}>
          <Typography variant="subtitle2">Example Rows</Typography>
          {rows.map((row, idx) => (
            <Typography variant="body2" key={idx} noWrap color="textSecondary">
              {row}
            </Typography>
          ))}
        </Box>
      </Box>
    </GlassCard>
  );
}

export default UploaderMappingCard;
