/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiFingerprintSourceFromJSON, ApiFingerprintSourceToJSON, } from './ApiFingerprintSource';
import { ApiLeadFromJSON, ApiLeadToJSON, } from './ApiLead';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiFingerprintFromJSON(json) {
    return ApiFingerprintFromJSONTyped(json, false);
}
export function ApiFingerprintFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'source': !exists(json, 'Source') ? undefined : ApiFingerprintSourceFromJSON(json['Source']),
        'key': !exists(json, 'Key') ? undefined : json['Key'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'lead': !exists(json, 'lead') ? undefined : ApiLeadFromJSON(json['lead']),
        'leadId': !exists(json, 'leadId') ? undefined : json['leadId'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
    };
}
export function ApiFingerprintToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'Source': ApiFingerprintSourceToJSON(value.source),
        'Key': value.key,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'lead': ApiLeadToJSON(value.lead),
        'leadId': value.leadId,
        'project': ApiProjectToJSON(value.project),
        'projectId': value.projectId,
    };
}
