import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import { GenericDialoagProps } from "Interfaces";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

interface ICreateNameDialogProps extends GenericDialoagProps {
  title: string;
  onSaved: (name: string) => void;
  name?: string;
}

function CreateNameDialog({
  open,
  setOpen,
  title,
  onSaved,
  name: originalName,
}: ICreateNameDialogProps) {
  const [name, setName] = useState(originalName || "");
  const save = () => {
    onSaved(name);
    setOpen(false);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      setName(originalName || "");
    }
  }, [originalName, open]);

  return (
    <CustomDialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <ValidatorForm onSubmit={save}>
        <DialogContent>
          <Box mb={2}>
            <TextValidator
              fullWidth
              label="name"
              validators={["required"]}
              name="name"
              value={name}
              variant="outlined"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </CustomDialog>
  );
}

export default CreateNameDialog;
