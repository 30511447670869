import {
  ApiChannelDefault,
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiYAxisChartType,
} from "@incendium/api";
import { Grid } from "@mui/material";
import { StatCardTypography } from "Components/UI/StatCard";
import { useChannels } from "Hooks/useChannels";
import { useMemo, useState } from "react";
import { useFromToContext } from "Providers/FromToProvider";
import { formatPercentage } from "Helpers/percentage";
import {
  AnalyticsCard,
  AnalyticsStatCard,
  dimensionToName,
  formatMetric,
  metricToName,
  percentageTopChangeByMetric,
} from "features/analytics";
import { CenterPage } from "Components/CenterPage/CenterPage";
import Loading from "Components/Loading/Loading";
import { AnalyticsSpacing } from "consts";

function EmailOverview() {
  const { formatedString } = useFromToContext();
  const { channels } = useChannels();
  const emailChannelName = useMemo(() => {
    return (
      channels.find((c) => c._default === ApiChannelDefault.EMAIL)?.name || ""
    );
  }, [channels]);

  const [metric, setMetric] = useState<ApiMetric>(
    ApiMetric.METRIC_SESSIONS_COUNT
  );
  const [dimension, setDimension] = useState<ApiDimension>(
    ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN
  );

  if (emailChannelName === "") {
    return (
      <CenterPage>
        <Loading />
      </CenterPage>
    );
  }

  return (
    <Grid container spacing={AnalyticsSpacing} mb={4}>
      <Grid item xs={4}>
        <AnalyticsStatCard
          comparison
          chart={{
            name: "",
            dimension: [],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: emailChannelName,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
              },
            ],
          }}
          renderBody={(data, compData) => {
            const change = percentageTopChangeByMetric(
              data,
              compData || [],
              ApiMetric.METRIC_SESSIONS_COUNT
            );
            return (
              <>
                <StatCardTypography size="medium" opactity={1}>
                  {formatMetric(
                    ApiMetric.METRIC_SESSIONS_COUNT,
                    Number(data[0][ApiMetric.METRIC_SESSIONS_COUNT])
                  )}{" "}
                  <StatCardTypography
                    size="small"
                    opactity={0.8}
                    display="inline"
                  >
                    sessions,{" "}
                    {(change || 0) >= 0 ? "an increase" : "a decrease"} of{" "}
                    <br />
                    <StatCardTypography
                      size="medium"
                      opactity={1}
                      display="inline"
                    >
                      {formatPercentage(change || 0)}
                    </StatCardTypography>{" "}
                    {formatedString}
                  </StatCardTypography>
                </StatCardTypography>
              </>
            );
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <AnalyticsStatCard
          comparison
          chart={{
            name: "",
            dimension: [],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: emailChannelName,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE],
              },
            ],
          }}
          renderBody={(data, compData) => {
            const change = percentageTopChangeByMetric(
              data,
              compData || [],
              ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE
            );
            return (
              <>
                <StatCardTypography size="medium" opactity={1}>
                  {formatMetric(
                    ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE,
                    Number(data[0][ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE])
                  )}
                  <StatCardTypography
                    size="small"
                    opactity={0.8}
                    display="inline"
                  >
                    of email sessions resulted in a sale,
                    {(change || 0) >= 0 ? "an increase" : "a decrease"} of{" "}
                    <StatCardTypography
                      size="medium"
                      opactity={1}
                      display="inline"
                    >
                      {formatPercentage(change || 0)}
                    </StatCardTypography>{" "}
                    {formatedString}
                  </StatCardTypography>
                </StatCardTypography>
              </>
            );
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <AnalyticsStatCard
          chart={{
            name: "",
            dimension: [],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: emailChannelName,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_RETURNING_NINETY_DAY_CUSTOMER_PERCENTAGE,
                ],
              },
            ],
          }}
          renderBody={(data) => (
            <StatCardTypography size="medium" opactity={1}>
              {formatMetric(
                ApiMetric.METRIC_RETURNING_NINETY_DAY_CUSTOMER_PERCENTAGE,
                Number(
                  data[0][
                    ApiMetric.METRIC_RETURNING_NINETY_DAY_CUSTOMER_PERCENTAGE
                  ]
                )
              )}{" "}
              <StatCardTypography size="small" opactity={0.8} display="inline">
                of 90-day active customers <br />
                clicked through on email
              </StatCardTypography>
            </StatCardTypography>
          )}
        />
      </Grid>
      <Grid item xs={8} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          customTitleFn={(d, m) =>
            `Top 5 ${dimensionToName(d)}s by ${metricToName(m)}`
          }
          chart={{
            name: "Top 5 ... by",
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY, dimension],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: emailChannelName,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [metric],
              },
            ],
            displayOptions: {
              noFilters: true,
              availableMetrics: [
                ApiMetric.METRIC_SESSIONS_COUNT,
                ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION,
                ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                ApiMetric.METRIC_SESSION_SALES_COUNT,
                ApiMetric.METRIC_SESSION_REVENUE,
              ],
              availableDimensions: [
                ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
                ApiDimension.DIMENSION_LEAD_AUDIENCE,
              ],
            },
          }}
          onChangeMetric={setMetric}
          onChangeDimension={setDimension}
          metricsOverride={[metric]}
        />
      </Grid>
      <Grid item xs={4}>
        <AnalyticsCard
          chart={{
            name: `Email Performance Composition by`,
            dimension: [dimension],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.PIE,
            limit: 10,
            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: emailChannelName,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [metric],
              },
            ],
            displayOptions: {
              noFilters: true,
              showTotals: true,
              availableMetrics: [
                ApiMetric.METRIC_SESSIONS_COUNT,
                ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION,
                ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                ApiMetric.METRIC_SESSION_SALES_COUNT,
                ApiMetric.METRIC_SESSION_REVENUE,
              ],
              availableDimensions: [
                ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
                ApiDimension.DIMENSION_LEAD_AUDIENCE,
              ],
            },
          }}
          onChangeMetric={setMetric}
          onChangeDimension={setDimension}
          metricsOverride={[metric]}
        />
      </Grid>
      <Grid item xs={6} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          chart={{
            name: `Sessions & Revenue Levels by Audience`,
            dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            limit: 10,
            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: emailChannelName,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                chart: {
                  [ApiMetric.METRIC_SESSIONS_COUNT]: ApiYAxisChartType.BAR,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={6} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          chart={{
            name: `Sessions & Revenue Levels by Email Campaign`,
            dimension: [ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            limit: 10,
            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: emailChannelName,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                chart: {
                  [ApiMetric.METRIC_SESSIONS_COUNT]: ApiYAxisChartType.BAR,
                },
              },
              {
                key: "r",
                fields: [ApiMetric.METRIC_SESSION_REVENUE],
              },
            ],
            displayOptions: {
              noFilters: true,
              metricsOrder: [
                ApiMetric.METRIC_SESSIONS_COUNT,
                ApiMetric.METRIC_SESSION_REVENUE,
              ],
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          chart={{
            name: `Email Campaigns`,
            dimension: [ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: emailChannelName,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_AVERAGE_PAGEVIEWS_PER_SESSION,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                  ApiMetric.METRIC_SESSION_REVENUE,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
              rowsPerPage: 10,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default EmailOverview;
