import { ApiChannel, ApiChannelGroupPayload } from "@incendium/api";
import { channelService } from "Apis";

export const saveChannel = async (
  projectId: number,
  channel: ApiChannel,
  groups?: ApiChannelGroupPayload[]
) => {
  const req = {
    projectId,
    channelId: channel.id as number,
    payload: {
      name: channel.name,
      isPaidChannel: channel.isPaidChannel,
      groups,
    },
  };

  const fn = channel.id
    ? channelService.channelServiceUpdateChannel(req)
    : channelService.channelServiceCreateChannel(req);
  const res = await fn;
  return res;
};
