/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function ApiFieldMappingFromJSON(json) {
    return ApiFieldMappingFromJSONTyped(json, false);
}
export function ApiFieldMappingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'field': !exists(json, 'field') ? undefined : json['field'],
        'mapping': !exists(json, 'mapping') ? undefined : json['mapping'],
    };
}
export function ApiFieldMappingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'model': value.model,
        'field': value.field,
        'mapping': value.mapping,
    };
}
