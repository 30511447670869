/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import { ApiCSVDateGroupingFromJSON, ApiCSVDateGroupingToJSON, } from './ApiCSVDateGrouping';
import { ApiCSVRowFromJSON, ApiCSVRowToJSON, } from './ApiCSVRow';
export function ApiCSVSavePayloadFromJSON(json) {
    return ApiCSVSavePayloadFromJSONTyped(json, false);
}
export function ApiCSVSavePayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'csvUrl': !exists(json, 'csvUrl') ? undefined : json['csvUrl'],
        'delimiter': !exists(json, 'delimiter') ? undefined : json['delimiter'],
        'joinDelimiter': !exists(json, 'joinDelimiter') ? undefined : json['joinDelimiter'],
        'grouping': !exists(json, 'grouping') ? undefined : ApiCSVDateGroupingFromJSON(json['grouping']),
        'mapping': !exists(json, 'mapping') ? undefined : (mapValues(json['mapping'], ApiCSVRowFromJSON)),
        'userValue': !exists(json, 'userValue') ? undefined : json['userValue'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}
export function ApiCSVSavePayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'csvUrl': value.csvUrl,
        'delimiter': value.delimiter,
        'joinDelimiter': value.joinDelimiter,
        'grouping': ApiCSVDateGroupingToJSON(value.grouping),
        'mapping': value.mapping === undefined ? undefined : (mapValues(value.mapping, ApiCSVRowToJSON)),
        'userValue': value.userValue,
        'name': value.name,
    };
}
