/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiClassificationVariable;
(function (ApiClassificationVariable) {
    ApiClassificationVariable["VARIABLE_UNSPECIFIED"] = "variable_unspecified";
    ApiClassificationVariable["URL"] = "url";
    ApiClassificationVariable["PATH"] = "path";
    ApiClassificationVariable["PAGEVIEWS"] = "pageviews";
    ApiClassificationVariable["AVERAGE_SCROLL_SPEED"] = "average_scroll_speed";
    ApiClassificationVariable["AVERAGE_TIME_ON_PAGE"] = "average_time_on_page";
    ApiClassificationVariable["AVERAGE_PAGE_VIEWS"] = "average_page_views";
    ApiClassificationVariable["BROWSER"] = "browser";
    ApiClassificationVariable["IS_MOBILE"] = "is_mobile";
    ApiClassificationVariable["LANDING_PAGE_LOCATION"] = "landing_page_location";
    ApiClassificationVariable["OS"] = "OS";
    ApiClassificationVariable["NUMBER_OF_SESSIONS"] = "number_of_sessions";
    ApiClassificationVariable["DAYS_SINCE_LAST_SESSION"] = "days_since_last_session";
    ApiClassificationVariable["DAYS_SINCE_LAST_PURCHASED"] = "days_since_last_purchased";
    ApiClassificationVariable["PERCENTAGE_BY_REVENUE"] = "percentage_by_revenue";
    ApiClassificationVariable["PERCENTAGE_BY_PURCHASES"] = "percentage_by_purchases";
    ApiClassificationVariable["REVENUE"] = "revenue";
    ApiClassificationVariable["PURCHASES"] = "purchases";
    ApiClassificationVariable["CONVERSIONS"] = "conversions";
    ApiClassificationVariable["VISITED_LOCATION"] = "visited_location";
    ApiClassificationVariable["LEAD_SCORE"] = "lead_score";
    ApiClassificationVariable["PERCENTAGE_LEAD_SCORE"] = "percentage_lead_score";
    ApiClassificationVariable["LEAD_STATUS"] = "lead_status";
    ApiClassificationVariable["EFFECTIVE_PAGEVIEWS"] = "effective_pageviews";
    ApiClassificationVariable["SESSION_CHANNEL"] = "session_channel";
    ApiClassificationVariable["SESSION_SOURCE"] = "session_source";
    ApiClassificationVariable["SESSION_MEDIUM"] = "session_medium";
    ApiClassificationVariable["SESSION_CAMPAIGN"] = "session_campaign";
    ApiClassificationVariable["SESSION_TERM"] = "session_term";
    ApiClassificationVariable["SESSION_BUCKET"] = "session_bucket";
})(ApiClassificationVariable || (ApiClassificationVariable = {}));
export function ApiClassificationVariableFromJSON(json) {
    return ApiClassificationVariableFromJSONTyped(json, false);
}
export function ApiClassificationVariableFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiClassificationVariableToJSON(value) {
    return value;
}
