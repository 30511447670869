import { Box, Button, Divider, Portal, Stack } from "@mui/material";
import withInfoColumn from "HoC/withInfoColumn";
import React, { useCallback, useRef } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { SidebarContainer } from "../UI/Sidebar";

interface IConditionGroupsFormWrapperExtendProps
  extends React.HTMLAttributes<HTMLDivElement> {
  onCancel: () => void;
  save: () => void;
  saveAndExit: () => void;
}
const ConditionGroupsFormWrapperExtend = ({
  onCancel,
  save,
  saveAndExit,
  children,
}: IConditionGroupsFormWrapperExtendProps) => {
  return (
    <Stack sx={{ height: "100%" }} justifyContent={"space-between"}>
      <>{children}</>
      <Box>
        <Divider />
        <Stack p={2} spacing={2}>
          <Stack
            spacing={2}
            direction={"row"}
            sx={{ background: "rgba(255,255,255,0.4)" }}
          >
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={onCancel}
            >
              cancel
            </Button>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={save}
            >
              save
            </Button>
          </Stack>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={saveAndExit}
          >
            save & exit
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};

interface ISidebarFormProps extends React.HTMLAttributes<HTMLDivElement> {
  rules?: React.ReactNode;
  rulesComponent?: React.ComponentType<any>;
  rulesComponentProps?: any;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
}

function ConditionGroupsFormWrapper({
  children,
  rules,
  rulesComponent,
  rulesComponentProps,
  onSubmit,
  onCancel,
}: ISidebarFormProps) {
  const formRef = useRef<HTMLFormElement | any>();

  const save = useCallback(() => {
    formRef.current.submit();
  }, [formRef]);

  const saveAndExit = useCallback(() => {
    save();
    onCancel();
  }, [save, onCancel]);

  return (
    <ValidatorForm onSubmit={onSubmit} ref={formRef}>
      {rules ? (
        <>
          <Box style={{ width: 865, margin: "auto" }}>{rules}</Box>
          <Portal container={() => document.getElementById("rightSideBar")}>
            <SidebarContainer>
              <ConditionGroupsFormWrapperExtend
                onCancel={onCancel}
                save={save}
                saveAndExit={saveAndExit}
              >
                {children}
              </ConditionGroupsFormWrapperExtend>
            </SidebarContainer>
          </Portal>
        </>
      ) : rulesComponent ? (
        withInfoColumn(rulesComponent)({
          dense: true,
          infoColumn: (
            <ConditionGroupsFormWrapperExtend
              onCancel={onCancel}
              save={save}
              saveAndExit={saveAndExit}
            >
              {children}
            </ConditionGroupsFormWrapperExtend>
          ),
          ...rulesComponentProps,
        })
      ) : (
        <>...</>
      )}
    </ValidatorForm>
  );
}

export default ConditionGroupsFormWrapper;
