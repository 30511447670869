import { ApiBucketResponse } from "@incendium/api";
import { Delete } from "@mui/icons-material";
import {
  Button,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import GroupsCell from "features/channels/components/GroupsCell";
import SpacedList, { SpacedLinkButton } from "Components/UI/SpacedList";
import { cell1Icon } from "consts";

interface ITrafficBucketsListProps {
  buckets: ApiBucketResponse[];
  onNew: () => void;
  onEdit: (v: ApiBucketResponse) => void;
  onDelete: (v: ApiBucketResponse) => void;
}

function TrafficBucketsList({
  buckets,
  onNew,
  onEdit,
  onDelete,
}: ITrafficBucketsListProps) {
  return (
    <SpacedList
      title="Buckets List"
      isEmpty={!buckets || buckets.length === 0}
      action={<Button onClick={onNew}>New Traffic Bucket</Button>}
    >
      <TableHead>
        <TableRow>
          <TableCell>Traffic Bucket Name</TableCell>
          <TableCell>Rules</TableCell>
          <TableCell>Tags</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {buckets.map((b) => (
          <TableRow key={b.id}>
            <TableCell>
              <SpacedLinkButton onClick={() => onEdit(b)}>
                {b.name}
              </SpacedLinkButton>
            </TableCell>
            <GroupsCell groups={b.groups || []} />
            <TableCell>
              {(b.tags || []).map((t) => t.name || "").join(", ")}
            </TableCell>
            <TableCell align="right" style={{ width: cell1Icon }}>
              <IconButton
                size="small"
                color="secondary"
                onClick={() => onDelete(b)}
              >
                <Delete />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </SpacedList>
  );
}

export default TrafficBucketsList;
