import { Save } from "@mui/icons-material";
import { Button, Portal, Stack } from "@mui/material";
import { chartService } from "Apis";
import FullWidthHeightGlass from "Components/Page/FullWidthHeightGlass";
import { PageActionPreSlot } from "consts";
import { ChartBuilder } from "features/chartLibrary";
import { useMount, useNotification, useSelectedProject } from "Hooks";
import { parseChartFromResponse, useCharts } from "Hooks/useCharts";
import { useLayoutContext } from "Providers/LayoutProvider";
import { useReportBuilderContext } from "Providers/ReportBuilderProvider";
import { useCallback, useLayoutEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

function ChartBuilderPage() {
  const { setPageTitle } = useLayoutContext();
  const history = useHistory();
  const { selectedProject } = useSelectedProject();
  const { chart, setChart } = useReportBuilderContext();
  const { chartId } = useParams<{ chartId: string }>();
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const { updateChart } = useCharts();

  useLayoutEffect(() => {
    setPageTitle("Chart Designer");
    return () => {
      setPageTitle("");
    };
  }, []);

  useMount(() => {
    if (!chartId) {
      return;
    }
    const load = async () => {
      const res = await chartService.chartServiceReadChart({
        projectId: selectedProject?.id as number,
        chartId: Number(chartId),
      });

      const o = parseChartFromResponse(res);
      setChart({
        ...o,
        yAxisKeys:
          o.yAxisKeys.length > 0
            ? o.yAxisKeys
            : [
                {
                  key: "l",
                  fields: [""],
                },
              ],
      });
    };
    load();
  });

  const onSave = useCallback(async () => {
    try {
      const res = await updateChart(chart, selectedProject);
      setChart(res);

      showSuccessNotification(`${chart.name} Saved`);

      history.push("../chart-library");
    } catch (error) {
      showErrorNotification("Something Went Wrong");
    }
  }, [
    chart,
    selectedProject,
    showSuccessNotification,
    showErrorNotification,
    history,
    setChart,
    updateChart,
  ]);

  return (
    <FullWidthHeightGlass height={"calc(100vh - 114px)"}>
      <Portal container={() => document.getElementById(PageActionPreSlot)}>
        <Stack direction={"row"} spacing={2}>
          <Button
            color="secondary"
            onClick={() => history.push("../chart-library")}
          >
            Cancel
          </Button>

          <Button startIcon={<Save />} onClick={onSave}>
            Save Chart To Library
          </Button>
        </Stack>
      </Portal>
      <ChartBuilder />
    </FullWidthHeightGlass>
  );
}

export default ChartBuilderPage;
