/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiConditionGroupFromJSON, ApiConditionGroupToJSON, } from './ApiConditionGroup';
import { ApiLeadFromJSON, ApiLeadToJSON, } from './ApiLead';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiAudienceFromJSON(json) {
    return ApiAudienceFromJSONTyped(json, false);
}
export function ApiAudienceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'conditionGroups': !exists(json, 'conditionGroups') ? undefined : (json['conditionGroups'].map(ApiConditionGroupFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'leads': !exists(json, 'leads') ? undefined : (json['leads'].map(ApiLeadFromJSON)),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'param': !exists(json, 'param') ? undefined : json['param'],
    };
}
export function ApiAudienceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'conditionGroups': value.conditionGroups === undefined ? undefined : (value.conditionGroups.map(ApiConditionGroupToJSON)),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'project': ApiProjectToJSON(value.project),
        'leads': value.leads === undefined ? undefined : (value.leads.map(ApiLeadToJSON)),
        'projectId': value.projectId,
        'param': value.param,
    };
}
