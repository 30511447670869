import { TimelineItem } from "@mui/lab";
import { Box, useTheme } from "@mui/material";
import { StyledTimeline } from "Components/UI/Timeline";
import { LeadJourneyItem } from "features/leadAnalytics";
import {
  ExtraUserActionType,
  IUserAction,
} from "features/leadAnalytics/types/types";
import { motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";

interface ILeadJourneySummaryProps {
  data: IUserAction[];
  activeSessionId: number;
  onClick: (id: number) => void;
  readonly?: boolean;
}

function LeadJourneySummary({
  data,
  activeSessionId,
  onClick,
  readonly,
}: ILeadJourneySummaryProps) {
  const theme = useTheme();
  const [ref, setRef] = useState<HTMLUListElement | null>(null);
  const onRefChange = useCallback((node) => {
    setRef(node);
  }, []);
  const [activeHeight, setActiveHeight] = useState(0);

  useEffect(() => {
    if (!ref || activeSessionId === 0) {
      return;
    }
    setActiveHeight(0);
    setTimeout(() => {
      const items = ref?.querySelectorAll(".active");
      let height = 0;
      items?.forEach((item) => {
        height = height + item.clientHeight;
      });

      setActiveHeight(height);
    }, 1);
  }, [ref, activeSessionId]);

  const after = useCallback(
    (idx: number) => {
      const item = data[idx];
      // if the item is not a vist start or end but is in between, we add line
      // if start or end its the connector
      const defaultAfter = {
        content: "''",
        width: 12,
        borderRight: 1,
        borderColor: "#707070",
        position: "absolute",
        right: 0,
      };

      if (item.type === ExtraUserActionType.VISIT_START) {
        return {
          ...defaultAfter,
          height: "50%",
          bottom: 0,
          borderTop: `1px solid #707070`,
        };
      }
      if (item.type === ExtraUserActionType.VISIT_END) {
        return {
          ...defaultAfter,
          height: "50%",
          top: 0,
          borderBottom: `1px solid #707070`,
        };
      }

      // check is inbetween
      if (idx === 0 || idx === data.length - 1) {
        return {};
      }

      let nextIsVisitEnd = false;
      for (let index = idx; index < data.length; index++) {
        const element = data[index];
        if (element.type === ExtraUserActionType.VISIT_START) {
          break;
        }
        if (element.type === ExtraUserActionType.VISIT_END) {
          nextIsVisitEnd = true;
          break;
        }
      }

      return nextIsVisitEnd
        ? {
            ...defaultAfter,
            height: "100%",
          }
        : {};
    },
    [data]
  );

  return (
    <StyledTimeline position="right" ref={onRefChange}>
      {data.map((item, i) => (
        <TimelineItem
          key={i}
          className={activeSessionId === item.blockId ? "active" : ""}
          sx={{
            "&:before": {
              width: 0,
              flex: 0,
              padding: 0,
            },
            "&:after": !readonly ? after(i) : {},
          }}
        >
          <LeadJourneyItem
            item={item}
            index={i}
            itemsLength={data.length}
            isActive={!readonly && activeSessionId === item.blockId}
            onClick={() => {
              !readonly && onClick(item.blockId || 0);
            }}
          />
          {!readonly &&
            item.type === ExtraUserActionType.VISIT_START &&
            activeSessionId === item.blockId &&
            activeHeight > 0 && (
              <>
                <Box
                  component={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  sx={{
                    position: "absolute",
                    height: 12,
                    width: 12,
                    borderTop: `2px solid ${theme.palette.charts[1]}`,
                    borderRight: `2px solid ${theme.palette.charts[1]}`,
                    right: -20,
                    transform: "rotate(45deg)!important",
                    top: activeHeight / 2 - 4,
                  }}
                ></Box>
              </>
            )}
        </TimelineItem>
      ))}
    </StyledTimeline>
  );
}

export default LeadJourneySummary;
