import {
  ApiChartCrawler,
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiOperator,
} from "@incendium/api";
import { Grid, Stack } from "@mui/material";
import { StatCardTypography } from "Components/UI/StatCard";
import { AnalyticsSpacing } from "consts";
import {
  AnalyticsCard,
  AnalyticsIncreaseDecreaseStatCard,
  AnalyticsStatCard,
  formatMetric,
} from "features/analytics";

function CrawlerAnalysis() {
  return (
    <Grid container spacing={AnalyticsSpacing} mb={4}>
      <Grid item xs={3}>
        <Stack spacing={AnalyticsSpacing}>
          <AnalyticsStatCard
            minHeight={110}
            chart={{
              name: "",
              dimension: [],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes: [],
              crawler: ApiChartCrawler.ONLY_CRAWLERS,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                },
              ],
            }}
            renderBody={(data) => (
              <>
                <StatCardTypography size="small" opactity={0.8}>
                  No. Bot Visits:
                </StatCardTypography>
                <StatCardTypography size="large" opactity={1}>
                  {formatMetric(
                    ApiMetric.METRIC_SESSIONS_COUNT,
                    Number(data[0][ApiMetric.METRIC_SESSIONS_COUNT])
                  )}
                </StatCardTypography>
              </>
            )}
          />
          <AnalyticsStatCard
            minHeight={130}
            chart={{
              name: "",
              dimension: [],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes: [],
              crawler: ApiChartCrawler.ONLY_CRAWLERS,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_PAGE_VIEWS_COUNT],
                },
              ],
            }}
            renderBody={(data) => (
              <>
                <StatCardTypography size="small" opactity={0.8}>
                  Which Crawled:
                </StatCardTypography>
                <StatCardTypography size="large" opactity={1}>
                  {formatMetric(
                    ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                    Number(data[0][ApiMetric.METRIC_PAGE_VIEWS_COUNT])
                  )}
                </StatCardTypography>
                <StatCardTypography size="small" opactity={0.8}>
                  Pages
                </StatCardTypography>
              </>
            )}
          />
          <AnalyticsIncreaseDecreaseStatCard
            metric={ApiMetric.METRIC_SESSIONS_COUNT}
            chart={{
              name: "",
              dimension: [],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes: [],
              crawler: ApiChartCrawler.ONLY_CRAWLERS,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                },
              ],
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={9} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          chart={{
            name: `Top 5 Crawler Visits`,
            dimension: [
              ApiDimension.DIMENSION_SESSION_START_BY_DAY,
              ApiDimension.DIMENSION_CRAWLER,
            ],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            crawler: ApiChartCrawler.ONLY_CRAWLERS,
            limit: 5,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>

      <Grid item xs={6} sx={{ minHeight: 480 }}>
        <AnalyticsCard
          chart={{
            name: `Crawled Pages by`,
            dimension: [ApiDimension.DIMENSION_LOCATION],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            limit: 5,
            crawler: ApiChartCrawler.ONLY_CRAWLERS,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
              availableDimensions: [
                ApiDimension.DIMENSION_LOCATION,
                ApiDimension.DIMENSION_PATH,
                ApiDimension.DIMENSION_PRODUCT_NAME,
                ApiDimension.DIMENSION_PRODUCT_CATEGORY,
                ApiDimension.DIMENSION_PRODUCT_BRAND,
              ],
            },
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <AnalyticsCard
          chart={{
            name: "Top 20 Crawlers",
            dimension: [ApiDimension.DIMENSION_CRAWLER],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.PIE,
            limit: 20,
            crawler: ApiChartCrawler.ONLY_CRAWLERS,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
              },
            ],
            displayOptions: {
              noFilters: true,
              showTotals: true,
            },
          }}
        />
      </Grid>

      <Grid item xs={12} sx={{ minHeight: 464 }}>
        <AnalyticsCard
          chart={{
            name: "Pages Visited But Not Crawled in This Time Period",
            dimension: [
              ApiDimension.DIMENSION_PATH,
              ApiDimension.DIMENSION_LOCATION,
            ],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            crawler: ApiChartCrawler.WITH_CRAWLERS,
            attributes: [],
            orderBy: `${ApiMetric.METRIC_SESSIONS_COUNT} desc`,
            having: [
              {
                metric: ApiMetric.METRIC_CRAWLER_VISIT_COUNT,
                operator: ApiOperator.EQUAL,
                value: 0,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                  ApiMetric.METRIC_PAGE_MICRO_CONVERSION_RATE,
                  ApiMetric.METRIC_SESSION_REVENUE,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default CrawlerAnalysis;
