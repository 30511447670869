/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ProtobufAnyFromJSON, ProtobufAnyToJSON, } from './ProtobufAny';
export function GooglerpcStatusFromJSON(json) {
    return GooglerpcStatusFromJSONTyped(json, false);
}
export function GooglerpcStatusFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'code': !exists(json, 'code') ? undefined : json['code'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'details': !exists(json, 'details') ? undefined : (json['details'].map(ProtobufAnyFromJSON)),
    };
}
export function GooglerpcStatusToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'code': value.code,
        'message': value.message,
        'details': value.details === undefined ? undefined : (value.details.map(ProtobufAnyToJSON)),
    };
}
