/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiCSVError;
(function (ApiCSVError) {
    ApiCSVError["CSV_ERROR_NAN"] = "CSVErrorNAN";
    ApiCSVError["CSV_ERROR_NOT_UNIQUE"] = "CSVErrorNotUnique";
    ApiCSVError["CSV_ERROR_INVALID_KEY"] = "CSVErrorInvalidKey";
})(ApiCSVError || (ApiCSVError = {}));
export function ApiCSVErrorFromJSON(json) {
    return ApiCSVErrorFromJSONTyped(json, false);
}
export function ApiCSVErrorFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiCSVErrorToJSON(value) {
    return value;
}
