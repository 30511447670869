import {
  ApiJobTagger,
  ApiJobTaggerPayload,
  ApiLocation,
  ApiLocationPageTagger,
  ApiProductTagger,
  ApiProject,
  ApiProjectType,
  ApiReservedLocationPageTagger,
  ApiReservedPageTypes,
} from "@incendium/api";
import {
  Box,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { jobService, locationService } from "Apis";
import { TagList } from "Components/TagList";
import { useNotification } from "Hooks";
import { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import { TagForm } from "Components/TagForm";
import { motion, AnimatePresence } from "framer-motion";
import { enumToArray, formatEnumVal } from "Helpers/enumToText";
import ProductTaggerForm from "Components/Product/ProductTaggerForm";
import JobTaggerTab from "Components/TagPages/JobTaggerTab";
import produce from "immer";
import { useHasProjectType } from "features/project";

type Ttab = "page-data" | "product" | "job";

export const PageDataTab = ({
  location,
  setLocation,
  project,
  productSchemaFound,
  active,
}: {
  location: ApiLocation;
  setLocation: (d: ApiLocation) => void;
  project: ApiProject;
  productSchemaFound: boolean;
  active: boolean;
}) => {
  const [taggersLoaded, setTaggersLoaded] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [tags, setTags] = useState<ApiLocationPageTagger[]>([]);
  const [tab, setTab] = useState<Ttab>("page-data");
  const hasProjectType = useHasProjectType();

  const productTagger = useMemo(
    () => (location.productTaggers || [])[0] || {},
    [location.productTaggers]
  );
  const setProductTagger = useCallback(
    (val: ApiProductTagger) => {
      setLocation(
        produce(location, (draft) => {
          draft.productTaggers = [val];
        })
      );
    },
    [location, setLocation]
  );
  const jobTagger = useMemo(
    () => (location.jobTaggers || [])[0] || {},
    [location.jobTaggers]
  );
  const setJobTagger = useCallback(
    (val: ApiJobTagger) => {
      setLocation(
        produce(location, (draft) => {
          draft.jobTaggers = [val];
        })
      );
    },
    [location, setLocation]
  );

  const [selectedTag, setSelectedTag] = useState<
    string | null | ApiLocationPageTagger
  >(null);

  const fetchProductTagger = async () => {
    const res = await locationService.locationServiceListProductTaggers({
      locationId: location.id as number,
      projectId: project.id as number,
    });
    setProductTagger(
      res.results && res.results.length > 0 ? res.results[0] : {}
    );
  };
  const fetchJobTagger = async () => {
    const res = await jobService.jobServiceListLocationJobTaggers({
      locationId: location.id as number,
      projectId: project.id as number,
    });
    setJobTagger(res.results && res.results.length > 0 ? res.results[0] : {});
  };

  const fetchAllTags = async () => {
    await locationService
      .locationServiceListLocationPageTaggers({
        locationId: location.id as number,
        projectId: project.id as number,
      })
      .then((result) => {
        if (result.results) {
          setTags(result.results);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const { showSuccessNotification, showErrorNotification } = useNotification();

  useEffect(() => {
    if (taggersLoaded || !location.id) {
      return;
    }
    fetchAllTags().then(() => setIsReady(true));
    fetchProductTagger();
    fetchJobTagger();
    setTaggersLoaded(true);
  }, [taggersLoaded, location.id]);

  const handleAddTag = async ({
    name,
    selector,
    regex,
  }: {
    name: string;
    selector: string;
    regex?: string;
  }) => {
    const reservedIDX = enumToArray(ApiReservedLocationPageTagger)
      .map(formatEnumVal)
      .findIndex((v) => name === v);
    try {
      await locationService.locationServiceCreateLocationPageTagger({
        locationId: location.id as number,
        projectId: project.id as number,
        payload: {
          name,
          selector,
          regex: regex ? regex : undefined,
          reserved: enumToArray(ApiReservedLocationPageTagger)[reservedIDX],
        },
      });
      await fetchAllTags();
      setSelectedTag(null);
      showSuccessNotification("Tag Added");
    } catch (e) {
      showErrorNotification("Failed to add Tag");
    }
  };

  const handleEditTag = async ({
    name,
    selector,
    regex,
    crawlSearchParams,
  }: {
    name: string;
    selector: string;
    regex?: string;
    crawlSearchParams?: boolean;
  }) => {
    if (selectedTag && typeof selectedTag !== "string") {
      const reservedIDX = enumToArray(ApiReservedLocationPageTagger)
        .map(formatEnumVal)
        .findIndex((v) => name === v);

      try {
        await locationService.locationServiceUpdateLocationPageTagger({
          locationId: location.id as number,
          projectId: project.id as number,
          pageTaggerId: selectedTag.id as number,
          payload: {
            name,
            selector,
            regex: regex ? regex : undefined,
            crawlSearchParams: !!crawlSearchParams,
            reserved: enumToArray(ApiReservedLocationPageTagger)[reservedIDX],
          },
        });
        await fetchAllTags();
        setSelectedTag(null);
        showSuccessNotification("Tag Updated");
      } catch (e) {
        showErrorNotification("Failed to update Tag");
      }
    }
  };

  const handleSaveProductTagger = async (input: ApiProductTagger) => {
    const payload = {
      titleSelector: input.titleSelector,
      descriptionSelector: input.descriptionSelector,
      priceSelector: input.priceSelector,
      categorySelector: input.categorySelector,
      imageUrlSelector: input.imageUrlSelector,
      productIdSelector: input.productIdSelector,
      productSkuSelector: input.skuSelector,
      brandSelector: input.brandSelector,
      uniqueField: input.uniqueField,
      useLinkedData: input.useLinkedData,
    };

    const res = input.id
      ? await locationService.locationServiceUpdateProductTagger({
          projectId: project.id as number,
          locationId: location.id as number,
          productTaggerId: productTagger.id as number,
          payload,
        })
      : await locationService.locationServiceCreateProductTagger({
          projectId: project.id as number,
          locationId: location.id as number,
          payload,
        });
    setProductTagger(res);
    showSuccessNotification("Product Info Saved");
  };

  const handleSaveJobTagger = async (input: ApiJobTagger) => {
    const payload: ApiJobTaggerPayload = {
      uniqueField: input.uniqueField,
      titleSelector: input.titleSelector,
      descriptionSelector: input.descriptionSelector,
      idSelector: input.idSelector,
      categorySelector: input.categorySelector,
      salaryRangeSelector: input.salaryRangeSelector,
      contractTypeSelector: input.contractTypeSelector,
      locationSelector: input.locationSelector,
      locationId: location.id as number,
    };

    const res = input.id
      ? await jobService.jobServiceUpdateJobTagger({
          projectId: project.id as number,
          jobTaggerId: input.id as number,
          payload,
        })
      : await jobService.jobServiceCreateJobTagger({
          projectId: project.id as number,
          payload,
        });
    setJobTagger(res);
    showSuccessNotification("Job Info Saved");
  };

  const handleTagDelete = async (tag: ApiLocationPageTagger) => {
    setIsReady(false);
    try {
      await locationService.locationServiceDeleteLocationPageTagger({
        locationId: location.id as number,
        projectId: project.id as number,
        pageTaggerId: tag.id as number,
      });
      await fetchAllTags();
      showSuccessNotification("Tag deleted");
    } catch (e) {
      showErrorNotification("Failed to delete Tag");
    } finally {
      setIsReady(true);
    }
  };

  const showProducts = useMemo(() => {
    return (
      hasProjectType(ApiProjectType.TYPE_ECOMMERCE) &&
      (location.pageTypes || []).findIndex(
        (pt) => pt._default === ApiReservedPageTypes.PRODUCT_PAGE
      ) >= 0
    );
  }, [location, hasProjectType]);
  const showJobs = useMemo(() => {
    return (
      hasProjectType(ApiProjectType.TYPE_RECRUITMENT) &&
      (location.pageTypes || []).findIndex(
        (pt) => pt._default === ApiReservedPageTypes.JOB_PAGE
      ) >= 0
    );
  }, [location, hasProjectType]);

  if (!isReady) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={"2rem"} />
      </Box>
    );
  }

  const pageDataBody = () => {
    if (selectedTag === null) {
      return (
        <TagList
          title="add new tag"
          tags={tags}
          onAdd={() => setSelectedTag("new")}
          onEdit={(tag) => {
            setSelectedTag(tag as ApiLocationPageTagger);
          }}
          onDelete={(tag) => handleTagDelete(tag as ApiLocationPageTagger)}
        />
      );
    }
    if (selectedTag) {
      return (
        <TagForm
          tag={typeof selectedTag === "string" ? undefined : selectedTag}
          onEdit={handleEditTag}
          onCancel={() => setSelectedTag(null)}
          onAdd={handleAddTag}
        />
      );
    }
  };

  return (
    <>
      {(showProducts || showJobs) && (
        <Box mb={2}>
          <ToggleButtonGroup
            size="small"
            fullWidth
            value={tab}
            exclusive
            color="secondary"
            onChange={(e, v) => {
              setTab(v);
            }}
          >
            <ToggleButton value={"page-data"}>Page Data</ToggleButton>
            {showProducts && (
              <ToggleButton value={"product"}>Product</ToggleButton>
            )}
            {showJobs && <ToggleButton value={"jobs"}>Jobs</ToggleButton>}
          </ToggleButtonGroup>
        </Box>
      )}

      <AnimatePresence mode="wait">
        {tab === "page-data" ? (
          <motion.div
            key="page-data"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {pageDataBody()}
          </motion.div>
        ) : tab === "product" ? (
          <Box
            component={motion.div}
            key="product"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            sx={{ flex: 1, overflowY: "auto" }}
          >
            <ProductTaggerForm
              productSchemaFound={productSchemaFound}
              productTagger={productTagger}
              onSubmit={handleSaveProductTagger}
              onCancel={() => {
                setSelectedTag(null);
                setTab("page-data");
              }}
              active={active}
            />
          </Box>
        ) : (
          <Box
            component={motion.div}
            key="job"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            sx={{ flex: 1, overflowY: "auto" }}
          >
            <JobTaggerTab
              tagger={jobTagger}
              onSubmit={handleSaveJobTagger}
              onCancel={() => {
                setSelectedTag(null);
                setTab("page-data");
              }}
            />
          </Box>
        )}
      </AnimatePresence>
    </>
  );
};
