import { locationService } from "Apis";
import { useSharedState } from "./useSharedState";
import { ApiDomain, ApiProject } from "@incendium/api";

const fetchDomains = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const domains = await locationService.locationServiceListDomains({
      projectId: selectedProject.id as number,
    });
    return domains.results || [];
  }
  return [];
};
export const useDomains = () => {
  const { state, setState, refetch } = useSharedState<ApiDomain, ApiDomain[]>(
    "domains",
    [],
    fetchDomains
  );

  return {
    domains: state,
    setDomains: setState,
    refetch,
  };
};
