import {
  alpha,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  styled,
} from "@mui/material";
import {
  DateCalendar,
  DateField,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import { CallbackOrVal, GenericDialoagProps } from "Interfaces";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";

interface CustomPickerDayProps extends PickersDayProps<moment.Moment> {
  isFrom: boolean;
  isTo: boolean;
  isSelected: boolean;
  isHovered: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "isHovered",
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, isFrom, isTo }) => {
  return {
    borderRadius: 0,
    "&:focus": {
      backgroundColor: "transparent",
    },
    ...(isHovered && {
      backgroundColor: `${alpha(theme.palette.primary.main, 0.2)}!important`,
    }),
    ...(isSelected && {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
      "&:focus": {
        backgroundColor: theme.palette.primary.main,
      },
    }),
    ...(isFrom && {
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
    }),
    ...(isTo && {
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
    }),
  };
}) as React.ComponentType<CustomPickerDayProps>;

interface IDayProps extends PickersDayProps<moment.Moment> {
  from?: moment.Moment | null;
  to?: moment.Moment | null;
  hoveredDay?: moment.Moment | null;
}

function Day(props: IDayProps) {
  const { day, from, to, hoveredDay, ...rest } = props;

  const isSelected = useMemo(() => {
    if (!to && !from) {
      return false;
    }
    if (from && !to) {
      return day.startOf("day").isSame(from.startOf("day"));
    }
    return day.isSameOrAfter(from) && (!to || day.isSameOrBefore(to));
  }, [day, to, from]);

  const isHovered = useMemo(() => {
    if (!to && !from) {
      return false;
    }
    if (from && hoveredDay && !to) {
      return (
        day.isSameOrAfter(from) &&
        day.startOf("day").isSameOrBefore(hoveredDay.startOf("day"))
      );
    }
    return false;
  }, [hoveredDay, to, from, day]);

  return (
    <CustomPickersDay
      {...rest}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isSelected}
      isHovered={isHovered}
      isFrom={!!from && day.startOf("day").isSame(from.startOf("day"))}
      isTo={
        to
          ? day.startOf("day").isSame(to.startOf("day"))
          : hoveredDay
          ? day.startOf("day").isSame(hoveredDay.startOf("day"))
          : false
      }
    />
  );
}

interface IAdvancedDatePickerProps extends GenericDialoagProps {
  onSubmit: () => void;
  from: moment.Moment | null;
  setFrom: (v: CallbackOrVal<moment.Moment | null>) => void;
  to: moment.Moment | null;
  setTo: (v: CallbackOrVal<moment.Moment | null>) => void;
}

function AdvancedDatePicker({
  open,
  setOpen,
  onSubmit,
  from,
  to,
  setFrom,
  setTo,
}: IAdvancedDatePickerProps) {
  const [selectedMonth, setSelectedMonth] = useState(
    from || moment().utc().subtract(1, "month")
  );

  const [hoveredDay, setHoveredDay] = useState<moment.Moment | null>(null);
  const [selectedMonthTo, setSelectedMonthTo] = useState(
    selectedMonth.clone().add(1, "month")
  );
  const onChange = useCallback(
    (date: moment.Moment | null, isTo: boolean) => {
      if (date) {
        setSelectedMonth(isTo ? date.clone().subtract(1, "month") : date);
      }

      if (!from) {
        setFrom(date);
        return;
      }
      if (!to) {
        setTo(date);
        return;
      }
      setFrom(date);
      setTo(null);
    },
    [from, setFrom, to, setTo]
  );

  useEffect(() => {
    setSelectedMonthTo(selectedMonth.clone().add(1, "month"));
  }, [selectedMonth]);

  useEffect(() => {
    if (!open) {
      setSelectedMonth(from || moment().utc().subtract(1, "month"));
    }
  }, [open, from]);

  return (
    <CustomDialog maxWidth="md" onClose={() => setOpen(false)} open={open}>
      <DialogTitle>Advanced Date Picker</DialogTitle>
      <DialogContent>
        <Stack
          mt={3}
          direction={"row"}
          spacing={2}
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ position: "relative" }}
        >
          <Stack spacing={1}>
            <Box px={3}>
              <DateField
                fullWidth
                size="small"
                label="From"
                value={from}
                onChange={(d) => setFrom(d)}
              />
            </Box>
            <DateCalendar
              views={["day"]}
              value={selectedMonth}
              minDate={moment().utc().subtract(5, "years")}
              disableFuture
              onChange={(v) => onChange(v, false)}
              onMonthChange={(d) => setSelectedMonth(d)}
              slots={{ day: Day }}
              slotProps={{
                day: (state) => {
                  return {
                    from,
                    to,
                    hoveredDay,
                    onPointerEnter: () => setHoveredDay(state.day),
                    onPointerLeave: () => setHoveredDay(null),
                  } as any;
                },
              }}
              sx={{
                "& .MuiPickersCalendarHeader-root": {
                  flexDirection: "row-reverse",
                  "& .MuiPickersCalendarHeader-labelContainer": {
                    margin: "auto",
                    paddingRight: "40px",
                  },
                },
                "& .MuiIconButton-edgeEnd": {
                  position: "absolute",
                  left: 15,
                  top: 55,
                },
                "& .MuiIconButton-edgeStart": {
                  position: "absolute",
                  right: 15,
                  top: 55,
                },
              }}
            />
          </Stack>
          <Stack spacing={1}>
            <Box px={3}>
              <DateField
                fullWidth
                size="small"
                label="To"
                value={to}
                onChange={(d) => setTo(d)}
              />
            </Box>
            <DateCalendar
              views={["day"]}
              value={selectedMonthTo}
              minDate={moment().utc().subtract(5, "years")}
              onChange={(v) => onChange(v, true)}
              disableFuture
              slots={{
                day: Day,
              }}
              slotProps={{
                day: (state) => {
                  return {
                    from,
                    to,
                    hoveredDay,
                    onPointerEnter: () => setHoveredDay(state.day),
                    onPointerLeave: () => setHoveredDay(null),
                  } as any;
                },
              }}
              sx={{
                "& .MuiPickersCalendarHeader-root": {
                  "& .MuiPickersCalendarHeader-labelContainer": {
                    margin: "auto",
                    paddingLeft: "40px",
                  },
                },
                "& .MuiIconButton-edgeEnd": {
                  display: "none",
                },
                "& .MuiIconButton-edgeStart": {
                  display: "none",
                },
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={onSubmit} color="primary">
            Confirm
          </Button>
        </Box>
      </DialogActions>
    </CustomDialog>
  );
}

export default AdvancedDatePicker;
