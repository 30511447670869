import {
  ApiCampaignStatus,
  ApiCampaignType,
  ApiCanActivateCampaignResponse,
  ApiSimpleCampaign,
} from "@incendium/api";
import { AnalyticsOutlined, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  Switch,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { personalisationService } from "Apis";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import CopyClipboardBtn from "Components/CopyClipboardBtn/CopyClipboardBtn";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { cell2Icons } from "consts";
import { friendlyDate } from "Helpers/dates";
import { formatNumber } from "Helpers/numbers";
import { truncate } from "Helpers/truncate";
import { useCallback, useEffect, useState } from "react";

interface IDetailsCellProps {
  campaign: ApiSimpleCampaign;
}

function DetailsCell({ campaign }: IDetailsCellProps) {
  const body = useCallback(() => {
    switch (campaign.type) {
      case ApiCampaignType.CAMPAIGN_TYPE_SPLIT:
        return (
          <Stack my={1}>
            <Typography gutterBottom variant="subtitle2">
              Split URL's
            </Typography>
            {(campaign.urls || []).map((u) => (
              <Typography key={u.name} variant="body2" noWrap>
                {u.url}
              </Typography>
            ))}
            <Box my={1}>
              <Divider />
            </Box>
            <Typography gutterBottom variant="subtitle2">
              Fallback URL
            </Typography>
            <Typography variant="body2" noWrap>
              {campaign.fallbackUrl}
            </Typography>
          </Stack>
        );

      default:
        return (
          <>
            <Typography variant="body2">
              <strong>Probability</strong> :{" "}
              {campaign.probability ? campaign.probability * 100 : 100}%
            </Typography>
            <Typography variant="body2">
              <strong>Max No. of Leads</strong> :{" "}
              {campaign.maxNumberOfLeads
                ? formatNumber(campaign.maxNumberOfLeads)
                : "N/A"}
            </Typography>
          </>
        );
    }
  }, [campaign]);
  return <TableCell>{body()}</TableCell>;
}

interface ICampaignListRowProps {
  campaign: ApiSimpleCampaign;
  projectId: number;
  editCampaign: (campaign: ApiSimpleCampaign) => void;
  deleteCampaign: (campaign: ApiSimpleCampaign) => void;
  activateCampaign: (campaign: ApiSimpleCampaign) => Promise<void>;
  deactivateCampaign: (campaign: ApiSimpleCampaign) => Promise<void>;
  toAnalytics?: (id: number) => void;
}

function CampaignListRow({
  campaign,
  projectId,
  editCampaign,
  deleteCampaign,
  activateCampaign,
  deactivateCampaign,
  toAnalytics,
}: ICampaignListRowProps) {
  const [canActivate, setCanActivate] =
    useState<ApiCanActivateCampaignResponse>({});

  useEffect(() => {
    const check = async () => {
      const res =
        await personalisationService.personalisationServiceCanActivateCampaign({
          projectId,
          campaignId: campaign.id as number,
        });
      setCanActivate(res);
    };
    check();
  }, [campaign, projectId]);

  const toggleActive = useCallback(
    (e, v) => {
      if (v) {
        return activateCampaign(campaign);
      }
      return deactivateCampaign(campaign);
    },
    [campaign, activateCampaign, deactivateCampaign]
  );

  return (
    <TableRow key={campaign.id}>
      <TableCell>
        <Stack direction={"row"} justifyContent="space-between">
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle2">{campaign.name}</Typography>
            <Typography variant="body2" fontStyle={"italic"}>
              {campaign.type === ApiCampaignType.CAMPAIGN_TYPE_AB
                ? "A/B Campaign"
                : campaign.type === ApiCampaignType.CAMPAIGN_TYPE_SPLIT
                ? "Split Campaign"
                : "Rezonate"}
            </Typography>
            {campaign.url && (
              <Stack mt={2}>
                <Divider />
                <ListItem disableGutters>
                  <CopyClipboardBtn
                    isIconButton
                    variant="text"
                    text={campaign.url}
                    size="extraSmall"
                    sx={{ textAlign: "left", paddingLeft: 0 }}
                  />

                  <ListItemText
                    primary="Split Root Domain"
                    secondary={campaign.url}
                  />
                </ListItem>
              </Stack>
            )}
            {campaign.type === ApiCampaignType.CAMPAIGN_TYPE_NONE && (
              <Stack mt={2}>
                <Divider />
                <Typography mt={1} variant="subtitle2">
                  Personalisations
                </Typography>
                {(campaign.personalisations || []).length > 0 && (
                  <Stack spacing={1}>
                    {campaign.personalisations?.map((p) => (
                      <Stack
                        direction={"row"}
                        key={p.id}
                        justifyContent="space-between"
                        alignItems={"center"}
                      >
                        <Typography variant="body1">{p.name}</Typography>
                        {toAnalytics && (
                          <Box>
                            <Button
                              onClick={() => toAnalytics(p.id!)}
                              endIcon={<AnalyticsOutlined fontSize="small" />}
                              size="extraSmall"
                            >
                              View in Analytics
                            </Button>
                          </Box>
                        )}
                      </Stack>
                    ))}
                  </Stack>
                )}
              </Stack>
            )}
          </Box>
          {campaign.type !== ApiCampaignType.CAMPAIGN_TYPE_NONE &&
            typeof toAnalytics !== "undefined" && (
              <Box>
                <Button
                  onClick={() => toAnalytics!(campaign.id!)}
                  endIcon={<AnalyticsOutlined fontSize="small" />}
                  size="extraSmall"
                >
                  View in Analytics
                </Button>
              </Box>
            )}
        </Stack>
      </TableCell>
      <TableCell>{truncate(campaign.description || "", 30)}</TableCell>
      <TableCell>
        <Typography variant="body2">
          <strong>From</strong> :{" "}
          {campaign.startDate ? friendlyDate(campaign.startDate) : "N/A"}
        </Typography>
        <Typography variant="body2">
          <strong>To</strong> :{" "}
          {campaign.endDate ? friendlyDate(campaign.endDate) : "N/A"}
        </Typography>
      </TableCell>
      <DetailsCell campaign={campaign} />

      <TableCell style={{ width: 190 }}>
        <StyledFlexBox>
          <Switch
            size="small"
            disabled={!canActivate?.canActivate}
            checked={
              campaign.status === ApiCampaignStatus.CAMPAIGN_STATUS_ACTIVE
            }
            onChange={toggleActive}
          />

          <Typography
            color={
              campaign.status === ApiCampaignStatus.CAMPAIGN_STATUS_ACTIVE
                ? "primary"
                : "secondary"
            }
          >
            Is Active?
          </Typography>

          {!canActivate?.canActivate ? (
            <ClickableTooltip
              onHover
              text={canActivate.message || ""}
              icon={<StyledHelp fontSize="small" />}
            />
          ) : (
            <Box />
          )}
        </StyledFlexBox>
      </TableCell>
      <TableCell align="right" style={{ width: cell2Icons }}>
        <IconButton
          onClick={() => editCampaign(campaign)}
          size="small"
          color="primary"
        >
          <Edit />
        </IconButton>
        <IconButton
          size="small"
          color="secondary"
          onClick={() => deleteCampaign(campaign)}
        >
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default CampaignListRow;
