/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiLeadInteractionTye;
(function (ApiLeadInteractionTye) {
    ApiLeadInteractionTye["UNASSIGNED"] = "LEAD_INTERACTION_TYPE_UNASSIGNED";
    ApiLeadInteractionTye["CALL"] = "LEAD_INTERACTION_TYPE_CALL";
    ApiLeadInteractionTye["SMS"] = "LEAD_INTERACTION_TYPE_SMS";
    ApiLeadInteractionTye["EMAIL"] = "LEAD_INTERACTION_TYPE_EMAIL";
    ApiLeadInteractionTye["REJECTION"] = "LEAD_INTERACTION_TYPE_REJECTION";
    ApiLeadInteractionTye["CUSTOM"] = "LEAD_INTERACTION_TYPE_CUSTOM";
})(ApiLeadInteractionTye || (ApiLeadInteractionTye = {}));
export function ApiLeadInteractionTyeFromJSON(json) {
    return ApiLeadInteractionTyeFromJSONTyped(json, false);
}
export function ApiLeadInteractionTyeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiLeadInteractionTyeToJSON(value) {
    return value;
}
