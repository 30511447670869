import { adaptV4Theme, ComponentsOverrides } from "@mui/material";
import { createTheme as createMuiTheme } from "@mui/material/styles";
import { unstable_createMuiStrictModeTheme } from "@mui/material";

declare module "@mui/material/styles/overrides" {
  interface ComponentNameToClassKey {
    MuiDateCalendar: "root";
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    charts: string[];
    analytics: { [key: string]: string };
    interest: string;
    highlight: string;
  }
  interface SimplePaletteColorOptions {
    vibrant?: string;
  }
  interface PaletteColor {
    vibrant?: string;
  }

  interface PaletteOptions {
    charts: string[];
    analytics: { [key: string]: string };
    interest: string;
    highlight: string;
  }
  interface TypeBackground {
    light: string;
  }
  interface TypographyVariants {
    bodyMedium: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    bodyMedium: React.CSSProperties;
  }
  interface Components {
    MuiDateCalendar?: {
      styleOverrides?: ComponentsOverrides["MuiDateCalendar"];
    };
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    contained_ntt: true;
  }
  interface ButtonPropsSizeOverrides {
    extraSmall: true;
  }
}
declare module "@mui/material/IconButton" {
  interface IconButtonPropsSizeOverrides {
    extraSmall: true;
  }
}
declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsSizeOverrides {
    extraSmall: true;
  }
}

const createTheme =
  process.env.NODE_ENV === "production"
    ? createMuiTheme
    : unstable_createMuiStrictModeTheme;

const analyticsPallete = {
  blue: "#7174F6",
  orange: "#F2A139",
  purple: "#A66AF7",
  plum: "#752258",
  pink: "#EA345C",
  yellow: "#FFDC19",
  green: "#8FC300",
};

const theme = createTheme(
  adaptV4Theme({
    props: {
      MuiLink: {
        underline: "hover",
      },
    },
    typography: {
      fontFamily: `"Inter Tight", sans-serif`,
      allVariants: {
        color: "#151530",
        fontFamily: `"Inter Tight", sans-serif`,
      },
      h1: {
        fontSize: 26,
        fontWeight: 700,
      },
      h2: {
        fontSize: 22,
        fontWeight: 700,
      },
      h3: {
        fontSize: 18,
        fontWeight: 700,
      },
      h4: {
        fontSize: 16,
        fontWeight: 700,
      },
      subtitle1: {
        fontSize: 16,
        fontWeight: 700,
      },
      subtitle2: {
        fontSize: 13,
        fontWeight: 700,
      },
      body1: {
        fontSize: 14,
      },
      bodyMedium: {
        fontSize: 14,
      },
      body2: {
        fontSize: 11,
      },
    },
    palette: {
      // incendium purples
      // #7d82d9 - faded - icons
      // #08062c - dark
      // #7374ff - light
      // #643dff" - vibrant
      primary: {
        dark: "#08062c",
        main: "#7374ff",
        light: "#7d82d9",
        vibrant: "#643dff",
      },
      secondary: {
        dark: "#474979",
        main: "#686aa8",
        light: "#7d82d9",
      },
      error: {
        main: "#D54747",
      },
      info: {
        main: "#575fe4",
        dark: "#D84168",
      },
      interest: "#2A2779",
      highlight: "#007B9F",
      success: { main: "#008D16" },
      // warning: {
      //   main: "#D84168",
      // },
      text: {
        primary: "#151530",
        secondary: "#7C97C6",
      },
      background: {
        default: "#e5ebfe",
        light: "#d0d9f6",
      },

      analytics: analyticsPallete,

      charts: [
        analyticsPallete.blue,
        analyticsPallete.orange,
        analyticsPallete.purple,
        analyticsPallete.plum,
        analyticsPallete.pink,
        analyticsPallete.green,

        "#FFDC19", // yellow
        "#007B9F", // turquise

        // old more muted ones
        "#e09e6d", // orange
        "#D84168", // plum
        "#8F1965", // pink
        "#8686CC", // purple
        "#E2B260", // yellow
        "#4d4b82", // dark blue
        "#658cb8", // light blue
      ],
    },
  })
);

theme.components = {
  ...theme.components,
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        fontFamily: "soleil, sans-serif",
        margin: 0,
        padding: 0,
      },
      body: {
        margin: 0,
        padding: 0,
        overflow: "hidden",
      },
      a: {
        textDecoration: "none",
        color: theme.palette.primary.main,
      },
      pre: {
        tabSize: 4,
        whiteSpace: "pre-wrap",
        wordBreak: "break-all",
        textAlign: "justify",
      },
      scrollbarColor: `#c4cbea ${theme.palette.primary.main}`,

      "& ::-webkit-scrollbar": {
        width: "18px",
      },

      "& ::-webkit-scrollbar-track": {
        border: `5px solid ${theme.palette.background.default}`,
        borderRadius: 10,
        background: "#c4cbea",
      },

      "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.primary.main,
        border: "4px solid transparent",
        borderRadius: 10,
        backgroundClip: "content-box",
      },
    },
  },
  MuiSvgIcon: {
    variants: [
      {
        props: { fontSize: "extraSmall" },
        style: { fontSize: "1rem" },
      },
    ],
    styleOverrides: {},
  },
  MuiButton: {
    variants: [
      {
        props: { size: "extraSmall" },
        style: { fontSize: 11, padding: "4px 8px", lineHeight: "20px" },
      },
    ],
    defaultProps: {
      variant: "contained",
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        textTransform: "capitalize",
        fontWeight: 500,
        paddingTop: 5,
        paddingBottom: 5,
      },
      containedSecondary: {
        color: theme.palette.common.white,
        "&:hover": {
          background: theme.palette.secondary.dark,
        },
      },
      containedPrimary: {
        background: theme.palette.primary.main,
        "&:hover": {
          background: theme.palette.primary.vibrant,
        },
      },
      outlinedPrimary: {
        borderColor: theme.palette.primary.dark,
        color: theme.palette.primary.dark,
        "&:hover": {
          borderColor: theme.palette.primary.vibrant,
          color: theme.palette.primary.vibrant,
        },
      },

      sizeLarge: {
        fontSize: 16,
        lineHeight: "26px",
        padding: "10px 18px",
        minWidth: 1,
        "& a": {
          lineHeight: "46px",
          width: "100%",
          margin: "-10px -18px",
        },
        "& svg": {
          fontSize: 26,
        },
      },

      // sizeMedium: {
      //   fontSize: 16,
      //   padding: "6px 12px",
      // },
      sizeSmall: {
        height: 36,
        fontSize: 14,
        padding: "6px 12px",
        lineHeight: "20px",
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        height: 36,
      },
    },
  },
  MuiIconButton: {
    variants: [
      {
        props: { size: "extraSmall" },
        style: {
          padding: 3,
          "& .MuiSvgIcon-root": {
            height: "18px",
            width: "18px",
          },
        },
      },
    ],
    defaultProps: {
      color: "inherit",
    },
    styleOverrides: {
      colorPrimary: {
        color: theme.palette.primary.light,
        "&:hover": {
          color: theme.palette.primary.vibrant,
        },
      },
      colorSecondary: {
        color: "#a1affe",
        "&:hover": {
          color: theme.palette.primary.vibrant,
        },
      },
      colorInherit: {
        color: theme.palette.secondary.light,
        "&:hover": {
          color: theme.palette.secondary.dark,
        },
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        background: "rgba(255,255,255,0.6)",
        boxShadow: "3px 3px 6px #5E65E526",
      },
      secondary: {
        background: theme.palette.secondary.main,
        color: theme.palette.common.white,
      },
    },
  },
  MuiStepper: {
    styleOverrides: {
      root: {
        background: "none",
        border: "none",
      },
    },
  },

  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: 11,
        fontWeight: 400,
        cursor: "default",
        padding: theme.spacing(0.6, 1.5),
        textShadow: "0 0 4px rgba(0,0,0,0.5)",
        borderRadius: 10,
      },
      arrow: {
        color: theme.palette.primary.main,
      },
      tooltipPlacementLeft: {
        maxWidth: 216,
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      switchBase: {
        color: theme.palette.secondary.main,
      },
      checked: {
        color: theme.palette.primary.main,
      },
      track: {
        backgroundColor: theme.palette.secondary.main,
      },
      colorSecondary: {
        "&.Mui-checked": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: theme.palette.secondary.main,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      sizeSmall: {
        fontSize: theme.typography.body2.fontSize,
        lineHeight: 1.43,
      },
    },
  },

  MuiInputLabel: {
    styleOverrides: {
      sizeSmall: {
        lineHeight: "20px",
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {},
      adornedEnd: {
        "& svg": {
          color: theme.palette.secondary.light,
        },
      },
      sizeSmall: {
        paddingTop: "0!important",
        paddingBottom: "0!important",
      },
      inputSizeSmall: {
        paddingTop: "8px!important", // cant find what is overriding the padding so having to add important :-(
        paddingBottom: "8px!important",
      },
    },
  },
  MuiInput: {
    defaultProps: {
      size: "small",
    },
  },
  MuiAutocomplete: {
    defaultProps: {
      size: "small",
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.background.light,
        },
      },
    },
  },

  MuiDivider: {
    styleOverrides: {
      root: {
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: 10,
        border: `4px solid ${theme.palette.primary.main}`,
        background: theme.palette.primary.main,
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        background: "white",
      },
    },
  },
  MuiModal: {
    styleOverrides: {
      root: {
        backdropFilter: "blur(3px)",
        "&.MuiPopover-root": {
          backdropFilter: "none",
        },
      },
    },
  },
  MuiDrawer: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      paper: {
        borderRight: `none`,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        minWidth: 600,
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      sizeSmall: {
        height: 22,
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        "& .MuiTableCell-root:last-child": {
          borderRight: "none",
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        lineHeight: 1.5,
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  MuiDateCalendar: {},
};

export default theme;
