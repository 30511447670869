/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiCSVMappingPayloadToJSON, ApiCSVMappingResponseFromJSON, ApiCSVRuleFromJSON, ApiCSVRulesFromJSON, ApiCSVSaveMultiplePayloadToJSON, ApiCSVSavePayloadToJSON, ApiCSVSessionsFromJSON, ApiCSVTestPayloadToJSON, ApiCSVTestResultFromJSON, } from '../models';
/**
 *
 */
export class CSVServiceApi extends runtime.BaseAPI {
    /**
     */
    cSVServiceDeleteCSVSessionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling cSVServiceDeleteCSVSession.');
            }
            if (requestParameters.sessionKey === null || requestParameters.sessionKey === undefined) {
                throw new runtime.RequiredError('sessionKey', 'Required parameter requestParameters.sessionKey was null or undefined when calling cSVServiceDeleteCSVSession.');
            }
            const queryParameters = {};
            if (requestParameters.ruleId !== undefined) {
                queryParameters['ruleId'] = requestParameters.ruleId;
            }
            if (requestParameters.sessionId !== undefined) {
                queryParameters['sessionId'] = requestParameters.sessionId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/csv/v1/project/{projectId}/sessions/{sessionKey}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"sessionKey"}}`, encodeURIComponent(String(requestParameters.sessionKey))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    cSVServiceDeleteCSVSession(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.cSVServiceDeleteCSVSessionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    cSVServiceDeleteCSVSessionTableRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling cSVServiceDeleteCSVSessionTable.');
            }
            if (requestParameters.sessionKey === null || requestParameters.sessionKey === undefined) {
                throw new runtime.RequiredError('sessionKey', 'Required parameter requestParameters.sessionKey was null or undefined when calling cSVServiceDeleteCSVSessionTable.');
            }
            if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
                throw new runtime.RequiredError('sessionId', 'Required parameter requestParameters.sessionId was null or undefined when calling cSVServiceDeleteCSVSessionTable.');
            }
            const queryParameters = {};
            if (requestParameters.ruleId !== undefined) {
                queryParameters['ruleId'] = requestParameters.ruleId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/csv/v1/project/{projectId}/sessions/{sessionKey}/{sessionId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"sessionKey"}}`, encodeURIComponent(String(requestParameters.sessionKey))).replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    cSVServiceDeleteCSVSessionTable(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.cSVServiceDeleteCSVSessionTableRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    cSVServiceListCSVSessionsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling cSVServiceListCSVSessions.');
            }
            const queryParameters = {};
            if (requestParameters.ruleId !== undefined) {
                queryParameters['ruleId'] = requestParameters.ruleId;
            }
            if (requestParameters.sessionKey !== undefined) {
                queryParameters['sessionKey'] = requestParameters.sessionKey;
            }
            if (requestParameters.sessionId !== undefined) {
                queryParameters['sessionId'] = requestParameters.sessionId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/csv/v1/project/{projectId}/sessions`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiCSVSessionsFromJSON(jsonValue));
        });
    }
    /**
     */
    cSVServiceListCSVSessions(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.cSVServiceListCSVSessionsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    cSVServiceListRuleCSVSessionsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling cSVServiceListRuleCSVSessions.');
            }
            if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
                throw new runtime.RequiredError('ruleId', 'Required parameter requestParameters.ruleId was null or undefined when calling cSVServiceListRuleCSVSessions.');
            }
            const queryParameters = {};
            if (requestParameters.sessionKey !== undefined) {
                queryParameters['sessionKey'] = requestParameters.sessionKey;
            }
            if (requestParameters.sessionId !== undefined) {
                queryParameters['sessionId'] = requestParameters.sessionId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/csv/v1/project/{projectId}/sessions/{ruleId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiCSVSessionsFromJSON(jsonValue));
        });
    }
    /**
     */
    cSVServiceListRuleCSVSessions(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.cSVServiceListRuleCSVSessionsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    cSVServiceListRulesRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/csv/v1/rules`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiCSVRulesFromJSON(jsonValue));
        });
    }
    /**
     */
    cSVServiceListRules(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.cSVServiceListRulesRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    cSVServiceReadRuleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
                throw new runtime.RequiredError('ruleId', 'Required parameter requestParameters.ruleId was null or undefined when calling cSVServiceReadRule.');
            }
            const queryParameters = {};
            if (requestParameters.payloadCsvUrl !== undefined) {
                queryParameters['payload.csvUrl'] = requestParameters.payloadCsvUrl;
            }
            if (requestParameters.payloadDelimiter !== undefined) {
                queryParameters['payload.delimiter'] = requestParameters.payloadDelimiter;
            }
            if (requestParameters.payloadJoinDelimiter !== undefined) {
                queryParameters['payload.joinDelimiter'] = requestParameters.payloadJoinDelimiter;
            }
            if (requestParameters.payloadGrouping !== undefined) {
                queryParameters['payload.grouping'] = requestParameters.payloadGrouping;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/csv/v1/rules/{ruleId}`.replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiCSVRuleFromJSON(jsonValue));
        });
    }
    /**
     */
    cSVServiceReadRule(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.cSVServiceReadRuleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    cSVServiceSaveRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
                throw new runtime.RequiredError('ruleId', 'Required parameter requestParameters.ruleId was null or undefined when calling cSVServiceSave.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling cSVServiceSave.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling cSVServiceSave.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/csv/v1/rules/{ruleId}/project/{projectId}/save`.replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiCSVSavePayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    cSVServiceSave(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.cSVServiceSaveRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    cSVServiceSaveMultipleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling cSVServiceSaveMultiple.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling cSVServiceSaveMultiple.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/csv/v1/rules/{projectId}/save`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiCSVSaveMultiplePayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    cSVServiceSaveMultiple(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.cSVServiceSaveMultipleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    cSVServiceSuggestMappingRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
                throw new runtime.RequiredError('ruleId', 'Required parameter requestParameters.ruleId was null or undefined when calling cSVServiceSuggestMapping.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling cSVServiceSuggestMapping.');
            }
            const queryParameters = {};
            if (requestParameters.projectId !== undefined) {
                queryParameters['projectId'] = requestParameters.projectId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/csv/v1/rules/{ruleId}/mapping`.replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiCSVMappingPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiCSVMappingResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    cSVServiceSuggestMapping(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.cSVServiceSuggestMappingRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    cSVServiceTestMappingRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
                throw new runtime.RequiredError('ruleId', 'Required parameter requestParameters.ruleId was null or undefined when calling cSVServiceTestMapping.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling cSVServiceTestMapping.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/csv/v1/rules/{ruleId}/test`.replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiCSVTestPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiCSVTestResultFromJSON(jsonValue));
        });
    }
    /**
     */
    cSVServiceTestMapping(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.cSVServiceTestMappingRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
/**
    * @export
    * @enum {string}
    */
export var CSVServiceReadRulePayloadGroupingEnum;
(function (CSVServiceReadRulePayloadGroupingEnum) {
    CSVServiceReadRulePayloadGroupingEnum["CSV_DATE_GROUPING_NO_GROUPING"] = "CSVDateGroupingNoGrouping";
    CSVServiceReadRulePayloadGroupingEnum["CSV_DATE_GROUPING_DAY"] = "CSVDateGroupingDay";
    CSVServiceReadRulePayloadGroupingEnum["CSV_DATE_GROUPING_MONTH"] = "CSVDateGroupingMonth";
})(CSVServiceReadRulePayloadGroupingEnum || (CSVServiceReadRulePayloadGroupingEnum = {}));
