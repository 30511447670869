/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function ApiFormInputFromJSON(json) {
    return ApiFormInputFromJSONTyped(json, false);
}
export function ApiFormInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'target': !exists(json, 'target') ? undefined : json['target'],
        'preventSubmit': !exists(json, 'preventSubmit') ? undefined : json['preventSubmit'],
    };
}
export function ApiFormInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'target': value.target,
        'preventSubmit': value.preventSubmit,
    };
}
