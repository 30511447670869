import { useSharedState } from "./useSharedState";
import { ApiPageviewMetaTagger } from "@incendium/api";
import { useSelectedProject } from "./useSelectedProject";
import { pageviewMetaService } from "Apis";

export const usePageviewMetaTaggers = (locationId?: number) => {
  const { selectedProject } = useSelectedProject();

  const fetch = async () => {
    if (selectedProject) {
      const res =
        await pageviewMetaService.pageviewMetaServiceListPageviewMetaTaggers({
          projectId: selectedProject.id as number,
          locationId: locationId,
        });
      return res.results || [];
    }
    return [];
  };
  const { state, setState, refetch } = useSharedState(
    "pageviewMetaTaggers",
    [],
    fetch
  );

  return {
    pageviewMetaTaggers: state as ApiPageviewMetaTagger[],
    setPageviewMetaTaggers: setState,
    refetch: refetch,
  };
};
