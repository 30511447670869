import {
  ApiChartAttribute,
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiOperator,
  ApiYAxisChartType,
} from "@incendium/api";
import { Grid, Stack, capitalize } from "@mui/material";
import { StatCardTypography } from "Components/UI/StatCard";
import { useAudienceAnalyticsContext } from "Providers/AudienceAnalyticsProvider";
import { useMemo } from "react";
import { AnalyticsCard, AnalyticsStatCard } from "features/analytics";

interface IAudienceNewAndReturningReportPageProps {
  userType:
    | "New Lead"
    | "Returning Lead"
    | "New Customer"
    | "Returning Customer";
}

function AudienceNewAndReturningReportPage({
  userType,
}: IAudienceNewAndReturningReportPageProps) {
  const { currentAudience } = useAudienceAnalyticsContext();

  const attributes = useMemo(() => {
    const filters: ApiChartAttribute[] = [
      {
        key: ApiDimension.DIMENSION_USER_TYPE,
        operator: ApiOperator.CONTAINS,
        value: userType,
      },
    ];
    if (currentAudience && currentAudience !== "") {
      filters.push({
        key: ApiDimension.DIMENSION_LEAD_AUDIENCE,
        value: currentAudience,
      });
    }
    return filters;
  }, [userType, currentAudience]);

  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs={3}>
        <Stack spacing={2}>
          <AnalyticsStatCard
            chart={{
              name: "",
              dimension: [ApiDimension.DIMENSION_CHANNEL],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                },
              ],
              limit: 1,
            }}
            renderBody={(data) => (
              <>
                <StatCardTypography size="small" mb={1}>
                  Most Sessions by Marketing Channel:
                </StatCardTypography>
                <StatCardTypography size="medium">
                  {data[0]?.name}
                </StatCardTypography>
              </>
            )}
          />
          <AnalyticsStatCard
            chart={{
              name: "",
              dimension: [ApiDimension.DIMENSION_CHANNEL],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_EFFECTIVE_SESSION_RATE],
                },
              ],
              limit: 10,
            }}
            renderBody={(data) => (
              <>
                <StatCardTypography size="small" mb={1}>
                  Most Engaging Marketing Channel:
                </StatCardTypography>
                <StatCardTypography size="medium">
                  {data[0]?.name}
                </StatCardTypography>
              </>
            )}
          />
          <AnalyticsStatCard
            minHeight={110}
            chart={{
              name: "",
              dimension: [ApiDimension.DIMENSION_DEVICE],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_LEADS_COUNT],
                },
              ],
              limit: 1,
            }}
            renderBody={(data) => (
              <>
                <StatCardTypography size="small" mb={1}>
                  Most Used Device:
                </StatCardTypography>
                <StatCardTypography size="medium">
                  {capitalize(data[0]?.name || "")}
                </StatCardTypography>
              </>
            )}
          />
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <AnalyticsCard
          chart={{
            name: `${userType} Sessions by Channels`,
            dimension: [ApiDimension.DIMENSION_CHANNEL],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                chart: {
                  [ApiMetric.METRIC_SESSIONS_COUNT]: ApiYAxisChartType.BAR,
                },
              },
              {
                key: "r",
                fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE],
                chart: {
                  [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE]:
                    ApiYAxisChartType.DOT,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <AnalyticsCard
          chart={{
            name: `${userType} Sessions by Channels Composition`,
            dimension: [ApiDimension.DIMENSION_CHANNEL],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.PIE,
            attributes,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
              },
            ],
            displayOptions: {
              noFilters: true,
              showTotals: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={6} sx={{ minHeight: 470 }}>
        <AnalyticsCard
          chart={{
            name: `${userType} Top Landing Pages`,
            dimension: [ApiDimension.DIMENSION_LANDING_PAGE_LOCATION],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes,
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_LEADS_COUNT,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_AVERAGE_PAGEVIEWS_PER_SESSION,
                  ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={6} sx={{ minHeight: 470 }}>
        <AnalyticsCard
          chart={{
            name: `${userType} Top Second Pageviews`,
            dimension: [ApiDimension.DIMENSION_LANDING_NEXT_PAGE_LOCATION],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_LEADS_COUNT],
                chart: {
                  [ApiMetric.METRIC_LEADS_COUNT]: ApiYAxisChartType.BAR,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
              noLegend: true,
              filterDropdowns: [ApiDimension.DIMENSION_LANDING_PAGE_LOCATION],
            },
          }}
        />
      </Grid>
      <Grid item xs={6} sx={{ minHeight: 415 }}>
        <AnalyticsCard
          chart={{
            name: `Top 5 Marketing Channels for ${userType} `,
            dimension: [
              ApiDimension.DIMENSION_SESSION_START_BY_DAY,
              ApiDimension.DIMENSION_CHANNEL,
            ],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>

      <Grid item xs={6} sx={{ minHeight: 415 }}>
        <AnalyticsCard
          chart={{
            name: `${userType} Effective Session Trend`,
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT],
                chart: {
                  [ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT]:
                    ApiYAxisChartType.AREA,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
              noLegend: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={6} sx={{ minHeight: 415 }}>
        <AnalyticsCard
          chart={{
            name: `${userType} Avg. Time Spend on Page Trend`,
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE],
              },
            ],
            displayOptions: {
              noFilters: true,
              noLegend: true,
            },
          }}
        />
      </Grid>

      <Grid item xs={6} sx={{ minHeight: 415 }}>
        <AnalyticsCard
          chart={{
            name: `${userType} Session Conversions`,
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT],
                chart: {
                  [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT]:
                    ApiYAxisChartType.AREA,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
              noLegend: true,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default AudienceNewAndReturningReportPage;
