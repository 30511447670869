import { useTheme, alpha } from "@mui/material";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
} from "recharts";
import { useMemo, useCallback } from "react";
import { useXHeight, useYWidth } from "Hooks/useAnalyticsAxisDimensions";
import { defaultXProps } from "Components/StyledComposedChart/StyledComposedChart";
import { IStyledChartProps } from "Interfaces";

function StyledBarChart({
  data,
  children,
  yAxisProps,
  height,
  xAxisProps,
  yAxisKeys,
  stackId,
  layout,
  aspect,
  noAnimation,
  noReOrder,
}: IStyledChartProps) {
  const theme = useTheme();
  const xHeight = useXHeight(data, {
    ...defaultXProps,
    ...xAxisProps,
  });
  const yWidths = useYWidth(data, yAxisKeys, yAxisProps);
  const chartData = useMemo(() => data.slice(), [data]);

  const renderBars = useCallback(() => {
    if (!data.length) {
      return null;
    }

    const rest = new Set(data.map(Object.keys).flat());
    rest.delete("name");

    return Array.from(rest)
      .sort((a, b) => (noReOrder ? 0 : a.localeCompare(b)))
      .map((key, i) => {
        return (
          <Bar
            key={i}
            dataKey={key}
            fill={theme.palette.charts[i]}
            radius={
              stackId
                ? [0, 0, 0, 0]
                : layout === "vertical"
                ? [0, 5, 5, 0]
                : [5, 5, 0, 0]
            }
            barSize={layout === "vertical" ? 40 : 32}
            stackId={stackId}
            isAnimationActive={!noAnimation}
          />
        );
      });
  }, [data, theme, stackId, layout, noAnimation, noReOrder]);

  return (
    <ResponsiveContainer
      height={height ? height : aspect ? undefined : "100%"}
      width={"100%"}
      aspect={aspect}
    >
      <BarChart data={chartData} layout={layout}>
        <CartesianGrid
          vertical={layout === "vertical"}
          horizontal={layout !== "vertical"}
          strokeDasharray="6"
          stroke={alpha(theme.palette.info.main, 0.5)}
        />

        {children}
        {renderBars()}

        <XAxis
          axisLine={{ stroke: theme.palette.primary.dark, strokeWidth: 2 }}
          dataKey={layout === "vertical" ? undefined : "name"}
          type={layout === "vertical" ? "number" : "category"}
          stroke={theme.palette.text.primary}
          height={xHeight}
          interval={"preserveStart"}
          {...defaultXProps}
          {...xAxisProps}
        />

        <YAxis
          allowDataOverflow
          dataKey={layout === "vertical" ? "name" : undefined}
          type={layout === "vertical" ? "category" : "number"}
          tickCount={6}
          tickLine={false}
          tickMargin={8}
          stroke={theme.palette.text.primary}
          axisLine={{ stroke: theme.palette.primary.dark, strokeWidth: 2 }}
          width={yWidths[0]}
          fontSize={12}
          tickFormatter={
            yAxisProps && yAxisProps[0].tickFormatter
              ? yAxisProps[0].tickFormatter
              : (v) => (typeof v === "number" ? v.toLocaleString() : v)
          }
          {...(yAxisProps ? yAxisProps[0] : {})}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default StyledBarChart;
