import { ApiLeadStatusType, ApiUserActionUser } from "@incendium/api";
import { Clear, FilterAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import SpacedList, { SpacedLinkButton } from "Components/UI/SpacedList";
import { friendlyDateTime } from "Helpers/dates";
import { useLeadStatusRules } from "Hooks/useLeadStatusRules";
import {
  LeadTimelineLIstFilterDialog,
  useLeadAnalyticsData,
  useLeadFilters,
} from "features/leadAnalytics";
import { Pagination, usePagination } from "features/pagination";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useUpdateEffect } from "react-use";

interface ILeadTimelineListProps {
  onClick: (l: ApiUserActionUser, page: number) => void;
  initialPage?: number;
  useParamsPagination?: boolean;
}

function LeadTimelineList({
  onClick,
  initialPage,
  useParamsPagination,
}: ILeadTimelineListProps) {
  // preload available filters,
  useLeadFilters();

  const [open, setOpen] = useState(false);
  const [tmpSearch, setTmpSearch] = useState("");
  const { token, totalPages, page, setPage, setReponseToken, resetPagination } =
    usePagination({
      limit: 20,
      useParams: useParamsPagination,
      initialPage,
    });

  const { chartData, loading, setSearch, setFilters, filters } =
    useLeadAnalyticsData(token);
  const { statusToName } = useLeadStatusRules();

  useEffect(() => {
    setReponseToken(chartData?.nextPageToken || "");
  }, [chartData, setReponseToken]);

  const doSearch = useCallback(() => {
    resetPagination();
    setSearch(tmpSearch);
  }, [tmpSearch, setSearch, resetPagination]);

  useUpdateEffect(() => {
    if (filters) {
      resetPagination();
    }
  }, [filters]);

  useUpdateEffect(() => {
    if (tmpSearch === "") {
      doSearch();
    }
  }, [tmpSearch]);

  return (
    <Stack spacing={2}>
      <Stack direction={"row"} justifyContent="space-between" spacing={2}>
        <Pagination
          totalPages={totalPages}
          page={page}
          onChange={(p) => setPage(p)}
        />
        <LeadTimelineLIstFilterDialog
          open={open}
          setOpen={setOpen}
          filters={filters}
          setFilters={setFilters}
        />
        <Stack direction={"row"} spacing={3}>
          <Stack direction={"row"} spacing={1}>
            <TextField
              size="small"
              label="Search by Email or Key."
              value={tmpSearch}
              onChange={(e) => setTmpSearch(e.target.value)}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  ev.preventDefault();
                  doSearch();
                }
              }}
              InputProps={{
                endAdornment: (
                  <Box mr={1}>
                    <IconButton
                      onClick={() => {
                        setTmpSearch("");
                      }}
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  </Box>
                ),
              }}
            />
            <Button onClick={doSearch}>Search</Button>
          </Stack>
          <Button startIcon={<FilterAlt />} onClick={() => setOpen(!open)}>
            Select Filters
          </Button>
        </Stack>
      </Stack>
      <Box>
        {loading ? (
          <CenterPage calcHeight>
            <CircularProgress />
            <Typography variant="h3" mt={2}>
              Fetching User Data
            </Typography>
          </CenterPage>
        ) : (
          <SpacedList>
            <TableHead>
              <TableRow>
                <TableCell>Key</TableCell>
                <TableCell sx={{ minWidth: 170 }}>Last Session</TableCell>

                <TableCell>Email</TableCell>
                <TableCell>Lead Audiences</TableCell>
                <TableCell>Current Lead Score</TableCell>
                <TableCell>Current Lead Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(chartData?.users || []).map((l) => (
                <TableRow key={l.key}>
                  <TableCell>
                    <SpacedLinkButton onClick={() => onClick(l, page)}>
                      {l.key}
                    </SpacedLinkButton>
                  </TableCell>
                  <TableCell>
                    {friendlyDateTime(l.lastActivity || "")}
                  </TableCell>

                  <TableCell>{l.email}</TableCell>
                  <TableCell> {(l.audiences || []).join(", ")}</TableCell>
                  <TableCell> {l.score}</TableCell>
                  <TableCell>
                    {statusToName(l.status as ApiLeadStatusType)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </SpacedList>
        )}
      </Box>
    </Stack>
  );
}

export default LeadTimelineList;
