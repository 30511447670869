import { Box, Stack, styled } from "@mui/material";
import { HTMLMotionProps } from "framer-motion";

export const PillWrapper = styled(Stack)(({ theme }) => ({
  borderRadius: 18,
  height: 18,
  overflow: "hidden",
  background: theme.palette.charts[3],
}));
PillWrapper.defaultProps = {
  direction: "row",
};
interface IPillInnerProps extends HTMLMotionProps<any> {
  percentage: number;
}

export const PillInner = styled(Box)<IPillInnerProps>(
  ({ theme, percentage }) => ({
    background: theme.palette.charts[1],
    minWidth: `${percentage}%`,
    willChange: "min-width",
    transition: "min-width 0.2s ease-in-out",
  })
);
