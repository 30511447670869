/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiProjectSettingKeyFromJSON, ApiProjectSettingKeyToJSON, } from './ApiProjectSettingKey';
import { ApiProjectTypeFromJSON, ApiProjectTypeToJSON, } from './ApiProjectType';
export function ApiProjectSettingPayloadFromJSON(json) {
    return ApiProjectSettingPayloadFromJSONTyped(json, false);
}
export function ApiProjectSettingPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'key': !exists(json, 'key') ? undefined : ApiProjectSettingKeyFromJSON(json['key']),
        'value': !exists(json, 'value') ? undefined : json['value'],
        'projectTypeUnused': !exists(json, 'projectTypeUnused') ? undefined : ApiProjectTypeFromJSON(json['projectTypeUnused']),
    };
}
export function ApiProjectSettingPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'key': ApiProjectSettingKeyToJSON(value.key),
        'value': value.value,
        'projectTypeUnused': ApiProjectTypeToJSON(value.projectTypeUnused),
    };
}
