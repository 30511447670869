/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiChartAttributeFromJSON, ApiChartAttributeToJSON, } from './ApiChartAttribute';
import { ApiChartResponseFromJSON, ApiChartResponseToJSON, } from './ApiChartResponse';
export function ApiTabChartResponseFromJSON(json) {
    return ApiTabChartResponseFromJSONTyped(json, false);
}
export function ApiTabChartResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'chart': !exists(json, 'chart') ? undefined : ApiChartResponseFromJSON(json['chart']),
        'extraAttributes': !exists(json, 'extraAttributes') ? undefined : (json['extraAttributes'].map(ApiChartAttributeFromJSON)),
        'order': !exists(json, 'order') ? undefined : json['order'],
        'overridingChart': !exists(json, 'overridingChart') ? undefined : ApiChartResponseFromJSON(json['overridingChart']),
        'overridingFields': !exists(json, 'overridingFields') ? undefined : json['overridingFields'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}
export function ApiTabChartResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'chart': ApiChartResponseToJSON(value.chart),
        'extraAttributes': value.extraAttributes === undefined ? undefined : (value.extraAttributes.map(ApiChartAttributeToJSON)),
        'order': value.order,
        'overridingChart': ApiChartResponseToJSON(value.overridingChart),
        'overridingFields': value.overridingFields,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}
