import { ApiChartYAxisKey, ApiDimension, ApiProject } from "@incendium/api";
import { isEmpty } from "Helpers/isEmpty";
import { IChart, IChartData, TChartData } from "Interfaces";
import { IFromToChartOutput } from "Providers/FromToProvider";
import {
  getPrimaryAnalytics,
  remapYAxisBackwardCompatable,
} from "features/analytics/";
import produce from "immer";
import { isEqual } from "lodash";
import { useState, useRef, useCallback } from "react";
import { useDebounce } from "react-use";

// Custom hook
const useChartData = (
  selectedProject: ApiProject | null,
  chart: IChart,
  chartOutput: IFromToChartOutput,
  dontRun?: boolean
) => {
  const [chartData, setChartData] = useState<IChartData | null>(null);
  const [totalsData, setTotalsData] = useState<TChartData>();
  const [dimensions, setDimensions] = useState<ApiDimension[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>(undefined);
  const previousChartOutputRef = useRef({});
  const previousChartRef = useRef({});

  const getQueryRelatedFields = (chart: IChart) => {
    return {
      dimension: chart.dimension,
      orderBy: chart.orderBy,
      limit: chart.limit,
      attributes: chart.attributes,
      ranks: chart.ranks,
      having: chart.having,
      crawler: chart.crawler,
      includeSubdomains: chart.includeSubdomains,
      includeEmpty: chart.includeEmpty,
      withTotals: chart.displayOptions?.showTotals,
      metrics: chart.yAxisKeys
        .map((y) => (y as ApiChartYAxisKey).fields)
        .flat(),
    };
  };

  const loadData = useCallback(
    async (
      projectId: number,
      chart: IChart,
      from?: Date,
      to?: Date,
      lastNDays?: number
    ) => {
      setLoading(true);
      setError(undefined);

      try {
        // currently we are not allowing totals in exports so we modifiy the chart from its display options if totals is set
        const res = await getPrimaryAnalytics(
          projectId,
          { ...chart, withTotals: chart.displayOptions?.showTotals },
          from,
          to,
          lastNDays
        );

        // map Yaxis keys to new metrics
        const yAxisKeys = remapYAxisBackwardCompatable(chart.yAxisKeys);
        setDimensions([...res.dimensions] as ApiDimension[]);
        setChartData({
          data: res.chartData,
          y: yAxisKeys,
        });
        setTotalsData(res.totals);
        setTimeout(() => {
          setLoading(false);
          setError(undefined);
        }, 1);
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    },
    []
  );

  useDebounce(
    () => {
      if (!selectedProject?.id || dontRun) {
        return;
      }

      const queryFields = getQueryRelatedFields(chart);

      if ((queryFields.metrics || []).filter((m) => m !== "").length === 0) {
        setChartData((old) =>
          produce(old, (draft) => {
            if (!draft) {
              return;
            }
            draft!.data = [];
            draft!.y = [];
          })
        );
        return;
      }
      // check if changes are to things that effect query, e.g dimensions, metrics and filters
      if (previousChartRef?.current && !isEmpty(previousChartRef?.current)) {
        if (
          isEqual(previousChartOutputRef?.current, chartOutput) &&
          isEqual(
            getQueryRelatedFields(chart),
            getQueryRelatedFields(previousChartRef?.current as IChart)
          )
        ) {
          setChartData((old) =>
            produce(old, (draft) => {
              if (!draft) {
                return;
              }
              draft.y = remapYAxisBackwardCompatable(chart.yAxisKeys);
            })
          );
          return;
        }
      }

      loadData(
        selectedProject.id,
        chart,
        chartOutput.from,
        chartOutput.to,
        chartOutput.lastNDays
      );

      previousChartOutputRef.current = chartOutput;
      previousChartRef.current = chart;
    },
    100,
    [chart, selectedProject, chartOutput, dontRun, loadData]
  );

  return { chartData, dimensions, loading, error, totalsData };
};

export default useChartData;
