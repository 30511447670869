import { ApiAttributionTag } from "@incendium/api";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useAttributionTags } from "Hooks/useAttributionTags";
import { useMemo } from "react";

interface IAttributionTagSelectorProps {
  size: "small" | "medium" | undefined;
  label: string;
  selectedTags: ApiAttributionTag[];
  onChange: (v: ApiAttributionTag[]) => void;
}

function AttributionTagSelector({
  size,
  label,
  selectedTags,
  onChange,
}: IAttributionTagSelectorProps) {
  const { attributionTags } = useAttributionTags();

  const options = useMemo(() => {
    const selectedIds = selectedTags.map((t) => t.id as number);
    return attributionTags.filter((t) => !selectedIds.includes(t.id as number));
  }, [attributionTags, selectedTags]);

  return (
    <Autocomplete
      multiple
      id="attribution-tags"
      onChange={(_, v) => onChange(v)}
      value={selectedTags}
      disableClearable
      fullWidth
      size={size}
      options={options || []}
      getOptionLabel={(option) => option.name || ""}
      renderTags={(value, getTagProps) => (
        <AnimatePresence>
          {value.map((option, index) => (
            <Chip
              size={size}
              variant="outlined"
              component={motion.div}
              initial={{ scale: 0.2 }}
              exit={{ scale: 0.1 }}
              animate={{ scale: 1 }}
              label={option.name}
              {...getTagProps({ index })}
            />
          ))}
        </AnimatePresence>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          id="outlined-basic"
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}

export default AttributionTagSelector;
