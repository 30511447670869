import { useMemo } from "react";
import { matchPath, RouteProps, useLocation } from "react-router";

export const useMatchPath = <T extends {[K in keyof T]?: string}>(path: string, args: RouteProps = {}) => {
  const location = useLocation();

  return useMemo(() => {
    return matchPath<T>(
      location.pathname,
      {
        path: path ,
        exact: false,
        strict: false,
        ...args
      }
    );
  }, [location.pathname]);
}