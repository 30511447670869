import { Box, Stack } from "@mui/material";
import { StatCardTypography } from "Components/UI/StatCard";
import IncreaseDecrease from "Components/IncreaseDecrease/IncreaseDecrease";
import {
  AnalyticsCard,
  AnalyticsStatCard,
  ChartLegend,
  JobCompareStep,
  formatMetric,
  percentageTopChangeByMetric,
} from "features/analytics";
import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import { AnalyticsSpacing } from "consts";

export interface IJobCompareWrapperProps {}

function JobCompareWrapper({}: IJobCompareWrapperProps) {
  return (
    <>
      <Box sx={{ gridArea: "1 / 1 / 4 / 6", height: "100%" }}>
        <Stack spacing={AnalyticsSpacing} sx={{ height: "100%" }}>
          <Stack direction={"row"} spacing={AnalyticsSpacing}>
            <AnalyticsStatCard
              comparison
              chart={{
                name: "",
                dimension: [],
                template: ApiChartTemplate.SNAPSHOT,
                type: ApiChartType.GRAPH,
                attributes: [],
                yAxisKeys: [
                  {
                    key: "l",
                    fields: [ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT],
                  },
                ],
              }}
              setDirection={(data, compData) =>
                percentageTopChangeByMetric(
                  data,
                  compData || [],
                  ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT
                ) > 0
                  ? "up"
                  : "down"
              }
              renderBody={(data, compData) => {
                const change = percentageTopChangeByMetric(
                  data,
                  compData || [],
                  ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT
                );
                return (
                  <>
                    <StatCardTypography size="small" opactity={0.8}>
                      Total Applications
                    </StatCardTypography>
                    <StatCardTypography size="large">
                      {formatMetric(
                        ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT,
                        Number(
                          data[0][
                            ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT
                          ] || 0
                        )
                      )}
                    </StatCardTypography>
                    <StatCardTypography size="small">
                      <IncreaseDecrease
                        direction={change >= 0 ? "up" : "down"}
                        value={change}
                        size={"large"}
                        fontSize={22}
                      />
                    </StatCardTypography>
                  </>
                );
              }}
            />

            <AnalyticsStatCard
              chart={{
                name: "",
                dimension: [ApiDimension.DIMENSION_JOB_TITLE],
                template: ApiChartTemplate.SNAPSHOT,
                type: ApiChartType.GRAPH,
                attributes: [],
                yAxisKeys: [
                  {
                    key: "l",
                    fields: [ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT],
                  },
                ],
              }}
              renderBody={(data) => (
                <>
                  <StatCardTypography size="small" opactity={0.8}>
                    Star job in
                    <br /> this time period:
                  </StatCardTypography>
                  <StatCardTypography
                    mt={1}
                    size={data[0]?.name.length < 13 ? "large" : "medium"}
                    lines={1}
                  >
                    {data[0]?.name}
                  </StatCardTypography>
                </>
              )}
            />
          </Stack>
          <Box sx={{ flex: 1 }}>
            <AnalyticsCard
              customRightTop={
                <ChartLegend
                  items={["This time Period", "Previous time Period"]}
                />
              }
              chart={{
                name: `Job Page Visits`,
                description: "Dates relate to currently selected time period",
                dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
                template: ApiChartTemplate.TREND,
                type: ApiChartType.GRAPH,
                attributes: [],
                yAxisKeys: [
                  {
                    key: "l",
                    fields: [ApiMetric.METRIC_JOB_PAGEVIEW_COUNT],
                  },
                ],
                comparison: true,
                displayOptions: {
                  noFilters: true,
                  noLegend: true,
                  noAttributionToggle: true,
                },
              }}
            />
          </Box>
        </Stack>
      </Box>
      <Box sx={{ gridArea: "1 / 6/ 4 / 13" }}>
        <JobCompareStep />
      </Box>
    </>
  );
}

export default JobCompareWrapper;
