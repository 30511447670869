import { ApiLeadStatusType, ApiProject } from "@incendium/api";
import { TSalesFlowData } from "Interfaces";
import { IFromToChartOutput } from "Providers/FromToProvider";
import getSalesB2BAnalytics from "features/salesFlow/services/getSalesB2BAnalytics";
import { useCallback, useRef, useState } from "react";
import { useDebounce } from "react-use";

const useSalesFlowData = (
  selectedProject: ApiProject | null,
  chartOutput: IFromToChartOutput,
  leadStatus: ApiLeadStatusType,
  closedFunnel: boolean
) => {
  const [chartData, setChartData] = useState<TSalesFlowData | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>(undefined);
  const previousChartOutputRef = useRef({});

  const loadData = useCallback(
    async (
      projectId: number,
      leadStatus: ApiLeadStatusType,
      closedFunnel: boolean,
      from?: Date,
      to?: Date,
      lastNDays?: number
    ) => {
      setLoading(true);
      setError(undefined);

      try {
        const res = await getSalesB2BAnalytics(
          projectId,
          leadStatus,
          closedFunnel,
          from,
          to,
          lastNDays
        );

        setChartData(res);

        setTimeout(() => {
          setLoading(false);
          setError(undefined);
        }, 1);
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    },
    []
  );

  useDebounce(
    () => {
      if (!selectedProject?.id) {
        return;
      }

      loadData(
        selectedProject.id,
        leadStatus,
        closedFunnel,
        chartOutput.from,
        chartOutput.to,
        chartOutput.lastNDays
      );

      previousChartOutputRef.current = chartOutput;
    },
    100,
    [selectedProject, leadStatus, chartOutput, closedFunnel, loadData]
  );

  return { chartData, loading, error };
};

export default useSalesFlowData;
