/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiPathStatusFromJSON, ApiPathStatusToJSON, } from './ApiPathStatus';
export function ApiReadLanderResponseFromJSON(json) {
    return ApiReadLanderResponseFromJSONTyped(json, false);
}
export function ApiReadLanderResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'componentId': !exists(json, 'componentId') ? undefined : json['componentId'],
        'subdomainId': !exists(json, 'subdomainId') ? undefined : json['subdomainId'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'status': !exists(json, 'status') ? undefined : ApiPathStatusFromJSON(json['status']),
        'noIndex': !exists(json, 'noIndex') ? undefined : json['noIndex'],
        'canonical': !exists(json, 'canonical') ? undefined : json['canonical'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'pending': !exists(json, 'pending') ? undefined : json['pending'],
        'deferIncendiumjs': !exists(json, 'deferIncendiumjs') ? undefined : json['deferIncendiumjs'],
        'screenshotUrl': !exists(json, 'screenshotUrl') ? undefined : json['screenshotUrl'],
    };
}
export function ApiReadLanderResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'componentId': value.componentId,
        'subdomainId': value.subdomainId,
        'path': value.path,
        'url': value.url,
        'status': ApiPathStatusToJSON(value.status),
        'noIndex': value.noIndex,
        'canonical': value.canonical,
        'description': value.description,
        'pending': value.pending,
        'deferIncendiumjs': value.deferIncendiumjs,
        'screenshotUrl': value.screenshotUrl,
    };
}
