import { alpha, Grid, GridSize, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

export const useColsLayoutStyles = makeStyles((theme: Theme) => ({
  body: {
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    // borderTop: `1px solid ${theme.palette.divider}`,
  },
  col: {
    borderRight: `1px solid ${theme.palette.divider}`,

    "&:last-child": {
      borderRight: "none",
    },
    transition: "all 0.3s",
    maxHeight: "100%",
    overflowX: "hidden",
  },
  colHandle: {
    // borderRight: "1px solid black",
    marginRight: theme.spacing(4),
    "&:after": {
      content: "''",
      height: "100%",
      width: 1,
      position: "absolute",
      top: 0,
      background: theme.palette.divider,
    },
  },
  title: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: theme.spacing(9),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  item: {
    transition: "all 0.2s linear",

    "& .moving": {
      background: theme.palette.action.focus,
    },
    "& .MuiListItem-root": {
      "&:not(.MuiListItemIcon-root)": {
        "& > .MuiSvgIcon-root": {
          transition: "all .14s",
          marginRight: theme.spacing(2),
        },
      },
    },
    "& .MuiListItem-secondaryAction": {
      paddingRight: theme.spacing(10),
    },
    "&:hover": {
      background: alpha(theme.palette.secondary.main, 0.8),
      transform: "scale(1.009)",
      boxShadow: "inset 0px 2px 6px rgba(157,173,232, 0.2)",
      "& .MuiSvgIcon-root, & .MuiTypography-root": {
        color: "white",
      },
      "& .MuiListItem-button:hover": {
        background: "transparent",
      },
      "& .MuiListItem-root": {
        "&:not(.MuiListItemIcon-root)": {
          "& > .MuiSvgIcon-root": {
            transform: `translateX(${theme.spacing(1)}) scale(1.1)`,
          },
        },
      },
    },
    "& .active": {
      background: theme.palette.secondary.main,
      borderRight: `3px solid ${theme.palette.primary.dark}`,
      "& .MuiSvgIcon-root, & .MuiTypography-root": {
        color: "white",
      },
    },
    "& .MuiListItemIcon-root": {
      minWidth: theme.spacing(5),
    },
  },
}));

interface IColsColProps extends React.HTMLAttributes<HTMLDivElement> {
  width: GridSize;
}

export const ColsCol = ({ children, width }: IColsColProps) => {
  const { col } = useColsLayoutStyles();
  return (
    <Grid item xs={width} className={col}>
      {children}
    </Grid>
  );
};

export const ColsTitle = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const { title } = useColsLayoutStyles();
  return <div className={title}>{children}</div>;
};

export const ColsLayout = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const { body } = useColsLayoutStyles();
  return (
    <Grid container className={body}>
      {children}
    </Grid>
  );
};

export const ColItem = ({ children }: React.HTMLAttributes<HTMLDivElement>) => {
  const { item } = useColsLayoutStyles();
  return <div className={item}>{children}</div>;
};
