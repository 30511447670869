/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function ApiProjectStatsResponseFromJSON(json) {
    return ApiProjectStatsResponseFromJSONTyped(json, false);
}
export function ApiProjectStatsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'leadCount': !exists(json, 'leadCount') ? undefined : json['leadCount'],
        'sessionCount': !exists(json, 'sessionCount') ? undefined : json['sessionCount'],
        'dataCount': !exists(json, 'dataCount') ? undefined : json['dataCount'],
        'salesCount': !exists(json, 'salesCount') ? undefined : json['salesCount'],
    };
}
export function ApiProjectStatsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'leadCount': value.leadCount,
        'sessionCount': value.sessionCount,
        'dataCount': value.dataCount,
        'salesCount': value.salesCount,
    };
}
