import { useTheme, alpha } from "@mui/material";
import {
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Area,
  ResponsiveContainer,
} from "recharts";
import { useMemo, useCallback } from "react";
import { IStyledChartProps } from "Interfaces";
import { useXHeight, useYWidth } from "Hooks/useAnalyticsAxisDimensions";

function StyledAreaChart({
  data,
  children,
  yAxisProps,
  yAxisKeys,
  xAxisProps,
  height,
  aspect,
  noAnimation,
}: IStyledChartProps) {
  const theme = useTheme();
  const xHeight = useXHeight(data, xAxisProps);
  const yWidths = useYWidth(data, yAxisKeys, yAxisProps);
  const chartData = useMemo(() => data.slice(), [data]);

  const renderAreas = useCallback(() => {
    if (!data.length) {
      return null;
    }
    const o: { [key: string]: number } = {};

    data.forEach((d, i) => {
      const { name, ...rest } = d;
      Object.keys(rest).forEach((k: string) => {
        if (!o.hasOwnProperty(k)) {
          o[k] = 0;
        }
        o[k] = o[k] + (data[i][k] as number);
      });
    });

    return Object.keys(o)
      .sort((a, b) => {
        return o[a] < o[b] ? 1 : o[a] > o[b] ? -1 : 0;
      })
      .map((key, i) => {
        return (
          <Area
            yAxisId={yAxisKeys && `${key}-y`}
            key={i}
            dataKey={key}
            stroke={theme.palette.charts[i]}
            fill={theme.palette.charts[i]}
            fillOpacity={0.6}
            strokeWidth={2}
            dot={false}
            isAnimationActive={!noAnimation}
          />
        );
      });
  }, [data, theme, yAxisKeys]);

  const renderYAxis = useCallback(() => {
    if (!data.length) {
      return null;
    }

    if (!yAxisKeys) {
      return (
        <YAxis
          allowDataOverflow
          tickCount={6}
          tickMargin={8}
          fontSize={12}
          stroke={theme.palette.text.primary}
          axisLine={{ stroke: theme.palette.primary.dark, strokeWidth: 2 }}
          domain={[
            0,
            (dataMax: number) => {
              return dataMax * 1.2;
            },
          ]}
          scale="linear"
          width={yWidths[0]}
          {...(yAxisProps ? yAxisProps[0] : {})}
        />
      );
    }
    return yAxisKeys.map((y, i) => {
      const k = typeof y === "object" ? y.key : y;
      return (
        <YAxis
          key={k}
          orientation={i === 0 ? "left" : "right"}
          yAxisId={`${k}-y`}
          allowDataOverflow
          tickCount={6}
          tickMargin={8}
          fontSize={12}
          stroke={theme.palette.text.primary}
          axisLine={{ stroke: theme.palette.primary.dark, strokeWidth: 2 }}
          domain={[
            0,
            (dataMax: number) => {
              return dataMax * 1.2;
            },
          ]}
          scale="linear"
          width={yWidths[i]}
          {...(yAxisProps ? yAxisProps[i] : {})}
        />
      );
    });
  }, [data, theme, yAxisProps, yAxisKeys, yWidths]);

  return (
    <ResponsiveContainer
      height={height ? height : aspect ? undefined : 500}
      width={"100%"}
      aspect={aspect || 2}
    >
      <AreaChart data={chartData}>
        <CartesianGrid
          vertical={false}
          strokeDasharray="6"
          stroke={alpha(theme.palette.info.main, 0.5)}
        />

        {children}
        {renderAreas()}

        <XAxis
          dataKey="name"
          tickMargin={8}
          stroke={theme.palette.text.primary}
          axisLine={{ stroke: theme.palette.primary.dark, strokeWidth: 2 }}
          angle={-45}
          textAnchor="end"
          fontSize={12}
          interval={"preserveStart"}
          minTickGap={0}
          height={xHeight}
          {...xAxisProps}
        />
        {renderYAxis()}
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default StyledAreaChart;
