import { ApiChartAttribute, ApiDimension, ApiOperator } from "@incendium/api";
import { useChannels } from "Hooks/useChannels";
import { useMemo } from "react";

const usePaidChannelAttribute = () => {
  const { channels, loading } = useChannels();

  return useMemo(() => {
    const paidChannelAttributes = channels
      .filter((c) => c.isPaidChannel)
      .map((pc) => ({
        key: ApiDimension.DIMENSION_CHANNEL,
        operator: ApiOperator.EQUAL,
        value: pc.name,
      })) as ApiChartAttribute[];

    return {
      loading,
      paidChannelAttributes,
    };
  }, [channels, loading]);
};

export default usePaidChannelAttribute;
