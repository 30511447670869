import { Grid, Portal } from "@mui/material";
import AudienceAnalyticsProvider, {
  useAudienceAnalyticsContext,
} from "Providers/AudienceAnalyticsProvider";
import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiYAxisChartType,
} from "@incendium/api";
import Loading from "Components/Loading/Loading";
import { useMemo } from "react";
import { StatCardTypography } from "Components/UI/StatCard";
import {
  AnalyticsCard,
  AnalyticsFilterDropdown,
  AnalyticsStatCard,
  formatMetric,
} from "features/analytics";
import { PageSubAction } from "consts";

function AudienceDeepDivePageInner() {
  const { currentAudience, setCurrentAudience } = useAudienceAnalyticsContext();
  const attributes = useMemo(
    () => [
      {
        key: ApiDimension.DIMENSION_LEAD_AUDIENCE,
        value: currentAudience,
      },
    ],
    [currentAudience]
  );

  return (
    <>
      <Portal container={() => document.getElementById(PageSubAction)}>
        <AnalyticsFilterDropdown
          dimension={ApiDimension.DIMENSION_LEAD_AUDIENCE}
          maxWidth={250}
          disableBlank
          defaultIndex={0}
          onChange={(v) => {
            setCurrentAudience(v);
          }}
        />
      </Portal>
      {!currentAudience ? (
        <Loading />
      ) : (
        <Grid container spacing={2} mb={2}>
          <Grid item xs={3} container spacing={2}>
            <Grid item xs={12}>
              <AnalyticsStatCard
                minHeight={100}
                chart={{
                  name: "",
                  dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.GRAPH,
                  attributes,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_LEADS_COUNT],
                    },
                  ],
                }}
                renderBody={(data) => (
                  <>
                    <StatCardTypography size="medium" mb={1}>
                      {formatMetric(
                        ApiMetric.METRIC_LEADS_COUNT,
                        Number(data[0][ApiMetric.METRIC_LEADS_COUNT])
                      )}
                    </StatCardTypography>
                    <StatCardTypography size="xs">
                      Leads in this Audience
                    </StatCardTypography>
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ height: 395 }}>
              <AnalyticsCard
                chart={{
                  name: "Top 5 Marketing Channels Pageviews & Interactions",
                  dimension: [ApiDimension.DIMENSION_CHANNEL],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.RADAR,
                  attributes,
                  limit: 5,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [
                        ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                        ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
                      ],
                    },
                  ],
                  displayOptions: {
                    noFilters: true,
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <AnalyticsCard
              chart={{
                name: "Marketing Channel Insights",
                dimension: [ApiDimension.DIMENSION_CHANNEL],
                template: ApiChartTemplate.TABLE,
                type: ApiChartType.TABLE,
                attributes,
                yAxisKeys: [
                  {
                    key: "l",
                    fields: [
                      ApiMetric.METRIC_SESSIONS_COUNT,
                      ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                      ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
                    ],
                  },
                ],
                displayOptions: {
                  noFilters: true,
                },
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <AnalyticsCard
              chart={{
                name: `Products Category Insights`,
                dimension: [ApiDimension.DIMENSION_PRODUCT_CATEGORY],
                template: ApiChartTemplate.TABLE,
                type: ApiChartType.TABLE,
                attributes,
                yAxisKeys: [
                  {
                    key: "l",
                    fields: [
                      ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                      ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
                      ApiMetric.METRIC_PAGE_MACRO_CONVERSION_COUNT,
                      ApiMetric.METRIC_PRODUCT_REVENUE,
                    ],
                  },
                ],
                displayOptions: {
                  noFilters: true,
                },
              }}
            />
          </Grid>

          <Grid item xs={3} container spacing={2}>
            <Grid item xs={12}>
              <AnalyticsStatCard
                minHeight={100}
                chart={{
                  name: "",
                  dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.GRAPH,
                  attributes,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_PAGE_VIEWS_COUNT],
                    },
                  ],
                }}
                renderBody={(data) => (
                  <>
                    <StatCardTypography size="medium" mb={1}>
                      {formatMetric(
                        ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                        Number(data[0][ApiMetric.METRIC_PAGE_VIEWS_COUNT])
                      )}
                    </StatCardTypography>
                    <StatCardTypography size="xs">
                      Pageviews by this Audience
                    </StatCardTypography>
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ height: 395 }}>
              <AnalyticsCard
                chart={{
                  name: "Top 5 Product Category Pageviews & Interactions",
                  dimension: [ApiDimension.DIMENSION_PRODUCT_CATEGORY],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.RADAR,
                  attributes,
                  limit: 5,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [
                        ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                        ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
                      ],
                    },
                  ],
                  displayOptions: {
                    noFilters: true,
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={9} container spacing={2}>
            <Grid item xs={12}>
              <AnalyticsCard
                chart={{
                  name: "Audience Primary Metrics",
                  dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
                  template: ApiChartTemplate.TABLE,
                  type: ApiChartType.TABLE,
                  attributes,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [
                        ApiMetric.METRIC_SESSIONS_COUNT,
                        ApiMetric.METRIC_NEW_SESSION_COUNT,
                        ApiMetric.METRIC_RETURNING_SESSION_COUNT,
                        ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                        ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE,
                        ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                        ApiMetric.METRIC_SESSION_MICRO_CONVERSION_RATE,
                        ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                      ],
                    },
                  ],
                  displayOptions: {
                    noFilters: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ minHeight: 380 }}>
              <AnalyticsCard
                chart={{
                  name: "Audience Sessions & Pageviews",
                  dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
                  template: ApiChartTemplate.TREND,
                  type: ApiChartType.GRAPH,
                  attributes,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                    },
                    {
                      key: "react",
                      fields: [ApiMetric.METRIC_PAGE_VIEWS_COUNT],
                    },
                  ],
                  displayOptions: {
                    noFilters: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <AnalyticsCard
                chart={{
                  name: "Audience Avg. Time Spent on Page",
                  dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
                  template: ApiChartTemplate.TREND,
                  type: ApiChartType.GRAPH,
                  attributes,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE],
                      chart: {
                        [ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE]:
                          ApiYAxisChartType.AREA,
                      },
                    },
                  ],
                  displayOptions: {
                    noFilters: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ minHeight: 380 }}>
              <AnalyticsCard
                chart={{
                  name: "Audience Effective Sessions & Pageviews",
                  dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
                  template: ApiChartTemplate.TREND,
                  type: ApiChartType.GRAPH,
                  attributes,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT],
                    },
                    {
                      key: "r",
                      fields: [ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_COUNT],
                    },
                  ],
                  displayOptions: {
                    noFilters: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <AnalyticsCard
                chart={{
                  name: "Audience Interactions & Conversions",
                  dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
                  template: ApiChartTemplate.TREND,
                  type: ApiChartType.GRAPH,
                  attributes,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT],
                      chart: {
                        [ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT]:
                          ApiYAxisChartType.AREA,
                      },
                    },
                    {
                      key: "r",
                      fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT],
                      chart: {
                        [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT]:
                          ApiYAxisChartType.AREA,
                      },
                    },
                  ],
                  displayOptions: {
                    noFilters: true,
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <AnalyticsCard
              chart={{
                name: "Product Primary Metrics",
                dimension: [ApiDimension.DIMENSION_PRODUCT_NAME],
                template: ApiChartTemplate.TABLE,
                type: ApiChartType.TABLE,
                attributes,
                yAxisKeys: [
                  {
                    key: "l",
                    fields: [
                      ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                      ApiMetric.METRIC_PAGE_MACRO_CONVERSION_COUNT,
                      ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
                      ApiMetric.METRIC_PRODUCT_REVENUE,
                    ],
                  },
                ],
                displayOptions: {
                  noFilters: true,
                  rowsPerPage: 15,
                  filterDropdowns: [ApiDimension.DIMENSION_PRODUCT_CATEGORY],
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <AnalyticsCard
              chart={{
                name: "Landing Page Primary Metrics",
                dimension: [ApiDimension.DIMENSION_LANDING_PAGE_LOCATION],
                template: ApiChartTemplate.TABLE,
                type: ApiChartType.TABLE,
                attributes,
                yAxisKeys: [
                  {
                    key: "l",
                    fields: [
                      ApiMetric.METRIC_SESSIONS_COUNT,
                      ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                      ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                      ApiMetric.METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_RATE_DURING_SESSION,
                      ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT,
                      ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
                      ApiMetric.METRIC_LEADS_COUNT,
                      ApiMetric.METRIC_NEW_LEADS_COUNT,
                      ApiMetric.METRIC_RETURNING_LEADS_COUNT,
                      ApiMetric.METRIC_DESKTOP_SESSION_COUNT,
                      ApiMetric.METRIC_MOBILE_SESSION_COUNT,
                    ],
                  },
                ],
                displayOptions: {
                  noFilters: true,
                },
              }}
            />
          </Grid>

          <Grid item xs={9} sx={{ minHeight: 450 }}>
            <AnalyticsCard
              chart={{
                name: "Marketing Channel Primary Metrics",
                dimension: [ApiDimension.DIMENSION_CHANNEL],
                template: ApiChartTemplate.TABLE,
                type: ApiChartType.TABLE,
                attributes,
                yAxisKeys: [
                  {
                    key: "l",
                    fields: [
                      ApiMetric.METRIC_SESSIONS_COUNT,
                      ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                      ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                      ApiMetric.METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_RATE_DURING_SESSION,
                      ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT,
                      ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
                      ApiMetric.METRIC_LEADS_COUNT,
                      ApiMetric.METRIC_NEW_LEADS_COUNT,
                      ApiMetric.METRIC_RETURNING_LEADS_COUNT,
                      ApiMetric.METRIC_DESKTOP_SESSION_COUNT,
                      ApiMetric.METRIC_MOBILE_SESSION_COUNT,
                    ],
                  },
                ],
                displayOptions: {
                  noFilters: true,
                },
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <AnalyticsCard
              chart={{
                name: "No. of Visits Prior Conversion by Channel",
                dimension: [
                  ApiDimension.DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION,
                ],
                template: ApiChartTemplate.TABLE,
                type: ApiChartType.TABLE,
                orderBy: `${ApiDimension.DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION}`,
                attributes,
                yAxisKeys: [
                  {
                    key: "l",
                    fields: [ApiMetric.METRIC_LEADS_COUNT],
                    chart: {
                      [ApiMetric.METRIC_LEADS_COUNT]: ApiYAxisChartType.BAR,
                    },
                  },
                ],
                displayOptions: {
                  noFilters: true,
                  filterDropdowns: [ApiDimension.DIMENSION_CHANNEL],
                },
              }}
            />
          </Grid>
          <Grid item xs={9} sx={{ minHeight: 450 }}>
            <AnalyticsCard
              chart={{
                name: "Marketing Channel ROI & LTV Metrics",
                dimension: [ApiDimension.DIMENSION_CHANNEL],
                template: ApiChartTemplate.TABLE,
                type: ApiChartType.TABLE,
                attributes,
                yAxisKeys: [
                  {
                    key: "l",
                    fields: [
                      ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROI,
                      ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_LTV,
                      ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_MACRO_CONVERSION_COUNT,
                      ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_REVENUE,
                      ApiMetric.METRIC_CPA,
                      ApiMetric.METRIC_CAC,
                      ApiMetric.METRIC_LINEAR_PREDICTED30_DAY_LTV_REVENUE,
                      ApiMetric.METRIC_LINEAR_PREDICTED60_DAY_LTV_REVENUE,
                      ApiMetric.METRIC_LINEAR_PREDICTED90_DAY_LTV_REVENUE,
                    ],
                  },
                ],
                displayOptions: {
                  noFilters: true,
                },
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <AnalyticsCard
              chart={{
                name: "Projected ROAS by Marketing Channels",
                dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
                template: ApiChartTemplate.TREND,
                type: ApiChartType.GRAPH,
                attributes,
                yAxisKeys: [
                  {
                    key: "l",
                    fields: [
                      ApiMetric.METRIC_LINEAR_PREDICTED30_DAY_LTV_REVENUE,
                      ApiMetric.METRIC_LINEAR_PREDICTED60_DAY_LTV_REVENUE,
                      ApiMetric.METRIC_LINEAR_PREDICTED90_DAY_LTV_REVENUE,
                    ],
                  },
                ],
                displayOptions: {
                  noFilters: true,
                  filterDropdowns: [ApiDimension.DIMENSION_CHANNEL],
                },
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

function AudienceDeepDivePage() {
  return (
    <AudienceAnalyticsProvider>
      <AudienceDeepDivePageInner />
    </AudienceAnalyticsProvider>
  );
}

export default AudienceDeepDivePage;
