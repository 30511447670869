import { matchPath, useLocation } from "react-router-dom";
import { IBreadcrumb } from "Interfaces";
import { useMemo } from "react";
import { useClientSelector } from "./useClientSelector";
import { useProjectSelector } from "./useProjectSelector";
import HomeIcon from "@mui/icons-material/Home";
import { useLocationSelector } from "./useLoationSelector";
import { useAudienceSelector } from "./useAudienceSelector";
import { useChannelSelector } from "Hooks/useChannelSelector";
import { useReportSelector } from "Hooks/useSelectedReport";
import { useChartSelector } from "Hooks/useSelectedChart";

const home: IBreadcrumb = { link: "/", text: "home", icon: HomeIcon };
const createBreadCrumb = (
  prev: string,
  pathName: string,
  clientId?: number,
  projectId?: number
) => {
  const arr = pathName.split("/");
  let crumbs: IBreadcrumb[] = [];
  const getLink = (index: number) => {
    const newarr = arr.slice(0, index + 1);
    return prev + newarr.join("/");
  };

  if (arr.length === 2 && arr[0] === "" && arr[1] === "") {
    return [];
  }
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];

    if (item !== "") {
      crumbs.push({ link: getLink(i), text: item, clientId, projectId });
    }
  }
  return crumbs;
};

export const useBreadcrumb = () => {
  const location = useLocation();

  const client = useClientSelector();
  const project = useProjectSelector();
  const selectedLocation = useLocationSelector();
  const selectedAudience = useAudienceSelector();
  const selectedChanel = useChannelSelector();
  const selectedReport = useReportSelector();
  const selectedChart = useChartSelector();

  const isProjectPath = matchPath<{ clientId: string; projectId: string }>(
    location.pathname,
    {
      path: "/clients/:clientId/projects/:projectId",
      exact: false,
      strict: false,
    }
  );
  return useMemo((): IBreadcrumb[] => {
    let crumb = [home];
    if (client) {
      crumb.push({
        text: client?.businessName || "client",
        link: `/clients/${client.id}`,
        clientId: client.id,
      });
    }
    if (isProjectPath && client && project) {
      crumb.push({
        text: project?.name || "Project",
        link: `/clients/${client.id}/projects/${project.id}`,
        clientId: client.id,
        projectId: project.id,
      });
      const splits = location.pathname.split(isProjectPath.url);

      const parts = splits[1].split("/");
      const extras = createBreadCrumb(
        isProjectPath.url,
        parts
          .map((p, i) => {
            if (parts[i - 1] === "chart-library" && selectedChart)
              return selectedChart.name || "unknown";
            if (parts[i - 1] === "reports" && selectedReport)
              return selectedReport.name || "unknown";
            if (parts[i - 1] === "channels" && selectedChanel)
              return selectedChanel.name || "unknown";
            if (parts[i - 1] === "audiences" && selectedAudience)
              return selectedAudience.name || "unknown";
            if (parts[i - 1] === "locations" && selectedLocation)
              return selectedLocation.name || "unknown";
            return p;
          })
          .join("/"),
        client?.id,
        project?.id
      );
      crumb = [...crumb, ...extras];
    }

    return crumb;
  }, [
    isProjectPath,
    client,
    project,
    location,
    selectedLocation,
    selectedAudience,
    selectedChanel,
    selectedReport,
    selectedChart,
  ]);
};
