import { mediaService } from "Apis";
import { useSharedState } from "./useSharedState";
import { ApiMedia, ApiProject } from "@incendium/api";

const fetchMedias = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const medias = await mediaService.mediaServiceListMedia({
      projectId: selectedProject.id as number,
    });
    return medias.results || [];
  }
  return [];
};
export const useMedias = () => {
  const { state, setState, loading, refetch } = useSharedState<
    ApiMedia,
    ApiMedia[]
  >("medias", [], fetchMedias);

  return {
    medias: state,
    setMedias: setState,
    loading,
    refetch,
  };
};
