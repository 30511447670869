import { useCallback, useState } from "react";
import { useMount, useSelectedProject } from "Hooks";
import { ApiComponentUISource, ApiSimpleComponent } from "@incendium/api";
import { useHistory, useParams } from "react-router-dom";
import { CenterPage } from "Components/CenterPage/CenterPage";
import {
  Box,
  Button,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Portal,
  Stack,
  Typography,
} from "@mui/material";
import {
  CodeComponentBuilder,
  GrapesComponentBuilder,
  HeadDrawer,
  MediaDrawer,
} from "features/uiBuilder";
import { useDispatch, useSelector } from "react-redux";
import { leftMenuSelector } from "Selectors/leftMenuSelector";
import { openSecondaryMenu } from "Actions";
import { Edit, Html, PermMedia } from "@mui/icons-material";
import { PageTitle } from "consts";
import {
  ComponentDialog,
  readComponent,
  saveComponent,
} from "features/campaigns";
import { useComponents } from "Hooks/useComponents";
import {
  LeftMenuBlock,
  LeftMenuExtras,
  LeftMenuExtrasInner,
  LeftMenuExtrasTitle,
  LeftMenuToggleButton,
} from "features/leftMenu";
import produce from "immer";

function ComponentPage() {
  const { componentId } = useParams<{ componentId: string }>();
  const { setComponents } = useComponents();
  const { selectedProject } = useSelectedProject();
  const [component, setComponent] = useState<ApiSimpleComponent | undefined>(
    undefined
  );
  const [ready, setReady] = useState(false);
  const [headOpen, setHeadOpen] = useState(false);
  const [mediaOpen, setMediaOpen] = useState(false);
  const [showConflict, setShowConflict] = useState(false);
  const [saveToken, setSaveToken] = useState(null);
  const history = useHistory();
  const { isSecondaryOpen } = useSelector(leftMenuSelector);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  useMount(() => {
    if (!componentId) {
      return;
    }
    dispatch(openSecondaryMenu());

    const load = async () => {
      const res = await readComponent(
        selectedProject?.id as number,
        Number(componentId)
      );

      setComponent(res.component);

      // try a save straight away to see if we can save component or if it's conncted to active campaign
      try {
        await saveComponent(selectedProject?.id as number, {
          ...res.component,
          id: Number(componentId),
        });
        setReady(true);
      } catch (error: any) {
        const t = await error.json();
        if (t.code === 9) {
          setShowConflict(true);
          setSaveToken(t.details[0].message);
        }
      }
    };
    load();
  });

  const onEditSaved = useCallback(
    (component) => {
      setComponent(component);
      setComponents((components) =>
        produce(components, (draft) => {
          if (!component) {
            return;
          }
          const idx = draft.findIndex((d) => d.id === component?.id);
          if (idx >= 0) {
            draft[idx] = component;
          }
        })
      );
    },
    [setComponents]
  );

  if (!ready || !selectedProject) {
    return showConflict ? (
      <CenterPage>
        <Typography variant="subtitle1">
          This component is been used by a 1 or more active campaigns.
        </Typography>
        <Typography variant="body1">
          Editing this component might result in inaccuracies in your analytics.
          Do you wish to proceed?
        </Typography>
        <Stack direction={"row"} spacing={2} my={4}>
          <Button
            color="secondary"
            onClick={() => {
              history.goBack();
            }}
          >
            Go Back
          </Button>
          <Button color="primary" onClick={() => setReady(true)}>
            Proceed
          </Button>
        </Stack>
      </CenterPage>
    ) : (
      <></>
    );
  }

  return (
    <>
      <Portal container={() => document.getElementById("leftMenuOptions")}>
        <LeftMenuExtras>
          <LeftMenuExtrasTitle>
            <LeftMenuToggleButton backToSecondaryText="Back to component tools" />
          </LeftMenuExtrasTitle>
          {isSecondaryOpen && (
            <LeftMenuBlock>
              <LeftMenuExtrasInner>
                <List>
                  <ListItemButton onClick={() => setHeadOpen(true)}>
                    <ListItemAvatar>
                      <Html />
                    </ListItemAvatar>
                    <ListItemText
                      primary="Head"
                      secondary="Populate html head"
                      primaryTypographyProps={{
                        color: "inherit",
                      }}
                    />
                  </ListItemButton>
                  <ListItemButton onClick={() => setMediaOpen(true)}>
                    <ListItemAvatar>
                      <PermMedia />
                    </ListItemAvatar>
                    <ListItemText
                      primary="Images"
                      secondary="Upload / Manage images"
                      primaryTypographyProps={{
                        color: "inherit",
                      }}
                    />
                  </ListItemButton>
                </List>
              </LeftMenuExtrasInner>
            </LeftMenuBlock>
          )}
        </LeftMenuExtras>
      </Portal>
      <Portal container={() => document.getElementById(PageTitle)}>
        <Stack direction={"row"} spacing={2}>
          <Typography variant="h1">{component?.name} Setup</Typography>
          <Box>
            <Button
              onClick={() => setOpen(true)}
              size="extraSmall"
              endIcon={<Edit fontSize="extraSmall" />}
            >
              edit name
            </Button>
          </Box>
        </Stack>
      </Portal>
      {component?.uiSource === ApiComponentUISource.GRAPES ? (
        <GrapesComponentBuilder
          component={component!}
          saveToken={saveToken}
          project={selectedProject}
          onSaved={onEditSaved}
        />
      ) : (
        <CodeComponentBuilder
          component={component!}
          saveToken={saveToken}
          project={selectedProject}
          onSaved={onEditSaved}
        />
      )}
      <MediaDrawer
        project={selectedProject}
        open={mediaOpen}
        setOpen={setMediaOpen}
      />
      <HeadDrawer
        project={selectedProject}
        saveToken={saveToken}
        open={headOpen}
        setOpen={setHeadOpen}
        component={component!}
        setComponent={setComponent}
      />
      {open && (
        <ComponentDialog
          open={open}
          setOpen={setOpen}
          component={component!}
          setComponents={setComponents}
          project={selectedProject!}
          onSaved={onEditSaved}
        />
      )}
    </>
  );
}

export default ComponentPage;
