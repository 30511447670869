import { Alert, Box, Grid, Stack, Typography } from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import GlassCard from "Components/GlassCard/GlassCard";
import { useSelectedProject } from "Hooks";
import Standard from "Assets/icons/Standard-reports-icon.png";
import Custom from "Assets/icons/Custom-reports-icon.png";
import IconChartLib from "Assets/icons/Chart-library-logo.png";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import AccessLevel from "Components/AccessLevel/AccessLevel";
import { ApiACLRole } from "@incendium/api";
import Image from "mui-image";
import withPagetitle from "HoC/withPagetitle";
import { useReports } from "Hooks/useReports";
import { useMemo } from "react";
import ReportPage from "Pages/Analyse/ReportPage";

const SelectionBox = ({
  title,
  path,
  icon,
}: {
  title: string;
  path: string;
  icon: string;
}) => {
  const history = useHistory();
  return (
    <motion.div initial={{ scale: 0.2 }} animate={{ scale: 1 }}>
      <GlassCard
        height={120}
        onClick={() => {
          history.push(`./analyse/${path}`);
        }}
        sx={{
          width: 170,
          borderRadius: "20px",
          overflow: "hidden",
          "&:hover": {
            transform: "scale(1.1)",
            transition: "all 0.2s",
            cursor: "pointer",
            "& .MuiTypography-root": {
              textDecoration: "underline",
            },
          },
        }}
        boxProps={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Image
          height={35}
          color="transparent"
          src={icon}
          alt={title}
          fit="contain"
          duration={100}
        />
        <Typography mt={2} variant="subtitle1">
          {title}
        </Typography>
      </GlassCard>
    </motion.div>
  );
};

function AnalysePageInner() {
  const { selectedProject } = useSelectedProject();

  return (
    <CenterPage>
      <Grid container spacing={6} alignItems="center">
        <Grid item xs={6}>
          <Stack spacing={4}>
            <Box>
              <Typography variant="h2" mb={2}>
                Welcome to{" "}
                <Typography variant="h1" display="inline" component={"span"}>
                  {selectedProject?.name}
                </Typography>
                <br /> Analytics.
              </Typography>

              <Typography variant="body1" color={"secondary"}>
                To get started select one of the options to the right
              </Typography>
            </Box>
            <Alert severity="info">
              Keep in mind that you can pin one of your custom reports to this
              homepage for convenient and quick access.
            </Alert>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={5}>
            <Stack direction="row" spacing={5} justifyContent="center">
              <SelectionBox
                title="Standard reports"
                icon={Standard}
                path="standard-reports/quick-insights"
              />
              <SelectionBox
                title="Custom reports"
                icon={Custom}
                path="reports"
              />
            </Stack>
            <Stack direction="row" spacing={5} justifyContent="center">
              <AccessLevel role={ApiACLRole.ANALYTICS_ADMIN}>
                <SelectionBox
                  title="Chart library"
                  icon={IconChartLib}
                  path="chart-library"
                />
              </AccessLevel>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </CenterPage>
  );
}

const EnhancedComponent = withPagetitle(AnalysePageInner);

function AnalysePage() {
  const { selectedProject } = useSelectedProject();
  const { reports } = useReports();

  const pinnedReport = useMemo(
    () => reports.find((r) => r.pinToHome),
    [reports]
  );

  if (pinnedReport) {
    return <ReportPage disableEditMode passedReportID={pinnedReport.id} />;
  }
  return <EnhancedComponent title={`${selectedProject?.name} Analytics`} />;
}

export default AnalysePage;
