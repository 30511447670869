/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiCityFromJSON, ApiCityToJSON, } from './ApiCity';
import { ApiCountryFromJSON, ApiCountryToJSON, } from './ApiCountry';
import { ApiDataFromJSON, ApiDataToJSON, } from './ApiData';
import { ApiRegionFromJSON, ApiRegionToJSON, } from './ApiRegion';
import { GeoLocationGeoLocationStatusFromJSON, GeoLocationGeoLocationStatusToJSON, } from './GeoLocationGeoLocationStatus';
export function ApiGeoLocationFromJSON(json) {
    return ApiGeoLocationFromJSONTyped(json, false);
}
export function ApiGeoLocationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'hashedIp': !exists(json, 'hashedIp') ? undefined : json['hashedIp'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'data': !exists(json, 'data') ? undefined : (json['data'].map(ApiDataFromJSON)),
        'status': !exists(json, 'status') ? undefined : GeoLocationGeoLocationStatusFromJSON(json['status']),
        'city': !exists(json, 'city') ? undefined : json['city'],
        'countryName': !exists(json, 'countryName') ? undefined : json['countryName'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'countryFlag': !exists(json, 'countryFlag') ? undefined : json['countryFlag'],
        'regionName': !exists(json, 'regionName') ? undefined : json['regionName'],
        'crawlerId': !exists(json, 'crawlerId') ? undefined : json['crawlerId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'country': !exists(json, 'country') ? undefined : ApiCountryFromJSON(json['country']),
        'countryId': !exists(json, 'countryId') ? undefined : json['countryId'],
        'region': !exists(json, 'region') ? undefined : ApiRegionFromJSON(json['region']),
        'regionId': !exists(json, 'regionId') ? undefined : json['regionId'],
        'cityObj': !exists(json, 'cityObj') ? undefined : ApiCityFromJSON(json['cityObj']),
        'cityId': !exists(json, 'cityId') ? undefined : json['cityId'],
    };
}
export function ApiGeoLocationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'hashedIp': value.hashedIp,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'data': value.data === undefined ? undefined : (value.data.map(ApiDataToJSON)),
        'status': GeoLocationGeoLocationStatusToJSON(value.status),
        'city': value.city,
        'countryName': value.countryName,
        'countryCode': value.countryCode,
        'countryFlag': value.countryFlag,
        'regionName': value.regionName,
        'crawlerId': value.crawlerId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'country': ApiCountryToJSON(value.country),
        'countryId': value.countryId,
        'region': ApiRegionToJSON(value.region),
        'regionId': value.regionId,
        'cityObj': ApiCityToJSON(value.cityObj),
        'cityId': value.cityId,
    };
}
