import { alpha, useTheme } from "@mui/material";
import { Glass } from "Components/Glass";
import { forwardRef } from "react";

const FullWidthHeightGlass = forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(({ children, height }, ref) => {
  const theme = useTheme();
  return (
    <Glass
      ref={ref}
      square
      noShadow={true}
      sx={{
        overflow: "hidden!important",
        transform: `translateX(-31px)`,
        width: `calc(100% + ${theme.spacing(8)})`,
        border: "none",
        borderTop: "1px solid  rgba(255, 255, 255, 0.7)",
        boxShadow: `0 -1px 0 0  ${alpha(
          theme.palette.secondary.main,
          0.2
        )}!important`,
      }}
      height={height || "100%"}
    >
      {children}
    </Glass>
  );
});

export default FullWidthHeightGlass;
