import { Box, Grid, Typography } from "@mui/material";
import { IndentDivider } from "Components/IndentDivider/IndentDivider";
import { DataConnectorsList, WebhooksList } from "features/dataConnectors";

function DataConnectionsPage() {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Platforms</Typography>
          <Typography variant="body1" mb={2} lineHeight={1.2}>
            Connect to ad-platforms and then select the accounts you're running
            ads to this site from.
          </Typography>
          <Box>
            <DataConnectorsList type="platform" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <IndentDivider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Webhooks</Typography>
          <Typography variant="body1" mb={2} lineHeight={1.2}>
            Activate these webhooks and copy the URL to track sales data.
          </Typography>
          <Box>
            <WebhooksList />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <IndentDivider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Apps</Typography>
          <Typography variant="body1" mb={2} lineHeight={1.2}>
            Connect to these apps and install automatically for seamless
            integration.
          </Typography>
          <Box>
            <DataConnectorsList type="app" />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default DataConnectionsPage;
