/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiExternalAdGroupFromJSON, ApiExternalAdGroupToJSON, } from './ApiExternalAdGroup';
import { ApiExternalCampaignFromJSON, ApiExternalCampaignToJSON, } from './ApiExternalCampaign';
import { ApiServiceFromJSON, ApiServiceToJSON, } from './ApiService';
import { ApiSyncFromJSON, ApiSyncToJSON, } from './ApiSync';
export function ApiExternalClickFromJSON(json) {
    return ApiExternalClickFromJSONTyped(json, false);
}
export function ApiExternalClickFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clickId': !exists(json, 'clickId') ? undefined : json['clickId'],
        'service': !exists(json, 'service') ? undefined : ApiServiceFromJSON(json['service']),
        'campaign': !exists(json, 'campaign') ? undefined : ApiExternalCampaignFromJSON(json['campaign']),
        'externalCampaignId': !exists(json, 'externalCampaignId') ? undefined : json['externalCampaignId'],
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'counter': !exists(json, 'counter') ? undefined : json['counter'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'sync': !exists(json, 'sync') ? undefined : ApiSyncFromJSON(json['sync']),
        'syncId': !exists(json, 'syncId') ? undefined : json['syncId'],
        'clickHash': !exists(json, 'clickHash') ? undefined : json['clickHash'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'adGroup': !exists(json, 'adGroup') ? undefined : ApiExternalAdGroupFromJSON(json['adGroup']),
        'externalAdGroupId': !exists(json, 'externalAdGroupId') ? undefined : json['externalAdGroupId'],
    };
}
export function ApiExternalClickToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'clickId': value.clickId,
        'service': ApiServiceToJSON(value.service),
        'campaign': ApiExternalCampaignToJSON(value.campaign),
        'externalCampaignId': value.externalCampaignId,
        'keywords': value.keywords,
        'counter': value.counter,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'sync': ApiSyncToJSON(value.sync),
        'syncId': value.syncId,
        'clickHash': value.clickHash,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'adGroup': ApiExternalAdGroupToJSON(value.adGroup),
        'externalAdGroupId': value.externalAdGroupId,
    };
}
