/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiComponentUISource;
(function (ApiComponentUISource) {
    ApiComponentUISource["UNSPECIFIED"] = "ComponentUISource_Unspecified";
    ApiComponentUISource["GRAPES"] = "ComponentUISource_grapes";
    ApiComponentUISource["CODE"] = "ComponentUISource_code";
})(ApiComponentUISource || (ApiComponentUISource = {}));
export function ApiComponentUISourceFromJSON(json) {
    return ApiComponentUISourceFromJSONTyped(json, false);
}
export function ApiComponentUISourceFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiComponentUISourceToJSON(value) {
    return value;
}
