/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiLeadScoreReason;
(function (ApiLeadScoreReason) {
    ApiLeadScoreReason["UNKNOWN"] = "LEAD_SCORE_REASON_UNKNOWN";
    ApiLeadScoreReason["OPE"] = "LEAD_SCORE_REASON_OPE";
    ApiLeadScoreReason["ADJUSTMENT"] = "LEAD_SCORE_REASON_ADJUSTMENT";
    ApiLeadScoreReason["CONSOLIDATION"] = "LEAD_SCORE_REASON_CONSOLIDATION";
})(ApiLeadScoreReason || (ApiLeadScoreReason = {}));
export function ApiLeadScoreReasonFromJSON(json) {
    return ApiLeadScoreReasonFromJSONTyped(json, false);
}
export function ApiLeadScoreReasonFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiLeadScoreReasonToJSON(value) {
    return value;
}
