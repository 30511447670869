import { ApiChartTemplate, ApiChartType, ApiDimension } from "@incendium/api";
import { chartService } from "Apis";
import { IChart } from "Interfaces";

export const saveChart = async (chart: IChart, projectId: number) => {
  const type =
    chart.type && chart.type !== ApiChartType.UNDEFINED
      ? chart.type
      : ApiChartType.TABLE;

  const dimension =
    chart.type === ApiChartType.STAT_CARD
      ? []
      : chart.template === ApiChartTemplate.TREND
      ? [ApiDimension.DIMENSION_SESSION_START_BY_DAY]
      : undefined;
  return chartService.chartServiceCreateChart({
    projectId,
    payload: {
      name: chart.name,
      type,
      template: chart.template,
      dimension,
      includeEmpty:
        chart.includeEmpty !== undefined ? chart.includeEmpty : true,
    },
  });
};
