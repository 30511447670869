import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, styled } from "@mui/material";
import { useCallback } from "react";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  left: 0,
  top: "50%",
  transform: "translateY(-50%)",
  width: 50,
  pointerEvents: "auto",
}));

interface ILeftMenuVisabilityToggleProps {
  to: string;
  toggleVisible: (to: string) => void;
  visible: boolean;
}

function LeftMenuVisabilityToggle({
  to,
  toggleVisible,
  visible,
}: ILeftMenuVisabilityToggleProps) {
  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      toggleVisible(to);
    },
    [toggleVisible, to]
  );

  return (
    <StyledIconButton size="extraSmall" onClick={onClick}>
      {visible ? <Visibility /> : <VisibilityOff />}
    </StyledIconButton>
  );
}

export default LeftMenuVisabilityToggle;
