import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { useTheme, styled, Box, alpha } from "@mui/material";
import { IStyledChartProps } from "Interfaces";
import { useCallback, useMemo } from "react";
import {
  AnalyticsCardCustomTooltip,
  formatMetric,
  getFirstMetricFromY,
} from "features/analytics";
import { truncate } from "Helpers/truncate";

const CustomizedTick = (props: any) => {
  const { x, y, cy, cx, payload, firstMetric } = props;
  const theme = useTheme();
  const value = `${formatMetric(firstMetric, payload.value)}`;
  const width = value.length * 8 + 8;
  return (
    <g transform={`translate(${x},${y})`}>
      <rect
        x={-width / 2}
        y={-8}
        cx={cx}
        cy={cy}
        width={width}
        height={14}
        style={{
          fill: "#fff",
          stroke: theme.palette.text.secondary,
        }}
      ></rect>
      <text
        x={0}
        y={0}
        cx={cx}
        cy={cy}
        fontSize={12}
        transform={props.transform}
        text-anchor="middle"
        dominant-baseline="middle"
      >
        <>{value}</>
      </text>
    </g>
  );
};

const CustomizedAxisTick = (props: any) => {
  const { x, y, payload, index } = props;
  const parts = truncate(
    payload.value || "",
    index === 0 ? 30 : index === 1 || index === 4 ? 5 : 13
  ).split("\n");

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        cx={props.cx}
        cy={props.cy}
        fontSize={props.fontSize}
      >
        {parts.map((v: string, i: number) => (
          <tspan
            textAnchor={props.textAnchor}
            x={0}
            dy={i * 20}
            y={
              props.textAnchor === "middle"
                ? payload.index === 0
                  ? -15
                  : 15
                : 0
            }
          >
            {v}
          </tspan>
        ))}
      </text>
    </g>
  );
};

const RadarContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "100%",
  aspectRatio: "1/1",
  position: "relative",
  overflow: "hidden",
  flex: 1,
  "& .inner": {
    position: "absolute",

    aspectRatio: "1/1",
    background: "rgba(255,255,255,0.1)",
    boxShadow: `-6px 6px 10px ${alpha(theme.palette.primary.dark, 0.15)}`,
    borderRadius: "50%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  "& .inner1": {
    width: "69%",
  },
  "& .inner2": {
    width: "46%",
  },
  "& .inner3": {
    width: "23%",
  },
}));

function StyledRadar({ data, yAxisKeys }: IStyledChartProps) {
  const theme = useTheme();
  const chartData = useMemo(() => data.slice(), [data]);

  const firstMetric = useMemo(() => {
    if (!yAxisKeys) {
      return;
    }
    return getFirstMetricFromY(yAxisKeys);
  }, [yAxisKeys]);

  const renderRadars = useCallback(() => {
    if (!data.length) {
      return null;
    }
    const { name, ...rest } = data[0];

    return Object.keys(rest)
      .filter((k) => !k.toLowerCase().includes("raw"))
      .map((key, i) => (
        <Radar
          key={i}
          dataKey={key}
          stroke={theme.palette.charts[i]}
          fill={theme.palette.charts[i]}
          strokeWidth={2}
          fillOpacity={0.3}
          radius={10}
          dot={{
            stroke: "white",
            fill: theme.palette.charts[i],
            fillOpacity: 1,
            strokeWidth: 2,
            r: 4,
          }}
          activeDot={{
            stroke: theme.palette.charts[i],
            fill: "white",
            strokeWidth: 2,
            r: 4,
          }}
        />
      ));
  }, [data, theme]);
  return (
    <RadarContainer>
      <div className="inner inner2" />
      <div className="inner inner3" />
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart cx="50%" cy="50%" outerRadius="70%" data={chartData}>
          <Tooltip
            cursor={{
              strokeDasharray: "3 3",
              stroke: theme.palette.info.main,
            }}
            content={<AnalyticsCardCustomTooltip />}
          />

          <PolarGrid stroke={theme.palette.text.secondary} />
          <PolarAngleAxis
            dataKey="name"
            axisLine={false}
            stroke={theme.palette.text.primary}
            fontSize={12}
            tickLine={false}
            tick={<CustomizedAxisTick />}
          />

          {renderRadars()}
          <PolarRadiusAxis
            angle={90}
            orientation="middle"
            domain={[0, "dataMax + 15"]}
            tick={<CustomizedTick firstMetric={firstMetric} />}
            fill="white"
          />
        </RadarChart>
      </ResponsiveContainer>
    </RadarContainer>
  );
}

export default StyledRadar;
