import Client from "Assets/icons/client-icon.png";
import { LeftMenuItem } from "features/leftMenu";

function RootMenu() {
  return (
    <>
      <LeftMenuItem imagePath={Client} to="/" text="Clients" active={true} />
    </>
  );
}

export default RootMenu;
