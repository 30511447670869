/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiStatus;
(function (ApiStatus) {
    ApiStatus["STATUS_UNSPECIFIED"] = "STATUS_UNSPECIFIED";
    ApiStatus["ACTIVE"] = "ACTIVE";
    ApiStatus["INACTIVE"] = "INACTIVE";
    ApiStatus["DELETED"] = "DELETED";
})(ApiStatus || (ApiStatus = {}));
export function ApiStatusFromJSON(json) {
    return ApiStatusFromJSONTyped(json, false);
}
export function ApiStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiStatusToJSON(value) {
    return value;
}
