/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiCampaignFromJSON, ApiCampaignToJSON, } from './ApiCampaign';
import { ApiComponentFromJSON, ApiComponentToJSON, } from './ApiComponent';
import { ApiInsertionTypeFromJSON, ApiInsertionTypeToJSON, } from './ApiInsertionType';
import { ApiLocationFromJSON, ApiLocationToJSON, } from './ApiLocation';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
import { ApiUserFromJSON, ApiUserToJSON, } from './ApiUser';
export function ApiPersonalisationFromJSON(json) {
    return ApiPersonalisationFromJSONTyped(json, false);
}
export function ApiPersonalisationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'location': !exists(json, 'location') ? undefined : ApiLocationFromJSON(json['location']),
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'appliedTo': !exists(json, 'appliedTo') ? undefined : json['appliedTo'],
        'insertionType': !exists(json, 'insertionType') ? undefined : ApiInsertionTypeFromJSON(json['insertionType']),
        'campaign': !exists(json, 'campaign') ? undefined : ApiCampaignFromJSON(json['campaign']),
        'campaignId': !exists(json, 'campaignId') ? undefined : json['campaignId'],
        'componentId': !exists(json, 'componentId') ? undefined : json['componentId'],
        'component': !exists(json, 'component') ? undefined : ApiComponentFromJSON(json['component']),
        'ownerId': !exists(json, 'ownerId') ? undefined : json['ownerId'],
        'owner': !exists(json, 'owner') ? undefined : ApiUserFromJSON(json['owner']),
        'lastUserId': !exists(json, 'lastUserId') ? undefined : json['lastUserId'],
        'lastUser': !exists(json, 'lastUser') ? undefined : ApiUserFromJSON(json['lastUser']),
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
        'pending': !exists(json, 'pending') ? undefined : (new Date(json['pending'])),
    };
}
export function ApiPersonalisationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'location': ApiLocationToJSON(value.location),
        'locationId': value.locationId,
        'appliedTo': value.appliedTo,
        'insertionType': ApiInsertionTypeToJSON(value.insertionType),
        'campaign': ApiCampaignToJSON(value.campaign),
        'campaignId': value.campaignId,
        'componentId': value.componentId,
        'component': ApiComponentToJSON(value.component),
        'ownerId': value.ownerId,
        'owner': ApiUserToJSON(value.owner),
        'lastUserId': value.lastUserId,
        'lastUser': ApiUserToJSON(value.lastUser),
        'project': ApiProjectToJSON(value.project),
        'projectId': value.projectId,
        'name': value.name,
        'description': value.description,
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'pending': value.pending === undefined ? undefined : (value.pending.toISOString()),
    };
}
