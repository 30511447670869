/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiChartRankDrectionFromJSON, ApiChartRankDrectionToJSON, } from './ApiChartRankDrection';
import { ApiMetricFromJSON, ApiMetricToJSON, } from './ApiMetric';
export function ApiChartRankFromJSON(json) {
    return ApiChartRankFromJSONTyped(json, false);
}
export function ApiChartRankFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'metric': !exists(json, 'metric') ? undefined : ApiMetricFromJSON(json['metric']),
        'direction': !exists(json, 'direction') ? undefined : ApiChartRankDrectionFromJSON(json['direction']),
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
    };
}
export function ApiChartRankToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'metric': ApiMetricToJSON(value.metric),
        'direction': ApiChartRankDrectionToJSON(value.direction),
        'weight': value.weight,
    };
}
