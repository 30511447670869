/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
import { ApiReportTabFromJSON, ApiReportTabToJSON, } from './ApiReportTab';
export function ApiReportFromJSON(json) {
    return ApiReportFromJSONTyped(json, false);
}
export function ApiReportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'tabs': !exists(json, 'tabs') ? undefined : (json['tabs'].map(ApiReportTabFromJSON)),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'pinToHome': !exists(json, 'pinToHome') ? undefined : json['pinToHome'],
        'favourite': !exists(json, 'favourite') ? undefined : json['favourite'],
    };
}
export function ApiReportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'project': ApiProjectToJSON(value.project),
        'projectId': value.projectId,
        'tabs': value.tabs === undefined ? undefined : (value.tabs.map(ApiReportTabToJSON)),
        'name': value.name,
        'pinToHome': value.pinToHome,
        'favourite': value.favourite,
    };
}
