import { ApiChartAttribute, ApiDimension, ApiOperator } from "@incendium/api";
import { FilterAlt } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import { formatEnumVal } from "Helpers/enumToText";
import { dimensionToName } from "features/analytics/";

interface IAnalyticsCardFiltersTooltipProps {
  title: string;
  filters: ApiChartAttribute[];
}

function AnalyticsCardFiltersTooltip({
  title,
  filters,
}: IAnalyticsCardFiltersTooltipProps) {
  return (
    <Tooltip
      arrow
      title={
        <>
          <Typography variant="subtitle2" color={"inherit"}>
            {title}
          </Typography>

          {(filters || []).map((filter, i) => (
            <Typography key={i} variant="body2" color={"inherit"}>
              <strong>{dimensionToName(filter.key as ApiDimension)}</strong> - [
              {formatEnumVal(filter.operator || ApiOperator.EQUAL)}] -{" "}
              {filter.value}
            </Typography>
          ))}
        </>
      }
    >
      <Box mb={1}>
        <FilterAlt fontSize="small" color="secondary" />
      </Box>
    </Tooltip>
  );
}

export default AnalyticsCardFiltersTooltip;
