import { ApiDimension } from "@incendium/api";
import { Portal } from "@mui/material";
import { ProductsDropdown } from "features/analytics";
import { ProductSinglePage } from "features/analytics";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PageActionPreSlot } from "consts";

function ProductReportPage() {
  const [selectedProductId, setSelectedProductId] = useState<
    number | undefined
  >();
  const { state } = useLocation<{
    selectedProductId: number;
  }>();

  useEffect(() => {
    if (state) {
      setSelectedProductId(state.selectedProductId);
    }
  }, [state]);

  return (
    <>
      <Portal container={() => document.getElementById(PageActionPreSlot)}>
        <ProductsDropdown
          sx={{ minWidth: 200 }}
          fullWidth
          size="small"
          variant="outlined"
          label="Select Product"
          value={selectedProductId}
          onChange={(n, p) => {
            setSelectedProductId(n);
          }}
        />
      </Portal>
      <ProductSinglePage
        selectedProductId={selectedProductId}
        setSelectedProductId={setSelectedProductId}
        dimension={ApiDimension.DIMENSION_PRODUCT_NAME}
      />
    </>
  );
}

export default ProductReportPage;
