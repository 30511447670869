/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiCSVDateGrouping;
(function (ApiCSVDateGrouping) {
    ApiCSVDateGrouping["CSV_DATE_GROUPING_NO_GROUPING"] = "CSVDateGroupingNoGrouping";
    ApiCSVDateGrouping["CSV_DATE_GROUPING_DAY"] = "CSVDateGroupingDay";
    ApiCSVDateGrouping["CSV_DATE_GROUPING_MONTH"] = "CSVDateGroupingMonth";
})(ApiCSVDateGrouping || (ApiCSVDateGrouping = {}));
export function ApiCSVDateGroupingFromJSON(json) {
    return ApiCSVDateGroupingFromJSONTyped(json, false);
}
export function ApiCSVDateGroupingFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiCSVDateGroupingToJSON(value) {
    return value;
}
