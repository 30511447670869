import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { ArrowBack, ExpandMore, Save } from "@mui/icons-material";
import { Alert } from "@mui/material";
import { useSave } from "Hooks/useSave";
import { useSelectedProject } from "Hooks";
import { useSnackbar } from "notistack";
import { useCSVUploaderContext } from "Providers/CSVUploaderProvider";
import { useMemo } from "react";
import { useTheme } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import { formatEnumVal } from "Helpers/enumToText";
import { saveCSV } from "features/csvManager";
import { getPresignedUrlByMediaType } from "services/media";

interface IUploaderStepThreeValidationProps {}

function UploaderStepThreeValidation({}: IUploaderStepThreeValidationProps) {
  const { selectedProject } = useSelectedProject();
  const { save, saving } = useSave();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const {
    step,
    setStep,
    rule,
    testResponse,
    media,
    filteredMapping,
    dateGrouping,
    userValue,
  } = useCSVUploaderContext();

  const columns = useMemo(
    () =>
      (testResponse.headers || []).map((h) => ({
        headerName: formatEnumVal(h),
        field: h,
      })),
    [testResponse]
  );

  const validRows = useMemo(() => {
    const rows = (testResponse.rows || []).filter((row) => {
      const sucCols = (row.columns || []).filter((col) =>
        (rule.availableErrors || []).every((v) => !col.includes(v))
      );
      return sucCols.length > 0;
    });
    return rows.map((r, i) => {
      let o: { [k: string]: string } = {
        id: `${i}`,
      };
      (r.columns || []).forEach((col, idx) => {
        o[columns[idx].field] = col;
      });

      return o;
    });
  }, [testResponse, rule, columns]);

  const errorRows = useMemo(() => {
    const rows = (testResponse.rows || []).filter((row) => {
      const errorCols = (row.columns || []).filter((col) =>
        (rule.availableErrors || []).some((v) => col.includes(v))
      );
      return errorCols.length > 0;
    });
    return rows.map((r, i) => {
      let o: { [k: string]: string } = {
        id: `${i}`,
      };
      (r.columns || []).forEach((col, idx) => {
        o[columns[idx].field] = col;
      });

      return o;
    });
  }, [testResponse, rule, columns]);

  const saveCsv = async () => {
    save(async () => {
      if (!media || !media.files || media.files.length === 0 || !rule.id) {
        return;
      }

      // first we need to fetch a signed url
      const url = await getPresignedUrlByMediaType(media, "text/csv");
      // then use url to get test mapping
      try {
        await saveCSV(
          rule.id,
          url,
          filteredMapping,
          userValue,
          dateGrouping,
          selectedProject?.id as number
        );
        setStep(step + 1);
      } catch (error) {
        enqueueSnackbar("Internal Server Error", {
          variant: "error",
          autoHideDuration: 2000,
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
      }
    });
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      pb={20}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        maxWidth={500}
        my={5}
      >
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" align="center" gutterBottom>
              Validation
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
              The results below are based on an initial check, any invalid rows
              will be skipped.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Alert severity="success">
              {testResponse.successRows} Rows are valid
            </Alert>
            <Accordion key={"errors"}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{ background: alpha(theme.palette.success.main, 0.1) }}
              >
                <Typography>See subset of valid rows?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <DataGrid
                    autoHeight
                    rows={validRows}
                    columns={columns}
                    pageSizeOptions={[5]}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {(testResponse?.errorRows || 0) > 0 && (
            <Grid item xs={12}>
              <Alert severity="error">
                {testResponse.errorRows} Rows are invalid
              </Alert>
              <Accordion key={"errors"}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  sx={{ background: alpha(theme.palette.error.main, 0.1) }}
                >
                  <Typography>See subset of errors?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <DataGrid
                      autoHeight
                      rows={errorRows}
                      columns={columns}
                      pageSizeOptions={[5]}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={saveCsv}
              startIcon={
                saving ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Save />
                )
              }
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              startIcon={<ArrowBack />}
              onClick={() => setStep(step - 1)}
            >
              Back to step {step - 1}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default UploaderStepThreeValidation;
