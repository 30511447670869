import { appearDown } from "Pages/Audience/ClassificationsPage";
import { motion } from "framer-motion";
import clsx from "clsx";
import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { ArrowBack, Delete, Edit, MoveToInbox } from "@mui/icons-material";
import { useState } from "react";
import {
  ApiClassification,
  ApiClassificationGroup,
  ApiClassificationItem,
  ApiProject,
} from "@incendium/api";
import { useSnackbar } from "notistack";
import { useConfirmationContext } from "Providers/ConfirmationProvider";
import produce from "immer";
import { audienceService } from "Apis";
import ClassificationDialog from "Components/ClassificationDialog/ClassificationDialog";
import { useClassifications } from "Hooks";
import { ColItem, ColsTitle } from "Components/ColsLayout/ColsLayout";
import Appear from "Components/Animate/Appear";
import { TypographyHelp } from "Components/UI/TypographyHelp";

function ClassificationsCol({
  selectedProject,
  selectedGroup,
  selectedClassification,
  setSelectedClassification,
  selectedClassifications,
  setSelectedClassificationItem,
  onSaved,
  move,
  setMove,
}: {
  selectedProject: ApiProject;
  selectedGroup: ApiClassificationGroup | null;
  selectedClassification: ApiClassification | null;
  setSelectedClassification: React.Dispatch<
    React.SetStateAction<ApiClassification | null>
  >;
  selectedClassifications: ApiClassification[];
  setSelectedClassificationItem: React.Dispatch<
    React.SetStateAction<ApiClassificationItem | null>
  >;

  onSaved: (classification: ApiClassification) => void;
  move: ApiClassification | null;
  setMove: React.Dispatch<React.SetStateAction<ApiClassification | null>>;
}) {
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [classification, setClassification] =
    useState<ApiClassification | null>(null);
  const { classifications, setClassifications } = useClassifications();

  const selectClassification = (c: ApiClassification) => {
    setSelectedClassification(c);
    setSelectedClassificationItem(null);
  };

  const onClassEdit = (classification: ApiClassification) => {
    setClassification(classification);
    setOpen(true);
  };

  const onClassDelete = (classification: ApiClassification) => {
    openConfirmation({
      title: `Are you sure you want to delete this classification`,
      body: `This action can not be undone`,
      callback: async () => {
        await audienceService.audienceServiceDeleteClassification({
          projectId: selectedProject?.id as number,
          classificationId: classification.id as number,
        });

        setClassifications(
          produce(classifications, (draft) => {
            const idx = classifications.findIndex(
              (ch) => ch.id === classification.id
            );
            if (idx !== -1) draft.splice(idx, 1);
          })
        );

        enqueueSnackbar(`${classification.name} Deleted`, {
          variant: "success",
          autoHideDuration: 2000,
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });

        closeConfirmation();
      },
    });
  };

  return (
    <div>
      <ColsTitle>
        {selectedGroup ? (
          <>
            <motion.span
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{ duration: 0.4, delay: 0.1 }}
            >
              <TypographyHelp
                variant="body1"
                placement="right"
                iconSize="small"
                tooltip={
                  "This is the trait you are wishing to assign to the lead."
                }
                text="Traits"
              />

              <Typography variant="body2" component="span">
                (Group : {selectedGroup.name})
              </Typography>
            </motion.span>
            {!selectedClassification ? (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setOpen(true)}
              >
                Add New Trait
              </Button>
            ) : (
              <Tooltip title="Back To Traits" placement="top">
                <IconButton
                  disabled={!selectClassification}
                  onClick={() => {
                    setSelectedClassification(null);
                    setSelectedClassificationItem(null);
                  }}
                  size="large"
                >
                  <ArrowBack />
                </IconButton>
              </Tooltip>
            )}
          </>
        ) : (
          <Typography variant="body1" color={"textSecondary"}>
            Traits
          </Typography>
        )}
      </ColsTitle>
      {selectedClassifications.length > 0 && (
        <motion.div initial="hidden" animate="show" variants={appearDown}>
          <List disablePadding>
            {selectedClassifications.map((classification) => (
              <motion.div
                key={classification.id}
                variants={{
                  hidden: { opacity: 0 },
                  show: { opacity: 1 },
                }}
              >
                <ColItem>
                  <ListItem
                    className={clsx({
                      active: classification.id === selectedClassification?.id,
                      moving: classification.id === move?.id,
                    })}
                    button
                    onClick={() =>
                      classification.id !== move?.id &&
                      selectClassification(classification)
                    }
                  >
                    <ListItemText
                      primary={classification.name}
                      secondary={
                        classification.id === move?.id
                          ? "(click folder icon to the left to move trait to that folder)"
                          : classification.description || "..."
                      }
                      primaryTypographyProps={{
                        noWrap: true,
                      }}
                      secondaryTypographyProps={{
                        noWrap: true,
                        variant: "caption",
                      }}
                    />
                    {!selectedClassification &&
                      classification.id !== move?.id && (
                        <Appear>
                          <ListItemSecondaryAction>
                            <Tooltip
                              title="Click to move trait to different folder"
                              placement="top"
                            >
                              <IconButton
                                color="secondary"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setMove(move ? null : classification);
                                }}
                                size="large"
                              >
                                <MoveToInbox />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="Edit trait name & description"
                              placement="top"
                            >
                              <IconButton
                                color="secondary"
                                onClick={() => onClassEdit(classification)}
                                size="large"
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete trait" placement="top">
                              <IconButton
                                color="secondary"
                                onClick={() => onClassDelete(classification)}
                                size="large"
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </ListItemSecondaryAction>
                        </Appear>
                      )}
                    {classification.id === move?.id && (
                      <Appear>
                        <Button
                          variant="outlined"
                          onClick={(e) => {
                            e.stopPropagation();
                            setMove(null);
                          }}
                        >
                          Cancel
                        </Button>
                      </Appear>
                    )}
                  </ListItem>
                  <Divider />
                </ColItem>
              </motion.div>
            ))}
          </List>
        </motion.div>
      )}
      {open && (
        <ClassificationDialog
          onClose={() => {
            setClassification(null);
            setSelectedClassification(null);
            setOpen(false);
          }}
          classification={classification}
          group={selectedGroup}
          project={selectedProject}
          onSaved={() => {
            setOpen(false);
            onSaved(classification as ApiClassification);
          }}
        />
      )}
    </div>
  );
}

export default ClassificationsCol;
