import { ApiDomainStatus } from "@incendium/api";

export const getRootDomain = (url: string) => {
  const domain = new URL(url).hostname;
  const domainWithoutSubdomain = domain.replace("www.", "");
  return domainWithoutSubdomain;
};
export const getDomain = (url: string) => {
  return new URL(url).hostname;
};
export const getScheme = (url: string) => {
  return new URL(url).protocol;
};

export const getSubdomainStatusText = (status: ApiDomainStatus) => {
  switch (status) {
    case ApiDomainStatus.DOMAIN_STATUS_NONE:
      return "Creating Certificate";
    case ApiDomainStatus.DOMAIN_STATUS_CERTIFICATE_PENDING:
      return "Certificate awaiting validation";
    case ApiDomainStatus.DOMAIN_STATUS_DOMAIN_PENDING:
      return "Domain awaiting validation";
    case ApiDomainStatus.DOMAIN_STATUS_READY:
      return "Live";
    case ApiDomainStatus.DOMAIN_STATUS_DELETING:
      return "Deleting - We are working on it.";
  }
};
