import { ApiClient } from "@incendium/api";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { addClient, updateClient } from "Actions";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import TimezoneSelect from "Components/TimezoneSelect/TimezoneSelect";
import { saveClient } from "features/clients";
import { useNotification } from "Hooks";
import { useCallback, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch } from "react-redux";

function ClientDialog({
  onClose,
  client,
}: {
  onClose: () => void;
  client?: ApiClient;
}) {
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const [name, setName] = useState(client?.businessName || "");
  const [timezone, setTimezone] = useState(client?.timezone || "");
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = useCallback(async () => {
    setDisabled(true);
    try {
      const res = await saveClient({
        ...client,
        businessName: name,
        timezone,
      });

      if (client?.id) {
        dispatch(updateClient(res));
      } else {
        dispatch(addClient(res));
      }
      showSuccessNotification(`${res.businessName} Saved`);
    } catch (error) {
      showErrorNotification("Internal Server Error");
    } finally {
      setDisabled(false);
      onClose();
    }
  }, [
    client,
    name,
    timezone,
    showSuccessNotification,
    showErrorNotification,
    dispatch,
    onClose,
  ]);

  return (
    <CustomDialog onClose={onClose} fullWidth maxWidth="sm" open>
      <ValidatorForm onSubmit={onSubmit}>
        <DialogTitle>
          {client ? `Update ${client.businessName}` : " Add a Client"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextValidator
              variant="outlined"
              label="Enter Business Name"
              fullWidth
              name="name"
              value={name}
              disabled={disabled}
              validators={["required"]}
              errorMessages={["Business Name is required"]}
              onChange={(e: any) => setName(e.target.value)}
            />
            <br />
            <TimezoneSelect
              value={timezone}
              size="medium"
              fullWidth
              variant="outlined"
              label="Select Client Timezone"
              onChange={(e) => setTimezone(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={disabled} onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button
            disabled={disabled}
            variant="contained"
            color="primary"
            type="submit"
          >
            {client ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </ValidatorForm>
    </CustomDialog>
  );
}

export default ClientDialog;
