import { Box, Stack, Typography, useTheme } from "@mui/material";
import { ChartLegend, getChartColor } from "features/analytics";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import { metricTitles } from "Interfaces/enums";
import { IActiveCell } from "Interfaces";
import { formatPercentage } from "Helpers/percentage";
import { Fragment, useCallback, useState } from "react";
import { ChevronRight } from "@mui/icons-material";
import { ReactComponent as ViewRate } from "Assets/Images/Stats/icon-view_rate.svg";
import { ReactComponent as Vistis } from "Assets/Images/Stats/icon-visits.svg";
import { ReactComponent as Shop } from "Assets/Images/Stats/icon-add-to-bag.svg";
import { ReactComponent as Sale } from "Assets/Images/Stats/icon-sale.svg";
import { ReactComponent as Mail } from "Assets/Images/Stats/icon-email.svg";
import { ReactComponent as Success } from "Assets/Images/Stats/icon-success.svg";
import Loading from "Components/Loading/Loading";
import { formatNumber } from "Helpers/numbers";

const radious = 220;
const width = 18;
const spacing = 30;

type TChartDataValue = {
  name: string | null;
  value: number;
};

interface IMetricBlockProps {
  activeCell: IActiveCell | null;
  icon: React.ComponentType<any>;
  title: metricTitles;
}

const MetricBlock = ({ activeCell, icon: Icon, title }: IMetricBlockProps) => {
  const toRateTxt = (n: metricTitles) => {
    switch (n) {
      case metricTitles.Impressions:
        return "Impression Rate";
      case metricTitles.Click_Throughs:
        return "CTR";
      case metricTitles.Add_to_carts:
        return "Add to Cart";
      case metricTitles.Apply_Click:
        return "Apply Click";
      case metricTitles.Sales:
        return "Completion Rate";
      case metricTitles.Application:
        return "Applied";

      default:
        return n;
    }
  };

  const toIcon = (n: metricTitles) => {
    switch (n) {
      case metricTitles.Impressions:
        return <ViewRate />;
      case metricTitles.Click_Throughs:
        return <Vistis />;
      case metricTitles.Add_to_carts:
        return <Shop />;
      case metricTitles.Apply_Click:
        return <Success />;
      case metricTitles.Sales:
        return <Sale />;
      case metricTitles.Application:
        return <Mail />;
    }
  };

  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        position: "relative",
        pointerEvents: "none",
        height: 110,
        width: 110,
        borderRadius: "50%",
        background: "#FFFFFFB3",
        border: `4px solid ${
          activeCell ? activeCell?.fill : "rgba(0,0,0,0.2)"
        }`,
        "& svg": {
          height: 35,
          width: 35,
        },
        "& svg path:first-of-type": {
          fill: activeCell ? activeCell?.fill : "rgba(0,0,0,0.2)",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          height: 35,
          width: 35,
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        {toIcon(title)}
      </Box>
      <Stack>
        <Typography variant="h6" align="center">
          {formatPercentage(activeCell?.percentages?.get(title) || 0)}
        </Typography>
        <Typography variant="body2" align="center">
          {toRateTxt(title)}
        </Typography>
      </Stack>
    </Stack>
  );
};

const WheelLabel = ({
  label,
  beforePosition,
  afterPosition,
}: {
  label: string;
  beforePosition: any;
  afterPosition: any;
}) => {
  return (
    <>
      <Typography
        mb={3}
        sx={{
          position: "relative",
          "&:before": {
            content: "''",
            height: "1px",
            position: "absolute",
            background: "#151530",
            transform: "rotate(-40deg)",
            ...beforePosition,
          },

          "&:after": {
            content: "''",
            height: "10px",
            width: "10px",
            position: "absolute",
            background: "white",
            border: "1px solid #151530",
            borderRadius: "50%",
            ...afterPosition,
          },
        }}
      >
        {label}
      </Typography>
    </>
  );
};

interface IInteractionWheelProps {
  channels: string[];
  chartData: Map<metricTitles, TChartDataValue[]>;
  ratesData: Map<metricTitles, TChartDataValue[]>;
  displayMetricFn: (s: metricTitles) => string;
  positionMetricFn: (s: metricTitles) => Object[];
  loading?: boolean;
}

function InteractionWheel({
  channels,
  chartData,
  ratesData,
  displayMetricFn,
  positionMetricFn,
  loading,
}: IInteractionWheelProps) {
  const theme = useTheme();
  const [activeCell, setActiveCell] = useState<IActiveCell | null>(null);

  const renderActiveShape = useCallback(
    (idx: number, type: metricTitles) => (props: any) => {
      const {
        percent,
        cx,
        cy,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        name,
        payload,
      } = props;

      return (
        <g>
          {activeCell?.name === name && activeCell?.idx === idx && (
            <>
              <text
                x={cx}
                y={cy}
                dy={-20}
                fill={fill}
                fontWeight="bold"
                fontSize={28}
                textAnchor="middle"
              >
                {formatNumber(payload.value)}
              </text>

              <text
                x={cx}
                y={cy}
                dy={5}
                textAnchor={"middle"}
                fill="#2A2779"
                fontSize={16}
                fontWeight={100}
              >
                {type} from
              </text>
              <text
                x={cx}
                y={cy}
                dy={30}
                textAnchor={"middle"}
                fill={fill}
                fontSize={16}
                fontWeight={600}
              >
                {name}
              </text>
              <text
                x={cx}
                y={cy}
                dy={50}
                textAnchor={"middle"}
                fill={fill}
                fontSize={14}
                fontWeight={600}
              >
                ({formatPercentage(percent * 100)})
              </text>
            </>
          )}
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
          {activeCell?.name === name && activeCell?.idx === idx && (
            <>
              <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius - 1}
                outerRadius={outerRadius + 6}
                fill={fill}
              />
              <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={innerRadius - 6}
                outerRadius={innerRadius + 1}
                fill={fill}
              />
            </>
          )}
        </g>
      );
    },
    [activeCell]
  );

  return (
    <ResponsiveContainer>
      <Stack alignItems={"center"}>
        <Typography variant="h2" mt={1} mb={2}>
          Interactions by Channel
        </Typography>
        <Box sx={{ width: 600 }}>
          <ChartLegend noFlex items={channels || []} noTooltip />
        </Box>

        <Box
          sx={{
            position: "relative",
            height: 630,
            width: 600,
            display: "flex",
            justifyContent: "center",
            transform: `translateY(-${channels.length * 6}px)`,
          }}
        >
          {loading ? (
            <Loading />
          ) : (
            <>
              <PieChart width={522} height={522}>
                {[...chartData.keys()].map((k, i) => {
                  const dim = radious / 2 + (spacing + width) * i;
                  const total = chartData
                    .get(k)
                    ?.reduce((agg, v) => agg + v.value, 0);

                  if (total === 0) {
                    return (
                      <Pie
                        key={`${k}-${i}`}
                        data={[{ value: 1 }]}
                        dataKey="value"
                        innerRadius={dim - width}
                        outerRadius={dim}
                        fill={theme.palette.action.disabled}
                        startAngle={-270}
                        endAngle={-630}
                      />
                    );
                  }

                  return (
                    <Pie
                      key={`${k}-${i}`}
                      data={chartData.get(k)}
                      dataKey="value"
                      innerRadius={dim - width}
                      outerRadius={dim}
                      fill={theme.palette.charts[0]}
                      startAngle={-270}
                      endAngle={-630}
                      activeIndex={activeCell?.cellIndex}
                      activeShape={renderActiveShape(i, k)}
                      onMouseEnter={(e, idx) => {
                        const percentages = new Map<string, number>();

                        ratesData.forEach((v, k) => {
                          percentages.set(
                            k,
                            v.find((d) => d.name === e.name)?.value || 0
                          );
                        });

                        setActiveCell({
                          name: e.name,
                          idx: i,
                          cellIndex: idx,
                          fill: getChartColor(idx, theme.palette.charts, 1),
                          percentages,
                        });
                      }}
                    >
                      {channels.map((channel, index) => {
                        const isActive =
                          activeCell?.idx === i && activeCell.name === channel;
                        const c = getChartColor(
                          index,
                          theme.palette.charts,
                          isActive ? 1 : activeCell?.idx === i ? 0.95 : 0.55
                        );
                        return (
                          <Cell
                            key={`cell-${channel} -${index}`}
                            strokeWidth={isActive ? 10 : 0}
                            stroke={c || theme.palette.secondary.main}
                            fill={c || theme.palette.secondary.main}
                          />
                        );
                      })}
                    </Pie>
                  );
                })}
              </PieChart>

              <Box
                sx={{
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  position: "absolute",
                  display: "flex",
                  justifyContent: "flex-end",
                  pointerEvents: "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  {[...chartData.keys()].reverse().map((ch, i) => {
                    return (
                      <>
                        <WheelLabel
                          label={displayMetricFn(ch)}
                          beforePosition={positionMetricFn(ch)[0]}
                          afterPosition={positionMetricFn(ch)[1]}
                        />
                      </>
                    );
                  })}
                </Box>
              </Box>
              <Stack
                alignItems={"center"}
                sx={{
                  position: "absolute",
                  bottom: 0,
                  pointerEvents: "none",
                }}
              >
                <Stack direction="row" alignItems={"center"}>
                  {[...chartData.keys()].reverse().map((c, i) => (
                    <Fragment key={c}>
                      <MetricBlock
                        activeCell={activeCell}
                        icon={ViewRate}
                        title={c}
                      />
                      {i < chartData.size - 1 && (
                        <ChevronRight
                          style={{
                            color: activeCell
                              ? activeCell?.fill
                              : "rgba(0,0,0,0.2)",
                            fontSize: 30,
                            margin: "0 -4px",
                          }}
                        />
                      )}
                    </Fragment>
                  ))}
                </Stack>
                <Typography
                  mt={1}
                  variant="h6"
                  sx={{ color: activeCell?.fill }}
                >
                  sales flow from {activeCell?.name || "..."}
                </Typography>
              </Stack>
            </>
          )}
        </Box>
      </Stack>
    </ResponsiveContainer>
  );
}

export default InteractionWheel;
