import { ApiConversion, ApiConversionState } from "@incendium/api";
import { locationService } from "Apis";

export const saveConversion = async (
  clientId: number,
  projectId: number,
  locationId: number,
  conversion: ApiConversion
) => {
  const payload = {
    name: conversion.name,
    type: conversion.type,
    reserved: conversion.reserved,
    pushToMeta: conversion.pushToMeta,
  };

  const fn = conversion.id
    ? locationService.locationServiceUpdateConversion({
        clientId,
        projectId,
        locationId,
        conversionId: conversion.id,
        payload,
      })
    : locationService.locationServiceCreateConversion({
        clientId,
        projectId,
        locationId,
        payload,
      });

  const res = await fn;
  return res;
};

export const saveConversionState = async (
  clientId: number,
  projectId: number,
  locationId: number,
  conversionId: number,
  state: ApiConversionState
) => {
  const { id, ...payload } = state;

  const fn = id
    ? locationService.locationServiceUpdateConversionState({
        clientId,
        locationId,
        projectId,
        conversionId,
        conversionStateId: id,
        payload,
      })
    : locationService.locationServiceCreateConversionState({
        clientId,
        locationId,
        projectId,
        conversionId,
        payload,
      });
  const res = await fn;
  return res;
};

export const bulkSaveConversionStates = async (
  clientId: number,
  projectId: number,
  locationId: number,
  conversionId: number,
  states: ApiConversionState[]
) => {
  return await Promise.all(
    states.map((st) =>
      saveConversionState(clientId, projectId, locationId, conversionId, st)
    )
  );
};

export const saveConversionAndStates = async (
  clientId: number,
  projectId: number,
  locationId: number,
  conversion: ApiConversion,
  states: ApiConversionState[]
) => {
  const res = await saveConversion(clientId, projectId, locationId, conversion);
  await bulkSaveConversionStates(
    clientId,
    projectId,
    locationId,
    res.result?.id as number,
    states
  );
};

export const deleteConversion = async (
  clientId: number,
  projectId: number,
  locationId: number,
  conversionId: number
) => {
  await locationService.locationServiceDeleteConversion({
    locationId,
    projectId,
    clientId,
    conversionId,
  });
};

export const listLocationConversions = async (
  clientId: number,
  projectId: number,
  locationId: number
) => {
  return await locationService.locationServiceListConversions({
    locationId,
    projectId,
    clientId,
  });
};

export const deleteConversionState = async (
  clientId: number,
  projectId: number,
  locationId: number,
  conversionId: number,
  conversionStateId: number
) => {
  await locationService.locationServiceDeleteConversionState({
    locationId,
    projectId,
    clientId,
    conversionId,
    conversionStateId,
  });
};
