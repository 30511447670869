/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiLeadFromJSON, ApiLeadToJSON, } from './ApiLead';
import { ApiPredictionTypeFromJSON, ApiPredictionTypeToJSON, } from './ApiPredictionType';
import { ApiRetentionStatusFromJSON, ApiRetentionStatusToJSON, } from './ApiRetentionStatus';
export function ApiRetentionFromJSON(json) {
    return ApiRetentionFromJSONTyped(json, false);
}
export function ApiRetentionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'lead': !exists(json, 'lead') ? undefined : ApiLeadFromJSON(json['lead']),
        'leadId': !exists(json, 'leadId') ? undefined : json['leadId'],
        'type': !exists(json, 'type') ? undefined : ApiPredictionTypeFromJSON(json['type']),
        'status': !exists(json, 'status') ? undefined : ApiRetentionStatusFromJSON(json['status']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
    };
}
export function ApiRetentionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'lead': ApiLeadToJSON(value.lead),
        'leadId': value.leadId,
        'type': ApiPredictionTypeToJSON(value.type),
        'status': ApiRetentionStatusToJSON(value.status),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}
