/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiCSVRuleFromJSON, ApiCSVRuleToJSON, } from './ApiCSVRule';
export function ApiCSVRulesFromJSON(json) {
    return ApiCSVRulesFromJSONTyped(json, false);
}
export function ApiCSVRulesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rules': !exists(json, 'rules') ? undefined : (json['rules'].map(ApiCSVRuleFromJSON)),
    };
}
export function ApiCSVRulesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'rules': value.rules === undefined ? undefined : (value.rules.map(ApiCSVRuleToJSON)),
    };
}
