import { ApiDimension } from "@incendium/api";
import { IStatCardProps } from "Components/UI/StatCard";
import { IChart, TChartData } from "Interfaces";
import { IFromToChartOutput } from "Providers/FromToProvider";
import { ReactNode } from "react";

export type TDimensionMetricMap = {
  originalName: string;
  name: string;
  description: string;
  format?: (val: unknown) => string;
  excludedDimensions?: ApiDimension[]; // list of dimensions this metric is not compatible with (empty means none)
  compatibleDimensions?: ApiDimension[]; // list of dimensions this metric is compatible with (empty means none)
  attribtionType?: AttributionType;
  attribtionMetric?: AttributionMetric;
};

export interface IAnalyticsStatCardProps extends IStatCardProps {
  chart: IChart;
  renderBody: (
    data: TChartData[],
    comp?: TChartData[],
    trendData?: TChartData[]
  ) => ReactNode;
  comparison?: boolean;
  setDirection?: (data: TChartData[], comp?: TChartData[]) => "up" | "down";
  chartOutput?: IFromToChartOutput;
  includeTrendedSpark?: boolean;
  noBg?: boolean;
}

export enum AttributionType {
  FIRST = "first",
  LAST = "last",
  LAST_NON = "lastNon",
  LINEAR = "linear",
  POSITION = "position",
  IMPACTED = "impacted",
}
export enum AttributionMetric {
  REVENUE = "revenue",
  SALES = "sales",
  MACRO = "macro",
  LTV = "ltv",
  ROAS = "roas",
  ROASLTV = "roasltv",
  ROI = "roi",
  ROILTV = "roiltv",
}
