/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiInsertionTypeFromJSON, ApiInsertionTypeToJSON, } from './ApiInsertionType';
export function ApiCreateUpdatePersonalisationPayloadFromJSON(json) {
    return ApiCreateUpdatePersonalisationPayloadFromJSONTyped(json, false);
}
export function ApiCreateUpdatePersonalisationPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'appliedTo': !exists(json, 'appliedTo') ? undefined : json['appliedTo'],
        'insertionType': !exists(json, 'insertionType') ? undefined : ApiInsertionTypeFromJSON(json['insertionType']),
        'campaignId': !exists(json, 'campaignId') ? undefined : json['campaignId'],
        'componentId': !exists(json, 'componentId') ? undefined : json['componentId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}
export function ApiCreateUpdatePersonalisationPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'locationId': value.locationId,
        'appliedTo': value.appliedTo,
        'insertionType': ApiInsertionTypeToJSON(value.insertionType),
        'campaignId': value.campaignId,
        'componentId': value.componentId,
        'name': value.name,
        'description': value.description,
    };
}
