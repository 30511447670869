import { matchPath, useLocation } from "react-router";



export const useClientIdFromUrl = () => {
  const location = useLocation();
  const isClientPath = matchPath<{ clientId: string; }>(location.pathname, {
    path: "/clients/:clientId",
    exact: false,
    strict: false,
  });


  const clientId = isClientPath?.params.clientId;
  return clientId;
};
