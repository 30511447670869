import { ClickAwayListener, MenuItem, Popper, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { formatEnumVal } from "Helpers/enumToText";

const useRuleToolTipClasses = makeStyles((theme: Theme) => {
  return {
    root: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: theme.spacing(0.5),
      position: "relative",
      "&::before": {
        content: "''",
        position: "absolute",
        top: "50%",
        left: 0,
        transform: `translate(-19px, -50%)`,
        display: "block",
        width: 10,
        height: 10,
        borderTop: `10px solid transparent`,
        borderRight: `10px solid ${theme.palette.secondary.main}`,
        borderBottom: `10px solid transparent`,
        borderLeft: `10px solid transparent`,
      },
    },
    menuItem: {
      color: "#fff",
      fontWeight: 300,
      fontSize: theme.spacing(1.5),
      padding: theme.spacing(1),
    },
  };
});

export const IncendiumTooltip = ({
  options,
  onSelect,
  anchorEl,
  onClose,
}: {
  options: string[]
  onSelect: (option: string) => void;
  anchorEl: any;
  onClose: () => void;
}) => {
  const classes = useRuleToolTipClasses();

  return (
    <Popper
      style={{ zIndex: 2000 }}
      placement="right"
      open={!!anchorEl}
      anchorEl={anchorEl}
      
    >
      <ClickAwayListener onClickAway={onClose}>
      <div className={classes.root}>
        {options.map((item) => {
          return (
            <MenuItem
              className={classes.menuItem}
              onClick={() => onSelect(item)}
              key={item}
            >
              {formatEnumVal(item)}
            </MenuItem>
          );
        })}
      </div>
      </ClickAwayListener>
    </Popper>
  );
};