import { ApiAttributionTag, ApiProject } from "@incendium/api";
import { attributionTagService } from "Apis";
import { useSharedState } from "./useSharedState";

const fetchAttributionTags = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const attributionTags =
      await attributionTagService.attributionTagsServiceListAttributionTags({
        projectId: selectedProject.id as number,
      });
    return attributionTags.results || [];
  }
  return [];
};

export const useAttributionTags = () => {
  const { state, setState } = useSharedState(
    "attributionTags",
    [],
    fetchAttributionTags
  );

  return {
    attributionTags: state as ApiAttributionTag[],
    setAttributionTags: setState,
  };
};
