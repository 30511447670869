import { AttributionType } from "features/analytics/types/types";
import { ReactComponent as First } from "Assets/Images/att-first-interaction.svg";
import { ReactComponent as Last } from "Assets/Images/att-last-interaction.svg";
import { ReactComponent as LastNonDirect } from "Assets/Images/att-last-nondirect.svg";
import { ReactComponent as Linear } from "Assets/Images/att-linear.svg";
import { ReactComponent as Position } from "Assets/Images/att-position-based.svg";
import { ReactComponent as Effected } from "Assets/Images/att-effected-interaction.svg";
import { Box, BoxProps } from "@mui/material";
import { useMemo } from "react";

interface IAnalyticsAttributionIconProps extends BoxProps {
  model: AttributionType;
}

function AnalyticsAttributionIcon({
  model,
  ...boxProps
}: IAnalyticsAttributionIconProps) {
  const iconRender = useMemo(() => {
    switch (model) {
      case AttributionType.FIRST:
        return <First />;
      case AttributionType.LAST:
        return <Last />;
      case AttributionType.LAST_NON:
        return <LastNonDirect />;
      case AttributionType.POSITION:
        return <Position />;
      case AttributionType.LINEAR:
        return <Linear />;
      case AttributionType.IMPACTED:
        return <Effected />;

      default:
        return <></>;
    }
  }, [model]);

  return (
    <Box
      {...boxProps}
      sx={{
        ...boxProps.sx,
        display: "flex",
        "& svg": (boxProps?.sx as any)?.width
          ? {
              width: "100%",
              height: "auto",
            }
          : undefined,
      }}
    >
      {iconRender}
    </Box>
  );
}

export default AnalyticsAttributionIcon;
