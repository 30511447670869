/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiAuthResponseFromJSON, ApiCreateUpdateUserRequestToJSON, ApiListUsersResponseFromJSON, ApiRefreshTokenRequestToJSON, ApiUserFromJSON, InlineObject5ToJSON, } from '../models';
/**
 *
 */
export class UserServiceApi extends runtime.BaseAPI {
    /**
     */
    userServiceCreateUserRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.body === null || requestParameters.body === undefined) {
                throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling userServiceCreateUser.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/users`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiCreateUpdateUserRequestToJSON(requestParameters.body),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiUserFromJSON(jsonValue));
        });
    }
    /**
     */
    userServiceCreateUser(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.userServiceCreateUserRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    userServiceDeleteUserRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.userId === null || requestParameters.userId === undefined) {
                throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling userServiceDeleteUser.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    userServiceDeleteUser(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.userServiceDeleteUserRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    userServiceGetAuthUserRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/auth-user`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiUserFromJSON(jsonValue));
        });
    }
    /**
     */
    userServiceGetAuthUser(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.userServiceGetAuthUserRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Users here
     */
    userServiceListUsersRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/users`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListUsersResponseFromJSON(jsonValue));
        });
    }
    /**
     * Users here
     */
    userServiceListUsers(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.userServiceListUsersRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    userServiceReadUserRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.userId === null || requestParameters.userId === undefined) {
                throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling userServiceReadUser.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiUserFromJSON(jsonValue));
        });
    }
    /**
     */
    userServiceReadUser(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.userServiceReadUserRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    userServiceRefreshTokenRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.body === null || requestParameters.body === undefined) {
                throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling userServiceRefreshToken.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/refresh-token`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiRefreshTokenRequestToJSON(requestParameters.body),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiAuthResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    userServiceRefreshToken(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.userServiceRefreshTokenRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    userServiceUpdateUserRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.userId === null || requestParameters.userId === undefined) {
                throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling userServiceUpdateUser.');
            }
            if (requestParameters.body === null || requestParameters.body === undefined) {
                throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling userServiceUpdateUser.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: InlineObject5ToJSON(requestParameters.body),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiUserFromJSON(jsonValue));
        });
    }
    /**
     */
    userServiceUpdateUser(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.userServiceUpdateUserRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
