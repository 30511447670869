import { ApiGetUsersFilter, ApiUserFilterType } from "@incendium/api";
import { analyticsService } from "Apis";

export default async function getAnalytisUsers(
  projectId: number,
  pageSize: number,
  pageToken: string,
  filters?: ApiGetUsersFilter[],
  search?: string
): Promise<any> {
  return analyticsService.analyticsServiceGetUsers({
    projectId,
    body: {
      pageSize,
      pageToken,
      filters,
      search,
    },
  });
}

export const getUserFilterValues = async (
  projectId: number,
  type: ApiUserFilterType
) => {
  const res = await analyticsService.analyticsServiceGetFilterValues({
    projectId,
    type: type as any, // AnalyticsServiceGetFilterValuesTypeEnum,
  });
  return res.result;
};
