import {
  ApiClassification,
  ApiClassificationGroup,
  ApiClassificationPayload,
  ApiProject,
} from "@incendium/api";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { audienceService } from "Apis";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

function ClassificationDialog({
  onClose,
  onSaved,
  classification,
  project,
  group,
}: {
  onClose: () => void;
  onSaved: (c: ApiClassification) => void;
  classification?: ApiClassification | null;
  project: ApiProject;
  group?: ApiClassificationGroup | null;
}) {
  const [name, setName] = useState(classification?.name || "");
  const [description, setDescription] = useState(
    classification?.description || ""
  );
  const [disabled, setDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async () => {
    setDisabled(true);

    let payload: ApiClassificationPayload = { name, description };
    if (group && group.id) {
      payload.classificationGroupId = group.id;
    }

    try {
      const fn =
        classification && classification.id
          ? audienceService.audienceServiceUpdateClassification({
              projectId: project.id as number,
              classificationId: classification.id,
              payload,
            })
          : audienceService.audienceServiceCreateClassification({
              projectId: project.id as number,
              payload,
            });
      const res = await fn;
      enqueueSnackbar(`${res.name} Saves`, {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      onSaved(res);
    } catch (error) {
      enqueueSnackbar("Something Went Wrong", {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    } finally {
      setDisabled(false);
    }
  };

  return (
    <CustomDialog onClose={onClose} fullWidth maxWidth="sm" open>
      <ValidatorForm onSubmit={onSubmit}>
        <DialogTitle>
          {classification
            ? `Edit ${classification.name}`
            : `Add New Classification`}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextValidator
                variant="outlined"
                fullWidth
                name="name"
                autoFocus={true}
                label="Classification Name *"
                value={name}
                disabled={disabled}
                validators={["required"]}
                errorMessages={["Classification Name is required"]}
                onChange={(e: any) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                variant="outlined"
                fullWidth
                name="description"
                label="Classification Description "
                value={description}
                disabled={disabled}
                multiline
                rows={3}
                rowsMax={8}
                validators={["maxStringLength:1000"]}
                errorMessages={[
                  "Classification description can only be 1000 characters long",
                ]}
                onChange={(e: any) => setDescription(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={disabled} onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={disabled}
            variant="contained"
            color="primary"
            type="submit"
          >
            {classification ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </ValidatorForm>
    </CustomDialog>
  );
}

export default ClassificationDialog;
