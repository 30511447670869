import { ApiCurrency } from "@incendium/api";
import { Autocomplete, TextField } from "@mui/material";
import { enumToArray } from "Helpers/enumToText";
import { useMemo } from "react";

interface ICurrencyPickerProps {
  currency: ApiCurrency | undefined;
  setCurrency: React.Dispatch<React.SetStateAction<ApiCurrency | undefined>>;
}

function CurrencyPicker({ currency, setCurrency }: ICurrencyPickerProps) {
  const options = useMemo(() => {
    return enumToArray(ApiCurrency);
  }, []);

  return (
    <Autocomplete
      id="currency-picker"
      freeSolo
      size="small"
      onChange={(e, value) => setCurrency(value as ApiCurrency)}
      value={currency}
      options={options}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
    />
  );
}

export default CurrencyPicker;
