import {
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import produce from "immer";
import { useReportBuilderContext } from "Providers/ReportBuilderProvider";

import { AccordianChartBuilderSidebarBlock } from "./ChartBuilderSidebarBlock";
import { ApiChartType, ApiDimension } from "@incendium/api";
import { hasTrendDimensions, useNonTrendDimensions } from "features/analytics";
import { useCallback } from "react";
import { dimensionOriginalNameToEnum } from "features/analytics/services/dimensionsFormatter";
import { Clear } from "@mui/icons-material";

function ChartBuilderGranularity() {
  const { chart, setChart } = useReportBuilderContext();
  const nonTrendDimensions = useNonTrendDimensions(chart);

  const isChecked = useCallback(
    (comp: ApiDimension) => {
      return (
        (chart.dimension || []).includes(comp) ||
        (chart.dimension || []).map(dimensionOriginalNameToEnum).includes(comp)
      );
    },
    [chart]
  );

  const clear = useCallback(() => {
    setChart((oldChart) =>
      produce(oldChart, (draft) => {
        if (!hasTrendDimensions(draft.dimension as ApiDimension[])) {
          return;
        }
        draft.dimension = [...nonTrendDimensions];
      })
    );
  }, [nonTrendDimensions, setChart]);

  return (
    <AccordianChartBuilderSidebarBlock title="Granularity">
      <Stack direction={"row"} spacing={2}>
        <ToggleButtonGroup
          size="small"
          value={chart.dimension}
          exclusive
          color="secondary"
          onChange={(e, v) => {
            setChart(
              produce(chart, (draft) => {
                if (!draft.dimension || draft.dimension.length === 0) {
                  draft.dimension = [];
                }
                // swicth off trend if click again
                if (v === draft.dimension[0]) {
                  draft.dimension = [...nonTrendDimensions];
                  return;
                }
                draft.dimension = [v, ...nonTrendDimensions];
              })
            );
          }}
        >
          {chart.type === ApiChartType.TABLE && (
            <ToggleButton
              selected={isChecked(ApiDimension.DIMENSION_SESSION_START_DATE)}
              value={ApiDimension.DIMENSION_SESSION_START_DATE}
            >
              Second
            </ToggleButton>
          )}
          <ToggleButton
            selected={isChecked(ApiDimension.DIMENSION_SESSION_START_BY_HOUR)}
            value={ApiDimension.DIMENSION_SESSION_START_BY_HOUR}
          >
            Hour
          </ToggleButton>
          <ToggleButton
            selected={isChecked(ApiDimension.DIMENSION_SESSION_START_BY_DAY)}
            value={ApiDimension.DIMENSION_SESSION_START_BY_DAY}
          >
            Day
          </ToggleButton>
          <ToggleButton
            selected={isChecked(ApiDimension.DIMENSION_SESSION_START_BY_WEEK)}
            value={ApiDimension.DIMENSION_SESSION_START_BY_WEEK}
          >
            Week
          </ToggleButton>
          <ToggleButton
            selected={isChecked(ApiDimension.DIMENSION_SESSION_START_BY_MONTH)}
            value={ApiDimension.DIMENSION_SESSION_START_BY_MONTH}
          >
            Month
          </ToggleButton>
        </ToggleButtonGroup>
        {hasTrendDimensions(chart.dimension as ApiDimension[]) && (
          <IconButton size="small" onClick={clear}>
            <Clear />
          </IconButton>
        )}
      </Stack>
    </AccordianChartBuilderSidebarBlock>
  );
}

export default ChartBuilderGranularity;
