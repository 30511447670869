import { lighten, useTheme } from "@mui/material";
import { FunnelDatum, FunnelPart, ResponsiveFunnel } from "@nivo/funnel";
import { useMemo } from "react";

interface IStyledFunnelProps {
  data: FunnelDatum[];
  onHover?: (part: FunnelPart<FunnelDatum>, show: boolean) => void;
}

function StyledFunnel({ data, onHover }: IStyledFunnelProps) {
  const theme = useTheme();

  const colours = useMemo(() => {
    const size = data.length;
    if (size < 2) {
      return [theme.palette.primary.main];
    }

    return Array.from({ length: size }, (v, i) => {
      return lighten(theme.palette.primary.main, (i / (size - 1)) * 0.3);
    }).reverse();
  }, [theme, data]);

  if (!data || data.length === 0) {
    return <></>;
  }

  return (
    <ResponsiveFunnel
      theme={{
        grid: {
          line: {
            stroke: theme.palette.divider,
          },
        },
      }}
      data={data}
      direction="horizontal"
      shapeBlending={0.45}
      valueFormat=">-.4s"
      colors={colours}
      borderWidth={10}
      enableLabel={false}
      isInteractive={true}
      labelColor={{
        from: "color",
        modifiers: [["brighter", 3]],
      }}
      tooltip={() => <></>}
      beforeSeparatorLength={100}
      beforeSeparatorOffset={10}
      afterSeparatorLength={0}
      afterSeparatorOffset={17}
      currentPartSizeExtension={5}
      currentBorderWidth={20}
      motionConfig="wobbly"
      onMouseEnter={onHover ? (part) => onHover(part, true) : undefined}
      onMouseLeave={onHover ? (part) => onHover(part, false) : undefined}
    />
  );
}

export default StyledFunnel;
