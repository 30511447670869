/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiFingerprintSource;
(function (ApiFingerprintSource) {
    ApiFingerprintSource["UNSPECIFIED"] = "FINGERPRINT_SOURCE_UNSPECIFIED";
    ApiFingerprintSource["FINGERPRINT_JS"] = "FINGERPRINT_SOURCE_FINGERPRINT_JS";
    ApiFingerprintSource["BROPRINT_JS"] = "FINGERPRINT_SOURCE_BROPRINT_JS";
})(ApiFingerprintSource || (ApiFingerprintSource = {}));
export function ApiFingerprintSourceFromJSON(json) {
    return ApiFingerprintSourceFromJSONTyped(json, false);
}
export function ApiFingerprintSourceFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiFingerprintSourceToJSON(value) {
    return value;
}
