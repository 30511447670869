/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiChannelDefault;
(function (ApiChannelDefault) {
    ApiChannelDefault["UNKNOWN"] = "DEFAULT_CHANNEL_UNKNOWN";
    ApiChannelDefault["DIRECT"] = "DEFAULT_CHANNEL_DIRECT";
    ApiChannelDefault["DISPLAY"] = "DEFAULT_CHANNEL_DISPLAY";
    ApiChannelDefault["ORGANIC"] = "DEFAULT_CHANNEL_ORGANIC";
    ApiChannelDefault["SOCIAL"] = "DEFAULT_CHANNEL_SOCIAL";
    ApiChannelDefault["EMAIL"] = "DEFAULT_CHANNEL_EMAIL";
    ApiChannelDefault["AFFILIATES"] = "DEFAULT_CHANNEL_AFFILIATES";
    ApiChannelDefault["REFERRAL"] = "DEFAULT_CHANNEL_REFERRAL";
    ApiChannelDefault["PAID"] = "DEFAULT_CHANNEL_PAID";
    ApiChannelDefault["OTHER_ADS"] = "DEFAULT_CHANNEL_OTHER_ADS";
})(ApiChannelDefault || (ApiChannelDefault = {}));
export function ApiChannelDefaultFromJSON(json) {
    return ApiChannelDefaultFromJSONTyped(json, false);
}
export function ApiChannelDefaultFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiChannelDefaultToJSON(value) {
    return value;
}
