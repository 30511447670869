/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiChartLayout;
(function (ApiChartLayout) {
    ApiChartLayout["HORIZONTAL"] = "horizontal";
    ApiChartLayout["VERTICAL"] = "vertical";
    ApiChartLayout["CENTRIC"] = "centric";
    ApiChartLayout["RADIAL"] = "radial";
})(ApiChartLayout || (ApiChartLayout = {}));
export function ApiChartLayoutFromJSON(json) {
    return ApiChartLayoutFromJSONTyped(json, false);
}
export function ApiChartLayoutFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiChartLayoutToJSON(value) {
    return value;
}
