import { ApiProjectSettingKey } from "@incendium/api";
import { isEmpty } from "Helpers/isEmpty";
import { useSelectedProject } from "Hooks";
import { isEqual } from "lodash";
import { useState } from "react";
import { usePrevious, useUpdateEffect } from "react-use";

const useProjectTypeTransition = () => {
  const { projectSettings } = useSelectedProject();
  const [transitioning, setTransitioning] = useState(false);
  const prevSettings = usePrevious(projectSettings);
  useUpdateEffect(() => {
    if (
      !prevSettings ||
      isEmpty(prevSettings) ||
      isEqual(
        projectSettings[ApiProjectSettingKey.PROJECT_TYPE],
        prevSettings[ApiProjectSettingKey.PROJECT_TYPE]
      )
    ) {
      return;
    }
    setTransitioning(true);
    setTimeout(() => {
      setTransitioning(false);
    }, 500);
  }, [projectSettings[ApiProjectSettingKey.PROJECT_TYPE]]);

  return transitioning;
};

export default useProjectTypeTransition;
