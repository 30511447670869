import { ApiDomainStatus } from "@incendium/api";
import { MenuItem, Stack, Typography } from "@mui/material";
import { SubdomainStatusLabel } from "features/subdomains";
import { useSubdomains } from "Hooks/useSubdomains";
import { SelectValidator } from "react-material-ui-form-validator";

interface ISubdomainSelectorProps {
  subdomainId?: number;
  setSubdomainId: (v: number) => void;
  helperText?: string;
}

function SubdomainSelector({
  subdomainId,
  setSubdomainId,
  helperText,
}: ISubdomainSelectorProps) {
  const { subdomains } = useSubdomains();

  return (
    <SelectValidator
      size={"small"}
      variant="outlined"
      fullWidth
      label="Which Subdomain"
      helperText={helperText}
      name="subdomainId"
      validators={["required"]}
      errorMessages={["Subdomain is required."]}
      value={subdomainId || ""}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setSubdomainId(Number(e.target.value));
      }}
    >
      {(subdomains || [])
        .filter((sd) => sd.status !== ApiDomainStatus.DOMAIN_STATUS_DELETING)
        .map((sd) => (
          <MenuItem key={sd.id} value={sd.id}>
            <Stack
              sx={{ width: "100%" }}
              direction={"row"}
              spacing={1}
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Typography variant="inherit">
                {sd.subdomain} - {sd.name}
              </Typography>
              <SubdomainStatusLabel status={sd.status!} />
            </Stack>
          </MenuItem>
        ))}
    </SelectValidator>
  );
}

export default SubdomainSelector;
