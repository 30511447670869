import {
  ApiAlert,
  ApiConversion,
  ApiProject,
  AlertServiceUpdateAlertRequest,
  ApiConditionGroup,
} from "@incendium/api";
import { Box } from "@mui/material";
import { alertService } from "Apis";
import { useConditionGroupContext } from "Components/ConditionalGroup/ConditionGroupProvider";
import ConditionGroupsFormWrapper from "Components/ConditionalGroup/ConditionGroupsFormWrapper";
import { isEmpty } from "Helpers/isEmpty";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AlertCommsDialog from "./AlertCommsDialog";
import AlertRules from "./AlertRules";
import AlertSidebar from "./AlertSidebar";

interface IAlertFormProps {
  alert: ApiAlert;
  setAlert: React.Dispatch<React.SetStateAction<ApiAlert | undefined>>;
  project: ApiProject;
  conversions: ApiConversion[];
}

function AlertForm({ alert, setAlert, project, conversions }: IAlertFormProps) {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { conditionGroups, setConditionGroups } = useConditionGroupContext();

  useEffect(() => {
    if (!alert || conditionGroups.length) {
      return;
    }
    const groups = alert.conditionGroups || [];
    setConditionGroups(
      groups && groups.length > 0
        ? [...groups]
        : [
            {
              conditions: [{}],
            },
          ]
    );
  }, [alert, setConditionGroups, conditionGroups]);

  const onCancel = () => {
    history.push(
      `/clients/${project.clientId}/projects/${project?.id}/train/alerts`
    );
  };
  const onSubmit = async () => {
    const req: AlertServiceUpdateAlertRequest = {
      projectId: project.id as number,
      alertId: alert.id as number,
      payload: {
        name: alert.name,
        description: alert.description,
        email: alert.email,
        conditionGroups: (conditionGroups as ApiConditionGroup[]).filter(
          (c) => {
            return (
              !isEmpty(c) &&
              (c.conditions || []).filter((co) => !isEmpty(co)).length > 0
            );
          }
        ),
      },
    };
    const res = await alertService.alertServiceUpdateAlert(req);
    enqueueSnackbar(`${res.name} Saved`, {
      variant: "success",
      autoHideDuration: 2000,
      anchorOrigin: { horizontal: "right", vertical: "top" },
    });
  };

  return (
    <Box px={5}>
      <ConditionGroupsFormWrapper
        onSubmit={onSubmit}
        onCancel={onCancel}
        rules={<AlertRules conversions={conversions} />}
      >
        <AlertSidebar alert={alert} setAlert={setAlert} setOpen={setOpen} />
      </ConditionGroupsFormWrapper>
      <AlertCommsDialog
        open={open}
        setOpen={setOpen}
        alert={alert}
        setAlert={setAlert}
      />
    </Box>
  );
}

export default AlertForm;
