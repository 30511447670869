/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiReportTabFromJSON, ApiReportTabToJSON, } from './ApiReportTab';
import { ApiTabChartFromJSON, ApiTabChartToJSON, } from './ApiTabChart';
import { ApiTabElementTypeFromJSON, ApiTabElementTypeToJSON, } from './ApiTabElementType';
export function ApiTabElementFromJSON(json) {
    return ApiTabElementFromJSONTyped(json, false);
}
export function ApiTabElementFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'chart': !exists(json, 'chart') ? undefined : ApiTabChartFromJSON(json['chart']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'x': !exists(json, 'x') ? undefined : json['x'],
        'y': !exists(json, 'y') ? undefined : json['y'],
        'w': !exists(json, 'w') ? undefined : json['w'],
        'h': !exists(json, 'h') ? undefined : json['h'],
        'type': !exists(json, 'type') ? undefined : ApiTabElementTypeFromJSON(json['type']),
        'parentElement': !exists(json, 'parentElement') ? undefined : ApiTabElementFromJSON(json['parentElement']),
        'parentElementId': !exists(json, 'parentElementId') ? undefined : json['parentElementId'],
        'elements': !exists(json, 'elements') ? undefined : (json['elements'].map(ApiTabElementFromJSON)),
        'tab': !exists(json, 'tab') ? undefined : ApiReportTabFromJSON(json['tab']),
        'reportTabId': !exists(json, 'reportTabId') ? undefined : json['reportTabId'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}
export function ApiTabElementToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'chart': ApiTabChartToJSON(value.chart),
        'name': value.name,
        'description': value.description,
        'level': value.level,
        'x': value.x,
        'y': value.y,
        'w': value.w,
        'h': value.h,
        'type': ApiTabElementTypeToJSON(value.type),
        'parentElement': ApiTabElementToJSON(value.parentElement),
        'parentElementId': value.parentElementId,
        'elements': value.elements === undefined ? undefined : (value.elements.map(ApiTabElementToJSON)),
        'tab': ApiReportTabToJSON(value.tab),
        'reportTabId': value.reportTabId,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}
