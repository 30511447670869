import { Check, Settings, WarningAmber } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import GlassCard from "Components/GlassCard/GlassCard";
import { motion } from "framer-motion";
import Image from "mui-image";
import { ReactNode } from "react";

interface ISyncCardUiProps {
  logo: string;
  imageSx?: SxProps<Theme>;
  onConfigureClick: () => void;
  title: string;
  description: string;
  isConnected: boolean;
  isReady: boolean;
  footer?: ReactNode;
}

function SyncCardUi({
  logo,
  imageSx,
  onConfigureClick,
  title,
  description,
  isConnected,
  isReady,
  footer,
}: ISyncCardUiProps) {
  return (
    <GlassCard boxProps={{ p: 2 }} height={252}>
      <Stack spacing={2}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          spacing={4}
          mb={2}
        >
          <motion.div
            initial={{
              opacity: 0,
              x: -50,
            }}
            animate={{
              opacity: 1,
              x: 0,
            }}
            transition={{ delay: 0.6 }}
          >
            <Image
              height={40}
              src={logo}
              duration={50}
              fit={"contain"}
              objectPosition={"left"}
              sx={{ objectPosition: "left", ...imageSx }}
            />
          </motion.div>

          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
            spacing={3}
            component={motion.div}
            initial={{
              opacity: 0,
              scale: 0.9,
            }}
            animate={{
              opacity: 1,
              scale: 1,
            }}
          >
            <Button
              size="small"
              variant="outlined"
              startIcon={<Settings />}
              onClick={onConfigureClick}
            >
              configure
            </Button>
          </Stack>
        </Stack>
        <Divider />
        <Box sx={{ height: 76 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="subtitle1">{title}</Typography>

            {isConnected && (
              <>
                {isReady ? (
                  <Check color={"success"} fontSize={"small"} />
                ) : (
                  <WarningAmber color={"warning"} fontSize={"small"} />
                )}
              </>
            )}
          </Stack>
          <Typography variant="body2" color={"text.secondary"}>
            {description}
          </Typography>
        </Box>
        <Divider />
        {footer}
      </Stack>
    </GlassCard>
  );
}

export default SyncCardUi;
