import { ApiAudience } from "@incendium/api";
import { Autocomplete, OutlinedTextFieldProps, TextField } from "@mui/material";
import { useAudiences } from "Hooks/useAudiences";

interface IAudenceMultiSelectProps
  extends Omit<OutlinedTextFieldProps, "onChange"> {
  values: ApiAudience[];
  disableClearable?: boolean;
  onChange: (audiences?: ApiAudience[]) => void;
}

function AudenceMultiSelect({
  values,
  label,
  helperText,
  variant,
  size,
  disableClearable,
  fullWidth,
  sx,
  onChange,
}: IAudenceMultiSelectProps) {
  const { audiences } = useAudiences();
  return (
    <Autocomplete
      sx={sx}
      size={size}
      fullWidth={fullWidth}
      multiple
      options={audiences}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      value={values}
      onChange={(e, v) => {
        onChange(v);
      }}
      disableClearable={disableClearable}
      getOptionLabel={(o: ApiAudience) =>
        o.name || audiences.find((a) => a.id === o.id)?.name || ""
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          size={size}
          helperText={helperText}
          multiline
        />
      )}
    />
  );
}

export default AudenceMultiSelect;
