import { mediaService } from "Apis";
import { IUploaderProps } from "features/media/types";
import ReactS3Uploader, { S3Response } from "react-s3-uploader";

function Uploader({
  onFinish,
  onError,
  hidden,
  path,
  multiple,
  accept,
  project,
  onProgress,
}: IUploaderProps) {
  const onGetSignedUrl = async (
    file: File,
    callback: (params: S3Response) => void
  ) => {
    const { url } = await mediaService.mediaServiceGetPresignedURL({
      projectId: project.id as number,
      payload: {
        name: file.name,
        path,
      },
    });

    callback({
      filename: file.name,
      fileKey: "",
      signedUrl: url || "",
      publicUrl: url?.split("?").shift() || "",
    });
  };

  return (
    <ReactS3Uploader
      hidden={hidden}
      getSignedUrl={onGetSignedUrl}
      accept={accept}
      multiple={multiple}
      onProgress={onProgress}
      onError={onError}
      onFinish={onFinish}
      uploadRequestHeaders={{}}
      contentDisposition="auto"
    />
  );
}

export default Uploader;
