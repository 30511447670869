import { SyncCardUi } from "features/dataConnectors/";
import DataLayerIcon from "Assets/Images/ThirdParty/layer.png";
import { useCallback, useMemo, useState } from "react";
import { useNotification, useSelectedProject } from "Hooks";
import { ApiProjectSettingKey } from "@incendium/api";
import StyledDrawer, {
  StyledDrawerContainer,
} from "Components/UI/StyledDrawer";
import { Stack } from "@mui/system";
import { FormControlLabel, Switch, Typography } from "@mui/material";
import produce from "immer";
import { useUpdateEffect } from "react-use";
import { saveProjectSetting } from "features/project";
import { useDispatch } from "react-redux";
import { fetchAllClientsAndProjectsThunk } from "Actions";

function DatalayerSycCard() {
  const { projectSettings, setProjectSettings, selectedProject } =
    useSelectedProject();
  const [openConfig, setOpenConfig] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const dispatch = useDispatch();

  const handleChange = useCallback(
    (key: ApiProjectSettingKey, value: string | null) => {
      setProjectSettings((settings) =>
        produce(settings, (draft) => {
          if (!value) {
            draft[key] = undefined;
          } else {
            draft[key] = value;
          }
        })
      );
      setIsDirty(true);
    },
    [setProjectSettings]
  );

  const onClick = useCallback(() => {
    setOpenConfig(true);
  }, []);

  const isInUse = useMemo(() => {
    return (
      projectSettings[ApiProjectSettingKey.USE_DATALAYER_SALES] === "true" ||
      projectSettings[ApiProjectSettingKey.USE_DATALAYER_PAGEVIEWS] ===
        "true" ||
      projectSettings[ApiProjectSettingKey.USE_DATALAYER_CART_EVENTS] ===
        "true" ||
      projectSettings[ApiProjectSettingKey.USE_DATALAYER_CHECKOUT_EVENTS] ===
        "true"
    );
  }, [projectSettings]);

  const onUpdate = useCallback(
    async (key: ApiProjectSettingKey) => {
      if (!isDirty) {
        return;
      }
      try {
        await saveProjectSetting(
          {
            key,
            value: projectSettings[key],
          },
          selectedProject!
        );

        showSuccessNotification(`Datalayer Settings Saved`);
        dispatch(
          fetchAllClientsAndProjectsThunk({
            clientId: selectedProject!.clientId as number,
            projectId: selectedProject!.id as number,
          })
        );
      } catch (error) {
        showErrorNotification(
          `Internal Error saving Datalayer settings, please try again`
        );
      }
    },
    [
      projectSettings,
      selectedProject,
      dispatch,
      showSuccessNotification,
      showErrorNotification,
      isDirty,
    ]
  );

  useUpdateEffect(() => {
    onUpdate(ApiProjectSettingKey.USE_DATALAYER_PAGEVIEWS);
  }, [projectSettings[ApiProjectSettingKey.USE_DATALAYER_PAGEVIEWS]]);
  useUpdateEffect(() => {
    onUpdate(ApiProjectSettingKey.USE_DATALAYER_SALES);
  }, [projectSettings[ApiProjectSettingKey.USE_DATALAYER_SALES]]);
  useUpdateEffect(() => {
    onUpdate(ApiProjectSettingKey.USE_DATALAYER_CART_EVENTS);
  }, [projectSettings[ApiProjectSettingKey.USE_DATALAYER_CART_EVENTS]]);
  useUpdateEffect(() => {
    onUpdate(ApiProjectSettingKey.USE_DATALAYER_CHECKOUT_EVENTS);
  }, [projectSettings[ApiProjectSettingKey.USE_DATALAYER_CHECKOUT_EVENTS]]);

  return (
    <>
      <SyncCardUi
        logo={DataLayerIcon}
        onConfigureClick={onClick}
        title={"DataLayer"}
        description={
          "Incendium can use datalayer for tracking things such as pageviews and sales, click confgire to turn these features on."
        }
        isConnected={isInUse}
        isReady={isInUse}
      />
      <StyledDrawer open={openConfig} onClose={() => setOpenConfig(false)}>
        <StyledDrawerContainer sx={{ width: 700 }}>
          <Typography variant="subtitle1">
            Enable / Disable DataLayer Options
          </Typography>
          <Typography variant="body2">
            Turning on these features allows Incendium to track specific types
            of data using the corresponding DataLayer events.
          </Typography>
          <Stack mt={2} spacing={2}>
            <Stack>
              <FormControlLabel
                componentsProps={{
                  typography: {
                    variant: "body1",
                  },
                }}
                control={
                  <Switch
                    checked={
                      projectSettings[
                        ApiProjectSettingKey.USE_DATALAYER_PAGEVIEWS
                      ] === "true"
                    }
                    onChange={(e, checked) => {
                      handleChange(
                        ApiProjectSettingKey.USE_DATALAYER_PAGEVIEWS,
                        checked ? "true" : "false"
                      );
                    }}
                  />
                }
                label="Use DataLayer to track Pageviews"
              />
              <Typography variant="body2">
                This will set Incendium to use pre-existing Datalayer
                implementations to track pageviews, it shall wtach for both
                pageview and gtm.historyChange-v2 events.
              </Typography>
            </Stack>
            <Stack>
              <FormControlLabel
                componentsProps={{
                  typography: {
                    variant: "body1",
                  },
                }}
                control={
                  <Switch
                    checked={
                      projectSettings[
                        ApiProjectSettingKey.USE_DATALAYER_SALES
                      ] === "true"
                    }
                    onChange={(e, checked) => {
                      handleChange(
                        ApiProjectSettingKey.USE_DATALAYER_SALES,
                        checked ? "true" : "false"
                      );
                    }}
                  />
                }
                label="Use DataLayer for Ecommerce"
              />
              <Typography variant="body2">
                This will set Incendium to use pre-existing Datalayer
                implementations to track ecommerce.
              </Typography>
            </Stack>
            <Stack>
              <FormControlLabel
                componentsProps={{
                  typography: {
                    variant: "body1",
                  },
                }}
                control={
                  <Switch
                    checked={
                      projectSettings[
                        ApiProjectSettingKey.USE_DATALAYER_CART_EVENTS
                      ] === "true"
                    }
                    onChange={(e, checked) => {
                      handleChange(
                        ApiProjectSettingKey.USE_DATALAYER_CART_EVENTS,
                        checked ? "true" : "false"
                      );
                    }}
                  />
                }
                label="Use DataLayer for Add to Cart Conversion"
              />
              <Typography variant="body2">
                This will set Incendium to use pre-existing Datalayer
                implementations to track add to cart conversions.
              </Typography>
            </Stack>
            <Stack>
              <FormControlLabel
                componentsProps={{
                  typography: {
                    variant: "body1",
                  },
                }}
                control={
                  <Switch
                    checked={
                      projectSettings[
                        ApiProjectSettingKey.USE_DATALAYER_CHECKOUT_EVENTS
                      ] === "true"
                    }
                    onChange={(e, checked) => {
                      handleChange(
                        ApiProjectSettingKey.USE_DATALAYER_CHECKOUT_EVENTS,
                        checked ? "true" : "false"
                      );
                    }}
                  />
                }
                label="Use DataLayer for Checkout Start"
              />
              <Typography variant="body2">
                This will set Incendium to use pre-existing Datalayer
                implementations to track check out start conversions.
              </Typography>
            </Stack>
          </Stack>
        </StyledDrawerContainer>
      </StyledDrawer>
    </>
  );
}

export default DatalayerSycCard;
