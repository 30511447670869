/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiConversionFromJSON, ApiConversionToJSON, } from './ApiConversion';
import { ApiConversionStateTriggerFromJSON, ApiConversionStateTriggerToJSON, } from './ApiConversionStateTrigger';
export function ApiConversionStateFromJSON(json) {
    return ApiConversionStateFromJSONTyped(json, false);
}
export function ApiConversionStateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'conversion': !exists(json, 'conversion') ? undefined : ApiConversionFromJSON(json['conversion']),
        'trigger': !exists(json, 'trigger') ? undefined : ApiConversionStateTriggerFromJSON(json['trigger']),
        'offline': !exists(json, 'offline') ? undefined : json['offline'],
        'target': !exists(json, 'target') ? undefined : json['target'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
        'offlineFieldName': !exists(json, 'offlineFieldName') ? undefined : json['offlineFieldName'],
        'conversionId': !exists(json, 'conversionId') ? undefined : json['conversionId'],
        'repeatedSelector': !exists(json, 'repeatedSelector') ? undefined : json['repeatedSelector'],
        'preventSubmit': !exists(json, 'preventSubmit') ? undefined : json['preventSubmit'],
        'targetInnerText': !exists(json, 'targetInnerText') ? undefined : json['targetInnerText'],
    };
}
export function ApiConversionStateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'conversion': ApiConversionToJSON(value.conversion),
        'trigger': ApiConversionStateTriggerToJSON(value.trigger),
        'offline': value.offline,
        'target': value.target,
        'value': value.value,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'offlineFieldName': value.offlineFieldName,
        'conversionId': value.conversionId,
        'repeatedSelector': value.repeatedSelector,
        'preventSubmit': value.preventSubmit,
        'targetInnerText': value.targetInnerText,
    };
}
