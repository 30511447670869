/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiService;
(function (ApiService) {
    ApiService["SERVICE_UNSPECIFIED"] = "service_unspecified";
    ApiService["GOOGLE"] = "google";
    ApiService["BING"] = "bing";
    ApiService["FACEBOOK"] = "facebook";
    ApiService["ECWID"] = "ecwid";
})(ApiService || (ApiService = {}));
export function ApiServiceFromJSON(json) {
    return ApiServiceFromJSONTyped(json, false);
}
export function ApiServiceFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiServiceToJSON(value) {
    return value;
}
