/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var LocationItemLocationItemCondition;
(function (LocationItemLocationItemCondition) {
    LocationItemLocationItemCondition["LOCATION_ITEM_CONDITION_UNSPECIFIED"] = "LOCATION_ITEM_CONDITION_UNSPECIFIED";
    LocationItemLocationItemCondition["EQUAL"] = "EQUAL";
    LocationItemLocationItemCondition["NOT_EQUAL"] = "NOT_EQUAL";
    LocationItemLocationItemCondition["CONTAINS"] = "CONTAINS";
    LocationItemLocationItemCondition["ANY"] = "ANY";
    LocationItemLocationItemCondition["STARTS_WITH"] = "STARTS_WITH";
    LocationItemLocationItemCondition["ENDS_WITH"] = "ENDS_WITH";
    LocationItemLocationItemCondition["DOES_NOT_CONTAIN"] = "DOES_NOT_CONTAIN";
    LocationItemLocationItemCondition["REPEATED_CHARACTER"] = "REPEATED_CHARACTER";
    LocationItemLocationItemCondition["REGEX"] = "REGEX";
    LocationItemLocationItemCondition["PAGE_DATA"] = "PAGE_DATA";
})(LocationItemLocationItemCondition || (LocationItemLocationItemCondition = {}));
export function LocationItemLocationItemConditionFromJSON(json) {
    return LocationItemLocationItemConditionFromJSONTyped(json, false);
}
export function LocationItemLocationItemConditionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function LocationItemLocationItemConditionToJSON(value) {
    return value;
}
