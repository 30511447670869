import { createContext, useContext, useMemo, useState } from "react";

// key is name of dimension, value is colour
export type TColourMap = {
  [key: string]: string;
};

interface IAnalyticsContext {
  colourMap: TColourMap;
  setColourMap: (m: TColourMap) => void;
}

export const AnalyticsContext = createContext<IAnalyticsContext>({
  colourMap: {},
  setColourMap: () => {},
});

export const useAnalyticsContext = () => useContext(AnalyticsContext);

const AnalyticsProvider = ({ children }: { children: React.ReactNode }) => {
  const [colourMap, setColourMap] = useState<TColourMap>({});

  const state = useMemo(
    () => ({
      colourMap,
      setColourMap,
    }),
    [colourMap, setColourMap]
  );

  return (
    <AnalyticsContext.Provider value={state}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
