/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiConditionGroupFromJSON, ApiConditionGroupToJSON, } from './ApiConditionGroup';
import { ApiLocationFromJSON, ApiLocationToJSON, } from './ApiLocation';
export function ApiEngagementPayloadFromJSON(json) {
    return ApiEngagementPayloadFromJSONTyped(json, false);
}
export function ApiEngagementPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'maxScore': !exists(json, 'maxScore') ? undefined : json['maxScore'],
        'conditionGroups': !exists(json, 'conditionGroups') ? undefined : (json['conditionGroups'].map(ApiConditionGroupFromJSON)),
        'locations': !exists(json, 'locations') ? undefined : (json['locations'].map(ApiLocationFromJSON)),
    };
}
export function ApiEngagementPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'score': value.score,
        'maxScore': value.maxScore,
        'conditionGroups': value.conditionGroups === undefined ? undefined : (value.conditionGroups.map(ApiConditionGroupToJSON)),
        'locations': value.locations === undefined ? undefined : (value.locations.map(ApiLocationToJSON)),
    };
}
