import {
  ApiAccount,
  ApiProject,
  ApiService,
  SyncServiceListClientsServiceEnum,
} from "@incendium/api";
import { syncService } from "Apis";
import { enumToArray } from "Helpers/enumToText";
import produce from "immer";
import { useCallback, useState } from "react";
import { TMappedApiUserOauthClient, useSharedState } from "./useSharedState";

const fetchSyncClients = async (selectedProject: ApiProject | null) => {
  let o = {} as TMappedApiUserOauthClient;
  await Promise.all(
    enumToArray(ApiService).map(async (service) => {
      const res = await syncService.syncServiceListClients({
        service: service as unknown as SyncServiceListClientsServiceEnum,
      });
      o[service] = res.results || [];
    })
  );
  return o;
};

// [key:OauthClient.id]
export type TMappedApiAccount = Record<number, ApiAccount[]>;

export const useSyncClients = () => {
  const [accounts, setAccounts] = useState<TMappedApiAccount>({});
  const [accountsLoading, setAccountsLoading] = useState(false);

  const { state, setState, refetch, loading } = useSharedState<
    TMappedApiUserOauthClient,
    TMappedApiUserOauthClient
  >("syncClients", {} as TMappedApiUserOauthClient, fetchSyncClients);
  const getAccounts = useCallback(
    async (oauthClientId: number) => {
      if (accounts?.hasOwnProperty(oauthClientId)) {
        return;
      }
      setAccountsLoading(true);
      const res = await syncService.syncServiceListAccounts({
        oauthClientId,
      });

      setAccounts(
        produce(accounts, (draft) => {
          draft[oauthClientId] = res.results || [];
        })
      );
      setAccountsLoading(false);
    },
    [accounts]
  );

  return {
    loading,
    syncClients: state,
    setSyncClients: setState,
    refetch,
    accounts,
    setAccounts,
    getAccounts,
    accountsLoading,
  };
};
