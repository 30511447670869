import { IStoreState } from "Interfaces";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";


export const useCheckLoginStatus = () => {
    const tokens = useSelector((state: IStoreState) => state.auth.tokens);
    const history = useHistory();
    const location = useLocation<{ from: string }>();

    useEffect(() => {
        if (tokens && location) {
            const from = location.state?.from || "/";
            history.push(from);
        }
    }, [history, tokens]);
}