/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiPathStatus;
(function (ApiPathStatus) {
    ApiPathStatus["PATH_STATUS_NONE"] = "PathStatusNone";
    ApiPathStatus["PATH_STATUS_ACTIVE"] = "PathStatusActive";
    ApiPathStatus["PATH_STATUS_INACTIVE"] = "PathStatusInactive";
    ApiPathStatus["PATH_STATUS_DELETING"] = "PathStatusDeleting";
})(ApiPathStatus || (ApiPathStatus = {}));
export function ApiPathStatusFromJSON(json) {
    return ApiPathStatusFromJSONTyped(json, false);
}
export function ApiPathStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiPathStatusToJSON(value) {
    return value;
}
