import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { IConsolidator } from "features/utms";
import { useEffect } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

interface IConsolidatorProps {
  name: string;
  setName: (n: string) => void;
  onSubmit: (n: string) => void;
  items: IConsolidator[];
  checked: string[];
}
function Consolidator({
  name,
  setName,
  onSubmit,
  items,
  checked,
}: IConsolidatorProps) {
  useEffect(() => {
    ValidatorForm.addValidationRule("doesNotEqual", (value) => {
      return !checked.includes(value);
    });
  }, [checked]);

  useEffect(() => {
    return () => {
      ValidatorForm.removeValidationRule("doesNotEqual");
    };
  }, []);
  return (
    <Stack
      spacing={2}
      px={2}
      sx={{ height: "100%", overflowY: "auto", overflowX: "hidden" }}
    >
      <ValidatorForm onSubmit={() => onSubmit(name)}>
        <Stack spacing={2}>
          <Box pt={1}>
            <TextValidator
              value={name}
              label="New Grouping Name"
              variant="outlined"
              name="Grouping Name"
              fullWidth
              validators={["required", "doesNotEqual:abc"]}
              errorMessages={[
                "Name is Required",
                "Name can not match one of your selected sources",
              ]}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setName(e.currentTarget.value)
              }
            />
          </Box>

          <Button fullWidth type="submit">
            Create New Grouping
          </Button>
        </Stack>
      </ValidatorForm>
      <Divider>
        <Typography variant="body1">Or Select Existing</Typography>
      </Divider>
      <List>
        {items.map((item) => (
          <ListItem disablePadding key={item.name}>
            <ListItemText primary={item.name} />
            <Button
              onClick={() => onSubmit(item.name)}
              size="extraSmall"
              endIcon={<Add fontSize="extraSmall" />}
              disabled={checked.includes(item.name)}
            >
              Consolidate
            </Button>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}

export default Consolidator;
