/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiChartAttributeFromJSON, ApiChartAttributeToJSON, } from './ApiChartAttribute';
import { ApiChartCrawlerFromJSON, ApiChartCrawlerToJSON, } from './ApiChartCrawler';
import { ApiChartDisplayOptionsFromJSON, ApiChartDisplayOptionsToJSON, } from './ApiChartDisplayOptions';
import { ApiChartHavingFromJSON, ApiChartHavingToJSON, } from './ApiChartHaving';
import { ApiChartRankFromJSON, ApiChartRankToJSON, } from './ApiChartRank';
import { ApiChartTemplateFromJSON, ApiChartTemplateToJSON, } from './ApiChartTemplate';
import { ApiChartTypeFromJSON, ApiChartTypeToJSON, } from './ApiChartType';
import { ApiChartYAxisKeyFromJSON, ApiChartYAxisKeyToJSON, } from './ApiChartYAxisKey';
export function ApiChartResponseFromJSON(json) {
    return ApiChartResponseFromJSONTyped(json, false);
}
export function ApiChartResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : ApiChartTypeFromJSON(json['type']),
        'yAxisKeys': !exists(json, 'yAxisKeys') ? undefined : (json['yAxisKeys'].map(ApiChartYAxisKeyFromJSON)),
        'dimension': !exists(json, 'dimension') ? undefined : json['dimension'],
        'attributes': !exists(json, 'attributes') ? undefined : (json['attributes'].map(ApiChartAttributeFromJSON)),
        'orderBy': !exists(json, 'orderBy') ? undefined : json['orderBy'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'template': !exists(json, 'template') ? undefined : ApiChartTemplateFromJSON(json['template']),
        'dimensionDecorators': !exists(json, 'dimensionDecorators') ? undefined : (json['dimensionDecorators'].map(ApiChartAttributeFromJSON)),
        'displayOptions': !exists(json, 'displayOptions') ? undefined : ApiChartDisplayOptionsFromJSON(json['displayOptions']),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'crawler': !exists(json, 'crawler') ? undefined : ApiChartCrawlerFromJSON(json['crawler']),
        'comparison': !exists(json, 'comparison') ? undefined : json['comparison'],
        'includeEmpty': !exists(json, 'includeEmpty') ? undefined : json['includeEmpty'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'ranks': !exists(json, 'ranks') ? undefined : (json['ranks'].map(ApiChartRankFromJSON)),
        'having': !exists(json, 'having') ? undefined : (json['having'].map(ApiChartHavingFromJSON)),
        'includeSubdomains': !exists(json, 'includeSubdomains') ? undefined : json['includeSubdomains'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'withTotals': !exists(json, 'withTotals') ? undefined : json['withTotals'],
    };
}
export function ApiChartResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'type': ApiChartTypeToJSON(value.type),
        'yAxisKeys': value.yAxisKeys === undefined ? undefined : (value.yAxisKeys.map(ApiChartYAxisKeyToJSON)),
        'dimension': value.dimension,
        'attributes': value.attributes === undefined ? undefined : (value.attributes.map(ApiChartAttributeToJSON)),
        'orderBy': value.orderBy,
        'limit': value.limit,
        'projectId': value.projectId,
        'template': ApiChartTemplateToJSON(value.template),
        'dimensionDecorators': value.dimensionDecorators === undefined ? undefined : (value.dimensionDecorators.map(ApiChartAttributeToJSON)),
        'displayOptions': ApiChartDisplayOptionsToJSON(value.displayOptions),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'crawler': ApiChartCrawlerToJSON(value.crawler),
        'comparison': value.comparison,
        'includeEmpty': value.includeEmpty,
        'description': value.description,
        'ranks': value.ranks === undefined ? undefined : (value.ranks.map(ApiChartRankToJSON)),
        'having': value.having === undefined ? undefined : (value.having.map(ApiChartHavingToJSON)),
        'includeSubdomains': value.includeSubdomains,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'withTotals': value.withTotals,
    };
}
