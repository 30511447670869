/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiAutomatedMarketingMessageFromJSON, ApiAutomatedMarketingMessageToJSON, } from './ApiAutomatedMarketingMessage';
import { ApiIntegrationServiceChoiceFromJSON, ApiIntegrationServiceChoiceToJSON, } from './ApiIntegrationServiceChoice';
import { ApiKeyValueFromJSON, ApiKeyValueToJSON, } from './ApiKeyValue';
import { ApiMediumFromJSON, ApiMediumToJSON, } from './ApiMedium';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
import { ApiProviderFromJSON, ApiProviderToJSON, } from './ApiProvider';
export function ApiIntegrationServiceFromJSON(json) {
    return ApiIntegrationServiceFromJSONTyped(json, false);
}
export function ApiIntegrationServiceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'active': !exists(json, 'active') ? undefined : json['active'],
        'provider': !exists(json, 'provider') ? undefined : ApiProviderFromJSON(json['provider']),
        'integrationServiceSettings': !exists(json, 'integrationServiceSettings') ? undefined : (json['integrationServiceSettings'].map(ApiKeyValueFromJSON)),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'messages': !exists(json, 'messages') ? undefined : (json['messages'].map(ApiAutomatedMarketingMessageFromJSON)),
        'integrationServiceChoice': !exists(json, 'integrationServiceChoice') ? undefined : ApiIntegrationServiceChoiceFromJSON(json['integrationServiceChoice']),
        'medium': !exists(json, 'medium') ? undefined : ApiMediumFromJSON(json['medium']),
        'providerId': !exists(json, 'providerId') ? undefined : json['providerId'],
        'integrationServiceChoiceId': !exists(json, 'integrationServiceChoiceId') ? undefined : json['integrationServiceChoiceId'],
    };
}
export function ApiIntegrationServiceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'project': ApiProjectToJSON(value.project),
        'active': value.active,
        'provider': ApiProviderToJSON(value.provider),
        'integrationServiceSettings': value.integrationServiceSettings === undefined ? undefined : (value.integrationServiceSettings.map(ApiKeyValueToJSON)),
        'projectId': value.projectId,
        'messages': value.messages === undefined ? undefined : (value.messages.map(ApiAutomatedMarketingMessageToJSON)),
        'integrationServiceChoice': ApiIntegrationServiceChoiceToJSON(value.integrationServiceChoice),
        'medium': ApiMediumToJSON(value.medium),
        'providerId': value.providerId,
        'integrationServiceChoiceId': value.integrationServiceChoiceId,
    };
}
