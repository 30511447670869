import {
  ApiGetUsersFilter,
  ApiOperator,
  ApiUserFilterType,
} from "@incendium/api";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import AnimatedList from "Components/AnimatedList/AnimatedList";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import Loading from "Components/Loading/Loading";
import { leadDimensionToName, useLeadFilters } from "features/leadAnalytics";
import { formatEnumVal } from "Helpers/enumToText";
import produce from "immer";
import { CallbackOrVal, GenericDialoagProps } from "Interfaces";
import { useCallback, useMemo, useState } from "react";

interface IFilterRowProps {
  row: ApiGetUsersFilter;
  onEdit: (v: ApiGetUsersFilter) => void;
}

const FilterRow = ({ row, onEdit }: IFilterRowProps) => {
  const { filters } = useLeadFilters();

  const optionLabels = useMemo(() => {
    return filters.map((f) => f.key);
  }, [filters]);

  const availableValues = useMemo(() => {
    return filters.find((f) => f.key === row.type)?.values;
  }, [filters, row]);

  const isNumericValue = useMemo(() => {
    if (!row.type) {
      return false;
    }
    return [
      ApiUserFilterType.LEAD_SCORE,
      ApiUserFilterType.LTV,
      ApiUserFilterType.SALES,
    ].includes(row.type);
  }, [row]);

  return (
    <>
      <Autocomplete
        id="att-l-dim"
        size="small"
        onChange={(e, value) => {
          onEdit(
            produce(row, (draft) => {
              draft.type = value;
            })
          );
        }}
        value={row.type}
        disableClearable
        fullWidth
        options={optionLabels || []}
        getOptionLabel={(o) => leadDimensionToName(o)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Dimension"
            variant="outlined"
            size="small"
            sx={{
              "& .MuiFormLabel-root": {
                maxWidth: "calc(100% - 34px)",
              },
            }}
          />
        )}
      />
      {isNumericValue ? (
        <TextField
          select
          label="Operator"
          size="small"
          fullWidth
          value={row.operator}
          onChange={(e) => {
            onEdit(
              produce(row, (draft) => {
                draft.operator = e.target.value as ApiOperator;
              })
            );
          }}
        >
          {[
            ApiOperator.EQUAL,
            ApiOperator.NOT_EQUAL,
            ApiOperator.IS_GREATER_THAN,
            ApiOperator.IS_GREATER_THAN_OR_EQUAL,
            ApiOperator.IS_LESS_THAN,
            ApiOperator.IS_LESS_THAN_OR_EQUAL,
          ].map((o) => (
            <MenuItem key={o} value={o}>
              {formatEnumVal(o)}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <TextField
          select
          label="Operator"
          size="small"
          fullWidth
          value={row.operator}
          onChange={(e) => {
            onEdit(
              produce(row, (draft) => {
                draft.operator = e.target.value as ApiOperator;
              })
            );
          }}
        >
          {[
            ApiOperator.EQUAL,
            ApiOperator.NOT_EQUAL,
            ApiOperator.CONTAINS,
            ApiOperator.NOT_CONTAINS,
          ].map((o) => (
            <MenuItem key={o} value={o}>
              {formatEnumVal(o)}
            </MenuItem>
          ))}
        </TextField>
      )}
      {isNumericValue ? (
        <TextField
          label="Numeric Value"
          size="small"
          fullWidth
          type={"number"}
          value={row.value || ""}
          onChange={(e) => {
            onEdit(
              produce(row, (draft) => {
                draft.value = e.target.value;
              })
            );
          }}
        />
      ) : row.operator &&
        [ApiOperator.EQUAL, ApiOperator.NOT_EQUAL].includes(row.operator) ? (
        <Autocomplete
          id="att-l-value"
          onChange={(e, value) => {
            onEdit(
              produce(row, (draft) => {
                draft.value = value;
              })
            );
          }}
          value={row.value}
          filterSelectedOptions
          disableClearable
          fullWidth
          options={availableValues || []}
          ChipProps={{
            size: "small",
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Value"
              variant="outlined"
              size="small"
            />
          )}
        />
      ) : (
        <TextField
          label="Value"
          size="small"
          fullWidth
          value={row.value || ""}
          onChange={(e) => {
            onEdit(
              produce(row, (draft) => {
                draft.value = e.target.value;
              })
            );
          }}
        />
      )}
    </>
  );
};

interface ILeadTimelineLIstFilterDialogProps extends GenericDialoagProps {
  filters: ApiGetUsersFilter[];
  setFilters: (v: CallbackOrVal<ApiGetUsersFilter[]>) => void;
}

function LeadTimelineLIstFilterDialog({
  open,
  setOpen,
  filters: initialFilters,
  setFilters: setInitialFilters,
}: ILeadTimelineLIstFilterDialogProps) {
  const { loading } = useLeadFilters();

  const [filters, setFilters] = useState(initialFilters);

  const onAdd = useCallback(() => {
    setFilters((prev) =>
      produce(prev, (draft) => {
        draft.push({});
      })
    );
  }, [setFilters]);

  const onRemove = useCallback(
    (idx: number, value: ApiGetUsersFilter) => {
      setFilters((prev) =>
        produce(prev, (draft) => {
          draft?.splice(idx, 1);
        })
      );
    },
    [setFilters]
  );

  const onEdit = useCallback(
    (idx: number) => (value: ApiGetUsersFilter) => {
      setFilters((prev) =>
        produce(prev, (draft) => {
          if (!draft) {
            draft = [];
          }

          draft[idx] = value;
        })
      );
    },
    [setFilters]
  );

  const apply = useCallback(() => {
    setInitialFilters(filters);
    setOpen(false);
  }, [setInitialFilters, filters, setOpen]);

  return (
    <CustomDialog
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth="sm"
      open={open}
    >
      <DialogTitle>Lead Timeline Filters</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2" mb={3}>
          Set Filters to Narrow Down your Users List
        </Typography>
        {loading ? (
          <Loading />
        ) : (
          <>
            <AnimatedList
              onAdd={onAdd}
              onRemove={onRemove}
              onEdit={onEdit}
              items={filters.length > 0 ? filters : [{}]}
              RowComponent={FilterRow}
              rowSpacing={3}
              allowDeleteAll
              btnText="Add New Filter"
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={apply}>Apply Filters</Button>
      </DialogActions>
    </CustomDialog>
  );
}

export default LeadTimelineLIstFilterDialog;
