/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiLeadScoreAdjustmentReasonFromJSON, ApiLeadScoreAdjustmentReasonToJSON, } from './ApiLeadScoreAdjustmentReason';
export function ApiRulePayloadFromJSON(json) {
    return ApiRulePayloadFromJSONTyped(json, false);
}
export function ApiRulePayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'score': !exists(json, 'score') ? undefined : json['score'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'reason': !exists(json, 'reason') ? undefined : ApiLeadScoreAdjustmentReasonFromJSON(json['reason']),
    };
}
export function ApiRulePayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'score': value.score,
        'value': value.value,
        'reason': ApiLeadScoreAdjustmentReasonToJSON(value.reason),
    };
}
