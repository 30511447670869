import { IStoreState } from "Interfaces";

export const audienceSelector =
  (clientId?: string, projectId?: string, audienceId?: string) =>
  (state: IStoreState) => {
    if (!clientId || !projectId || !audienceId) {
      return null;
    }

    const clientAudiences = state.application.audiences[clientId];
    if (!clientAudiences) {
      return null;
    }
    const audiences = clientAudiences[projectId].state;
    if (!audiences) {
      return null;
    }
    const index = audiences.findIndex(
      (item) => item.id === parseInt(audienceId)
    );
    if (index === -1) {
      return null;
    }
    return audiences[index];
  };
