/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiSessionEffectivenessRuleFromJSON, ApiSessionEffectivenessRulePayloadToJSON, ApiSessionEffectivenessRulesFromJSON, } from '../models';
/**
 *
 */
export class SessionEffectivenessRuleServiceApi extends runtime.BaseAPI {
    /**
     */
    sessionEffectivenessRuleServiceCreateRuleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling sessionEffectivenessRuleServiceCreateRule.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling sessionEffectivenessRuleServiceCreateRule.');
            }
            const queryParameters = {};
            if (requestParameters.ruleId !== undefined) {
                queryParameters['ruleId'] = requestParameters.ruleId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/session-effectiveness-rule/v1/projects/{projectId}/rules`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiSessionEffectivenessRulePayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiSessionEffectivenessRuleFromJSON(jsonValue));
        });
    }
    /**
     */
    sessionEffectivenessRuleServiceCreateRule(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.sessionEffectivenessRuleServiceCreateRuleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    sessionEffectivenessRuleServiceDeleteRuleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling sessionEffectivenessRuleServiceDeleteRule.');
            }
            if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
                throw new runtime.RequiredError('ruleId', 'Required parameter requestParameters.ruleId was null or undefined when calling sessionEffectivenessRuleServiceDeleteRule.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/session-effectiveness-rule/v1/projects/{projectId}/rules/{ruleId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    sessionEffectivenessRuleServiceDeleteRule(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.sessionEffectivenessRuleServiceDeleteRuleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    sessionEffectivenessRuleServiceListRulesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling sessionEffectivenessRuleServiceListRules.');
            }
            const queryParameters = {};
            if (requestParameters.ruleId !== undefined) {
                queryParameters['ruleId'] = requestParameters.ruleId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/session-effectiveness-rule/v1/projects/{projectId}/rules`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiSessionEffectivenessRulesFromJSON(jsonValue));
        });
    }
    /**
     */
    sessionEffectivenessRuleServiceListRules(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.sessionEffectivenessRuleServiceListRulesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    sessionEffectivenessRuleServiceReadRuleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling sessionEffectivenessRuleServiceReadRule.');
            }
            if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
                throw new runtime.RequiredError('ruleId', 'Required parameter requestParameters.ruleId was null or undefined when calling sessionEffectivenessRuleServiceReadRule.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/session-effectiveness-rule/v1/projects/{projectId}/rules/{ruleId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiSessionEffectivenessRuleFromJSON(jsonValue));
        });
    }
    /**
     */
    sessionEffectivenessRuleServiceReadRule(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.sessionEffectivenessRuleServiceReadRuleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    sessionEffectivenessRuleServiceUpdateRuleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling sessionEffectivenessRuleServiceUpdateRule.');
            }
            if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
                throw new runtime.RequiredError('ruleId', 'Required parameter requestParameters.ruleId was null or undefined when calling sessionEffectivenessRuleServiceUpdateRule.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling sessionEffectivenessRuleServiceUpdateRule.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/session-effectiveness-rule/v1/projects/{projectId}/rules/{ruleId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiSessionEffectivenessRulePayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiSessionEffectivenessRuleFromJSON(jsonValue));
        });
    }
    /**
     */
    sessionEffectivenessRuleServiceUpdateRule(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.sessionEffectivenessRuleServiceUpdateRuleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
