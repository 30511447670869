import { IStoreState } from "Interfaces";

export const channelSelector =
  (clientId?: string, projectId?: string, channelId?: string) =>
  (state: IStoreState) => {
    if (!clientId || !projectId || !channelId) {
      return null;
    }

    const clientChannels = state.application.channels[clientId];
    if (!clientChannels) {
      return null;
    }
    const channels = clientChannels[projectId].state;
    if (!channels) {
      return null;
    }
    const index = channels.findIndex((item) => item.id === parseInt(channelId));
    if (index === -1) {
      return null;
    }
    return channels[index];
  };
