import { ApiBucketResponse } from "@incendium/api";
import { Box, CircularProgress, Portal } from "@mui/material";
import { trafficBucketService } from "Apis";
import ConditionGroupProvider from "Components/ConditionalGroup/ConditionGroupProvider";
import TrafficBucketForm from "Components/TrafficBuckets/TrafficBucketForm";
import { TypographyHelp } from "Components/UI/TypographyHelp";
import { useSelectedProject } from "Hooks";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useMount } from "react-use";

function TrafficBucketPage() {
  const [title, setTitle] = useState("");
  const { bucketId } = useParams<{ bucketId: string }>();
  const { selectedProject } = useSelectedProject();
  const [trafficBucket, setTrafficBucket] = useState<
    ApiBucketResponse | undefined
  >(undefined);

  useMount(() => {
    if (!bucketId) {
      return;
    }

    const load = async () => {
      const res = await trafficBucketService.bucketServiceReadBucket({
        projectId: selectedProject?.id as number,
        bucketId: Number(bucketId),
      });
      setTrafficBucket(res);
      setTitle(res.name || "");
    };
    load();
  });

  if (!trafficBucket || !selectedProject) {
    return <CircularProgress />;
  }

  return (
    <Box pt={5}>
      <Portal container={() => document.getElementById("pageTitle")}>
        <TypographyHelp
          variant="h1"
          text={title}
          placement={"right"}
          width={400}
          tooltip={
            <>
              <p>
                Configure the rules which will define what traffic bucket a
                pageview belongs to.
              </p>
              <p>
                Field: this is what we are looking to find a match to; these are
                often, but not always, set as UTM params
              </p>
              <p>
                Operator
                <li>Equal - field exactly matches this value</li>
                <li>Not Equal - field does not exactly match this value </li>
                <li>
                  Is In - field exactly matches one of the values, seperated by
                  `|` e.g "cpc|ppc|paidsearch"
                </li>
                <li>
                  Not In - field does not exactly match any of the values
                  seperated by `|`
                </li>
                <li>Starts with - field starts with this value</li>
                <li>Contains - field contains this value</li>
              </p>
            </>
          }
        />
      </Portal>
      <ConditionGroupProvider>
        <TrafficBucketForm
          bucket={trafficBucket}
          setBucket={setTrafficBucket}
          project={selectedProject}
        />
      </ConditionGroupProvider>
    </Box>
  );
}

export default TrafficBucketPage;
