/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiScheduledReportIntervalTypeFromJSON, ApiScheduledReportIntervalTypeToJSON, } from './ApiScheduledReportIntervalType';
import { ApiScheduledReportTypeFromJSON, ApiScheduledReportTypeToJSON, } from './ApiScheduledReportType';
export function ApiScheduledReportPayloadFromJSON(json) {
    return ApiScheduledReportPayloadFromJSONTyped(json, false);
}
export function ApiScheduledReportPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'intervalType': !exists(json, 'intervalType') ? undefined : ApiScheduledReportIntervalTypeFromJSON(json['intervalType']),
        'type': !exists(json, 'type') ? undefined : ApiScheduledReportTypeFromJSON(json['type']),
        'emails': !exists(json, 'emails') ? undefined : json['emails'],
    };
}
export function ApiScheduledReportPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'intervalType': ApiScheduledReportIntervalTypeToJSON(value.intervalType),
        'type': ApiScheduledReportTypeToJSON(value.type),
        'emails': value.emails,
    };
}
