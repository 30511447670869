import { styled } from "@mui/system";
import { Slider, SliderProps, Typography } from "@mui/material";

interface IStyledSliderProps extends SliderProps {
  activecolour?: string;
  title?: string;
  align?: "right" | "left" | "center" | "inherit" | "justify";
}

export const StyledSlider = styled(
  ({ title, align = "center", ...props }: IStyledSliderProps) => (
    <>
      {title && (
        <Typography variant="body2" align={align}>
          {title}
        </Typography>
      )}
      <Slider {...props} />
    </>
  )
)(({ theme, ...rest }) => ({
  color: rest.activecolour || theme.palette.secondary.light,

  height: 5,
  padding: "8px 0",
  "& .MuiSlider-track": {
    border: "none",
    background: "#a1affe",
    height: 6,
  },
  "& .MuiSlider-thumb": {
    height: 14,
    width: 14,
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-mark": {
    display: "none",
  },
  "& .MuiSlider-markLabel": {
    fontSize: ".8rem",
    color: theme.palette.text.primary,
    top: 22,
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: ".8rem",
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: rest.activecolour || "#a1affe",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
}));
