import { ApiSimpleUTM, ApiSyncType } from "@incendium/api";
import { formatEnumVal } from "Helpers/enumToText";

export const formatUTMSyncType = (t: ApiSyncType) => {
  switch (t) {
    case ApiSyncType.UNSPECIFIED:
      return "Catchall";
    case ApiSyncType.FACEBOOK_ADS:
      return "Meta / Facebook";
    case ApiSyncType.GOOGLE_ADS:
      return "Google";
    case ApiSyncType.BING_ADS:
      return "Bing";
    default:
      return formatEnumVal(t);
  }
};

export const compareUtms = (
  originalUtms: ApiSimpleUTM[],
  updatedUtms: ApiSimpleUTM[]
) => {
  const utmsToSave: ApiSimpleUTM[] = [];
  const utmsToDelete: ApiSimpleUTM[] = [];
  // remove default
  originalUtms = originalUtms.filter((u) => !u._default);
  updatedUtms = updatedUtms.filter((u) => !u._default);

  // Identify utms to save (new or updated)
  updatedUtms.forEach((updatedUtm) => {
    const index = originalUtms.findIndex((o) => o.id === updatedUtm.id);
    if (index === -1) {
      // New utm
      utmsToSave.push(updatedUtm);
    } else {
      // Existing utm
      const originalUtm = originalUtms[index];
      if (!areUtmsEqual(originalUtm, updatedUtm)) {
        utmsToSave.push(updatedUtm); // Mark for saving if changed
      }
      // Remove from originalUtms to identify utms to delete
      originalUtms.splice(index, 1);
    }
  });

  // Remaining utms in originalUtms are to be deleted
  utmsToDelete.push(...originalUtms);

  return { utmsToSave, utmsToDelete };
};

const areUtmsEqual = (utm1: ApiSimpleUTM, utm2: ApiSimpleUTM): boolean => {
  return (
    utm1.syncType === utm2.syncType &&
    utm1.utmSource === utm2.utmSource &&
    utm1.utmMedium === utm2.utmMedium &&
    utm1.utmCampaign === utm2.utmCampaign &&
    utm1.utmTerm === utm2.utmTerm &&
    utm1.utmContent === utm2.utmContent &&
    utm1.utmCampaignId === utm2.utmCampaignId &&
    utm1.utmAdgroupId === utm2.utmAdgroupId
  );
};
