import { appearDown } from "Pages/Audience/ClassificationsPage";
import { motion } from "framer-motion";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  List,
  Stack,
  Typography,
} from "@mui/material";
import {
  ApiClassification,
  ApiClassificationItem,
  ApiClassificationItemPayload,
  ApiClassificationRule,
  ApiClassificationRulePayload,
  ApiProject,
} from "@incendium/api";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { audienceService } from "Apis";
import { useEffect } from "react";
import { ColsTitle } from "Components/ColsLayout/ColsLayout";
import AudienceRule from "Components/Audience/AudienceRule";
import produce from "immer";
import { Add, Remove } from "@mui/icons-material";

const RuleRow = ({
  rule,
  classificationItem,
  setClassificationItem,
  index,
}: {
  rule: ApiClassificationRule;
  classificationItem: ApiClassificationItem;
  setClassificationItem: React.Dispatch<
    React.SetStateAction<ApiClassificationItem | null>
  >;
  index: number;
}) => {
  const addRule = () => {
    let rules = [...(classificationItem.rules || [])];
    rules.splice(index + 1, 0, {});

    setClassificationItem({
      ...classificationItem,
      rules,
    });
  };

  const removeRule = () => {
    let rules = [...(classificationItem.rules || [])];
    rules.splice(index, 1);

    setClassificationItem({
      ...classificationItem,
      rules,
    });
  };
  return (
    <Stack p={2} direction="row" spacing={2} alignItems="center">
      <AudienceRule
        size="small"
        rule={rule}
        setRule={(value) => {
          setClassificationItem(
            produce(classificationItem, (draft) => {
              const idx = (draft.rules || []).findIndex(
                (d) => d.id === value.id
              );

              if (idx !== -1) {
                if (!draft.rules) {
                  draft.rules = [];
                }
                draft.rules[idx] = { ...value };
              }
            })
          );
        }}
      />
      <ButtonGroup>
        <Button
          disabled={(classificationItem.rules || []).length <= 1}
          onClick={removeRule}
          color="secondary"
        >
          <Remove />
        </Button>

        <Button onClick={addRule} color="primary">
          <Add />
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

function ClassificationRulesCol({
  classification,
  project,
  classificationItem,
  setClassificationItem,
  onSaved,
}: {
  classification?: ApiClassification | null;
  project: ApiProject;
  classificationItem: ApiClassificationItem | null;
  setClassificationItem: React.Dispatch<
    React.SetStateAction<ApiClassificationItem | null>
  >;
  onSaved: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (
      classificationItem &&
      (!classificationItem?.rules || classificationItem.rules.length === 0)
    ) {
      setClassificationItem({
        ...classificationItem,
        rules: [{}],
      });
    }
  });

  const save = async () => {
    setDisabled(true);
    // save
    let payload: ApiClassificationItemPayload = {
      value: classificationItem?.value,
      description: classificationItem?.description,
      order: classificationItem?.order,
    };

    const rules: ApiClassificationRulePayload[] = (
      classificationItem?.rules || []
    ).map((r) => ({
      ...r,
      condition: undefined,
    }));
    payload.rules = rules;
    try {
      const res = await audienceService.audienceServiceUpdateClassificationItem(
        {
          projectId: project.id as number,
          classificationId: classification?.id as number,
          classificationItemId: classificationItem?.id as number,
          payload,
        }
      );

      enqueueSnackbar(`${res.value} Saved`, {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      onSaved();
    } catch (error) {
      enqueueSnackbar("Something Went Wrong", {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }
    setDisabled(false);
  };
  return (
    <div>
      <ColsTitle>
        {classificationItem ? (
          <>
            <motion.span
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{ duration: 0.4, delay: 0.1 }}
            >
              <Typography variant="body1">Behaviours</Typography>
              <Typography variant="body2" component="span" color="primary">
                (Trait Value : {classificationItem.value})
              </Typography>
            </motion.span>

            <motion.span
              animate={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.4, delay: 0.3 }}
            >
              <Button
                disabled={disabled}
                variant="contained"
                color="primary"
                onClick={save}
              >
                Save Rules
              </Button>
            </motion.span>
          </>
        ) : (
          <Typography variant="body1" color="textSecondary">
            Behaviours
          </Typography>
        )}
      </ColsTitle>
      {classificationItem &&
        classificationItem.rules &&
        classificationItem.rules.length > 0 && (
          <Box
            component={motion.div}
            initial="hidden"
            animate="show"
            exit="hidden"
            variants={appearDown}
          >
            <List disablePadding>
              {classificationItem.rules.map((rule, idx) => (
                <Box
                  component={motion.div}
                  key={rule.id || idx}
                  variants={{
                    hidden: { opacity: 0 },
                    show: { opacity: 1 },
                  }}
                >
                  <RuleRow
                    rule={rule}
                    classificationItem={classificationItem || {}}
                    setClassificationItem={setClassificationItem}
                    index={idx}
                  />
                  <Divider />
                </Box>
              ))}
            </List>
          </Box>
        )}
    </div>
  );
}

export default ClassificationRulesCol;
