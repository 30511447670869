import { ApiAlert } from "@incendium/api";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import { GenericDialoagProps } from "Interfaces";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { FormEvent, useEffect, useState } from "react";
import { Box } from "@mui/system";
import produce from "immer";

interface IAlertCommsDialogProps extends GenericDialoagProps {
  alert: ApiAlert;
  setAlert: React.Dispatch<React.SetStateAction<ApiAlert | undefined>>;
}

function AlertCommsDialog({
  open,
  setOpen,
  alert,
  setAlert,
}: IAlertCommsDialogProps) {
  const [email, setEmail] = useState(alert.email || "");

  useEffect(() => {
    if (!open) {
      setEmail("");
    }
  }, [open]);

  const save = (e: FormEvent<Element>) => {
    e.preventDefault();
    e.stopPropagation();
    setAlert(
      produce(alert, (draft) => {
        draft.email = email;
      })
    );
    setOpen(false);
    return;
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>Select new comms method for {alert.name}</DialogTitle>
      <ValidatorForm onSubmit={save} name="comms">
        <DialogContent>
          <Box mb={2}>
            <TextValidator
              fullWidth
              label="Add contact email"
              validators={["required"]}
              name="email"
              value={email}
              variant="outlined"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </CustomDialog>
  );
}

export default AlertCommsDialog;
