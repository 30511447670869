/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiPermissionFromJSON, ApiPermissionToJSON, } from './ApiPermission';
import { ApiStatusFromJSON, ApiStatusToJSON, } from './ApiStatus';
export function ApiUserFromJSON(json) {
    return ApiUserFromJSONTyped(json, false);
}
export function ApiUserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'status': !exists(json, 'status') ? undefined : ApiStatusFromJSON(json['status']),
        'createDatetime': !exists(json, 'createDatetime') ? undefined : (new Date(json['createDatetime'])),
        'updatedDatetime': !exists(json, 'updatedDatetime') ? undefined : (new Date(json['updatedDatetime'])),
        'ip': !exists(json, 'ip') ? undefined : json['ip'],
        'permissions': !exists(json, 'permissions') ? undefined : (json['permissions'].map(ApiPermissionFromJSON)),
        'verifiedDatetime': !exists(json, 'verifiedDatetime') ? undefined : (new Date(json['verifiedDatetime'])),
    };
}
export function ApiUserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'password': value.password,
        'status': ApiStatusToJSON(value.status),
        'createDatetime': value.createDatetime === undefined ? undefined : (value.createDatetime.toISOString()),
        'updatedDatetime': value.updatedDatetime === undefined ? undefined : (value.updatedDatetime.toISOString()),
        'ip': value.ip,
        'permissions': value.permissions === undefined ? undefined : (value.permissions.map(ApiPermissionToJSON)),
        'verifiedDatetime': value.verifiedDatetime === undefined ? undefined : (value.verifiedDatetime.toISOString()),
    };
}
