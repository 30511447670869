import { ApiChartType } from "@incendium/api";
import { Box } from "@mui/material";
import { NewChartDialog } from "features/chartLibrary";
import { useSelectedProject } from "Hooks";
import { useReportBuilderContext } from "Providers/ReportBuilderProvider";
import { useCallback, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLayoutContext } from "Providers/LayoutProvider";
import { ChartLibraryList } from "features/chartLibrary";
import { IChart } from "Interfaces";

function ChartLibraryPage() {
  const { setPageTitle } = useLayoutContext();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { chart, setChart } = useReportBuilderContext();

  const { selectedProject, selectedClient } = useSelectedProject();

  useLayoutEffect(() => {
    setPageTitle("Chart Library");
    return () => {
      setPageTitle("");
    };
  }, [setPageTitle]);

  const onNewChart = useCallback(() => {
    setOpen(true);
    setChart({
      name: "",
      type: ApiChartType.TABLE,
      yAxisKeys: [],
      dimension: [],
      attributes: [],
      orderBy: "",
    });
  }, [setChart]);

  const onEdit = useCallback(
    (chart: IChart) => {
      history.push(
        `/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/reports/chart-library/${chart.id}`
      );
    },
    [history, selectedClient, selectedProject]
  );

  const onNewChartGo = useCallback(
    (id: number) => {
      setOpen(false);
      history.push(
        `/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/reports/chart-library/${id}`
      );
    },
    [history, selectedClient, selectedProject]
  );

  return (
    <>
      <Box mb={2}>
        <ChartLibraryList onClick={onEdit} onCreate={onNewChart} />
      </Box>

      <NewChartDialog
        open={open}
        setOpen={setOpen}
        chart={chart}
        setChart={setChart}
        onClick={onNewChartGo}
      />
    </>
  );
}

export default ChartLibraryPage;
