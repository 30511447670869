/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiScheduledReportIntervalType;
(function (ApiScheduledReportIntervalType) {
    ApiScheduledReportIntervalType["SCHEDULER_INTERVAL_NONE"] = "SchedulerIntervalNone";
    ApiScheduledReportIntervalType["SCHEDULER_INTERVAL_TYPE_DAILY"] = "SchedulerIntervalTypeDaily";
    ApiScheduledReportIntervalType["SCHEDULER_INTERVAL_TYPE_WEEKLY"] = "SchedulerIntervalTypeWeekly";
    ApiScheduledReportIntervalType["SCHEDULER_INTERVAL_TYPE_MONTHLY"] = "SchedulerIntervalTypeMonthly";
    ApiScheduledReportIntervalType["SCHEDULER_INTERVAL_TYPE_FORTNIGHTLY"] = "SchedulerIntervalTypeFortnightly";
})(ApiScheduledReportIntervalType || (ApiScheduledReportIntervalType = {}));
export function ApiScheduledReportIntervalTypeFromJSON(json) {
    return ApiScheduledReportIntervalTypeFromJSONTyped(json, false);
}
export function ApiScheduledReportIntervalTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiScheduledReportIntervalTypeToJSON(value) {
    return value;
}
