import {
  Container,
  Typography,
  Stepper,
  Step,
  Button,
  Box,
  Divider,
  Stack,
  StepButton,
} from "@mui/material";
import { GoogleAdsTrackingTemplate } from "features/utms";
import { googleIAIMapping } from "features/utms/components/GoogleAdsTrackingTemplate";
import {
  I_CAMPAIGNID,
  I_CONTENTID,
  I_MEDIUM,
  I_SOURCE,
  I_TERM,
} from "features/utms/constants";
import { useState } from "react";

const steps = [
  "Sign in to Google Ads",
  "Navigate to Account Settings",
  "Open Tracking Template",
  "Set Up Tracking Template",
  "Explanation of Parameters",
  "Save the Changes",
];

interface IGoogleAdsTrackingSetupProps {
  onFinish: () => void;
}

function GoogleAdsTrackingSetup({ onFinish }: IGoogleAdsTrackingSetupProps) {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Typography>
            Log in to your Google Ads account at{" "}
            <a
              href="https://ads.google.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Ads
            </a>
            .
          </Typography>
        );
      case 1:
        return (
          <Typography>
            Click on the <strong>Admin</strong> icon (the wrench) in the left
            hand side of the Google Ads interface. Click on{" "}
            <strong>Account settings</strong>.
          </Typography>
        );
      case 2:
        return (
          <Typography>
            In the Account settings menu, click on{" "}
            <strong>Tracking (Using URL tracking options)</strong>.
          </Typography>
        );
      case 3:
        return <GoogleAdsTrackingTemplate />;
      case 4:
        return (
          <Box>
            <Typography>
              <strong>Explanation of Parameters:</strong>
            </Typography>
            <ul>
              <li>
                <strong>{I_SOURCE}</strong>: Represents the source of the
                traffic. Set this to <code>{googleIAIMapping[I_SOURCE]}</code>.
              </li>
              <li>
                <strong>{I_MEDIUM}</strong>: Represents the medium of the
                traffic. Set this to <code>{googleIAIMapping[I_MEDIUM]}</code>.
              </li>
              <li>
                <strong>{I_CAMPAIGNID}</strong>: Dynamically inserts the
                campaign ID. Use <code>{googleIAIMapping[I_CAMPAIGNID]}</code>.
              </li>
              <li>
                <strong>{I_CONTENTID}</strong>: Dynamically inserts the ad group
                ID. Use <code>{googleIAIMapping[I_CONTENTID]}</code>.
              </li>
              <li>
                <strong>{I_TERM}</strong>: Dynamically inserts the term or
                keyword that triggered the ad. Use{" "}
                <code>{googleIAIMapping[I_TERM]}</code>.
              </li>
            </ul>
          </Box>
        );
      case 5:
        return (
          <Typography>
            Click <strong>Save</strong> to apply the tracking template to your
            account.
          </Typography>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Container>
      <Typography variant="h4" align="center" mb={4}>
        Setting Up Account-Level Incendium Tracking Parameters in Google Ads
      </Typography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, i) => (
          <Step key={label} disabled={false}>
            <StepButton onClick={() => setActiveStep(i)}>{label}</StepButton>
          </Step>
        ))}
      </Stepper>
      <Stack mt={4} spacing={4}>
        {renderStepContent(activeStep)}
        <Divider />
        <Box>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={activeStep === steps.length - 1 ? onFinish : handleNext}
          >
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </Button>
        </Box>
      </Stack>
    </Container>
  );
}

export default GoogleAdsTrackingSetup;
