import {
  ApiSimpleUTM,
  UTMServiceUpdateUTMSyncTypeEnum,
  UTMServiceCreateUTMSyncTypeEnum,
  ApiSyncType,
} from "@incendium/api";
import { utmService } from "Apis";

export const saveUTM = async (projectId: number, utm: ApiSimpleUTM) => {
  let syncType = ApiSyncType.UNSPECIFIED;
  if (utm.syncType) {
    syncType = utm.syncType;
  }
  const { id, ...payload } = utm;
  const fn =
    id && id > 0
      ? utmService.uTMServiceUpdateUTM({
          utmId: id,
          projectId,
          syncType: `${syncType}` as UTMServiceUpdateUTMSyncTypeEnum,
          payload,
        })
      : utmService.uTMServiceCreateUTM({
          projectId,
          syncType: `${syncType}` as UTMServiceCreateUTMSyncTypeEnum,
          payload,
        });
  const res = await fn;
  return res.utm;
};

export const bulkSaveUTMs = async (projectId: number, utms: ApiSimpleUTM[]) => {
  const res = await Promise.all(utms.map((u) => saveUTM(projectId, u)));
  return res;
};

export const deleteUTM = async (projectId: number, id: number) => {
  await utmService.uTMServiceDeleteUTM({
    projectId,
    utmId: id,
  });
  return id;
};

export const bulkDeleteUTMs = async (projectId: number, ids: number[]) => {
  const res = await Promise.all(ids.map((id) => deleteUTM(projectId, id)));
  return res;
};

export const listSources = async (projectId: number) => {
  const res = await utmService.uTMServiceListSourceValues({ projectId });
  return res.results;
};
