/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiCountryFromJSON, ApiCountryToJSON, } from './ApiCountry';
export function ApiRegionFromJSON(json) {
    return ApiRegionFromJSONTyped(json, false);
}
export function ApiRegionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'country': !exists(json, 'country') ? undefined : ApiCountryFromJSON(json['country']),
        'countryId': !exists(json, 'countryId') ? undefined : json['countryId'],
    };
}
export function ApiRegionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'country': ApiCountryToJSON(value.country),
        'countryId': value.countryId,
    };
}
