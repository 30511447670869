import { ApiProject } from "@incendium/api";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { addProject, updateProject } from "Actions";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import TimezoneSelect from "Components/TimezoneSelect/TimezoneSelect";
import { GenericDialoagProps } from "Interfaces";
import { ProjectDomains, saveProject } from "features/project";
import { useNotification } from "Hooks";

interface IProjectDialogProps extends GenericDialoagProps {
  project?: ApiProject;
}

function ProjectDialog({ project, open, setOpen }: IProjectDialogProps) {
  const [name, setName] = useState(project?.name || "");
  const [domains, setDomains] = useState(
    (project?.domains || []).filter((d) => d.isPrimary) || []
  );
  const [sitemap, setSitemap] = useState(project?.sitemap || "");
  const [timezone, setTimezone] = useState(project?.timezone || "");
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const { clientId } = useParams<{ clientId: string }>();
  const { showSuccessNotification, showErrorNotification } = useNotification();

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onSubmit = useCallback(async () => {
    setDisabled(true);
    try {
      const res = await saveProject(parseInt(clientId), {
        id: project?.id,
        name,
        sitemap,
        timezone,
        domains,
      });
      if (project?.id) {
        dispatch(updateProject(res));
      } else {
        dispatch(addProject(res));
      }
      showSuccessNotification(`${res.name} Saved`);
    } catch (e) {
      showErrorNotification("Internal Server Error");
    } finally {
      setDisabled(false);
      onClose();
    }
  }, [
    project,
    clientId,
    showSuccessNotification,
    showErrorNotification,
    dispatch,
    name,
    onClose,
    sitemap,
    timezone,
    domains,
  ]);

  return (
    <CustomDialog onClose={onClose} fullWidth maxWidth="sm" open={open}>
      <ValidatorForm onSubmit={onSubmit}>
        <DialogTitle>
          {project && project.id && project.id > 0
            ? `Update ${project.name}`
            : "Add a new Project"}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextValidator
              variant="outlined"
              fullWidth
              name="project name"
              label="Project name *"
              helperText="e.g. incendium"
              value={name}
              disabled={disabled}
              validators={["required", "minStringLength:4"]}
              size="small"
              errorMessages={[
                "Project Name is Required",
                "Project Name Must be Over 3 Characters Long",
              ]}
              onChange={(e: any) => setName(e.target.value)}
            />

            <TimezoneSelect
              value={timezone}
              fullWidth
              size="small"
              variant="outlined"
              helperText="Select Project timezone, this will override the clients timezone."
              label="Select Project Timezone"
              onChange={(e) => setTimezone(e.target.value)}
            />

            <TextValidator
              variant="outlined"
              placeholder="http://mydomain.com/path/to/sitemap.xls"
              fullWidth
              name="sitemap"
              label="Sitemap"
              helperText="This is a url to sitemap XLS"
              validators={["isDomain"]}
              errorMessages={["Enter a valid domain"]}
              value={sitemap}
              size="small"
              disabled={disabled}
              onChange={(e: any) => setSitemap(e.target.value)}
            />
          </Stack>
        </DialogContent>
        <Divider />

        <DialogContent>
          <Typography variant="subtitle2">
            Manage your projects domains
          </Typography>
          <Typography variant="body2" mb={3}>
            These are the domain you shall run incendium from
          </Typography>
          <ProjectDomains domains={domains} setDomains={setDomains} />
        </DialogContent>
        <DialogActions>
          <Button disabled={disabled} onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button
            disabled={disabled}
            variant="contained"
            color="primary"
            type="submit"
          >
            {project ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </ValidatorForm>
    </CustomDialog>
  );
}

export default ProjectDialog;
