import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { motion } from "framer-motion";

const useFullPageStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    // padding: theme.spacing(0, 3),
  },
}));

function FullWidthPage({ children }: React.HTMLAttributes<HTMLDivElement>) {
  const { root } = useFullPageStyles();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          delay: 0.1,
        },
      }}
      className={root}
    >
      {children}
    </motion.div>
  );
}

export default FullWidthPage;
