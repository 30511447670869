import {
  ApiChartTemplate,
  ApiChartType,
  ApiConversion,
  ApiConversionType,
  ApiDimension,
  ApiMetric,
  ApiProjectDefault,
  ApiReservedConversion,
  ApiYAxisChartType,
} from "@incendium/api";
import { Delete, QuestionMark } from "@mui/icons-material";
import {
  IconButton,
  Link,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { locationService } from "Apis";
import GlassCard from "Components/GlassCard/GlassCard";
import { LocationTabs } from "Components/TagPages";
import SpacedList, { SpacedLinkButton } from "Components/UI/SpacedList";
import { StyledDrawerContainer } from "Components/UI/StyledDrawer";
import { sortAlpha, sortNum } from "Helpers/arrays";
import { friendlyDateTime } from "Helpers/dates";
import { formatEnumVal } from "Helpers/enumToText";
import withInfoColumn from "HoC/withInfoColumn";
import { useSelectedProject } from "Hooks";
import { useConversions } from "Hooks/useConversions";
import { useDeleteConfirmation } from "Hooks/useDeleteConfirmation";
import useProjectTimezone from "Hooks/useProjectTimezone";
import produce from "immer";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { AnalyticsCard, AnalyticsChartStatCard } from "features/analytics";
import { AccordianChartBuilderSidebarBlock } from "features/chartLibrary";

function ConversionsPageMain() {
  const { selectedClient } = useSelectedProject();
  const { conversions, setConversions } = useConversions();
  const handleDelete = useDeleteConfirmation();
  const history = useHistory();
  const timezone = useProjectTimezone();

  const orderedConversions = useMemo(() => {
    return sortAlpha(sortNum(conversions, "location.id"), "location.name");
  }, [conversions]);

  const onDelete = useCallback(
    (conversion: ApiConversion) => {
      if (!conversion.id) {
        return;
      }
      handleDelete({
        title: `Are you sure you want to delete this conversion`,
        body: `This action can not be undone`,
        callback: async () => {
          await locationService.locationServiceDeleteConversion({
            locationId: conversion.locationId as number,
            projectId: conversion.projectId as number,
            clientId: selectedClient?.id as number,
            conversionId: conversion.id as number,
          });
          setConversions(
            produce(conversions, (draft) => {
              const idx = draft.findIndex((d) => d.id === conversion.id);
              if (idx >= 0) {
                draft.splice(idx, 1);
              }
            })
          );
          return "Conversion deleted";
        },
      });
    },
    [selectedClient, setConversions, conversions, handleDelete]
  );

  const onNew = useCallback(
    (locationId: number) => {
      history.push(
        `../train/locations/${locationId}/location-tagging?tab=${
          "Conversion Tab" as LocationTabs
        }`
      );
    },
    [history]
  );
  const onEdit = useCallback(
    (conversion: ApiConversion) => {
      history.push(
        `../train/locations/${conversion.locationId}/location-tagging?tab=${
          "Conversion Tab" as LocationTabs
        }`,
        conversion
      );
    },
    [history]
  );

  return (
    <>
      <SpacedList title="Conversions List" height={"calc(100vh - 170px)"}>
        <TableHead>
          <TableRow>
            <TableCell>Conversion Name</TableCell>
            <TableCell>Location Name</TableCell>
            <TableCell>Conversion Type</TableCell>
            <TableCell>Last Fired</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(orderedConversions || []).map((conversion) => (
            <TableRow key={conversion.id}>
              <TableCell>
                <SpacedLinkButton onClick={() => onEdit(conversion)}>
                  {conversion.name || ""}
                </SpacedLinkButton>

                {conversion.reserved !== ApiReservedConversion.NOT_RESERVED && (
                  <Typography variant="body2">
                    (Reserved : {formatEnumVal(conversion.reserved || "")})
                  </Typography>
                )}
              </TableCell>
              <TableCell>
                <Typography variant="body1" noWrap>
                  <Link
                    color={"inherit"}
                    onClick={() => onNew(conversion.location!.id as number)}
                  >
                    {conversion.location?.name}
                  </Link>
                </Typography>
              </TableCell>
              <TableCell width={200}>
                <Typography variant="body1" noWrap>
                  {conversion.type === ApiConversionType.MACRO
                    ? "Conversion"
                    : "Interaction"}
                </Typography>
              </TableCell>

              <TableCell width={250}>
                <Typography variant="body1" noWrap>
                  Last Fired :
                  {conversion.lastFiredAt
                    ? friendlyDateTime(conversion.lastFiredAt, timezone)
                    : "-"}
                </Typography>
              </TableCell>
              <TableCell>
                {conversion._default !== ApiProjectDefault.UNSPECIFIED ? (
                  <Tooltip
                    arrow
                    title="This is a system default conversion that cannot be deleted"
                  >
                    <div>
                      <IconButton disabled size="small" color="secondary">
                        <Delete />
                      </IconButton>
                    </div>
                  </Tooltip>
                ) : (
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => onDelete(conversion)}
                  >
                    <Delete />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </SpacedList>
    </>
  );
}

const ConversionsPageWithInfo = withInfoColumn(ConversionsPageMain);

function ConversionsPage() {
  return (
    <ConversionsPageWithInfo
      infoColumn={
        <>
          <AccordianChartBuilderSidebarBlock
            title="Conversions Help"
            subTitle="Conversions track important events that happen on your site."
            icon={QuestionMark}
            defaultClosed
          >
            <GlassCard boxProps={{ p: 2 }} noShadow>
              <Typography variant="body1">
                We have 2 types of conversions
              </Typography>
              <ul>
                <li>
                  Conversions - These should be used for your main goals such as
                  a booking, sale, an enquiry submission etc.
                </li>
                <li>
                  Interactions - These should be used for smaller but still
                  notable events. For example, someone interacting with an image
                  gallery on a product page, or clicking a 'Read More' link on a
                  blog category page.
                </li>
              </ul>
              <Typography variant="body1">
                Conversions are linked to Locations. This is so that you only
                need to set them up once for a particular type of page/page
                template and not for every URL!
              </Typography>
              <ul>
                <li>
                  Click the location name to go to the Conversions tab for that
                  location, here you can add new conversions or update an
                  existing conversion.
                </li>
                <li>Click the conversion name to go to it.</li>
                <li>Click the delete icon to remove the conversion.</li>
              </ul>
            </GlassCard>
          </AccordianChartBuilderSidebarBlock>

          <StyledDrawerContainer>
            <Stack spacing={2}>
              <AnalyticsChartStatCard
                topDimension={ApiDimension.DIMENSION_MACRO_CONVERSION}
                chart={{
                  name: "Top Fired Conversion",
                  dimension: [ApiDimension.DIMENSION_MACRO_CONVERSION],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.STAT_CARD,
                  comparison: true,
                  attributes: [],
                  displayOptions: {
                    statTitle: "Top Fired Conversion",
                  },
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT],
                    },
                  ],
                }}
                noToolbar
              />
              <AnalyticsChartStatCard
                topDimension={ApiDimension.DIMENSION_MICRO_CONVERSION}
                chart={{
                  name: "Top Fired Interacion",
                  dimension: [ApiDimension.DIMENSION_MICRO_CONVERSION],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.STAT_CARD,
                  comparison: true,
                  attributes: [],
                  displayOptions: {
                    statTitle: "Top Fired Interacion",
                  },
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT],
                    },
                  ],
                }}
                noToolbar
              />
              <AnalyticsCard
                height={380}
                chart={{
                  name: "Conversions by Day",
                  dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
                  template: ApiChartTemplate.TREND,
                  type: ApiChartType.GRAPH,
                  attributes: [],
                  displayOptions: {
                    noLegend: true,
                  },
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT],
                      chart: {
                        [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT]:
                          ApiYAxisChartType.BAR,
                      },
                    },
                  ],
                }}
                noToolbar
              />
            </Stack>
          </StyledDrawerContainer>
        </>
      }
    />
  );
}

export default ConversionsPage;
