import { useCallback, useMemo } from "react";
import { Box, Link, Typography } from "@mui/material";
import { StyledButtonNoTextTransform } from "Components/Button/StyledButtons";
import Loading from "Components/Loading/Loading";
import {
  formatMetric,
  metricIcon,
  metricToName,
  useSalesFlowData,
} from "features/salesFlow";
import { motion } from "framer-motion";
import { Link as RouterLink } from "react-router-dom";
import { useSelectedProject } from "Hooks";
import { useFromToContext } from "Providers/FromToProvider";
import { TSalesType } from "Providers/SalesFunnelProvider";
import { ApiSalesFlowMetric } from "@incendium/api";
import { percentageChange } from "Helpers/percentage";
import { isEmpty } from "Helpers/isEmpty";
import { formatNumber } from "Helpers/numbers";

interface ISaleFlowFunnelBlockBodyProps {
  status: TSalesType;
  statuses: TSalesType[];
  chartData: any;
  metric: ApiSalesFlowMetric;
  icon?: any;
}

const SaleFlowFunnelBlockBody = ({
  metric,
  status,
  statuses,
  chartData,
  icon: Icon,
}: ISaleFlowFunnelBlockBodyProps) => {
  const statusIdx = useMemo(
    () => statuses.findIndex((s) => s.type === status.type),
    [status, statuses]
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
      mb={2}
    >
      <Box mr={1} display="flex">
        <Icon />
      </Box>
      <Typography variant="body2">
        {metricToName(metric, status.name, statuses[statusIdx - 1]?.name || "")}
        : <b>{formatMetric(metric, chartData[metric])}</b>
      </Typography>
    </Box>
  );
};

interface IBlockProps extends React.HTMLAttributes<HTMLDivElement> {
  idx: number;
  width: string;
  totalSize: number;
  status: TSalesType;
  statuses: TSalesType[];
  closedFunnel: boolean;
}

const SaleFlowFunnelBlock = ({
  idx,
  width,
  status,
  statuses,
  totalSize,
  closedFunnel,
}: IBlockProps) => {
  const { selectedClient, selectedProject } = useSelectedProject();
  const { chartOutput, chartComparisonOutput, comparisonWindowDays } =
    useFromToContext();

  const { chartData, loading } = useSalesFlowData(
    selectedProject,
    chartOutput,
    status.type,
    closedFunnel
  );
  const { chartData: compChartData, loading: compLoading } = useSalesFlowData(
    selectedProject,
    chartComparisonOutput,
    status.type,
    closedFunnel
  );

  const slopePercentage = 4;
  const scale = `${(idx + 1) / 2}`.replace(/\./g, "");

  const getHeight = (h: number, idx: number) => {
    for (let index = 0; index < idx; index++) {
      const perc = (h / 100) * slopePercentage;
      h = h - perc * 2;
    }
    return h;
  };

  const height = useMemo(() => {
    return getHeight(770, idx);
  }, [idx]);

  const innerHeight = useMemo(() => {
    return getHeight(770, totalSize);
  }, [totalSize]);

  const changed = useCallback(
    (metric: ApiSalesFlowMetric) =>
      !compChartData || !chartData
        ? 0
        : percentageChange(
            Number(compChartData[metric]),
            Number(chartData[metric])
          ),
    [chartData, compChartData]
  );

  return (
    <Box
      key={idx}
      mr={idx === 4 ? 0 : 1}
      component={motion.div}
      variants={{
        hidden: { x: -20, opacity: 0 },
        show: { x: 0, opacity: 1 },
      }}
      whileHover={{
        scale: `1.${scale}`,
      }}
      sx={{
        filter: "drop-shadow(3px 3px 6px #5E65E526)",
        display: "flex",
        alignItems: "center",
        transition: "all 0.3s",
        height: 705,
        width: width,
        position: "relative",
        pointerEvents: "auto",
        "&:hover": {
          zIndex: 999,
          filter: "drop-shadow(0px 0px 6px #5E65E526)",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: { height },
          background: "white",
          clipPath:
            idx === 4
              ? ""
              : `polygon(0 0, 100% ${slopePercentage}%, 100% ${
                  100 - slopePercentage
                }%, 0% 100%)`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderTopLeftRadius: idx === 0 ? 10 : 0,
          borderBottomLeftRadius: idx === 0 ? 10 : 0,
          borderTopRightRadius: idx === 4 ? 10 : 0,
          borderBottomRightRadius: idx === 4 ? 10 : 0,
          "&:before": {
            content: "''",
            height: 9,
            width:
              idx === 0
                ? "calc(90% - 13px)"
                : idx === 4
                ? "calc(90% - 14px)"
                : "100%",
            right: idx === 0 ? 0 : "inherit",
            left: idx === 4 ? 0 : "inherit",
            background: "#7C97C6",
            position: "absolute",
            // top: 265,
            top: 210,
            opacity: 0.6,
          },
          "&:after": {
            display: idx === 0 || idx === 4 ? "block" : "none",
            content: "''",
            background: idx === 0 ? "#7C97C6" : "transparent",
            // top: 265,
            top: 210,
            borderRadius: idx === 0 ? "50%" : 0,
            height: idx === 0 ? 28 : 0,
            width: idx === 0 ? 28 : 0,
            position: "absolute",

            borderTop: idx === 4 ? `14px solid transparent` : "none",
            borderBottom: idx === 4 ? `14px solid transparent` : "none",
            borderLeft: idx === 4 ? `28px solid #7C97C6` : "none",
            left: idx === 0 ? "10%" : "inherit",
            right: idx === 4 ? "10%" : "inherit",
            transform:
              idx === 0 ? "translate(-50%, -9px)" : "translate(50%, -9px)",
            opacity: 0.6,
          },
          "&:hover:before,&:hover:after": {
            display: "none",
          },
        }}
      >
        <Box sx={{ height: innerHeight }}>
          {loading || !chartData ? (
            <Loading sx={{ height: 26 }} />
          ) : (
            <Box
              component={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              sx={{
                width: 200,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                position: "relative",
                background: "white",
              }}
            >
              <Box sx={{ minHeight: 83 }}>
                <Typography variant="h4" color={"text.primary"}>
                  <Typography
                    variant="h1"
                    component={"span"}
                    display={"inline"}
                    mb={1}
                  >
                    {formatMetric(
                      ApiSalesFlowMetric.LEADS,
                      Number(chartData[ApiSalesFlowMetric.LEADS])
                    )}
                  </Typography>
                  {"  "}
                  {status.name}s
                </Typography>
                {compLoading ? (
                  <Loading />
                ) : (
                  <Typography variant="subtitle1" color={"text.secondary"}>
                    <Typography
                      variant="h2"
                      component={"span"}
                      color={
                        changed(ApiSalesFlowMetric.LEADS) >= 0
                          ? "success.main"
                          : "error.main"
                      }
                      display={"inline"}
                    >
                      {formatNumber(
                        Math.abs(changed(ApiSalesFlowMetric.LEADS))
                      )}
                      %
                    </Typography>{" "}
                    {changed(ApiSalesFlowMetric.LEADS) >= 0
                      ? "increase"
                      : changed(ApiSalesFlowMetric.LEADS) < 0
                      ? "decrease"
                      : "no change"}
                  </Typography>
                )}
                <Typography variant="body2" color={"text.secondary"}>
                  compared to previous {comparisonWindowDays} days
                </Typography>
              </Box>

              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  top: 120,
                  width: 156,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  color: "white",
                }}
              >
                {!true && (
                  <>
                    <Box mb={1}>
                      {status.title ? (
                        <Typography variant="subtitle2" lineHeight={"20px"}>
                          {status.title}
                        </Typography>
                      ) : (
                        <Box sx={{ height: 21 }}></Box>
                      )}
                    </Box>

                    <StyledButtonNoTextTransform
                      size="large"
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={{
                        transform: `scale(1)`,
                        zIndex: 2,
                      }}
                    >
                      <Link
                        to={`/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/sales-flow-analysis-report/${status.path}`}
                        color="inherit"
                        component={RouterLink}
                      >
                        {status.short}
                      </Link>
                    </StyledButtonNoTextTransform>
                  </>
                )}
                <Box mt={5.5}>
                  {!isEmpty(chartData) &&
                    status.highlightMetrics.map((key) => (
                      <SaleFlowFunnelBlockBody
                        key={key}
                        metric={key as ApiSalesFlowMetric}
                        status={status}
                        statuses={statuses}
                        icon={metricIcon(key as ApiSalesFlowMetric)}
                        chartData={chartData}
                      />
                    ))}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SaleFlowFunnelBlock;
