/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiCrawlerType;
(function (ApiCrawlerType) {
    ApiCrawlerType["CRAWLER_TYPE_UNSPECIFIED"] = "CrawlerTypeUnspecified";
    ApiCrawlerType["CRAWLER_TYPE_USER_AGENT"] = "CrawlerTypeUserAgent";
    ApiCrawlerType["CRAWLER_TYPE_FLAGGER"] = "CrawlerTypeFlagger";
})(ApiCrawlerType || (ApiCrawlerType = {}));
export function ApiCrawlerTypeFromJSON(json) {
    return ApiCrawlerTypeFromJSONTyped(json, false);
}
export function ApiCrawlerTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiCrawlerTypeToJSON(value) {
    return value;
}
