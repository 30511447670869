import {
  alpha,
  Box,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import produce from "immer";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { AnalyticsCardTitle } from "Components/Analytics/AnalyticsCard";
import {
  ApiChartType,
  ApiClient,
  ApiElementPosition,
  ApiProject,
  ApiReport,
  ApiTabElementResponse,
  ApiTabElementType,
  ApiTabResponse,
} from "@incendium/api";
import ChartLibraryDialog from "./ChartLibraryDialog";
import { IChart } from "Interfaces";
import { reportService } from "Apis";
import { useDebounce, useUpdateEffect } from "react-use";
import { parseContextChartFromTabChart } from "Hooks/useCharts";
import { Delete, Edit } from "@mui/icons-material";
import NameAndDescriptionDialog from "Components/NameAndDescriptionDialog/NameAndDescriptionDialog";
import { useTheme } from "@mui/styles";
import { useFromToContext } from "Providers/FromToProvider";
import { AnalyticsCard, AnalyticsChartStatCard } from "features/analytics";
import { downloadReport } from "features/reports";
import { useNotification } from "Hooks";
import { useReportBuilderContext } from "Providers/ReportBuilderProvider";

const ReactGridLayout = memo(WidthProvider(RGL));
const rowHeight = 53;

export const FixedReactGridLayout = styled(ReactGridLayout)(({ theme }) => ({
  "& .react-grid-item.react-grid-placeholder": {
    pointerEvents: "none",
    background: alpha(theme.palette.primary.main, 0.7),
    border: `2px solid ${theme.palette.primary.main}`,
    opacity: 1,
    borderRadius: theme.shape.borderRadius,
  },
}));

FixedReactGridLayout.defaultProps = {
  margin: [16, 16],
};

export interface ILayout extends RGL.Layout {
  inner?: ILayout[];
  id?: number;
  parentId?: number;
  element?: ApiTabElementResponse;
  type?: ApiTabElementType;
  name?: string;
  description?: string;
}

const Item = memo(
  ({
    l,
    onRemove,
    onEdit,
    editMode,
    report,
    tab,
    project,
    client,
  }: {
    l: ILayout;
    onRemove: (l: ILayout) => void;
    onEdit: (l: ILayout) => void;
    editMode: boolean;
    report: ApiReport;
    tab: ApiTabResponse;
    project: ApiProject;
    client: ApiClient;
  }) => {
    const { showSuccessNotification, showErrorNotification } =
      useNotification();
    const { from, to, lastNDays } = useFromToContext();
    const chart = useMemo(() => {
      return parseContextChartFromTabChart(l.element?.chart || {});
    }, [l]);

    const edit = useCallback(async () => {
      await onEdit(l);
    }, [l, onEdit]);

    const remove = useCallback(async () => {
      await onRemove(l);
    }, [l, onRemove]);

    const onExport = async () => {
      try {
        await downloadReport(
          project?.id as number,
          report.id as number,
          tab.id as number,
          l.element?.chart?.id as number,
          {
            from: from
              ? from?.clone().utc().startOf("day").toDate()
              : undefined,
            to: to ? to?.clone().utc().endOf("day").toDate() : undefined,
            lastNDays: lastNDays ? lastNDays : undefined,
            timezone: client?.timezone,
          },
          l.element?.chart?.chart?.name
        );
        showSuccessNotification("Chart Exported");
      } catch (error) {
        showErrorNotification("Internal Server Error");
      }
    };

    if (chart.type === ApiChartType.STAT_CARD) {
      return (
        <AnalyticsChartStatCard
          chart={chart}
          onEdit={edit}
          fullHeight
          onDelete={remove}
          noToolbar={!editMode}
        />
      );
    }

    return (
      <AnalyticsCard
        chart={chart}
        onEdit={edit}
        onDelete={remove}
        onExport={onExport}
      />
    );
  }
);

export const InnerGrid = ({
  layout,
  setLayout,
  inner,
  l,
  project,
  client,
  onDrop,
  onDelete,
  onEdit,
  editMode,
  report,
  tab,
}: {
  layout: ILayout[];
  setLayout: (value: React.SetStateAction<ILayout[]>) => void;
  inner: RGL.Layout[];
  l: ILayout;
  project: ApiProject;
  client: ApiClient;
  onDrop: (lay: RGL.Layout[], item: ILayout, e: any) => void;
  onDelete: (id: number, fn: () => void) => Promise<void>;
  onEdit: (l: ILayout) => void;
  editMode: boolean;
  report: ApiReport;
  tab: ApiTabResponse;
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [height, setHeight] = useState(60);
  let timeout: any;
  const titleRef = useRef<HTMLElement>();
  const [open, setOpen] = useState(false);
  const { gridDimensions } = useReportBuilderContext();

  const onInnerLayoutChange = (newLayout: RGL.Layout[]) => {
    if (isDragging) {
      return;
    }

    setLayout(
      produce(layout, (draft) => {
        const idx = draft.findIndex((d) => d.i === l.i);
        if (idx < 0) {
          return;
        }
        const dInner = draft[idx].inner || [];

        dInner.forEach((l, i) => {
          const vIdx = newLayout.findIndex((nl) => nl.i === l.i);
          if (vIdx >= 0) {
            dInner[i] = {
              ...dInner[i],
              ...newLayout[vIdx],
            };
          }
        });
      })
    );
  };

  const lowestItem = useMemo(() => {
    if (isDragging) {
      return;
    }

    // get max height
    // max height is the lowest reaching block, this could be on a new line or just tall
    return [...inner].sort((a, b) => {
      const ah = a.y + a.h;
      const bh = b.y + b.h;
      if (ah === bh) return 0;
      return ah > bh ? -1 : 1;
    })[0];
  }, [inner, isDragging]);

  useEffect(() => {
    if (isDragging || !lowestItem) {
      return;
    }

    // do height
    const rows = lowestItem.y + lowestItem.h;
    const titleH = titleRef?.current?.offsetHeight || 0;
    setHeight((rowHeight * rows - titleH) / rows);
  }, [lowestItem, isDragging]);

  const onInnerDrop = (lay: RGL.Layout[], item: RGL.Layout, e: any) => {
    let key = e.dataTransfer.getData("dragData");
    if (key === ApiTabElementType.BLOCK) {
      alert("You cant nest a block into a block");
    } else {
      onDrop(lay, { ...item, parentId: l.id }, e);
    }

    setTimeout(() => {
      setIsDragging(false);
    }, 1);
  };

  const cols = useMemo(() => {
    return l.w;
  }, [l]);

  const onRemove = (toRemove: ILayout) => {
    onDelete(toRemove.id as number, () =>
      setLayout(
        produce(layout, (draft) => {
          const idx = draft.findIndex((d) => d.i === l.i);
          if (idx < 0) {
            return;
          }
          if (toRemove.type === ApiTabElementType.BLOCK) {
            draft.splice(idx, 1);
            return;
          }

          const vIdx = (draft[idx].inner || []).findIndex(
            (d) => d.i === toRemove.i
          );

          if (vIdx >= 0) {
            draft[idx].inner?.splice(vIdx, 1);
          }
        })
      )
    );
  };

  const onBlockUpdate = async (name: string, description: string) => {
    const res = await reportService.reportServiceUpdateElement({
      projectId: project.id as number,
      reportId: report.id as number,
      tabId: tab.id as number,
      elementId: l.id as number,
      payload: {
        name,
        description,
      },
    });

    setLayout(
      produce(layout, (draft) => {
        const idx = draft.findIndex((d) => d.id === res.id);
        if (idx >= 0) {
          draft[idx] = {
            ...draft[idx],
            name: res.name,
            description: res.description,
          };
        }
      })
    );
  };

  return (
    <>
      {editMode && (
        <Stack
          direction={"row"}
          spacing={1}
          sx={{
            position: "absolute",
            top: -22,
            zIndex: 999,
            right: 0,
          }}
        >
          <Box
            sx={{
              background: "white",
              borderRadius: "50%",
            }}
          >
            <IconButton onClick={() => setOpen(true)}>
              <Edit />
            </IconButton>
          </Box>
          <Box
            sx={{
              background: "white",
              borderRadius: "50%",
            }}
          >
            <IconButton onClick={() => onRemove(l)}>
              <Delete />
            </IconButton>
          </Box>
        </Stack>
      )}
      {(l.name || l.description) && (
        <Box p={1} ref={titleRef}>
          {l.name && <AnalyticsCardTitle title={l.name || ""} />}
          {l.description &&
            l.description.split("\n").map((s, i) => (
              <Typography key={i} variant="body1">
                {s}
              </Typography>
            ))}
        </Box>
      )}

      <FixedReactGridLayout
        style={{
          minHeight: "100%",
        }}
        layout={inner}
        rowHeight={height}
        cols={cols}
        containerPadding={[0, 0]}
        maxRows={l.h}
        isDroppable={editMode}
        isResizable={editMode}
        isDraggable={editMode}
        useCSSTransforms={true}
        onDropDragOver={(e) => {
          if (isDragging) {
            return;
          }
          setIsDragging(true);
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            setIsDragging(false);
          }, 1000);
          if (gridDimensions) {
            return gridDimensions;
          }
          return { w: 4, h: 4 };
        }}
        onDrop={onInnerDrop}
        isBounded={true}
        onDragStart={(a, b, c, d, e) => e.stopPropagation()}
        onLayoutChange={onInnerLayoutChange}
      >
        {inner.map((inn) => (
          <Box key={inn.i}>
            <Item
              l={inn}
              onRemove={() => onRemove(inn)}
              onEdit={() => onEdit(inn)}
              editMode={editMode}
              report={report}
              tab={tab}
              project={project}
              client={client}
            />
          </Box>
        ))}
      </FixedReactGridLayout>
      <NameAndDescriptionDialog
        title="Set Name and Description for block"
        open={open}
        setOpen={setOpen}
        onSaved={onBlockUpdate}
        name={l.name}
        description={l.description}
      />
    </>
  );
};

interface IReportBuilderProps {
  layout: ILayout[];
  setLayout: React.Dispatch<React.SetStateAction<ILayout[]>>;
  project: ApiProject;
  client: ApiClient;
  report: ApiReport;
  tab: ApiTabResponse;
  editElement: (element: ApiTabElementResponse) => void;
  editMode: boolean;
}

function ReportBuilder({
  layout,
  setLayout,
  project,
  client,
  report,
  tab,
  editElement,
  editMode,
}: IReportBuilderProps) {
  let timeout: any;
  const theme = useTheme();
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const onRefChange = useCallback((node) => {
    setRef(node);
  }, []);
  const [isDragging, setIsDragging] = useState(false);
  const [containerMinHeight, setContainerMinHeight] = useState(700);
  const { gridDimensions } = useReportBuilderContext();
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const [open, setOpen] = useState(false);
  const [droppedItem, setDroppedItem] = useState<ILayout | undefined>(
    undefined
  );
  const [debouncedLayout, setDebouncedLayout] = useState<ILayout[]>(layout);
  useDebounce(
    () => {
      setDebouncedLayout(layout);
    },
    300,
    [layout]
  );

  const onLayoutChange = useCallback(
    (newLayout: RGL.Layout[]) => {
      if (!newLayout || newLayout.length === 0 || isDragging) {
        return;
      }

      setContainerMinHeight(700);

      setLayout(
        produce(layout, (draft) => {
          layout.forEach((l, i) => {
            const vIdx = newLayout.findIndex((nl) => nl.i === l.i);
            if (vIdx >= 0) {
              draft[i] = {
                ...draft[i],
                ...newLayout[vIdx],
              };
            }
          });
        })
      );
    },
    [isDragging, layout, setLayout]
  );

  const onDrop = useCallback(
    (lay: RGL.Layout[], item: ILayout, e: any) => {
      onLayoutChange(lay);
      // open chart selector
      let key: ApiTabElementType = e.dataTransfer.getData("dragData");
      if (key === ApiTabElementType.BLOCK) {
        setDroppedItem({ ...item, type: ApiTabElementType.BLOCK });
      } else {
        setOpen(true);
        setDroppedItem({ ...item, type: key });
      }

      return;
    },
    [onLayoutChange]
  );

  useUpdateEffect(() => {
    if (
      !droppedItem ||
      droppedItem.type === ApiTabElementType.CHART ||
      droppedItem.type === ApiTabElementType.STAT
    ) {
      return;
    }
    addElement();
  }, [droppedItem]);

  const addElement = useCallback(
    async (chart?: IChart) => {
      if (!droppedItem) {
        return;
      }

      const req = {
        projectId: project.id as number,
        reportId: report.id as number,
        tabId: tab.id as number,
        payload: {
          type: droppedItem.type,
          chart: {
            chartId: chart?.id as number,
          },
        },
      };

      const res = droppedItem.parentId
        ? await reportService.reportServiceAddElementToElement({
            ...req,
            elementId: droppedItem.parentId as number,
            parentId: droppedItem.parentId as number, // why do we need this
          })
        : await reportService.reportServiceAddElementToTab({ ...req });

      setLayout(
        produce(layout, (draft) => {
          let items = draft;
          if (droppedItem.parentId) {
            const idx = (draft || []).findIndex(
              (d) => d.id === droppedItem.parentId
            );

            if (idx >= 0) {
              if (!draft[idx].inner) {
                draft[idx].inner = [];
              }
              items = draft[idx].inner as ILayout[];
            }
          }

          let key = `${res.id}`;
          // check if key already used
          const found = items
            .filter((f) => f.i.startsWith(key))
            .map((f) => f.i.split("_")[1] || 0)
            .sort((a, b) => {
              if (a === b) return 0;
              return a > b ? -1 : 1;
            });
          if (found.length > 0) {
            key = `${key}_${Number(found[0]) + 1}`;
          }

          items.push({
            ...droppedItem,
            i: key,
            id: res.id as number,
            element: res,
            type: res.type,
          });
        })
      );
      setDroppedItem(undefined);
      setOpen(false);
    },
    [droppedItem, layout, project, report.id, tab.id, setLayout]
  );

  const onDelete = useCallback(
    async (id: number, fn: () => void) => {
      try {
        await reportService.reportServiceDeleteElement({
          projectId: project.id as number,
          reportId: report.id as number,
          tabId: tab.id as number,
          elementId: id,
        });
        fn();
        showSuccessNotification("Chart Removed");
      } catch (error) {
        showErrorNotification("There was an internal error, please try again");
      }
    },
    [
      project.id,
      report.id,
      tab.id,
      showSuccessNotification,
      showErrorNotification,
    ]
  );

  const onRemove = useCallback(
    (l: ILayout) => {
      onDelete(l.id as number, () =>
        setLayout(
          produce(layout, (draft) => {
            const idx = draft.findIndex((d) => d.i === l.i);
            if (idx >= 0) {
              draft.splice(idx, 1);
            }
          })
        )
      );
    },
    [layout, onDelete, setLayout]
  );

  const onEdit = (l: ILayout) => {
    editElement(l.element || {});
  };

  useEffect(() => {
    // call reorder, check if needed
    if (!debouncedLayout || debouncedLayout.length === 0) {
      return;
    }

    reOrder(debouncedLayout);
  }, [debouncedLayout]);

  const reOrder = useCallback(
    async (layout: ILayout[]) => {
      let positions: { [key: string]: ApiElementPosition } = {};
      // flatten all layouts
      const allLayouts = [
        ...layout,
        ...layout
          .filter((l) => l.inner && l.inner.length > 0)
          .map((l) => l.inner),
      ]
        .flat(2)
        .filter((l) => l && l.id) as ILayout[];

      allLayouts.forEach((l) => {
        positions[`${l.id}`] = {
          x: l.x,
          y: l.y,
          w: l.w,
          h: l.h,
        };
      });
      if (Object.keys(positions).length === 0) {
        return;
      }
      await reportService.reportServiceReorderElements({
        projectId: project.id as number,
        reportId: report.id as number,
        tabId: tab.id as number,
        payload: {
          positions,
        },
      });
      // enforces ReactGridLayout to rerender
      window.dispatchEvent(new Event("resize"));
    },
    [project.id, report.id, tab.id]
  );

  const onDragStart = useCallback(
    (item, e) => {
      setContainerMinHeight(
        (ref?.clientHeight || 700) + item.h * (rowHeight + 20)
      );

      e.stopPropagation();
    },
    [ref]
  );

  return (
    <Box mb={7} ref={onRefChange}>
      <FixedReactGridLayout
        draggableHandle=".dragH"
        onDragStart={(a, b, c, d, e) => {
          onDragStart(b, e);
        }}
        layout={layout}
        rowHeight={rowHeight}
        cols={12}
        draggableCancel={".MuiButtonBase-root"}
        containerPadding={[0, 20]}
        onLayoutChange={onLayoutChange}
        onDropDragOver={(e) => {
          if (isDragging) {
            return;
          }
          setIsDragging(true);
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            setIsDragging(false);
          }, 1000);

          if (gridDimensions) {
            return gridDimensions;
          }
          return { w: 4, h: 4 };
        }}
        isDroppable={editMode}
        isResizable={editMode}
        isDraggable={editMode}
        onDrop={onDrop}
        style={{
          minHeight: containerMinHeight,
        }}
      >
        {(layout || []).length === 0 ? (
          <Box>
            <Typography>Drag Chart or block to get started</Typography>
          </Box>
        ) : (
          layout.map((l) => (
            <Box key={l.i} className="dragH">
              {l.type === ApiTabElementType.BLOCK ? (
                <Stack
                  sx={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    " &::before": {
                      content: "''",
                      display: editMode ? "block" : "none",
                      zIndex: -1,
                      position: "absolute",
                      left: -2,
                      height: "calc(100% + 4px)",
                      width: "calc(100% + 4px)",
                      border: `2px dashed ${theme.palette.info.main}`,
                    },
                  }}
                  key={l.i}
                >
                  <InnerGrid
                    project={project}
                    client={client}
                    onDrop={onDrop}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    layout={layout}
                    setLayout={setLayout}
                    inner={l.inner || []}
                    key={l.i}
                    l={l}
                    editMode={editMode}
                    report={report}
                    tab={tab}
                  />
                </Stack>
              ) : (
                <Item
                  l={l}
                  onRemove={onRemove}
                  onEdit={onEdit}
                  editMode={editMode}
                  report={report}
                  tab={tab}
                  project={project}
                  client={client}
                />
              )}
            </Box>
          ))
        )}
      </FixedReactGridLayout>
      <ChartLibraryDialog
        open={open}
        setOpen={setOpen}
        onSelect={addElement}
        hideTabs={droppedItem?.type === ApiTabElementType.STAT}
        type={
          droppedItem?.type === ApiTabElementType.STAT
            ? ApiChartType.STAT_CARD
            : undefined
        }
      />
    </Box>
  );
}

export default ReportBuilder;
