import { ApiUser } from "@incendium/api";
import { Check, Delete, Edit } from "@mui/icons-material";
import {
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { userService } from "Apis";
import SpacedList from "Components/UI/SpacedList";
import { cell2Icons } from "consts";
import { formatEnumVal } from "Helpers/enumToText";
import { useUsers } from "Hooks/useUsers";
import produce from "immer";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useConfirmationContext } from "Providers/ConfirmationProvider";

interface IUsersListProps {
  onSelect: (user: ApiUser) => void;
}

function UsersList({ onSelect }: IUsersListProps) {
  const { users, setUsers } = useUsers();
  const { enqueueSnackbar } = useSnackbar();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();

  const deleteUser = async (user: ApiUser) => {
    openConfirmation({
      title: `Are you sure you want to delete this user`,
      body: `This action can not be undone`,
      callback: async () => {
        await userService.userServiceDeleteUser({ userId: user.id as number });

        try {
          await userService.userServiceDeleteUser({
            userId: user.id as number,
          });
          enqueueSnackbar("User Removed", {
            variant: "success",
            autoHideDuration: 2000,
            anchorOrigin: { horizontal: "right", vertical: "top" },
          });

          setUsers(
            produce(users, (draft) => {
              const idx = draft.findIndex((d) => d.id === user.id);
              if (idx >= 0) {
                draft.splice(idx, 1);
              }
            })
          );
          closeConfirmation();
        } catch (error) {
          enqueueSnackbar("There was an internal error, please try again", {
            variant: "error",
            autoHideDuration: 2000,
            anchorOrigin: { horizontal: "right", vertical: "top" },
          });
        }
      },
    });
  };

  return (
    <SpacedList>
      <TableHead>
        <TableRow>
          <TableCell>Email</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Role(s)</TableCell>
          <TableCell>Active</TableCell>
          <TableCell>Validated</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {users.map((user) => {
          return (
            <TableRow key={user.id}>
              <TableCell className="primary">{user.email}</TableCell>
              <TableCell>{user.name}</TableCell>
              <TableCell>
                <Stack>
                  {(user.permissions || []).map((p) => (
                    <Typography variant="body2" key={p.id}>
                      {formatEnumVal(p.role || "")}{" "}
                      {p.clientId && <>(Client : {p.clientId})</>}
                      {p.projectId && <>(Client : {p.projectId})</>}
                    </Typography>
                  ))}
                </Stack>
              </TableCell>
              <TableCell>{user.status && <Check />}</TableCell>
              <TableCell>
                {user.verifiedDatetime
                  ? moment(user.verifiedDatetime).format("lll")
                  : "-"}
              </TableCell>
              <TableCell
                align="right"
                color="primary"
                sx={{ width: cell2Icons }}
              >
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => onSelect(user)}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={() => deleteUser(user)}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </SpacedList>
  );
}

export default UsersList;
