import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import { useCallback, useMemo, useState } from "react";
import { AnalyticsCard, metricByAttribution } from "features/analytics";
import { TChartData } from "Interfaces";
import {
  AttributionMetric,
  AttributionType,
} from "features/analytics/types/types";

interface IHiddenTableProps {
  title?: string;
  dimensions?: ApiDimension[];
}

function HiddenValueTable({ title, dimensions }: IHiddenTableProps) {
  const [modelType, setModelType] = useState(AttributionType.LAST);
  const customFn = useCallback((data: TChartData[]) => {
    const metric = metricByAttribution(modelType, AttributionMetric.ROI);
    return data
      .filter(
        (d) =>
          Number(d[ApiMetric.METRIC_PREDICTED_ROI]) > 0 &&
          Number(d[metric!]) < 0
      )
      .sort(
        (a, b) =>
          Number(
            b[ApiMetric.METRIC_PREDICTED_ROI]
              ? b[ApiMetric.METRIC_PREDICTED_ROI]
              : 0
          ) -
          Number(
            a[ApiMetric.METRIC_PREDICTED_ROI]
              ? a[ApiMetric.METRIC_PREDICTED_ROI]
              : 0
          )
      );
  }, []);

  const chart = useMemo(
    () => ({
      name: title,
      dimension: dimensions || [],
      attributes: [],
      template: ApiChartTemplate.TABLE,
      type: ApiChartType.TABLE,
      yAxisKeys: [
        {
          key: "l",
          fields: [
            ApiMetric.METRIC_SESSION_REVENUE,
            ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROI,
            ApiMetric.METRIC_PREDICTED_ROI,
            ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROAS,
            ApiMetric.METRIC_PREDICTED_ROAS,
            ApiMetric.METRIC_CAC,
            ApiMetric.METRIC_ECR,
            ApiMetric.METRIC_CPNV,
            ApiMetric.METRIC_SPEND,
            ApiMetric.METRIC_LINEAR_PREDICTED30_DAY_LTV_REVENUE,
            ApiMetric.METRIC_LINEAR_PREDICTED60_DAY_LTV_REVENUE,
            ApiMetric.METRIC_LINEAR_PREDICTED90_DAY_LTV_REVENUE,
          ],
        },
      ],
      displayOptions: {
        noFilters: true,
      },
    }),
    [dimensions, title]
  );

  return (
    <AnalyticsCard
      chart={chart}
      customDataFn={customFn}
      onModelsChange={(models) => setModelType(models[0])}
    />
  );
}

export default HiddenValueTable;
