export const filterObjectByKeys = <T extends object, K extends keyof T>(
  obj: T,
  keys: K[]
): T => {
  const filteredEntries = Object.entries(obj)
    .filter(([key]) => keys.includes(key as K))
    .map(([key, value]) => [key, value]);

  return Object.fromEntries(filteredEntries);
};
