import { metricTitles } from "Interfaces/enums";
import { formatNumber } from "./numbers";
import { percentageChange, percentageOf } from "./percentage";
import { ApiChartAttribute, ApiDimension, ApiOperator } from "@incendium/api";
import { TChartData } from "Interfaces";

// returns top x dimensions by metric
export const getTopByDimensionByMetric = <T extends object>(
  data: T[],
  limit: number,
  dimension: keyof T,
  metric: keyof T
) => {
  const totals = getTotalsByMetricAndDimension(data, dimension, metric);
  return totals
    .sort((a, b) => b.value - a.value)
    .slice(0, limit)
    .map((d) => d.key);
};

export const getTotalsByMetricAndDimension = <T extends object>(
  data: T[],
  dimension: keyof T,
  metric: keyof T
): {
  key: T[keyof T];
  value: number;
}[] => {
  return data
    .map((d) => ({
      key: d[dimension],
      value: Number(d[metric]),
    }))
    .reduce<any>((agg, v) => {
      const idx = agg.findIndex((a: any) => a.key === v.key);
      if (idx >= 0) {
        agg[idx].value = agg[idx].value + v.value;
        return agg;
      }
      return [...agg, v];
    }, []);
};

export const formatMetricValue = <T>(
  metric: keyof T | string,
  value: unknown
) => {
  if (!metric) {
    return "";
  }
  // split metric after \ if there
  const parts = metric?.toString().toLowerCase().split("\\") || "";
  const formatedMetric = parts[parts.length - 1] as string;

  if (typeof value === "number") {
    if (!isNaN(value)) {
      value = formatNumber(value);
    }
  }

  if (["revenue", "spend", "ltv"].some((v) => formatedMetric.includes(v))) {
    value = `$${value}`;
  }

  if (["duration", "seconds", "time"].some((v) => formatedMetric.includes(v))) {
    value = `${value} s`;
  }

  if (
    ["rank", "rate", "roas", "roi", "percentage", "ctr"].some((v) =>
      formatedMetric.includes(v)
    )
  ) {
    value = `${value}%`;
  }
  return value;
};

export const getHighestPerformer = <T extends object>(
  data: T[],
  compData: T[],
  field: keyof T,
  dimension?: keyof T
) => {
  if (data.length === 0) {
    return null;
  }
  const sortedByMetric = [...data].sort(
    (a, b) => Number(b[field]) - Number(a[field])
  );
  const highest = sortedByMetric[0];
  let comp: T | undefined;
  let name = "";
  if (dimension) {
    name = `${highest[dimension]}` || "";
    comp = compData.find((f) => `${f[dimension]}` === name);
  } else {
    comp = compData[0];
  }

  const total = sortedByMetric.reduce((agg: number, v: any) => {
    return agg + Number(v[field]);
  }, 0);

  return {
    name,
    value: highest[field],
    percentage: percentageOf(
      Number((highest as any)[field as keyof any]),
      total
    ),
    diff: comp
      ? percentageChange(
          Number((comp as any)[field as keyof any]),
          Number((highest as any)[field as keyof any])
        )
      : 0,
  };
};

export const parseChartAttributeToPrimaryFilters = (
  attributes: ApiChartAttribute[]
) =>
  (attributes || []).map((a) => ({
    key: a.key as ApiDimension,
    value: a.value || "",
    operator: a.operator ? (a.operator as ApiOperator) : undefined,
  }));

export const metricTitlesToProductDislpayName = (s: metricTitles): string => {
  switch (s) {
    case metricTitles.Impressions:
      return "Product Impressions";
    case metricTitles.Click_Throughs:
      return "Click through rate";
    case metricTitles.Add_to_carts:
      return "Add to cart";
    case metricTitles.Sales:
      return "Sales";

    default:
      return "";
  }
};

export const metricTitlesToProductWheelPosition = (s: metricTitles) => {
  let beforePosition = {};
  let afterPosition = {};
  switch (s) {
    case metricTitles.Impressions:
      beforePosition = {
        width: "56px",
        left: "-53px",
        top: "29px",
      };
      afterPosition = {
        left: "-50px",
        top: "42px",
      };
      break;
    case metricTitles.Click_Throughs:
      beforePosition = {
        width: "76px",
        left: "-70px",
        top: "36px",
      };
      afterPosition = {
        left: "-65px",
        top: "55px",
      };
      break;
    case metricTitles.Add_to_carts:
      beforePosition = {
        width: "124px",
        left: "-115px",
        top: "54px",
      };
      afterPosition = {
        left: "-105px",
        top: "88px",
      };
      break;

    case metricTitles.Sales:
      beforePosition = {
        width: "208px",
        left: "-190px",
        top: "81px",
      };
      afterPosition = {
        left: "-170px",
        top: "142px",
      };
      break;
  }
  return [beforePosition, afterPosition];
};

export const metricTitlesToJobDislpayName = (s: metricTitles): string => {
  switch (s) {
    case metricTitles.Impressions:
      return "Job Impressions";
    case metricTitles.Click_Throughs:
      return "Click through rate";
    case metricTitles.Apply_Click:
      return "Click Apply";
    case metricTitles.Application:
      return "Applications";

    default:
      return "";
  }
};

export const metricTitlesToJobWheelPosition = (s: metricTitles) => {
  let beforePosition = {};
  let afterPosition = {};
  switch (s) {
    case metricTitles.Impressions:
      beforePosition = {
        width: "66px",
        left: "-63px",
        top: "34px",
      };
      afterPosition = {
        left: "-62px",
        top: "52px",
      };
      break;
    case metricTitles.Click_Throughs:
      beforePosition = {
        width: "76px",
        left: "-70px",
        top: "36px",
      };
      afterPosition = {
        left: "-65px",
        top: "55px",
      };
      break;
    case metricTitles.Apply_Click:
      beforePosition = {
        width: "124px",
        left: "-115px",
        top: "54px",
      };
      afterPosition = {
        left: "-105px",
        top: "88px",
      };
      break;

    case metricTitles.Application:
      beforePosition = {
        width: "140px",
        left: "-131px",
        top: "60px",
      };
      afterPosition = {
        left: "-123px",
        top: "103px",
      };
      break;
  }
  return [beforePosition, afterPosition];
};

export const getDataValueOrZeroByKey = <
  T extends TChartData | null | undefined
>(
  data: T,
  key: keyof TChartData
): number => {
  if (data && key in (data as TChartData)) {
    return Number(data[key]) || 0;
  }
  return 0;
};
