import { Alert, Box, TextField, Typography } from "@mui/material";
import {
  I_CAMPAIGNID,
  I_CONTENTID,
  I_MEDIUM,
  I_SOURCE,
  I_TERM,
} from "features/utms/constants";
import { useMemo } from "react";

export const bingIAIMapping = {
  [I_SOURCE]: "bing",
  [I_MEDIUM]: "cpc",
  [I_CAMPAIGNID]: "{CampaignId}",
  [I_CONTENTID]: "{AdGroupId}",
  [I_TERM]: "{Keyword}",
};

function BingAdsTrackingTemplate() {
  const params = useMemo(() => {
    return Object.keys(bingIAIMapping)
      .map((k) => `${k}=${bingIAIMapping[k]}`)
      .join("&");
  }, []);

  return (
    <Box>
      <Typography gutterBottom>
        If you dont yet have a tracking temnplate in place, in the{" "}
        <strong>Tracking template</strong> field, enter the following:
      </Typography>
      <TextField
        fullWidth
        multiline
        variant="outlined"
        value={`{lpurl}?${params}`}
        InputProps={{
          readOnly: true,
        }}
      />
      <Typography my={2}>
        If you already have a template, add the following to the end of your
        template:
      </Typography>
      <TextField
        fullWidth
        multiline
        variant="outlined"
        value={`&${params}`}
        InputProps={{
          readOnly: true,
        }}
      />
      <Box mt={2}>
        <Alert severity="info">
          <Typography variant="subtitle2" gutterBottom>
            Important Note:
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Auto-Tagging</strong>: If you have enabled auto-tagging in
            Bing Ads, make sure to select the option "Keep my existing tags and
            add any that are missing." This ensures that your custom tracking
            parameters are not overwritten.
          </Typography>
          <Typography variant="body2">
            <strong>Lower-Level Tracking Templates</strong>: If you have set up
            tracking templates at the campaign or ad group level, you need to
            either:
            <li>Remove those lower-level tracking templates.</li>
            <li>
              Or, update them to include the tracking template provided in these
              instructions.
            </li>
          </Typography>
        </Alert>
      </Box>
    </Box>
  );
}

export default BingAdsTrackingTemplate;
