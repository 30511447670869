import { clientService } from "Apis";

export const saveClient = async (client) => {
  const { businessName, timezone, id } = client;
  const fn = id
    ? clientService.clientServiceUpdate({
        clientId: id,
        payload: { businessName, timezone },
      })
    : clientService.clientServiceCreate({
        body: { businessName, timezone },
      });
  const res = await fn;
  return res;
};

export const deleteClient = async (clientId: number) => {
  return clientService.clientServiceDelete({
    clientId,
  });
};
