import { Typography } from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import { TabWrapper } from "Components/UI/TabContainer";
import { enumToArray } from "Helpers/enumToText";
import { useCallback, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import AttributionInsights from "./AttributionInsights";
import CreativesInsights from "./CreativesInsights";
import TabbedAnalysesPage from "Pages/Analyse/TabbedAnalysesPage";
import KeywordInsights from "./KeywordInsights";
import { ApiDimension } from "@incendium/api";

enum Insight {
  platforms = "Platforms",
  campaigns = "Campaigns",
  creatives = "Creatives",
  keywords = "Keywords",
}

function MarketingPerformancePaidMediaPage() {
  const { insight } = useParams<{ insight: string }>();
  const [activeInsight, setActiveInsight] = useState(insight);

  const renderSwitch = useCallback(() => {
    switch (activeInsight) {
      case Insight.platforms.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <AttributionInsights
              marketingDimension={ApiDimension.DIMENSION_SOURCE}
              title="Platforms"
            />
          </TabWrapper>
        );
      case Insight.campaigns.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <AttributionInsights
              marketingDimension={ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN}
              title="Campaigns"
            />
          </TabWrapper>
        );
      case Insight.creatives.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <CreativesInsights />
          </TabWrapper>
        );
      case Insight.keywords.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <KeywordInsights />
          </TabWrapper>
        );

      default:
        return (
          <TabWrapper>
            <CenterPage calcHeight>
              <Typography variant="subtitle1">Not Implemented</Typography>
            </CenterPage>
          </TabWrapper>
        );
    }
  }, [activeInsight]);

  if (!insight || insight === "") {
    return <Redirect to={`./paid-media/platforms`} />;
  }

  return (
    <TabbedAnalysesPage
      title=" Marketing Performance - Paid Media"
      activeTab={activeInsight}
      setActiveTab={setActiveInsight}
      tabs={enumToArray(Insight)}
      renderSwitch={renderSwitch}
    />
  );
}

export default MarketingPerformancePaidMediaPage;
