import { Alert } from "@mui/material";
import { StyledMiddleBox } from "Components/UI/StylesFlexBox";
import { motion } from "framer-motion";

interface IErrorProps {
  text?: string;
}

function Error({ text }: IErrorProps) {
  return (
    <StyledMiddleBox
      sx={{ height: "100%" }}
      component={motion.div}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5 }}
    >
      <Alert severity="error">
        {text || "Oops! Error fetching data. Please try again later."}
      </Alert>
    </StyledMiddleBox>
  );
}

export default Error;
