import { appearDown } from "Pages/Audience/ClassificationsPage";
import { motion } from "framer-motion";
import clsx from "clsx";
import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ApiAudience,
  ApiClassification,
  ApiClassificationItem,
  ApiProject,
} from "@incendium/api";
import {
  ArrowBack,
  Delete,
  Edit,
  Reorder,
  Settings,
} from "@mui/icons-material";
import { useConfirmationContext } from "Providers/ConfirmationProvider";
import { useSnackbar } from "notistack";
import { audienceService } from "Apis";
import ClassificationItemDialog from "Components/ClassificationDialog/ClassificationItemDialog";
import { useMemo, useState } from "react";
import { Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import {
  ColItem,
  ColsTitle,
  useColsLayoutStyles,
} from "Components/ColsLayout/ColsLayout";
import { useAppDndContext } from "Providers/AppDndProvider";
import { useUpdateEffect } from "react-use";
import Appear from "Components/Animate/Appear";
import AudienceDialog from "Components/Audience/AudienceDialog";
import { TypographyHelp } from "Components/UI/TypographyHelp";
import StyledDrawer, {
  StyledDrawerContainer,
  StyledDrawerTitle,
} from "Components/UI/StyledDrawer";
import CopyClipboardBtn from "Components/CopyClipboardBtn/CopyClipboardBtn";
import { traitParam } from "@incendium/inc-ts-helpers";

// todo, type
const ItemRow = ({
  item,
  selectedClassificationItem,
  selectClassificationItem,
  onClassItemEdit,
  onConfig,
  onItemDelete,
  provided,
  snapshot,
}: any) => {
  const { colHandle } = useColsLayoutStyles();
  const [audienceOpen, setAudienceOpen] = useState(false);
  const [audience, setAudience] = useState<ApiAudience>({});

  const onAudienceSaved = (a: ApiAudience) => {
    setAudienceOpen(false);
    setAudience(a);
  };

  if (snapshot.isDragging) {
    const offset = { x: 147, y: 95 }; // your fixed container left/top position
    const x = provided.draggableProps.style.left - offset.x;
    const y = provided.draggableProps.style.top - offset.y;
    provided.draggableProps.style.left = x;
    provided.draggableProps.style.top = y;
  }

  const child = (
    <div {...provided.draggableProps} ref={provided.innerRef}>
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          show: { opacity: 1 },
        }}
      >
        {snapshot.isDragging && <Divider />}
        <ColItem>
          <ListItem
            className={clsx({
              active: item.id === selectedClassificationItem?.id,
            })}
            button
            onClick={() => selectClassificationItem(item)}
          >
            {!selectedClassificationItem && (
              <div {...provided.dragHandleProps} className={colHandle}>
                <ListItemIcon>
                  <Reorder color="secondary" />
                </ListItemIcon>
              </div>
            )}
            <ListItemText
              primary={item.value}
              secondary={item.description || "..."}
              primaryTypographyProps={{
                noWrap: true,
              }}
              secondaryTypographyProps={{
                noWrap: true,
                variant: "caption",
              }}
            />
            {!selectedClassificationItem && (
              <Appear>
                <ListItemSecondaryAction>
                  <IconButton color="secondary" onClick={onConfig} size="large">
                    <Settings />
                  </IconButton>

                  <IconButton
                    color="secondary"
                    onClick={() => onClassItemEdit(item)}
                    size="large"
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => onItemDelete(item)}
                    size="large"
                  >
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </Appear>
            )}
          </ListItem>
          <Divider />
        </ColItem>
      </motion.div>
      <AudienceDialog
        open={audienceOpen}
        setOpen={setAudienceOpen}
        audience={audience}
        onSaved={onAudienceSaved}
      />
    </div>
  );

  return child;
};

function ClassificationItemsCol({
  selectedClassification,
  selectClassificationItem,
  selectedClassificationItem,
  setSelectedClassificationItem,
  selectedProject,
  setSelectedClassification,
  onClassItemSaved,
}: {
  selectedClassification: ApiClassification | null;

  selectClassificationItem: (i: ApiClassificationItem) => void;
  selectedClassificationItem: ApiClassificationItem | null;
  setSelectedClassificationItem: React.Dispatch<
    React.SetStateAction<ApiClassificationItem | null>
  >;
  selectedProject: ApiProject;
  setSelectedClassification: React.Dispatch<
    React.SetStateAction<ApiClassification | null>
  >;
  onClassItemSaved: (classificationItem: ApiClassificationItem) => void;
}) {
  const [open, setOpen] = useState(false);
  const [configOpen, setConfigOpen] = useState(false);
  const [classificationItem, setClassificationItem] =
    useState<ApiClassificationItem | null>(null);
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { enqueueSnackbar } = useSnackbar();
  const { setCallBacks } = useAppDndContext();

  useUpdateEffect(() => {
    setCallBacks({
      onDragEnd,
    });
  }, [selectedClassification?.classificationItems]);

  const items = useMemo(() => {
    return (selectedClassification?.classificationItems || [])
      .map((c) => c)
      .sort((a, b) => (a.order || 0) - (b.order || 0));
  }, [selectedClassification]);

  const onClassItemEdit = (classificationItem: ApiClassificationItem) => {
    setClassificationItem(classificationItem);
    setOpen(true);
  };

  const onItemDelete = (item: ApiClassificationItem) => {
    openConfirmation({
      title: `Are you sure you want to delete this item`,
      body: `This action can not be undone`,
      callback: async () => {
        await audienceService.audienceServiceDeleteClassificationItem({
          projectId: selectedProject?.id as number,
          classificationId: selectedClassification?.id as number,
          classificationItemId: item.id as number,
        });
        const items = [...(selectedClassification?.classificationItems || [])];
        const idx = items.findIndex((ci) => ci.id === item.id);
        if (idx !== -1) items.splice(idx, 1);

        setSelectedClassification({
          ...selectedClassification,
          classificationItems: items,
        });

        enqueueSnackbar(`${item.value} Deleted`, {
          variant: "success",
          autoHideDuration: 2000,
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        setSelectedClassificationItem(null);

        closeConfirmation();
      },
    });
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // lets reorder  priority
    const newClassificationItems = (
      selectedClassification?.classificationItems || []
    )
      .map((item, i) => {
        if (Number(draggableId) === item.id) {
          return {
            ...item,
            order: destination.index + 1,
          };
        }

        // if destination is less then going up
        if (destination.index < source.index) {
          if (i > source.index || i < destination.index) return item;

          return {
            ...item,
            order: (item.order as number) + 1,
          };
        }

        // else going down
        if (i < source.index || i > destination.index) return item;
        return {
          ...item,
          order: (item.order as number) - 1,
        };
      })
      .sort((a, b) => (a.order || 0) - (b.order || 0))
      .map((c, i) => ({
        ...c,
        order: i + 1,
      }));
    setSelectedClassification({
      ...selectedClassification,
      classificationItems: newClassificationItems,
    });
  };

  return (
    <div>
      <ColsTitle>
        {selectedClassification ? (
          <>
            <motion.span
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{ duration: 0.4, delay: 0.1 }}
            >
              <TypographyHelp
                variant="body1"
                iconSize="small"
                placement="right"
                tooltip={
                  <>
                    <p>
                      A lead can only have one value per trait; for example, the
                      trait might be "hair color" and the value "brown". The
                      values are in a cascading order, which means that we check
                      to see if the first one is a match and if it isn't, we try
                      the second rule and so on.
                    </p>
                  </>
                }
                text=" Possible Values"
              />

              <Typography variant="body2" component="span">
                (Trait : {selectedClassification.name})
              </Typography>
            </motion.span>
            {!selectedClassificationItem ? (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setOpen(true)}
              >
                Add New Value
              </Button>
            ) : (
              <Tooltip title="Back To Possible Values" placement="top">
                <IconButton
                  disabled={!selectedClassificationItem}
                  onClick={() => {
                    setSelectedClassificationItem(null);
                  }}
                  size="large"
                >
                  <ArrowBack />
                </IconButton>
              </Tooltip>
            )}
          </>
        ) : (
          <Typography variant="body1" color="textSecondary">
            Possible Values
          </Typography>
        )}
      </ColsTitle>
      {selectedClassification &&
        selectedClassification.classificationItems &&
        selectedClassification.classificationItems.length > 0 && (
          <motion.div initial="hidden" animate="show" variants={appearDown}>
            <Droppable droppableId="list">
              {(provided) => {
                return (
                  <List
                    disablePadding
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {items.map((item, idx) => (
                      <div key={item.id}>
                        <Draggable
                          draggableId={(item.id && item.id > 0
                            ? item.id
                            : idx
                          ).toString()}
                          index={idx}
                        >
                          {(draggableProvided, draggableSnapshot) => (
                            <ItemRow
                              provided={draggableProvided}
                              snapshot={draggableSnapshot}
                              item={item}
                              selectedClassificationItem={
                                selectedClassificationItem
                              }
                              selectClassificationItem={
                                selectClassificationItem
                              }
                              onClassItemEdit={onClassItemEdit}
                              onConfig={() => setConfigOpen(!configOpen)}
                              onItemDelete={onItemDelete}
                            />
                          )}
                        </Draggable>
                      </div>
                    ))}
                    {provided.placeholder}
                  </List>
                );
              }}
            </Droppable>
          </motion.div>
        )}
      {open && (
        <ClassificationItemDialog
          onClose={() => {
            setSelectedClassificationItem(null);
            setOpen(false);
          }}
          classification={selectedClassification}
          classificationItem={classificationItem}
          project={selectedProject}
          onSaved={(classificationItem) => {
            setOpen(false);
            onClassItemSaved(classificationItem);
          }}
        />
      )}
      <StyledDrawer open={configOpen} onClose={() => setConfigOpen(false)}>
        <StyledDrawerTitle>
          <Typography variant="subtitle1"> Manually Set Trait</Typography>
          <Typography variant="body2">
            Use one of these options to manually set a leads trait.
          </Typography>
        </StyledDrawerTitle>
        {selectedClassificationItem && (
          <>
            <StyledDrawerContainer>
              <Typography variant="body1">
                <strong>Key</strong> : {selectedClassificationItem.param}
                <CopyClipboardBtn
                  color="secondary"
                  text={`${selectedClassificationItem.param}`}
                  stopPropagation
                  isIconButton
                  sx={{ marginLeft: 1 }}
                />
              </Typography>
              <Typography variant="body1">
                <strong>URL Search Param</strong> :{" "}
                {`${traitParam}=${selectedClassificationItem.param}`}
                <CopyClipboardBtn
                  color="secondary"
                  text={`${selectedClassificationItem}=${selectedClassificationItem.param}`}
                  stopPropagation
                  isIconButton
                  sx={{ marginLeft: 1 }}
                />
              </Typography>
              <Typography variant="body1">
                <strong>JS Function</strong> :{" "}
                <i>inc("add_trait", "{selectedClassificationItem.param}");</i>
                <CopyClipboardBtn
                  color="secondary"
                  text={`inc("add_trait", "${selectedClassificationItem.param}");`}
                  stopPropagation
                  isIconButton
                  sx={{ marginLeft: 1 }}
                />
              </Typography>
            </StyledDrawerContainer>
          </>
        )}
      </StyledDrawer>
    </div>
  );
}

export default ClassificationItemsCol;
