import { Portal, Stack, Typography } from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import JobsDropdown from "Components/Job/JobsDropdown";
import { useSelectedProject } from "Hooks";
import { useJobs } from "Hooks/useJobs";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useSearchParam } from "react-use";
import JobSingleAndCategoryPage from "./JobSingleAndCategoryPage";
import { ApiDimension } from "@incendium/api";
import { PageActionPreSlot } from "consts";

function JobsReportPage() {
  const { selectedClient, selectedProject } = useSelectedProject();
  const [selectedJobId, setSelectedJobId] = useState<number | undefined>();
  const { jobs } = useJobs();
  const jobId = useSearchParam("job_id");

  useEffect(() => {
    if (jobId) {
      setSelectedJobId(Number(jobId));
    }
  }, [jobId]);

  const selectedJob = useMemo(
    () => jobs.find((j) => j.id === selectedJobId),
    [selectedJobId, jobs]
  );

  return (
    <>
      <Portal container={() => document.getElementById(PageActionPreSlot)}>
        <JobsDropdown
          sx={{ minWidth: 200 }}
          fullWidth
          size="small"
          variant="outlined"
          label="Select Job"
          value={selectedJobId}
          onChange={(n, p) => {
            setSelectedJobId(n);
          }}
        />
      </Portal>
      <Portal container={() => document.getElementById("pageTitle")}>
        <Stack>
          <Typography variant="h1">Single Job Dashboard</Typography>
          <Stack direction="row" alignItems={"baseline"}>
            <Typography variant="subtitle2" color={"secondary"}>
              {selectedJob?.title}
            </Typography>
            <Link
              to={{
                pathname: `/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/job-category-deep-dive?job_category=${selectedJob?.category}`,
              }}
            >
              {" - "}
              {selectedJob?.category}
            </Link>
          </Stack>
        </Stack>
      </Portal>

      {selectedJob ? (
        <JobSingleAndCategoryPage
          dimension={ApiDimension.DIMENSION_JOB_TITLE}
          byDimension={selectedJob.title || ""}
        />
      ) : (
        <CenterPage calcHeight>
          <Typography variant="subtitle1" mb={2}>
            Select a Job to get started
          </Typography>
          <JobsDropdown
            sx={{ maxWidth: 300 }}
            fullWidth
            size="small"
            variant="outlined"
            label="Select Job"
            value={selectedJobId}
            onChange={(n, p) => {
              setSelectedJobId(n);
            }}
          />
        </CenterPage>
      )}
    </>
  );
}

export default JobsReportPage;
