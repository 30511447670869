import { Pagination as MUIPagination, PaginationItem } from "@mui/material";
import { formatNumber } from "Helpers/numbers";

interface IPaginationProps {
  totalPages: number;
  page: number;
  onChange: (p: number) => void;
}

function Pagination({ totalPages, page, onChange }: IPaginationProps) {
  return (
    <MUIPagination
      count={totalPages}
      page={page}
      renderItem={(item) => (
        <PaginationItem {...item} page={formatNumber(Number(item.page))} />
      )}
      onChange={(_, value) => {
        onChange(value);
      }}
    />
  );
}

export default Pagination;
