/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiEffectivenessRuleFromJSON, ApiEffectivenessRulePayloadToJSON, ApiEffectivenessRulesFromJSON, } from '../models';
/**
 *
 */
export class EffectivenessRuleServiceApi extends runtime.BaseAPI {
    /**
     */
    effectivenessRuleServiceCreateRuleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling effectivenessRuleServiceCreateRule.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling effectivenessRuleServiceCreateRule.');
            }
            const queryParameters = {};
            if (requestParameters.ruleId !== undefined) {
                queryParameters['ruleId'] = requestParameters.ruleId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/effectiveness-rule/v1/projects/{projectId}/rules`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiEffectivenessRulePayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiEffectivenessRuleFromJSON(jsonValue));
        });
    }
    /**
     */
    effectivenessRuleServiceCreateRule(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.effectivenessRuleServiceCreateRuleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    effectivenessRuleServiceDeleteRuleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling effectivenessRuleServiceDeleteRule.');
            }
            if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
                throw new runtime.RequiredError('ruleId', 'Required parameter requestParameters.ruleId was null or undefined when calling effectivenessRuleServiceDeleteRule.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadLocations) {
                queryParameters['payload.locations'] = requestParameters.payloadLocations;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/effectiveness-rule/v1/projects/{projectId}/rules/{ruleId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    effectivenessRuleServiceDeleteRule(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.effectivenessRuleServiceDeleteRuleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    effectivenessRuleServiceListRulesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling effectivenessRuleServiceListRules.');
            }
            const queryParameters = {};
            if (requestParameters.ruleId !== undefined) {
                queryParameters['ruleId'] = requestParameters.ruleId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadLocations) {
                queryParameters['payload.locations'] = requestParameters.payloadLocations;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/effectiveness-rule/v1/projects/{projectId}/rules`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiEffectivenessRulesFromJSON(jsonValue));
        });
    }
    /**
     */
    effectivenessRuleServiceListRules(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.effectivenessRuleServiceListRulesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    effectivenessRuleServiceReadRuleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling effectivenessRuleServiceReadRule.');
            }
            if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
                throw new runtime.RequiredError('ruleId', 'Required parameter requestParameters.ruleId was null or undefined when calling effectivenessRuleServiceReadRule.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadLocations) {
                queryParameters['payload.locations'] = requestParameters.payloadLocations;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/effectiveness-rule/v1/projects/{projectId}/rules/{ruleId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiEffectivenessRuleFromJSON(jsonValue));
        });
    }
    /**
     */
    effectivenessRuleServiceReadRule(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.effectivenessRuleServiceReadRuleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    effectivenessRuleServiceUpdateRuleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling effectivenessRuleServiceUpdateRule.');
            }
            if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
                throw new runtime.RequiredError('ruleId', 'Required parameter requestParameters.ruleId was null or undefined when calling effectivenessRuleServiceUpdateRule.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling effectivenessRuleServiceUpdateRule.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/effectiveness-rule/v1/projects/{projectId}/rules/{ruleId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiEffectivenessRulePayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiEffectivenessRuleFromJSON(jsonValue));
        });
    }
    /**
     */
    effectivenessRuleServiceUpdateRule(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.effectivenessRuleServiceUpdateRuleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
