import { Button, Box } from "@mui/material";
import PageTypeDialog from "Components/PageTypes/PageTypeDialog";
import { useLayoutEffect, useState } from "react";
import { ApiPageType } from "@incendium/api";
import { useSelectedProject } from "Hooks";
import { usePageTypes } from "Hooks/usePageTypes";
import produce from "immer";
import { useSnackbar } from "notistack";
import PageTypesList from "Components/PageTypes/PageTypesList";
import { locationService } from "Apis";
import { useLayoutContext } from "Providers/LayoutProvider";
import { TypographyHelp } from "Components/UI/TypographyHelp";
import { useDeleteConfirmation } from "Hooks/useDeleteConfirmation";

function LocationTagsPage() {
  const { pageTypes, setPageTypes } = usePageTypes();
  const handleDelete = useDeleteConfirmation();
  const { selectedProject } = useSelectedProject();
  const [open, setOpen] = useState(false);
  const [pageType, setPageType] = useState<ApiPageType>({});
  const { enqueueSnackbar } = useSnackbar();
  const { setPageTitle, setTitleAction } = useLayoutContext();

  useLayoutEffect(() => {
    setPageTitle(
      <TypographyHelp
        variant="h1"
        text={`${selectedProject?.name} Location Tags`}
        tooltip={
          "Location Tags can be used to group locations together to use in analytics"
        }
      />
    );
    setTitleAction(<Button onClick={onNew}>New Location Tag</Button>);
    return () => {
      setPageTitle("");
      setTitleAction(undefined);
    };
  }, [selectedProject]);

  const onNew = () => {
    setPageType({});
    setOpen(true);
  };

  const onEdit = (pageType: ApiPageType) => {
    setPageType(pageType);
    setOpen(true);
  };

  const onDelete = (pageType: ApiPageType) => {
    handleDelete({
      title: `Are you sure you want to delete this location tag`,
      body: "This action can not be undone",
      callback: async () => {
        await locationService.locationServiceDeletePageType({
          projectId: selectedProject?.id as number,
          pageTypeId: pageType.id as number,
        });

        setPageTypes(
          produce(pageTypes, (draft) => {
            const idx = pageTypes.findIndex((pt) => pt.id === pageType.id);
            if (idx !== -1) draft.splice(idx, 1);
          })
        );
        return `Location Tag Deleted`;
      },
    });
  };

  const onSave = (pageType: ApiPageType) => {
    const idx = pageTypes.findIndex((pt) => pt.id === pageType.id);
    if (idx >= 0) {
      setPageTypes(
        produce(pageTypes, (draft) => {
          draft[idx] = pageType;
        })
      );
    } else {
      setPageTypes(
        produce(pageTypes, (draft) => {
          draft.push(pageType);
        })
      );
    }

    enqueueSnackbar(`Location Tag Saved`, {
      variant: "success",
      autoHideDuration: 2000,
      anchorOrigin: { horizontal: "right", vertical: "top" },
    });
  };

  return (
    <>
      <Box sx={{ maxWidth: 700 }}>
        <PageTypesList
          pageTypes={pageTypes}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </Box>
      <PageTypeDialog
        open={open}
        setOpen={setOpen}
        pageType={pageType}
        project={selectedProject || {}}
        onSaved={onSave}
      />
    </>
  );
}

export default LocationTagsPage;
