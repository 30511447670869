import {
  ApiChartRankDrection,
  ApiDimension,
  ApiMetric,
  ApiOperator,
} from "@incendium/api";
import { Portal, Tooltip, Typography } from "@mui/material";
import { TabButton } from "Components/UI/TabButton";
import { TabContainer } from "Components/UI/TabContainer";
import { ProductsRanking } from "features/analytics";
import { ExplorerProvider } from "features/explorer/";
import { TExploreConfig } from "features/explorer/types";
import { useState } from "react";

export type ProductRankType =
  | "improve"
  | "leading"
  | "promising"
  | "non-sellers"
  | "gateway";

const config: TExploreConfig<ProductRankType> = {
  leading: {
    title: "Leading Products",
    desc: "These products are the most visited, best converting products. They are best used in marketing campaigns and featured on your most prominent landing pages such as your homepage.",
    default: [
      ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
      ApiMetric.METRIC_PRODUCT_REVENUE,
    ],
    ranking: [
      {
        ranks: [
          {
            metric: ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE,
            direction: ApiChartRankDrection.ASC,
            weight: 0.1,
          },
          {
            metric: ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
            direction: ApiChartRankDrection.ASC,
            weight: 0.1,
          },
          {
            metric: ApiMetric.METRIC_SALES_REVENUE,
            direction: ApiChartRankDrection.ASC,
            weight: 0.1,
          },
          {
            metric: ApiMetric.METRIC_PRODUCT_REVENUE,
            direction: ApiChartRankDrection.ASC,
            weight: 0.1,
          },
        ],
      },
    ],
    cards: ["improvement", "top", "hot"],
    analyseBy: {
      description: "Select what to split products by",

      grouping: [
        {
          option: "",
          dimensions: [
            ApiDimension.DIMENSION_PRODUCT_NAME,
            ApiDimension.DIMENSION_PRODUCT_CATEGORY,
            ApiDimension.DIMENSION_PRODUCT_BRAND,
          ],
        },
      ],
    },
    having: {
      description: "Exclude products based on pageviews or sales etc.",
      havings: [
        {
          label: "Min No. of pageviews",
          metric: ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
          operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
          value: undefined,
        },
        {
          label: "Max No. of pageviews",
          metric: ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
          operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
          value: undefined,
        },
        {
          label: "Min No. of sales",
          metric: ApiMetric.METRIC_SALES_COUNT,
          operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
          value: undefined,
        },
        {
          label: "Max No. of sales",
          metric: ApiMetric.METRIC_SALES_COUNT,
          operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
          value: undefined,
        },
      ],
    },
  },
  improve: {
    title: "Products to improve",
    desc: "These are your worst performing products; the most visited, but poorest converting. Analyse the product pages and consider changes to copy, pricing or perhaps review the traffic sources as they may be mismatched.",
    default: [
      ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
      ApiMetric.METRIC_PRODUCT_REVENUE,
    ],
    ranking: [
      {
        ranks: [
          {
            metric: ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE,
            direction: ApiChartRankDrection.DESC,
            weight: 0.1,
          },
          {
            metric: ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
            direction: ApiChartRankDrection.ASC,
            weight: 0.1,
          },
          {
            metric: ApiMetric.METRIC_SALES_REVENUE,
            direction: ApiChartRankDrection.DESC,
            weight: 0.1,
          },
          {
            metric: ApiMetric.METRIC_PRODUCT_REVENUE,
            direction: ApiChartRankDrection.DESC,
            weight: 0.1,
          },
        ],
      },
    ],
    cards: ["regression", "bottom", "cold"],
    having: {
      description: "Exclude products based on pageviews or sales etc.",
      havings: [
        {
          label: "Min No. of pageviews",
          metric: ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
          operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
          value: undefined,
        },
        {
          label: "Max No. of pageviews",
          metric: ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
          operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
          value: undefined,
        },
        {
          label: "Min No. of sales",
          metric: ApiMetric.METRIC_SALES_COUNT,
          operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
          value: undefined,
        },
        {
          label: "Max No. of sales",
          metric: ApiMetric.METRIC_SALES_COUNT,
          operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
          value: undefined,
        },
      ],
    },
    analyseBy: {
      description: "Select what to split products by",
      grouping: [
        {
          option: "",
          dimensions: [
            ApiDimension.DIMENSION_PRODUCT_NAME,
            ApiDimension.DIMENSION_PRODUCT_CATEGORY,
            ApiDimension.DIMENSION_PRODUCT_BRAND,
          ],
        },
      ],
    },
  },
  promising: {
    title: "Promising Products",
    desc: "Among your less visited products, these products converted the best during the period. Evaluate why these products have low traffic. Are they niche or just an undermarketed products? Drive more traffic to them using marketing campaigns and highlighting/promotion in positioning in results and you will likely drive more revenue.",
    default: [
      ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE,
      ApiMetric.METRIC_SALES_REVENUE,
      ApiMetric.METRIC_PRODUCT_REVENUE,
      ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
    ],
    ranking: [
      {
        ranks: [
          {
            metric: ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE,
            direction: ApiChartRankDrection.ASC,
            weight: 0.1,
          },
          {
            metric: ApiMetric.METRIC_SALES_REVENUE,
            direction: ApiChartRankDrection.ASC,
            weight: 0.1,
          },
          {
            metric: ApiMetric.METRIC_PRODUCT_REVENUE,
            direction: ApiChartRankDrection.ASC,
            weight: 0.1,
          },
          {
            metric: ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
            direction: ApiChartRankDrection.DESC,
            weight: 0.1,
          },
        ],
      },
    ],
    having: {
      description: "Exclude products based on pageviews or sales etc.",
      havings: [
        {
          label: "Min No. of pageviews",
          metric: ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
          operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
          value: 20,
        },
        {
          label: "Max No. of pageviews",
          metric: ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
          operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
          value: undefined,
        },
        {
          label: "Min No. of sales",
          metric: ApiMetric.METRIC_SALES_COUNT,
          operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
          value: undefined,
        },
        {
          label: "Max No. of sales",
          metric: ApiMetric.METRIC_SALES_COUNT,
          operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
          value: undefined,
        },
      ],
    },
    cards: ["improvement", "top", "hot"],
    analyseBy: {
      description: "Select what to split products by",
      grouping: [
        {
          option: "",
          dimensions: [
            ApiDimension.DIMENSION_PRODUCT_NAME,
            ApiDimension.DIMENSION_PRODUCT_CATEGORY,
            ApiDimension.DIMENSION_PRODUCT_BRAND,
          ],
        },
      ],
    },
  },
  "non-sellers": {
    title: "Non-sellers",
    desc: "These products are not selling at all. Try making improvements to the product copy, reviews, images, and pricing. Reexamine the products to ensure it is relevant to your customer base. Assess the sources of traffic driving people to them; perhaps the campaigns are not well targeted",
    default: [ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT],
    ranking: [
      {
        ranks: [
          {
            metric: ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
            direction: ApiChartRankDrection.ASC,
            weight: 0.1,
          },
        ],
      },
    ],
    having: {
      description: "Exclude products based on pageviews or sales etc.",
      havings: [
        {
          label: "Min No. of pageviews",
          metric: ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
          operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
          value: undefined,
        },
        {
          label: "Max No. of pageviews",
          metric: ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
          operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
          value: undefined,
        },
        {
          label: "Min No. of sales",
          metric: ApiMetric.METRIC_SALES_COUNT,
          operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
          value: undefined,
        },
        {
          label: "Max No. of sales",
          metric: ApiMetric.METRIC_SALES_COUNT,
          operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
          value: 0,
        },
      ],
    },
    cards: ["regression", "bottom", "cold"],
    analyseBy: {
      description: "Select what to split products by",
      grouping: [
        {
          option: "",
          dimensions: [
            ApiDimension.DIMENSION_PRODUCT_NAME,
            ApiDimension.DIMENSION_PRODUCT_CATEGORY,
            ApiDimension.DIMENSION_PRODUCT_BRAND,
          ],
        },
      ],
    },
  },
  gateway: {
    title: "Gateway Products",
    desc: "These are the best products for driving high value customers. Customers who purchased these products as their first product were most likely to purchase again, or spend more. Include them in marketing and win-back campaigns. We normally recommend reviewing this list monthly rather than weekly",
    default: [ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_LTV], // todo, product gateway LTV
    ranking: [
      {
        ranks: [
          {
            metric: ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_LTV, // todo, product gateway LTV
            direction: ApiChartRankDrection.ASC,
            weight: 0.1,
          },
          {
            metric: ApiMetric.METRIC_PRODUCT_REVENUE,
            direction: ApiChartRankDrection.ASC,
            weight: 0.1,
          },
          {
            metric: ApiMetric.METRIC_SALES_REVENUE,
            direction: ApiChartRankDrection.ASC,
            weight: 0.1,
          },
        ],
      },
    ],
    having: {
      description: "Exclude products based on pageviews or sales etc.",
      havings: [
        {
          label: "Min No. of pageviews",
          metric: ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
          operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
          value: undefined,
        },
        {
          label: "Max No. of pageviews",
          metric: ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
          operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
          value: undefined,
        },
        {
          label: "Min No. of sales",
          metric: ApiMetric.METRIC_SALES_COUNT,
          operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
          value: 1,
        },
        {
          label: "Max No. of sales",
          metric: ApiMetric.METRIC_SALES_COUNT,
          operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
          value: undefined,
        },
      ],
    },
    cards: ["ltv", "top", "hot"],
    analyseBy: {
      description: "Select what to split products by",
      grouping: [
        {
          option: "",
          dimensions: [
            ApiDimension.DIMENSION_PRODUCT_NAME,
            ApiDimension.DIMENSION_PRODUCT_CATEGORY,
            ApiDimension.DIMENSION_PRODUCT_BRAND,
          ],
        },
      ],
    },
  },
};

function ProductsRankingPage() {
  const [type, setType] = useState<ProductRankType>("leading");
  return (
    <>
      <Portal container={() => document.getElementById("pageTitle")}>
        <Typography variant="h1" mb={2}>
          Product Performance Analysis
        </Typography>

        <TabContainer sx={{ minWidth: 800 }}>
          {Object.keys(config).map((k) => (
            <TabButton
              onClick={() => setType(k as ProductRankType)}
              isActive={type === k}
            >
              <Tooltip
                arrow
                title={
                  <>
                    <Typography variant="subtitle2" color={"inherit"}>
                      {config[k as ProductRankType].title}
                    </Typography>
                    <Typography variant="body2" color={"inherit"}>
                      {config[k as ProductRankType].desc}
                    </Typography>
                  </>
                }
              >
                <span>{config[k as ProductRankType].title}</span>
              </Tooltip>
            </TabButton>
          ))}
        </TabContainer>
      </Portal>

      <ExplorerProvider config={config[type]}>
        <ProductsRanking />
      </ExplorerProvider>
    </>
  );
}

export default ProductsRankingPage;
