/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiExternalClickFromJSON, ApiExternalClickToJSON, } from './ApiExternalClick';
import { ApiServiceFromJSON, ApiServiceToJSON, } from './ApiService';
import { ApiSyncFromJSON, ApiSyncToJSON, } from './ApiSync';
export function ApiExternalCampaignFromJSON(json) {
    return ApiExternalCampaignFromJSONTyped(json, false);
}
export function ApiExternalCampaignFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'service': !exists(json, 'service') ? undefined : ApiServiceFromJSON(json['service']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'clicks': !exists(json, 'clicks') ? undefined : (json['clicks'].map(ApiExternalClickFromJSON)),
        'sync': !exists(json, 'sync') ? undefined : ApiSyncFromJSON(json['sync']),
        'syncId': !exists(json, 'syncId') ? undefined : json['syncId'],
    };
}
export function ApiExternalCampaignToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'externalId': value.externalId,
        'name': value.name,
        'service': ApiServiceToJSON(value.service),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'clicks': value.clicks === undefined ? undefined : (value.clicks.map(ApiExternalClickToJSON)),
        'sync': ApiSyncToJSON(value.sync),
        'syncId': value.syncId,
    };
}
