import {
  ApiProject,
  ApiReport,
  ApiScheduledReportIntervalType,
  ApiScheduledReportPayload,
  ApiScheduledReportType,
} from "@incendium/api";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { scheduledReportService } from "Apis";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import produce from "immer";
import { GenericDialoagProps } from "Interfaces";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";

interface IScheduleReportDialogProps extends GenericDialoagProps {
  project: ApiProject;
  report: ApiReport;
}

type TType = "csv" | "xls" | "link";

function ScheduleReportDialog({
  open,
  setOpen,
  project,
  report,
}: IScheduleReportDialogProps) {
  const [schedule, setSchedule] = useState<ApiScheduledReportPayload>({});
  const [types, setTypes] = useState<TType[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!open) {
      return;
    }
    // fetch report schedule when opened
    const load = async () => {
      const res =
        await scheduledReportService.scheduledReportServiceReadScheduledReport({
          projectId: project.id as number,
          reportId: report.id as number,
        });
      setSchedule(res);
    };
    load();
  }, [open]);

  useEffect(() => {
    if (
      !schedule ||
      !schedule.type ||
      schedule.type === ApiScheduledReportType.SCHEDULED_REPORT_TYPE_NONE
    ) {
      return;
    }
    setTypes(
      (["csv", "xls", "link"] as TType[]).filter((v) =>
        schedule.type?.toLowerCase().includes(v)
      )
    );
  }, [schedule]);

  useEffect(() => {
    if (types.length === 0) {
      return;
    }
  }, [types]);

  const onClose = () => {
    setOpen(false);
    setSchedule({});
  };
  const onSubmit = async () => {
    try {
      await scheduledReportService.scheduledReportServiceUpdateScheduledReport({
        projectId: project.id as number,
        reportId: report.id as number,
        payload: {
          ...schedule,
        },
      });
      enqueueSnackbar("Schedule Saved", {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      onClose();
    } catch (error) {
      enqueueSnackbar("Something Went Wrong", {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }
  };
  return (
    <CustomDialog onClose={onClose} fullWidth maxWidth="sm" open={open}>
      <ValidatorForm onSubmit={onSubmit}>
        <DialogTitle>Scheduling Report</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <Autocomplete
              multiple
              id="tags-filled"
              options={schedule.emails || []}
              freeSolo
              clearOnBlur={true}
              onBlur={(e) => {
                const value = (e.target as any).value;
                if (value && value !== "") {
                  setSchedule(
                    produce(schedule, (draft) => {
                      draft.emails?.push(value);
                    })
                  );
                }
              }}
              value={schedule.emails || []}
              onChange={(e, v) => {
                setSchedule(
                  produce(schedule, (draft) => {
                    draft.emails = v;
                  })
                );
              }}
              renderTags={(value: string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  label="Email Addresses"
                  placeholder="Enter Email and hit enter"
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Typography variant="body1" gutterBottom>
              Frequency of report
            </Typography>
            <ToggleButtonGroup
              size="small"
              value={schedule.intervalType}
              exclusive
              color="secondary"
              onChange={(e, v) => {
                setSchedule(
                  produce(schedule, (draft) => {
                    draft.intervalType = v;
                  })
                );
              }}
            >
              <ToggleButton
                value={
                  ApiScheduledReportIntervalType.SCHEDULER_INTERVAL_TYPE_DAILY
                }
              >
                Daily
              </ToggleButton>
              <ToggleButton
                value={
                  ApiScheduledReportIntervalType.SCHEDULER_INTERVAL_TYPE_WEEKLY
                }
              >
                weekly
              </ToggleButton>
              <ToggleButton
                value={
                  ApiScheduledReportIntervalType.SCHEDULER_INTERVAL_TYPE_FORTNIGHTLY
                }
              >
                fortnightly
              </ToggleButton>
              <ToggleButton
                value={
                  ApiScheduledReportIntervalType.SCHEDULER_INTERVAL_TYPE_MONTHLY
                }
              >
                monthly
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box>
            <Typography variant="body1" gutterBottom>
              Report Format
            </Typography>
            <ToggleButtonGroup
              size="small"
              value={types}
              color="secondary"
              onChange={(e, tt: TType[]) => {
                setSchedule(
                  produce(schedule, (draft) => {
                    if (tt.length === 1) {
                      if (tt.includes("csv")) {
                        draft.type =
                          ApiScheduledReportType.SCHEDULED_REPORT_TYPE_CSV;
                      }
                      if (tt.includes("xls")) {
                        draft.type =
                          ApiScheduledReportType.SCHEDULED_REPORT_TYPE_XLS;
                      }
                      if (tt.includes("link")) {
                        draft.type =
                          ApiScheduledReportType.SCHEDULED_REPORT_TYPE_LINK;
                      }
                    }
                    if (tt.length === 2) {
                      if (tt.includes("csv") && tt.includes("xls")) {
                        draft.type =
                          ApiScheduledReportType.SCHEDULED_REPORT_TYPE_CSV_AND_XLS;
                      }
                      if (tt.includes("csv") && tt.includes("link")) {
                        draft.type =
                          ApiScheduledReportType.SCHEDULED_REPORT_TYPE_CSV_AND_LINK;
                      }
                      if (tt.includes("xls") && tt.includes("link")) {
                        draft.type =
                          ApiScheduledReportType.SCHEDULED_REPORT_TYPE_XLS_AND_LINK;
                      }
                    }

                    if (tt.length === 3) {
                      draft.type =
                        ApiScheduledReportType.SCHEDULED_REPORT_TYPE_CSV_AND_XLS_AND_LINK;
                    }
                  })
                );
              }}
            >
              <ToggleButton value={`csv`}>csv</ToggleButton>
              <ToggleButton value={`xls`}>.xlsx (excel)</ToggleButton>
              <ToggleButton value={`link`}>link</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Go
          </Button>
        </DialogActions>
      </ValidatorForm>
    </CustomDialog>
  );
}

export default ScheduleReportDialog;
