const toRadians = (degrees: number): number => {
  return degrees * (Math.PI / 180);
};

const distance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const R = 6371; // Radius of the earth in km
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};

interface Coordinate<T> {
  [key: string]: any;
  lat: number;
  lon: number;
}

interface Cluster<T> {
  center: Coordinate<T>;
  coords: Coordinate<T>[];
}

/**
 * uses Haversine formula
 * @param coordinates
 * @param maxDistance
 * @returns
 */
export const groupCoordinatesByDistance = <T>(
  coordinates: Coordinate<T>[],
  maxDistance: number
): Cluster<T>[] => {
  const clusters: Cluster<T>[] = [];
  coordinates.forEach((coord) => {
    let added = false;
    clusters.forEach((cluster) => {
      const center = cluster.center;
      const dist = distance(coord.lat, coord.lon, center.lat, center.lon);
      if (dist < maxDistance) {
        cluster.coords.push(coord);
        added = true;
      }
    });
    if (!added) {
      clusters.push({
        center: coord,
        coords: [coord],
      });
    }
  });
  return clusters;
};
