import { styled, Typography } from "@mui/material";

// todo: lines as prop
export const MulitlineElipsed = styled(Typography)(({ theme }) => ({
  display: "-webkit-box",
  "-webkit-line-clamp": "2",
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
}));

export const TypographyUnlined = styled(Typography)(({ theme }) => ({
  position: "relative",
  "&:before": {
    content: "''",
    position: "absolute",
    height: 3,
    width: "40%",
    background: theme.palette.secondary.main,
    bottom: -3,
  },
}));
