import { ApiMetric } from "@incendium/api";
import { ApiDimension } from "@incendium/api";
import { ContentExplorer } from "features/explorer";
import { ExplorerProvider } from "features/explorer";
import { TExploreConfig } from "features/explorer/types";
import { useMemo } from "react";

type Tab = "explore";

function JourneyExplorerPage() {
  const config: TExploreConfig<Tab> = useMemo(
    () => ({
      explore: {
        title: "User journey explorer",
        desc: `Discover how your visitors are converting and the most popular paths to conversion. 
        Adapt your site and marketing to drive more people through these funnels.
        Results are restricted to the first 1,000 unique journeys.`,
        type: "top",
        ranking: [],
        analyseBy: {
          description: "Select which type of journey to explore",
          grouping: [
            {
              option: "Conversion",
              dimensions: [
                ApiDimension.DIMENSION_CONVERTING_SESSION_JOURNEY,
                ApiDimension.DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY,
              ],
            },
            {
              option: "Sales",
              dimensions: [
                ApiDimension.DIMENSION_SALES_SESSION_JOURNEY,
                ApiDimension.DIMENSION_CHANNEL_SALES_JOURNEY,
              ],
            },
          ],
        },
      },
    }),
    []
  );

  return (
    <>
      <ExplorerProvider config={config["explore"]}>
        <ContentExplorer
          noTabs
          dimensionNoClick
          metrics={[
            ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
            ApiMetric.METRIC_SESSION_SALES_COUNT,
            ApiMetric.METRIC_SESSION_REVENUE,
            ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION,
            ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE_DURING_SESSION,
            ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE_DURING_SESSION,
          ]}
          limit={1000}
        />
      </ExplorerProvider>
    </>
  );
}

export default JourneyExplorerPage;
