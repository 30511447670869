import { TextField } from "@mui/material";
import produce from "immer";
import { useReportBuilderContext } from "Providers/ReportBuilderProvider";
import { ChartBuilderSidebarBlock } from "../";

function ChartBuilderName() {
  const { chart, setChart } = useReportBuilderContext();
  return (
    <ChartBuilderSidebarBlock>
      <TextField
        label="Name Your Chart"
        fullWidth
        size="small"
        value={chart.name}
        onChange={(e) =>
          setChart(
            produce(chart, (draft) => {
              draft.name = e.target.value;
            })
          )
        }
      />
    </ChartBuilderSidebarBlock>
  );
}

export default ChartBuilderName;
