import { TextField, TextFieldProps } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import moment from "moment-timezone";

const zones = moment.tz.names();

function TimezoneSelect(props: TextFieldProps) {
  const { onChange, value, ...rest } = props;

  return (
    <Autocomplete
      value={value}
      options={zones.map((zone) => zone)}
      onChange={(_, value) =>
        onChange &&
        onChange({
          target: { value },
        } as React.ChangeEvent<HTMLInputElement>)
      }
      renderInput={(params) => <TextField {...params} {...rest} />}
    />
  );
}

export default TimezoneSelect;
