import { Portal, Stack, Switch, Typography } from "@mui/material";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { PageSubAction } from "consts";
import { CampaignAnalysisExplorer } from "features/attributionAnalytics";
import { useState } from "react";

function CampaignAnalysis() {
  const [exploreMode, setExploreMode] = useState(true);
  return (
    <>
      <Portal container={() => document.getElementById(PageSubAction)}>
        <StyledFlexBox>
          <Stack direction={"row"} alignItems="center" mr={4}>
            <Typography color={"secondary"}>Analyze</Typography>
            <Switch
              checked={exploreMode}
              onChange={(e, checked) => setExploreMode(checked)}
            />
            <Typography color={"primary"}>Explore</Typography>
          </Stack>
          <ClickableTooltip
            placement="bottom"
            width={335}
            text={
              <>
                <Typography variant="body2" color={"inherit"} mb={1}>
                  <strong>Analyze</strong>: Gain an overview with a standard
                  table showcasing the full picture of your campaigns.
                </Typography>
                <Typography variant="body2" color={"inherit"}>
                  <strong>Explore</strong>: Dive deeper by drilling down into
                  your campaigns. (Handy tip: Hold Shift while selecting options
                  to choose multiple items.)"
                </Typography>
              </>
            }
            icon={<StyledHelp />}
          />
        </StyledFlexBox>
      </Portal>
      <CampaignAnalysisExplorer mode={exploreMode ? "explore" : "anlyze"} />
    </>
  );
}

export default CampaignAnalysis;
