import { useSelector } from "react-redux";
import { locationSelector } from "Selectors/locationSelector";
import { useClientIdFromUrl } from "./useClientIdFromUrl";
import { useLocationIdFromUrl } from "./useLocationIdFromUrl";
import { useProjectIdFromUrl } from "./useProjectIdFromUrl";

export const useLocationSelector = () => {
  const clientId = useClientIdFromUrl();
  const projectId = useProjectIdFromUrl();
  const locationId = useLocationIdFromUrl();

  const location = useSelector(
    locationSelector(clientId, projectId, locationId)
  );
  return location;
};
