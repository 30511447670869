import { ApiBucketRule, ApiChannelRule, ApiOperator } from "@incendium/api";
import { Box, Grid, MenuItem, TextField } from "@mui/material";
import ConditionalGroup from "Components/ConditionalGroup/ConditionalGroup";
import {
  IChannelGroupOverride,
  useConditionGroupContext,
} from "Components/ConditionalGroup/ConditionGroupProvider";
import produce from "immer";
import { useMemo } from "react";
import { useEffect } from "react";
import { enumToArray } from "Helpers/enumToText";
import { friendlyOperator } from "Helpers/operators";

const Row = ({ groupIdx, rowIdx }: { groupIdx: number; rowIdx: number }) => {
  const { conditionGroups, setConditionGroups } = useConditionGroupContext();

  const condition: ApiChannelRule | ApiBucketRule = useMemo(() => {
    return (conditionGroups[groupIdx]?.conditions || [])[rowIdx];
  }, [conditionGroups, groupIdx, rowIdx]);

  const operators = useMemo(() => {
    return enumToArray(ApiOperator).filter(
      (item) =>
        ![
          ApiOperator.OPERATOR_UNASSIGNED,
          ApiOperator.IS_GREATER_THAN,
          ApiOperator.IS_LESS_THAN,
          ApiOperator.IS_GREATER_THAN_OR_EQUAL,
          ApiOperator.IS_LESS_THAN_OR_EQUAL,
        ].includes(item)
    );
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <TextField
          variant="outlined"
          label="Field"
          value={condition?.field || ""}
          onChange={(e) => {
            setConditionGroups(
              produce(conditionGroups, (draft: IChannelGroupOverride[]) => {
                (draft[groupIdx]?.conditions || [])[rowIdx].field =
                  e.target.value;
              })
            );
          }}
          select
          fullWidth
        >
          <MenuItem value="source">Source</MenuItem>
          <MenuItem value="medium">Medium</MenuItem>
          <MenuItem value="campaign">Campaign</MenuItem>
          <MenuItem value="term">Term</MenuItem>
          <MenuItem value="content">Content</MenuItem>
          <MenuItem value="keyword">Keyword</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={3}>
        <TextField
          select
          fullWidth
          variant="outlined"
          label="Operator"
          value={condition?.operator || ""}
          onChange={(e) => {
            setConditionGroups(
              produce(conditionGroups, (draft: IChannelGroupOverride[]) => {
                (draft[groupIdx]?.conditions || [])[rowIdx].operator = e.target
                  .value as ApiOperator;
              })
            );
          }}
        >
          {operators.map((o) => (
            <MenuItem key={o} value={o}>
              {friendlyOperator(o)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={5}>
        <TextField
          label="value"
          fullWidth
          variant="outlined"
          value={condition?.value || ""}
          onChange={(e) => {
            setConditionGroups(
              produce(conditionGroups, (draft: IChannelGroupOverride[]) => {
                (draft[groupIdx]?.conditions || [])[rowIdx].value =
                  e.target.value;
              })
            );
          }}
        />
        {/* todo: auto complete set options based on field */}
        {/* <Autocomplete
      options={[]}
      // getOptionLabel={(option) => option.name || ""}
      // onChange={(_, v) => {
      //   setLocation(v || {});
      // }}
      // value={location}
      fullWidth
      size="small"
      renderInput={(params: any) => (
        <TextField {...params} label="Value" variant="outlined" />
      )}
    /> */}
      </Grid>
    </Grid>
  );
};

function SourceRules() {
  const { setRowComponent } = useConditionGroupContext();

  useEffect(() => {
    setRowComponent(() => (groupIdx, rowIdx) => {
      return <Row groupIdx={groupIdx} rowIdx={rowIdx} />;
    });
  }, []);

  return (
    <Box>
      <ConditionalGroup />
    </Box>
  );
}

export default SourceRules;
