import { CircularProgress } from "@mui/material";
import { initializeProjectsThunk } from "Actions";
import { EmptyComponent } from "Components/EmptyComponent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { projectListSelector } from "Selectors/projectListSelector";
import { useSelectedProject } from "Hooks";
import { ProjectDialog, ProjectsList } from "features/project";
import withPagetitle, { IWithPagetitleProps } from "HoC/withPagetitle";

const ClientPageInner = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useDispatch();
  const projects = useSelector(projectListSelector(clientId));

  useEffect(() => {
    dispatch(initializeProjectsThunk(Number(clientId)));
  }, [clientId]);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const renderData = () => {
    if (!projects.hasLoaded) {
      return <CircularProgress />;
    }
    if (projects.list.length === 0) {
      return (
        <>
          <EmptyComponent
            content="It looks like you don’t have any projects set up."
            onBtnClick={handleOpen}
            btnText="add project"
          />
        </>
      );
    } else {
      return (
        <>
          <ProjectsList
            projects={projects.list.map(({ project }) => project)}
            onClick={handleOpen}
          />
        </>
      );
    }
  };

  return (
    <>
      {renderData()}
      {open && <ProjectDialog open={open} setOpen={setOpen} />}
    </>
  );
};

const EnhancedComponent = withPagetitle(
  ClientPageInner
) as React.ComponentType<IWithPagetitleProps>;

function ClientPage() {
  const { selectedClient } = useSelectedProject();
  return (
    <EnhancedComponent
      title={`Manage ${selectedClient?.businessName} Projects`}
      subTitle={`Create / Update or Delete Projects for  ${selectedClient?.businessName}.`}
    />
  );
}

export default ClientPage;
