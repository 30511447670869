import { useState } from "react";

type UseArrayReturnType<T> = {
  array: T[];
  set: React.Dispatch<React.SetStateAction<T[]>>;
  push: (element: T) => void;
  unique: (array: T[]) => T[];
  filter: (callback: (value: T, index: number, array: T[]) => boolean) => void;
  forEach: (callback: (value: T, index: number, array: T[]) => void) => void;
  forEachUnique: (
    callback: (value: T, index: number, array: T[]) => void
  ) => void;
  update: (index: number, newElement: T) => void;
  remove: (index: number) => void;
  clear: () => void;
};

export default function useArray<T>(defaultValue: T[]): UseArrayReturnType<T> {
  const [array, setArray] = useState<T[]>(defaultValue);

  function push(element: T) {
    setArray((a) => [...a, element]);
  }

  function unique() {
    return [...new Set(array)];
  }

  function filter(callback: (value: T, index: number, array: T[]) => boolean) {
    setArray((a) => a.filter(callback));
  }

  function forEach(callback: (value: T, index: number, array: T[]) => void) {
    array.forEach(callback);
  }

  function forEachUnique(
    callback: (value: T, index: number, array: T[]) => void
  ) {
    unique().forEach(callback);
  }

  function update(index: number, newElement: T) {
    setArray((a) => [
      ...a.slice(0, index),
      newElement,
      ...a.slice(index + 1, a.length),
    ]);
  }

  function remove(index: number) {
    setArray((a) => [...a.slice(0, index), ...a.slice(index + 1, a.length)]);
  }

  function clear() {
    setArray([]);
  }

  return {
    array,
    set: setArray,
    push,
    filter,
    forEach,
    forEachUnique,
    update,
    remove,
    clear,
    unique,
  };
}
