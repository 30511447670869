import { ApiChartTemplate } from "@incendium/api";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import { useSelectedProject } from "Hooks";
import { useCharts } from "Hooks/useCharts";
import { GenericDialoagProps, IChart } from "Interfaces";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import NewChartForm from "./NewChartForm";

interface INewChartDialogProps extends GenericDialoagProps {
  chart: IChart;
  setChart: React.Dispatch<React.SetStateAction<IChart>>;
  onClick: (id: number) => void;
}

function NewChartDialog({
  open,
  setOpen,
  chart,
  setChart,
  onClick,
}: INewChartDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { saveNewChart } = useCharts();
  const { selectedProject } = useSelectedProject();

  useEffect(() => {
    if (!open) {
      setChart({
        yAxisKeys: [],
        attributes: [],
      });
    }
  }, [open, setChart]);

  const onSave = async () => {
    try {
      const res = await saveNewChart(chart, selectedProject);

      enqueueSnackbar(`${res.name} Created`, {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      onClick(res.id as number);
    } catch (error) {
      enqueueSnackbar("Something Went Wrong", {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }
  };

  return (
    <CustomDialog
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth="sm"
      open={open}
    >
      <DialogTitle>Setup</DialogTitle>
      <DialogContent>
        <NewChartForm chart={chart} setChart={setChart} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="secondary">
          Cancel
        </Button>
        <Button
          disabled={
            chart.name === "" ||
            !chart.template ||
            chart.template === ApiChartTemplate.UNDEFINED
          }
          onClick={onSave}
          color="primary"
        >
          Go
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}

export default NewChartDialog;
