export const getPathFromUrl = (urlString) => {
  const url = new URL(urlString);
  return url.pathname;
};

export const removeTrailingSlash = (src: string) => {
  if (src.endsWith("/")) {
    return src.slice(0, src.length - 1);
  }
  return src;
};

export const appendTrailingSlash = (src: string) => {
  if (src.endsWith("/")) {
    return src;
  }
  return `${src}/`;
};
