/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiLeadScoreAdjustmentRuleFromJSON, ApiListRulesResponseFromJSON, ApiRulePayloadToJSON, } from '../models';
/**
 *
 */
export class LeadScoreAdjustmentRuleServiceApi extends runtime.BaseAPI {
    /**
     */
    leadScoreAdjustmentRuleServiceCreateRuleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling leadScoreAdjustmentRuleServiceCreateRule.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling leadScoreAdjustmentRuleServiceCreateRule.');
            }
            const queryParameters = {};
            if (requestParameters.ruleId !== undefined) {
                queryParameters['ruleId'] = requestParameters.ruleId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/adjustment-rule/v1/projects/{projectId}/rules`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiRulePayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiLeadScoreAdjustmentRuleFromJSON(jsonValue));
        });
    }
    /**
     */
    leadScoreAdjustmentRuleServiceCreateRule(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.leadScoreAdjustmentRuleServiceCreateRuleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    leadScoreAdjustmentRuleServiceDeleteRuleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling leadScoreAdjustmentRuleServiceDeleteRule.');
            }
            if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
                throw new runtime.RequiredError('ruleId', 'Required parameter requestParameters.ruleId was null or undefined when calling leadScoreAdjustmentRuleServiceDeleteRule.');
            }
            const queryParameters = {};
            if (requestParameters.payloadScore !== undefined) {
                queryParameters['payload.score'] = requestParameters.payloadScore;
            }
            if (requestParameters.payloadValue !== undefined) {
                queryParameters['payload.value'] = requestParameters.payloadValue;
            }
            if (requestParameters.payloadReason !== undefined) {
                queryParameters['payload.reason'] = requestParameters.payloadReason;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/adjustment-rule/v1/projects/{projectId}/rules/{ruleId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    leadScoreAdjustmentRuleServiceDeleteRule(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.leadScoreAdjustmentRuleServiceDeleteRuleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    leadScoreAdjustmentRuleServiceListRulesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling leadScoreAdjustmentRuleServiceListRules.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/adjustment-rule/v1/projects/{projectId}/rules`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListRulesResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    leadScoreAdjustmentRuleServiceListRules(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.leadScoreAdjustmentRuleServiceListRulesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    leadScoreAdjustmentRuleServiceReadRuleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling leadScoreAdjustmentRuleServiceReadRule.');
            }
            if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
                throw new runtime.RequiredError('ruleId', 'Required parameter requestParameters.ruleId was null or undefined when calling leadScoreAdjustmentRuleServiceReadRule.');
            }
            const queryParameters = {};
            if (requestParameters.payloadScore !== undefined) {
                queryParameters['payload.score'] = requestParameters.payloadScore;
            }
            if (requestParameters.payloadValue !== undefined) {
                queryParameters['payload.value'] = requestParameters.payloadValue;
            }
            if (requestParameters.payloadReason !== undefined) {
                queryParameters['payload.reason'] = requestParameters.payloadReason;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/adjustment-rule/v1/projects/{projectId}/rules/{ruleId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiLeadScoreAdjustmentRuleFromJSON(jsonValue));
        });
    }
    /**
     */
    leadScoreAdjustmentRuleServiceReadRule(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.leadScoreAdjustmentRuleServiceReadRuleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    leadScoreAdjustmentRuleServiceUpdateRuleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling leadScoreAdjustmentRuleServiceUpdateRule.');
            }
            if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
                throw new runtime.RequiredError('ruleId', 'Required parameter requestParameters.ruleId was null or undefined when calling leadScoreAdjustmentRuleServiceUpdateRule.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling leadScoreAdjustmentRuleServiceUpdateRule.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/adjustment-rule/v1/projects/{projectId}/rules/{ruleId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiRulePayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiLeadScoreAdjustmentRuleFromJSON(jsonValue));
        });
    }
    /**
     */
    leadScoreAdjustmentRuleServiceUpdateRule(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.leadScoreAdjustmentRuleServiceUpdateRuleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
/**
    * @export
    * @enum {string}
    */
export var LeadScoreAdjustmentRuleServiceDeleteRulePayloadReasonEnum;
(function (LeadScoreAdjustmentRuleServiceDeleteRulePayloadReasonEnum) {
    LeadScoreAdjustmentRuleServiceDeleteRulePayloadReasonEnum["LEAD_SCORE_ADJUSTMENT_REASON_INACTIVITY"] = "LEAD_SCORE_ADJUSTMENT_REASON_INACTIVITY";
})(LeadScoreAdjustmentRuleServiceDeleteRulePayloadReasonEnum || (LeadScoreAdjustmentRuleServiceDeleteRulePayloadReasonEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LeadScoreAdjustmentRuleServiceReadRulePayloadReasonEnum;
(function (LeadScoreAdjustmentRuleServiceReadRulePayloadReasonEnum) {
    LeadScoreAdjustmentRuleServiceReadRulePayloadReasonEnum["LEAD_SCORE_ADJUSTMENT_REASON_INACTIVITY"] = "LEAD_SCORE_ADJUSTMENT_REASON_INACTIVITY";
})(LeadScoreAdjustmentRuleServiceReadRulePayloadReasonEnum || (LeadScoreAdjustmentRuleServiceReadRulePayloadReasonEnum = {}));
