import { ApiPageviewMetaTagger, ApiReservedPageviewMeta } from "@incendium/api";
import { Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import produce from "immer";
import { IPageMetaFormProps } from "features/pageMeta/interfaces";
import CssSelector from "features/pageMeta/components/CssSelector";

function OutOfStockForm({ pageviewMetaTaggers, onSubmit }: IPageMetaFormProps) {
  const [tagger, setTagger] = useState<ApiPageviewMetaTagger>({
    reserved: ApiReservedPageviewMeta.RESERVERED_OUT_OF_STOCK,
  });

  useEffect(() => {
    if (!pageviewMetaTaggers) {
      return;
    }
    const idx = pageviewMetaTaggers.findIndex(
      (t) => t.reserved === ApiReservedPageviewMeta.RESERVERED_OUT_OF_STOCK
    );
    if (idx >= 0) {
      setTimeout(() => {
        setTagger(pageviewMetaTaggers[idx]);
      }, 1);
    }
  }, [pageviewMetaTaggers]);

  const onValueChange = (e: React.FormEvent<HTMLInputElement>) => {
    setTagger(
      produce(tagger, (draft) => {
        draft.value = e.currentTarget.value;
      })
    );
  };

  return (
    <>
      <ValidatorForm onSubmit={() => onSubmit(tagger)}>
        <Typography variant="body2">
          Select an area on the page and text it should contain.
        </Typography>
        <Typography variant="body2">
          If that area contains that text we shall mark this page visit as out
          of stock.
        </Typography>

        <Stack spacing={2} mt={3}>
          <CssSelector
            selector={tagger.selector}
            onChange={(v) => {
              setTagger(
                produce(tagger, (draft) => {
                  draft.selector = v;
                })
              );
            }}
          />
          <TextValidator
            value={tagger.value || ""}
            variant="outlined"
            name="selector"
            label="Contains Value"
            fullWidth
            validators={["required"]}
            errorMessages={["value is required"]}
            onChange={onValueChange}
          />
          <Button type="submit">Save</Button>
        </Stack>
      </ValidatorForm>
    </>
  );
}

export default OutOfStockForm;
