import {
  SiderBarHeader,
  SidebarContentWrapper,
  SidebarStyledDivider,
  SidebarSubtitle,
  SidebarActionButton,
  SidebarListButton,
} from "Components/UI/Sidebar";
import { Box, TextField, Typography } from "@mui/material";
import { TextValidator } from "react-material-ui-form-validator";
import { ApiAlert } from "@incendium/api";
import produce from "immer";
import { AddCircle, Email } from "@mui/icons-material";
import Delete from "@mui/icons-material/Delete";

interface IAlertSidebarProps {
  alert: ApiAlert;
  setAlert: React.Dispatch<React.SetStateAction<ApiAlert | undefined>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function AlertSidebar({ alert, setAlert, setOpen }: IAlertSidebarProps) {
  const addComms = () => {
    setOpen(true);
  };

  return (
    <>
      <SiderBarHeader>
        <Typography variant="h2">{alert.name} Settings</Typography>
        <Typography>Describe alert and choose mode of contact</Typography>
      </SiderBarHeader>
      <SidebarContentWrapper px={3}>
        <Box mb={3.5}>
          <TextValidator
            label="name of alert"
            variant="outlined"
            name="name"
            fullWidth
            validators={["required"]}
            errorMessages={["name is required"]}
            value={alert.name || ""}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setAlert(
                produce(alert, (draft) => {
                  draft.name = e.currentTarget.value;
                })
              );
            }}
          />
        </Box>
        <Box mb={3.5}>
          <TextField
            label="description"
            variant="outlined"
            name="description"
            fullWidth
            value={alert.description || ""}
            multiline
            minRows={5}
            maxRows={8}
            onChange={(e) => {
              setAlert(
                produce(alert, (draft) => {
                  draft.description = e.currentTarget.value;
                })
              );
            }}
          />
        </Box>
        <Box mb={2}>
          <SidebarStyledDivider />
        </Box>
        <Box mb={2}>
          <SidebarSubtitle>How should we contact you?</SidebarSubtitle>
        </Box>
        <SidebarActionButton
          endIcon={<AddCircle color="primary" />}
          onClick={addComms}
          disabled={!!alert.email}
        >
          add new comms channel {}
        </SidebarActionButton>
        {alert.email && (
          <SidebarListButton
            startIcon={<Email color="info" />}
            onClick={() => {
              setAlert(
                produce(alert, (draft) => {
                  draft.email = undefined;
                })
              );
            }}
            endIcon={<Delete />}
          >
            {alert.email}
          </SidebarListButton>
        )}
      </SidebarContentWrapper>
    </>
  );
}

export default AlertSidebar;
