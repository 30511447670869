import { ApiLeadStatusRule } from "@incendium/api";
import { Box, CircularProgress, Portal } from "@mui/material";
import { leadStatusService } from "Apis";
import ConditionGroupProvider from "Components/ConditionalGroup/ConditionGroupProvider";
import LeadStatusRuleForm from "Components/LeadStatusRule/LeadStatusRuleForm";
import { TypographyHelp } from "Components/UI/TypographyHelp";
import { useMount, useProjectSelector } from "Hooks";
import { useConversions } from "Hooks/useConversions";
import { useState, useMemo } from "react";
import { useParams } from "react-router-dom";

function LeadPage() {
  const project = useProjectSelector();
  const { leadStatusRuleId } = useParams<{ leadStatusRuleId: string }>();
  const [leadStatusRule, setLeadStatusRule] = useState<
    ApiLeadStatusRule | undefined
  >(undefined);
  const { conversions } = useConversions();

  useMount(() => {
    if (!leadStatusRuleId) {
      return;
    }
    const load = async () => {
      const res = await leadStatusService.leadStatusServiceReadLeadStatusRule({
        projectId: project?.id as number,
        leadStatusRuleId: Number(leadStatusRuleId),
      });
      setLeadStatusRule(res);
    };
    load();
  });

  const filteredConversions = useMemo(() => {
    return [...conversions];
  }, [leadStatusRule, conversions]);

  if (!project || !leadStatusRule) {
    return <CircularProgress />;
  }

  return (
    <Box mt={5}>
      <ConditionGroupProvider>
        <Portal container={() => document.getElementById("pageTitle")}>
          <TypographyHelp
            variant="h1"
            text="Lead Status Rule Setup"
            width={335}
            tooltip={
              <>
                <p>todo</p>
              </>
            }
          />
        </Portal>
        <LeadStatusRuleForm
          leadStatusRule={leadStatusRule}
          setLeadStatusRule={setLeadStatusRule}
          project={project}
          conversions={filteredConversions}
        />
      </ConditionGroupProvider>
    </Box>
  );
}

export default LeadPage;
