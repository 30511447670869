/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiMediaFilePayloadFromJSON, ApiMediaFilePayloadToJSON, } from './ApiMediaFilePayload';
import { ApiMediaTypeFromJSON, ApiMediaTypeToJSON, } from './ApiMediaType';
export function ApiMediaPayloadFromJSON(json) {
    return ApiMediaPayloadFromJSONTyped(json, false);
}
export function ApiMediaPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : ApiMediaTypeFromJSON(json['type']),
        'files': !exists(json, 'files') ? undefined : (json['files'].map(ApiMediaFilePayloadFromJSON)),
    };
}
export function ApiMediaPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'type': ApiMediaTypeToJSON(value.type),
        'files': value.files === undefined ? undefined : (value.files.map(ApiMediaFilePayloadToJSON)),
    };
}
