import React from "react";
import { motion } from "framer-motion";

function Appear({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          delay: 0.2,
        },
      }}
    >
      {children}
    </motion.div>
  );
}

export default Appear;
