export const isEmpty = (obj: Object): boolean =>
  obj &&
  Object.keys(obj).length === 0 &&
  Object.getPrototypeOf(obj) === Object.prototype;

export const isArrayEmpty = <T>(arr?: T[]): boolean => {
  return (
    !arr ||
    arr.length === 0 ||
    arr.every((item) => {
      if (item === null || item === undefined) {
        return true;
      }
      if (typeof item === "string") {
        return item.trim() === "";
      }
      if (typeof item === "object") {
        return isEmpty(item);
      }
      return false;
    })
  );
};
