import { ApiProjectSettingKey } from "@incendium/api";
import { saveProjectSetting } from "features/project";
import { useSelectedProject } from "Hooks";
import produce from "immer";
import { isEqual } from "lodash";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { usePrevious } from "react-use";

interface UIState {
  leftMenu: { [t: string]: boolean };
}

type TLeftmenuProviderContext = {
  value: UIState;
  toggleVisibility: (to: string) => void;
  leftMenuLinkIsVisible: (to: string) => boolean;
};

export const LeftmenuProviderContext = createContext<TLeftmenuProviderContext>({
  value: { leftMenu: {} },
  toggleVisibility: () => {},
  leftMenuLinkIsVisible: () => true,
});

export const useLeftmenuProviderContext = () =>
  useContext(LeftmenuProviderContext);

function LeftmenuProvider({ children }: { children: React.ReactNode }) {
  const [value, setValue] = useState<UIState>({ leftMenu: {} });
  const prevValue = usePrevious(value);
  const { selectedProject, projectSettings, setProjectSettings } =
    useSelectedProject();
  const prevUiState = usePrevious(
    projectSettings[ApiProjectSettingKey.UI_STATE]
  );

  const save = useCallback(async () => {
    await saveProjectSetting(
      {
        key: ApiProjectSettingKey.UI_STATE,
        value: projectSettings[ApiProjectSettingKey.UI_STATE],
      },
      selectedProject!
    );
  }, [selectedProject, projectSettings]);

  useEffect(() => {
    if (
      !selectedProject ||
      !projectSettings ||
      !projectSettings[ApiProjectSettingKey.UI_STATE]
    ) {
      return;
    }

    const parsedValue = JSON.parse(
      projectSettings[ApiProjectSettingKey.UI_STATE] || ""
    );
    if (
      prevUiState &&
      isEqual(projectSettings[ApiProjectSettingKey.UI_STATE], prevUiState)
    ) {
      return;
    }

    save();
    setValue(parsedValue);
  }, [projectSettings, save, selectedProject, prevUiState]);

  useEffect(() => {
    if (!isEqual(value, prevValue)) {
      setProjectSettings((ps) =>
        produce(ps, (draft) => {
          draft[ApiProjectSettingKey.UI_STATE] = JSON.stringify(value);
        })
      );
    }
  }, [value, prevValue, setProjectSettings]);

  const toggleVisibility = useCallback(
    (to: string) => {
      setValue(
        produce(value, (draft) => {
          const isVisible =
            value.leftMenu[to] !== undefined ? value.leftMenu[to] : true;

          // since we only care when visabilty is false, as is true by default, clean the state of any true visibiltys
          Object.keys(draft.leftMenu).forEach((k) => {
            if (draft.leftMenu[k]) {
              delete draft.leftMenu[k];
            }
          });

          draft.leftMenu = { ...draft.leftMenu, [to]: !isVisible };
        })
      );
    },
    [setValue, value]
  );

  const leftMenuLinkIsVisible = useCallback(
    (to: string) => {
      return value?.leftMenu && value?.leftMenu[to] !== undefined
        ? value?.leftMenu[to]
        : true;
    },
    [value]
  );

  const state = useMemo(
    () => ({
      value,
      toggleVisibility,
      leftMenuLinkIsVisible,
    }),
    [value, toggleVisibility, leftMenuLinkIsVisible]
  );

  return (
    <LeftmenuProviderContext.Provider value={state}>
      {children}
    </LeftmenuProviderContext.Provider>
  );
}

export default LeftmenuProvider;
