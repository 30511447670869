import { formatNumber } from "./numbers";

export const percentageChange = (prev: number, curr: number) => {
  return prev === 0 && curr === 0
    ? 0
    : prev === 0
    ? 100
    : ((curr - prev) / prev) * 100;
};
export const percentageOf = (value: number, of: number) => {
  return (value / of) * 100;
};

export const percentage = (n1: number, n2: number) => {
  return (n1 / 100) * n2;
};

export const formatPercentage = (n: number) => {
  return formatNumber(n) + "%";
};
