/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiAttributionClickDateGrouping;
(function (ApiAttributionClickDateGrouping) {
    ApiAttributionClickDateGrouping["DAILY"] = "DAILY";
    ApiAttributionClickDateGrouping["MONTHLY"] = "MONTHLY";
})(ApiAttributionClickDateGrouping || (ApiAttributionClickDateGrouping = {}));
export function ApiAttributionClickDateGroupingFromJSON(json) {
    return ApiAttributionClickDateGroupingFromJSONTyped(json, false);
}
export function ApiAttributionClickDateGroupingFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiAttributionClickDateGroupingToJSON(value) {
    return value;
}
