/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiAudienceFromJSON, ApiAudiencePayloadToJSON, ApiClassificationFromJSON, ApiClassificationGroupFromJSON, ApiClassificationGroupPayloadToJSON, ApiClassificationItemFromJSON, ApiClassificationItemPayloadToJSON, ApiClassificationPayloadToJSON, ApiListAudiencesResponseFromJSON, ApiListClassificationGroupsResponseFromJSON, ApiListClassificationsResponseFromJSON, ApiReorderClassificationItemsPayloadToJSON, ApiTestAudienceResponseFromJSON, } from '../models';
/**
 *
 */
export class AudienceServiceApi extends runtime.BaseAPI {
    /**
     */
    audienceServiceCreateAudienceRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling audienceServiceCreateAudience.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling audienceServiceCreateAudience.');
            }
            const queryParameters = {};
            if (requestParameters.audienceId !== undefined) {
                queryParameters['audienceId'] = requestParameters.audienceId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/audience/v1/projects/{projectId}/audiences`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiAudiencePayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiAudienceFromJSON(jsonValue));
        });
    }
    /**
     */
    audienceServiceCreateAudience(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.audienceServiceCreateAudienceRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    audienceServiceCreateClassificationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling audienceServiceCreateClassification.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling audienceServiceCreateClassification.');
            }
            const queryParameters = {};
            if (requestParameters.classificationId !== undefined) {
                queryParameters['classificationId'] = requestParameters.classificationId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/audience/v1/projects/{projectId}/classifications`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiClassificationPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiClassificationFromJSON(jsonValue));
        });
    }
    /**
     */
    audienceServiceCreateClassification(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.audienceServiceCreateClassificationRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    audienceServiceCreateClassificationGroupRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling audienceServiceCreateClassificationGroup.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling audienceServiceCreateClassificationGroup.');
            }
            const queryParameters = {};
            if (requestParameters.classificationGroupId !== undefined) {
                queryParameters['classificationGroupId'] = requestParameters.classificationGroupId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/audience/v1/projects/{projectId}/classification-groups`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiClassificationGroupPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiClassificationGroupFromJSON(jsonValue));
        });
    }
    /**
     */
    audienceServiceCreateClassificationGroup(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.audienceServiceCreateClassificationGroupRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * items
     */
    audienceServiceCreateClassificationItemRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling audienceServiceCreateClassificationItem.');
            }
            if (requestParameters.classificationId === null || requestParameters.classificationId === undefined) {
                throw new runtime.RequiredError('classificationId', 'Required parameter requestParameters.classificationId was null or undefined when calling audienceServiceCreateClassificationItem.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling audienceServiceCreateClassificationItem.');
            }
            const queryParameters = {};
            if (requestParameters.classificationItemId !== undefined) {
                queryParameters['classificationItemId'] = requestParameters.classificationItemId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/audience/v1/projects/{projectId}/classifications/{classificationId}/items`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"classificationId"}}`, encodeURIComponent(String(requestParameters.classificationId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiClassificationItemPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiClassificationItemFromJSON(jsonValue));
        });
    }
    /**
     * items
     */
    audienceServiceCreateClassificationItem(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.audienceServiceCreateClassificationItemRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    audienceServiceDeleteAudienceRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling audienceServiceDeleteAudience.');
            }
            if (requestParameters.audienceId === null || requestParameters.audienceId === undefined) {
                throw new runtime.RequiredError('audienceId', 'Required parameter requestParameters.audienceId was null or undefined when calling audienceServiceDeleteAudience.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/audience/v1/projects/{projectId}/audiences/{audienceId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"audienceId"}}`, encodeURIComponent(String(requestParameters.audienceId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    audienceServiceDeleteAudience(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.audienceServiceDeleteAudienceRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    audienceServiceDeleteClassificationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling audienceServiceDeleteClassification.');
            }
            if (requestParameters.classificationId === null || requestParameters.classificationId === undefined) {
                throw new runtime.RequiredError('classificationId', 'Required parameter requestParameters.classificationId was null or undefined when calling audienceServiceDeleteClassification.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadClassificationGroupId !== undefined) {
                queryParameters['payload.classificationGroupId'] = requestParameters.payloadClassificationGroupId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/audience/v1/projects/{projectId}/classifications/{classificationId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"classificationId"}}`, encodeURIComponent(String(requestParameters.classificationId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    audienceServiceDeleteClassification(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.audienceServiceDeleteClassificationRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    audienceServiceDeleteClassificationGroupRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling audienceServiceDeleteClassificationGroup.');
            }
            if (requestParameters.classificationGroupId === null || requestParameters.classificationGroupId === undefined) {
                throw new runtime.RequiredError('classificationGroupId', 'Required parameter requestParameters.classificationGroupId was null or undefined when calling audienceServiceDeleteClassificationGroup.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/audience/v1/projects/{projectId}/classification-groups/{classificationGroupId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"classificationGroupId"}}`, encodeURIComponent(String(requestParameters.classificationGroupId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    audienceServiceDeleteClassificationGroup(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.audienceServiceDeleteClassificationGroupRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    audienceServiceDeleteClassificationItemRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling audienceServiceDeleteClassificationItem.');
            }
            if (requestParameters.classificationId === null || requestParameters.classificationId === undefined) {
                throw new runtime.RequiredError('classificationId', 'Required parameter requestParameters.classificationId was null or undefined when calling audienceServiceDeleteClassificationItem.');
            }
            if (requestParameters.classificationItemId === null || requestParameters.classificationItemId === undefined) {
                throw new runtime.RequiredError('classificationItemId', 'Required parameter requestParameters.classificationItemId was null or undefined when calling audienceServiceDeleteClassificationItem.');
            }
            const queryParameters = {};
            if (requestParameters.payloadValue !== undefined) {
                queryParameters['payload.value'] = requestParameters.payloadValue;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadOrder !== undefined) {
                queryParameters['payload.order'] = requestParameters.payloadOrder;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/audience/v1/projects/{projectId}/classifications/{classificationId}/items/{classificationItemId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"classificationId"}}`, encodeURIComponent(String(requestParameters.classificationId))).replace(`{${"classificationItemId"}}`, encodeURIComponent(String(requestParameters.classificationItemId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    audienceServiceDeleteClassificationItem(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.audienceServiceDeleteClassificationItemRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    audienceServiceListAudiencesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling audienceServiceListAudiences.');
            }
            const queryParameters = {};
            if (requestParameters.audienceId !== undefined) {
                queryParameters['audienceId'] = requestParameters.audienceId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/audience/v1/projects/{projectId}/audiences`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListAudiencesResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    audienceServiceListAudiences(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.audienceServiceListAudiencesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    audienceServiceListClassificationGroupsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling audienceServiceListClassificationGroups.');
            }
            const queryParameters = {};
            if (requestParameters.classificationGroupId !== undefined) {
                queryParameters['classificationGroupId'] = requestParameters.classificationGroupId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/audience/v1/projects/{projectId}/classification-groups`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListClassificationGroupsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    audienceServiceListClassificationGroups(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.audienceServiceListClassificationGroupsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    audienceServiceListClassificationsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling audienceServiceListClassifications.');
            }
            const queryParameters = {};
            if (requestParameters.classificationId !== undefined) {
                queryParameters['classificationId'] = requestParameters.classificationId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadClassificationGroupId !== undefined) {
                queryParameters['payload.classificationGroupId'] = requestParameters.payloadClassificationGroupId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/audience/v1/projects/{projectId}/classifications`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListClassificationsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    audienceServiceListClassifications(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.audienceServiceListClassificationsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    audienceServiceReadAudienceRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling audienceServiceReadAudience.');
            }
            if (requestParameters.audienceId === null || requestParameters.audienceId === undefined) {
                throw new runtime.RequiredError('audienceId', 'Required parameter requestParameters.audienceId was null or undefined when calling audienceServiceReadAudience.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/audience/v1/projects/{projectId}/audiences/{audienceId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"audienceId"}}`, encodeURIComponent(String(requestParameters.audienceId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiAudienceFromJSON(jsonValue));
        });
    }
    /**
     */
    audienceServiceReadAudience(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.audienceServiceReadAudienceRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    audienceServiceReorderClassificationItemsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling audienceServiceReorderClassificationItems.');
            }
            if (requestParameters.classificationId === null || requestParameters.classificationId === undefined) {
                throw new runtime.RequiredError('classificationId', 'Required parameter requestParameters.classificationId was null or undefined when calling audienceServiceReorderClassificationItems.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling audienceServiceReorderClassificationItems.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/audience/v1/projects/{projectId}/classifications/{classificationId}/items:reorder`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"classificationId"}}`, encodeURIComponent(String(requestParameters.classificationId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiReorderClassificationItemsPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    audienceServiceReorderClassificationItems(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.audienceServiceReorderClassificationItemsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    audienceServiceTestAudienceRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling audienceServiceTestAudience.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling audienceServiceTestAudience.');
            }
            const queryParameters = {};
            if (requestParameters.audienceId !== undefined) {
                queryParameters['audienceId'] = requestParameters.audienceId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/audience/v1/projects/{projectId}/audiences/test`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiAudiencePayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiTestAudienceResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    audienceServiceTestAudience(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.audienceServiceTestAudienceRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    audienceServiceUpdateAudienceRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling audienceServiceUpdateAudience.');
            }
            if (requestParameters.audienceId === null || requestParameters.audienceId === undefined) {
                throw new runtime.RequiredError('audienceId', 'Required parameter requestParameters.audienceId was null or undefined when calling audienceServiceUpdateAudience.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling audienceServiceUpdateAudience.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/audience/v1/projects/{projectId}/audiences/{audienceId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"audienceId"}}`, encodeURIComponent(String(requestParameters.audienceId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiAudiencePayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiAudienceFromJSON(jsonValue));
        });
    }
    /**
     */
    audienceServiceUpdateAudience(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.audienceServiceUpdateAudienceRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    audienceServiceUpdateClassificationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling audienceServiceUpdateClassification.');
            }
            if (requestParameters.classificationId === null || requestParameters.classificationId === undefined) {
                throw new runtime.RequiredError('classificationId', 'Required parameter requestParameters.classificationId was null or undefined when calling audienceServiceUpdateClassification.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling audienceServiceUpdateClassification.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/audience/v1/projects/{projectId}/classifications/{classificationId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"classificationId"}}`, encodeURIComponent(String(requestParameters.classificationId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiClassificationPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiClassificationFromJSON(jsonValue));
        });
    }
    /**
     */
    audienceServiceUpdateClassification(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.audienceServiceUpdateClassificationRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    audienceServiceUpdateClassificationGroupRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling audienceServiceUpdateClassificationGroup.');
            }
            if (requestParameters.classificationGroupId === null || requestParameters.classificationGroupId === undefined) {
                throw new runtime.RequiredError('classificationGroupId', 'Required parameter requestParameters.classificationGroupId was null or undefined when calling audienceServiceUpdateClassificationGroup.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling audienceServiceUpdateClassificationGroup.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/audience/v1/projects/{projectId}/classification-groups/{classificationGroupId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"classificationGroupId"}}`, encodeURIComponent(String(requestParameters.classificationGroupId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiClassificationGroupPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiClassificationGroupFromJSON(jsonValue));
        });
    }
    /**
     */
    audienceServiceUpdateClassificationGroup(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.audienceServiceUpdateClassificationGroupRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    audienceServiceUpdateClassificationItemRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling audienceServiceUpdateClassificationItem.');
            }
            if (requestParameters.classificationId === null || requestParameters.classificationId === undefined) {
                throw new runtime.RequiredError('classificationId', 'Required parameter requestParameters.classificationId was null or undefined when calling audienceServiceUpdateClassificationItem.');
            }
            if (requestParameters.classificationItemId === null || requestParameters.classificationItemId === undefined) {
                throw new runtime.RequiredError('classificationItemId', 'Required parameter requestParameters.classificationItemId was null or undefined when calling audienceServiceUpdateClassificationItem.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling audienceServiceUpdateClassificationItem.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/audience/v1/projects/{projectId}/classifications/{classificationId}/items/{classificationItemId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"classificationId"}}`, encodeURIComponent(String(requestParameters.classificationId))).replace(`{${"classificationItemId"}}`, encodeURIComponent(String(requestParameters.classificationItemId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiClassificationItemPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiClassificationItemFromJSON(jsonValue));
        });
    }
    /**
     */
    audienceServiceUpdateClassificationItem(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.audienceServiceUpdateClassificationItemRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
