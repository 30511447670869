/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiCSVErrorFromJSON, ApiCSVErrorToJSON, } from './ApiCSVError';
import { ApiCSVFieldFromJSON, ApiCSVFieldToJSON, } from './ApiCSVField';
export function ApiCSVRuleFromJSON(json) {
    return ApiCSVRuleFromJSONTyped(json, false);
}
export function ApiCSVRuleFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'warning': !exists(json, 'warning') ? undefined : json['warning'],
        'uniqueIndex': !exists(json, 'uniqueIndex') ? undefined : json['uniqueIndex'],
        'fields': !exists(json, 'fields') ? undefined : (json['fields'].map(ApiCSVFieldFromJSON)),
        'availableErrors': !exists(json, 'availableErrors') ? undefined : (json['availableErrors'].map(ApiCSVErrorFromJSON)),
        'dateGroupingRequired': !exists(json, 'dateGroupingRequired') ? undefined : json['dateGroupingRequired'],
    };
}
export function ApiCSVRuleToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'description': value.description,
        'warning': value.warning,
        'uniqueIndex': value.uniqueIndex,
        'fields': value.fields === undefined ? undefined : (value.fields.map(ApiCSVFieldToJSON)),
        'availableErrors': value.availableErrors === undefined ? undefined : (value.availableErrors.map(ApiCSVErrorToJSON)),
        'dateGroupingRequired': value.dateGroupingRequired,
    };
}
