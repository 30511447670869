import { CloudUpload } from "@mui/icons-material";
import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { Uploader } from "features/media";
import { IUploaderProps } from "features/media/types";

interface IUploaderButtonProps extends IUploaderProps {
  text: string;
  uploading: boolean;
  buttonProps?: ButtonProps;
}

function UploaderButton({
  uploading,
  text,
  buttonProps,
  ...rest
}: IUploaderButtonProps) {
  return (
    <Button
      color="primary"
      variant="contained"
      component="label"
      disabled={uploading}
      {...buttonProps}
      startIcon={
        uploading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <CloudUpload />
        )
      }
    >
      {text}
      <Uploader {...rest} />
    </Button>
  );
}

export default UploaderButton;
