/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiComponentUISourceFromJSON, ApiComponentUISourceToJSON, } from './ApiComponentUISource';
import { ApiSimplePersonalisationFromJSON, ApiSimplePersonalisationToJSON, } from './ApiSimplePersonalisation';
import { ApiSimpleProjectFromJSON, ApiSimpleProjectToJSON, } from './ApiSimpleProject';
import { ApiSimpleUserFromJSON, ApiSimpleUserToJSON, } from './ApiSimpleUser';
export function ApiSimpleComponentFromJSON(json) {
    return ApiSimpleComponentFromJSONTyped(json, false);
}
export function ApiSimpleComponentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'html': !exists(json, 'html') ? undefined : json['html'],
        'css': !exists(json, 'css') ? undefined : json['css'],
        'js': !exists(json, 'js') ? undefined : json['js'],
        'grapeJs': !exists(json, 'grapeJs') ? undefined : json['grapeJs'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'owner': !exists(json, 'owner') ? undefined : ApiSimpleUserFromJSON(json['owner']),
        'lastUser': !exists(json, 'lastUser') ? undefined : ApiSimpleUserFromJSON(json['lastUser']),
        'project': !exists(json, 'project') ? undefined : ApiSimpleProjectFromJSON(json['project']),
        'personalisations': !exists(json, 'personalisations') ? undefined : (json['personalisations'].map(ApiSimplePersonalisationFromJSON)),
        'uiSource': !exists(json, 'uiSource') ? undefined : ApiComponentUISourceFromJSON(json['uiSource']),
        'head': !exists(json, 'head') ? undefined : json['head'],
        'seoTitle': !exists(json, 'seoTitle') ? undefined : json['seoTitle'],
        'seoDescrition': !exists(json, 'seoDescrition') ? undefined : json['seoDescrition'],
        'favicon': !exists(json, 'favicon') ? undefined : json['favicon'],
        'pending': !exists(json, 'pending') ? undefined : json['pending'],
    };
}
export function ApiSimpleComponentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'html': value.html,
        'css': value.css,
        'js': value.js,
        'grapeJs': value.grapeJs,
        'name': value.name,
        'description': value.description,
        'owner': ApiSimpleUserToJSON(value.owner),
        'lastUser': ApiSimpleUserToJSON(value.lastUser),
        'project': ApiSimpleProjectToJSON(value.project),
        'personalisations': value.personalisations === undefined ? undefined : (value.personalisations.map(ApiSimplePersonalisationToJSON)),
        'uiSource': ApiComponentUISourceToJSON(value.uiSource),
        'head': value.head,
        'seoTitle': value.seoTitle,
        'seoDescrition': value.seoDescrition,
        'favicon': value.favicon,
        'pending': value.pending,
    };
}
