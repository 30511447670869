import { ApiSessionEffectivenessRule, ApiProject } from "@incendium/api";
import { sessionEffectivenessRuleService } from "Apis";
import { useSharedState } from "./useSharedState";

const fetchSessionEffectivenessRules = async (
  selectedProject: ApiProject | null
) => {
  if (selectedProject) {
    const sessionEffectivenessRules =
      await sessionEffectivenessRuleService.sessionEffectivenessRuleServiceListRules(
        {
          projectId: selectedProject.id as number,
        }
      );
    return sessionEffectivenessRules.results || [];
  }
  return [];
};

export const useSessionEffectivenessRules = () => {
  const { state, setState } = useSharedState(
    "sessionEffectivenessRules",
    [],
    fetchSessionEffectivenessRules
  );

  return {
    sessionEffectivenessRules: state as ApiSessionEffectivenessRule[],
    setSessionEffectivenessRules: setState,
  };
};
