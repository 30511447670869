import { ApiLocation } from "@incendium/api";
import { Autocomplete, TextField, OutlinedTextFieldProps } from "@mui/material";
import { useLocations } from "Hooks";
import { useMemo } from "react";

interface ILocationDropdownProps
  extends Omit<OutlinedTextFieldProps, "onChange"> {
  value?: number;
  onChange: (n?: number, l?: ApiLocation) => void;
  showInAnalytics?: boolean;
}

function LocationDropdown({
  label,
  size,
  variant,
  value,
  onChange,
  fullWidth,
  sx,
  showInAnalytics,
}: ILocationDropdownProps) {
  const { locations } = useLocations();

  const filteredLocations = useMemo(() => {
    return locations.filter((l) =>
      showInAnalytics ? l.showInAnalytics === showInAnalytics : true
    );
  }, [locations, showInAnalytics]);

  const selectLocation = useMemo(
    () => (locations || []).find((l) => l.id === value) || {},
    [locations, value]
  );
  return (
    <Autocomplete
      sx={sx}
      fullWidth={fullWidth}
      options={filteredLocations}
      value={selectLocation}
      onChange={(e, v) => {
        onChange(v?.id, v ? v : undefined);
      }}
      getOptionLabel={(o: ApiLocation) => o.name || ""}
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant} size={size} />
      )}
    />
  );
}

export default LocationDropdown;
