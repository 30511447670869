import { ApiProject, ApiSync } from "@incendium/api";
import { syncService } from "Apis";
import { useSharedState } from "./useSharedState";

const fetchExternalSyncs = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const res = await syncService.syncServiceListSyncs({
      projectId: selectedProject.id as number,
    });
    return res.results || [];
  }
  return [];
};

export const useExternalSyncs = () => {
  const { state, setState, refetch } = useSharedState(
    "externalSyncs",
    [],
    fetchExternalSyncs
  );

  return {
    externalSyncs: state as ApiSync[],
    setExternalSyncs: setState,
    refetch,
  };
};
