import { ApiDomain } from "@incendium/api";
import { Box, Stack, Switch, Typography } from "@mui/material";
import AnimatedList from "Components/AnimatedList/AnimatedList";
import Loading from "Components/Loading/Loading";
import produce from "immer";
import { CallbackOrVal } from "Interfaces";
import { Suspense, useCallback } from "react";
import { TextValidator } from "react-material-ui-form-validator";

function ProjectDomainsHead() {
  return (
    <Stack direction={"row"} spacing={2} justifyContent="space-between">
      <Typography variant="subtitle2">Add Domain</Typography>
      <Typography variant="subtitle2" sx={{ width: 115 }}>
        Enable Cross Link
      </Typography>
    </Stack>
  );
}

interface IProjectDomainsRowProps {
  row: ApiDomain;
  onEdit: (v: ApiDomain) => void;
}

function ProjectDomainsRow({ row, onEdit }: IProjectDomainsRowProps) {
  return (
    <Stack
      sx={{ width: "100%" }}
      direction="row"
      spacing={2}
      justifyContent="space-between"
    >
      <Box sx={{ flex: 1 }}>
        <TextValidator
          variant="outlined"
          placeholder="http://mydomain.com"
          label="Domain *"
          helper
          fullWidth
          name="domain"
          size="small"
          value={row.url}
          validators={["required", "isDomain"]}
          errorMessages={["Domain Name is required", "Enter a valid domain"]}
          onChange={(e: any) =>
            onEdit(
              produce(row, (draft) => {
                draft.url = e.target.value;
              })
            )
          }
        />
      </Box>
      <Box sx={{ width: 115 }}>
        <Switch
          checked={row.crossDomainEnabled}
          onChange={(_, c) =>
            onEdit(
              produce(row, (draft) => {
                draft.crossDomainEnabled = c;
              })
            )
          }
        />
      </Box>
    </Stack>
  );
}

interface IProjectDomainsProps {
  domains: ApiDomain[];
  setDomains: (v: CallbackOrVal<ApiDomain[]>) => void;
}

function ProjectDomains({ domains, setDomains }: IProjectDomainsProps) {
  const onAdd = useCallback(() => {
    setDomains((prevDomains) =>
      produce(prevDomains, (draft) => {
        draft.push({
          id: 0 - draft.length,
        });
      })
    );
  }, [setDomains]);

  const onEdit = useCallback(
    (idx: number) => (v: ApiDomain) => {
      setDomains((prevDomains) =>
        produce(prevDomains, (draft) => {
          draft[idx] = v;
        })
      );
    },
    [setDomains]
  );

  const onRemove = useCallback(
    (idx: number, value: ApiDomain) => {
      setDomains((prevDomains) =>
        produce(prevDomains, (draft) => {
          const index = draft.findIndex((d) => d.id === value.id);
          draft?.splice(index, 1);
        })
      );
    },
    [setDomains]
  );

  return (
    <Suspense fallback={<Loading />}>
      <AnimatedList
        onAdd={onAdd}
        onRemove={onRemove}
        onEdit={onEdit}
        items={
          domains && domains.length
            ? domains
            : [
                {
                  id: 0,
                },
              ]
        }
        uniqueProp={"id"}
        RowHead={ProjectDomainsHead}
        RowComponent={ProjectDomainsRow}
        rowSpacing={3}
      />
    </Suspense>
  );
}

export default ProjectDomains;
