/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiDataFromJSON, ApiDataToJSON, } from './ApiData';
import { ApiDomainFromJSON, ApiDomainToJSON, } from './ApiDomain';
import { ApiJobFromJSON, ApiJobToJSON, } from './ApiJob';
import { ApiLocationFromJSON, ApiLocationToJSON, } from './ApiLocation';
import { ApiPageStatusFromJSON, ApiPageStatusToJSON, } from './ApiPageStatus';
import { ApiPageTagFromJSON, ApiPageTagToJSON, } from './ApiPageTag';
import { ApiProductFromJSON, ApiProductToJSON, } from './ApiProduct';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiPageFromJSON(json) {
    return ApiPageFromJSONTyped(json, false);
}
export function ApiPageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'displayPage': !exists(json, 'displayPage') ? undefined : json['displayPage'],
        'locations': !exists(json, 'locations') ? undefined : (json['locations'].map(ApiLocationFromJSON)),
        'tags': !exists(json, 'tags') ? undefined : (json['tags'].map(ApiPageTagFromJSON)),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'pageViews': !exists(json, 'pageViews') ? undefined : (json['pageViews'].map(ApiDataFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'status': !exists(json, 'status') ? undefined : ApiPageStatusFromJSON(json['status']),
        'locatedAt': !exists(json, 'locatedAt') ? undefined : (new Date(json['locatedAt'])),
        'crawledAt': !exists(json, 'crawledAt') ? undefined : (new Date(json['crawledAt'])),
        'errorCounter': !exists(json, 'errorCounter') ? undefined : json['errorCounter'],
        'products': !exists(json, 'products') ? undefined : (json['products'].map(ApiProductFromJSON)),
        'jobs': !exists(json, 'jobs') ? undefined : (json['jobs'].map(ApiJobFromJSON)),
        'domain': !exists(json, 'domain') ? undefined : ApiDomainFromJSON(json['domain']),
        'domainId': !exists(json, 'domainId') ? undefined : json['domainId'],
        'analyticsSynced': !exists(json, 'analyticsSynced') ? undefined : json['analyticsSynced'],
    };
}
export function ApiPageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'project': ApiProjectToJSON(value.project),
        'projectId': value.projectId,
        'page': value.page,
        'displayPage': value.displayPage,
        'locations': value.locations === undefined ? undefined : (value.locations.map(ApiLocationToJSON)),
        'tags': value.tags === undefined ? undefined : (value.tags.map(ApiPageTagToJSON)),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'pageViews': value.pageViews === undefined ? undefined : (value.pageViews.map(ApiDataToJSON)),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'status': ApiPageStatusToJSON(value.status),
        'locatedAt': value.locatedAt === undefined ? undefined : (value.locatedAt.toISOString()),
        'crawledAt': value.crawledAt === undefined ? undefined : (value.crawledAt.toISOString()),
        'errorCounter': value.errorCounter,
        'products': value.products === undefined ? undefined : (value.products.map(ApiProductToJSON)),
        'jobs': value.jobs === undefined ? undefined : (value.jobs.map(ApiJobToJSON)),
        'domain': ApiDomainToJSON(value.domain),
        'domainId': value.domainId,
        'analyticsSynced': value.analyticsSynced,
    };
}
