/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiAPITokenTypeFromJSON, ApiAPITokenTypeToJSON, } from './ApiAPITokenType';
import { ApiAPITokenValueResponseFromJSON, ApiAPITokenValueResponseToJSON, } from './ApiAPITokenValueResponse';
export function ApiAPITokenResponseFromJSON(json) {
    return ApiAPITokenResponseFromJSONTyped(json, false);
}
export function ApiAPITokenResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'type': !exists(json, 'type') ? undefined : ApiAPITokenTypeFromJSON(json['type']),
        'values': !exists(json, 'values') ? undefined : (json['values'].map(ApiAPITokenValueResponseFromJSON)),
    };
}
export function ApiAPITokenResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'value': value.value,
        'url': value.url,
        'type': ApiAPITokenTypeToJSON(value.type),
        'values': value.values === undefined ? undefined : (value.values.map(ApiAPITokenValueResponseToJSON)),
    };
}
