import { useSelectedProject } from "Hooks/useSelectedProject";
import { useMemo } from "react";

function useProjectTimezone() {
  const { selectedClient, selectedProject } = useSelectedProject();

  return useMemo(() => {
    return selectedProject?.timezone || selectedClient?.timezone || "UTC";
  }, [selectedClient, selectedProject]);
}

export default useProjectTimezone;
