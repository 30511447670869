import {
  Divider,
  Theme,
  alpha,
  styled,
  Stack,
  Menu,
  MenuItem,
  Button,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import LogoFavIcon from "Assets/Images/logo-fav.png";
import MenuIcon from "@mui/icons-material/Menu";
import {
  ArrowForwardIos,
  ArrowBackIos as ArrowBackIosIcon,
  KeyboardBackspace,
  ArrowRightAlt,
} from "@mui/icons-material";
import clsx from "clsx";
import ProjectMenu from "./ProjectMenu";
import { Link, matchPath, useLocation } from "react-router-dom";
import ClientMenu from "./ClientMenu";
import RootMenu from "features/leftMenu/components/RootMenu";
import { useDispatch, useSelector } from "react-redux";
import { clientListSelector } from "Selectors/clientListSelector";
import { leftMenuSelector } from "Selectors/leftMenuSelector";
import {
  closeSecondaryMenu,
  logout,
  toggleHover,
  toggleMenu,
  toggleSecondaryMenu,
} from "Actions";
import { AnimatePresence, motion } from "framer-motion";
import { Box } from "@mui/system";
import { ReactComponent as Logo } from "Assets/Images/logo-no-symbol.svg";
import { useDebounce, useMount } from "react-use";
import { memo, useEffect, useMemo, useState } from "react";
import AccessLevel from "Components/AccessLevel/AccessLevel";
import { ApiACLRole } from "@incendium/api";
import UserIcon from "Assets/icons/Users-icon.png";
import { useSelectedProject } from "Hooks";
import Image from "mui-image";
import HeaderAvatar from "Components/Header/HeaderAvatar";
import { useUser } from "Hooks/useUser";
import {
  LeftMenuItem,
  LeftmenuProvider,
  ProjectClientSelector,
} from "features/leftMenu";

const useLeftmenuStyle = makeStyles((theme: Theme) => ({
  leftmenu: {
    position: "relative",
    padding: "1px 0",
    width: 260,
    height: "100%",
    minWidth: 260,
    zIndex: 2,
    fontSize: theme.typography.body1.fontSize,
    background: theme.palette.primary.dark,
    display: "flex",
    flexDirection: "column",
    willChange: "width, min-width",
    transition: "min-width 0.17s linear",
    "&.is-closed": {
      width: 64,
      minWidth: 64,
      "& .MuiDivider-root": {
        height: 2,
        background: "transparent",
        borderColor: `transparent`,
      },
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "100%",
    },
    "& .MuiDivider-root": {
      height: 2,
      borderTop: `1px solid rgba(0,0,0,0.9)`,
      background: alpha(theme.palette.info.main, 0.4),
    },
  },

  logo: {
    width: "100%",
    height: "55px",
    margin: "0 auto",
    textAlign: "center",
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
  },

  navigation: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  mainNav: {
    padding: "0",
    margin: "0",
    color: theme.palette.secondary.main,
    opacity: "1",
    fontSize: "20px",
    listStyle: "none",
    cursor: "pointer",
  },

  secondaryNav: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: "20px",
    // padding: "10px 0px",
  },
  close: {
    background: theme.palette.primary.dark,
    padding: theme.spacing(0.2),
    paddingTop: theme.spacing(0.8),
    paddingBottom: theme.spacing(0.8),
    borderBottomRightRadius: 4,
    borderTopRightRadius: 4,
    top: 55,
    zIndex: 10,
    fontSize: 14,
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    color: theme.palette.common.white,
    position: "absolute",
    right: 1,
    transform: `translateX(100%)`,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    boxShadow: "inset -3px 5px 3px rgb(0 0 0 / 60%)",
  },

  mobileMenu: {
    display: "none",
    "& svg ": {
      fontSize: "40px",
    },

    [theme.breakpoints.down("md")]: {
      display: "block",
      position: "absolute",
      top: "3%",
      fontSize: "20px",
    },
  },
}));

export const LeftMenuExtras = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <Box>
      <Box>{children}</Box>
    </Box>
  );
};

export const LeftMenuToggleButton = ({
  backToSecondaryText,
}: {
  backToSecondaryText: string;
}) => {
  const { isSecondaryOpen, isMenuOpen, debouncedIsHover } =
    useSelector(leftMenuSelector);
  const dispatch = useDispatch();

  return (
    <motion.div animate={isMenuOpen || debouncedIsHover ? "open" : "closed"}>
      <motion.div
        variants={{
          open: {
            x: 0,
          },
          closed: {
            x: -12,
          },
        }}
      >
        <LeftMenuItem
          icon={isSecondaryOpen ? KeyboardBackspace : ArrowRightAlt}
          to="#"
          text={isSecondaryOpen ? "Back to menu" : backToSecondaryText}
          onClick={() => dispatch(toggleSecondaryMenu())}
        />
      </motion.div>
    </motion.div>
  );
};
export const LeftMenuExtrasTitle = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <Box>
      <Box>{children}</Box>
      <Divider />
    </Box>
  );
};
export const LeftMenuBlock = styled(Stack)<{ densePadding?: boolean }>(
  ({ densePadding }) => ({
    padding: densePadding ? "0 5px" : "0 18px",
    color: "white",
  })
);

const LeftMenuLnksWrapper = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const { selectedClient, selectedProject } = useSelectedProject();
  const dispatch = useDispatch();
  const LeftmenuClasses = useLeftmenuStyle();
  const { isMenuOpen, debouncedIsHover } = useSelector(leftMenuSelector);
  const [isHover, setIsHover] = useState(false);
  useDebounce(
    () => {
      if (isHover !== debouncedIsHover) {
        dispatch(toggleHover());
      }
    },
    300,
    [isHover]
  );

  return (
    <motion.div
      key={`${selectedClient?.id}-${selectedProject?.id}`}
      className={LeftmenuClasses.navigation}
      animate={isMenuOpen || debouncedIsHover ? "open" : "closed"}
      initial={isMenuOpen || debouncedIsHover ? "open" : "closed"}
      onMouseOver={() => !isHover && !isMenuOpen && setIsHover(true)}
      onMouseLeave={() => {
        if (!isHover) {
          return;
        }

        setIsHover(false);
        if (isMenuOpen) {
          dispatch(toggleMenu());
        }
      }}
    >
      <motion.div
        variants={{
          open: {
            transition: { staggerChildren: 0.04, delayChildren: 0.1 },
          },
          closed: {
            transition: { staggerChildren: 0.01, staggerDirection: -1 },
          },
        }}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};

export const LeftMenuExtrasInner = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <Box
      key="extra"
      component={motion.div}
      initial={{ x: 50 }}
      animate={{ x: 0 }}
      exit={{ x: 50 }}
    >
      {children}
    </Box>
  );
};

function Leftmenu({ hideUser }: { hideUser?: boolean }) {
  const dispatch = useDispatch();
  const LeftmenuClasses = useLeftmenuStyle();
  const location = useLocation();
  const { user } = useUser();
  const { list: clientsList } = useSelector(clientListSelector);
  const availableProjects = useMemo(() => {
    return clientsList.map((c) => c.projects).flat();
  }, [clientsList]);

  const {
    isMenuOpen: isOpen,
    isSecondaryOpen,
    debouncedIsHover,
  } = useSelector(leftMenuSelector);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const isRootPath = matchPath(location.pathname, {
    path: "/",
    exact: true,
    strict: false,
  });
  const isUsersPath = matchPath(location.pathname, {
    path: "/users",
    exact: true,
    strict: false,
  });

  const isClientPath = matchPath(location.pathname, {
    path: "/clients/:clientId",
    exact: true,
    strict: false,
  });

  const handleClick = (e: React.MouseEvent) => {
    if (anchorEl) {
      return setAnchorEl(null);
    }
    setAnchorEl(e.currentTarget);
  };

  const doLogout = () => {
    dispatch(logout());
  };

  const toggleMenuD = () => {
    dispatch(toggleMenu());
  };

  useMount(() => {
    if (isSecondaryOpen) {
      dispatch(closeSecondaryMenu());
    }
  });

  useEffect(() => {
    if (
      isSecondaryOpen &&
      !["/analyse/reports"].some((v) => location.pathname.includes(v))
    ) {
      dispatch(closeSecondaryMenu());
    }
  }, [location]);

  return (
    <LeftmenuProvider>
      <Box
        className={clsx(LeftmenuClasses.leftmenu, {
          "is-closed": !isOpen && !debouncedIsHover,
        })}
      >
        <div className={LeftmenuClasses.mobileMenu}>
          <MenuIcon />
        </div>
        <div onClick={toggleMenuD} className={LeftmenuClasses.close}>
          {isOpen || debouncedIsHover ? (
            <ArrowBackIosIcon
              style={{ transform: `translateX(3px)` }}
              fontSize={"inherit"}
            />
          ) : (
            <ArrowForwardIos fontSize="inherit" />
          )}
        </div>
        <LeftMenuBlock
          className={LeftmenuClasses.logo}
          densePadding={!isOpen && !debouncedIsHover}
        >
          <Link to={"/"}>
            {isOpen || debouncedIsHover ? (
              <Logo />
            ) : (
              <Image
                height={42}
                color="transparent"
                src={LogoFavIcon}
                alt="Logo Favicon"
                fit="contain"
              />
            )}
          </Link>
        </LeftMenuBlock>
        {availableProjects.length > 1 && (
          <LeftMenuBlock mb={2}>
            <ProjectClientSelector
              isCollapsed={!isOpen && !debouncedIsHover}
              clients={clientsList}
            />
          </LeftMenuBlock>
        )}
        <AnimatePresence>
          <Box
            className={isSecondaryOpen ? LeftmenuClasses.navigation : ""}
            id="leftMenuOptions"
          />
        </AnimatePresence>

        {isSecondaryOpen ? (
          <Box></Box>
        ) : (
          <LeftMenuLnksWrapper>
            <Box sx={{ overflow: "hidden" }}>
              {isRootPath || isUsersPath ? (
                <AccessLevel role={ApiACLRole.DOMAIN_ADMIN}>
                  <RootMenu />
                </AccessLevel>
              ) : isClientPath ? (
                <AccessLevel role={ApiACLRole.CLIENT_OBSERVER}>
                  <ClientMenu />
                </AccessLevel>
              ) : (
                <ProjectMenu />
              )}
            </Box>
          </LeftMenuLnksWrapper>
        )}

        <Stack mb={1} spacing={1}>
          <Stack
            direction={!isOpen && !debouncedIsHover ? "column" : "row"}
            alignItems="center"
            sx={{ overflow: "hidden" }}
          >
            <AccessLevel type="admin" role={ApiACLRole.PROJECT_ADMIN}>
              <LeftMenuLnksWrapper>
                <LeftMenuItem
                  imagePath={UserIcon}
                  text="My Account"
                  to="/users"
                  defaultIsOpen={!!isUsersPath}
                  active={!!isUsersPath}
                />
              </LeftMenuLnksWrapper>
            </AccessLevel>
            {!hideUser && (
              <LeftMenuBlock direction={"row"}>
                <Box
                  sx={
                    isOpen || debouncedIsHover
                      ? {
                          paddingLeft: 2,
                          borderLeft: `1px solid rgba(255,255,255,0.5)`,
                        }
                      : {}
                  }
                >
                  <IconButton sx={{ padding: 0 }} onClick={handleClick}>
                    <HeaderAvatar name={user?.name || ""} />
                  </IconButton>
                </Box>
                <Menu
                  elevation={0}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => {
                    setAnchorEl(null);
                  }}
                >
                  <MenuItem>
                    <Button onClick={doLogout}>Logout</Button>
                  </MenuItem>
                </Menu>
              </LeftMenuBlock>
            )}
          </Stack>
        </Stack>
      </Box>
    </LeftmenuProvider>
  );
}

export default memo(Leftmenu);
