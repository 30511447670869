/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiLeadStatusType;
(function (ApiLeadStatusType) {
    ApiLeadStatusType["UNASSIGNED"] = "LEAD_STATUS_UNASSIGNED";
    ApiLeadStatusType["PROSPECT"] = "LEAD_STATUS_PROSPECT";
    ApiLeadStatusType["MQL"] = "LEAD_STATUS_MQL";
    ApiLeadStatusType["SQL"] = "LEAD_STATUS_SQL";
    ApiLeadStatusType["SAL"] = "LEAD_STATUS_SAL";
    ApiLeadStatusType["CUSTOMER"] = "LEAD_STATUS_CUSTOMER";
})(ApiLeadStatusType || (ApiLeadStatusType = {}));
export function ApiLeadStatusTypeFromJSON(json) {
    return ApiLeadStatusTypeFromJSONTyped(json, false);
}
export function ApiLeadStatusTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiLeadStatusTypeToJSON(value) {
    return value;
}
