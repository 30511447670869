import { exhaustiveCheck } from "@incendium/inc-ts-helpers";
import { TAttributionModel } from "Interfaces";

export const getAttributionTitle = (m: TAttributionModel): string => {
  switch (m) {
    case "first":
      return "First Touch";
    case "last":
      return "Last Touch";
    case "lastNon":
      return "Last Non-Direct Touch";
    case "firstNon":
      return "First Non-Direct Touch";
    case "position":
      return "Position Based";
    case "linear":
      return "Linear";
    case "impacted":
      return "Impacted";

    default:
      exhaustiveCheck(m);
  }
};
