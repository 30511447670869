
export function clearSelection() {
  if (typeof window === "undefined") {
    return;
  }
  if (window.getSelection) {
    if (window.getSelection()?.empty) { // Chrome
      window.getSelection()?.empty();
    } else if (window.getSelection()?.removeAllRanges) { // Firefox
      window.getSelection()?.removeAllRanges();
    }
    //@ts-ignore
  } else if (document.selection) {
    //@ts-ignore  // IE?
    document.selection.empty();
  }
}
