/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiClassificationFromJSON, ApiClassificationToJSON, } from './ApiClassification';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiClassificationGroupFromJSON(json) {
    return ApiClassificationGroupFromJSONTyped(json, false);
}
export function ApiClassificationGroupFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'classifications': !exists(json, 'classifications') ? undefined : (json['classifications'].map(ApiClassificationFromJSON)),
    };
}
export function ApiClassificationGroupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'project': ApiProjectToJSON(value.project),
        'projectId': value.projectId,
        'classifications': value.classifications === undefined ? undefined : (value.classifications.map(ApiClassificationToJSON)),
    };
}
