/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiAudienceFromJSON, ApiAudienceToJSON, } from './ApiAudience';
import { ApiClassificationFromJSON, ApiClassificationToJSON, } from './ApiClassification';
import { ApiClientFromJSON, ApiClientToJSON, } from './ApiClient';
import { ApiDomainFromJSON, ApiDomainToJSON, } from './ApiDomain';
import { ApiFormFromJSON, ApiFormToJSON, } from './ApiForm';
import { ApiIntegrationServiceFromJSON, ApiIntegrationServiceToJSON, } from './ApiIntegrationService';
import { ApiMediaFromJSON, ApiMediaToJSON, } from './ApiMedia';
import { ApiPageTypeFromJSON, ApiPageTypeToJSON, } from './ApiPageType';
import { ApiProjectSettingFromJSON, ApiProjectSettingToJSON, } from './ApiProjectSetting';
import { ApiSessionFromJSON, ApiSessionToJSON, } from './ApiSession';
import { ApiStatusFromJSON, ApiStatusToJSON, } from './ApiStatus';
export function ApiProjectFromJSON(json) {
    return ApiProjectFromJSONTyped(json, false);
}
export function ApiProjectFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'client': !exists(json, 'client') ? undefined : ApiClientFromJSON(json['client']),
        'key': !exists(json, 'key') ? undefined : json['key'],
        'status': !exists(json, 'status') ? undefined : ApiStatusFromJSON(json['status']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'ip': !exists(json, 'ip') ? undefined : json['ip'],
        'pushedToDataflare': !exists(json, 'pushedToDataflare') ? undefined : json['pushedToDataflare'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
        'oldDomain': !exists(json, 'oldDomain') ? undefined : json['oldDomain'],
        'sitemap': !exists(json, 'sitemap') ? undefined : json['sitemap'],
        'classifications': !exists(json, 'classifications') ? undefined : (json['classifications'].map(ApiClassificationFromJSON)),
        'pageTypes': !exists(json, 'pageTypes') ? undefined : (json['pageTypes'].map(ApiPageTypeFromJSON)),
        'settings': !exists(json, 'settings') ? undefined : (json['settings'].map(ApiProjectSettingFromJSON)),
        'sessions': !exists(json, 'sessions') ? undefined : (json['sessions'].map(ApiSessionFromJSON)),
        'forms': !exists(json, 'forms') ? undefined : (json['forms'].map(ApiFormFromJSON)),
        'audiences': !exists(json, 'audiences') ? undefined : (json['audiences'].map(ApiAudienceFromJSON)),
        'integrationService': !exists(json, 'integrationService') ? undefined : ApiIntegrationServiceFromJSON(json['integrationService']),
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'homepageScreen': !exists(json, 'homepageScreen') ? undefined : ApiMediaFromJSON(json['homepageScreen']),
        'homepageScreenId': !exists(json, 'homepageScreenId') ? undefined : json['homepageScreenId'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'domains': !exists(json, 'domains') ? undefined : (json['domains'].map(ApiDomainFromJSON)),
    };
}
export function ApiProjectToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'client': ApiClientToJSON(value.client),
        'key': value.key,
        'status': ApiStatusToJSON(value.status),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'ip': value.ip,
        'pushedToDataflare': value.pushedToDataflare,
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'oldDomain': value.oldDomain,
        'sitemap': value.sitemap,
        'classifications': value.classifications === undefined ? undefined : (value.classifications.map(ApiClassificationToJSON)),
        'pageTypes': value.pageTypes === undefined ? undefined : (value.pageTypes.map(ApiPageTypeToJSON)),
        'settings': value.settings === undefined ? undefined : (value.settings.map(ApiProjectSettingToJSON)),
        'sessions': value.sessions === undefined ? undefined : (value.sessions.map(ApiSessionToJSON)),
        'forms': value.forms === undefined ? undefined : (value.forms.map(ApiFormToJSON)),
        'audiences': value.audiences === undefined ? undefined : (value.audiences.map(ApiAudienceToJSON)),
        'integrationService': ApiIntegrationServiceToJSON(value.integrationService),
        'clientId': value.clientId,
        'homepageScreen': ApiMediaToJSON(value.homepageScreen),
        'homepageScreenId': value.homepageScreenId,
        'timezone': value.timezone,
        'domains': value.domains === undefined ? undefined : (value.domains.map(ApiDomainToJSON)),
    };
}
