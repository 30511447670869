/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiProjectType;
(function (ApiProjectType) {
    ApiProjectType["TYPE_UNSPECIFIED"] = "TypeUnspecified";
    ApiProjectType["TYPE_ECOMMERCE"] = "TypeEcommerce";
    ApiProjectType["TYPE_RECRUITMENT"] = "TypeRecruitment";
    ApiProjectType["TYPE_B2_B"] = "TypeB2B";
})(ApiProjectType || (ApiProjectType = {}));
export function ApiProjectTypeFromJSON(json) {
    return ApiProjectTypeFromJSONTyped(json, false);
}
export function ApiProjectTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiProjectTypeToJSON(value) {
    return value;
}
