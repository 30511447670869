import { ApiACLRole } from "@incendium/api";
import { RouteProps } from "react-router-dom";
import { Dashboard } from "Pages/Dashboard";
import ClientPage from "Pages/ClientPage";
import ProjectPage from "Pages/ProjectPage";
import { LocationsPage } from "Pages/LocationsPage";
import ClassificationsPage from "Pages/Audience/ClassificationsPage";
import AudiencesPage from "Pages/Audience/AudiencesPage";
import AudiencePage from "Pages/Audience/AudiencePage";
import LocationTagsPage from "Pages/Train/LocationTagsPage";
import EngagementPage from "Pages/Train/EngagementPage";
import AlertsPage from "Pages/Train/AlertsPage";
import AlertPage from "Pages/Train/AlertPage";
import LeadStatusesPage from "Pages/Train/LeadStatusesPage";
import LeadStatusPage from "Pages/Train/LeadStatusPage";
import StylesPage from "Pages/StylesPage";
import LeadScoringPage from "Pages/Train/LeadScoringPage";
import EffectivenessPage from "Pages/Train/EffectivenessPage";
import PageviewEffectivenessRulesPage from "Pages/Train/PageviewEffectivenessRulesPage";
import SessionEffectivenessRulePage from "Pages/Train/SessionEffectivenessRulePage";
import { TrafficBucketsPage } from "Pages/Train/TrafficBucketsPage";
import TrafficBucketPage from "Pages/Train/TrafficBucketPage";
import DataManagerPage from "Pages/Connect/DataManagerPage";
import DataUploadPage from "Pages/Connect/DataUploadPage";
import AnalysePage from "Pages/Analyse/AnalysePage";
import UsersPage from "Pages/UserManagement/UsersPage";
import ConversionsPage from "Pages/Train/ConversionsPage";
import DataConnectionsPage from "Pages/Connect/DataConnectionsPage";
import AudienceReportPage from "Pages/Analyse/Audiences";
import AudienceDeepDivePage from "Pages/Analyse/Audiences/AudienceDeepDivePage";
import ProductOverviewPage from "Pages/Analyse/Products/ProductOverviewPage";
import ProductReportPage from "Pages/Analyse/Products/ProductReportPage";
import ProductCategoryReportPage from "Pages/Analyse/Products/ProductCategoryReportPage";
import ProductsRankingPage from "Pages/Analyse/Products/ProductsRankingPage";
import ProductsPerformanceMapPage from "Pages/Analyse/Products/ProductsPerformanceMapPage";
import SingleUserDashboard from "Pages/Analyse/Users/SingleUserDashboard";
import UserDashboardPage from "Pages/Analyse/Users/UserDashboardPage";
import LocationOverviewPage from "Pages/Analyse/Content/LocationOverviewPage";
import ChartBuilderPage from "Pages/Analyse/ChartBuilderPage";
import ReportPage from "Pages/Analyse/ReportPage";
import ReportsPage from "Pages/Analyse/ReportsPage";
import ChartLibraryPage from "Pages/Analyse/ChartLibraryPage";
import MarketingPerformanceOrganicPage from "Pages/Analyse/MarketingPerformance/Organic";
import MarketingPerformancePaidMediaPage from "Pages/Analyse/MarketingPerformance/PaidMedia";
import MarketingPerformanceEmailPage from "Pages/Analyse/MarketingPerformance/EmailMarketing";
import MarketingPerformanceOrganicSocialPage from "Pages/Analyse/MarketingPerformance/OrganicSocial";
import JobsOverviewPage from "Pages/Analyse/Jobs/JobsOverviewPage";
import JobsReportPage from "Pages/Analyse/Jobs/JobsReportPage";
import JobsCategoryReportPage from "Pages/Analyse/Jobs/JobCategoryReportPage";
import JobsPerformanceMapPage from "Pages/Analyse/Jobs/JobPerfomanceMapPage";
import AudienceNewAndReturningPage from "Pages/Analyse/Audiences/New_and_returning";
import AudienceUserJourneyPage from "Pages/Analyse/Audiences/UserJourney";
import ContentPerformancePage from "Pages/Analyse/Content/ContentPerformancePage";
import JourneyExplorerPage from "Pages/Analyse/Content/JourneyExplorerPage";
import PerformanceAnalysisPage from "Pages/Analyse/MarketingPerformance/PerformanceAnalysisPage";
import SalesFlowAnalysisPage from "Pages/Analyse/SalesFlowAnalysisPage";
import ComponentsPage from "Pages/Publish/ComponentsPage";
import ComponentPage from "Pages/Publish/ComponentPage";
import CampaignABOverviewPage from "Pages/Analyse/Content/CampaignABOverviewPage";
import SubdomainsPage from "Pages/Connect/SubdomainsPage";
import LightningLandersPage from "Pages/Publish/LightningLandersPage";
import ConversionLabPage from "Pages/Publish/ConversionLabPage";
import RezonatePage from "Pages/Publish/RezonatePage";
import LightningLandersReportPage from "Pages/Analyse/Content/LightningLandersReportPage";
import RezonateReportPage from "Pages/Analyse/Content/RezonateReportPage";
import ProductBrandReportPage from "Pages/Analyse/Products/ProductBrandReportPage";
import TrafficPage from "Pages/Train/TrafficPage";
import EcomInsightsPage from "Pages/Analyse/EcomInsights";
import SiteOverview from "Pages/Analyse/QuickInsights/SiteOverview";
import ChannelInsights from "Pages/Analyse/QuickInsights/ChannelInsights";
import ContentInsights from "Pages/Analyse/QuickInsights/ContentInsights";
import DevicesInsights from "Pages/Analyse/QuickInsights/DevicesInsights";
import ReferralTrafficPage from "Pages/Analyse/MarketingPerformance/ReferralTrafficPage";

export interface IProtectedRoute extends RouteProps {
  minRole?: ApiACLRole;
  withClients?: boolean;
  pageTitle?: string;
  pageSubTitle?: string;
  documentTitle?: string;
  withDatepicker?: boolean;
}

export function useProtectedRoutes() {
  return [
    {
      exact: true,
      path: "/",
      withClients: true,
      component: Dashboard,
      minRole: ApiACLRole.CLIENT_OBSERVER,
      pageTitle: "Manage Clients",
      pageSubTitle: "Create / Update or Delete Clients",
      documentTitle: "Manage",
    },
    {
      exact: true,
      path: "/clients/:clientId",
      withClients: true,
      component: ClientPage,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      documentTitle: "Manage",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId",
      withClients: true,
      component: ProjectPage,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      documentTitle: "Manage",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/connect/integrations",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: DataConnectionsPage,
      pageTitle: "Integrations",
      pageSubTitle: `Connect Incendium to external data sources to further enrich your analytics and analysis capabilities.`,
      documentTitle: "Connections",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/connect/subdomains",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: SubdomainsPage,
      documentTitle: "Connections",
      pageTitle: "Subdomain Management",
      pageSubTitle:
        "Effortlessly Create and Manage Custom Subdomains for Seamless Online Presence.",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/connect/data-manager",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: DataManagerPage,
      pageTitle: "Data Manager",
      pageSubTitle:
        "Upload / delete csv's to populate historic sales, add profit margins etc.",
      documentTitle: "Connections",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/connect/data-manager/:ruleId",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: DataUploadPage,
      documentTitle: "Connections",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/train/locations",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: LocationsPage,
      documentTitle: "Train",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/train/conversions",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: ConversionsPage,
      documentTitle: "Train",
      pageTitle: "Conversions",
    },
    {
      path: "/clients/:clientId/projects/:projectId/train/traffic",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: TrafficPage,
      documentTitle: "Traffic",
    },
    {
      path: "/clients/:clientId/projects/:projectId/train/traffic/channels/:channelId",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: TrafficPage,
      documentTitle: "Train",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/train/traffic/channels",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: TrafficPage,
      documentTitle: "Train",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/train/traffic/buckets/:bucketId",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: TrafficBucketPage,
      documentTitle: "Train",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/train/traffic/buckets",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: TrafficBucketsPage,
      documentTitle: "Train",
    },
    {
      path: "/clients/:clientId/projects/:projectId/train/locations/:locationId",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: LocationsPage,
      documentTitle: "Train",
    },
    {
      path: "/clients/:clientId/projects/:projectId/train/lead-scoring/:engagementId",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: EngagementPage,
      documentTitle: "Train",
    },
    {
      path: "/clients/:clientId/projects/:projectId/train/lead-scoring",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: LeadScoringPage,
      documentTitle: "Train",
    },
    {
      path: "/clients/:clientId/projects/:projectId/train/effectiveness/pageviews/:ruleId",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: PageviewEffectivenessRulesPage,
      documentTitle: "Train",
    },
    {
      path: "/clients/:clientId/projects/:projectId/train/effectiveness/sessions/:ruleId",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: SessionEffectivenessRulePage,
      documentTitle: "Train",
    },
    {
      path: "/clients/:clientId/projects/:projectId/train/effectiveness",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: EffectivenessPage,
      documentTitle: "Train",
    },
    {
      path: "/clients/:clientId/projects/:projectId/train/alerts/:alertId",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: AlertPage,
      documentTitle: "Train",
    },
    {
      path: "/clients/:clientId/projects/:projectId/train/alerts",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: AlertsPage,
      documentTitle: "Train",
    },
    {
      path: "/clients/:clientId/projects/:projectId/train/leads-status/:leadStatusRuleId",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: LeadStatusPage,
      documentTitle: "Train",
    },
    {
      path: "/clients/:clientId/projects/:projectId/train/leads-status",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: LeadStatusesPage,
      documentTitle: "Train",
    },
    {
      path: "/clients/:clientId/projects/:projectId/train/location-tags",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: LocationTagsPage,
      documentTitle: "Train",
    },
    {
      path: "/clients/:clientId/projects/:projectId/audience/traits",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: ClassificationsPage,
      documentTitle: "Audiences",
      pageTitle: "Traits",
      pageSubTitle: `Traits are a way of assigning an attribute to a lead and a value to that attribute, based on their actions and behavior. You can then use combinations of these attributes and values to build audience segments for analytics or personalization.`,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/audience/audiences",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: AudiencesPage,
      pageTitle: "Manage Audiences",
      pageSubTitle:
        "Create rules to group users togther basd on their traits and interactions.",
      documentTitle: "Audiences",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/audience/audiences/:audienceid",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: AudiencePage,
      documentTitle: "Audiences",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse",
      withClients: true,
      component: AnalysePage,
      documentTitle: "Analytics",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/custom-report",
      withClients: true,
      component: AnalysePage,
      documentTitle: "Analytics",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/site-overview",
      withClients: true,
      component: SiteOverview,
      documentTitle: "Analytics",
      pageTitle: "Site Overview",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/traffic-&-campaigns",
      withClients: true,
      component: ChannelInsights,
      documentTitle: "Analytics",
      pageTitle: "Traffic & Campaigns",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/landing-pages-&-content",
      withClients: true,
      component: ContentInsights,
      documentTitle: "Analytics",
      pageTitle: "Landing Pages & Content",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/devices",
      withClients: true,
      component: DevicesInsights,
      documentTitle: "Analytics",
      pageTitle: "Devices",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/quick-insights-ecom",
      withClients: true,
      component: EcomInsightsPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/quick-insights-ecom/:insight",
      withClients: true,
      component: EcomInsightsPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/marketing-performance/paid-media",
      withClients: true,
      component: MarketingPerformancePaidMediaPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/marketing-performance/paid-media/:insight",
      withClients: true,
      component: MarketingPerformancePaidMediaPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/marketing-performance/organic",
      withClients: true,
      component: MarketingPerformanceOrganicPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/marketing-performance/organic/:insight",
      withClients: true,
      component: MarketingPerformanceOrganicPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/marketing-performance/email-performance",
      withClients: true,
      component: MarketingPerformanceEmailPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/marketing-performance/email-performance/:insight",
      withClients: true,
      component: MarketingPerformanceEmailPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/marketing-performance/organic-social",
      withClients: true,
      component: MarketingPerformanceOrganicSocialPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/marketing-performance/organic-social/:insight",
      withClients: true,
      component: MarketingPerformanceOrganicSocialPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/marketing-performance/referral-traffic",
      withClients: true,
      component: ReferralTrafficPage,
      documentTitle: "Analytics",
      withDatepicker: true,
      pageTitle: "Referral Traffic",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/optimization/marketing-performance-analysis",
      withClients: true,
      component: PerformanceAnalysisPage,
      documentTitle: "Optimization",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/audience-comparison",
      withClients: true,
      component: AudienceReportPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/audience-comparison/:insight",
      withClients: true,
      component: AudienceReportPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/audience-deep-dive",
      withClients: true,
      component: AudienceDeepDivePage,
      documentTitle: "Analytics",
      withDatepicker: true,
      pageTitle: "Audience Deep Dive",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/audience-insights-new-and-returning/",
      withClients: true,
      component: AudienceNewAndReturningPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/audience-insights-new-and-returning/:insight",
      withClients: true,
      component: AudienceNewAndReturningPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/audience-insights-user-journey/",
      withClients: true,
      component: AudienceUserJourneyPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/audience-insights-user-journey/:insight",
      withClients: true,
      component: AudienceUserJourneyPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/products-overview",
      withClients: true,
      component: ProductOverviewPage,
      documentTitle: "Analytics",
      pageTitle: "Products Overview",
      pageSubTitle: "Compare product performance with selected time period",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/product-deep-dive",
      withClients: true,
      component: ProductReportPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/product-category-deep-dive",
      withClients: true,
      component: ProductCategoryReportPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/product-brand-deep-dive",
      withClients: true,
      component: ProductBrandReportPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/optimization/product-performance-analysis",
      withClients: true,
      component: ProductsRankingPage,
      documentTitle: "Optimization",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/products-performance-map",
      withClients: true,
      component: ProductsPerformanceMapPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/jobs-overview",
      withClients: true,
      component: JobsOverviewPage,
      documentTitle: "Analytics",
      withDatepicker: true,
      pageTitle: "Jobs Overview",
      pageSubTitle: "Compare job performance with last equal time period",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/jobs-deep-dive",
      withClients: true,
      component: JobsReportPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/jobs-category-deep-dive",
      withClients: true,
      component: JobsCategoryReportPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/jobs-performance-map",
      withClients: true,
      component: JobsPerformanceMapPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/user-dashboard/:leadid",
      withClients: true,
      component: SingleUserDashboard,
      documentTitle: "Analytics",
      pageTitle: "Single User Dashboard",
      pageSubTitle: "Examine the journey of this user",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/user-dashboard",
      withClients: true,
      component: UserDashboardPage,
      documentTitle: "Analytics",
      withDatepicker: true,
      pageTitle: "User Dashboard",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/locations-overview",
      withClients: true,
      component: LocationOverviewPage,
      pageTitle: "Locations Overview",
      pageSubTitle: "See which locations generate the most engagement",
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/optimization/content-performance-analysis",
      withClients: true,
      component: ContentPerformancePage,
      documentTitle: "Optimization",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/journey-explorer",
      withClients: true,
      component: JourneyExplorerPage,
      documentTitle: "Analytics",
      withDatepicker: true,
      pageTitle: "User Journey Explorer",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/optimization/conversion-lab",
      withClients: true,
      component: CampaignABOverviewPage,
      documentTitle: "Optimization",
      withDatepicker: true,
      pageTitle: "Conversion Lab Report",
      pageSubTitle: "Variant vs. Control AB test results",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/optimization/lightning-landers",
      withClients: true,
      component: LightningLandersReportPage,
      documentTitle: "Optimization",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/optimization/lightning-landers/:landerId",
      withClients: true,
      component: LightningLandersReportPage,
      documentTitle: "Optimization",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/optimization/rezonate-report",
      withClients: true,
      component: RezonateReportPage,
      documentTitle: "Optimization",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/optimization/rezonate-report/:personalisationId",
      withClients: true,
      component: RezonateReportPage,
      pageTitle: "Rezonate Campaigns",
      pageSubTitle: "Personalization Performance Results",
      documentTitle: "Optimization",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/sales-flow-analysis-report/:crKey",
      withClients: true,
      component: SalesFlowAnalysisPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/standard-reports/sales-flow-analysis-report",
      withClients: true,
      component: SalesFlowAnalysisPage,
      documentTitle: "Analytics",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/reports/chart-library",
      withClients: true,
      minRole: ApiACLRole.ANALYTICS_ADMIN,
      component: ChartLibraryPage,
      documentTitle: "Reporting",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/reports/chart-library/:chartId",
      withClients: true,
      minRole: ApiACLRole.ANALYTICS_ADMIN,
      component: ChartBuilderPage,
      documentTitle: "Reporting",
      withDatepicker: true,
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/reports",
      withClients: true,
      component: ReportsPage,
      pageTitle: "Manager Custom Reports",
      pageSubTitle:
        "Create / Edit & Delete Custom reports or pin them to you Analyse Homepage.",
      documentTitle: "Reporting",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/analyse/reports/:reportId",
      withClients: true,
      component: ReportPage,
      documentTitle: "Reporting",
    },

    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/publish/conversion-lab",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: ConversionLabPage,
      pageTitle: "Conversion Lab",
      documentTitle: "Publish",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/publish/rezonate",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: RezonatePage,
      documentTitle: "Publish",
      pageTitle: "Rezonate",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/publish/components",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: ComponentsPage,
      pageTitle: "Components",
      documentTitle: "Publish",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/publish/components/:componentId",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: ComponentPage,
      documentTitle: "Publish",
    },
    {
      exact: true,
      path: "/clients/:clientId/projects/:projectId/publish/lightning-landers",
      withClients: true,
      minRole: ApiACLRole.PROJECT_OBSERVER,
      component: LightningLandersPage,
      documentTitle: "Publish",
      pageTitle: "Lightning Landers Management",
      pageSubTitle:
        " Create and Manage Your Lightning Landing Pages, Link Them to a Subdomain, and Track Performance.",
    },
    {
      exact: true,
      path: "/styles",
      withClients: true,
      component: StylesPage,
      minRole: ApiACLRole.SUPER_ADMIN,
    },
    {
      exact: true,
      path: "/users",
      withClients: true,
      component: UsersPage,
      documentTitle: "Users",
    },
  ] as IProtectedRoute[];
}
