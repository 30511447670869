import { BugReport } from "@mui/icons-material";
import { Alert, Box, Button, CircularProgress } from "@mui/material";
import { StyledRightAlignedBox } from "Components/UI/StylesFlexBox";
import { useSave } from "Hooks/useSave";
import { useSnackbar } from "notistack";
import { useCSVUploaderContext } from "Providers/CSVUploaderProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import UploaderMappingRow from "./UploaderMappingRow";
import { suggestMapping, testMapping } from "features/csvManager";
import { getPresignedUrlByMediaType } from "services/media";
import Loading from "Components/Loading/Loading";

interface IUploadMapFieldsStepTwoProps {}

function UploadMapFieldsStepTwo({}: IUploadMapFieldsStepTwoProps) {
  const { saving, save } = useSave();
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const {
    media,
    setMappingResponse,
    rule,
    userValue,
    dateGrouping,
    validation,
    step,
    setStep,
    setTestResponse,
    filteredMapping,
    orderedFields,
  } = useCSVUploaderContext();

  const getCSVSuggestions = useCallback(async () => {
    if (!media || !media.files || media.files.length === 0 || !rule.id) {
      return;
    }

    try {
      // first we need to fetch a signed url
      const url = await getPresignedUrlByMediaType(media, "text/csv");
      // then use url to get suggested mapping
      const res = await suggestMapping(rule.id, url);
      setMappingResponse(res);
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(
        "There was a error loading this CSV, please try uploading again.",
        {
          variant: "error",
          autoHideDuration: 4000,
          anchorOrigin: { horizontal: "right", vertical: "top" },
        }
      );
      setTimeout(() => {
        setStep((s) => s - 1);
      }, 1000);
    }
  }, [media, rule, setMappingResponse, enqueueSnackbar, setStep]);

  useEffect(() => {
    if (!media) {
      return;
    }
    getCSVSuggestions();
  }, [media, getCSVSuggestions]);

  const isValid = useMemo(() => {
    const requiredFields = (rule.fields || [])
      .filter((v) => v.required)
      .map((v) => v.id || "");

    const validInValidation = Object.entries(validation)
      .filter(([key, value]) => value)
      .map(([key, value]) => key);

    return requiredFields.every((v) => validInValidation.includes(v));
  }, [validation, rule]);

  const testCSV = async () => {
    save(async () => {
      if (!media || !media.files || media.files.length === 0 || !rule.id) {
        return;
      }

      // first we need to fetch a signed url
      const url = await getPresignedUrlByMediaType(media, "text/csv");
      // then use url to get test mapping

      try {
        const res = await testMapping(
          rule.id,
          url,
          filteredMapping,
          userValue,
          dateGrouping
        );
        setTestResponse(res);
        setStep(step + 1);
      } catch (error) {
        enqueueSnackbar("Internal Server Error", {
          variant: "error",
          autoHideDuration: 2000,
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
      }
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Box mt={2}>
      <Box mb={5}>
        <Box
          mt={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {rule.warning && rule.warning !== "" && (
            <Box sx={{ width: 680 }} mb={4}>
              <Alert severity="warning">{rule.warning}</Alert>
            </Box>
          )}
          <Box>
            <Box>
              {orderedFields.map((field, i) => (
                <Box key={field.id || i} mb={2} sx={{ width: 700 }}>
                  <UploaderMappingRow index={i} />
                </Box>
              ))}
            </Box>

            <StyledRightAlignedBox sx={{ width: 680 }}>
              <Button
                disabled={!isValid}
                onClick={testCSV}
                startIcon={
                  saving ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <BugReport />
                  )
                }
              >
                Test CSV
              </Button>
            </StyledRightAlignedBox>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default UploadMapFieldsStepTwo;
