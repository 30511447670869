import { ApiJob } from "@incendium/api";
import { Autocomplete, TextField, OutlinedTextFieldProps } from "@mui/material";
import { sortAlpha } from "Helpers/arrays";
import { useJobs } from "Hooks/useJobs";
import { useMemo } from "react";

interface IJobsDropdownProps extends Omit<OutlinedTextFieldProps, "onChange"> {
  value?: number;
  onChange: (n?: number, p?: ApiJob) => void;
}

function JobsDropdown({
  label,
  size,
  variant,
  value,
  onChange,
  fullWidth,
  sx,
}: IJobsDropdownProps) {
  const { jobs } = useJobs();

  const selected = useMemo(
    () => (jobs || []).find((l) => l.id === value) || {},
    [jobs, value]
  );
  return (
    <Autocomplete
      sx={sx}
      fullWidth={fullWidth}
      options={sortAlpha([...jobs], "title")}
      value={selected}
      onChange={(e, v) => {
        onChange(v?.id, v ? v : undefined);
      }}
      getOptionLabel={(o: ApiJob) => o.title || ""}
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant} size={size} />
      )}
    />
  );
}

export default JobsDropdown;
