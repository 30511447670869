import { ApiAudience, ApiProject } from "@incendium/api";
import { audienceService } from "Apis";
import { useConditionGroupContext } from "Components/ConditionalGroup/ConditionGroupProvider";
import ConditionGroupsFormWrapper from "Components/ConditionalGroup/ConditionGroupsFormWrapper";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import AudienceRules from "./AudienceRules";
import AudienceSidebar from "./AudienceSidebar";

interface IAudienceFormProps {
  audience: ApiAudience;
  setAudience: React.Dispatch<React.SetStateAction<ApiAudience>>;
  project: ApiProject;
}

function AudienceForm({ audience, setAudience, project }: IAudienceFormProps) {
  const history = useHistory();
  const { conditionGroups, setConditionGroups } = useConditionGroupContext();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!audience || conditionGroups.length) {
      return;
    }

    const groups = audience.conditionGroups || [];
    setConditionGroups(
      groups && groups.length > 0
        ? [...groups]
        : [
            {
              conditions: [{}],
            },
          ]
    );
  }, [audience, setConditionGroups, conditionGroups]);

  const onSubmit = async () => {
    try {
      const res = await audienceService.audienceServiceUpdateAudience({
        projectId: project.id as number,
        audienceId: audience.id as number,
        payload: {
          ...audience,
          conditionGroups,
        },
      });

      enqueueSnackbar(`${res.name} Updated`, {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    } catch (error) {
      enqueueSnackbar("Something Went Wrong", {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }
  };

  const onCancel = () => {
    history.push(`../audiences`);
  };

  return (
    <ConditionGroupsFormWrapper
      onSubmit={onSubmit}
      onCancel={onCancel}
      rules={<AudienceRules />}
    >
      <AudienceSidebar audience={audience} setAudience={setAudience} />
    </ConditionGroupsFormWrapper>
  );
}

export default AudienceForm;
