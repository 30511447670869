import { ApiAudience, ApiAudiencePayload } from "@incendium/api";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { audienceService } from "Apis";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import { useSelectedProject } from "Hooks";
import { useAudiences } from "Hooks/useAudiences";
import produce from "immer";
import { GenericDialoagProps } from "Interfaces";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

interface IAudienceDialogProps extends GenericDialoagProps {
  audience: ApiAudience;
  onSaved: (audience: ApiAudience) => void;
}

function AudienceDialog({
  open,
  setOpen,
  onSaved,
  audience,
}: IAudienceDialogProps) {
  const { selectedProject: project } = useSelectedProject();
  const [name, setName] = useState(audience?.name || "");
  const [description, setDescription] = useState(audience?.description || "");
  const [groups, setGroups] = useState(audience?.conditionGroups || undefined);
  const [disabled, setDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { audiences, setAudiences } = useAudiences();

  useEffect(() => {
    setName(audience?.name || "");
    setDescription(audience?.description || "");
    if (audience?.conditionGroups && audience?.conditionGroups.length > 0) {
      setGroups(audience?.conditionGroups);
    }
  }, [audience]);

  const onSubmit = async () => {
    setDisabled(true);

    const payload: ApiAudiencePayload = {
      name,
      description,
      conditionGroups: groups,
    };

    try {
      const fn =
        audience && audience.id
          ? audienceService.audienceServiceUpdateAudience({
              projectId: project?.id as number,
              audienceId: audience.id,
              payload,
            })
          : audienceService.audienceServiceCreateAudience({
              projectId: project?.id as number,
              payload,
            });
      const res = await fn;
      enqueueSnackbar(`${res.name} Saved`, {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });

      const idx = audiences.findIndex((a) => a.id === res.id);
      if (idx >= 0) {
        setAudiences(
          produce(audiences, (draft) => {
            draft[idx] = res;
          })
        );
      } else {
        setAudiences(
          produce(audiences, (draft) => {
            draft.push(res);
          })
        );
      }
      onSaved(res);
    } catch (error) {
      enqueueSnackbar("Something Went Wrong", {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    } finally {
      setDisabled(false);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <CustomDialog onClose={onClose} fullWidth maxWidth="sm" open={open}>
      <ValidatorForm onSubmit={onSubmit}>
        <DialogTitle>
          {audience?.id ? `Edit ${audience.name}` : `Add New Audience`}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextValidator
                variant="outlined"
                fullWidth
                name="name"
                autoFocus={true}
                label="Audience Name *"
                value={name}
                disabled={disabled}
                validators={["required"]}
                errorMessages={["Audience Name is required"]}
                onChange={(e: any) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                variant="outlined"
                fullWidth
                name="description"
                label="Audience Description "
                value={description}
                disabled={disabled}
                multiline
                rows={3}
                rowsMax={8}
                validators={["maxStringLength:1000"]}
                errorMessages={[
                  "Audience description can only be 1000 characters long",
                ]}
                onChange={(e: any) => setDescription(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={disabled} onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button
            disabled={disabled}
            variant="contained"
            color="primary"
            type="submit"
          >
            {audience ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </ValidatorForm>
    </CustomDialog>
  );
}

export default AudienceDialog;
