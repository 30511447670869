import {
  ApiChartAttribute,
  ApiDimension,
  ApiMetric,
  ApiYAxisChartType,
} from "@incendium/api";
import { Box, Stack, useTheme } from "@mui/material";
import AnalyticsCardOld, {
  AnalyticsCardTitle,
} from "Components/Analytics/AnalyticsCard";
import {
  metricToName,
  removeFirstDimension,
  useChartData,
} from "features/analytics";
import { StatCardRound, StatCardRoundTypography } from "Components/UI/StatCard";
import StyledComposedChart from "Components/StyledComposedChart/StyledComposedChart";
import { formatPercentage, percentageChange } from "Helpers/percentage";
import { useMemo } from "react";
import { truncate } from "Helpers/truncate";
import { useFromToContext } from "Providers/FromToProvider";
import { useSelectedProject } from "Hooks";
import Loading from "Components/Loading/Loading";
import IncreaseDecrease from "Components/IncreaseDecrease/IncreaseDecrease";

interface IAudienceEffectiveCardProps {
  attributes: ApiChartAttribute[];
}

function AudienceEffectiveCard({ attributes }: IAudienceEffectiveCardProps) {
  const theme = useTheme();
  const { chartOutput, chartComparisonOutput } = useFromToContext();
  const { selectedProject } = useSelectedProject();
  const chart = useMemo(
    () => ({
      name: "",
      dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
      yAxisKeys: [
        {
          fields: [
            ApiMetric.METRIC_SESSION_COUNT_PER_LEAD,
            ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD,
          ],
        },
      ],
      attributes,
    }),
    [attributes]
  );

  const { chartData, dimensions, loading } = useChartData(
    selectedProject,
    chart,
    chartOutput
  );
  const { chartData: compData } = useChartData(
    selectedProject,
    chart,
    chartComparisonOutput
  );

  const parsedChartData = useMemo(() => {
    if (!chartData?.data) {
      return [];
    }
    return removeFirstDimension(chartData?.data, dimensions);
  }, [chartData, dimensions]);

  const incDecChartData = useMemo(() => {
    if (!parsedChartData) {
      return [];
    }
    return parsedChartData.map((d) => {
      const found = (compData?.data || []).find((c) => c.name === d.name);
      if (!found) {
        return {
          name: d.name,
          [ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD]: 0,
          [ApiMetric.METRIC_SESSION_COUNT_PER_LEAD]: 0,
        };
      }
      return {
        name: d.name,
        [ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD]: percentageChange(
          Number(found[ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD]) || 0,
          Number(d[ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD]) || 0
        ),
        [ApiMetric.METRIC_SESSION_COUNT_PER_LEAD]: percentageChange(
          Number(found[ApiMetric.METRIC_SESSION_COUNT_PER_LEAD]) || 0,
          Number(d[ApiMetric.METRIC_SESSION_COUNT_PER_LEAD]) || 0
        ),
      };
    });
  }, [parsedChartData, compData]);

  const mostEffective = useMemo(() => {
    return [...parsedChartData].sort(
      (a, b) =>
        (Number(b[ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD]) || 0) -
        (Number(a[ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD]) || 0)
    )[0];
  }, [parsedChartData]);

  const biggestIncrease = useMemo(() => {
    return incDecChartData.sort(
      (a, b) =>
        (Number(b[ApiMetric.METRIC_SESSION_COUNT_PER_LEAD]) || 0) -
        (Number(a[ApiMetric.METRIC_SESSION_COUNT_PER_LEAD]) || 0)
    )[0];
  }, [incDecChartData]);

  const absIncDec = useMemo(() => {
    if (!incDecChartData.length) {
      return 0;
    }

    const s = Math.abs(
      Number(
        incDecChartData.sort(
          (a, b) =>
            Math.abs(Number(b[ApiMetric.METRIC_SESSION_COUNT_PER_LEAD]) || 0) -
            Math.abs(Number(a[ApiMetric.METRIC_SESSION_COUNT_PER_LEAD]) || 0)
        )[0][ApiMetric.METRIC_SESSION_COUNT_PER_LEAD]
      ) || 0
    );
    const e = Math.abs(
      Number(
        incDecChartData.sort(
          (a, b) =>
            Math.abs(
              Number(b[ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD]) || 0
            ) -
            Math.abs(
              Number(a[ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD]) || 0
            )
        )[0][ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD]
      ) || 0
    );

    return e > s ? e : s;
  }, [incDecChartData]);

  return (
    <AnalyticsCardOld sx={{ justifyContent: "center", minHeight: 408 }}>
      {loading ? (
        <Loading />
      ) : (
        <Stack direction={"row"} spacing={2}>
          <Box sx={{ flex: 1 }}>
            <Box sx={{ height: 24 }}>
              <AnalyticsCardTitle title="Average Sessions vs. Effective Sessions Per User" />
            </Box>

            <Box
              mt={3}
              sx={{
                height: 290,
                width: "100%",
              }}
            >
              <StyledComposedChart
                noReOrder
                data={parsedChartData}
                xAxisProps={{
                  tickFormatter: (v: string) => truncate(v, 18),
                }}
                metricsOrder={[
                  ApiMetric.METRIC_SESSION_COUNT_PER_LEAD,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD,
                ]}
                yAxisKeys={[
                  {
                    key: "l",
                    fields: [
                      ApiMetric.METRIC_SESSION_COUNT_PER_LEAD,
                      ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD,
                    ],
                    chart: {
                      [ApiMetric.METRIC_SESSION_COUNT_PER_LEAD]:
                        ApiYAxisChartType.BAR,
                      [ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD]:
                        ApiYAxisChartType.DOT,
                    },
                  },
                ]}
                fills={{
                  [ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD]:
                    theme.palette.analytics.green,
                }}
                noLegend
                showTooltip
              />
            </Box>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box sx={{ height: 24 }}>
              <AnalyticsCardTitle title="Changes in the Selected Time Period" />
            </Box>

            <Box
              mt={3}
              sx={{
                height: 290,
                width: "100%",
              }}
            >
              <StyledComposedChart
                noReOrder
                data={incDecChartData}
                metricsOrder={[
                  ApiMetric.METRIC_SESSION_COUNT_PER_LEAD,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD,
                ]}
                xAxisProps={{
                  tickFormatter: (v) => truncate(v, 18),
                }}
                yAxisProps={[
                  {
                    tickFormatter: (v) => formatPercentage(v),
                    domain: [-absIncDec * 1.2, absIncDec * 1.2],
                  },
                ]}
                yAxisKeys={[
                  {
                    key: "l",
                    fields: [
                      ApiMetric.METRIC_SESSION_COUNT_PER_LEAD,
                      ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD,
                    ],
                    chart: {
                      [ApiMetric.METRIC_SESSION_COUNT_PER_LEAD]:
                        ApiYAxisChartType.BAR,
                      [ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD]:
                        ApiYAxisChartType.DOT,
                    },
                  },
                ]}
                fills={{
                  [ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD]:
                    theme.palette.analytics.green,
                }}
                noLegend
                showTooltip
                tooltipProps={{
                  noSort: true,
                  renderValueFn: (key, v) =>
                    `${metricToName(key)} : ${formatPercentage(v)}`,
                }}
                showReferenceLineKey={"l"}
              ></StyledComposedChart>
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              minWidth: 243,
              maxWidth: 243,
              height: 368,
              position: "relative",
            }}
          >
            <StatCardRound
              size="medium"
              sx={{ position: "absolute", bottom: 0, left: 0 }}
              backgroundColor={1}
            >
              {biggestIncrease && (
                <Stack mt={1} spacing={0.5} alignItems="center">
                  <StatCardRoundTypography size="large" lines={2}>
                    {biggestIncrease?.name}
                  </StatCardRoundTypography>
                  <StatCardRoundTypography size="small" opactity={0.8}>
                    had the biggest increase in avg. sessions this time period
                  </StatCardRoundTypography>
                  <IncreaseDecrease
                    direction={
                      Number(
                        biggestIncrease[ApiMetric.METRIC_SESSION_COUNT_PER_LEAD]
                      ) > 0
                        ? "up"
                        : "down"
                    }
                    value={Number(
                      biggestIncrease[ApiMetric.METRIC_SESSION_COUNT_PER_LEAD]
                    )}
                    fontSize={28}
                    fontWeight={600}
                  />
                </Stack>
              )}
            </StatCardRound>
            <StatCardRound sx={{ position: "absolute", top: 0, right: 0 }}>
              {mostEffective?.name && (
                <>
                  <StatCardRoundTypography size="small" opactity={0.8}>
                    Looks like
                  </StatCardRoundTypography>
                  <StatCardRoundTypography size="large" lines={2}>
                    {mostEffective?.name}
                  </StatCardRoundTypography>
                  <StatCardRoundTypography size="small" opactity={0.8}>
                    sessions are proving most effective
                  </StatCardRoundTypography>
                </>
              )}
            </StatCardRound>
          </Box>
        </Stack>
      )}
    </AnalyticsCardOld>
  );
}

export default AudienceEffectiveCard;
