import { useSelectedProject } from "Hooks";
import Projects from "Assets/icons/projects-icon.png";
import { LeftMenuItem } from "features/leftMenu";

function ClientMenu() {
  const { selectedClient } = useSelectedProject();

  return (
    <>
      <LeftMenuItem
        to={`/clients/${selectedClient?.id}`}
        text="Projects"
        imagePath={Projects}
        active={true}
      />
    </>
  );
}

export default ClientMenu;
