import { ApiLeadScoreReason, ApiRetentionStatus } from "@incendium/api";

export const leadScoreReasonToName = (status: ApiLeadScoreReason) => {
  switch (status) {
    case ApiLeadScoreReason.OPE:
      return "OPE";
    case ApiLeadScoreReason.ADJUSTMENT:
      return "Adjustment";
    case ApiLeadScoreReason.CONSOLIDATION:
      return "Consolidation";
    default:
      return "";
  }
};

export const retentionStatusToName = (r: ApiRetentionStatus) => {
  switch (r) {
    case ApiRetentionStatus.AT_RISK:
      return "At Risk";
    case ApiRetentionStatus.DEAD:
      return "Dead";
    case ApiRetentionStatus.WON_BACK:
      return "Wno Back";

    default:
      return "";
  }
};
