/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiConversionStateTrigger;
(function (ApiConversionStateTrigger) {
    ApiConversionStateTrigger["UNSPECIFIED"] = "CONVERSION_TRIGGER_UNSPECIFIED";
    ApiConversionStateTrigger["CLICK"] = "CONVERSION_TRIGGER_CLICK";
    ApiConversionStateTrigger["SUBMIT"] = "CONVERSION_TRIGGER_SUBMIT";
    ApiConversionStateTrigger["PAGE_VIEW"] = "CONVERSION_TRIGGER_PAGE_VIEW";
    ApiConversionStateTrigger["MANUAL"] = "CONVERSION_TRIGGER_MANUAL";
    ApiConversionStateTrigger["ONSITE_PAGEVIEWS"] = "CONVERSION_TRIGGER_ONSITE_PAGEVIEWS";
    ApiConversionStateTrigger["TIME_ON_ELEMENT"] = "CONVERSION_TRIGGER_TIME_ON_ELEMENT";
})(ApiConversionStateTrigger || (ApiConversionStateTrigger = {}));
export function ApiConversionStateTriggerFromJSON(json) {
    return ApiConversionStateTriggerFromJSONTyped(json, false);
}
export function ApiConversionStateTriggerFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiConversionStateTriggerToJSON(value) {
    return value;
}
