/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiDimensionAllNamesResponseFromJSON, ApiDimensionNameResponseFromJSON, ApiFilterableDimensionsResponseFromJSON, ApiGetUserJourneyResponseFromJSON, ApiGetUsersResponseFromJSON, ApiPrimaryRequestPayloadToJSON, ApiResultFromJSON, ApiSalesFlowPayloadToJSON, ApiSalesFlowResultFromJSON, InlineObjectToJSON, } from '../models';
/**
 *
 */
export class AnalyticsServiceApi extends runtime.BaseAPI {
    /**
     */
    analyticsServiceGetAllDimensionNamesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling analyticsServiceGetAllDimensionNames.');
            }
            const queryParameters = {};
            if (requestParameters.dimension !== undefined) {
                queryParameters['dimension'] = requestParameters.dimension;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/analytics/v1/projects/{projectId}/dimension/names`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiDimensionAllNamesResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    analyticsServiceGetAllDimensionNames(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.analyticsServiceGetAllDimensionNamesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    analyticsServiceGetDimensionNamesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling analyticsServiceGetDimensionNames.');
            }
            if (requestParameters.dimension === null || requestParameters.dimension === undefined) {
                throw new runtime.RequiredError('dimension', 'Required parameter requestParameters.dimension was null or undefined when calling analyticsServiceGetDimensionNames.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/analytics/v1/projects/{projectId}/dimension/{dimension}/names`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"dimension"}}`, encodeURIComponent(String(requestParameters.dimension))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiDimensionNameResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    analyticsServiceGetDimensionNames(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.analyticsServiceGetDimensionNamesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    analyticsServiceGetFilterValuesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling analyticsServiceGetFilterValues.');
            }
            if (requestParameters.type === null || requestParameters.type === undefined) {
                throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling analyticsServiceGetFilterValues.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/analytics/v1/projects/{projectId}/leads/{type}/values`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiDimensionNameResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    analyticsServiceGetFilterValues(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.analyticsServiceGetFilterValuesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    analyticsServiceGetFilterableDimensionsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.projectId !== undefined) {
                queryParameters['projectId'] = requestParameters.projectId;
            }
            if (requestParameters.dimension !== undefined) {
                queryParameters['dimension'] = requestParameters.dimension;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/analytics/v1/dimensions`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiFilterableDimensionsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    analyticsServiceGetFilterableDimensions(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.analyticsServiceGetFilterableDimensionsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * ------------------- Primary -------------------//
     */
    analyticsServiceGetPrimaryMetricsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling analyticsServiceGetPrimaryMetrics.');
            }
            if (requestParameters.chartId === null || requestParameters.chartId === undefined) {
                throw new runtime.RequiredError('chartId', 'Required parameter requestParameters.chartId was null or undefined when calling analyticsServiceGetPrimaryMetrics.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling analyticsServiceGetPrimaryMetrics.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/analytics/v1/projects/{projectId}/charts/{chartId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"chartId"}}`, encodeURIComponent(String(requestParameters.chartId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiPrimaryRequestPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultFromJSON(jsonValue));
        });
    }
    /**
     * ------------------- Primary -------------------//
     */
    analyticsServiceGetPrimaryMetrics(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.analyticsServiceGetPrimaryMetricsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    analyticsServiceGetSalesFlowRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling analyticsServiceGetSalesFlow.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling analyticsServiceGetSalesFlow.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/analytics/v1/projects/{projectId}/sales_flow`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiSalesFlowPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiSalesFlowResultFromJSON(jsonValue));
        });
    }
    /**
     */
    analyticsServiceGetSalesFlow(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.analyticsServiceGetSalesFlowRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    analyticsServiceGetUserJourneyRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling analyticsServiceGetUserJourney.');
            }
            if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
                throw new runtime.RequiredError('leadId', 'Required parameter requestParameters.leadId was null or undefined when calling analyticsServiceGetUserJourney.');
            }
            const queryParameters = {};
            if (requestParameters.pageSize !== undefined) {
                queryParameters['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.pageToken !== undefined) {
                queryParameters['pageToken'] = requestParameters.pageToken;
            }
            if (requestParameters.search !== undefined) {
                queryParameters['search'] = requestParameters.search;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/analytics/v1/projects/{projectId}/leads/{leadId}/journey`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"leadId"}}`, encodeURIComponent(String(requestParameters.leadId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiGetUserJourneyResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    analyticsServiceGetUserJourney(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.analyticsServiceGetUserJourneyRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * ------------------- User journey -------------------//
     */
    analyticsServiceGetUsersRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling analyticsServiceGetUsers.');
            }
            if (requestParameters.body === null || requestParameters.body === undefined) {
                throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling analyticsServiceGetUsers.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/analytics/v1/projects/{projectId}/leads`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: InlineObjectToJSON(requestParameters.body),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiGetUsersResponseFromJSON(jsonValue));
        });
    }
    /**
     * ------------------- User journey -------------------//
     */
    analyticsServiceGetUsers(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.analyticsServiceGetUsersRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
/**
    * @export
    * @enum {string}
    */
export var AnalyticsServiceGetFilterValuesTypeEnum;
(function (AnalyticsServiceGetFilterValuesTypeEnum) {
    AnalyticsServiceGetFilterValuesTypeEnum["UNSPECIFIED"] = "UserFilterType_Unspecified";
    AnalyticsServiceGetFilterValuesTypeEnum["AUDIENCE"] = "UserFilterType_Audience";
    AnalyticsServiceGetFilterValuesTypeEnum["MICRO_CONVERSION"] = "UserFilterType_MicroConversion";
    AnalyticsServiceGetFilterValuesTypeEnum["MACRO_CONVERSION"] = "UserFilterType_MacroConversion";
    AnalyticsServiceGetFilterValuesTypeEnum["SALES"] = "UserFilterType_Sales";
    AnalyticsServiceGetFilterValuesTypeEnum["DEVICE"] = "UserFilterType_Device";
    AnalyticsServiceGetFilterValuesTypeEnum["PRODUCT"] = "UserFilterType_Product";
    AnalyticsServiceGetFilterValuesTypeEnum["STATUS"] = "UserFilterType_Status";
    AnalyticsServiceGetFilterValuesTypeEnum["LEAD_SCORE"] = "UserFilterType_LeadScore";
    AnalyticsServiceGetFilterValuesTypeEnum["LTV"] = "UserFilterType_LTV";
})(AnalyticsServiceGetFilterValuesTypeEnum || (AnalyticsServiceGetFilterValuesTypeEnum = {}));
