import { Moving } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { formatNumber } from "Helpers/numbers";
import { percentageChange } from "Helpers/percentage";
import { useMemo } from "react";

interface IIncreaseDecreaseProps {
  direction?: "up" | "down" | "same";
  value: number;
  size?: "small" | "inherit" | "large" | "medium";
  fontSize?: number;
  fontWeight?: number;
  useThemeColours?: boolean;
}

export const incDec = (
  comp: number = 0,
  curr: number = 0,
  isPercentage?: boolean
): {
  direction?: "up" | "down";
  value: number;
} => {
  const change = isPercentage ? curr - comp : percentageChange(comp, curr);
  return {
    value: Number(formatNumber(change || 0)),
    direction:
      Math.sign(change) > 0 ? "up" : Math.sign(change) < 0 ? "down" : undefined,
  };
};

function IncreaseDecrease({
  direction: directionOverride,
  value,
  useThemeColours,
  size,
  fontSize,
  fontWeight,
}: IIncreaseDecreaseProps) {
  const direction = useMemo(() => {
    return directionOverride
      ? directionOverride
      : Math.sign(value) > 0
      ? "up"
      : Math.sign(value) < 0
      ? "down"
      : "same";
  }, [value, directionOverride]);

  const colours = useMemo(() => {
    return useThemeColours
      ? ["success.main", "error.main", "secondary"]
      : ["#fff", "#fff", "#fff"];
  }, [useThemeColours]);

  return (
    <Stack
      sx={{
        position: "relative",
        paddingLeft: fontSize ? `${fontSize + 6}px` : 3,
      }}
      direction="row"
      alignItems={"baseline"}
      color={
        direction === "up"
          ? colours[0]
          : direction === "down"
          ? colours[1]
          : colours[2]
      }
    >
      <Moving
        fontSize={size || "small"}
        sx={{
          fontSize: fontSize,
          fontWeight,
          transform: `translateY(-50%) scale(1, ${
            direction === "up" ? 1 : -1
          })`,
          position: "absolute",
          left: 0,
          top: "50%",
        }}
      />

      <Typography
        variant={!size || size === "small" ? "body2" : "body1"}
        sx={
          colours
            ? {
                fontSize: fontSize,
                fontWeight,
                color: direction === "up" ? colours[0] : colours[1],
              }
            : {
                fontSize: fontSize,
                fontWeight,
              }
        }
        color={
          direction === "up"
            ? colours[0]
            : direction === "down"
            ? colours[1]
            : colours[2]
        }
        noWrap
      >
        {formatNumber(Math.abs(value))}%
      </Typography>
    </Stack>
  );
}

export default IncreaseDecrease;
