/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import { ApiCSVSavePayloadFromJSON, ApiCSVSavePayloadToJSON, } from './ApiCSVSavePayload';
export function ApiCSVSaveMultiplePayloadFromJSON(json) {
    return ApiCSVSaveMultiplePayloadFromJSONTyped(json, false);
}
export function ApiCSVSaveMultiplePayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'mappings': !exists(json, 'mappings') ? undefined : (mapValues(json['mappings'], ApiCSVSavePayloadFromJSON)),
    };
}
export function ApiCSVSaveMultiplePayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'mappings': value.mappings === undefined ? undefined : (mapValues(value.mappings, ApiCSVSavePayloadToJSON)),
    };
}
