import { Box, Paper, Typography, alpha } from "@mui/material";
import { formatEnumVal } from "Helpers/enumToText";
import { IAnalyticsCardCustomTooltipProps, TKeyValue } from "Interfaces";
import { getCellFillByDimension } from "Models/Chart.model";
import { TColourMap, useAnalyticsContext } from "Providers/AnalyticsProvider";
import { formatMetric, labelMetricToName } from "features/analytics";
import { useMemo } from "react";

const ToolTipRow = ({
  item,
  renderValueFn,
  colourMap,
  fills,
}: {
  item: any;
  renderValueFn:
    | ((
        key: any,
        value: any,
        payload?: any
      ) =>
        | string
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>)
    | undefined;
  colourMap: TColourMap;
  fills?: TKeyValue;
}) => {
  const fill = useMemo(() => {
    const fill =
      fills && fills[item.name]
        ? fills[item.name]
        : getCellFillByDimension(
            item.payload.name,
            colourMap,
            item.fill && item.fill !== "#fff" ? item.fill : item.stroke
          );

    return item.name.includes("previous") ? alpha(fill, 0.6) : fill;
  }, [item, colourMap, fills]);

  const label = useMemo(() => {
    return labelMetricToName(item.name);
  }, [item]);

  const metric = item.name.includes("\\")
    ? item.name.split("\\")[1]
    : item.name;

  return (
    <Typography
      sx={{
        position: "relative",
        paddingLeft: fill ? "18px" : 0,
        "&::before": {
          content: "''",
          position: "absolute",
          left: 0,
          top: 4,
          height: 10,
          width: 10,
          background: fill,
          borderRadius: "50%",
        },
      }}
      variant="body2"
      gutterBottom
    >
      {renderValueFn
        ? renderValueFn(metric, item.value, item)
        : `${label} : ${formatMetric(metric, item.value)}`}
    </Typography>
  );
};

const AnalyticsCardCustomTooltip = ({
  active,
  payload,
  label,
  noSort,
  renderLabelFn,
  renderValueFn,
  fills,
  ...rest
}: IAnalyticsCardCustomTooltipProps) => {
  const { colourMap } = useAnalyticsContext();
  if (active && payload && payload.length) {
    const sorted = noSort
      ? payload
      : payload.sort((a: any, b: any) => b.value - a.value);
    const title = payload[0].payload.name || label || payload[0].name;

    return (
      <Paper>
        <Box p={1}>
          <Typography variant="subtitle2" gutterBottom>
            {renderLabelFn
              ? renderLabelFn(title || "")
              : title.includes("_")
              ? formatEnumVal(title || "")
              : title}
          </Typography>
          {sorted.map((p: any, i: number) => (
            <ToolTipRow
              item={p}
              renderValueFn={renderValueFn}
              colourMap={colourMap}
              key={i}
              fills={fills}
            />
          ))}
        </Box>
      </Paper>
    );
  }

  return null;
};

export default AnalyticsCardCustomTooltip;
