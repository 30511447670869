import { ApiConversion, ApiConditionCustom } from "@incendium/api";
import { Box, Grid, TextField, MenuItem } from "@mui/material";
import ConditionalGroup from "Components/ConditionalGroup/ConditionalGroup";
import { useConditionGroupContext } from "Components/ConditionalGroup/ConditionGroupProvider";
import { useEffect, useMemo, useState } from "react";
import produce from "immer";

type TLeadStatusRuleType = {
  key: string;
  label: string;
};

const Row = ({
  conversions,
  groupIdx,
  rowIdx,
}: {
  conversions: ApiConversion[];
  groupIdx: number;
  rowIdx: number;
}) => {
  const { conditionGroups, setConditionGroups } = useConditionGroupContext();
  const [types] = useState<TLeadStatusRuleType[]>([
    {
      key: "conversion",
      label: "Conversion (goal achieved)",
    },
  ]);

  const condition = useMemo(() => {
    return (conditionGroups[groupIdx]?.conditions || [])[rowIdx] || {};
  }, [conditionGroups, groupIdx, rowIdx]);

  const [type, setType] = useState(
    condition.custom &&
      condition.custom === ApiConditionCustom.CONVERSION_TRIGGERED
      ? "conversion"
      : condition.custom !== ApiConditionCustom.UNSPECIFIED
      ? condition.custom
      : ""
  );

  const conversionId = useMemo(() => {
    return (
      (conditionGroups[groupIdx]?.conditions || [])[rowIdx]?.conversionId || ""
    );
  }, [conditionGroups, groupIdx, rowIdx]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField
          label="type"
          fullWidth
          select
          value={type || ""}
          onChange={(e) => {
            setConditionGroups(
              produce(conditionGroups, (draft) => {
                (draft[groupIdx]?.conditions || [])[rowIdx].custom =
                  e.target.value === "conversion"
                    ? ApiConditionCustom.UNSPECIFIED
                    : (e.target.value as ApiConditionCustom);
              })
            );
            setType(e.target.value);
          }}
        >
          {types.map((t) => (
            <MenuItem key={t.key} value={t.key}>
              {t.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6}>
        {type === "conversion" && (
          <TextField
            select
            variant="outlined"
            fullWidth
            label="conversion"
            value={conversionId}
            onChange={(e) => {
              setConditionGroups(
                produce(conditionGroups, (draft) => {
                  (draft[groupIdx]?.conditions || [])[rowIdx].conversionId =
                    Number(e.target.value);
                })
              );
            }}
          >
            {conversions.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Grid>
    </Grid>
  );
};

function LeadStatusRuleRules({
  conversions,
}: {
  conversions: ApiConversion[];
}) {
  const { setRowComponent } = useConditionGroupContext();

  useEffect(() => {
    setRowComponent(() => (groupIdx, rowIdx) => {
      return (
        <Row groupIdx={groupIdx} rowIdx={rowIdx} conversions={conversions} />
      );
    });
  }, [conversions]);

  return (
    <Box>
      <ConditionalGroup />
    </Box>
  );
}

export default LeadStatusRuleRules;
