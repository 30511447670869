/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiClassificationItemFromJSON, ApiClassificationItemToJSON, } from './ApiClassificationItem';
import { ApiLeadFromJSON, ApiLeadToJSON, } from './ApiLead';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiStaticClassificationFromJSON(json) {
    return ApiStaticClassificationFromJSONTyped(json, false);
}
export function ApiStaticClassificationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'lead': !exists(json, 'lead') ? undefined : ApiLeadFromJSON(json['lead']),
        'classificationItem': !exists(json, 'classificationItem') ? undefined : ApiClassificationItemFromJSON(json['classificationItem']),
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'classificationItemId': !exists(json, 'classificationItemId') ? undefined : json['classificationItemId'],
        'leadId': !exists(json, 'leadId') ? undefined : json['leadId'],
    };
}
export function ApiStaticClassificationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'lead': ApiLeadToJSON(value.lead),
        'classificationItem': ApiClassificationItemToJSON(value.classificationItem),
        'project': ApiProjectToJSON(value.project),
        'classificationItemId': value.classificationItemId,
        'leadId': value.leadId,
    };
}
