import { Box, TextField, Typography } from "@mui/material";
import {
  I_CAMPAIGNID,
  I_CONTENTID,
  I_MEDIUM,
  I_SOURCE,
  I_TERM,
} from "features/utms/constants";
import { useMemo } from "react";

export const metaIAIMapping = {
  [I_SOURCE]: "{{site_source_name}}",
  [I_MEDIUM]: "cpc",
  [I_CAMPAIGNID]: "{{campaign.id}}",
  [I_CONTENTID]: "{{adset.id}}",
  [I_TERM]: "{{ad.name}}",
};

function MetaAdsTrackingTemplate() {
  const params = useMemo(() => {
    return Object.keys(metaIAIMapping)
      .map((k) => `${k}=${metaIAIMapping[k]}`)
      .join("&");
  }, []);

  return (
    <Box>
      <Typography gutterBottom>
        In the <strong>Destination {"->"} Website URL</strong> field, enter the
        following URL:
      </Typography>
      <TextField
        fullWidth
        multiline
        variant="outlined"
        value={`[you ads target URL]?${params}`}
        InputProps={{
          readOnly: true,
        }}
      />
      <Typography my={2}>
        If you already have a URL setup with UTMS and wish just to append ours
        just add this to the end of it:
      </Typography>
      <TextField
        fullWidth
        multiline
        variant="outlined"
        value={`&${params}`}
        InputProps={{
          readOnly: true,
        }}
      />
    </Box>
  );
}

export default MetaAdsTrackingTemplate;
