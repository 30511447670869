import {
  Box,
  Button,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "Actions";
import { WashedForm } from "Components/UI/WashedForm";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { motion } from "framer-motion";

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation<{ from: string }>();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleSignIn = async () => {
    dispatch(
      login({
        email,
        password,
        onSuccess: () => {
          const from = location.state?.from || "/";
          history.push(from);
        },
        onFail: (e) => {
          enqueueSnackbar("Internal Server Error", {
            variant: "error",
            autoHideDuration: 2000,
            anchorOrigin: { horizontal: "right", vertical: "top" },
          });
        },
      })
    );
  };

  return (
    <>
      <WashedForm>
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: {
              delay: 0.2,
            },
          }}
          exit={{
            x: 300,
            opacity: 0,
            transition: {
              duration: 0.3,
            },
          }}
        >
          <Stack spacing={2}>
            <div>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
            </div>
            <div>
              <FormLabel>Password</FormLabel>
              <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
          </Stack>

          <Box mt={4} mb={2}>
            <Button
              onClick={handleSignIn}
              variant="contained"
              fullWidth
              color="primary"
              size="large"
            >
              Log in
            </Button>
          </Box>

          <Box mt={1} display={"flex"} justifyContent={"flex-end"}>
            <Typography color={"white"} fontWeight={500}>
              Forgot Password?
              <Typography component={"span"} ml={1}>
                <Link to="/password/forgot-password" color="primary">
                  Reset
                </Link>
              </Typography>
            </Typography>
          </Box>
        </motion.div>
      </WashedForm>
    </>
  );
};

export default LoginForm;
