import { ApiChartAttribute, ApiDimension } from "@incendium/api";
import { Typography } from "@mui/material";
import StyledDrawer, { StyledDrawerTitle } from "Components/UI/StyledDrawer";
import { ChartBuilderAttributes } from "features/chartLibrary";
import produce from "immer";
import { CallbackOrVal, GenericDialoagProps, IChart } from "Interfaces";

interface IAnalyticsAttributionSidebarProps extends GenericDialoagProps {
  chart: IChart;
  setChart: (v: CallbackOrVal<IChart>) => void;
  dimensions: ApiDimension[];
}

function AnalyticsAttributionSidebar({
  open,
  setOpen,
  chart,
  setChart,
  dimensions,
}: IAnalyticsAttributionSidebarProps) {
  return (
    <StyledDrawer open={open} onClose={() => setOpen(false)}>
      <StyledDrawerTitle>
        <Typography variant="subtitle1">Extend Your Chart</Typography>
      </StyledDrawerTitle>

      <ChartBuilderAttributes
        dimensions={dimensions}
        attributes={chart.attributes}
        setAttributes={(attributes) =>
          setChart((chart) =>
            produce(chart, (draft) => {
              draft.attributes = attributes as ApiChartAttribute[];
            })
          )
        }
      />
    </StyledDrawer>
  );
}

export default AnalyticsAttributionSidebar;
