/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiLeadScoreAdjustmentReasonFromJSON, ApiLeadScoreAdjustmentReasonToJSON, } from './ApiLeadScoreAdjustmentReason';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiLeadScoreAdjustmentRuleFromJSON(json) {
    return ApiLeadScoreAdjustmentRuleFromJSONTyped(json, false);
}
export function ApiLeadScoreAdjustmentRuleFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'reason': !exists(json, 'reason') ? undefined : ApiLeadScoreAdjustmentReasonFromJSON(json['reason']),
        'score': !exists(json, 'score') ? undefined : json['score'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}
export function ApiLeadScoreAdjustmentRuleToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'project': ApiProjectToJSON(value.project),
        'reason': ApiLeadScoreAdjustmentReasonToJSON(value.reason),
        'score': value.score,
        'value': value.value,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'projectId': value.projectId,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}
