import { ApiUserFilterType } from "@incendium/api";
import { getUserFilterValues } from "features/leadAnalytics";
import { enumToArray } from "Helpers/enumToText";
import { useSelectedProject } from "Hooks";
import { useCallback, useEffect, useState } from "react";

function useLeadFilters() {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<
    { key: ApiUserFilterType; values: string[] | undefined }[]
  >([]);
  const { selectedProject } = useSelectedProject();

  const loadData = useCallback(async (projectId: number) => {
    setLoading(true);
    const filters = await Promise.all(
      enumToArray(ApiUserFilterType)
        .filter((f) => f !== ApiUserFilterType.UNSPECIFIED)
        .map(async (f) => {
          const values = await getUserFilterValues(projectId, f);
          return { key: f, values };
        })
    );

    setFilters(filters);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!selectedProject?.id) {
      return;
    }
    loadData(selectedProject.id!);
  }, [selectedProject?.id, loadData]);

  return { filters, loading };
}

export default useLeadFilters;
