/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function ApiCanActivateCampaignResponseFromJSON(json) {
    return ApiCanActivateCampaignResponseFromJSONTyped(json, false);
}
export function ApiCanActivateCampaignResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'canActivate': !exists(json, 'canActivate') ? undefined : json['canActivate'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}
export function ApiCanActivateCampaignResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'canActivate': value.canActivate,
        'message': value.message,
    };
}
