import { ApiCampaignType, ApiSimpleCampaign } from "@incendium/api";
import { Button } from "@mui/material";
import Loading from "Components/Loading/Loading";
import { useMount, useSelectedProject } from "Hooks";
import { useCampaigns } from "Hooks/useCampaigns";
import { CampaignList, CampaignSetup } from "features/campaigns";
import { useCallback, useState } from "react";

function RezonatePage() {
  const { selectedProject } = useSelectedProject();
  const [open, setOpen] = useState(false);
  const [campaign, setCampaign] = useState<ApiSimpleCampaign>({});
  const { campaigns, loading, refetch } = useCampaigns();

  useMount(() => {
    if (campaigns.length > 0) {
      refetch();
    }
  });

  const newCampaign = useCallback(() => {
    setCampaign({
      type: ApiCampaignType.CAMPAIGN_TYPE_NONE,
    });
    setOpen(true);
  }, []);

  if (loading) {
    return <Loading text="Fetching Rezonate Campaigns" />;
  }

  return (
    <>
      <CampaignList
        project={selectedProject!}
        setOpen={setOpen}
        setCampaign={setCampaign}
        types={[ApiCampaignType.CAMPAIGN_TYPE_NONE]}
        actions={<Button onClick={newCampaign}>New Campaign</Button>}
      />

      <CampaignSetup
        open={open}
        setOpen={setOpen}
        campaign={campaign}
        setCampaign={setCampaign}
      />
    </>
  );
}

export default RezonatePage;
