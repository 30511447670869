import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiOperator,
  ApiYAxisChartType,
} from "@incendium/api";
import { Grid } from "@mui/material";
import { AnalyticsSpacing } from "consts";
import { AnalyticsCard, table5Rows } from "features/analytics";

function StockImpactInsights({
  organicChannelName,
}: {
  organicChannelName: string;
}) {
  return (
    <Grid container spacing={AnalyticsSpacing} mb={4}>
      <Grid item xs={12} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          chart={{
            name: `Out of Stock Pages vs. Organic Traffic`,
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: organicChannelName,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
              },
              {
                key: "r",
                fields: [ApiMetric.METRIC_OUT_OF_STOCK_PAGES_COUNT],
                chart: {
                  [ApiMetric.METRIC_OUT_OF_STOCK_PAGES_COUNT]:
                    ApiYAxisChartType.BAR,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={8} sx={{ minHeight: table5Rows }}>
        <AnalyticsCard
          chart={{
            name: `Out of Stock Pages by Organic Traffic`,
            dimension: [ApiDimension.DIMENSION_PATH],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            comparison: true,
            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: organicChannelName,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                  ApiMetric.METRIC_OUT_OF_STOCK_PAGEVIEW_COUNT,
                  ApiMetric.METRIC_LEADS_COUNT,
                ],
              },
            ],
            having: [
              {
                metric: ApiMetric.METRIC_OUT_OF_STOCK_PAGEVIEW_COUNT,
                operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
                value: 1,
              },
            ],
            displayOptions: {
              noFilters: true,
              availableDimensions: [
                ApiDimension.DIMENSION_PATH,
                ApiDimension.DIMENSION_PRODUCT_NAME,
                ApiDimension.DIMENSION_PRODUCT_CATEGORY,
                ApiDimension.DIMENSION_PRODUCT_BRAND,
              ],
            },
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 500 }}>
        <AnalyticsCard
          chart={{
            name: `Out of Stock Pages by Organic Traffic`,
            dimension: [ApiDimension.DIMENSION_PATH],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: organicChannelName,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_OUT_OF_STOCK_PAGEVIEW_COUNT],
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default StockImpactInsights;
