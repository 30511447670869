import {
  ApiPageviewMetaTagger,
  ApiReservedPageviewMeta,
  ApiReservedPageviewMetaSelectorType,
} from "@incendium/api";
import { Button, MenuItem, Stack, Typography } from "@mui/material";
import { CssSelector } from "features/pageMeta";
import { IPageMetaFormProps } from "features/pageMeta/interfaces";
import { enumToArray, formatEnumVal } from "Helpers/enumToText";
import produce from "immer";
import { useEffect, useState } from "react";
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";

function OrderNumberForm({
  pageviewMetaTaggers,
  onSubmit,
}: IPageMetaFormProps) {
  const [tagger, setTagger] = useState<ApiPageviewMetaTagger>({
    reserved: ApiReservedPageviewMeta.RESERVED_ORDER_ID,
  });

  useEffect(() => {
    if (!pageviewMetaTaggers) {
      return;
    }
    const idx = pageviewMetaTaggers.findIndex(
      (t) => t.reserved === ApiReservedPageviewMeta.RESERVED_ORDER_ID
    );

    if (idx >= 0) {
      setTimeout(() => {
        setTagger(pageviewMetaTaggers[idx]);
      }, 100);
    }
  }, [pageviewMetaTaggers]);

  return (
    <ValidatorForm onSubmit={() => onSubmit(tagger)}>
      <Typography variant="body2" mb={2}>
        Select what method we should use to find the order number.
      </Typography>
      <SelectValidator
        variant="outlined"
        fullWidth
        label="Method"
        name="selectorType"
        value={tagger.selectorType || ""}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setTagger((tagger) =>
            produce(tagger, (draft) => {
              draft.selectorType = e.target
                .value as ApiReservedPageviewMetaSelectorType;
            })
          );
        }}
      >
        {enumToArray(ApiReservedPageviewMetaSelectorType).map((type) => (
          <MenuItem key={type} value={type}>
            {formatEnumVal(type)}
          </MenuItem>
        ))}
      </SelectValidator>
      <Typography variant="body2" mt={2}>
        {tagger.selectorType === ApiReservedPageviewMetaSelectorType.CSS
          ? "Select a CSS selector and we shall take its inner html as this pages order number"
          : tagger.selectorType === ApiReservedPageviewMetaSelectorType.JS
          ? "Enter JS to capture Order Number, This must be a function that returned a string"
          : tagger.selectorType === ApiReservedPageviewMetaSelectorType.PATH
          ? "Use REGEX to extract part of the url, Note we use the 1st capture group"
          : "Add Query string key to save it's value as Order Number"}
      </Typography>

      <Stack spacing={2} mt={3}>
        {tagger.selectorType === ApiReservedPageviewMetaSelectorType.CSS ? (
          <CssSelector
            selector={tagger.selector || ""}
            onChange={(v) => {
              setTagger(
                produce(tagger, (draft) => {
                  draft.selector = v;
                })
              );
            }}
          />
        ) : (
          <TextValidator
            value={tagger.selector || ""}
            variant="outlined"
            name="other"
            label={
              tagger.selectorType === ApiReservedPageviewMetaSelectorType.JS
                ? "JS"
                : tagger.selectorType ===
                  ApiReservedPageviewMetaSelectorType.PATH
                ? "REGEX"
                : "Query String Key"
            }
            fullWidth
            multiline={
              tagger.selectorType === ApiReservedPageviewMetaSelectorType.JS
            }
            rows={5}
            rowsMax={10}
            validators={["required"]}
            errorMessages={["selector is required"]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTagger((tagger) =>
                produce(tagger, (draft) => {
                  draft.selector = e.target.value;
                })
              );
            }}
          />
        )}
        <Button type="submit">Save</Button>
      </Stack>
    </ValidatorForm>
  );
}

export default OrderNumberForm;
