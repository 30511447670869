import { ApiChartAttribute, ApiDimension } from "@incendium/api";
import { TAudienceCfg } from "Interfaces";
import { createContext, useContext, useMemo, useState } from "react";

interface IAudienceAnalyticsContext {
  audienceNames: string[];
  setAudienceNames: (s: string[]) => void;
  audienceCfgs: TAudienceCfg[];
  setAudienceCfgs: (s: TAudienceCfg[]) => void;
  currentAudience: string;
  setCurrentAudience: (a: string) => void;
  audienceAttributes: ApiChartAttribute[];
}

export const AudienceAnalyticsContext =
  createContext<IAudienceAnalyticsContext>({
    audienceNames: [],
    setAudienceNames: () => {},
    audienceCfgs: [],
    setAudienceCfgs: () => {},
    currentAudience: "",
    setCurrentAudience: () => {},
    audienceAttributes: [],
  });

export const useAudienceAnalyticsContext = () =>
  useContext(AudienceAnalyticsContext);

const AudienceAnalyticsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [audienceNames, setAudienceNames] = useState<string[]>([]);
  const [audienceCfgs, setAudienceCfgs] = useState<TAudienceCfg[]>([]);
  const [currentAudience, setCurrentAudience] = useState("");
  const audienceAttributes = useMemo(() => {
    if (audienceNames.length === 0) {
      return [];
    }
    return audienceNames.map((a) => ({
      key: ApiDimension.DIMENSION_LEAD_AUDIENCE,
      value: a,
    }));
  }, [audienceNames]);

  const state = useMemo(
    () => ({
      audienceNames,
      setAudienceNames,
      audienceCfgs,
      setAudienceCfgs,
      currentAudience,
      setCurrentAudience,
      audienceAttributes,
    }),
    [
      audienceNames,
      setAudienceNames,
      audienceCfgs,
      setAudienceCfgs,
      currentAudience,
      setCurrentAudience,
      audienceAttributes,
    ]
  );

  return (
    <AudienceAnalyticsContext.Provider value={state}>
      {children}
    </AudienceAnalyticsContext.Provider>
  );
};

export default AudienceAnalyticsProvider;
