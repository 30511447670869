/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiCalcOverridePreference;
(function (ApiCalcOverridePreference) {
    ApiCalcOverridePreference["CALC_UNSPECIFIED"] = "CALC_UNSPECIFIED";
    ApiCalcOverridePreference["CRON"] = "CRON";
    ApiCalcOverridePreference["MANUAL"] = "MANUAL";
})(ApiCalcOverridePreference || (ApiCalcOverridePreference = {}));
export function ApiCalcOverridePreferenceFromJSON(json) {
    return ApiCalcOverridePreferenceFromJSONTyped(json, false);
}
export function ApiCalcOverridePreferenceFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiCalcOverridePreferenceToJSON(value) {
    return value;
}
