import { Portal, Stack, Typography } from "@mui/material";
import AudienceAnalyticsProvider, {
  useAudienceAnalyticsContext,
} from "Providers/AudienceAnalyticsProvider"; // useAudienceAnalyticsContext,
import { TabWrapper } from "Components/UI/TabContainer";
import { Redirect, useParams } from "react-router-dom";
import { useCallback, useState } from "react";
import { CenterPage } from "Components/CenterPage/CenterPage";
import { enumToArray } from "Helpers/enumToText";
import TabbedAnalysesPage from "Pages/Analyse/TabbedAnalysesPage";
import AudienceUserJourneyReportPage from "./AudienceUserJourneyReportPage";
import { ApiDimension } from "@incendium/api";
import { useSelectedProject } from "Hooks";
import Loading from "Components/Loading/Loading";
import {
  AnalyticsFilterDropdown,
  useFilterableDimensions,
} from "features/analytics";
import { PageSubAction } from "consts";

enum Insight {
  overview = "Overview",
  new_vs_returning = "New vs. Returning",
  country_region = "Country - Region",
  device = "Device",
  channels = "Channels",
  campaign = "Campaign",
}

function AudienceUserJourneyPageInner() {
  const { setCurrentAudience } = useAudienceAnalyticsContext();
  const { insight } = useParams<{ insight: string }>();
  const [activeInsight, setActiveInsight] = useState(insight);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [device, setDevice] = useState<string>("");
  const [selectedChannel, setSelectedChannel] = useState<string>();
  const [userType, setUserType] = useState<string>("New Lead");
  const [campaign, setCampaign] = useState<string>("");
  const { selectedProject } = useSelectedProject();
  const { loading } = useFilterableDimensions();

  const renderSwitch = useCallback(() => {
    if (!selectedProject || loading) {
      return <Loading />;
    }
    switch (activeInsight) {
      case Insight.overview.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <AudienceUserJourneyReportPage
              dimension={ApiDimension.DIMENSION_NOT_SET}
            />
          </TabWrapper>
        );
      case Insight.new_vs_returning.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <AudienceUserJourneyReportPage
              userType={userType}
              dimension={ApiDimension.DIMENSION_USER_TYPE}
            />
          </TabWrapper>
        );
      case Insight.country_region.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <AudienceUserJourneyReportPage
              countryName={selectedCountry}
              dimension={ApiDimension.DIMENSION_COUNTRY}
            />
          </TabWrapper>
        );
      case Insight.device.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <AudienceUserJourneyReportPage
              device={device}
              dimension={ApiDimension.DIMENSION_DEVICE}
            />
          </TabWrapper>
        );
      case Insight.channels.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <AudienceUserJourneyReportPage
              channelName={selectedChannel}
              dimension={ApiDimension.DIMENSION_CHANNEL}
            />
          </TabWrapper>
        );
      case Insight.campaign.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <AudienceUserJourneyReportPage
              campaign={campaign}
              dimension={ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN}
            />
          </TabWrapper>
        );
      default:
        return (
          <TabWrapper>
            <CenterPage calcHeight>
              <Typography variant="subtitle1">Not Implemented</Typography>
            </CenterPage>
          </TabWrapper>
        );
    }
  }, [
    activeInsight,
    selectedCountry,
    device,
    selectedChannel,
    userType,
    selectedProject,
    campaign,
    loading,
  ]);

  const portalRender = useCallback(() => {
    if (!selectedProject) {
      return <></>;
    }

    switch (activeInsight) {
      case Insight.new_vs_returning.toLowerCase().replace(" ", "_"):
        return (
          <>
            <AnalyticsFilterDropdown
              dimension={ApiDimension.DIMENSION_USER_TYPE}
              defaultIndex={0}
              maxWidth={250}
              onChange={(v) => {
                setUserType(v);
              }}
            />
          </>
        );
      case Insight.country_region.toLowerCase().replace(" ", "_"):
        return (
          <AnalyticsFilterDropdown
            dimension={ApiDimension.DIMENSION_COUNTRY}
            defaultIndex={0}
            maxWidth={250}
            onChange={(v) => {
              setSelectedCountry(v);
            }}
          />
        );
      case Insight.device.toLowerCase().replace(" ", "_"):
        return (
          <AnalyticsFilterDropdown
            dimension={ApiDimension.DIMENSION_DEVICE}
            defaultIndex={0}
            maxWidth={250}
            onChange={(v) => {
              setDevice(v);
            }}
          />
        );
      case Insight.channels.toLowerCase().replace(" ", "_"):
        return (
          <AnalyticsFilterDropdown
            dimension={ApiDimension.DIMENSION_CHANNEL}
            defaultIndex={0}
            maxWidth={250}
            onChange={(v) => {
              setSelectedChannel(v);
            }}
          />
        );
      case Insight.campaign.toLowerCase().replace(" ", "_"):
        return (
          <AnalyticsFilterDropdown
            dimension={ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN}
            defaultIndex={0}
            maxWidth={250}
            onChange={(v) => {
              setCampaign(v);
            }}
          />
        );
    }
  }, [activeInsight, selectedProject]);

  if (!insight || insight === "") {
    return (
      <Redirect
        to={`./audience-insights-user-journey/${Insight.overview
          .toLowerCase()
          .replace(" ", "_")}`}
      />
    );
  }

  return (
    <>
      <Portal container={() => document.getElementById(PageSubAction)}>
        <Stack direction={"row"}>
          {portalRender()}
          <AnalyticsFilterDropdown
            dimension={ApiDimension.DIMENSION_LEAD_AUDIENCE}
            defaultIndex={-1}
            maxWidth={250}
            onChange={(v) => {
              setCurrentAudience(v);
            }}
          />
        </Stack>
      </Portal>
      <TabbedAnalysesPage
        title="User Journey"
        activeTab={activeInsight}
        setActiveTab={setActiveInsight}
        tabs={enumToArray(Insight)}
        renderSwitch={renderSwitch}
      />
    </>
  );
}

function AudienceUserJourneyPage() {
  return (
    <AudienceAnalyticsProvider>
      <AudienceUserJourneyPageInner />
    </AudienceAnalyticsProvider>
  );
}

export default AudienceUserJourneyPage;
