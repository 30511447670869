import { IChart } from "Interfaces";
import { useState } from "react";
import { useUpdateEffect } from "react-use";

const useChartTypeTransition = (chart: IChart) => {
  const [transitioning, setTransitioning] = useState(false);
  useUpdateEffect(() => {
    setTransitioning(true);
    setTimeout(() => {
      setTransitioning(false);
    }, 500);
  }, [chart.type]);

  return transitioning;
};

export default useChartTypeTransition;
