import { Stack, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { IncendiumTooltip } from "Components/IncendiumTooltip";
import { clearSelection, getSelectedText, removeTrailingSlash } from "Helpers";
import { useMemo } from "react";
import { useState } from "react";

const useTextHighlightStyles = makeStyles((theme: Theme) => {
  return {
    disable: {
      pointerEvents: "none",
      userSelect: "none",
      fontSize: 16,
    },
    text: {
      fontSize: 16,
      "&::selection": {
        backgroundColor: "#F5CE9D",
      },
    },
  };
});

export const TextHighlight = ({
  options,
  text,
  onSelect,
}: {
  text: string;
  options: string[];
  onSelect: (arg: { text: string; option: string }) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [selectedText, setSelectedText] = useState("");
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedText("");
    clearSelection();
  };

  const classes = useTextHighlightStyles();
  const handleMouseUp = () => {
    const selection = window.getSelection();

    // Resets when the selection has a length of 0
    if (!selection || selection.anchorOffset === selection.focusOffset) {
      handleClose();
      return;
    }

    const getBoundingClientRect = () =>
      selection.getRangeAt(0).getBoundingClientRect();
    const rect = getBoundingClientRect().toJSON();

    rect.width += 10;
    rect.right += 10;

    setAnchorEl({
      clientWidth: rect.width,
      clientHeight: rect.height,
      getBoundingClientRect: () => rect,
    });
    setSelectedText(getSelectedText());
  };

  const url = useMemo(() => {
    try {
      const url = new window.URL(text);
      return url;
    } catch (error) {
      console.error(error);
    }
  }, [text]);

  return (
    <>
      <Stack direction="row" justifyContent={"center"}>
        <Typography display="inline" className={classes.disable}>
          {removeTrailingSlash(url?.origin || "")}
        </Typography>
        <Typography
          display="inline"
          className={classes.text}
          onMouseUp={handleMouseUp}
          color="primary"
        >
          {url?.pathname}
        </Typography>
      </Stack>
      <IncendiumTooltip
        options={options}
        onClose={handleClose}
        anchorEl={anchorEl}
        onSelect={(option) => {
          onSelect({ text: selectedText, option });
          handleClose();
        }}
      />
    </>
  );
};
