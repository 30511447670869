/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiReservedLocationPageTagger;
(function (ApiReservedLocationPageTagger) {
    ApiReservedLocationPageTagger["TAGGER_NOT_RESERVED"] = "TAGGER_NOT_RESERVED";
    ApiReservedLocationPageTagger["NAME"] = "NAME";
    ApiReservedLocationPageTagger["DESCRIPTION"] = "DESCRIPTION";
})(ApiReservedLocationPageTagger || (ApiReservedLocationPageTagger = {}));
export function ApiReservedLocationPageTaggerFromJSON(json) {
    return ApiReservedLocationPageTaggerFromJSONTyped(json, false);
}
export function ApiReservedLocationPageTaggerFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiReservedLocationPageTaggerToJSON(value) {
    return value;
}
