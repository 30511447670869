import { ApiSimpleCampaign, ApiSplitCampaignUrl } from "@incendium/api";
import { Autocomplete, TextField } from "@mui/material";
import { useCallback, useEffect } from "react";
import AnimatedList from "Components/AnimatedList/AnimatedList";
import produce from "immer";
import { useLanders } from "Hooks/useLanders";

function CampaignSplitUrlsRow({
  row,
  onEdit,
}: {
  row: ApiSplitCampaignUrl;
  onEdit: (v: ApiSplitCampaignUrl) => void;
}) {
  const { landers, loading } = useLanders();

  const handleChange = useCallback(
    (value: string) => {
      onEdit(
        produce(row, (draft) => {
          draft.url = value;
        })
      );
    },
    [onEdit, row]
  );

  return (
    <Autocomplete
      fullWidth
      freeSolo
      size="small"
      loading={loading}
      onChange={(e, value) => handleChange(value || "")}
      value={row.url}
      options={landers.map((l) => l.url)}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          name="p"
          size="small"
          label="Type URL"
          onChange={(e: any) => {
            handleChange(e.target.value);
          }}
        />
      )}
    />
  );
}

interface ICampaignSplitUrlsProps {
  campaign: ApiSimpleCampaign;
  setCampaign: (c: ApiSimpleCampaign) => void;
}

function CampaignSplitUrls({ campaign, setCampaign }: ICampaignSplitUrlsProps) {
  useEffect(() => {
    if (!campaign.urls) {
      setCampaign(
        produce(campaign, (draft) => {
          draft.urls = [
            {
              name: `${1 * Math.random()}`,
            },
          ];
        })
      );
    }
  }, [campaign, setCampaign]);

  const onAdd = useCallback(() => {
    setCampaign(
      produce(campaign, (draft) => {
        if (!draft.urls) {
          draft.urls = [];
        }
        draft.urls.push({
          name: `${draft.urls.length + 1 * Math.random()}`,
        });
      })
    );
  }, [campaign, setCampaign]);

  const onRemove = useCallback(
    (idx: number) => {
      setCampaign(
        produce(campaign, (draft) => {
          draft.urls?.splice(idx, 1);
        })
      );
    },
    [campaign, setCampaign]
  );
  const onEdit = useCallback(
    (idx: number) => (value: any) => {
      setCampaign(
        produce(campaign, (draft) => {
          if (!draft.urls) {
            draft.urls = [];
          }
          draft.urls[idx] = value;
        })
      );
    },
    [campaign, setCampaign]
  );

  return (
    <>
      <AnimatedList
        onAdd={onAdd}
        onRemove={onRemove}
        onEdit={onEdit}
        items={(campaign.urls || []) as any}
        uniqueProp={"name"}
        RowComponent={CampaignSplitUrlsRow}
      />
    </>
  );
}

export default CampaignSplitUrls;
