import { personalisationService } from "Apis";
import { useSharedState } from "./useSharedState";
import { ApiProject, ApiSimpleComponent } from "@incendium/api";

const fetchComponents = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const res =
      await personalisationService.personalisationServiceListComponents({
        projectId: selectedProject.id as number,
      });
    return res.components || [];
  }
  return [];
};
export const useComponents = () => {
  const { state, setState, loading } = useSharedState<
    ApiSimpleComponent,
    ApiSimpleComponent[]
  >("components", [], fetchComponents);

  return {
    components: state,
    setComponents: setState,
    loading,
  };
};
