import { ApiACLRole, ApiClient, ApiProject, ApiUser } from "@incendium/api";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  capitalize,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { initializeClientsThunk } from "Actions";
import AccessLevel from "Components/AccessLevel/AccessLevel";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { useUser } from "Hooks/useUser";
import produce from "immer";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clientListSelector } from "Selectors/clientListSelector";

interface IUserRolesProps {
  user: ApiUser;
  setUser: React.Dispatch<React.SetStateAction<ApiUser>>;
}

interface IFieldsProps {
  toggleAdmin: () => void;
  toggleObs: () => void;
  adminActive: boolean;
  obsActive: boolean;
  type: "client" | "project" | "analytics";
}
interface IRowProps extends React.HTMLAttributes<HTMLDivElement>, IFieldsProps {
  text: string;
}

const Fields = ({
  toggleAdmin,
  toggleObs,
  adminActive,
  obsActive,
  type,
}: IFieldsProps) => {
  return (
    <Stack direction="row" alignItems={"center"} justifyContent={"flex-end"}>
      <FormControlLabel
        sx={{ minWidth: 160 }}
        control={
          <Checkbox
            size="small"
            sx={{ pointerEvents: "none" }}
            onChange={(e) => {
              e.stopPropagation();
              toggleAdmin();
            }}
            checked={adminActive}
          />
        }
        label={capitalize(`${type} Admin`)}
      />
      <FormControlLabel
        sx={{ minWidth: 160 }}
        control={
          <Checkbox
            size="small"
            sx={{ pointerEvents: "none" }}
            onChange={(e) => {
              e.stopPropagation();
              toggleObs();
            }}
            checked={obsActive}
          />
        }
        label={capitalize(`${type} Observer`)}
      />
    </Stack>
  );
};

const Row = ({
  text,
  toggleAdmin,
  toggleObs,
  adminActive,
  obsActive,
  children,
  type,
}: IRowProps) => {
  return (
    <Stack
      sx={{ width: "100%" }}
      direction="row"
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Typography color={"secondary"}>{text}</Typography>

      <Stack direction="row" alignItems={"center"}>
        <Box mr={2}>{children}</Box>
        <Fields
          toggleAdmin={toggleAdmin}
          toggleObs={toggleObs}
          adminActive={adminActive}
          obsActive={obsActive}
          type={type}
        />
      </Stack>
    </Stack>
  );
};

interface IPRowProps extends IUserRolesProps {
  project: ApiProject;
  isClientAdmin: boolean;
  isClientObs: boolean;
}
const ProjectRow = ({
  project,
  user,
  setUser,
  isClientAdmin,
  isClientObs,
}: IPRowProps) => {
  const toggleAdmin = () => {
    toggleRole(ApiACLRole.PROJECT_ADMIN);
  };
  const toggleObs = () => {
    toggleRole(ApiACLRole.PROJECT_OBSERVER);
  };
  const toggleAAdmin = () => {
    toggleRole(ApiACLRole.ANALYTICS_ADMIN);
  };
  const toggleAObs = () => {
    toggleRole(ApiACLRole.ANALYTICS_OBSERVER);
  };
  const toggleRole = (role: ApiACLRole) => {
    setUser(
      produce(user, (draft) => {
        const idx = (draft.permissions || []).findIndex(
          (p) => p.role === role && p.projectId === project.id
        );

        if (draft.permissions && idx >= 0) {
          draft.permissions.splice(idx, 1);
        } else {
          if (!draft.permissions) {
            draft.permissions = [];
          }
          draft.permissions.push({
            role,
            projectId: project.id,
          });
        }
      })
    );
  };

  const adminActive = useMemo(() => {
    const idx = (user.permissions || []).findIndex(
      (p) => p.role === ApiACLRole.PROJECT_ADMIN && p.projectId === project.id
    );
    return idx >= 0;
  }, [user, project]);

  const obsActive = useMemo(() => {
    const idx = (user.permissions || []).findIndex(
      (p) =>
        p.role === ApiACLRole.PROJECT_OBSERVER && p.projectId === project.id
    );
    return idx >= 0;
  }, [user, project]);

  const adminAActive = useMemo(() => {
    const idx = (user.permissions || []).findIndex(
      (p) => p.role === ApiACLRole.ANALYTICS_ADMIN && p.projectId === project.id
    );
    return idx >= 0;
  }, [user, project]);

  const obsAActive = useMemo(() => {
    const idx = (user.permissions || []).findIndex(
      (p) =>
        p.role === ApiACLRole.ANALYTICS_OBSERVER && p.projectId === project.id
    );
    return idx >= 0;
  }, [user, project]);

  return (
    <Stack alignItems={"flex-end"} pr={3}>
      <Row
        text={project.name || ""}
        toggleAdmin={toggleAdmin}
        toggleObs={toggleObs}
        adminActive={adminActive}
        obsActive={obsActive}
        type="project"
      >
        {isClientAdmin && !adminActive && !obsActive && (
          <Typography textAlign={"right"} variant="caption" color="secondary">
            (Admin Inherited)
          </Typography>
        )}
        {isClientObs && !isClientAdmin && !adminActive && !obsActive && (
          <Typography textAlign={"right"} variant="caption" color="secondary">
            (Observer Inherited)
          </Typography>
        )}
      </Row>
      <Fields
        toggleAdmin={toggleAAdmin}
        toggleObs={toggleAObs}
        adminActive={adminAActive}
        obsActive={obsAActive}
        type={"analytics"}
      />
    </Stack>
  );
};

interface ICRowProps extends IUserRolesProps {
  client: ApiClient;
}
const ClientRow = ({ client, user, setUser }: ICRowProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const toggleAdmin = () => {
    toggleRole(ApiACLRole.CLIENT_ADMIN);
  };
  const toggleObs = () => {
    toggleRole(ApiACLRole.CLIENT_OBSERVER);
  };
  const toggleRole = (role: ApiACLRole) => {
    setUser(
      produce(user, (draft) => {
        const idx = (draft.permissions || []).findIndex(
          (p) => p.role === role && p.clientId === client.id
        );

        if (draft.permissions && idx >= 0) {
          draft.permissions.splice(idx, 1);
        } else {
          if (!draft.permissions) {
            draft.permissions = [];
          }
          draft.permissions.push({
            role,
            clientId: client.id,
          });
        }
      })
    );
  };

  const adminActive = useMemo(() => {
    const idx = (user.permissions || []).findIndex(
      (p) => p.role === ApiACLRole.CLIENT_ADMIN && p.clientId === client.id
    );
    return idx >= 0;
  }, [user, client]);

  const obsActive = useMemo(() => {
    const idx = (user.permissions || []).findIndex(
      (p) => p.role === ApiACLRole.CLIENT_OBSERVER && p.clientId === client.id
    );
    return idx >= 0;
  }, [user, client]);

  const projectsWithRoles = useMemo(() => {
    const projectIds = (client.projects || []).map((p) => p.id);
    return (user.permissions || []).filter((p) =>
      projectIds.includes(p.projectId)
    ).length;
  }, [user, client]);

  return (
    <Accordion
      sx={{ background: "transparent" }}
      elevation={0}
      disableGutters
      expanded={open}
      id={`client-row-${client.id}`}
      onChange={() => setOpen(!open)}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Row
          text={client.businessName || ""}
          toggleAdmin={toggleAdmin}
          toggleObs={toggleObs}
          adminActive={adminActive}
          obsActive={obsActive}
          type="client"
        >
          {projectsWithRoles > 0 && (
            <Typography variant="caption" color={"secondary"}>
              {projectsWithRoles} project(s) roles set.
            </Typography>
          )}
        </Row>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          background: theme.palette.background.default,
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Stack>
          {(client.projects || []).map((p) => (
            <ProjectRow
              key={`${client.id}-${p.id}`}
              project={p}
              user={user}
              setUser={setUser}
              isClientAdmin={adminActive}
              isClientObs={obsActive}
            />
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

function UserRoles({ user, setUser }: IUserRolesProps) {
  const state = useSelector(clientListSelector);
  const { hasPermission } = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeClientsThunk());
  }, []);

  const isSuper = useMemo(
    () =>
      (user.permissions || []).findIndex(
        (p) => p.role === ApiACLRole.SUPER_ADMIN
      ) >= 0,
    [user]
  );
  const isDom = useMemo(
    () =>
      (user.permissions || []).findIndex(
        (p) => p.role === ApiACLRole.DOMAIN_ADMIN
      ) >= 0,
    [user]
  );

  const clients = useMemo(() => {
    return state.list
      .map((c) => ({
        ...c,
        projects: (c.projects || []).filter((p) =>
          hasPermission(ApiACLRole.PROJECT_OBSERVER, undefined, c, p)
        ),
      }))
      .filter(
        (c) =>
          hasPermission(ApiACLRole.CLIENT_OBSERVER, undefined, c) ||
          c.projects.length > 0
      );
  }, [state, hasPermission]);

  if (!state.hasLoaded) {
    return <CircularProgress />;
  }

  return (
    <Stack spacing={1} alignItems="flex-end">
      <Stack p={2} spacing={1} alignItems="flex-end">
        <AccessLevel role={ApiACLRole.SUPER_ADMIN}>
          <StyledFlexBox sx={{ width: 200 }}>
            <Switch
              checked={isSuper}
              onChange={(e, checked) =>
                setUser(
                  produce(user, (draft) => {
                    const idx = (draft.permissions || []).findIndex(
                      (p) => p.role === ApiACLRole.SUPER_ADMIN
                    );
                    if (idx >= 0) {
                      draft.permissions?.splice(idx, 1);
                    } else {
                      draft.permissions = [
                        ...(draft.permissions || []),
                        {
                          role: ApiACLRole.SUPER_ADMIN,
                        },
                      ];
                    }
                  })
                )
              }
            />
            <Typography color={isSuper ? "primary" : "secondary"}>
              Is Super Admin
            </Typography>
          </StyledFlexBox>
        </AccessLevel>

        <AccessLevel role={ApiACLRole.DOMAIN_ADMIN}>
          <StyledFlexBox sx={{ width: 200 }}>
            <Switch
              checked={isDom}
              onChange={(e, checked) =>
                setUser(
                  produce(user, (draft) => {
                    const idx = (draft.permissions || []).findIndex(
                      (p) => p.role === ApiACLRole.DOMAIN_ADMIN
                    );
                    if (idx >= 0) {
                      draft.permissions?.splice(idx, 1);
                    } else {
                      draft.permissions = [
                        ...(draft.permissions || []),
                        {
                          role: ApiACLRole.DOMAIN_ADMIN,
                        },
                      ];
                    }
                  })
                )
              }
            />
            <Typography color={isSuper ? "primary" : "secondary"}>
              Is Domain Admin
            </Typography>
          </StyledFlexBox>
        </AccessLevel>
      </Stack>
      <Box sx={{ width: "100%" }}>
        {clients.map((client) => (
          <ClientRow
            client={client}
            key={client.id}
            user={user}
            setUser={setUser}
          />
        ))}
      </Box>
    </Stack>
  );
}

export default UserRoles;
