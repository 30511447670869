/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiGetPresignedURLPayloadToJSON, ApiGetPresignedURLReponseFromJSON, ApiListMediaResponseFromJSON, ApiMediaFromJSON, ApiMediaCSVFromJSON, ApiMediaCSVToJSON, ApiMediaPayloadToJSON, } from '../models';
/**
 *
 */
export class MediaServiceApi extends runtime.BaseAPI {
    /**
     */
    mediaServiceCreateMediaRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling mediaServiceCreateMedia.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling mediaServiceCreateMedia.');
            }
            const queryParameters = {};
            if (requestParameters.mediaId !== undefined) {
                queryParameters['mediaId'] = requestParameters.mediaId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/media/v1/projects/{projectId}/media`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiMediaPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiMediaFromJSON(jsonValue));
        });
    }
    /**
     */
    mediaServiceCreateMedia(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.mediaServiceCreateMediaRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    mediaServiceDeleteMediaRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling mediaServiceDeleteMedia.');
            }
            if (requestParameters.mediaId === null || requestParameters.mediaId === undefined) {
                throw new runtime.RequiredError('mediaId', 'Required parameter requestParameters.mediaId was null or undefined when calling mediaServiceDeleteMedia.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/media/v1/projects/{projectId}/media/{mediaId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"mediaId"}}`, encodeURIComponent(String(requestParameters.mediaId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    mediaServiceDeleteMedia(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.mediaServiceDeleteMediaRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    mediaServiceGetCsvPreviewRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling mediaServiceGetCsvPreview.');
            }
            if (requestParameters.mediaId === null || requestParameters.mediaId === undefined) {
                throw new runtime.RequiredError('mediaId', 'Required parameter requestParameters.mediaId was null or undefined when calling mediaServiceGetCsvPreview.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/media/v1/projects/{projectId}/media/{mediaId}/action:csv-preview`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"mediaId"}}`, encodeURIComponent(String(requestParameters.mediaId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiMediaCSVFromJSON(jsonValue));
        });
    }
    /**
     */
    mediaServiceGetCsvPreview(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.mediaServiceGetCsvPreviewRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    mediaServiceGetPresignedURLRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling mediaServiceGetPresignedURL.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling mediaServiceGetPresignedURL.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/media/v1/projects/{projectId}/presigned`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiGetPresignedURLPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiGetPresignedURLReponseFromJSON(jsonValue));
        });
    }
    /**
     */
    mediaServiceGetPresignedURL(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.mediaServiceGetPresignedURLRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    mediaServiceListMediaRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling mediaServiceListMedia.');
            }
            const queryParameters = {};
            if (requestParameters.mediaId !== undefined) {
                queryParameters['mediaId'] = requestParameters.mediaId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/media/v1/projects/{projectId}/media`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListMediaResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    mediaServiceListMedia(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.mediaServiceListMediaRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    mediaServiceMediaFilePresignedURLRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling mediaServiceMediaFilePresignedURL.');
            }
            if (requestParameters.mediaFileId === null || requestParameters.mediaFileId === undefined) {
                throw new runtime.RequiredError('mediaFileId', 'Required parameter requestParameters.mediaFileId was null or undefined when calling mediaServiceMediaFilePresignedURL.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/media/v1/projects/{projectId}/presigned/{mediaFileId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"mediaFileId"}}`, encodeURIComponent(String(requestParameters.mediaFileId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiGetPresignedURLReponseFromJSON(jsonValue));
        });
    }
    /**
     */
    mediaServiceMediaFilePresignedURL(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.mediaServiceMediaFilePresignedURLRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    mediaServiceReadMediaRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling mediaServiceReadMedia.');
            }
            if (requestParameters.mediaId === null || requestParameters.mediaId === undefined) {
                throw new runtime.RequiredError('mediaId', 'Required parameter requestParameters.mediaId was null or undefined when calling mediaServiceReadMedia.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/media/v1/projects/{projectId}/media/{mediaId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"mediaId"}}`, encodeURIComponent(String(requestParameters.mediaId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiMediaFromJSON(jsonValue));
        });
    }
    /**
     */
    mediaServiceReadMedia(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.mediaServiceReadMediaRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    mediaServiceSaveCSVMappingRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling mediaServiceSaveCSVMapping.');
            }
            if (requestParameters.mediaId === null || requestParameters.mediaId === undefined) {
                throw new runtime.RequiredError('mediaId', 'Required parameter requestParameters.mediaId was null or undefined when calling mediaServiceSaveCSVMapping.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling mediaServiceSaveCSVMapping.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/media/v1/projects/{projectId}/media/{mediaId}/action:csv-save-mapping`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"mediaId"}}`, encodeURIComponent(String(requestParameters.mediaId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiMediaCSVToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiMediaCSVFromJSON(jsonValue));
        });
    }
    /**
     */
    mediaServiceSaveCSVMapping(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.mediaServiceSaveCSVMappingRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    mediaServiceUpdateMediaRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling mediaServiceUpdateMedia.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling mediaServiceUpdateMedia.');
            }
            const queryParameters = {};
            if (requestParameters.mediaId !== undefined) {
                queryParameters['mediaId'] = requestParameters.mediaId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/media/v1/projects/{projectId}/media`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiMediaPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiMediaFromJSON(jsonValue));
        });
    }
    /**
     */
    mediaServiceUpdateMedia(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.mediaServiceUpdateMediaRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    mediaServiceValidateCSVMappingRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling mediaServiceValidateCSVMapping.');
            }
            if (requestParameters.mediaId === null || requestParameters.mediaId === undefined) {
                throw new runtime.RequiredError('mediaId', 'Required parameter requestParameters.mediaId was null or undefined when calling mediaServiceValidateCSVMapping.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling mediaServiceValidateCSVMapping.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/media/v1/projects/{projectId}/media/{mediaId}/action:csv-validate-mapping`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"mediaId"}}`, encodeURIComponent(String(requestParameters.mediaId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiMediaCSVToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiMediaCSVFromJSON(jsonValue));
        });
    }
    /**
     */
    mediaServiceValidateCSVMapping(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.mediaServiceValidateCSVMappingRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
