import { landersService } from "Apis";
import { useSharedState } from "./useSharedState";
import { ApiProject, ApiReadLanderResponse } from "@incendium/api";

const fetchLanders = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const res = await landersService.landersServiceListLanders({
      projectId: selectedProject.id as number,
    });
    return res.results || [];
  }
  return [];
};
export const useLanders = () => {
  const { state, setState, loading, refetch } = useSharedState<
    ApiReadLanderResponse,
    ApiReadLanderResponse[]
  >("landers", [], fetchLanders);

  return {
    landers: state,
    setLanders: setState,
    loading,
    refetch,
  };
};
