/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiCampaignType;
(function (ApiCampaignType) {
    ApiCampaignType["CAMPAIGN_TYPE_NONE"] = "CampaignTypeNone";
    ApiCampaignType["CAMPAIGN_TYPE_AB"] = "CampaignTypeAB";
    ApiCampaignType["CAMPAIGN_TYPE_SPLIT"] = "CampaignTypeSplit";
})(ApiCampaignType || (ApiCampaignType = {}));
export function ApiCampaignTypeFromJSON(json) {
    return ApiCampaignTypeFromJSONTyped(json, false);
}
export function ApiCampaignTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiCampaignTypeToJSON(value) {
    return value;
}
