import { ApiLocation } from "@incendium/api";
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import { useLocations } from "Hooks";
import produce from "immer";
import { GenericDialoagProps } from "Interfaces";
import { useCallback, useEffect, useState } from "react";

interface IMultipleLocationSelectorProps extends GenericDialoagProps {
  locations: ApiLocation[];
  setLocations: React.Dispatch<React.SetStateAction<ApiLocation[]>>;
}

function MultipleLocationSelector({
  open,
  setOpen,
  locations,
  setLocations,
}: IMultipleLocationSelectorProps) {
  const [internalLocations, setInternalLocations] = useState(locations);
  const { locations: fetchedLocations } = useLocations();

  useEffect(() => {
    if (!open) {
      setInternalLocations([]);
      return;
    }
    setInternalLocations(locations);
  }, [open, locations]);

  const onCancel = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onSubmit = useCallback(() => {
    setLocations([...internalLocations]);
    setOpen(false);
  }, [internalLocations, setLocations, setOpen]);

  const handleClick = useCallback((location) => {
    setInternalLocations((internalLocations) =>
      produce(internalLocations, (draft) => {
        const idx = internalLocations.findIndex((l) => l.id === location.id);
        if (idx > -1) {
          draft.splice(idx, 1);
        } else {
          draft.push(location);
        }
      })
    );
  }, []);

  return (
    <CustomDialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>Select locations</DialogTitle>

      <DialogContent>
        <Grid container>
          {fetchedLocations.map((location) => (
            <Grid key={location.id} item xs={6}>
              <ListItem key={location.id} disableGutters disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={() => handleClick(location)}
                >
                  <Checkbox
                    edge="start"
                    checked={
                      internalLocations.findIndex((l) => l.id === location.id) >
                      -1
                    }
                    tabIndex={-1}
                    disableRipple
                  />
                  <ListItemText primary={location.name} />
                </ListItemButton>
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          Set Locations
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}

export default MultipleLocationSelector;
