/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiCSVRowsFromJSON, ApiCSVRowsToJSON, } from './ApiCSVRows';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiCSVUploadSessionFromJSON(json) {
    return ApiCSVUploadSessionFromJSONTyped(json, false);
}
export function ApiCSVUploadSessionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'csvRule': !exists(json, 'csvRule') ? undefined : json['csvRule'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'rows': !exists(json, 'rows') ? undefined : (json['rows'].map(ApiCSVRowsFromJSON)),
        'key': !exists(json, 'key') ? undefined : json['key'],
    };
}
export function ApiCSVUploadSessionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'csvRule': value.csvRule,
        'project': ApiProjectToJSON(value.project),
        'projectId': value.projectId,
        'rows': value.rows === undefined ? undefined : (value.rows.map(ApiCSVRowsToJSON)),
        'key': value.key,
    };
}
