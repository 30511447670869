/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiAlertFromJSON, ApiAlertPayloadToJSON, ApiListAlertsResponseFromJSON, } from '../models';
/**
 *
 */
export class AlertServiceApi extends runtime.BaseAPI {
    /**
     */
    alertServiceCreateAlertRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling alertServiceCreateAlert.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling alertServiceCreateAlert.');
            }
            const queryParameters = {};
            if (requestParameters.alertId !== undefined) {
                queryParameters['alertId'] = requestParameters.alertId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/alert/v1/projects/{projectId}/alerts`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiAlertPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiAlertFromJSON(jsonValue));
        });
    }
    /**
     */
    alertServiceCreateAlert(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.alertServiceCreateAlertRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    alertServiceDeleteAlertRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling alertServiceDeleteAlert.');
            }
            if (requestParameters.alertId === null || requestParameters.alertId === undefined) {
                throw new runtime.RequiredError('alertId', 'Required parameter requestParameters.alertId was null or undefined when calling alertServiceDeleteAlert.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadEmail !== undefined) {
                queryParameters['payload.email'] = requestParameters.payloadEmail;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/alert/v1/projects/{projectId}/alerts/{alertId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"alertId"}}`, encodeURIComponent(String(requestParameters.alertId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    alertServiceDeleteAlert(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.alertServiceDeleteAlertRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    alertServiceListAlertsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling alertServiceListAlerts.');
            }
            const queryParameters = {};
            if (requestParameters.alertId !== undefined) {
                queryParameters['alertId'] = requestParameters.alertId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadEmail !== undefined) {
                queryParameters['payload.email'] = requestParameters.payloadEmail;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/alert/v1/projects/{projectId}/alerts`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListAlertsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    alertServiceListAlerts(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.alertServiceListAlertsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    alertServiceReadAlertRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling alertServiceReadAlert.');
            }
            if (requestParameters.alertId === null || requestParameters.alertId === undefined) {
                throw new runtime.RequiredError('alertId', 'Required parameter requestParameters.alertId was null or undefined when calling alertServiceReadAlert.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadEmail !== undefined) {
                queryParameters['payload.email'] = requestParameters.payloadEmail;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/alert/v1/projects/{projectId}/alerts/{alertId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"alertId"}}`, encodeURIComponent(String(requestParameters.alertId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiAlertFromJSON(jsonValue));
        });
    }
    /**
     */
    alertServiceReadAlert(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.alertServiceReadAlertRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    alertServiceUpdateAlertRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling alertServiceUpdateAlert.');
            }
            if (requestParameters.alertId === null || requestParameters.alertId === undefined) {
                throw new runtime.RequiredError('alertId', 'Required parameter requestParameters.alertId was null or undefined when calling alertServiceUpdateAlert.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling alertServiceUpdateAlert.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/alert/v1/projects/{projectId}/alerts/{alertId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"alertId"}}`, encodeURIComponent(String(requestParameters.alertId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiAlertPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiAlertFromJSON(jsonValue));
        });
    }
    /**
     */
    alertServiceUpdateAlert(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.alertServiceUpdateAlertRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
