export function mergeMapsSortByKey<T, U>(
  map1: Map<number, T>,
  map2: Map<number, U>
): Map<number, T | U> {
  const mergedMap = new Map<number, T | U>();

  // Iterate over keys in asc order
  const allKeys = Array.from(new Set([...map1.keys(), ...map2.keys()])).sort(
    (a, b) => a - b
  );
  allKeys.forEach((key) => {
    if (map1.has(key)) {
      mergedMap.set(key, map1.get(key)!);
    }
    if (map2.has(key)) {
      mergedMap.set(key, map2.get(key)!);
    }
  });

  return mergedMap;
}

export function sortMapByKey<K, V>(map: Map<K, V>): Map<K, V> {
  const sortedEntries = Array.from(map.entries()).sort(([key1], [key2]) => {
    if (key1 === key2) {
      return 0;
    } else if (key1 < key2) {
      return -1;
    } else {
      return 1;
    }
  });

  return new Map<K, V>(sortedEntries);
}

export function uniqueValues<K, V>(map: Map<K, V>): V[] {
  const uniqueValuesSet = new Set<V>();
  map.forEach((value) => {
    uniqueValuesSet.add(value);
  });

  return Array.from(uniqueValuesSet);
}
