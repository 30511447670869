/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiServiceFromJSON, ApiServiceToJSON, } from './ApiService';
import { ApiSyncFromJSON, ApiSyncToJSON, } from './ApiSync';
import { ApiSyncTypeFromJSON, ApiSyncTypeToJSON, } from './ApiSyncType';
import { ApiUserFromJSON, ApiUserToJSON, } from './ApiUser';
export function ApiUserOauthClientFromJSON(json) {
    return ApiUserOauthClientFromJSONTyped(json, false);
}
export function ApiUserOauthClientFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'user': !exists(json, 'user') ? undefined : ApiUserFromJSON(json['user']),
        'service': !exists(json, 'service') ? undefined : ApiServiceFromJSON(json['service']),
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'scopes': !exists(json, 'scopes') ? undefined : json['scopes'],
        'readyToTypes': !exists(json, 'readyToTypes') ? undefined : (json['readyToTypes'].map(ApiSyncTypeFromJSON)),
        'syncs': !exists(json, 'syncs') ? undefined : (json['syncs'].map(ApiSyncFromJSON)),
    };
}
export function ApiUserOauthClientToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'user': ApiUserToJSON(value.user),
        'service': ApiServiceToJSON(value.service),
        'externalId': value.externalId,
        'scopes': value.scopes,
        'readyToTypes': value.readyToTypes === undefined ? undefined : (value.readyToTypes.map(ApiSyncTypeToJSON)),
        'syncs': value.syncs === undefined ? undefined : (value.syncs.map(ApiSyncToJSON)),
    };
}
