import { ApiEffectivenessRule, ApiProject } from "@incendium/api";
import { effectivenessRuleService } from "Apis";
import { useSharedState } from "./useSharedState";

const fetchEffectivenessRules = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const effectivenessRules =
      await effectivenessRuleService.effectivenessRuleServiceListRules({
        projectId: selectedProject.id as number,
      });
    return effectivenessRules.results || [];
  }
  return [];
};

export const useEffectivenessRules = () => {
  const { state, setState } = useSharedState(
    "effectivenessRules",
    [],
    fetchEffectivenessRules
  );

  return {
    effectivenessRules: state as ApiEffectivenessRule[],
    setEffectivenessRules: setState,
  };
};
