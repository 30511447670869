/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function ApiTestAudienceResponseFromJSON(json) {
    return ApiTestAudienceResponseFromJSONTyped(json, false);
}
export function ApiTestAudienceResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nLeads': !exists(json, 'nLeads') ? undefined : json['nLeads'],
        'haveEmails': !exists(json, 'haveEmails') ? undefined : json['haveEmails'],
        'haveMacro': !exists(json, 'haveMacro') ? undefined : json['haveMacro'],
        'haveRevenue': !exists(json, 'haveRevenue') ? undefined : json['haveRevenue'],
        'nCalculatedLeads': !exists(json, 'nCalculatedLeads') ? undefined : json['nCalculatedLeads'],
    };
}
export function ApiTestAudienceResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nLeads': value.nLeads,
        'haveEmails': value.haveEmails,
        'haveMacro': value.haveMacro,
        'haveRevenue': value.haveRevenue,
        'nCalculatedLeads': value.nCalculatedLeads,
    };
}
