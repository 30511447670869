import { ApiChartTemplate } from "@incendium/api";
import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import produce from "immer";
import { IChart } from "Interfaces";
import React from "react";
import { chartTemplateToColour, chartTemplateToIcon } from "Helpers/chartFns";

const Option = ({
  label,
  value,
}: {
  value: ApiChartTemplate;
  label: string;
}) => {
  return (
    <Stack direction="row" alignItems={"center"} spacing={1}>
      <Box
        sx={{
          background: chartTemplateToColour(value),
          height: 32,
          width: 32,
        }}
        p={0.5}
      >
        {chartTemplateToIcon(value)}
      </Box>

      <FormControlLabel value={value} control={<Radio />} label={label} />
    </Stack>
  );
};

function NewChartForm({
  chart,
  setChart,
  hideTemplateSelect,
}: {
  chart: IChart;
  setChart: React.Dispatch<React.SetStateAction<IChart>>;
  hideTemplateSelect?: boolean;
}) {
  return (
    <>
      <Box mt={2} mb={4} sx={{ width: "70%" }}>
        <TextField
          label="Name Your Chart *"
          fullWidth
          value={chart.name}
          size="small"
          onChange={(e) =>
            setChart(
              produce(chart, (draft) => {
                draft.name = e.target.value;
              })
            )
          }
        />
      </Box>
      {!hideTemplateSelect && (
        <>
          <FormControl>
            <FormLabel>What do you want to do? (*)</FormLabel>
            <RadioGroup
              name="template"
              value={chart.template}
              onChange={(e) =>
                setChart(
                  produce(chart, (draft) => {
                    draft.template = e.target.value as ApiChartTemplate;
                  })
                )
              }
            >
              <Option
                label="Show a snapshot or trend of data in a range of charts?"
                value={ApiChartTemplate.SNAPSHOT}
              />
              <Option
                label="Display data on a map?"
                value={ApiChartTemplate.MAP}
              />
            </RadioGroup>
          </FormControl>

          <Box mt={2}>
            <Alert
              severity={
                chart.name === "" ||
                !chart.template ||
                chart.template === ApiChartTemplate.UNDEFINED
                  ? "error"
                  : "success"
              }
            >
              {chart.name === "" ||
              !chart.template ||
              chart.template === ApiChartTemplate.UNDEFINED ? (
                <>
                  {chart.name === "" && "Name "}
                  {chart.name === "" &&
                    (!chart.template ||
                      chart.template === ApiChartTemplate.UNDEFINED) &&
                    "and "}
                  {(!chart.template ||
                    chart.template === ApiChartTemplate.UNDEFINED) &&
                    "Type "}
                  field(s) are required.
                </>
              ) : (
                <>Ready</>
              )}
            </Alert>
          </Box>
        </>
      )}
    </>
  );
}

export default NewChartForm;
