/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiDataFromJSON, ApiDataToJSON, } from './ApiData';
import { ApiJobCategoryFromJSON, ApiJobCategoryToJSON, } from './ApiJobCategory';
import { ApiJobTitleFromJSON, ApiJobTitleToJSON, } from './ApiJobTitle';
import { ApiPageFromJSON, ApiPageToJSON, } from './ApiPage';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiJobFromJSON(json) {
    return ApiJobFromJSONTyped(json, false);
}
export function ApiJobFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'jobId': !exists(json, 'jobId') ? undefined : json['jobId'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'salaryRange': !exists(json, 'salaryRange') ? undefined : json['salaryRange'],
        'contractType': !exists(json, 'contractType') ? undefined : json['contractType'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'pages': !exists(json, 'pages') ? undefined : (json['pages'].map(ApiPageFromJSON)),
        'pageViewImpressions': !exists(json, 'pageViewImpressions') ? undefined : (json['pageViewImpressions'].map(ApiDataFromJSON)),
        'jobTitle': !exists(json, 'jobTitle') ? undefined : ApiJobTitleFromJSON(json['jobTitle']),
        'jobCategory': !exists(json, 'jobCategory') ? undefined : ApiJobCategoryFromJSON(json['jobCategory']),
        'jobTitleId': !exists(json, 'jobTitleId') ? undefined : json['jobTitleId'],
        'jobCategoryId': !exists(json, 'jobCategoryId') ? undefined : json['jobCategoryId'],
    };
}
export function ApiJobToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'title': value.title,
        'description': value.description,
        'jobId': value.jobId,
        'category': value.category,
        'salaryRange': value.salaryRange,
        'contractType': value.contractType,
        'location': value.location,
        'project': ApiProjectToJSON(value.project),
        'projectId': value.projectId,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'pages': value.pages === undefined ? undefined : (value.pages.map(ApiPageToJSON)),
        'pageViewImpressions': value.pageViewImpressions === undefined ? undefined : (value.pageViewImpressions.map(ApiDataToJSON)),
        'jobTitle': ApiJobTitleToJSON(value.jobTitle),
        'jobCategory': ApiJobCategoryToJSON(value.jobCategory),
        'jobTitleId': value.jobTitleId,
        'jobCategoryId': value.jobCategoryId,
    };
}
