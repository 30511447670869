/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiGetUsersFilterFromJSON, ApiGetUsersFilterToJSON, } from './ApiGetUsersFilter';
export function InlineObjectFromJSON(json) {
    return InlineObjectFromJSONTyped(json, false);
}
export function InlineObjectFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'leadId': !exists(json, 'leadId') ? undefined : json['leadId'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'pageToken': !exists(json, 'pageToken') ? undefined : json['pageToken'],
        'search': !exists(json, 'search') ? undefined : json['search'],
        'filters': !exists(json, 'filters') ? undefined : (json['filters'].map(ApiGetUsersFilterFromJSON)),
    };
}
export function InlineObjectToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'leadId': value.leadId,
        'pageSize': value.pageSize,
        'pageToken': value.pageToken,
        'search': value.search,
        'filters': value.filters === undefined ? undefined : (value.filters.map(ApiGetUsersFilterToJSON)),
    };
}
