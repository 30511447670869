import { Box, Stack, styled, SxProps, Theme, Typography } from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import { StyledMiddleBox } from "Components/UI/StylesFlexBox";
import { motion } from "framer-motion";
import { useCallback } from "react";

interface ILoadingProps {
  text?: string;
  sx?: SxProps<Theme>;
  size?: number;
  fullHeight?: boolean;
}

const LoadingCircle = styled(Box)<{ size?: number }>(({ theme, size }) => ({
  width: size || 40,
  height: size || 40,
  borderRadius: "50%",
  display: "inline-block",
  position: "relative",
  border: "3px solid",
  borderColor: `#fff #fff  transparent transparent`,
  boxSizing: "border-box",
  animation: "rotation 1s linear infinite",
  "&::after, ::before": {
    content: "''",
    boxSizing: "border-box",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: "auto",
    border: "3px solid",
    borderColor: ` transparent transparent ${theme.palette.primary.main} ${theme.palette.primary.main}`,
    width: size ? size - 8 : 32,
    height: size ? size - 8 : 32,
    borderRadius: "50%",
    animation: `rotationBack 0.5s linear infinite`,
    transformOrigin: "center center",
  },
  "&::before": {
    width: size ? size - 16 : 32,
    height: size ? size - 16 : 32,
    borderColor: `#fff #fff transparent transparent`,
    animation: `rotation 1.5s linear infinite`,
  },
  "@keyframes rotation": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  " @keyframes rotationBack": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(-360deg)",
    },
  },
}));

function Loading({ text, sx, size, fullHeight }: ILoadingProps) {
  const renderBody = useCallback(() => {
    return (
      <Stack
        spacing={2}
        direction={fullHeight ? "column" : "row"}
        alignItems="center"
      >
        <LoadingCircle size={size} />
        {!!text && (
          <Typography variant={size && size > 50 ? "subtitle1" : "subtitle2"}>
            {text}
          </Typography>
        )}
      </Stack>
    );
  }, [text, size, fullHeight]);

  return (
    <StyledMiddleBox
      sx={{ height: "100%", ...sx }}
      component={motion.div}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{
        opacity: 1,
        scale: 1,
        transition: {
          ease: "easeOut",
        },
      }}
      exit={{ opacity: 0, scale: 0.5 }}
    >
      {fullHeight ? (
        <CenterPage calcHeight>{renderBody()}</CenterPage>
      ) : (
        <>{renderBody()}</>
      )}
    </StyledMiddleBox>
  );
}

export default Loading;
