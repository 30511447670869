import { trafficBucketService } from "Apis";
import { useSharedState } from "./useSharedState";
import { ApiBucketResponse, ApiProject } from "@incendium/api";

const fetchTrafficBuckets = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const trafficBuckets = await trafficBucketService.bucketServiceListBuckets({
      projectId: selectedProject.id as number,
    });
    return trafficBuckets.results || [];
  }
  return [];
};
export const useTrafficBuckets = () => {
  const { state, setState, loading } = useSharedState(
    "buckets",
    [],
    fetchTrafficBuckets
  );

  return {
    trafficBuckets: state as ApiBucketResponse[],
    setTrafficBuckets: setState,
    loading,
  };
};
