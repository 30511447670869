/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiScheduledReportType;
(function (ApiScheduledReportType) {
    ApiScheduledReportType["SCHEDULED_REPORT_TYPE_NONE"] = "ScheduledReportTypeNone";
    ApiScheduledReportType["SCHEDULED_REPORT_TYPE_CSV"] = "ScheduledReportTypeCSV";
    ApiScheduledReportType["SCHEDULED_REPORT_TYPE_XLS"] = "ScheduledReportTypeXLS";
    ApiScheduledReportType["SCHEDULED_REPORT_TYPE_LINK"] = "ScheduledReportTypeLink";
    ApiScheduledReportType["SCHEDULED_REPORT_TYPE_CSV_AND_XLS"] = "ScheduledReportTypeCSVAndXLS";
    ApiScheduledReportType["SCHEDULED_REPORT_TYPE_CSV_AND_LINK"] = "ScheduledReportTypeCSVAndLink";
    ApiScheduledReportType["SCHEDULED_REPORT_TYPE_XLS_AND_LINK"] = "ScheduledReportTypeXLSAndLink";
    ApiScheduledReportType["SCHEDULED_REPORT_TYPE_CSV_AND_XLS_AND_LINK"] = "ScheduledReportTypeCSVAndXLSAndLink";
})(ApiScheduledReportType || (ApiScheduledReportType = {}));
export function ApiScheduledReportTypeFromJSON(json) {
    return ApiScheduledReportTypeFromJSONTyped(json, false);
}
export function ApiScheduledReportTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiScheduledReportTypeToJSON(value) {
    return value;
}
