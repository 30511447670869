import { ApiClient, ApiProject } from "@incendium/api";
import { clientService, projectService } from "Apis/api";
import { AppThunk, CallbackOrVal, IStoreState } from "Interfaces";
import { get } from "lodash";
import { clientListSelector } from "Selectors/clientListSelector";
import { projectListSelector } from "Selectors/projectListSelector";
import { action } from "typesafe-actions";
import {
  ADD_CLIENT,
  ADD_PROJECT,
  CLEAR,
  DELETE_CLIENT,
  FETCHING_STATE,
  FETCHING_STATE_ERROR,
  FETCHING_STATE_SUCCESS,
  INITIALIZE_CLIENTS,
  INITIALIZE_PROJECTS,
  LOAD_FULL_PROJECT,
  SET_APPLICATION_STATE,
  UPDATE_CLIENT,
  UPDATE_PROJECT,
} from "./clientActionTypes";

export const initializeClients = (clients: ApiClient[]) =>
  action(INITIALIZE_CLIENTS, { clients });
export const initializeProjects = (
  clientId: number,
  projects: ApiProject[]
) => {
  return action(INITIALIZE_PROJECTS, { clientId, projects });
};

export const loadFullProject = (
  clientId: number,
  projectId: number,
  project: ApiProject
) =>
  action(LOAD_FULL_PROJECT, {
    clientId,
    projectId,
    project,
  });

export const addClient = (client: ApiClient) => {
  return action(ADD_CLIENT, { client });
};

export const updateClient = (client: ApiClient) => {
  return action(UPDATE_CLIENT, { client });
};

export const deleteClient = (id: number) => {
  return action(DELETE_CLIENT, { id });
};

export const addProject = (project: ApiProject) => {
  return action(ADD_PROJECT, { project });
};

export const updateProject = (project: ApiProject) => {
  return action(UPDATE_PROJECT, { project });
};

export const setApplicationState = (
  clientId: string,
  projectId: string,
  key: Exclude<keyof IStoreState["application"], "clients" | "projects">,
  state: CallbackOrVal<any>,
  id?: number
) => {
  return action(SET_APPLICATION_STATE, { clientId, projectId, state, key, id });
};

export const fetchStateThunk =
  (
    clientId: string,
    projectId: string,
    key: Exclude<keyof IStoreState["application"], "clients" | "projects">,
    fetchFunc: () => Promise<void>,
    refresh?: boolean,
    id?: number
  ): AppThunk =>
  async (dispatch, getState) => {
    const status =
      typeof id !== "undefined"
        ? get(
            getState().application[key],
            [clientId, projectId, id, "status"],
            "UNFETCHED"
          )
        : get(
            getState().application[key],
            [clientId, projectId, "status"],
            "UNFETCHED"
          );

    if (status === "UNFETCHED" || refresh) {
      dispatch(fetchingStateFromApi(clientId, projectId, key, id));
      try {
        await fetchFunc();
        dispatch(fetchStateFromApiSuccess(clientId, projectId, key, id));
      } catch (e) {
        console.error(e);
        dispatch(fetchStateFromApiError(clientId, projectId, key, id));
      }
    }
  };

export const fetchingStateFromApi = (
  clientId: string,
  projectId: string,
  key: Exclude<keyof IStoreState["application"], "clients" | "projects">,
  id?: number
) => {
  return action(FETCHING_STATE, { clientId, projectId, key, id });
};

export const fetchStateFromApiSuccess = (
  clientId: string,
  projectId: string,
  key: Exclude<keyof IStoreState["application"], "clients" | "projects">,
  id?: number
) => {
  return action(FETCHING_STATE_SUCCESS, { clientId, projectId, key, id });
};
export const fetchStateFromApiError = (
  clientId: string,
  projectId: string,
  key: Exclude<keyof IStoreState["application"], "clients" | "projects">,
  id?: number
) => {
  return action(FETCHING_STATE_ERROR, { clientId, projectId, key, id });
};

export const initializeClientsThunk = ({
  onDone = () => {},
} = {}): AppThunk => {
  return async (dispatch, getState) => {
    const clients = clientListSelector(getState());
    if (!clients.hasLoaded) {
      const res = await clientService.clientServiceList();

      if (res.results) {
        dispatch(initializeClients(res.results));
      }
    }
    onDone();
  };
};

export const initializeProjectsThunk = (
  clientId?: number,
  onDone = () => {}
): AppThunk => {
  return async (dispatch, getState) => {
    if (!clientId) {
      return;
    }
    await dispatch(initializeClientsThunk());
    const hasLoaded = projectListSelector(`${clientId}`)(getState()).hasLoaded;
    if (!hasLoaded) {
      const res = await projectService.projectsServiceListProjects({
        clientId: clientId,
      });
      if (res.results) {
        dispatch(initializeProjects(clientId, res.results));
      }
    }
    onDone();
  };
};

export const fetchAllClientsAndProjectsThunk =
  ({
    clientId,
    projectId,
    onDone,
  }: {
    clientId: number;
    projectId: number;
    onDone?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(
      initializeClientsThunk({
        onDone: async () => {
          dispatch(
            initializeProjectsThunk(clientId, async () => {
              const res = await projectService.projectsServiceReadProject({
                clientId: clientId,
                projectId: projectId,
              });

              dispatch(loadFullProject(clientId, projectId, res));
              onDone && onDone();
            })
          );
        },
      })
    );
  };

export const clearState = () => action(CLEAR);
