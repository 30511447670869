/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiCalculateChannelFromSourceRequestToJSON, ApiCalculateChannelFromSourceResponseFromJSON, ApiChannelFromJSON, ApiChannelPayloadToJSON, ApiListChannelsResponseFromJSON, ApiReorderChannelsPayloadToJSON, } from '../models';
/**
 *
 */
export class ChannelServiceApi extends runtime.BaseAPI {
    /**
     */
    channelServiceCalculateChannelFromSourceRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.body === null || requestParameters.body === undefined) {
                throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling channelServiceCalculateChannelFromSource.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/channel/v1/channels/calculate-from-source`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiCalculateChannelFromSourceRequestToJSON(requestParameters.body),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiCalculateChannelFromSourceResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    channelServiceCalculateChannelFromSource(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.channelServiceCalculateChannelFromSourceRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    channelServiceCreateChannelRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling channelServiceCreateChannel.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling channelServiceCreateChannel.');
            }
            const queryParameters = {};
            if (requestParameters.channelId !== undefined) {
                queryParameters['channelId'] = requestParameters.channelId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/channel/v1/projects/{projectId}/channels`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiChannelPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiChannelFromJSON(jsonValue));
        });
    }
    /**
     */
    channelServiceCreateChannel(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.channelServiceCreateChannelRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    channelServiceDeleteChannelRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling channelServiceDeleteChannel.');
            }
            if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
                throw new runtime.RequiredError('channelId', 'Required parameter requestParameters.channelId was null or undefined when calling channelServiceDeleteChannel.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadIsPaidChannel !== undefined) {
                queryParameters['payload.isPaidChannel'] = requestParameters.payloadIsPaidChannel;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/channel/v1/projects/{projectId}/channels/{channelId}:delete`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    channelServiceDeleteChannel(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.channelServiceDeleteChannelRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    channelServiceListChannelsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling channelServiceListChannels.');
            }
            const queryParameters = {};
            if (requestParameters.channelId !== undefined) {
                queryParameters['channelId'] = requestParameters.channelId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/channel/v1/projects/{projectId}/channels`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListChannelsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    channelServiceListChannels(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.channelServiceListChannelsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    channelServiceReadChannelRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling channelServiceReadChannel.');
            }
            if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
                throw new runtime.RequiredError('channelId', 'Required parameter requestParameters.channelId was null or undefined when calling channelServiceReadChannel.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadIsPaidChannel !== undefined) {
                queryParameters['payload.isPaidChannel'] = requestParameters.payloadIsPaidChannel;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/channel/v1/projects/{projectId}/channels/{channelId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiChannelFromJSON(jsonValue));
        });
    }
    /**
     */
    channelServiceReadChannel(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.channelServiceReadChannelRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    channelServiceReorderChannelsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling channelServiceReorderChannels.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling channelServiceReorderChannels.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/channel/v1/projects/{projectId}/channels:reorder`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiReorderChannelsPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListChannelsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    channelServiceReorderChannels(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.channelServiceReorderChannelsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    channelServiceUpdateChannelRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling channelServiceUpdateChannel.');
            }
            if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
                throw new runtime.RequiredError('channelId', 'Required parameter requestParameters.channelId was null or undefined when calling channelServiceUpdateChannel.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling channelServiceUpdateChannel.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/channel/v1/projects/{projectId}/channels/{channelId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiChannelPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiChannelFromJSON(jsonValue));
        });
    }
    /**
     */
    channelServiceUpdateChannel(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.channelServiceUpdateChannelRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
