/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiListProjectsResponseFromJSON(json) {
    return ApiListProjectsResponseFromJSONTyped(json, false);
}
export function ApiListProjectsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'results': !exists(json, 'results') ? undefined : (json['results'].map(ApiProjectFromJSON)),
        'nextPageToken': !exists(json, 'nextPageToken') ? undefined : json['nextPageToken'],
    };
}
export function ApiListProjectsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'results': value.results === undefined ? undefined : (value.results.map(ApiProjectToJSON)),
        'nextPageToken': value.nextPageToken,
    };
}
