import { ApiConversion, ApiConversionType } from "@incendium/api";
import { Autocomplete, TextField, OutlinedTextFieldProps } from "@mui/material";
import { useConversions } from "Hooks/useConversions";
import { useMemo } from "react";

interface IConversionsDropdownProps
  extends Omit<OutlinedTextFieldProps, "onChange"> {
  value?: number;
  onChange: (n?: number, c?: ApiConversion) => void;
  type?: ApiConversionType;
  disableClearable?: boolean;
}

function ConversionsDropdown({
  label,
  size,
  variant,
  value,
  onChange,
  fullWidth,
  sx,
  type,
  disableClearable,
}: IConversionsDropdownProps) {
  const { conversions } = useConversions();

  const selectConversion = useMemo(
    () => (conversions || []).find((c) => c.id === value) || {},
    [conversions, value]
  );

  const filteredConversions = useMemo(() => {
    if (!type) {
      return conversions;
    }
    return conversions.filter((c) => c.type === type);
  }, [conversions, type]);

  const sortedFilteredConversions = useMemo(() => {
    return [...filteredConversions].sort((a, b) =>
      `${a.location?.name || ""} - ${
        a.type === ApiConversionType.MACRO ? "Conversion" : "Interaction"
      }`.localeCompare(
        `${b.location?.name || ""} - ${
          b.type === ApiConversionType.MACRO ? "Conversion" : "Interaction"
        }`
      )
    );
  }, [filteredConversions]);

  return (
    <Autocomplete
      sx={sx}
      fullWidth={fullWidth}
      options={sortedFilteredConversions}
      value={selectConversion}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      groupBy={(o) =>
        `${o.location?.name || ""} - ${
          o.type === ApiConversionType.MACRO ? "Conversion" : "Interaction"
        }`
      }
      onChange={(e, v) => {
        onChange(v?.id, v ? v : undefined);
      }}
      disableClearable={disableClearable}
      getOptionLabel={(o: ApiConversion) => o.name || ""}
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant} size={size} />
      )}
    />
  );
}

export default ConversionsDropdown;
