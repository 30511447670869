/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiFileResponseFromJSON, ApiFileResponseToJSON, } from './ApiFileResponse';
import { GooglerpcStatusFromJSON, GooglerpcStatusToJSON, } from './GooglerpcStatus';
export function StreamResultOfApiFileResponseFromJSON(json) {
    return StreamResultOfApiFileResponseFromJSONTyped(json, false);
}
export function StreamResultOfApiFileResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'result': !exists(json, 'result') ? undefined : ApiFileResponseFromJSON(json['result']),
        'error': !exists(json, 'error') ? undefined : GooglerpcStatusFromJSON(json['error']),
    };
}
export function StreamResultOfApiFileResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'result': ApiFileResponseToJSON(value.result),
        'error': GooglerpcStatusToJSON(value.error),
    };
}
