import {
  ApiChannel,
  ApiChannelGroupPayload,
  ApiConditionCustom,
  ApiProject,
} from "@incendium/api";
import { Box, Switch, Typography } from "@mui/material";
import produce from "immer";
import { useCallback, useEffect } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import SourceRules from "features/channels/components/SourceRules";
import {
  IChannelGroupOverride,
  useConditionGroupContext,
} from "Components/ConditionalGroup/ConditionGroupProvider";
import { useHistory } from "react-router-dom";
import { useChannels } from "Hooks/useChannels";
import ConditionGroupsFormWrapper from "Components/ConditionalGroup/ConditionGroupsFormWrapper";
import { SidebarContentWrapper, SiderBarHeader } from "Components/UI/Sidebar";
import { useNotification } from "Hooks";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import { saveChannel } from "features/channels";

interface IChanelFormProps {
  channel: ApiChannel;
  setChannel: React.Dispatch<React.SetStateAction<ApiChannel | undefined>>;
  project: ApiProject;
}

function ChanelForm({ channel, setChannel, project }: IChanelFormProps) {
  const history = useHistory();
  const { conditionGroups, setConditionGroups } = useConditionGroupContext();
  const { channels, setChannels } = useChannels();
  const { showSuccessNotification, showErrorNotification } = useNotification();

  useEffect(() => {
    if (!channel || conditionGroups.length) {
      return;
    }

    const groups = channel.channelGroups || [];

    // parse channel groups as condition groups
    const parsedCGs: IChannelGroupOverride[] = groups.map((g) => ({
      ...g,
      conditions: (g.rules || []).map((r) => ({
        ...r,
        custom: ApiConditionCustom.UNSPECIFIED,
        conversionId: null,
      })),
    }));

    setConditionGroups(
      parsedCGs && parsedCGs.length > 0
        ? [...parsedCGs]
        : [
            {
              conditions: [{}],
            },
          ]
    );
  }, [channel, setConditionGroups, conditionGroups]);

  const onSubmit = useCallback(async () => {
    const parsedCGs: ApiChannelGroupPayload[] = conditionGroups.map((cg) => ({
      ...cg,
      rules: cg.conditions || [],
    }));

    try {
      const res = await saveChannel(project.id as number, channel, parsedCGs);
      showSuccessNotification(`${res.name} Saved`);

      setChannels(
        produce(channels, (draft) => {
          const idx = draft.findIndex((d) => d.id === res.id);
          if (idx >= 0) {
            draft[idx] = res;
          }
        })
      );
    } catch (error) {
      showErrorNotification(`Internal error, please try again`);
    }
  }, [
    channel,
    channels,
    conditionGroups,
    project.id,
    setChannels,
    showErrorNotification,
    showSuccessNotification,
  ]);

  const onCancel = () => {
    history.push(`../channels`);
  };

  return (
    <>
      <ConditionGroupsFormWrapper
        onSubmit={onSubmit}
        onCancel={onCancel}
        rules={<SourceRules />}
      >
        <SiderBarHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography variant="h2">{channel.name} Settings</Typography>
          </Box>
          <Typography>Assign a name to this channel.</Typography>
        </SiderBarHeader>
        <SidebarContentWrapper px={3}>
          <Box mb={2} mt={2}>
            <TextValidator
              label="name of channel"
              variant="outlined"
              name="name"
              fullWidth
              validators={["required"]}
              errorMessages={["name is required"]}
              value={channel.name || ""}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setChannel(
                  produce(channel, (draft) => {
                    draft.name = e.currentTarget.value;
                  })
                );
              }}
            />
          </Box>
          <StyledFlexBox>
            <Box display="flex" alignItems="center">
              <Switch
                checked={channel.isPaidChannel}
                onChange={(e, checked) =>
                  setChannel(
                    produce(channel, (draft) => {
                      draft.isPaidChannel = checked;
                    })
                  )
                }
              />
              <Typography
                color={!!channel.isPaidChannel ? "primary" : "secondary"}
              >
                Is Paid Marketing
              </Typography>
            </Box>
            <ClickableTooltip
              placement="right"
              text={`Specify whether this Channel should be considered 'paid marketing' in analytics.`}
              icon={<StyledHelp />}
            />
          </StyledFlexBox>
        </SidebarContentWrapper>
      </ConditionGroupsFormWrapper>
    </>
  );
}

export default ChanelForm;
