/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiProviderType;
(function (ApiProviderType) {
    ApiProviderType["UNKNOWN"] = "UNKNOWN";
    ApiProviderType["SEND_GRID_EMAIL"] = "SEND_GRID_EMAIL";
    ApiProviderType["TWILIO_SMS"] = "TWILIO_SMS";
    ApiProviderType["MAIL_CHIMP_EMAIL"] = "MAIL_CHIMP_EMAIL";
})(ApiProviderType || (ApiProviderType = {}));
export function ApiProviderTypeFromJSON(json) {
    return ApiProviderTypeFromJSONTyped(json, false);
}
export function ApiProviderTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiProviderTypeToJSON(value) {
    return value;
}
