import { ApiTabElementType } from "@incendium/api";
import { Addchart, Widgets } from "@mui/icons-material";
import { Portal, Stack } from "@mui/material";
import {
  LeftMenuBlock,
  LeftMenuExtras,
  LeftMenuExtrasInner,
  LeftMenuExtrasTitle,
  LeftMenuToggleButton,
} from "features/leftMenu";
import { TypographyHelp } from "Components/UI/TypographyHelp";
import { useSelector } from "react-redux";
import { leftMenuSelector } from "Selectors/leftMenuSelector";
import ElementDragCard from "./ElementDragCard";
import { useCallback } from "react";
import { useReportBuilderContext } from "Providers/ReportBuilderProvider";

function ReportBuilderSidebar() {
  const { isSecondaryOpen } = useSelector(leftMenuSelector);
  const { setGridDimensions } = useReportBuilderContext();

  const onDragStart = useCallback(
    (type: ApiTabElementType, e: unknown) => {
      (e as DragEvent).dataTransfer!.setData("dragData", type);
      switch (type) {
        case ApiTabElementType.BLOCK:
          setGridDimensions({
            h: 6,
            w: 6,
          });
          break;
        case ApiTabElementType.CHART:
          setGridDimensions({
            h: 6,
            w: 4,
          });
          break;
        case ApiTabElementType.STAT:
          setGridDimensions({
            h: 2,
            w: 4,
          });
          break;

        default:
          break;
      }
    },
    [setGridDimensions]
  );

  return (
    <Portal container={() => document.getElementById("leftMenuOptions")}>
      <LeftMenuExtras>
        <LeftMenuExtrasTitle>
          <LeftMenuToggleButton backToSecondaryText="Back to report tools" />
        </LeftMenuExtrasTitle>

        {isSecondaryOpen && (
          <LeftMenuBlock>
            <LeftMenuExtrasInner>
              <Stack mt={3} spacing={4}>
                <Stack spacing={2.5}>
                  <Stack
                    sx={{ width: "100%" }}
                    direction={"row"}
                    spacing={2}
                    alignItems="center"
                    pl={1}
                  >
                    <Widgets color="primary" />
                    <TypographyHelp
                      variant="subtitle1"
                      color={"white"}
                      text="Widgets"
                      tooltip={
                        "Drag a chart block, or a grouping block, onto the report canvas. Chart blocks can contain 1 chart where as grouping blocks can contain multiple charts under one optional heading."
                      }
                      justifyContent="space-between"
                    />
                  </Stack>
                  <Stack spacing={2}>
                    {/* <ElementDragCard
                      icon={<AutoAwesomeMosaic />}
                      title="Block"
                      type={ApiTabElementType.BLOCK}
                      onDragStart={(e) => {
                        onDragStart(ApiTabElementType.BLOCK, e);
                      }}
                    /> */}
                    <ElementDragCard
                      icon={<Addchart />}
                      title="Chart"
                      type={ApiTabElementType.CHART}
                      onDragStart={(e) => {
                        onDragStart(ApiTabElementType.CHART, e);
                      }}
                    />
                    <ElementDragCard
                      icon={<Addchart />}
                      title="Statistic Card"
                      type={ApiTabElementType.STAT}
                      onDragStart={(e) => {
                        onDragStart(ApiTabElementType.STAT, e);
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </LeftMenuExtrasInner>
          </LeftMenuBlock>
        )}
      </LeftMenuExtras>
    </Portal>
  );
}

export default ReportBuilderSidebar;
