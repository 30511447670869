import { useSelector } from "react-redux";
import { reportSelector } from "Selectors/reportSelector";
import { useClientIdFromUrl } from "./useClientIdFromUrl";
import { useProjectIdFromUrl } from "./useProjectIdFromUrl";
import { useMatchPath } from "Hooks/useMatchPath";

const useReportPath = () => {
  return useMatchPath<{
    clientId: string;
    projectId: string;
    reportId: string;
  }>("/clients/:clientId/projects/:projectId/analyse/reports/:reportId");
};

const useReportIdFromUrl = () => {
  const path = useReportPath();
  return path?.params.reportId;
};

export const useReportSelector = () => {
  const clientId = useClientIdFromUrl();
  const projectId = useProjectIdFromUrl();
  const reportId = useReportIdFromUrl();

  const report = useSelector(reportSelector(clientId, projectId, reportId));
  return report;
};
