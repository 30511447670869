import { createContext, useContext, useMemo, useState } from "react";
import {
  DragDropContext,
  DragStart,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";

export interface IDndCallbacks {
  onDragStart?: (initial: DragStart, provided: ResponderProvided) => void;
  onDragEnd: (initial: DropResult, provided: ResponderProvided) => void;
}

export type TAppDndContext = {
  setCallBacks: React.Dispatch<React.SetStateAction<IDndCallbacks>>;
};

const AppDndContext = createContext<TAppDndContext>({
  setCallBacks: () => {},
});
export const useAppDndContext = () => useContext(AppDndContext);

const AppDndProvider = ({ children }: any) => {
  const [callBacks, setCallBacks] = useState<any>();

  const state = useMemo(
    () => ({
      setCallBacks,
    }),
    [setCallBacks]
  );

  return (
    <AppDndContext.Provider value={state}>
      <DragDropContext
        onDragStart={callBacks?.onDragStart}
        onDragEnd={callBacks?.onDragEnd}
      >
        {children}
      </DragDropContext>
    </AppDndContext.Provider>
  );
};

export default AppDndProvider;
