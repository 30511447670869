import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";
// import { OverridableComponent } from "@mui/material/OverridableComponent";
import { motion } from "framer-motion";
import backgroundImageStat1 from "Assets/backgrounds/stat-1.jpg";
import backgroundImageStat2 from "Assets/backgrounds/stat-2.jpg";
import backgroundImageStat3 from "Assets/backgrounds/stat-3.jpg";
import backgroundImageStat4 from "Assets/backgrounds/stat-4.jpg";
import { useFromToContext } from "Providers/FromToProvider";
import { useCallback, useEffect, useState } from "react";
import { usePrevious } from "react-use";
import Loading from "Components/Loading/Loading";
import { ReactComponent as LightBulb } from "Assets/icons/light-bulb.svg";
import { HTMLMotionProps } from "framer-motion";
import { TFramerAnimationProps } from "Interfaces";

export interface IStatCardProps extends BoxProps {
  direction?: "up" | "down";
  minHeight?: string | number;
  loading?: boolean;
  showRecordedIn?: boolean;
  bgIndex?: number;
  noBg?: boolean;
  fullHeight?: boolean;
}

interface IStyledStatCardProps
  extends BoxProps,
    Pick<HTMLMotionProps<any>, TFramerAnimationProps> {
  minHeight?: string | number;
  bgIndex?: number;
  noBg?: boolean;
  fullHeight?: boolean;
}
const StyledStatCardWrapper = styled(Box)<{ fullHeight?: boolean }>(
  ({ fullHeight }) => ({
    flex: 1,
    height: fullHeight ? "100%" : undefined,
  })
);
const StyledStatCard = styled(Box)<IStyledStatCardProps>(
  ({ minHeight = 117, bgIndex, noBg, fullHeight }) => {
    const bg =
      bgIndex === 1
        ? backgroundImageStat1
        : bgIndex === 2
        ? backgroundImageStat2
        : bgIndex === 3
        ? backgroundImageStat3
        : backgroundImageStat4;

    return {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      justifyContent: "flex-start",
      minHeight,
      borderRadius: 10,
      overflow: "hidden",
      color: "white",
      backgroundColor: "white",
      backgroundImage: !noBg ? `url(${bg})` : undefined,
      backgroundSize: "cover",
      height: fullHeight ? "100%" : undefined,
    };
  }
);
StyledStatCard.defaultProps = {
  px: 2,
  py: 1.5,
};

export const StatCard = ({
  direction,
  children,
  loading,
  showRecordedIn,
  bgIndex: initialBgIndex,
  noBg,
  fullHeight,
  ...rest
}: IStatCardProps) => {
  const [bgIndex, setBgIndex] = useState(initialBgIndex || 1);
  const [loaded, setLoaded] = useState(false);
  const prevLoading = usePrevious(loading);
  const [statRef, setStatRef] = useState<HTMLDivElement | null>(null);
  const onRefChange = useCallback((node) => {
    setStatRef(node);
  }, []);

  useEffect(() => {
    if (prevLoading && !loading) {
      setLoaded(true);
    }
  }, [loading, prevLoading]);

  useEffect(() => {
    const parent = statRef?.parentNode?.parentNode?.parentNode;
    if (!parent) return; // Exit early if parent is not available

    const statCards = parent.querySelectorAll(".stat-card");

    statCards.forEach((card, index) => {
      if (card === statRef) {
        setBgIndex((index % 4) + 1);
      }
    });
  }, [statRef]);

  const { formatedString } = useFromToContext();
  return (
    <StyledStatCardWrapper
      ref={onRefChange}
      className="stat-card"
      sx={{ flex: 1 }}
      fullHeight={fullHeight}
    >
      <StyledStatCard
        bgIndex={initialBgIndex || bgIndex}
        noBg={noBg}
        component={motion.div}
        fullHeight={fullHeight}
        initial={{
          opacity: 0,
          scale: 0.7,
        }}
        animate={{
          opacity: 1,
          scale: 1,
        }}
        {...rest}
      >
        {loading || !loaded ? (
          <Loading />
        ) : (
          <>
            <Box>{children}</Box>
            <Box
              sx={{
                position: "absolute",
                top: 12,
                right: 12,
                width: 25,
                height: 25,
                fill: "white",
              }}
            >
              <LightBulb />
            </Box>

            {showRecordedIn && (
              <Box
                component={motion.div}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                sx={{ position: "absolute", bottom: 12, right: 12 }}
              >
                <Typography
                  sx={{
                    opacity: 0.8,
                    color: noBg ? "initial" : "white",
                    transform: "translateY(8px)",
                    fontSize: 12,
                  }}
                >
                  {formatedString}
                </Typography>
              </Box>
            )}
          </>
        )}
      </StyledStatCard>
    </StyledStatCardWrapper>
  );
};

interface IStatCardRoundProps extends IStatCardProps {
  size?: "small" | "medium" | number;
  backgroundColor?: number;
}

export const StatCardRound = styled(Box, {
  shouldForwardProp: (prop) => prop !== "lines",
})<IStatCardRoundProps>(({ size, theme, direction, backgroundColor }) => ({
  position: "relative",
  height: typeof size === "number" ? size : size === "medium" ? 207 : 180,
  width: typeof size === "number" ? size : size === "medium" ? 207 : 180,
  borderRadius: "50%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.charts[backgroundColor || 0],
  "&::before": {
    content: "''",
    position: "absolute",
    top: -10,
    bottom: -10,
    left: -10,
    right: -10,
    border: "3px solid",
    borderRadius: "50%",
    opacity: 0.3,
    borderColor: theme.palette.charts[backgroundColor || 0],
  },
}));

StatCardRound.defaultProps = {
  p: 1.5,
};

export const StatCardCenterContainer = styled(Box)(() => ({
  minHeight: "100%",
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
}));

type TSize = "xs" | "small" | "medium" | "large";

interface IStatCardTypographyProps extends TypographyProps {
  size?: TSize;
  fontSize?: number;
  fontWeight?: number;
  opactity?: number;
  directionColour?: "up" | "down";
  dense?: boolean;
  lines?: number;
}

const StyledStatCardTypography = styled(Typography)<IStatCardTypographyProps>(
  ({ theme, size, opactity, dense, lines, display, color }) => ({
    lineHeight: dense ? 1 : 1.25,
    color: !color ? `rgba(255,255,255,${opactity || 1})` : undefined,
    overflow: lines ? "hidden" : undefined,
    textOverflow: "ellipsis",
    display: display ? undefined : "-webkit-box",
    WebkitLineClamp: lines,
    WebkitBoxOrient: "vertical",
    textTransform:
      size === "large" || size === "medium" ? "capitalize" : "initial",
  })
);

export const StatCardTypography = ({
  children,
  ...props
}: IStatCardTypographyProps) => {
  const theme = useTheme();
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const onRefChange = useCallback((node) => {
    setContainerRef(node);
  }, []);

  const [fontSize, setFontSize] = useState(
    props.fontSize
      ? props.fontSize
      : props.size === "xs" || props.size === "small"
      ? 16
      : 26
  );
  const text = children as string;

  useEffect(() => {
    if (!containerRef || props.fontSize) {
      return;
    }
    const adjustFontSize = () => {
      const containerWidth = containerRef?.offsetWidth;
      if (containerWidth == null) return; // Exit early if containerWidth is null or undefined

      let textWidth = getTextWidth(
        text,
        `${props.size === "xs" || props.size === "small" ? 16 : 26}px ${
          theme.typography.fontFamily
        }`
      );
      const padding = 0;

      if (textWidth + padding > containerWidth) {
        // see if 15 - 24 would fit
        let textWidth = getTextWidth(
          text,
          `${props.size === "xs" || props.size === "small" ? 15 : 24}px ${
            theme.typography.fontFamily
          }`
        );
        if (textWidth + padding > containerWidth) {
          setFontSize(props.size === "xs" || props.size === "small" ? 14 : 22);
        } else {
          setFontSize(props.size === "xs" || props.size === "small" ? 15 : 24);
        }
      } else {
        setFontSize(props.size === "xs" || props.size === "small" ? 16 : 26);
      }
    };

    adjustFontSize();

    // Add event listener for window resize
    window.addEventListener("resize", adjustFontSize);
    return () => {
      // Cleanup: remove event listener
      window.removeEventListener("resize", adjustFontSize);
    };
  }, [
    text,
    props.size,
    theme.typography.fontFamily,
    containerRef,
    props.fontSize,
  ]);

  // Function to measure text width
  const getTextWidth = (text: string, font: string) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (!context) return 0; // Return 0 if context is not available
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  };

  return (
    <span ref={onRefChange}>
      <StyledStatCardTypography
        {...props}
        fontSize={fontSize}
        fontWeight={
          props.fontWeight
            ? props.fontWeight
            : props.size &&
              (["small", "xsmall"] as TSize[]).includes(props.size)
            ? 400
            : 600
        }
      >
        {children}
      </StyledStatCardTypography>
    </span>
  );
};

export const StatCardRoundTypography = styled(
  Typography
)<IStatCardTypographyProps>(
  ({ theme, size, opactity, directionColour, dense, lines }) => ({
    fontSize: size === "small" ? 14 : size === "medium" ? 18 : 24,
    fontWeight: size === "small" ? 600 : "bold",
    lineHeight: dense ? 1 : 1.2,
    paddingTop: dense ? 2.5 : 0,
    paddingBottom: dense ? 2.5 : 0,
    color:
      directionColour === "up"
        ? "#DDFF80"
        : directionColour === "down"
        ? "#FFCED9"
        : `rgba(255,255,255,${opactity || 1})`, //todo, handle direction colour in context of background
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: lines,
    WebkitBoxOrient: "vertical",
  })
);

StatCardRoundTypography.defaultProps = {
  align: "center",
};
