/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiCampaignStatus;
(function (ApiCampaignStatus) {
    ApiCampaignStatus["CAMPAIGN_STATUS_NONE"] = "CampaignStatusNone";
    ApiCampaignStatus["CAMPAIGN_STATUS_ACTIVE"] = "CampaignStatusActive";
    ApiCampaignStatus["CAMPAIGN_STATUS_INACTIVE"] = "CampaignStatusInactive";
})(ApiCampaignStatus || (ApiCampaignStatus = {}));
export function ApiCampaignStatusFromJSON(json) {
    return ApiCampaignStatusFromJSONTyped(json, false);
}
export function ApiCampaignStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiCampaignStatusToJSON(value) {
    return value;
}
