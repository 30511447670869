/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiAudienceFromJSON, ApiAudienceToJSON, } from './ApiAudience';
import { ApiCampaignLeadFromJSON, ApiCampaignLeadToJSON, } from './ApiCampaignLead';
import { ApiCampaignSplitUrlFromJSON, ApiCampaignSplitUrlToJSON, } from './ApiCampaignSplitUrl';
import { ApiCampaignStatusFromJSON, ApiCampaignStatusToJSON, } from './ApiCampaignStatus';
import { ApiCampaignTypeFromJSON, ApiCampaignTypeToJSON, } from './ApiCampaignType';
import { ApiLandingDomainFromJSON, ApiLandingDomainToJSON, } from './ApiLandingDomain';
import { ApiLandingPathFromJSON, ApiLandingPathToJSON, } from './ApiLandingPath';
import { ApiPersonalisationFromJSON, ApiPersonalisationToJSON, } from './ApiPersonalisation';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
import { ApiUserFromJSON, ApiUserToJSON, } from './ApiUser';
export function ApiCampaignFromJSON(json) {
    return ApiCampaignFromJSONTyped(json, false);
}
export function ApiCampaignFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
        'status': !exists(json, 'status') ? undefined : ApiCampaignStatusFromJSON(json['status']),
        'type': !exists(json, 'type') ? undefined : ApiCampaignTypeFromJSON(json['type']),
        'maxNumberOfLeads': !exists(json, 'maxNumberOfLeads') ? undefined : json['maxNumberOfLeads'],
        'probability': !exists(json, 'probability') ? undefined : json['probability'],
        'personalisations': !exists(json, 'personalisations') ? undefined : (json['personalisations'].map(ApiPersonalisationFromJSON)),
        'ownerId': !exists(json, 'ownerId') ? undefined : json['ownerId'],
        'owner': !exists(json, 'owner') ? undefined : ApiUserFromJSON(json['owner']),
        'lastUserId': !exists(json, 'lastUserId') ? undefined : json['lastUserId'],
        'lastUser': !exists(json, 'lastUser') ? undefined : ApiUserFromJSON(json['lastUser']),
        'leads': !exists(json, 'leads') ? undefined : (json['leads'].map(ApiCampaignLeadFromJSON)),
        'leadCount': !exists(json, 'leadCount') ? undefined : json['leadCount'],
        'audiences': !exists(json, 'audiences') ? undefined : (json['audiences'].map(ApiAudienceFromJSON)),
        'urls': !exists(json, 'urls') ? undefined : (json['urls'].map(ApiCampaignSplitUrlFromJSON)),
        'fallbackUrl': !exists(json, 'fallbackUrl') ? undefined : json['fallbackUrl'],
        'landingPath': !exists(json, 'landingPath') ? undefined : ApiLandingPathFromJSON(json['landingPath']),
        'landingDomainId': !exists(json, 'landingDomainId') ? undefined : json['landingDomainId'],
        'landingDomain': !exists(json, 'landingDomain') ? undefined : ApiLandingDomainFromJSON(json['landingDomain']),
        'noCache': !exists(json, 'noCache') ? undefined : json['noCache'],
        'pending': !exists(json, 'pending') ? undefined : (new Date(json['pending'])),
    };
}
export function ApiCampaignToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'project': ApiProjectToJSON(value.project),
        'projectId': value.projectId,
        'name': value.name,
        'description': value.description,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'status': ApiCampaignStatusToJSON(value.status),
        'type': ApiCampaignTypeToJSON(value.type),
        'maxNumberOfLeads': value.maxNumberOfLeads,
        'probability': value.probability,
        'personalisations': value.personalisations === undefined ? undefined : (value.personalisations.map(ApiPersonalisationToJSON)),
        'ownerId': value.ownerId,
        'owner': ApiUserToJSON(value.owner),
        'lastUserId': value.lastUserId,
        'lastUser': ApiUserToJSON(value.lastUser),
        'leads': value.leads === undefined ? undefined : (value.leads.map(ApiCampaignLeadToJSON)),
        'leadCount': value.leadCount,
        'audiences': value.audiences === undefined ? undefined : (value.audiences.map(ApiAudienceToJSON)),
        'urls': value.urls === undefined ? undefined : (value.urls.map(ApiCampaignSplitUrlToJSON)),
        'fallbackUrl': value.fallbackUrl,
        'landingPath': ApiLandingPathToJSON(value.landingPath),
        'landingDomainId': value.landingDomainId,
        'landingDomain': ApiLandingDomainToJSON(value.landingDomain),
        'noCache': value.noCache,
        'pending': value.pending === undefined ? undefined : (value.pending.toISOString()),
    };
}
