/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiReservedPageviewMetaFromJSON, ApiReservedPageviewMetaToJSON, } from './ApiReservedPageviewMeta';
import { ApiReservedPageviewMetaSelectorTypeFromJSON, ApiReservedPageviewMetaSelectorTypeToJSON, } from './ApiReservedPageviewMetaSelectorType';
export function ApiPageviewMetaTaggerConfigFromJSON(json) {
    return ApiPageviewMetaTaggerConfigFromJSONTyped(json, false);
}
export function ApiPageviewMetaTaggerConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'taggerKey': !exists(json, 'taggerKey') ? undefined : json['taggerKey'],
        'reserved': !exists(json, 'reserved') ? undefined : ApiReservedPageviewMetaFromJSON(json['reserved']),
        'selector': !exists(json, 'selector') ? undefined : json['selector'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'selectorType': !exists(json, 'selectorType') ? undefined : ApiReservedPageviewMetaSelectorTypeFromJSON(json['selectorType']),
    };
}
export function ApiPageviewMetaTaggerConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'taggerKey': value.taggerKey,
        'reserved': ApiReservedPageviewMetaToJSON(value.reserved),
        'selector': value.selector,
        'value': value.value,
        'selectorType': ApiReservedPageviewMetaSelectorTypeToJSON(value.selectorType),
    };
}
