/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiConversionFromJSON, ApiConversionToJSON, } from './ApiConversion';
import { ApiConversionStateFromJSON, ApiConversionStateToJSON, } from './ApiConversionState';
import { ApiDataFromJSON, ApiDataToJSON, } from './ApiData';
import { ApiLeadFromJSON, ApiLeadToJSON, } from './ApiLead';
import { ApiLeadConversionStatusFromJSON, ApiLeadConversionStatusToJSON, } from './ApiLeadConversionStatus';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
import { ApiSaleFromJSON, ApiSaleToJSON, } from './ApiSale';
import { ApiSessionFromJSON, ApiSessionToJSON, } from './ApiSession';
export function ApiLeadConversionFromJSON(json) {
    return ApiLeadConversionFromJSONTyped(json, false);
}
export function ApiLeadConversionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'lead': !exists(json, 'lead') ? undefined : ApiLeadFromJSON(json['lead']),
        'data': !exists(json, 'data') ? undefined : ApiDataFromJSON(json['data']),
        'session': !exists(json, 'session') ? undefined : ApiSessionFromJSON(json['session']),
        'conversion': !exists(json, 'conversion') ? undefined : ApiConversionFromJSON(json['conversion']),
        'state': !exists(json, 'state') ? undefined : ApiConversionStateFromJSON(json['state']),
        'cancelled': !exists(json, 'cancelled') ? undefined : json['cancelled'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'sale': !exists(json, 'sale') ? undefined : ApiSaleFromJSON(json['sale']),
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'leadId': !exists(json, 'leadId') ? undefined : json['leadId'],
        'conversionId': !exists(json, 'conversionId') ? undefined : json['conversionId'],
        'conversionStateId': !exists(json, 'conversionStateId') ? undefined : json['conversionStateId'],
        'saleId': !exists(json, 'saleId') ? undefined : json['saleId'],
        'dataId': !exists(json, 'dataId') ? undefined : json['dataId'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'sessionId': !exists(json, 'sessionId') ? undefined : json['sessionId'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'analyticsSynced': !exists(json, 'analyticsSynced') ? undefined : json['analyticsSynced'],
        'rand': !exists(json, 'rand') ? undefined : json['rand'],
        'status': !exists(json, 'status') ? undefined : ApiLeadConversionStatusFromJSON(json['status']),
    };
}
export function ApiLeadConversionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'lead': ApiLeadToJSON(value.lead),
        'data': ApiDataToJSON(value.data),
        'session': ApiSessionToJSON(value.session),
        'conversion': ApiConversionToJSON(value.conversion),
        'state': ApiConversionStateToJSON(value.state),
        'cancelled': value.cancelled,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'sale': ApiSaleToJSON(value.sale),
        'project': ApiProjectToJSON(value.project),
        'leadId': value.leadId,
        'conversionId': value.conversionId,
        'conversionStateId': value.conversionStateId,
        'saleId': value.saleId,
        'dataId': value.dataId,
        'projectId': value.projectId,
        'sessionId': value.sessionId,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'analyticsSynced': value.analyticsSynced,
        'rand': value.rand,
        'status': ApiLeadConversionStatusToJSON(value.status),
    };
}
