import { useSelector } from "react-redux";
import { chartSelector } from "Selectors/chartSelector";
import { useClientIdFromUrl } from "./useClientIdFromUrl";
import { useProjectIdFromUrl } from "./useProjectIdFromUrl";
import { useMatchPath } from "Hooks/useMatchPath";

const useChartPath = () => {
  return useMatchPath<{
    clientId: string;
    projectId: string;
    chartId: string;
  }>(
    "/clients/:clientId/projects/:projectId/analyse/reports/chart-library/:chartId"
  );
};

const useChartIdFromUrl = () => {
  const path = useChartPath();
  return path?.params.chartId;
};

export const useChartSelector = () => {
  const clientId = useClientIdFromUrl();
  const projectId = useProjectIdFromUrl();
  const chartId = useChartIdFromUrl();

  const chart = useSelector(chartSelector(clientId, projectId, chartId));
  return chart;
};
