import {
  ApiProject,
  ApiProjectSetting,
  ApiProjectSettingKey,
  ApiProjectSettingPayload,
} from "@incendium/api";
import { projectService } from "Apis";
import { TProjectSettings } from "Hooks";

interface ISettingPayload extends ApiProjectSetting {
  body: ApiProjectSettingPayload;
}

export const saveProjectSettings = async (
  projectSettings: TProjectSettings,
  project: ApiProject
) => {
  const payloads: ISettingPayload[] = Object.keys(projectSettings).map(
    (key) => {
      return {
        body: {
          key: key as ApiProjectSettingKey,
          value: projectSettings[key as ApiProjectSettingKey],
        },
      };
    }
  );

  return Promise.all(
    payloads
      .filter((s) => s.body.value !== "" || s.id)
      .map((setting: ISettingPayload) => {
        return saveProjectSetting(setting.body, project);
      })
  );
};

export const saveProjectSetting = async (
  payload: ApiProjectSettingPayload,
  project: ApiProject
) => {
  if (!project.settings || project.settings.length === 0) {
    return;
  }
  const setting = (project.settings || []).find((s) => s.key === payload.key);

  const fn = setting?.id
    ? projectService.projectsServiceUpdateProjectSetting({
        clientId: project?.clientId as number,
        projectId: project?.id as number,
        settingId: setting.id,
        payload,
      })
    : projectService.projectsServiceCreateProjectSetting({
        clientId: project?.clientId as number,
        projectId: project?.id as number,
        payload,
      });

  const res = await fn;
  return res;
};
