/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiAttributionClickDateGroupingFromJSON, ApiAttributionClickDateGroupingToJSON, } from './ApiAttributionClickDateGrouping';
import { ApiAttributionClickStatusFromJSON, ApiAttributionClickStatusToJSON, } from './ApiAttributionClickStatus';
import { ApiCSVUploadSessionFromJSON, ApiCSVUploadSessionToJSON, } from './ApiCSVUploadSession';
import { ApiCurrencyFromJSON, ApiCurrencyToJSON, } from './ApiCurrency';
import { ApiExternalAdGroupFromJSON, ApiExternalAdGroupToJSON, } from './ApiExternalAdGroup';
import { ApiExternalCampaignFromJSON, ApiExternalCampaignToJSON, } from './ApiExternalCampaign';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
import { ApiSessionFromJSON, ApiSessionToJSON, } from './ApiSession';
import { ApiSourceFromJSON, ApiSourceToJSON, } from './ApiSource';
export function ApiAttributionClickFromJSON(json) {
    return ApiAttributionClickFromJSONTyped(json, false);
}
export function ApiAttributionClickFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'dateGrouping': !exists(json, 'dateGrouping') ? undefined : ApiAttributionClickDateGroupingFromJSON(json['dateGrouping']),
        'cost': !exists(json, 'cost') ? undefined : json['cost'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'currency': !exists(json, 'currency') ? undefined : ApiCurrencyFromJSON(json['currency']),
        'sources': !exists(json, 'sources') ? undefined : (json['sources'].map(ApiSourceFromJSON)),
        'term': !exists(json, 'term') ? undefined : json['term'],
        'campaign': !exists(json, 'campaign') ? undefined : json['campaign'],
        'medium': !exists(json, 'medium') ? undefined : json['medium'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'channel': !exists(json, 'channel') ? undefined : json['channel'],
        'status': !exists(json, 'status') ? undefined : ApiAttributionClickStatusFromJSON(json['status']),
        'uploadSession': !exists(json, 'uploadSession') ? undefined : ApiCSVUploadSessionFromJSON(json['uploadSession']),
        'sync': !exists(json, 'sync') ? undefined : ApiCSVUploadSessionFromJSON(json['sync']),
        'syncId': !exists(json, 'syncId') ? undefined : json['syncId'],
        'externalCampaign': !exists(json, 'externalCampaign') ? undefined : ApiExternalCampaignFromJSON(json['externalCampaign']),
        'externalCampaignId': !exists(json, 'externalCampaignId') ? undefined : json['externalCampaignId'],
        'averageSessionCost': !exists(json, 'averageSessionCost') ? undefined : json['averageSessionCost'],
        'sessionsNumber': !exists(json, 'sessionsNumber') ? undefined : json['sessionsNumber'],
        'sessions': !exists(json, 'sessions') ? undefined : (json['sessions'].map(ApiSessionFromJSON)),
        'adGroup': !exists(json, 'adGroup') ? undefined : ApiExternalAdGroupFromJSON(json['adGroup']),
        'externalAdGroupId': !exists(json, 'externalAdGroupId') ? undefined : json['externalAdGroupId'],
        'analyticsSynced': !exists(json, 'analyticsSynced') ? undefined : json['analyticsSynced'],
        'conversionsCount': !exists(json, 'conversionsCount') ? undefined : json['conversionsCount'],
        'allConversionsCount': !exists(json, 'allConversionsCount') ? undefined : json['allConversionsCount'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'csvUploadSessionId': !exists(json, 'csvUploadSessionId') ? undefined : json['csvUploadSessionId'],
        'sourceNameId': !exists(json, 'sourceNameId') ? undefined : json['sourceNameId'],
        'mediumNameId': !exists(json, 'mediumNameId') ? undefined : json['mediumNameId'],
        'campaignNameId': !exists(json, 'campaignNameId') ? undefined : json['campaignNameId'],
        'termNameId': !exists(json, 'termNameId') ? undefined : json['termNameId'],
        'contentNameId': !exists(json, 'contentNameId') ? undefined : json['contentNameId'],
        'impressions': !exists(json, 'impressions') ? undefined : json['impressions'],
    };
}
export function ApiAttributionClickToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'project': ApiProjectToJSON(value.project),
        'projectId': value.projectId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'dateGrouping': ApiAttributionClickDateGroupingToJSON(value.dateGrouping),
        'cost': value.cost,
        'count': value.count,
        'name': value.name,
        'currency': ApiCurrencyToJSON(value.currency),
        'sources': value.sources === undefined ? undefined : (value.sources.map(ApiSourceToJSON)),
        'term': value.term,
        'campaign': value.campaign,
        'medium': value.medium,
        'source': value.source,
        'channel': value.channel,
        'status': ApiAttributionClickStatusToJSON(value.status),
        'uploadSession': ApiCSVUploadSessionToJSON(value.uploadSession),
        'sync': ApiCSVUploadSessionToJSON(value.sync),
        'syncId': value.syncId,
        'externalCampaign': ApiExternalCampaignToJSON(value.externalCampaign),
        'externalCampaignId': value.externalCampaignId,
        'averageSessionCost': value.averageSessionCost,
        'sessionsNumber': value.sessionsNumber,
        'sessions': value.sessions === undefined ? undefined : (value.sessions.map(ApiSessionToJSON)),
        'adGroup': ApiExternalAdGroupToJSON(value.adGroup),
        'externalAdGroupId': value.externalAdGroupId,
        'analyticsSynced': value.analyticsSynced,
        'conversionsCount': value.conversionsCount,
        'allConversionsCount': value.allConversionsCount,
        'content': value.content,
        'csvUploadSessionId': value.csvUploadSessionId,
        'sourceNameId': value.sourceNameId,
        'mediumNameId': value.mediumNameId,
        'campaignNameId': value.campaignNameId,
        'termNameId': value.termNameId,
        'contentNameId': value.contentNameId,
        'impressions': value.impressions,
    };
}
