/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function ApiStaticAudienceFromJSON(json) {
    return ApiStaticAudienceFromJSONTyped(json, false);
}
export function ApiStaticAudienceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'audienceId': !exists(json, 'audienceId') ? undefined : json['audienceId'],
        'leadId': !exists(json, 'leadId') ? undefined : json['leadId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
    };
}
export function ApiStaticAudienceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'projectId': value.projectId,
        'audienceId': value.audienceId,
        'leadId': value.leadId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}
