/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiConversionStateFromJSON, ApiConversionStateToJSON, } from './ApiConversionState';
import { ApiConversionTypeFromJSON, ApiConversionTypeToJSON, } from './ApiConversionType';
import { ApiLocationFromJSON, ApiLocationToJSON, } from './ApiLocation';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
import { ApiProjectDefaultFromJSON, ApiProjectDefaultToJSON, } from './ApiProjectDefault';
import { ApiReservedConversionFromJSON, ApiReservedConversionToJSON, } from './ApiReservedConversion';
export function ApiConversionFromJSON(json) {
    return ApiConversionFromJSONTyped(json, false);
}
export function ApiConversionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'location': !exists(json, 'location') ? undefined : ApiLocationFromJSON(json['location']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
        'states': !exists(json, 'states') ? undefined : (json['states'].map(ApiConversionStateFromJSON)),
        '_default': !exists(json, 'default') ? undefined : ApiProjectDefaultFromJSON(json['default']),
        'locationSiblings': !exists(json, 'locationSiblings') ? undefined : json['locationSiblings'],
        'type': !exists(json, 'type') ? undefined : ApiConversionTypeFromJSON(json['type']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'reserved': !exists(json, 'reserved') ? undefined : ApiReservedConversionFromJSON(json['reserved']),
        'lastFiredAt': !exists(json, 'lastFiredAt') ? undefined : (new Date(json['lastFiredAt'])),
        'pushToMeta': !exists(json, 'pushToMeta') ? undefined : json['pushToMeta'],
    };
}
export function ApiConversionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'project': ApiProjectToJSON(value.project),
        'location': ApiLocationToJSON(value.location),
        'name': value.name,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'states': value.states === undefined ? undefined : (value.states.map(ApiConversionStateToJSON)),
        'default': ApiProjectDefaultToJSON(value._default),
        'locationSiblings': value.locationSiblings,
        'type': ApiConversionTypeToJSON(value.type),
        'projectId': value.projectId,
        'locationId': value.locationId,
        'reserved': ApiReservedConversionToJSON(value.reserved),
        'lastFiredAt': value.lastFiredAt === undefined ? undefined : (value.lastFiredAt.toISOString()),
        'pushToMeta': value.pushToMeta,
    };
}
