import { ApiDimension } from "@incendium/api";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Autocomplete, IconButton, Stack, TextField } from "@mui/material";
import { IChart } from "Interfaces";
import {
  NOT_SET,
  useFilterableDimensions,
  dimensionToName,
} from "features/analytics";
import produce from "immer";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useUpdateEffect } from "react-use";

interface IAnalyticsFilterDropdownProps {
  dimension: ApiDimension;
  setChart?: React.Dispatch<React.SetStateAction<IChart>>;
  defaultFirstFilter?: { [key in ApiDimension]?: string };
  isReady?: () => void;
  onChange?: (e: string) => void;
  defaultIndex?: number | ((a: string[]) => number);
  maxWidth?: number;
  disableBlank?: boolean;
}

function AnalyticsFilterDropdown({
  dimension,
  setChart,
  defaultFirstFilter,
  isReady,
  onChange,
  defaultIndex,
  maxWidth,
  disableBlank,
}: IAnalyticsFilterDropdownProps) {
  const { filterableDimensions, loading } = useFilterableDimensions(dimension);
  const [selectedValue, setSelectedValue] = useState("");
  const [index, setIndex] = useState(
    typeof defaultIndex === "number" ? defaultIndex : -1
  );
  const [defaultSet, setDefaultSet] = useState(false);

  const availableOptions = useMemo(() => {
    return (filterableDimensions[dimension] || []).filter((f) => f !== NOT_SET);
  }, [filterableDimensions, dimension]);

  useUpdateEffect(() => {
    if (
      typeof defaultIndex === "function" &&
      defaultIndex(availableOptions) !== index
    ) {
      setIndex(defaultIndex(availableOptions));
    }
  }, [defaultIndex]);

  useEffect(() => {
    if (loading || index >= 0 || defaultSet) {
      return;
    }
    if (defaultFirstFilter && defaultFirstFilter[dimension]) {
      setIndex(
        availableOptions.findIndex((a) => a === defaultFirstFilter[dimension])
      );
    } else if (typeof defaultIndex === "function") {
      setIndex(defaultIndex(availableOptions));
    }
    setDefaultSet(true);
    isReady && isReady();
  }, [
    loading,
    availableOptions,
    defaultFirstFilter,
    defaultIndex,
    dimension,
    index,
    isReady,
    defaultSet,
  ]);

  useUpdateEffect(() => {
    if (!onChange && !setChart) {
      return;
    }
    if (onChange) {
      onChange(selectedValue);
      return;
    }
    if (setChart) {
      setChart((chart) =>
        produce(chart, (draft) => {
          const idx = (draft.attributes || []).findIndex(
            (f) => f.key === dimension
          );
          if (idx >= 0) {
            if (!selectedValue) {
              draft.attributes.splice(idx, 1);
              return;
            }

            draft.attributes[idx] = {
              ...draft.attributes[idx],
              value: selectedValue,
            };
          } else {
            draft.attributes.push({
              key: dimension,
              value: selectedValue,
            });
          }
        })
      );
    }
  }, [selectedValue]);

  const onCycle = useCallback(
    (direction: "left" | "right") => {
      setIndex((prev) => {
        const newIndex = direction === "right" ? prev + 1 : prev - 1;
        if (newIndex > availableOptions.length - 1) {
          return 0;
        }
        if (newIndex < 0) {
          return availableOptions.length - 1;
        }
        return newIndex;
      });
    },
    [availableOptions]
  );

  useEffect(() => {
    const value = availableOptions[index];
    setSelectedValue(value !== "All Users" ? value : "");
  }, [index, availableOptions]);

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      sx={{
        maxWidth: maxWidth || "100%",
        width: maxWidth || 264,
        minWidth: 160,
      }}
    >
      <IconButton
        size="small"
        onClick={() => onCycle("left")}
        color="secondary"
        disabled={loading}
      >
        <ChevronLeft />
      </IconButton>
      <Autocomplete
        id="att-value"
        loading={loading}
        disabled={loading}
        onChange={(e, v) => {
          const index = availableOptions.findIndex((a) => a === v);
          setIndex(index);
        }}
        value={loading ? "" : selectedValue ? selectedValue : "All Users"}
        filterSelectedOptions
        disableClearable={disableBlank}
        sx={{ flex: 1 }}
        fullWidth
        options={
          disableBlank ? availableOptions : ["All Users", ...availableOptions]
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={`Select ${dimensionToName(dimension)}`}
            variant="outlined"
            size="small"
          />
        )}
      />
      <IconButton
        size="small"
        onClick={() => onCycle("right")}
        color="secondary"
        disabled={loading}
      >
        <ChevronRight />
      </IconButton>
    </Stack>
  );
}

export default AnalyticsFilterDropdown;
