import { ApiConversion, ApiProject } from "@incendium/api";
import { projectService } from "Apis";
import { useSharedState } from "./useSharedState";

const fetchConversions = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const conversions =
      await projectService.projectsServiceListProjectConversions({
        clientId: selectedProject.clientId as number,
        projectId: selectedProject.id as number,
      });
    return conversions.results || [];
  }
  return [];
};

export const useConversions = () => {
  const { state, setState, refetch } = useSharedState(
    "conversions",
    [],
    fetchConversions
  );

  return {
    conversions: state as ApiConversion[],
    setConversions: setState,
    refetch,
  };
};
