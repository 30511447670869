import { Assignment, AssignmentTurnedIn } from "@mui/icons-material";
import { Button, ButtonProps, IconButton } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";

interface ICopyClipboardBtnProps extends ButtonProps {
  copied?: boolean;
  setCopied?: (value: React.SetStateAction<boolean>) => void;
  text: string;
  stopPropagation?: boolean;
  isIconButton?: boolean;
}

function CopyClipboardBtn({
  copied,
  setCopied,
  text,
  children,
  stopPropagation,
  isIconButton,
  size: sizeProp,
  ...rest
}: ICopyClipboardBtnProps) {
  const [internalCopied, setInternalCopied] = useState(
    typeof copied !== "undefined" ? copied : false
  );

  const size = useMemo(
    () => (isIconButton && sizeProp === "extraSmall" ? "small" : sizeProp),
    [isIconButton, sizeProp]
  );

  useEffect(() => {
    setCopied && setCopied(internalCopied);
  }, [internalCopied, setCopied]);

  const handleClick = useCallback(
    (e) => {
      if (stopPropagation) {
        e.stopPropagation();
      }

      navigator.clipboard.writeText(text);
      setInternalCopied(true);
    },
    [stopPropagation, text]
  );

  if (isIconButton) {
    return (
      <IconButton
        {...rest}
        size={size as any}
        onClick={handleClick}
        edge="start"
      >
        {internalCopied ? (
          <AssignmentTurnedIn fontSize="inherit" />
        ) : (
          <Assignment fontSize="inherit" />
        )}
      </IconButton>
    );
  }

  return (
    <Button
      {...rest}
      onClick={handleClick}
      size={size}
      endIcon={internalCopied ? <AssignmentTurnedIn /> : <Assignment />}
    >
      {children}
    </Button>
  );
}

export default CopyClipboardBtn;
