import { ApiPageTag } from "@incendium/api";
import { Autocomplete, TextField, OutlinedTextFieldProps } from "@mui/material";
import { usePageTaggers } from "Hooks/useLocationPageTaggers";
import { useMemo } from "react";

type TValue = {
  locationPageTaggerId?: number;
  pageTagValue?: string;
};

interface IPageDataDropdownProps
  extends Omit<OutlinedTextFieldProps, "onChange"> {
  value?: TValue;
  onChange: (v?: TValue) => void;
}

interface IPageData extends ApiPageTag {
  name?: string;
}

function PageDataDropdown({
  label,
  size,
  variant,
  value,
  onChange,
  fullWidth,
  sx,
}: IPageDataDropdownProps) {
  const { pageTaggers } = usePageTaggers();

  const options: IPageData[] = useMemo(
    () =>
      (pageTaggers || [])
        .reduce(
          (agg, v) => [
            ...agg,
            ...(v.pageTags || []).map((t) => ({
              name: v.name || "",
              ...t,
            })),
          ],
          [] as IPageData[]
        )
        .flat(1),
    [pageTaggers]
  );

  const selected = useMemo(
    () =>
      (options || []).find(
        (l) =>
          l.locationPageTaggerId === value?.locationPageTaggerId &&
          l.value === value?.pageTagValue
      ) || {},
    [options, value]
  );
  return (
    <Autocomplete
      sx={sx}
      fullWidth={fullWidth}
      options={options}
      value={selected}
      onChange={(e, v) => {
        onChange({
          locationPageTaggerId: v?.locationPageTaggerId,
          pageTagValue: v?.value,
        });
      }}
      groupBy={(o) => o.name || ""}
      getOptionLabel={(o: IPageData) => o.value || ""}
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant} size={size} />
      )}
    />
  );
}

export default PageDataDropdown;
