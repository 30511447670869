import {
  ApiLocation,
  ApiForm,
  ApiProject,
  ApiFormRule,
  ApiAction,
} from "@incendium/api";
import { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { locationService } from "Apis";
import { useNotification } from "Hooks";
import { useEffect } from "react";
import { FormList } from "Components/FormList";
import { FormHandlersForm } from "Components/FormHandlersForm";
import { useClientIdFromUrl } from "Hooks";

export const FormHandlersTab = ({
  location,
  project,
}: {
  location: ApiLocation;
  project: ApiProject;
}) => {
  const clientId = useClientIdFromUrl();
  const [isReady, setIsReady] = useState(false);
  const [forms, setForms] = useState<ApiForm[]>([]);
  const [selectedForm, setSelectedForm] = useState<string | null | ApiForm>(
    null
  );
  const { showSuccessNotification, showErrorNotification } = useNotification();

  const fetchAllForms = async () => {
    await locationService
      .locationServiceListForms({
        locationId: location.id as number,
        projectId: project.id as number,
        clientId: Number(clientId),
      })
      .then((result) => {
        if (result.results) {
          setForms(result.results);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    fetchAllForms().then(() => setIsReady(true));
  }, []);

  const createFormRules = async (rules: ApiFormRule[], formId: number) => {
    const requests = rules.map(({ id, ...rule }) =>
      locationService.locationServiceCreateFormRule({
        locationId: location.id as number,
        projectId: project.id as number,
        clientId: Number(clientId),
        formId,
        payload: rule,
      })
    );
    try {
      await Promise.all(requests);
      await fetchAllForms();
    } catch (err) {
      console.error(err);
    }
  };

  const createFormActions = async (rules: ApiAction[], formId: number) => {
    const requests = rules.map(({ id, ...rule }) =>
      locationService.locationServiceCreateFormAction({
        locationId: location.id as number,
        projectId: project.id as number,
        clientId: Number(clientId),
        formId,
        payload: rule,
      })
    );
    try {
      await Promise.all(requests);
      await fetchAllForms();
    } catch (err) {
      console.error(err);
    }
  };

  const deleteFormRules = async (rules: ApiFormRule[], formId: number) => {
    const requests = rules.map((rule) =>
      locationService.locationServiceDeleteFormRule({
        locationId: location.id as number,
        projectId: project.id as number,
        clientId: Number(clientId),
        formId,
        formRuleId: rule.id as number,
      })
    );
    try {
      await Promise.all(requests);
      await fetchAllForms();
    } catch (err) {
      console.error(err);
    }
  };

  const deleteFormActions = async (actions: ApiAction[], formId: number) => {
    const requests = actions.map((action) =>
      locationService.locationServiceDeleteFormAction({
        locationId: location.id as number,
        projectId: project.id as number,
        clientId: Number(clientId),
        formId,
        formActionId: action.id as number,
      })
    );
    try {
      await Promise.all(requests);
      await fetchAllForms();
    } catch (err) {
      console.error(err);
    }
  };

  const updateFormRules = async (rules: ApiFormRule[], formId: number) => {
    const requests = rules.map(({ id, ...rule }) =>
      locationService.locationServiceUpdateFormRule({
        locationId: location.id as number,
        projectId: project.id as number,
        clientId: Number(clientId),
        formId,
        formRuleId: id as number,
        payload: rule,
      })
    );
    try {
      await Promise.all(requests);
      await fetchAllForms();
    } catch (err) {
      console.error(err);
    }
  };

  const updateFormActions = async (actions: ApiAction[], formId: number) => {
    const requests = actions.map(({ id, ...action }) =>
      locationService.locationServiceUpdateFormAction({
        locationId: location.id as number,
        projectId: project.id as number,
        clientId: Number(clientId),
        formId,
        formActionId: id as number,
        payload: action,
      })
    );
    try {
      await Promise.all(requests);
      await fetchAllForms();
    } catch (err) {
      console.error(err);
    }
  };

  const handleCreateForm = async (
    { name, target, preventSubmit }: ApiForm,
    rulesToCreate: ApiFormRule[],
    actionsToCreate: ApiAction[]
  ) => {
    try {
      const formResponse = await locationService.locationServiceCreateForm({
        locationId: location.id as number,
        projectId: project.id as number,
        clientId: Number(clientId),
        payload: {
          target,
          name,
          preventSubmit: preventSubmit,
        },
      });
      await createFormRules(rulesToCreate, formResponse.id as number);
      await createFormActions(actionsToCreate, formResponse.id as number);
      await fetchAllForms();
      setSelectedForm(null);
      showSuccessNotification("Form created");
    } catch (err) {
      showErrorNotification("Failed to create form");
    }
  };
  const handleEditForm = async (
    { name, target, preventSubmit }: ApiForm,
    formId: number,
    rulesToCreate: ApiFormRule[],
    rulesToDelete: ApiFormRule[],
    rulesToUpdate: ApiFormRule[],
    actionsToCreate: ApiAction[],
    actionsToDelete: ApiAction[],
    actionsToUpdate: ApiAction[]
  ) => {
    try {
      await locationService.locationServiceUpdateForm({
        locationId: location.id as number,
        projectId: project.id as number,
        clientId: Number(clientId),
        formId: formId as number,
        payload: {
          target,
          name,
          preventSubmit: preventSubmit,
        },
      });
      await createFormRules(rulesToCreate, formId);
      await deleteFormRules(rulesToDelete, formId);
      await updateFormRules(rulesToUpdate, formId);
      await createFormActions(actionsToCreate, formId);
      await deleteFormActions(actionsToDelete, formId);
      await updateFormActions(actionsToUpdate, formId);
      await fetchAllForms();
      setSelectedForm(null);
      showSuccessNotification("Form Updated");
    } catch (e) {
      showErrorNotification("Failed to edit Conversion");
    }
  };
  const handleDeleteForm = async (form: ApiForm) => {
    try {
      await locationService.locationServiceDeleteForm({
        locationId: location.id as number,
        projectId: project.id as number,
        clientId: Number(clientId),
        formId: form.id as number,
      });
      await fetchAllForms();
      showSuccessNotification("Form Deleted");
    } catch (e) {
      showErrorNotification("Failed to delete Form");
    }
  };

  if (!isReady) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={"2rem"} />
      </Box>
    );
  }

  if (selectedForm === null) {
    return (
      <FormList
        forms={forms}
        onAdd={() => setSelectedForm("new")}
        onEdit={(form) => {
          setSelectedForm(form);
        }}
        onDelete={handleDeleteForm}
      />
    );
  }

  if (selectedForm) {
    return (
      <FormHandlersForm
        form={typeof selectedForm === "string" ? undefined : selectedForm}
        onEdit={handleEditForm}
        onCancel={() => setSelectedForm(null)}
        onAdd={handleCreateForm}
      />
    );
  }
  return null;
};
