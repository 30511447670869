/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiDomainType;
(function (ApiDomainType) {
    ApiDomainType["DOMAIN_TYPE_REGULAR"] = "DomainTypeRegular";
    ApiDomainType["DOMAIN_TYPE_SPLIT"] = "DomainTypeSplit";
})(ApiDomainType || (ApiDomainType = {}));
export function ApiDomainTypeFromJSON(json) {
    return ApiDomainTypeFromJSONTyped(json, false);
}
export function ApiDomainTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiDomainTypeToJSON(value) {
    return value;
}
