import { ApiReportResponse } from "@incendium/api";
import CreateNameDialog from "Components/CreateNameDialog/CreateNameDialog";
import {
  deleteReport,
  pinReportToHome,
  ReportsList,
  saveReport,
  saveReportTab,
  toggleReportFavourite,
} from "features/reports";
import { useNotification, useSelectedProject } from "Hooks";
import { useReports } from "Hooks/useReports";
import produce from "immer";
import { useConfirmationContext } from "Providers/ConfirmationProvider";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

function ReportsPage() {
  const { reports, setReports } = useReports();
  const [open, setOpen] = useState(false);
  const { selectedProject: project } = useSelectedProject();
  const history = useHistory();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { showSuccessNotification, showErrorNotification } = useNotification();

  const onSaved = useCallback(
    async (name: string) => {
      const res = await saveReport(project?.id as number, {
        name,
      });

      await saveReportTab(project?.id as number, res.id as number, {
        name: "Tab 1",
      });

      setReports(
        produce(reports, (draft) => {
          draft.push(res);
        })
      );
      showSuccessNotification(`Response (${name}) Created, redirecting`);
      history.push(`reports/${res.id}?edit`);
    },
    [showSuccessNotification, history, project, reports, setReports]
  );

  const onDelete = useCallback(
    (r: ApiReportResponse) => {
      openConfirmation({
        title: `Are you sure you want to delete this Report`,
        body: `This action can not be undone`,
        callback: async () => {
          await deleteReport(project?.id as number, r.id as number);
          setReports(
            produce(reports, (draft) => {
              const idx = draft.findIndex((report) => r.id === report.id);
              if (idx !== -1) draft.splice(idx, 1);
            })
          );

          showSuccessNotification(`${r.name} Deleted`);
          closeConfirmation();
        },
      });
    },
    [
      showSuccessNotification,
      closeConfirmation,
      openConfirmation,
      project?.id,
      reports,
      setReports,
    ]
  );
  const onFavourite = useCallback(
    async (report: ApiReportResponse) => {
      try {
        const res = await toggleReportFavourite(
          project?.id as number,
          report.id!
        );

        setReports((preReports) =>
          produce(preReports, (draft) => {
            const idx = draft.findIndex((d) => d.id === res.id);
            if (idx >= 0) {
              draft[idx].favourite = res.favourite;
            }
          })
        );
        showSuccessNotification(
          `${res.name} ${
            res.favourite ? "Added to" : "Removed From"
          } Favourites`
        );
      } catch (error) {
        showErrorNotification(
          `Internal Error pinning report, please try again`
        );
      }
    },
    [project?.id, setReports, showErrorNotification, showSuccessNotification]
  );

  const onPin = useCallback(
    async (report, checked) => {
      try {
        const res = await pinReportToHome(report.projectId, report.id);
        setReports((preReports) =>
          produce(preReports, (draft) => {
            draft.forEach((r) => {
              r.pinToHome = checked && r.id === res.id;
            });
          })
        );
        showSuccessNotification(
          `${res.name} ${
            checked ? "Added to" : "Removed From"
          } Analyse Homepage`
        );
      } catch (error) {
        showErrorNotification(
          `Internal Error pinning report, please try again`
        );
      }
    },
    [showSuccessNotification, showErrorNotification, setReports]
  );

  const onEdit = useCallback(
    (id) => {
      history.push(`reports/${id}`);
    },
    [history]
  );

  return (
    <>
      <ReportsList
        reports={reports}
        onClick={() => setOpen(true)}
        onEdit={onEdit}
        onPin={onPin}
        onDelete={onDelete}
        onFavourite={onFavourite}
      />
      <CreateNameDialog
        open={open}
        setOpen={setOpen}
        title="Create New Report"
        onSaved={onSaved}
      />
    </>
  );
}

export default ReportsPage;
