/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiClassificationRulePayloadFromJSON, ApiClassificationRulePayloadToJSON, } from './ApiClassificationRulePayload';
export function ApiClassificationItemPayloadFromJSON(json) {
    return ApiClassificationItemPayloadFromJSONTyped(json, false);
}
export function ApiClassificationItemPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'value': !exists(json, 'value') ? undefined : json['value'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'rules': !exists(json, 'rules') ? undefined : (json['rules'].map(ApiClassificationRulePayloadFromJSON)),
    };
}
export function ApiClassificationItemPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'value': value.value,
        'description': value.description,
        'order': value.order,
        'rules': value.rules === undefined ? undefined : (value.rules.map(ApiClassificationRulePayloadToJSON)),
    };
}
