import { ApiChartTemplate } from "@incendium/api";
import { TChartData } from "Interfaces";
import theme from "theme";
import { percentageChange } from "./percentage";
import { ReactComponent as TrendIcon } from "Assets/Images/symbol-data-trend.svg";
import { ReactComponent as SnapShotIcon } from "Assets/Images/symbol-data-snapshot.svg";
import { ReactComponent as MapIcon } from "Assets/Images/symbol-data-map.svg";
import { ReactComponent as TableIcon } from "Assets/Images/symbol-data-table.svg";
import { ReactComponent as AttributionIcon } from "Assets/Images/symbol-data-attribution.svg";
import { formatNumber } from "./numbers";

export const chartDataWithCompare = (
  dd: TChartData[],
  mapped: TChartData[]
): TChartData[] => {
  const negativeIsGood = ["spend", "CAC", "CPNV"];

  const upOrDown = (name: string, change: number) => {
    const positiveTest = negativeIsGood.includes(name)
      ? Math.sign(change) < 0
      : Math.sign(change) > 0;
    const negativeTest = negativeIsGood.includes(name)
      ? Math.sign(change) > 0
      : Math.sign(change) < 0;

    return positiveTest ? "up" : negativeTest ? "down" : "same";
  };

  return dd.map((d) => {
    const { name, ...rest } = d;

    // find matching comp data
    const found = mapped.find((f) => f.name === d.name);

    // rest
    let newObj = Object.fromEntries(
      Object.entries(rest).map(([k, v]) => {
        if (found && found[k]) {
          const isPercentage = ["rate", "percentage"].some((v) =>
            k.toLowerCase().includes(v)
          );
          const change = isPercentage
            ? Number(v) - Number(found[k])
            : percentageChange(Number(found[k]), Number(v));

          return [
            k,
            {
              value: v,
              compare: {
                value: formatNumber(change),
                direction: upOrDown(k, change),
              },
            },
          ];
        }

        return [
          k,
          {
            value: v,
            compare: {
              value: "- ",
              direction: "same",
            },
          },
        ];
      })
    );

    return {
      name,
      ...newObj,
    };
  });
};

export const chartTemplateToColour = (template?: ApiChartTemplate) => {
  switch (template) {
    case ApiChartTemplate.TREND:
      return theme.palette.charts[3];
    case ApiChartTemplate.SNAPSHOT:
      return theme.palette.charts[4];
    case ApiChartTemplate.ATTRIBUTION:
      return theme.palette.charts[2];
    case ApiChartTemplate.TABLE:
      return theme.palette.charts[6];
    case ApiChartTemplate.MAP:
      return theme.palette.charts[7];

    default:
      return theme.palette.charts[0];
  }
};

export const chartTemplateToIcon = (template?: ApiChartTemplate) => {
  switch (template) {
    case ApiChartTemplate.TREND:
      return <TrendIcon />;
    case ApiChartTemplate.SNAPSHOT:
      return <SnapShotIcon />;
    case ApiChartTemplate.ATTRIBUTION:
      return <AttributionIcon />;
    case ApiChartTemplate.TABLE:
      return <TableIcon />;
    case ApiChartTemplate.MAP:
      return <MapIcon />;

    default:
      return <></>;
  }
};
