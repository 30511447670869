/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiLocationPageTaggerFromJSON, ApiLocationPageTaggerToJSON, } from './ApiLocationPageTagger';
import { ApiPageFromJSON, ApiPageToJSON, } from './ApiPage';
export function ApiPageTagFromJSON(json) {
    return ApiPageTagFromJSONTyped(json, false);
}
export function ApiPageTagFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'locationPageTagger': !exists(json, 'locationPageTagger') ? undefined : ApiLocationPageTaggerFromJSON(json['locationPageTagger']),
        'locationPageTaggerId': !exists(json, 'locationPageTaggerId') ? undefined : json['locationPageTaggerId'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'pages': !exists(json, 'pages') ? undefined : (json['pages'].map(ApiPageFromJSON)),
    };
}
export function ApiPageTagToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'locationPageTagger': ApiLocationPageTaggerToJSON(value.locationPageTagger),
        'locationPageTaggerId': value.locationPageTaggerId,
        'value': value.value,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'pages': value.pages === undefined ? undefined : (value.pages.map(ApiPageToJSON)),
    };
}
