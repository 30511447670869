/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiJobTaggerFromJSON, ApiJobTaggerPayloadToJSON, ApiListJobTaggersResponseFromJSON, ApiListJobsResponseFromJSON, } from '../models';
/**
 *
 */
export class JobServiceApi extends runtime.BaseAPI {
    /**
     */
    jobServiceCreateJobTaggerRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling jobServiceCreateJobTagger.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling jobServiceCreateJobTagger.');
            }
            const queryParameters = {};
            if (requestParameters.jobTaggerId !== undefined) {
                queryParameters['jobTaggerId'] = requestParameters.jobTaggerId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/job-tagger/v1/projects/{projectId}/job-taggers`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiJobTaggerPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiJobTaggerFromJSON(jsonValue));
        });
    }
    /**
     */
    jobServiceCreateJobTagger(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.jobServiceCreateJobTaggerRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    jobServiceDeleteJobTaggerRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling jobServiceDeleteJobTagger.');
            }
            if (requestParameters.jobTaggerId === null || requestParameters.jobTaggerId === undefined) {
                throw new runtime.RequiredError('jobTaggerId', 'Required parameter requestParameters.jobTaggerId was null or undefined when calling jobServiceDeleteJobTagger.');
            }
            const queryParameters = {};
            if (requestParameters.locationId !== undefined) {
                queryParameters['locationId'] = requestParameters.locationId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/job-tagger/v1/projects/{projectId}/job-taggers/{jobTaggerId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"jobTaggerId"}}`, encodeURIComponent(String(requestParameters.jobTaggerId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    jobServiceDeleteJobTagger(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.jobServiceDeleteJobTaggerRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    jobServiceListJobTaggersRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling jobServiceListJobTaggers.');
            }
            const queryParameters = {};
            if (requestParameters.jobTaggerId !== undefined) {
                queryParameters['jobTaggerId'] = requestParameters.jobTaggerId;
            }
            if (requestParameters.locationId !== undefined) {
                queryParameters['locationId'] = requestParameters.locationId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/job-tagger/v1/projects/{projectId}/job-taggers`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListJobTaggersResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    jobServiceListJobTaggers(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.jobServiceListJobTaggersRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    jobServiceListJobsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling jobServiceListJobs.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/product/v1/projects/{projectId}/jobs`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListJobsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    jobServiceListJobs(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.jobServiceListJobsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    jobServiceListLocationJobTaggersRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling jobServiceListLocationJobTaggers.');
            }
            if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
                throw new runtime.RequiredError('locationId', 'Required parameter requestParameters.locationId was null or undefined when calling jobServiceListLocationJobTaggers.');
            }
            const queryParameters = {};
            if (requestParameters.jobTaggerId !== undefined) {
                queryParameters['jobTaggerId'] = requestParameters.jobTaggerId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/job-tagger/v1/projects/{projectId}/locations/{locationId}/job-taggers`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListJobTaggersResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    jobServiceListLocationJobTaggers(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.jobServiceListLocationJobTaggersRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    jobServiceReadJobTaggerRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling jobServiceReadJobTagger.');
            }
            if (requestParameters.jobTaggerId === null || requestParameters.jobTaggerId === undefined) {
                throw new runtime.RequiredError('jobTaggerId', 'Required parameter requestParameters.jobTaggerId was null or undefined when calling jobServiceReadJobTagger.');
            }
            const queryParameters = {};
            if (requestParameters.locationId !== undefined) {
                queryParameters['locationId'] = requestParameters.locationId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/job-tagger/v1/projects/{projectId}/job-taggers/{jobTaggerId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"jobTaggerId"}}`, encodeURIComponent(String(requestParameters.jobTaggerId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiJobTaggerFromJSON(jsonValue));
        });
    }
    /**
     */
    jobServiceReadJobTagger(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.jobServiceReadJobTaggerRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    jobServiceUpdateJobTaggerRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling jobServiceUpdateJobTagger.');
            }
            if (requestParameters.jobTaggerId === null || requestParameters.jobTaggerId === undefined) {
                throw new runtime.RequiredError('jobTaggerId', 'Required parameter requestParameters.jobTaggerId was null or undefined when calling jobServiceUpdateJobTagger.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling jobServiceUpdateJobTagger.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/job-tagger/v1/projects/{projectId}/job-taggers/{jobTaggerId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"jobTaggerId"}}`, encodeURIComponent(String(requestParameters.jobTaggerId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiJobTaggerPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiJobTaggerFromJSON(jsonValue));
        });
    }
    /**
     */
    jobServiceUpdateJobTagger(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.jobServiceUpdateJobTaggerRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
