/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiReservedConversion;
(function (ApiReservedConversion) {
    ApiReservedConversion["NOT_RESERVED"] = "NOT_RESERVED";
    ApiReservedConversion["ADD_TO_BASKET"] = "ADD_TO_BASKET";
    ApiReservedConversion["EXTERNAL_LINK"] = "EXTERNAL_LINK";
    ApiReservedConversion["JOB_APPLICATION"] = "JOB_APPLICATION";
    ApiReservedConversion["JOB_APPLY_CLICK"] = "JOB_APPLY_CLICK";
    ApiReservedConversion["CHECKOUT"] = "CHECKOUT";
})(ApiReservedConversion || (ApiReservedConversion = {}));
export function ApiReservedConversionFromJSON(json) {
    return ApiReservedConversionFromJSONTyped(json, false);
}
export function ApiReservedConversionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiReservedConversionToJSON(value) {
    return value;
}
