import { Grid } from "@mui/material";
import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import { AnalyticsCard } from "features/analytics";
import { AnalyticsSpacing } from "consts";

function AudienceGeographicTab() {
  return (
    <Grid container spacing={AnalyticsSpacing}>
      <Grid item xs={12} sx={{ height: 600 }}>
        <AnalyticsCard
          chart={{
            name: "Total Sessions by Countries",
            dimension: [
              ApiDimension.DIMENSION_LON_LAT,
              ApiDimension.DIMENSION_COUNTRY,
              ApiDimension.DIMENSION_CITY,
            ],
            template: ApiChartTemplate.MAP,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
              },
            ],
            displayOptions: {
              noFilters: true,
              filterDropdowns: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
            },
          }}
        />
      </Grid>

      <Grid item xs={8} sx={{ minHeight: 830 }}>
        <AnalyticsCard
          chart={{
            name: "Country Primary Stats by Audiences",
            dimension: [ApiDimension.DIMENSION_COUNTRY],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                  ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                  ApiMetric.METRIC_SESSION_MICRO_CONVERSION_RATE,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
              filterDropdowns: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
              rowsPerPage: 11,
            },
          }}
        />
      </Grid>
      <Grid item xs={4} container spacing={AnalyticsSpacing}>
        <Grid item xs={12}>
          <AnalyticsCard
            chart={{
              name: "Top 10 Countries by Sessions",
              dimension: [ApiDimension.DIMENSION_COUNTRY],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.PIE,
              attributes: [],
              limit: 10,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                },
              ],
              displayOptions: {
                noFilters: true,
                filterDropdowns: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
                showTotals: true,
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <AnalyticsCard
            chart={{
              name: "Top 10 Countries by Conversions",
              dimension: [ApiDimension.DIMENSION_COUNTRY],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.PIE,
              attributes: [],
              limit: 10,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT],
                },
              ],
              displayOptions: {
                noFilters: true,
                filterDropdowns: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
                showTotals: true,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={8} sx={{ minHeight: 830 }}>
        <AnalyticsCard
          chart={{
            name: "Region Primary Stats by Audiences",
            dimension: [ApiDimension.DIMENSION_REGION],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                  ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                  ApiMetric.METRIC_SESSION_MICRO_CONVERSION_RATE,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
              filterDropdowns: [
                ApiDimension.DIMENSION_COUNTRY,
                ApiDimension.DIMENSION_LEAD_AUDIENCE,
              ],
              rowsPerPage: 11,
            },
          }}
        />
      </Grid>
      <Grid item xs={4} container spacing={AnalyticsSpacing}>
        <Grid item xs={12}>
          <AnalyticsCard
            chart={{
              name: "Top 10 Regions by Sessions",
              dimension: [ApiDimension.DIMENSION_REGION],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.PIE,
              attributes: [],
              limit: 10,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                },
              ],
              displayOptions: {
                noFilters: true,
                filterDropdowns: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
                showTotals: true,
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <AnalyticsCard
            chart={{
              name: "Top 10 Regions by Conversions",
              dimension: [ApiDimension.DIMENSION_REGION],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.PIE,
              attributes: [],
              limit: 10,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT],
                },
              ],
              displayOptions: {
                noFilters: true,
                filterDropdowns: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
                showTotals: true,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={8} sx={{ minHeight: 830 }}>
        <AnalyticsCard
          chart={{
            name: "City Primary Stats by Audiences",
            dimension: [ApiDimension.DIMENSION_CITY],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                  ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                  ApiMetric.METRIC_SESSION_MICRO_CONVERSION_RATE,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
              filterDropdowns: [
                ApiDimension.DIMENSION_COUNTRY,
                ApiDimension.DIMENSION_LEAD_AUDIENCE,
              ],
              rowsPerPage: 11,
            },
          }}
        />
      </Grid>
      <Grid item xs={4} container spacing={AnalyticsSpacing}>
        <Grid item xs={12}>
          <AnalyticsCard
            chart={{
              name: "Top 10 Cities by Sessions",
              dimension: [ApiDimension.DIMENSION_CITY],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.PIE,
              attributes: [],
              limit: 10,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                },
              ],
              displayOptions: {
                noFilters: true,
                filterDropdowns: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
                showTotals: true,
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <AnalyticsCard
            chart={{
              name: "Top 10 Cities by Conversions",
              dimension: [ApiDimension.DIMENSION_CITY],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.PIE,
              attributes: [],
              limit: 10,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT],
                },
              ],
              displayOptions: {
                noFilters: true,
                filterDropdowns: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
                showTotals: true,
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AudienceGeographicTab;
