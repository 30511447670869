import { ApiACLRole, ApiReportResponse } from "@incendium/api";
import { Delete, Star, StarBorder } from "@mui/icons-material";
import {
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import AccessLevel from "Components/AccessLevel/AccessLevel";
import SpacedList, { SpacedLinkButton } from "Components/UI/SpacedList";
import { cell2Icons } from "consts";

interface IReportsListProps {
  onClick: () => void;
  onEdit: (id: number) => void;
  reports: ApiReportResponse[];
  onPin: (report: any, checked: any) => Promise<void>;
  onDelete: (r: ApiReportResponse) => void;
  onFavourite: (r: ApiReportResponse) => void;
}

function ReportsList({
  onClick,
  reports,
  onPin,
  onDelete,
  onEdit,
  onFavourite,
}: IReportsListProps) {
  return (
    <SpacedList
      title="Custom Reports List"
      action={
        <AccessLevel role={ApiACLRole.ANALYTICS_ADMIN} write>
          <Button onClick={onClick}>Create New Report</Button>
        </AccessLevel>
      }
    >
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Pin to Home</TableCell>
          <AccessLevel role={ApiACLRole.ANALYTICS_ADMIN} write>
            <TableCell sx={{ width: 60 }}></TableCell>
          </AccessLevel>
        </TableRow>
      </TableHead>

      <TableBody>
        {reports.map((report) => {
          return (
            <TableRow key={report.id}>
              <TableCell className="primary">
                <SpacedLinkButton onClick={() => onEdit(report.id!)}>
                  {report.name}
                </SpacedLinkButton>
              </TableCell>
              <TableCell>
                <FormControlLabel
                  componentsProps={{
                    typography: {
                      variant: "body1",
                    },
                  }}
                  control={
                    <Switch
                      checked={report.pinToHome}
                      onChange={(e, checked) => {
                        onPin(report, checked);
                      }}
                    />
                  }
                  label="Pin To Analyse Home"
                />
              </TableCell>
              <AccessLevel role={ApiACLRole.ANALYTICS_ADMIN} write>
                <TableCell align="right" sx={{ width: cell2Icons }}>
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => onFavourite(report)}
                  >
                    {report.favourite ? <Star /> : <StarBorder />}
                  </IconButton>
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => onDelete(report)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </AccessLevel>
            </TableRow>
          );
        })}
      </TableBody>
    </SpacedList>
  );
}

export default ReportsList;
