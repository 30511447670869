import { ApiMedia } from "@incendium/api";
import { Delete } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import CopyClipboardBtn from "Components/CopyClipboardBtn/CopyClipboardBtn";
import { friendlyDate } from "Helpers/dates";
import { useMemo } from "react";
import { StyledWhiteIconBtn } from "Components/Button/StyledButtons";

interface IMediaCardProps {
  media: ApiMedia;
  onDelete: (m: ApiMedia) => void;
}

function MediaCard({ media, onDelete }: IMediaCardProps) {
  const imgSrc = useMemo(() => {
    return media?.files ? media?.files[0].fullPath : "";
  }, [media]);

  return (
    <>
      <Card>
        <Grid container>
          <Grid item xs={5} sx={{ position: "relative" }}>
            <CardMedia
              sx={{ height: "100%" }}
              image={imgSrc}
              title={media.name}
            />
            <StyledWhiteIconBtn
              color="primary"
              size="extraSmall"
              sx={{ position: "absolute", bottom: 4, left: 4 }}
              onClick={() => onDelete(media)}
            >
              <Delete />
            </StyledWhiteIconBtn>
          </Grid>
          <Grid item xs={7}>
            <CardContent>
              <Typography variant="subtitle1" noWrap>
                {media.name}
              </Typography>
              <Typography variant="body2">
                {friendlyDate(media.createdAt)}
              </Typography>
              <List dense sx={{ paddingBottom: 0 }}>
                <ListItem disablePadding disableGutters>
                  <CopyClipboardBtn
                    isIconButton
                    variant="text"
                    text={imgSrc || ""}
                    size="extraSmall"
                    sx={{ marginRight: 0.5 }}
                  />
                  <ListItemText
                    primary="Image URL"
                    secondary={imgSrc}
                    secondaryTypographyProps={{ noWrap: true }}
                  />
                </ListItem>
              </List>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default MediaCard;
