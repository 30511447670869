import { ApiDomainResponse, ApiProject } from "@incendium/api";
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { landingDomainService } from "Apis";
import SavingButton from "Components/UI/SavingButton";
import { useNotification } from "Hooks";
import { FormEvent, useCallback, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

interface ISubdomainFormProps {
  project: ApiProject;
  onSaved: (sd: ApiDomainResponse) => void;
}

function SubdomainForm({ project, onSaved }: ISubdomainFormProps) {
  const [saving, setSaving] = useState(false);
  const { showErrorNotification, showSuccessNotification } = useNotification();
  const [subdomainText, setSubdomainText] = useState("");
  const [domain, setDomain] = useState(
    project.domains?.filter((d) => d.isPrimary)[0].domain
  );

  const onSubmit = useCallback(
    async (event: FormEvent<Element>) => {
      setSaving(true);
      const payload = {
        name: (subdomainText || "").replaceAll(".", "-"),
        subdomain: subdomainText,
        domain,
      };

      try {
        const res = await landingDomainService.landingDomainServiceCreateDomain(
          {
            projectId: project.id as number,
            payload,
          }
        );
        showSuccessNotification(
          `Subdomain saved, Check back later for cname records`
        );
        onSaved(res.domain!);
        setSubdomainText("");
      } catch (error) {
        showErrorNotification(
          `Internal Error connecting subdomain, please try again`
        );
      }
      setSaving(false);
    },
    [
      subdomainText,
      domain,
      onSaved,
      project,
      showSuccessNotification,
      showErrorNotification,
    ]
  );

  return (
    <ValidatorForm onSubmit={onSubmit}>
      <Stack spacing={2}>
        <Paper
          component="form"
          sx={{
            px: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>https://</Typography>
          <Divider sx={{ height: 32, m: 2, width: 0 }} orientation="vertical" />
          <Box
            sx={{
              flex: 1,
              "& .MuiFormHelperText-root.Mui-error": {
                position: "absolute",
                bottom: 0,
              },
            }}
          >
            <TextValidator
              autoFocus
              fullWidth
              label="Add subdomain"
              validators={["required", "isSubdomain"]}
              errorMessages={[
                "Subdomain Name is required",
                "Please enter a valid subdomain",
              ]}
              name="subdomain"
              value={subdomainText}
              variant="standard"
              InputLabelProps={{
                sx: {
                  top: "-2px",
                },
              }}
              InputProps={{
                sx: {
                  marginTop: "12px!important",
                  marginBottom: "8px",
                },
                disableUnderline: true,
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSubdomainText(e.target.value.toLowerCase().trim())
              }
            />
          </Box>
          <Divider sx={{ height: 32, m: 2, width: 0 }} orientation="vertical" />
          <TextField
            value={domain}
            select
            variant="standard"
            onChange={(e) => setDomain(e.target.value)}
          >
            {(project.domains || [])
              .filter((d) => d.isPrimary)
              .map((domain) => (
                <MenuItem key={domain.id} value={domain.domain}>
                  {domain.domain}
                </MenuItem>
              ))}
          </TextField>
        </Paper>
        <Box>
          <Grid container spacing={1} alignItems={"flex-start"}>
            <Grid item xs={7}>
              <Typography variant="body2">
                Your full domain would be <br />
                <strong>
                  https://
                  {subdomainText !== "" ? subdomainText : "[add subdomain]"}.
                  {domain}
                </strong>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button
                color="secondary"
                fullWidth
                onClick={() => {
                  setSubdomainText("");
                }}
              >
                Clear
              </Button>
            </Grid>
            <Grid item xs={3}>
              <SavingButton saving={saving} type="submit" fullWidth>
                Connect
              </SavingButton>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </ValidatorForm>
  );
}

export default SubdomainForm;
