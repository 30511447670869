import { ApiMetric } from "@incendium/api";
import { TChartData } from "Interfaces";
import { NOT_SET } from "features/analytics/constants/constants";
import { RankConfig } from "features/explorer/types";

export const filterByRank = (
  data: TChartData[],
  rank: number,
  rankConfig?: RankConfig
) => {
  data = data.filter((d) => d.name !== NOT_SET);
  if (!rankConfig) {
    return data;
  }
  return data.filter((d) => Number(d[ApiMetric.METRIC_RANK]) >= rank / 100);
};
