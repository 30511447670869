/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiAlertFromJSON, ApiAlertToJSON, } from './ApiAlert';
import { ApiAudienceFromJSON, ApiAudienceToJSON, } from './ApiAudience';
import { ApiConditionFromJSON, ApiConditionToJSON, } from './ApiCondition';
import { ApiEngagementRuleFromJSON, ApiEngagementRuleToJSON, } from './ApiEngagementRule';
export function ApiConditionGroupFromJSON(json) {
    return ApiConditionGroupFromJSONTyped(json, false);
}
export function ApiConditionGroupFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'audience': !exists(json, 'audience') ? undefined : ApiAudienceFromJSON(json['audience']),
        'conditionGroup': !exists(json, 'conditionGroup') ? undefined : ApiConditionGroupFromJSON(json['conditionGroup']),
        'conditions': !exists(json, 'conditions') ? undefined : (json['conditions'].map(ApiConditionFromJSON)),
        'engagementRule': !exists(json, 'engagementRule') ? undefined : ApiEngagementRuleFromJSON(json['engagementRule']),
        'engagementRuleId': !exists(json, 'engagementRuleId') ? undefined : json['engagementRuleId'],
        'alert': !exists(json, 'alert') ? undefined : ApiAlertFromJSON(json['alert']),
        'alertId': !exists(json, 'alertId') ? undefined : json['alertId'],
    };
}
export function ApiConditionGroupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'audience': ApiAudienceToJSON(value.audience),
        'conditionGroup': ApiConditionGroupToJSON(value.conditionGroup),
        'conditions': value.conditions === undefined ? undefined : (value.conditions.map(ApiConditionToJSON)),
        'engagementRule': ApiEngagementRuleToJSON(value.engagementRule),
        'engagementRuleId': value.engagementRuleId,
        'alert': ApiAlertToJSON(value.alert),
        'alertId': value.alertId,
    };
}
