import { utmService } from "Apis";
import { useSharedState } from "./useSharedState";
import { ApiProject, ApiSimpleUTM } from "@incendium/api";

const fetchUTMs = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const utm = await utmService.uTMServiceListUTMs({
      projectId: selectedProject.id as number,
    });
    return utm.utms || [];
  }
  return [];
};
export const useUTMs = () => {
  const { state, setState, loading, refetch } = useSharedState<
    ApiSimpleUTM,
    ApiSimpleUTM[]
  >("utms", [], fetchUTMs);

  return {
    utms: state,
    setUTMs: setState,
    loading,
    refetch,
  };
};
