import { ApiUser, ApiStatus } from "@incendium/api";
import { Button, Stack } from "@mui/material";
import { userService } from "Apis";
import { useUsers } from "Hooks/useUsers";
import produce from "immer";
import { useSnackbar } from "notistack";
import { useRef } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import UserRoles from "./UserRoles";

interface IUserFormProps {
  user: ApiUser;
  setUser: React.Dispatch<React.SetStateAction<ApiUser>>;
}

function UserForm({ user, setUser }: IUserFormProps) {
  const { users, setUsers } = useUsers();
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef<HTMLFormElement | any>();

  const saveUser = async () => {
    (await user.id) ? updateUser() : createUser();
  };

  const createUser = async () => {
    try {
      const res = await userService.userServiceCreateUser({
        body: {
          user,
        },
      });

      enqueueSnackbar(`${res.name} Created`, {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });

      setUsers(
        produce(users, (draft) => {
          draft.push(res);
        })
      );
      setUser({});
    } catch (error) {
      enqueueSnackbar(`There was an internal error, please try again`, {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }
  };

  const updateUser = async () => {
    try {
      const res = await userService.userServiceUpdateUser({
        userId: user.id as number,
        body: {
          user,
        },
      });

      enqueueSnackbar(`${res.name} Updated`, {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });

      setUsers(
        produce(users, (draft) => {
          const idx = draft.findIndex((d) => d.id === res.id);
          if (idx >= 0) {
            draft[idx] = res;
          }
        })
      );
      setUser({});
    } catch (error) {
      enqueueSnackbar(`There was an internal error, please try again`, {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }
  };

  const cancel = () => setUser({});

  return (
    <div>
      <ValidatorForm onSubmit={saveUser} ref={formRef}>
        <Stack spacing={2} p={2}>
          <TextValidator
            label="Name"
            value={user.name || ""}
            variant="outlined"
            name="name"
            fullWidth
            size="small"
            validators={["required"]}
            errorMessages={["Name is required"]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setUser(
                produce(user, (draft) => {
                  draft.name = e.target.value;
                })
              )
            }
          />

          <TextValidator
            label="Email"
            value={user.email || ""}
            variant="outlined"
            name="email"
            fullWidth
            size="small"
            validators={["required", "isEmail"]}
            errorMessages={["Name is required", "Email is invalid"]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setUser(
                produce(user, (draft) => {
                  draft.email = e.target.value;
                })
              )
            }
          />
        </Stack>
        <Stack spacing={2}>
          <UserRoles user={user} setUser={setUser} />

          <Stack
            p={2}
            direction={"row"}
            justifyContent="flex-end"
            spacing={1}
            sx={{ minHeight: 37 }}
          >
            {user.name && user.name?.length > 0 && (
              <>
                <Button color="secondary" onClick={cancel}>
                  Cancel
                </Button>
                <Button type="submit">{user.id ? "Update" : "Create"}</Button>
                {!user.id && (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setUser(
                        produce(user, (draft) => {
                          if (
                            !draft.status ||
                            draft.status === ApiStatus.STATUS_UNSPECIFIED
                          ) {
                            draft.status = ApiStatus.ACTIVE;
                          }
                        })
                      );
                      setTimeout(() => {
                        formRef.current.submit();
                      }, 1);
                    }}
                  >
                    Create And Invite
                  </Button>
                )}
              </>
            )}
          </Stack>
        </Stack>
      </ValidatorForm>
    </div>
  );
}

export default UserForm;
