import { Box, Button, Divider, styled, Typography } from "@mui/material";

export const SidebarContainer = styled(Box)({
  width: 350,
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  paddingLeft: "6px",
  // maxWidth: '800px',
  // margin: '0 auto'
});

export const SiderBarHeader = styled(Box)<{
  noBorder?: boolean;
  compact?: boolean;
}>(({ theme, noBorder, compact }) => ({
  minHeight: compact ? 65 : 88,
  maxHeight: 180,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

export const SidebarContentWrapper = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  flex: 1,
  height: "calc(100vh - 100px)", // 88 title + 12 (border)
  overflowX: "hidden",
  overflowY: "auto",
}));

export const SidebarStyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  borderColor: theme.palette.secondary.main,
  height: 1,
  width: "100%",
}));

export const SidebarSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h3.fontSize,
  fontWeight: 500,
  color: theme.palette.primary.dark,
  paddingLeft: theme.spacing(2),
}));

export const SidebarActionButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  background: "transparent",
  color: theme.palette.primary.dark,
  textAlign: "left",
  width: "100%",
  justifyContent: "space-between",
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderRadius: 0,
  fontWeight: 600,
  "&:hover": {
    color: "white",
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
  "& .MuiButton-endIcon svg": {
    fontSize: 24,
  },
}));
export const SidebarListButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  background: "transparent",
  color: theme.palette.text.secondary,
  textAlign: "left",
  width: "100%",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderRadius: 0,
  "&:hover": {
    color: "white",
  },
  "& .MuiButton-endIcon svg": {
    fontSize: 24,
  },
  "& .MuiButton-startIcon": {
    marginRight: -24,
  },
}));
