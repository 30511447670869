import { Box, Grid, GridSize, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { motion } from "framer-motion";
import { useCallback, useMemo, useState } from "react";

const useCenterStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
  },
  content: {
    transform: `translateY(-8%)`,
  },
}));

interface ICenterPageProps extends React.HTMLAttributes<HTMLDivElement> {
  calcHeight?: boolean;
  sm?: GridSize;
}

export const CenterPage = ({ calcHeight, children, sm }: ICenterPageProps) => {
  const classes = useCenterStyles();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const onRefChange = useCallback((node) => {
    setRef(node);
  }, []);

  const height = useMemo(() => {
    // 114 is top bar and  30px is just for ui sakes
    return calcHeight
      ? `calc(100vh - ${ref?.offsetTop}px - 114px - 30px )`
      : "100%";
  }, [ref, calcHeight]);

  return (
    <>
      <Box
        ref={onRefChange}
        sx={{
          position: "absolute",
        }}
      />
      <Grid
        className={classes.root}
        container
        justifyContent="center"
        alignItems="center"
        component={motion.div}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        sx={{ height }}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          item
          sm={sm || 8}
          className={classes.content}
        >
          {children}
        </Grid>
      </Grid>
    </>
  );
};
