import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import StyledDrawer, {
  StyledDrawerContainer,
  StyledDrawerTitle,
} from "Components/UI/StyledDrawer";
import { GenericDialoagProps } from "Interfaces";
import { AccordianChartBuilderSidebarBlock } from "features/chartLibrary";
import { useCallback } from "react";

const NumberedList = ({ items }: { items: string[] }) => {
  return (
    <List sx={{ listStyle: "decimal", pl: 5 }}>
      {items.map((item) => (
        <ListItem key={item} sx={{ display: "list-item" }} dense disablePadding>
          <ListItemText
            primary={item}
            primaryTypographyProps={{ fontSize: "12px" }}
          />
        </ListItem>
      ))}
    </List>
  );
};

interface IAddDomainGuideDrawerProps extends GenericDialoagProps {}

function AddDomainGuideDrawer({ open, setOpen }: IAddDomainGuideDrawerProps) {
  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <StyledDrawer open={open} onClose={onClose} maxWidth={600}>
      <StyledDrawerTitle>
        <Typography variant="subtitle1">Subdomain Guide</Typography>
        <Typography variant="body2">
          Step-by-Step Instructions for Linking Your Subdomain to Your Domain
          Provider
        </Typography>
      </StyledDrawerTitle>
      <StyledDrawerContainer>
        <Typography variant="body2">
          A CNAME (Canonical Name) record serves as an alias for a domain name.
          In the context of Incendium, utilizing CNAME records enables users to
          create a customized subdomain of their primary domain, directing it to
          Incendium's services. This process involves pointing the subdomain to
          Incendium's servers, allowing seamless integration of our platform
          within the user's domain infrastructure. By configuring the CNAME to
          point at Incendium and utilizing the provided SSL certificate, users
          can ensure secure connections while accessing and showcasing their
          content through the personalized subdomain, enhancing branding and
          accessibility.
        </Typography>
      </StyledDrawerContainer>
      <Divider />
      <StyledDrawerContainer>
        <Typography variant="body2">
          Remember, DNS changes may take some time to propagate globally,
          ranging from a few minutes to several hours, depending on the
          provider. Once the changes have propagated, the subdomain should be
          properly configured to direct traffic as intended.
        </Typography>
      </StyledDrawerContainer>

      <AccordianChartBuilderSidebarBlock
        title="General"
        subTitle="These Instructions should work for most providers."
        defaultClosed
      >
        <NumberedList
          items={[
            "Log in to your [Provider Name] account.",
            "Navigate to your account dashboard or homepage.",
            `Find and click on the "Domain Settings" or "Manage Domains" option.`,
            `Locate the domain for which you want to add a CNAME record.`,
            `Look for a section labeled "DNS Settings," "DNS Management," or similar.`,
            `Choose the option to "Add a new record" or "Add DNS record."`,
            `Select "CNAME" as the record type.`,
            `In the "Host" or "Name" field, enter the desired subdomain (e.g., "subdomain").`,
            `Enter the provided CNAME value or destination in the respective field (often labeled "Points to," "Value," or "Destination").`,
            `Save or apply your changes, following any prompts to confirm.`,
          ]}
        />
      </AccordianChartBuilderSidebarBlock>
      <AccordianChartBuilderSidebarBlock
        title="GoDaddy"
        subTitle="These Instructions are for GoDaddy."
        defaultClosed
      >
        <NumberedList
          items={[
            `Log in to your GoDaddy account.`,
            `Navigate to your Domain Manager.`,
            `Select the domain for which you want to add a CNAME record.`,
            `Scroll down to the "DNS Management" section and find "Records."`,
            `Click "Add" and choose "CNAME (Alias)."`,
            `In the "Host" field, enter the desired subdomain (e.g., "subdomain").`,
            `In the "Points to" field, enter the provided value or destination.`,
            `Save your changes`,
          ]}
        />
      </AccordianChartBuilderSidebarBlock>
      <AccordianChartBuilderSidebarBlock
        title="Namecheap"
        subTitle="These Instructions are for Namecheap."
        defaultClosed
      >
        <NumberedList
          items={[
            `Log in to your Namecheap account.`,
            `Go to the "Domain List" section and click "Manage" next to your domain.`,
            `Navigate to the "Advanced DNS" tab.`,
            `Find the "Host Records" section and click "Add New Record."`,
            `Select "CNAME Record" from the dropdown.`,
            `Enter the desired subdomain in the "Host" field (e.g., "subdomain").`,
            `In the "Value" field, enter the provided CNAME value or destination.`,
            `Save your changes.`,
          ]}
        />
      </AccordianChartBuilderSidebarBlock>
      <AccordianChartBuilderSidebarBlock
        title="Cloudflare"
        subTitle="These Instructions are for Cloudfalre."
        defaultClosed
      >
        <NumberedList
          items={[
            `Log in to your Cloudflare account.`,
            `Select the domain to which you want to add the CNAME.`,
            `Go to the "DNS" tab.`,
            `Click "Add Record" and choose "CNAME" as the record type.`,
            `Enter the desired subdomain in the "Name" field (e.g., "subdomain").`,
            `In the "Target" field, enter the provided CNAME value or destination.`,
            `Save your changes.`,
          ]}
        />
      </AccordianChartBuilderSidebarBlock>
      <StyledDrawerContainer>
        <Typography variant="body2">
          Please note that the interface and specific steps might differ
          slightly based on the DNS provider's dashboard layout and options.
          Always ensure accuracy when entering the subdomain and CNAME value to
          avoid errors in configuring the record. If you encounter any issues,
          refer to the provider's help documentation or support for detailed
          instructions.
        </Typography>
      </StyledDrawerContainer>
    </StyledDrawer>
  );
}

export default AddDomainGuideDrawer;
