/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiOperatorFromJSON, ApiOperatorToJSON, } from './ApiOperator';
import { ApiUserFilterTypeFromJSON, ApiUserFilterTypeToJSON, } from './ApiUserFilterType';
export function ApiGetUsersFilterFromJSON(json) {
    return ApiGetUsersFilterFromJSONTyped(json, false);
}
export function ApiGetUsersFilterFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': !exists(json, 'type') ? undefined : ApiUserFilterTypeFromJSON(json['type']),
        'operator': !exists(json, 'operator') ? undefined : ApiOperatorFromJSON(json['operator']),
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}
export function ApiGetUsersFilterToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': ApiUserFilterTypeToJSON(value.type),
        'operator': ApiOperatorToJSON(value.operator),
        'value': value.value,
    };
}
