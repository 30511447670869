import { ApiEngagementRule, ApiLocation } from "@incendium/api";
import { Box, Grid, Typography } from "@mui/material";
import produce from "immer";
import { useEffect, useState } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import LocationSidebarSelector from "Components/Location/LocationSidebarSelector";
import { StyledDrawerContainer } from "Components/UI/StyledDrawer";
import { AccordianChartBuilderSidebarBlock } from "features/chartLibrary";
import { QuestionMark } from "@mui/icons-material";
import GlassCard from "Components/GlassCard/GlassCard";

interface IEngagementSidebarProps {
  engagement: ApiEngagementRule;
  setEngagement: React.Dispatch<
    React.SetStateAction<ApiEngagementRule | undefined>
  >;
}

function EngagementSidebar({
  engagement,
  setEngagement,
}: IEngagementSidebarProps) {
  const [locations, setLocations] = useState<ApiLocation[]>(
    engagement?.locations || []
  );

  useEffect(() => {
    setEngagement(
      produce(engagement, (draft) => {
        draft.locations = locations;
      })
    );
  }, [engagement, setEngagement, locations]);

  return (
    <Box>
      <AccordianChartBuilderSidebarBlock
        title="Lead Score Setup"
        subTitle="Define score and relevant locations."
        icon={QuestionMark}
        defaultClosed
      >
        <GlassCard boxProps={{ p: 2 }} noShadow>
          <Typography variant="body1" gutterBottom>
            In order to measure how well a particular lead engages with a page,
            you can allocate points to various interactions taken by the viewer.
            The score can be given if the viewer takes a grouped set of actions
            assigned by you (i.e. action 1 <strong>AND</strong> action 2) or a
            grouped set of different actions (i.e. action 1 and action 2, OR
            action 3, 4 and 5). You can have as many or as few actions as you
            want in each set of grouped actions. This score is then added to the
            leads total Lead Score
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Example</strong>
          </Typography>
          <Typography variant="body1" gutterBottom>
            You wish to allocate the points if the viewer spent more than 60
            seconds on the page <strong>AND</strong> they viewed 100% of the
            page, <strong>OR</strong> a conversion was achieved - the viewer
            made a booking enquiry.
          </Typography>
          <ul>
            <li>
              In the first group you would select: time (seconds) on page = 60
            </li>
            <li>Amount (%) page viewed = 100</li>
            <li>
              You would then click <strong>OR</strong> to create a new group,
              and in the new group you would select
            </li>
            <li>Conversion (goal achieved) = booking enquiry</li>
          </ul>
        </GlassCard>
      </AccordianChartBuilderSidebarBlock>
      <StyledDrawerContainer>
        <GlassCard boxProps={{ p: 2 }} noShadow>
          <Typography variant="subtitle1" mb={3}>
            Set Parameters
          </Typography>
          <TextValidator
            label="name of pageview score"
            variant="outlined"
            name="name"
            fullWidth
            validators={["required"]}
            errorMessages={["name is required"]}
            value={engagement.name || ""}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setEngagement(
                produce(engagement, (draft) => {
                  draft.name = e.currentTarget.value;
                })
              );
            }}
          />
          <Grid mt={1} container spacing={3}>
            <Grid item xs={6}>
              <TextValidator
                label="score"
                variant="outlined"
                name="score"
                fullWidth
                type="number"
                validators={["required"]}
                errorMessages={["score is required"]}
                value={engagement.score || ""}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  setEngagement(
                    produce(engagement, (draft) => {
                      draft.score = Number(e.currentTarget.value);
                    })
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextValidator
                label="max score"
                variant="outlined"
                name="score"
                fullWidth
                type="number"
                value={engagement.maxScore || ""}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  setEngagement(
                    produce(engagement, (draft) => {
                      draft.maxScore = Number(e.currentTarget.value);
                    })
                  );
                }}
              />
            </Grid>
          </Grid>
          <Box mt={5}>
            <LocationSidebarSelector
              locations={locations}
              setLocations={setLocations}
            />
          </Box>
        </GlassCard>
      </StyledDrawerContainer>
    </Box>
  );
}

export default EngagementSidebar;
