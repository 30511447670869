/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiSessionConditionCustom;
(function (ApiSessionConditionCustom) {
    ApiSessionConditionCustom["UNSPECIFIED"] = "SESSION_CONDITION_UNSPECIFIED";
    ApiSessionConditionCustom["CONVERSION_TRIGGERED"] = "SESSION_CONDITION_CONVERSION_TRIGGERED";
    ApiSessionConditionCustom["CONVERSION_COUNT"] = "SESSION_CONDITION_CONVERSION_COUNT";
    ApiSessionConditionCustom["AVG_TIME_ON_PAGE"] = "SESSION_CONDITION_AVG_TIME_ON_PAGE";
    ApiSessionConditionCustom["NUMBER_OF_PAGE_VIEWS"] = "SESSION_CONDITION_NUMBER_OF_PAGE_VIEWS";
    ApiSessionConditionCustom["TIME_ON_PAGE_PERCENTILE"] = "SESSION_CONDITION_TIME_ON_PAGE_PERCENTILE";
    ApiSessionConditionCustom["VIEW_RATE_PERCENTILE"] = "SESSION_CONDITION_VIEW_RATE_PERCENTILE";
    ApiSessionConditionCustom["SCROLL_RATE_PERCENTILE"] = "SESSION_CONDITION_SCROLL_RATE_PERCENTILE";
    ApiSessionConditionCustom["LEAD_SCORE_DURING_SESSION"] = "SESSION_CONDITION_LEAD_SCORE_DURING_SESSION";
    ApiSessionConditionCustom["NUMBER_OF_EFFECTIVE_PAGE_VIEWS"] = "SESSION_CONDITION_NUMBER_OF_EFFECTIVE_PAGE_VIEWS";
    ApiSessionConditionCustom["EFFECTIVE_PAGE_VIEWS_PERCENTAGE"] = "SESSION_CONDITION_EFFECTIVE_PAGE_VIEWS_PERCENTAGE";
    ApiSessionConditionCustom["MACRO_CONVERSION_COUNT"] = "SESSION_CONDITION_MACRO_CONVERSION_COUNT";
    ApiSessionConditionCustom["MICRO_CONVERSION_COUNT"] = "SESSION_CONDITION_MICRO_CONVERSION_COUNT";
})(ApiSessionConditionCustom || (ApiSessionConditionCustom = {}));
export function ApiSessionConditionCustomFromJSON(json) {
    return ApiSessionConditionCustomFromJSONTyped(json, false);
}
export function ApiSessionConditionCustomFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiSessionConditionCustomToJSON(value) {
    return value;
}
