import { Stack, Box, Button, styled, Tooltip } from "@mui/material";
import { useTheme } from "@mui/styles";
import { ReactComponent as First } from "Assets/Images/att-first-interaction.svg";
import { ReactComponent as Last } from "Assets/Images/att-last-interaction.svg";
import { ReactComponent as LastNonDirect } from "Assets/Images/att-last-nondirect.svg";
import { ReactComponent as FirstNonDirect } from "Assets/Images/att-first-nondirect.svg";
import { ReactComponent as Linear } from "Assets/Images/att-linear.svg";
import { ReactComponent as Position } from "Assets/Images/att-position-based.svg";
import { getAttributionTitle } from "Helpers/getAttributionTitle";
import { TAttributionModel } from "Interfaces";
import { useMemo } from "react";
import produce from "immer";

const StyledButton = styled(Button)(({ theme }) => ({
  background: "transparent",
  padding: `0`,
  borderRadius: 14,
  minWidth: 30,

  "&.active": {
    background: theme.palette.primary.dark,
    "& svg": {
      fill: theme.palette.common.white,
    },
  },

  "& svg": {
    fill: theme.palette.primary.dark,
  },
  "&:hover svg": {
    transition: "all 1.2s",
    fill: theme.palette.common.white,
  },
}));

interface IAttributionToggleProps {
  models: TAttributionModel[];
  setModels: (args: TAttributionModel[]) => void;
  show?: TAttributionModel[];
  multiple?: boolean;
}

function AttributionToggle({
  models,
  setModels,
  show,
  multiple,
}: IAttributionToggleProps) {
  const theme = useTheme();

  const options: TAttributionModel[] = useMemo(() => {
    const allModels: TAttributionModel[] = [
      "first",
      "last",
      "lastNon",
      "firstNon",
      "linear",
      "position",
    ];
    return show ? show : allModels;
  }, [show]);

  const iconRender = (m: TAttributionModel) => {
    switch (m) {
      case "first":
        return <First />;
      case "last":
        return <Last />;

      case "lastNon":
        return <LastNonDirect />;
      case "firstNon":
        return <FirstNonDirect />;
      case "position":
        return <Position />;
      case "linear":
        return <Linear />;

      default:
        break;
    }
  };
  return (
    <Box
      sx={{
        background: theme.palette.secondary.light,
        borderRadius: 10,
        padding: "7px 8px",
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent={"space-between"}
      >
        {options.map((m) => (
          <Tooltip title={getAttributionTitle(m)} key={m}>
            <StyledButton
              onClick={() => {
                if (multiple) {
                  setModels(
                    produce(models, (draft) => {
                      const idx = draft.indexOf(m);
                      if (idx < 0) {
                        draft.push(m);
                      } else if (draft.length > 1) {
                        draft.splice(idx, 1);
                      }
                    })
                  );
                  return;
                }
                setModels([m]);
              }}
              className={models.includes(m) ? "active" : ""}
            >
              {iconRender(m)}
            </StyledButton>
          </Tooltip>
        ))}
      </Stack>
    </Box>
  );
}

export default AttributionToggle;
