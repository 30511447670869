import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import { Grid } from "@mui/material";
import AttributionInsights from "./AttributionInsights";
import { AnalyticsCard } from "features/analytics";
import { AnalyticsSpacing } from "consts";
import withPaidChannelAttributes from "HoC/withPaidChannelAttributes";

function CreativesInsights({ paidChannelAttributes }) {
  return (
    <Grid container spacing={AnalyticsSpacing}>
      <Grid item xs={6} sx={{ minHeight: 500 }}>
        <AnalyticsCard
          chart={{
            name: `Creative Primary Stats`,
            dimension: [ApiDimension.DIMENSION_CONTENT],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [...paidChannelAttributes],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE,
                  ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
                  ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
                  ApiMetric.METRIC_PAGE_MACRO_CONVERSION_RATE,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
              rowsPerPage: 5,
            },
          }}
        />
      </Grid>
      <Grid item xs={6} sx={{ minHeight: 500 }}>
        <AnalyticsCard
          chart={{
            name: `Creative Performance Overview`,
            dimension: [ApiDimension.DIMENSION_CONTENT],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.BUBBLE,
            attributes: [...paidChannelAttributes],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <AttributionInsights
          title="Creatives"
          marketingDimension={ApiDimension.DIMENSION_CONTENT}
        />
      </Grid>
    </Grid>
  );
}

export default withPaidChannelAttributes(CreativesInsights);
