/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiLocationFromJSON, ApiLocationToJSON, } from './ApiLocation';
import { ApiProductTaggerUniqueFieldFromJSON, ApiProductTaggerUniqueFieldToJSON, } from './ApiProductTaggerUniqueField';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
export function ApiProductTaggerFromJSON(json) {
    return ApiProductTaggerFromJSONTyped(json, false);
}
export function ApiProductTaggerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'uniqueField': !exists(json, 'uniqueField') ? undefined : ApiProductTaggerUniqueFieldFromJSON(json['uniqueField']),
        'titleSelector': !exists(json, 'titleSelector') ? undefined : json['titleSelector'],
        'descriptionSelector': !exists(json, 'descriptionSelector') ? undefined : json['descriptionSelector'],
        'priceSelector': !exists(json, 'priceSelector') ? undefined : json['priceSelector'],
        'categorySelector': !exists(json, 'categorySelector') ? undefined : json['categorySelector'],
        'imageUrlSelector': !exists(json, 'imageUrlSelector') ? undefined : json['imageUrlSelector'],
        'productIdSelector': !exists(json, 'productIdSelector') ? undefined : json['productIdSelector'],
        'skuSelector': !exists(json, 'skuSelector') ? undefined : json['skuSelector'],
        'location': !exists(json, 'location') ? undefined : ApiLocationFromJSON(json['location']),
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'brandSelector': !exists(json, 'brandSelector') ? undefined : json['brandSelector'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'useLinkedData': !exists(json, 'useLinkedData') ? undefined : json['useLinkedData'],
    };
}
export function ApiProductTaggerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'uniqueField': ApiProductTaggerUniqueFieldToJSON(value.uniqueField),
        'titleSelector': value.titleSelector,
        'descriptionSelector': value.descriptionSelector,
        'priceSelector': value.priceSelector,
        'categorySelector': value.categorySelector,
        'imageUrlSelector': value.imageUrlSelector,
        'productIdSelector': value.productIdSelector,
        'skuSelector': value.skuSelector,
        'location': ApiLocationToJSON(value.location),
        'locationId': value.locationId,
        'project': ApiProjectToJSON(value.project),
        'projectId': value.projectId,
        'brandSelector': value.brandSelector,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'useLinkedData': value.useLinkedData,
    };
}
