import {
  alpha,
  Box,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { formatNumber } from "Helpers/numbers";
import { formatPercentage } from "Helpers/percentage";
import { ReactNode, useMemo, useState } from "react";
import { useMount } from "react-use";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  YAxis,
} from "recharts";

interface IBarFunnelProps {
  children: ReactNode;
  headers: string[];
  loading: boolean;
}

const colorIndexOrder = [4, 2, 6, 7];

export const BarFunnel = ({ children, headers, loading }: IBarFunnelProps) => {
  const xWidth = 80; // todo, calculate
  const theme = useTheme();
  const colorOrder = [
    theme.palette.charts[colorIndexOrder[0]],
    theme.palette.charts[colorIndexOrder[1]],
    theme.palette.charts[colorIndexOrder[2]],
    theme.palette.charts[colorIndexOrder[3]],
  ];

  return (
    <Stack flexDirection={"column"}>
      {loading ? (
        <Box sx={{ height: 235 * 2 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            sx={{
              paddingLeft: `${xWidth}px`,
              paddingRight: "29px",
            }}
          >
            {headers.map((header, i) => (
              <Box
                key={header}
                p={0.5}
                sx={{
                  background: colorOrder[i],
                  flex: 1,
                  borderLeft:
                    i === 0 ? `1px solid ${colorOrder[i]}` : `1px solid white`,
                  borderRight:
                    i === headers.length - 1
                      ? `1px solid ${colorOrder[i]}`
                      : `1px solid white`,

                  color: "white",
                  textAlign: "center",
                  borderTopLeftRadius: i === 0 ? 10 : 0,
                  borderTopRightRadius: i === headers.length - 1 ? 10 : 0,
                }}
              >
                {header}
              </Box>
            ))}
          </Stack>

          {/* using column revser here as with transform in chart container it fixes issue with revcharts and overlapping bar to graph when reveresed */}
          <Stack flexDirection={"column-reverse"}>{children}</Stack>
        </>
      )}
    </Stack>
  );
};

const renderCustomizedLabel = (direction: "up" | "down") => (props: any) => {
  const { name, x, y, width, value, index } = props;
  const gap = 12;
  const theme = useTheme();
  const fill = theme.palette.charts[colorIndexOrder[index]];

  return (
    <g>
      <text
        x={x + width / 2}
        y={direction === "up" ? y - (gap + 18) : y + gap}
        fill={fill}
        fontWeight={"bold"}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {name}
      </text>
      <text
        x={x + width / 2}
        y={direction === "up" ? y - gap : y + (gap + 18)}
        fill={fill}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value > 1 ? formatNumber(value) : 0}
      </text>
    </g>
  );
};

interface IChartContainerProps {
  maxDomain: number;
  direction: "up" | "down";
  xWidth: number;
  fields: {
    chartData: {
      name: string;
      value: number;
    }[];
    steps: {
      name: string;
      value: number;
    }[];
  };
}

export const ChartContainer = ({
  fields,
  maxDomain,
  direction,
  xWidth,
}: IChartContainerProps) => {
  const [animationShouldBeActive, setAnimationShouldBeActive] = useState(true);
  const theme = useTheme();
  const chartData = useMemo(() => {
    return fields.chartData;
  }, [fields]);
  useMount(() => {
    setTimeout(() => {
      setAnimationShouldBeActive(false);
    }, 1500);
  });

  return (
    <Stack
      direction={"row"}
      alignItems={direction === "up" ? "flex-end" : "flex-start"}
      sx={{
        height: 235,
        position: "relative",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent="space-between"
        sx={{
          position: "absolute",
          height: "100%",
          top: 0,
          bottom: 0,
          right: 25,
          left: xWidth,
          borderStyle: "solid",
          borderColor: theme.palette.info.main,
          borderWidth: 2,
          borderTopWidth: direction === "up" ? 2 : 0,
          borderBottomWidth: direction === "down" ? 2 : 0,
          "&:after": {
            display: direction === "up" ? "block" : "none",
            content: "''",
            position: "absolute",
            bottom: -1,
            width: "100%",
            height: 2,
            background: theme.palette.info.main,
            zIndex: 1,
          },
        }}
      >
        {fields.steps.map((step) => (
          <MetricStick
            key={step.name}
            name={step.name}
            value={Math.ceil(step.value || 0)}
            direction={direction}
          />
        ))}
        <Box sx={{ flex: 1 }}></Box>
      </Stack>
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={chartData}
            barCategoryGap={40}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <CartesianGrid
              vertical={false}
              strokeDasharray="6"
              stroke={alpha(theme.palette.info.main, 0.5)}
            />

            <Bar
              dataKey="value"
              radius={[10, 10, 0, 0]}
              isAnimationActive={animationShouldBeActive}
            >
              <LabelList
                dataKey="value"
                position="top"
                content={renderCustomizedLabel(direction)}
              />
              {chartData.map((entry, index) => {
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={alpha(
                      theme.palette.charts[colorIndexOrder[index]],
                      direction === "down" ? 0.5 : 1
                    )}
                  />
                );
              })}
            </Bar>

            <YAxis
              scale={"log"}
              domain={[1, maxDomain]}
              tickCount={7}
              tickLine={false}
              stroke={theme.palette.text.primary}
              axisLine={{ strokeWidth: 0 }}
              tickFormatter={(v) => formatNumber(v)}
              width={xWidth}
              reversed={direction === "down"}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <Box
        sx={{
          borderTopRightRadius: direction === "up" ? 10 : 0,
          borderBottomRightRadius: direction === "down" ? 10 : 0,
          position: "relative",
          width: 29,
          background:
            direction === "up"
              ? theme.palette.charts[1]
              : theme.palette.charts[3],
          height: "100%",
          writingMode: "vertical-lr",
          padding: "6px",
          textAlign: "center",
          color: "white",
          zIndex: 2,
          borderTop: direction === "down" ? "1px solid white" : 0,
          borderBottom: direction === "up" ? "1px solid white" : 0,
        }}
      >
        {direction === "up" ? " selected time period" : "previous time period"}
      </Box>
    </Stack>
  );
};

interface IMetricStickProps {
  name: string;
  value: number;
  direction: "up" | "down";
}

const MetricStick = ({ name, value, direction }: IMetricStickProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        zIndex: 1,
        position: "relative",

        flex: 1,
        "&:before": {
          content: "''",
          position: "absolute",
          width: 2,
          top: direction === "up" ? 56 : 0,
          bottom: direction === "down" ? 56 : 0,
          right: 1,
          background:
            direction === "up"
              ? theme.palette.charts[1]
              : theme.palette.charts[3],
        },
      }}
    >
      <Stack
        sx={{
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
          background:
            direction === "up"
              ? theme.palette.charts[1]
              : theme.palette.charts[3],
          borderRadius: "50%",
          top: direction === "up" ? 27 : "auto",
          bottom: direction === "down" ? 27 : 0,
          height: 40,
          width: 40,
          right: -18,
          color: "white",
          textAlign: "center",
        }}
      >
        <Typography variant="body2" sx={{ color: "white", lineHeight: 1 }}>
          {formatPercentage(value)}
        </Typography>
        <Typography variant="body2" sx={{ color: "white", lineHeight: 1 }}>
          {name}
        </Typography>
      </Stack>
    </Box>
  );
};
