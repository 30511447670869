import { Typography } from "@mui/material";
import { StyledMiddleBox } from "Components/UI/StylesFlexBox";
import { motion } from "framer-motion";

function ChartBuuilderTypeTransitioning() {
  return (
    <StyledMiddleBox
      component={motion.div}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5 }}
    >
      <Typography variant="subtitle1">Changing Chart Type.</Typography>
    </StyledMiddleBox>
  );
}

export default ChartBuuilderTypeTransitioning;
