import { useState } from "react";
import {
  Box,
  Button,
  FormLabel,
  Input,
  Stack,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { authService } from "Apis/api";
import { useSnackbar } from "notistack";
import { WashedForm } from "Components/UI/WashedForm";
import { motion } from "framer-motion";

const PasswordForgot = () => {
  const [email, setEmail] = useState("");

  const [isEmailSent, setIsEmailSent] = useState(false);

  const [sending, setIsSending] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const onButtonClick = async () => {
    setIsSending(true);
    try {
      await authService.authForgotPassword({ body: { email } });
      setIsEmailSent(true);
    } catch (e) {
      enqueueSnackbar("Internal Server Error", {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    } finally {
      setIsSending(false);
    }
  };

  const showForm = () => {
    if (!isEmailSent) {
      return (
        <>
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{
              x: 300,
              opacity: 0,
              transition: {
                duration: 0.3,
              },
            }}
          >
            <Stack spacing={4}>
              <Typography variant="subtitle1" color={"white"}>
                Enter your registered email to reset password.
              </Typography>
              <div>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  disabled={sending}
                />
              </div>

              <Button
                variant="contained"
                fullWidth
                disabled={sending}
                onClick={onButtonClick}
                color="primary"
                size="large"
              >
                Send Me reset link
              </Button>
            </Stack>
          </motion.div>
        </>
      );
    }

    return (
      <Typography color={"white"} variant="subtitle1">
        Please check Your Email
      </Typography>
    );
  };

  return (
    <WashedForm>
      {showForm()}
      <Box mt={1} display={"flex"} justifyContent={"flex-start"}>
        <Typography color={"white"} fontWeight={500}>
          <Link to="/login">{"<"} Go Back</Link>
        </Typography>
      </Box>
    </WashedForm>
  );
};

export default PasswordForgot;
