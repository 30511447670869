import { ApiService, ApiSyncType } from "@incendium/api";
import { Typography } from "@mui/material";
import SyncAccountConfigDrawer from "features/dataConnectors/components/SyncAccountConfigDrawer";
import { ISyncConfigComponentProps } from "features/dataConnectors/types";

function Ecwid({
  oauthClient,
  syncs,
  open,
  setOpen,
}: ISyncConfigComponentProps) {
  return (
    <SyncAccountConfigDrawer
      oauthClient={oauthClient}
      syncs={syncs}
      provider="ecwid"
      type={ApiSyncType.ECWID_API}
      open={open}
      setOpen={setOpen}
      service={ApiService.ECWID}
      features={["connect"]}
      connectDescription={
        <>
          <Typography variant="body2">
            Select You store to link to this project.
          </Typography>
          <Typography variant="body2">
            This will allow us to pull your products, enable webhooks and
            install our pixel.
          </Typography>
        </>
      }
    />
  );
}

export default Ecwid;
