/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiEffectivenessConditionFromJSON, ApiEffectivenessConditionToJSON, } from './ApiEffectivenessCondition';
import { ApiEffectivenessRuleFromJSON, ApiEffectivenessRuleToJSON, } from './ApiEffectivenessRule';
export function ApiEffectivenessConditionGroupFromJSON(json) {
    return ApiEffectivenessConditionGroupFromJSONTyped(json, false);
}
export function ApiEffectivenessConditionGroupFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'rule': !exists(json, 'rule') ? undefined : ApiEffectivenessRuleFromJSON(json['rule']),
        'ruleId': !exists(json, 'ruleId') ? undefined : json['ruleId'],
        'conditions': !exists(json, 'conditions') ? undefined : (json['conditions'].map(ApiEffectivenessConditionFromJSON)),
    };
}
export function ApiEffectivenessConditionGroupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'rule': ApiEffectivenessRuleToJSON(value.rule),
        'ruleId': value.ruleId,
        'conditions': value.conditions === undefined ? undefined : (value.conditions.map(ApiEffectivenessConditionToJSON)),
    };
}
