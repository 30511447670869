import { Button, styled } from "@mui/material";
import clsx from "clsx";
export const TabButton = styled(
  ({
    isActive,
    ...rest
  }: React.ComponentProps<typeof Button> & { isActive?: boolean }) => {
    return (
      <Button
        {...rest}
        size="small"
        className={clsx(rest.className, { active: isActive })}
      />
    );
  }
)(({ theme, isActive }) => ({
  padding: theme.spacing(1, 2),
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  display: "block",
  lineHeight: "inherit",
  textTransform: "capitalize",
  maxWidth: 200,
  maxHeight: "36px",
  "&.MuiButtonGroup-grouped": {
    minWidth: isActive ? 150 : "initial",
  },
  "&.active": {
    backgroundColor: theme.palette.primary.dark,
    color: "#fff",
  },
  borderColor: "white",
  borderWidth: 2,

  "&:last-of-type": {
    borderTopRightRadius: 18,
    borderBottomRightRadius: 18,
  },
}));

TabButton.defaultProps = {
  color: "primary",
};
