/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiClassificationRulePeriodTypeFromJSON, ApiClassificationRulePeriodTypeToJSON, } from './ApiClassificationRulePeriodType';
import { ApiClassificationVariableFromJSON, ApiClassificationVariableToJSON, } from './ApiClassificationVariable';
import { ApiOperatorFromJSON, ApiOperatorToJSON, } from './ApiOperator';
export function ApiClassificationRulePayloadFromJSON(json) {
    return ApiClassificationRulePayloadFromJSONTyped(json, false);
}
export function ApiClassificationRulePayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'variable': !exists(json, 'variable') ? undefined : ApiClassificationVariableFromJSON(json['variable']),
        'oldCondition': !exists(json, 'oldCondition') ? undefined : json['oldCondition'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'conversionId': !exists(json, 'conversionId') ? undefined : json['conversionId'],
        'operator': !exists(json, 'operator') ? undefined : ApiOperatorFromJSON(json['operator']),
        'periodType': !exists(json, 'periodType') ? undefined : ApiClassificationRulePeriodTypeFromJSON(json['periodType']),
        'period': !exists(json, 'period') ? undefined : json['period'],
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'locationPageTaggerId': !exists(json, 'locationPageTaggerId') ? undefined : json['locationPageTaggerId'],
        'pageTagValue': !exists(json, 'pageTagValue') ? undefined : json['pageTagValue'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'leadStatusId': !exists(json, 'leadStatusId') ? undefined : json['leadStatusId'],
    };
}
export function ApiClassificationRulePayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'variable': ApiClassificationVariableToJSON(value.variable),
        'oldCondition': value.oldCondition,
        'value': value.value,
        'key': value.key,
        'conversionId': value.conversionId,
        'operator': ApiOperatorToJSON(value.operator),
        'periodType': ApiClassificationRulePeriodTypeToJSON(value.periodType),
        'period': value.period,
        'locationId': value.locationId,
        'locationPageTaggerId': value.locationPageTaggerId,
        'pageTagValue': value.pageTagValue,
        'productId': value.productId,
        'leadStatusId': value.leadStatusId,
    };
}
