import {
  ApiCreateUpdatePersonalisationPayload,
  ApiInsertionType,
  ApiSimplePersonalisation,
} from "@incendium/api";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { personalisationService } from "Apis";
import CustomDialog from "Components/CustomDialog/CustomDialog";
import Loading from "Components/Loading/Loading";
import LocationDropdown from "Components/Location/LocationDropdown";
import { enumToArray, formatEnumVal } from "Helpers/enumToText";
import { useNotification, useSelectedProject } from "Hooks";
import { useComponents } from "Hooks/useComponents";
import { GenericDialoagProps } from "Interfaces";
import produce from "immer";
import { useCallback, useEffect, useState } from "react";
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import { usePrevious } from "react-use";
import { useConfirmationContext } from "Providers/ConfirmationProvider";

interface IPersonalisationDialogProps extends GenericDialoagProps {
  personlisation: ApiSimplePersonalisation;
  campaignId?: number;
  onSaved: (c: ApiSimplePersonalisation) => void;
}

function PersonalisationDialog({
  open,
  setOpen,
  campaignId,
  personlisation: originalPersonalisation,
  onSaved,
}: IPersonalisationDialogProps) {
  const { selectedProject } = useSelectedProject();
  const { components, loading } = useComponents();
  const [personlisation, setPersonlisation] = useState(originalPersonalisation);
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const prevOpen = usePrevious(open);

  useEffect(() => {
    if (!prevOpen && open) {
      setPersonlisation(originalPersonalisation);
    }
  }, [open, prevOpen, originalPersonalisation]);

  const onClose = useCallback(() => {
    setOpen(false);
    setPersonlisation({});
  }, [setOpen]);

  const onSubmit = useCallback(async () => {
    const payload: ApiCreateUpdatePersonalisationPayload = {
      ...personlisation,
      campaignId,
    };
    try {
      const fn = personlisation.id
        ? personalisationService.personalisationServiceUpdatePersonalisation({
            projectId: selectedProject?.id as number,
            personalisationId: personlisation.id,
            payload,
          })
        : personalisationService.personalisationServiceCreatePersonalisation({
            projectId: selectedProject?.id as number,
            payload,
          });

      const res = await fn;
      onSaved(res.personalisation || {});
      showSuccessNotification(`${personlisation.name} Saved`);
      onClose();
    } catch (error: any) {
      const t = await error.json();
      if (t.code === 9) {
        openConfirmation({
          title: `This Personalisation is used in active campaign.`,
          body: "Making changes to this personalization might result in inaccuracies in your analytics. Do you wish to proceed?",
          callback: async () => {
            try {
              const res =
                await personalisationService.personalisationServiceUpdatePersonalisation(
                  {
                    projectId: selectedProject?.id as number,
                    personalisationId: personlisation.id as number,
                    payload,
                    token: t.details[0].message,
                  }
                );

              onSaved(res.personalisation || {});
              showSuccessNotification(`${personlisation.name} Saved`);
              onClose();
            } catch (error) {
              showErrorNotification(
                `Internal Error deleting personalisation, please try again`
              );
            }
            closeConfirmation();
          },
        });
        return;
      }

      showErrorNotification(
        `Internal Error saving Personalization settings, please try again`
      );
    }
  }, [
    personlisation,
    showSuccessNotification,
    showErrorNotification,
    onSaved,
    selectedProject?.id,
    onClose,
    campaignId,
    openConfirmation,
    closeConfirmation,
  ]);

  return (
    <CustomDialog onClose={onClose} fullWidth maxWidth="sm" open={open}>
      <DialogTitle>
        Setup Personalisation, setting where and what to insert.
      </DialogTitle>
      {loading ? (
        <Loading />
      ) : components.length === 0 ? (
        <DialogContent>
          <Typography variant="subtitle2">
            It Looks like you dont have any Components created yet.
          </Typography>
        </DialogContent>
      ) : (
        <ValidatorForm onSubmit={onSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextValidator
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Personalisation Name"
                  name="name"
                  value={personlisation.name || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPersonlisation(
                      produce(personlisation, (draft) => {
                        draft.name = e.target.value;
                      })
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextValidator
                  variant="outlined"
                  fullWidth
                  label="Personalisation Description"
                  rows={4}
                  name="description"
                  validators={["maxStringLength:250"]}
                  errorMessages={[
                    "Campaign description can only be 250 characters long",
                  ]}
                  value={personlisation.description || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPersonlisation(
                      produce(personlisation, (draft) => {
                        draft.description = e.target.value;
                      })
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <LocationDropdown
                  label="Run on this location"
                  size={"small"}
                  variant="outlined"
                  fullWidth
                  value={personlisation.locationId}
                  onChange={(id) => {
                    setPersonlisation(
                      produce(personlisation, (draft) => {
                        draft.locationId = id;
                      })
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectValidator
                  size={"small"}
                  variant="outlined"
                  fullWidth
                  label="Which Component"
                  name="component"
                  value={personlisation.componentId || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPersonlisation(
                      produce(personlisation, (draft) => {
                        draft.componentId = Number(e.target.value);
                      })
                    );
                  }}
                >
                  {(components || []).map((comp) => (
                    <MenuItem key={comp.id} value={comp.id}>
                      {comp.name}
                    </MenuItem>
                  ))}
                </SelectValidator>
              </Grid>

              <Grid item xs={7}>
                <TextValidator
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Add to this css selector"
                  name="appliedTo"
                  value={personlisation.appliedTo || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPersonlisation(
                      produce(personlisation, (draft) => {
                        draft.appliedTo = e.target.value;
                      })
                    );
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <SelectValidator
                  size={"small"}
                  variant="outlined"
                  fullWidth
                  label="How to insert"
                  name="insertionType"
                  validators={["maxStringLength:250"]}
                  errorMessages={[
                    "Campaign description can only be 250 characters long",
                  ]}
                  value={personlisation.insertionType || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPersonlisation(
                      produce(personlisation, (draft) => {
                        draft.insertionType = e.target
                          .value as ApiInsertionType;
                      })
                    );
                  }}
                >
                  {enumToArray(ApiInsertionType)
                    .filter((ins) => ins !== ApiInsertionType.IT_UNSPECIFIED)
                    .map((ins) => (
                      <MenuItem key={ins} value={ins}>
                        {formatEnumVal(ins)}
                      </MenuItem>
                    ))}
                </SelectValidator>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="secondary">
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              {personlisation?.id ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </ValidatorForm>
      )}
    </CustomDialog>
  );
}

export default PersonalisationDialog;
