import {
  ApiLeadStatusRule,
  ApiLeadStatusType,
  ApiProject,
} from "@incendium/api";
import { leadStatusService } from "Apis";
import { useSharedState } from "./useSharedState";
import { useCallback } from "react";

const fetchLeadStatusRules = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const leadStatusRules =
      await leadStatusService.leadStatusServiceListLeadStatusRules({
        projectId: selectedProject.id as number,
      });
    return leadStatusRules.results || [];
  }
  return [];
};

export const useLeadStatusRules = () => {
  const { state, setState, loading } = useSharedState<
    ApiLeadStatusRule,
    ApiLeadStatusRule[]
  >("leadStatusRules", [], fetchLeadStatusRules);

  const statusToName = useCallback(
    (status: ApiLeadStatusType) => {
      if (!state || state.length === 0) {
        return "";
      }
      return state.find((l) => l.type === status)?.name || "";
    },
    [state]
  );

  return {
    leadStatusRules: state,
    setLeadStatusRules: setState,
    statusToName,
    loading,
  };
};
