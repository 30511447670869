import { ApiAPITokenResponse, ApiProject } from "@incendium/api";
import { APITokenService } from "Apis";
import { useSharedState } from "./useSharedState";

const fetchAPITokens = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const res = await APITokenService.aPITokenServiceListAPITokens({
      clientId: selectedProject.clientId as number,
      projectId: selectedProject.id as number,
    });
    return res.result || [];
  }
  return [];
};

export const useAPITokens = () => {
  const { state, setState } = useSharedState("apiTokens", [], fetchAPITokens);

  return {
    tokens: state as ApiAPITokenResponse[],
    setTokens: setState,
  };
};
