import { ValidatorForm } from "react-material-ui-form-validator";

export const setupCustomValidators = () => {
  ValidatorForm.addValidationRule("isDomain", (value) => {
    if (value === "") {
      return true;
    }

    const regex =
      /https?:\/\/(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\/\/=]*)/;
    return regex.test(value);
  });
  ValidatorForm.addValidationRule("isSubdomain", (value) => {
    if (value === "") {
      return true;
    }

    const regex = /^[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$/;
    return regex.test(value);
  });
  ValidatorForm.addValidationRule("isPath", (value) => {
    if (value === "") {
      return true;
    }

    const regex = /^(?!\/)[a-zA-Z0-9/\-_.]*$/;
    return regex.test(value);
  });
  return () => {
    ValidatorForm.removeValidationRule("isSubdomain");
    ValidatorForm.removeValidationRule("isDomain");
    ValidatorForm.removeValidationRule("isPath");
  };
};
