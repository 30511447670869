import { CircularProgress, Typography } from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import { TabWrapper } from "Components/UI/TabContainer";
import { enumToArray } from "Helpers/enumToText";
import { useCallback, useMemo, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import TabbedAnalysesPage from "Pages/Analyse/TabbedAnalysesPage";
import OrganicSocialOverview from "./OrganicSocialOverview";
import { useChannels } from "Hooks/useChannels";
import { ApiChannelDefault } from "@incendium/api";

enum Insight {
  overview = "Overview",
}

function MarketingPerformanceOrganicSocialPage() {
  const { insight } = useParams<{ insight: string }>();
  const [activeInsight, setActiveInsight] = useState(insight);

  const { channels } = useChannels();
  const socialChannelName = useMemo(() => {
    return (
      channels.find((c) => c._default === ApiChannelDefault.SOCIAL)?.name || ""
    );
  }, [channels]);

  const renderSwitch = useCallback(() => {
    switch (activeInsight) {
      case Insight.overview.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <OrganicSocialOverview channelName={socialChannelName} />{" "}
          </TabWrapper>
        );

      default:
        return (
          <TabWrapper>
            <CenterPage calcHeight>
              <Typography variant="subtitle1">Not Implemented</Typography>
            </CenterPage>
          </TabWrapper>
        );
    }
  }, [activeInsight, socialChannelName]);

  if (!insight || insight === "") {
    return <Redirect to={`./organic-social/overview`} />;
  }

  if (!socialChannelName || socialChannelName === "") {
    return (
      <CenterPage calcHeight>
        <CircularProgress />
        <Typography variant="h3" mt={2}>
          Fetching social channels
        </Typography>
      </CenterPage>
    );
  }

  return (
    <TabbedAnalysesPage
      title="Marketing Performance - Organic Social"
      activeTab={activeInsight}
      setActiveTab={setActiveInsight}
      tabs={enumToArray(Insight)}
      renderSwitch={renderSwitch}
    />
  );
}

export default MarketingPerformanceOrganicSocialPage;
