/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiConversionFromJSON, ApiConversionToJSON, } from './ApiConversion';
import { ApiFormFromJSON, ApiFormToJSON, } from './ApiForm';
import { ApiJobTaggerFromJSON, ApiJobTaggerToJSON, } from './ApiJobTagger';
import { ApiLocationItemFromJSON, ApiLocationItemToJSON, } from './ApiLocationItem';
import { ApiLocationPageTaggerFromJSON, ApiLocationPageTaggerToJSON, } from './ApiLocationPageTagger';
import { ApiPageFromJSON, ApiPageToJSON, } from './ApiPage';
import { ApiPageTypeFromJSON, ApiPageTypeToJSON, } from './ApiPageType';
import { ApiProductTaggerFromJSON, ApiProductTaggerToJSON, } from './ApiProductTagger';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
import { ApiProjectDefaultFromJSON, ApiProjectDefaultToJSON, } from './ApiProjectDefault';
export function ApiLocationFromJSON(json) {
    return ApiLocationFromJSONTyped(json, false);
}
export function ApiLocationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'urlMatch': !exists(json, 'urlMatch') ? undefined : json['urlMatch'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'parentLocationId': !exists(json, 'parentLocationId') ? undefined : json['parentLocationId'],
        'items': !exists(json, 'items') ? undefined : (json['items'].map(ApiLocationItemFromJSON)),
        '_default': !exists(json, 'default') ? undefined : ApiProjectDefaultFromJSON(json['default']),
        'automate': !exists(json, 'automate') ? undefined : json['automate'],
        'pageTypes': !exists(json, 'pageTypes') ? undefined : (json['pageTypes'].map(ApiPageTypeFromJSON)),
        'siblings': !exists(json, 'siblings') ? undefined : (json['siblings'].map(ApiLocationFromJSON)),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'pages': !exists(json, 'pages') ? undefined : (json['pages'].map(ApiPageFromJSON)),
        'taggers': !exists(json, 'taggers') ? undefined : (json['taggers'].map(ApiLocationPageTaggerFromJSON)),
        'showInAnalytics': !exists(json, 'showInAnalytics') ? undefined : json['showInAnalytics'],
        'conversions': !exists(json, 'conversions') ? undefined : (json['conversions'].map(ApiConversionFromJSON)),
        'productTaggers': !exists(json, 'productTaggers') ? undefined : (json['productTaggers'].map(ApiProductTaggerFromJSON)),
        'formTaggers': !exists(json, 'formTaggers') ? undefined : (json['formTaggers'].map(ApiFormFromJSON)),
        'jobTaggers': !exists(json, 'jobTaggers') ? undefined : (json['jobTaggers'].map(ApiJobTaggerFromJSON)),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
    };
}
export function ApiLocationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'project': ApiProjectToJSON(value.project),
        'name': value.name,
        'urlMatch': value.urlMatch,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'parentLocationId': value.parentLocationId,
        'items': value.items === undefined ? undefined : (value.items.map(ApiLocationItemToJSON)),
        'default': ApiProjectDefaultToJSON(value._default),
        'automate': value.automate,
        'pageTypes': value.pageTypes === undefined ? undefined : (value.pageTypes.map(ApiPageTypeToJSON)),
        'siblings': value.siblings === undefined ? undefined : (value.siblings.map(ApiLocationToJSON)),
        'projectId': value.projectId,
        'pages': value.pages === undefined ? undefined : (value.pages.map(ApiPageToJSON)),
        'taggers': value.taggers === undefined ? undefined : (value.taggers.map(ApiLocationPageTaggerToJSON)),
        'showInAnalytics': value.showInAnalytics,
        'conversions': value.conversions === undefined ? undefined : (value.conversions.map(ApiConversionToJSON)),
        'productTaggers': value.productTaggers === undefined ? undefined : (value.productTaggers.map(ApiProductTaggerToJSON)),
        'formTaggers': value.formTaggers === undefined ? undefined : (value.formTaggers.map(ApiFormToJSON)),
        'jobTaggers': value.jobTaggers === undefined ? undefined : (value.jobTaggers.map(ApiJobTaggerToJSON)),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
    };
}
