import { personalisationService } from "Apis";
import { useSharedState } from "./useSharedState";
import { ApiProject, ApiSimpleCampaign } from "@incendium/api";

const fetchCampaigns = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const res =
      await personalisationService.personalisationServiceListCampaigns({
        projectId: selectedProject.id as number,
      });
    return res.campaigns || [];
  }
  return [];
};
export const useCampaigns = () => {
  const { state, setState, loading, refetch } = useSharedState<
    ApiSimpleCampaign,
    ApiSimpleCampaign[]
  >("campaigns", [], fetchCampaigns);

  return {
    campaigns: state,
    setCampaigns: setState,
    loading,
    refetch,
  };
};
