/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiSyncType;
(function (ApiSyncType) {
    ApiSyncType["UNSPECIFIED"] = "SYNC_TYPE_UNSPECIFIED";
    ApiSyncType["GOOGLE_ADS"] = "SYNC_TYPE_GOOGLE_ADS";
    ApiSyncType["BING_ADS"] = "SYNC_TYPE_BING_ADS";
    ApiSyncType["FACEBOOK_ADS"] = "SYNC_TYPE_FACEBOOK_ADS";
    ApiSyncType["ECWID_API"] = "SYNC_TYPE_ECWID_API";
})(ApiSyncType || (ApiSyncType = {}));
export function ApiSyncTypeFromJSON(json) {
    return ApiSyncTypeFromJSONTyped(json, false);
}
export function ApiSyncTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiSyncTypeToJSON(value) {
    return value;
}
