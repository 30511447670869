/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function ApiClientInputFromJSON(json) {
    return ApiClientInputFromJSONTyped(json, false);
}
export function ApiClientInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'businessName': !exists(json, 'businessName') ? undefined : json['businessName'],
        'abn': !exists(json, 'abn') ? undefined : json['abn'],
        'billingAddress': !exists(json, 'billingAddress') ? undefined : json['billingAddress'],
        'invoiceAddressTo': !exists(json, 'invoiceAddressTo') ? undefined : json['invoiceAddressTo'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'hostName': !exists(json, 'hostName') ? undefined : json['hostName'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'ip': !exists(json, 'ip') ? undefined : json['ip'],
    };
}
export function ApiClientInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'firstName': value.firstName,
        'lastName': value.lastName,
        'businessName': value.businessName,
        'abn': value.abn,
        'billingAddress': value.billingAddress,
        'invoiceAddressTo': value.invoiceAddressTo,
        'website': value.website,
        'hostName': value.hostName,
        'timezone': value.timezone,
        'ip': value.ip,
    };
}
