import { ArrowDropDown, Check } from "@mui/icons-material";
import {
  alpha,
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useMemo, useState } from "react";
import {
  AttributionMetric,
  AttributionType,
} from "features/analytics/types/types";
import { AnalyticsAttributionIcon, useChartData } from "features/analytics";
import { useFromToContext } from "Providers/FromToProvider";
import { useSelectedProject } from "Hooks";
import { IChart } from "Interfaces";
import { ApiChartAttribute, ApiDimension, ApiMetric } from "@incendium/api";
import Loading from "Components/Loading/Loading";
import { CampaignAnalysisTableRow } from "features/attributionAnalytics";
import withPaidChannelAttributes from "HoC/withPaidChannelAttributes";

const StyledListTitle = styled(ListItemText)();
StyledListTitle.defaultProps = {
  primaryTypographyProps: {
    fontSize: 14,
    fontWeight: 500,
  },
};

const StyledPopper = styled(Popper)(() => ({
  zIndex: 2,
}));
StyledPopper.defaultProps = {
  placement: "bottom-end",
};

const StyledTopTableCell = styled(TableCell)(({ theme }) => ({
  cursor: "pointer",
  "&:hover": {
    background: alpha(theme.palette.secondary.main, 0.8),
    color: "white",
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  background: "rgba(239, 242, 251, 1)", // same colour as opacique background with no alpha
}));
const StyledListButton = styled(ListItemButton)(({ theme }) => ({
  "& svg:not(.MuiSvgIcon-root)": {
    width: 40,
    fill: theme.palette.primary.dark,
  },
  "& .MuiListItemText-root": {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

interface ICampaignAnalysisTableProps {
  title?: string;
  firstCols: { key: ApiDimension; text: string }[];
  onClick?: (v: string) => void;
  selecteValues: string[];
  headerComponent?: React.ReactNode;
  conversionMetric: AttributionMetric;
  setConversionMetric: (m: AttributionMetric) => void;
  attributionModel: AttributionType;
  setAttributionModel: (m: AttributionType) => void;
  publisherConversion: ApiMetric;
  setPublisherConversion: (m: ApiMetric) => void;
  filters: ApiChartAttribute[];
  currentStep: number;
  step: number;
  onTitleClick?: () => void;
  paidChannelAttributes: ApiChartAttribute[];
  onMetricClick?: (m: ApiMetric, d: ApiDimension, dv: string) => void;
  showProviderIcon?: boolean;
}

function CampaignAnalysisTable({
  title,
  firstCols,
  onClick,
  selecteValues,
  headerComponent,
  conversionMetric,
  setConversionMetric,
  attributionModel,
  setAttributionModel,
  publisherConversion,
  setPublisherConversion,
  filters,
  step,
  currentStep,
  onTitleClick,
  paidChannelAttributes,
  onMetricClick,
  showProviderIcon,
}: ICampaignAnalysisTableProps) {
  const { chartOutput } = useFromToContext();
  const { selectedProject } = useSelectedProject();
  const [anchorMetricEl, setAnchorMetricEl] = useState<null | HTMLElement>(
    null
  );
  const [anchorModelEl, setAnchorModelEl] = useState<null | HTMLElement>(null);
  const [anchorConversionEl, setAnchorConversionEl] =
    useState<null | HTMLElement>(null);
  const onMetricRefChange = useCallback((node) => {
    setAnchorMetricEl(node);
  }, []);
  const onModelRefChange = useCallback((node) => {
    setAnchorModelEl(node);
  }, []);
  const onConversionRefChange = useCallback((node) => {
    setAnchorConversionEl(node);
  }, []);
  const [metricOpen, setMetricOpen] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const [conversionOpen, setConversionOpen] = useState(false);
  const active = useMemo(() => step === currentStep, [step, currentStep]);

  const dimensions = useMemo(() => firstCols.map((f) => f.key), [firstCols]);
  const row1Height = useMemo(
    () => (firstCols.length > 1 ? 69 : 48.72),
    [firstCols]
  );
  const row2Height = useMemo(() => 73, []);

  const chart: IChart = useMemo(
    () => ({
      dimension: dimensions,
      // includeEmpty: true,
      yAxisKeys: [
        {
          key: "l",
          fields: [
            ApiMetric.METRIC_SESSIONS_COUNT,
            ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION,
            ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
            ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT,

            ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_MACRO_CONVERSION_COUNT,
            ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_SALES_COUNT,
            ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_REVENUE,
            ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_ROAS,
            ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_ROI,

            ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_MACRO_CONVERSION_COUNT,
            ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_SALES_COUNT,
            ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_REVENUE,
            ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROAS,
            ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROI,

            ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT,
            ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_SALES_COUNT,
            ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_REVENUE,
            ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROAS,
            ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROI,

            ApiMetric.METRIC_ATTRIBUTION_LINEAR_MACRO_CONVERSION_COUNT,
            ApiMetric.METRIC_ATTRIBUTION_LINEAR_SALES_COUNT,
            ApiMetric.METRIC_ATTRIBUTION_LINEAR_REVENUE,
            ApiMetric.METRIC_ATTRIBUTION_LINEAR_ROAS,
            ApiMetric.METRIC_ATTRIBUTION_LINEAR_ROI,

            ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_MACRO_CONVERSION_COUNT,
            ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_SALES_COUNT,
            ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_REVENUE,
            ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROAS,
            ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROI,

            ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT,
            ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_SALES_COUNT,
            ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_REVENUE,
            ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_ROAS,
            ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_ROI,

            ApiMetric.METRIC_PUBLISHER_CLICKS,
            ApiMetric.METRIC_PUBLISHER_CONVERSIONS,
            ApiMetric.METRIC_PUBLISHER_ALL_CONVERSIONS,
            ApiMetric.METRIC_SPEND,
            ApiMetric.METRIC_PUBLISHER_CPC,
            ApiMetric.METRIC_PUBLISHER_IMPRESSIONS,
            ApiMetric.METRIC_PUBLISHER_CTR,
            ApiMetric.METRIC_CALCULATED_CTR,
          ],
        },
      ],
      attributes: [...paidChannelAttributes, ...filters],
    }),
    [filters, dimensions, paidChannelAttributes]
  );

  const { chartData, loading } = useChartData(
    selectedProject,
    chart,
    chartOutput,
    !active
  );

  const attributionMetrics = useMemo(() => {
    return {
      [AttributionMetric.SALES]: "Actual Sales",
      [AttributionMetric.MACRO]: "Actual Conversions",
      [AttributionMetric.REVENUE]: "Actual Revenue",
      [AttributionMetric.ROI]: "Actual ROI",
      [AttributionMetric.ROAS]: "Actual ROAS",
    };
  }, []);
  const attributionModels = useMemo(() => {
    return {
      [AttributionType.FIRST]: "First Click",
      [AttributionType.LAST]: "Last Click",
      [AttributionType.LAST_NON]: "Last Non-Direct",
      [AttributionType.LINEAR]: "Linear",
      [AttributionType.POSITION]: "Position",
      [AttributionType.IMPACTED]: "Impacted",
    };
  }, []);
  const publisherConversions = useMemo(() => {
    return {
      [ApiMetric.METRIC_PUBLISHER_CONVERSIONS]: "Conversions",
      [ApiMetric.METRIC_PUBLISHER_ALL_CONVERSIONS]: "All Conversions",
    };
  }, []);

  const combinedData = useMemo(() => {
    return chartData?.data || [];
  }, [chartData?.data]);

  return (
    <AnimatePresence>
      <TableContainer
        sx={{
          overflowX: "auto",
          overflowY: "auto",
          userSelect: "none",
          maxHeight: `calc(100vh - 166px)`,
        }}
      >
        {active ? (
          <Table
            // sx={{ tableLayout: "fixed" }}
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.25 }}
            stickyHeader
          >
            <TableHead>
              {headerComponent && (
                <TableRow>
                  <StyledTableCell colSpan={13 + firstCols.length}>
                    {headerComponent}
                  </StyledTableCell>
                </TableRow>
              )}
              {title && (
                <TableRow>
                  <StyledTableCell colSpan={13 + firstCols.length}>
                    <Typography
                      variant="body2"
                      fontWeight={"bold"}
                      noWrap
                      color={"inherit"}
                    >
                      {title}
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              )}
              <TableRow>
                {firstCols.map((firstCol) => (
                  <StyledTableCell
                    key={firstCol.key}
                    rowSpan={2}
                    sx={{ top: row1Height }}
                  >
                    <strong>{firstCol.text}</strong>
                  </StyledTableCell>
                ))}

                <StyledTableCell colSpan={3} sx={{ top: row1Height }}>
                  <strong>Traffic Metrics</strong>
                </StyledTableCell>
                <StyledTableCell colSpan={3} sx={{ top: row1Height }}>
                  <strong>Click Through Metrics</strong>
                </StyledTableCell>
                <StyledTableCell colSpan={2} sx={{ top: row1Height }}>
                  <strong>Cost Metrics</strong>
                </StyledTableCell>
                <StyledTableCell colSpan={3} sx={{ top: row1Height }}>
                  <strong>Engagement Metrics</strong>
                </StyledTableCell>
                <StyledTableCell sx={{ top: row1Height }}>
                  <strong>Publisher Conversions</strong>
                </StyledTableCell>
                <StyledTableCell sx={{ top: row1Height }}>
                  <Stack direction="row" alignItems={"center"}>
                    <strong>{attributionMetrics[conversionMetric]}</strong>
                    <IconButton
                      ref={onMetricRefChange}
                      onClick={() => setMetricOpen(!metricOpen)}
                    >
                      <ArrowDropDown
                        sx={{
                          transition: "transform 0.15s linear",
                          transform: metricOpen ? "rotate(180deg)" : undefined,
                        }}
                      />
                    </IconButton>
                  </Stack>
                  <StyledPopper open={metricOpen} anchorEl={anchorMetricEl}>
                    <Paper>
                      <ClickAwayListener
                        onClickAway={() => setMetricOpen(false)}
                      >
                        <List disablePadding>
                          <ListItem>
                            <StyledListTitle primary="Conversion Metrics" />
                          </ListItem>
                          <Divider />
                          {Object.keys(attributionMetrics).map((k) => (
                            <ListItem key={k} disablePadding>
                              <StyledListButton
                                onClick={() => {
                                  setConversionMetric(k as AttributionMetric);
                                  setMetricOpen(false);
                                }}
                              >
                                <ListItemText primary={attributionMetrics[k]} />
                                {conversionMetric === k && (
                                  <ListItemIcon>
                                    <Check color="success" />
                                  </ListItemIcon>
                                )}
                              </StyledListButton>
                            </ListItem>
                          ))}
                        </List>
                      </ClickAwayListener>
                    </Paper>
                  </StyledPopper>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{ top: row2Height + row1Height }}
                >
                  Publisher Clicks
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ top: row2Height + row1Height }}
                >
                  Actual Sessions
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ top: row2Height + row1Height }}
                >
                  Difference (%)
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ top: row2Height + row1Height }}
                >
                  Impressions
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ top: row2Height + row1Height }}
                >
                  Publisher CTR
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ top: row2Height + row1Height }}
                >
                  Calculated CTR
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ top: row2Height + row1Height }}
                >
                  CPCs
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  sx={{ top: row2Height + row1Height }}
                >
                  Total Spend
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ top: row2Height + row1Height }}
                >
                  Avg. Time on Page
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ top: row2Height + row1Height }}
                >
                  Effective Session Rate
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ top: row2Height + row1Height }}
                >
                  Interaction Conv.
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ top: row2Height + row1Height }}
                >
                  <Stack direction="row" alignItems={"center"}>
                    <strong>{publisherConversions[publisherConversion]}</strong>
                    <IconButton
                      ref={onConversionRefChange}
                      onClick={() => setConversionOpen(!conversionOpen)}
                    >
                      <ArrowDropDown
                        sx={{
                          transition: "transform 0.15s linear",
                          transform: metricOpen ? "rotate(180deg)" : undefined,
                        }}
                      />
                    </IconButton>
                  </Stack>
                  <StyledPopper
                    open={conversionOpen}
                    anchorEl={anchorConversionEl}
                  >
                    <Paper>
                      <ClickAwayListener
                        onClickAway={() => setConversionOpen(false)}
                      >
                        <List disablePadding>
                          <ListItem>
                            <StyledListTitle primary="Publisher Conversions" />
                          </ListItem>
                          <Divider />
                          {Object.keys(publisherConversions).map((k) => (
                            <ListItem key={k} disablePadding>
                              <StyledListButton
                                onClick={() => {
                                  setPublisherConversion(k as ApiMetric);
                                  setConversionOpen(false);
                                }}
                              >
                                <ListItemText
                                  primary={publisherConversions[k]}
                                />
                                {publisherConversion === k && (
                                  <ListItemIcon>
                                    <Check color="success" />
                                  </ListItemIcon>
                                )}
                              </StyledListButton>
                            </ListItem>
                          ))}
                        </List>
                      </ClickAwayListener>
                    </Paper>
                  </StyledPopper>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ top: row2Height + row1Height }}
                >
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                  >
                    <Box>{attributionModels[attributionModel]}</Box>

                    <IconButton
                      ref={onModelRefChange}
                      onClick={() => setModelOpen(!modelOpen)}
                    >
                      <ArrowDropDown
                        sx={{
                          transition: "transform 0.15s linear",
                          transform: modelOpen ? "rotate(180deg)" : undefined,
                        }}
                      />
                    </IconButton>
                  </Stack>
                  <StyledPopper open={modelOpen} anchorEl={anchorModelEl}>
                    <Paper>
                      <ClickAwayListener
                        onClickAway={() => setModelOpen(false)}
                      >
                        <List disablePadding>
                          <ListItem>
                            <StyledListTitle primary="Attribution Models" />
                          </ListItem>
                          <Divider />
                          {Object.keys(attributionModels).map((k) => (
                            <ListItem key={k} disablePadding>
                              <StyledListButton
                                onClick={() => {
                                  setAttributionModel(k as AttributionType);
                                  setModelOpen(false);
                                }}
                              >
                                <AnalyticsAttributionIcon
                                  model={k as AttributionType}
                                />

                                <ListItemText primary={attributionModels[k]} />
                                {attributionModel === k && (
                                  <ListItemIcon>
                                    <Check color="success" />
                                  </ListItemIcon>
                                )}
                              </StyledListButton>
                            </ListItem>
                          ))}
                        </List>
                      </ClickAwayListener>
                    </Paper>
                  </StyledPopper>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <StyledTableCell colSpan={10 + firstCols.length}>
                    <Loading />
                  </StyledTableCell>
                </TableRow>
              ) : combinedData.length === 0 ? (
                <TableRow>
                  <StyledTableCell
                    align="center"
                    colSpan={10 + firstCols.length}
                  >
                    No Data Found
                  </StyledTableCell>
                </TableRow>
              ) : (
                combinedData.map((d, i) => (
                  <CampaignAnalysisTableRow
                    current
                    key={i}
                    data={d}
                    firstCols={firstCols}
                    attributionModel={attributionModel}
                    conversionMetric={conversionMetric}
                    publisherConversion={publisherConversion}
                    selecteValues={selecteValues}
                    dimension={dimensions[0]}
                    onClick={onClick}
                    onMetricClick={onMetricClick}
                    showProviderIcon={showProviderIcon}
                  />
                ))
              )}
            </TableBody>
          </Table>
        ) : step < currentStep ? (
          <Table sx={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <StyledTopTableCell onClick={onTitleClick}>
                  <Typography
                    variant="body2"
                    fontWeight={"bold"}
                    noWrap
                    color={"inherit"}
                  >
                    {title}
                  </Typography>
                </StyledTopTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {combinedData.map((d, i) => (
                <CampaignAnalysisTableRow
                  current={false}
                  key={i}
                  data={d}
                  firstCols={firstCols}
                  attributionModel={attributionModel}
                  conversionMetric={conversionMetric}
                  publisherConversion={publisherConversion}
                  selecteValues={selecteValues}
                  dimension={dimensions[0]}
                  onClick={onClick}
                  onMetricClick={onMetricClick}
                  showProviderIcon={showProviderIcon}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <Table sx={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <StyledTopTableCell onClick={onTitleClick}>
                  <Typography
                    variant="body2"
                    fontWeight={"bold"}
                    noWrap
                    color={"inherit"}
                  >
                    {title}
                  </Typography>
                </StyledTopTableCell>
              </TableRow>
            </TableHead>
          </Table>
        )}
      </TableContainer>
    </AnimatePresence>
  );
}

export default withPaidChannelAttributes(CampaignAnalysisTable);
