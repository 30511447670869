import { ApiPage } from "@incendium/api";
import { Search } from "@mui/icons-material";
import {
  CircularProgress,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Link,
} from "@mui/material";
import { Box } from "@mui/system";
import { locationService } from "Apis";
import { StyledSearchResults } from "Components/UI/StyledSearchResults";
import { useSelectedProject } from "Hooks";
import { useEffect, useMemo, useState } from "react";
import { friendlyDate } from "Helpers/dates";

function PagesWithNoLocationList({
  limit,
  onShowMore,
}: {
  limit?: number;
  onShowMore?: () => void;
}) {
  const { selectedProject: project } = useSelectedProject();

  const [pages, setPages] = useState<ApiPage[]>([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    loadPages();
  }, []);

  const filteredPages = useMemo(() => {
    return (pages || [])
      .filter((p) => {
        if ((p.displayPage || "").includes(search)) {
          return true;
        }
        try {
          return new RegExp(search).test(p.displayPage || "");
        } catch (e) {
          return false;
        }
      })
      .slice(0, limit || 500);
  }, [search, pages]);

  const loadPages = async () => {
    setLoading(true);
    const matchedPages =
      await locationService.locationServiceListPagesMissingLocations({
        projectId: project?.id as number,
      });
    if (matchedPages.results) {
      setPages(matchedPages.results || []);
    }
    setLoaded(true);
    setLoading(false);
  };

  if (loaded && pages.length === 0) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: 240, width: "100%" }}
      >
        <Typography variant="subtitle2" color="secondary">
          Great news
        </Typography>
        <Typography variant="body1">
          It looks like there are no Pages not linked to locations
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          {onShowMore ? (
            <Link
              color="primary"
              variant="body2"
              align="right"
              gutterBottom
              onClick={onShowMore}
              sx={{ cursor: "pointer" }}
            >
              Showing {filteredPages.length} of {pages.length}{" "}
              {limit && pages.length > limit && "(Show All)"}
            </Link>
          ) : (
            <Typography
              color="primary"
              variant="body2"
              align="right"
              gutterBottom
            >
              Showing {filteredPages.length} of {pages.length}
            </Typography>
          )}
        </Box>

        <TextField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          label="Search"
          fullWidth
          variant="outlined"
          size="small"
          helperText="Use text lookup, or regex e.g (\/[a-z-_]+){3} would be anything that
          is 3 levels deep i.e /my/cool/page"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledSearchResults mt={1}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid
              container
              spacing={1}
              item
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              {filteredPages.map((p) => (
                <Grid item xs={12} key={p.id}>
                  <Typography variant="body2" noWrap component="li">
                    {p.displayPage} - (first viewed{" "}
                    {friendlyDate(p.createdAt || p.updatedAt || "")})
                  </Typography>
                </Grid>
              ))}
            </Grid>
          )}
        </StyledSearchResults>
      </Grid>
    </>
  );
}
export default PagesWithNoLocationList;
