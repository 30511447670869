import { IStoreState } from "Interfaces";

export const clientSelector = (clientId?: string) => (state: IStoreState) => {
  if(!clientId){
    return null
  }
  const index = state.application.clients.list.findIndex(
    (item) => item.id === parseInt(clientId)
  );
  if (index === -1) {
    return null;
  }

  return state.application.clients.list[index];
};
