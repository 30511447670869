import {
  ApiAudience,
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiYAxisChartType,
} from "@incendium/api";
import { CircularProgress, Grid } from "@mui/material";
import { audienceService } from "Apis";
import AudienceDialog from "Components/Audience/AudienceDialog";
import AudienceList from "Components/Audience/AudienceList";
import { EmptyComponent } from "Components/EmptyComponent";
import { useSelectedProject } from "Hooks";
import { useAudiences } from "Hooks/useAudiences";
import produce from "immer";
import { useSnackbar } from "notistack";
import { useConfirmationContext } from "Providers/ConfirmationProvider";
import { useState } from "react";
import FromToProvider, { useFromToContext } from "Providers/FromToProvider";
import { useMount } from "react-use";
import { useHistory } from "react-router-dom";
import { AnalyticsCard } from "features/analytics";

function AudiencesPage() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [audience, setAudience] = useState<ApiAudience>({});
  const { selectedProject } = useSelectedProject();
  const { audiences, setAudiences, loading } = useAudiences();
  const { enqueueSnackbar } = useSnackbar();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { setLastNDays } = useFromToContext();

  useMount(() => {
    setLastNDays(30);
  });
  const newAudience = () => {
    setOpen(true);
    setAudience({});
  };

  const onEdit = (a: ApiAudience) => {
    setAudience(a);
    history.push(`audiences/${a.id}`);
  };
  const onDelete = (a: ApiAudience) => {
    openConfirmation({
      title: `Are you sure you want to delete this Audience`,
      body: `This action can not be undone`,
      callback: async () => {
        await audienceService.audienceServiceDeleteAudience({
          projectId: selectedProject?.id as number,
          audienceId: a.id as number,
        });

        setAudiences(
          produce(audiences, (draft) => {
            const idx = audiences.findIndex((aud) => aud.id === a.id);
            if (idx !== -1) draft.splice(idx, 1);
          })
        );

        enqueueSnackbar(`${a.name} Deleted`, {
          variant: "success",
          autoHideDuration: 2000,
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        closeConfirmation();
      },
    });
  };

  const onSaved = (audience: ApiAudience) => {
    setOpen(false);
    // update audiences
    const idx = audiences.findIndex((a) => a.id === audience.id);
    if (idx >= 0) {
      setAudiences(
        produce(audiences, (draft) => {
          draft[idx] = audience;
        })
      );
    } else {
      setAudiences(
        produce(audiences, (draft) => {
          draft.push(audience);
        })
      );
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (audiences.length === 0) {
    return (
      <>
        <EmptyComponent
          content="It looks like you don’t have any audiences set up."
          onBtnClick={newAudience}
          btnText="add audience"
        />
        <AudienceDialog
          open={open}
          setOpen={setOpen}
          audience={audience}
          onSaved={onSaved}
        />
      </>
    );
  }

  return (
    <FromToProvider initialLastNDays={2000}>
      {selectedProject ? (
        <>
          <Grid container spacing={5}>
            <Grid item xs={8} minHeight={370}>
              <AnalyticsCard
                chart={{
                  name: "Engaged Pageviews by top 5 Audience Segments",
                  dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.GRAPH,
                  limit: 5,
                  attributes: [],
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [
                        ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_COUNT,
                        ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                      ],
                      chart: {
                        [ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_COUNT]:
                          ApiYAxisChartType.BAR,
                        [ApiMetric.METRIC_PAGE_VIEWS_COUNT]:
                          ApiYAxisChartType.BAR,
                      },
                    },
                  ],
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <AnalyticsCard
                chart={{
                  name: "Pageviews by all Audiences",
                  dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.PIE,
                  attributes: [],
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_PAGE_VIEWS_COUNT],
                    },
                  ],
                  displayOptions: {
                    showTotals: true,
                  },
                }}
              />
            </Grid>

            <Grid item xs={23}>
              <AudienceList
                audiences={audiences}
                onEdit={onEdit}
                onDelete={onDelete}
                onClick={newAudience}
                project={selectedProject}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        "No Project selected"
      )}
      <AudienceDialog
        open={open}
        setOpen={setOpen}
        audience={audience}
        onSaved={onSaved}
      />
    </FromToProvider>
  );
}

export default AudiencesPage;
