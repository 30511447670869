/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiProjectDefault;
(function (ApiProjectDefault) {
    ApiProjectDefault["UNSPECIFIED"] = "DEFAULT_UNSPECIFIED";
    ApiProjectDefault["ECOMMERCE"] = "DEFAULT_ECOMMERCE";
    ApiProjectDefault["HOMEPAGE"] = "DEFAULT_HOMEPAGE";
    ApiProjectDefault["LOCATION_ALL"] = "DEFAULT_LOCATION_ALL";
    ApiProjectDefault["CONVERSION_CHECKOUT"] = "DEFAULT_CONVERSION_CHECKOUT";
    ApiProjectDefault["CONVERSION_ADD_TO_BASKET"] = "DEFAULT_CONVERSION_ADD_TO_BASKET";
})(ApiProjectDefault || (ApiProjectDefault = {}));
export function ApiProjectDefaultFromJSON(json) {
    return ApiProjectDefaultFromJSONTyped(json, false);
}
export function ApiProjectDefaultFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiProjectDefaultToJSON(value) {
    return value;
}
