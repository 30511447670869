import {
  ApiAttributionTag,
  ApiBucketResponse,
  ApiConditionCustom,
  ApiProject,
  BucketServiceUpdateBucketRequest,
} from "@incendium/api";
import { Box, Stack, Typography } from "@mui/material";
import { trafficBucketService } from "Apis";
import SourceRules from "features/channels/components/SourceRules";
import { useConditionGroupContext } from "Components/ConditionalGroup/ConditionGroupProvider";
import {
  SidebarContentWrapper,
  SidebarStyledDivider,
  SiderBarHeader,
} from "Components/UI/Sidebar";
import ConditionGroupsFormWrapper from "Components/ConditionalGroup/ConditionGroupsFormWrapper";
import { useTrafficBuckets } from "Hooks/useTrafficBuckets";
import produce from "immer";
import { useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import { useHistory } from "react-router-dom";
import AttributionTagSelector from "Components/AttributionTag/AttributionTagSelector";

interface ITrafficBucketFormProps {
  bucket: ApiBucketResponse;
  setBucket: React.Dispatch<
    React.SetStateAction<ApiBucketResponse | undefined>
  >;
  project: ApiProject;
}

function TrafficBucketForm({
  bucket,
  setBucket,
  project,
}: ITrafficBucketFormProps) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { conditionGroups, setConditionGroups } = useConditionGroupContext();
  const { trafficBuckets, setTrafficBuckets } = useTrafficBuckets();

  useEffect(() => {
    if (!bucket || conditionGroups.length) {
      return;
    }

    const groups = bucket.groups || [];

    // parse bucket groups as condition groups
    const parsedCGs = groups.map((g) => ({
      ...g,
      conditions: (g.rules || []).map((r) => ({
        ...r,
        custom: ApiConditionCustom.UNSPECIFIED,
        conversionId: null,
      })),
    }));

    setConditionGroups(
      parsedCGs && parsedCGs.length > 0
        ? [...parsedCGs]
        : [
            {
              conditions: [{}],
            },
          ]
    );
  }, [bucket, setConditionGroups, conditionGroups]);

  const onAttributionTagChange = useCallback(
    (tags: ApiAttributionTag[]) => {
      setBucket(
        produce(bucket, (draft) => {
          draft.tags = tags;
        })
      );
    },
    [bucket, setBucket]
  );

  const onSubmit = async () => {
    const parsedCGs = conditionGroups.map((cg) => ({
      ...cg,
      rules: cg.conditions || [],
    }));

    try {
      const req: BucketServiceUpdateBucketRequest = {
        projectId: project.id as number,
        bucketId: bucket.id as number,
        payload: {
          name: bucket.name,
          groups: parsedCGs,
          attributionTags: (bucket.tags || []).map((t) => t.id as number),
        },
      };
      const res = await trafficBucketService.bucketServiceUpdateBucket(req);
      enqueueSnackbar(`${res.name} Saved`, {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });

      setTrafficBuckets(
        produce(trafficBuckets, (draft) => {
          const idx = draft.findIndex((d) => d.id === res.id);
          if (idx >= 0) {
            draft[idx] = res;
          }
        })
      );
    } catch (error) {
      enqueueSnackbar(`Internal error, please try again`, {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }
  };

  const onCancel = () => {
    history.push(`../buckets`);
  };

  return (
    <>
      <ConditionGroupsFormWrapper
        onSubmit={onSubmit}
        onCancel={onCancel}
        rules={<SourceRules />}
      >
        <SiderBarHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography justifyContent="space-between" variant="h2">
              {bucket.name} Settings
            </Typography>
          </Box>
          <Typography variant="body1">
            Assign a name to this traffic bucket. You can also select tags for
            this bucket for grouping in analytics
          </Typography>
        </SiderBarHeader>
        <SidebarStyledDivider />
        <SidebarContentWrapper px={3}>
          <Stack spacing={2} mb={3.5} mt={2}>
            <TextValidator
              label="Name of Traffic Bucket"
              variant="outlined"
              name="name"
              fullWidth
              validators={["required"]}
              errorMessages={["name is required"]}
              value={bucket.name || ""}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setBucket(
                  produce(bucket, (draft) => {
                    draft.name = e.currentTarget.value;
                  })
                );
              }}
            />
            <AttributionTagSelector
              size="medium"
              label="Tag this bucket"
              selectedTags={bucket.tags || []}
              onChange={onAttributionTagChange}
            />
          </Stack>
        </SidebarContentWrapper>
      </ConditionGroupsFormWrapper>
    </>
  );
}

export default TrafficBucketForm;
