import { ButtonProps, Fab } from "@mui/material";
import { useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AnimatePresence, motion } from "framer-motion";
import { useMemo } from "react";

interface IAnimatedFabProps extends ButtonProps {
  visible: boolean;
  size?: "small" | "medium" | "large";
}

const useAnimatedFabStyle = makeStyles((theme) => ({
  root: {
    position: "absolute",
    bottom: theme.spacing(8),
    right: theme.spacing(8),
    minWidth: 0,
    zIndex: 9999,
  },
  inner: {
    display: "flex",
    "& > svg": {
      marginRight: theme.spacing(1),
    },
  },
}));

function AnimatedFab({
  onClick,
  children,
  visible,
  ...rest
}: IAnimatedFabProps) {
  const classes = useAnimatedFabStyle();

  const clicked = (e: any) => onClick && onClick(e);

  const theme = useTheme();
  const height = useMemo(() => {
    switch (rest.size) {
      case "small":
        return 30;

      case "medium":
        return theme.spacing(5);
      case "large":
        return 42;
      default:
        return theme.spacing(5);
    }
  }, [rest.size, theme]);

  return (
    <AnimatePresence>
      {visible && (
        <Fab
          variant="extended"
          color={rest.color as any}
          size={rest.size}
          onClick={clicked}
          className={classes.root}
          component={motion.div}
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{
            height: height,
            borderRadius: "50%!important",
            y: 100,
            opacity: 0,
            transition: {
              opacity: {
                delay: 0.23,
              },
              y: {
                delay: 0.23,
              },
            },
          }}
        >
          <motion.div
            className={classes.inner}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{
              opacity: 0,
              width: 10,
            }}
            transition={{
              opacity: {
                duration: 0.01,
              },
              width: {
                duration: 0.14,
              },
            }}
          >
            {children}
          </motion.div>
        </Fab>
      )}
    </AnimatePresence>
  );
}

export default AnimatedFab;
