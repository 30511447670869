import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiYAxisChartType,
} from "@incendium/api";
import { Grid } from "@mui/material";
import { AnalyticsSpacing } from "consts";
import { AnalyticsCard, metricToName } from "features/analytics";
import { useState } from "react";

function DevicesInsights() {
  const [metric, setMetric] = useState<ApiMetric>(
    ApiMetric.METRIC_SESSIONS_COUNT
  );

  return (
    <Grid container spacing={AnalyticsSpacing} mb={4}>
      <Grid item xs={4} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          chart={{
            name: `${metricToName(metric)} Composition by Devices`,
            dimension: [ApiDimension.DIMENSION_DEVICE],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.PIE,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
              },
            ],
            displayOptions: {
              showTotals: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={8} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          chart={{
            name: "Device Type by",
            dimension: [
              ApiDimension.DIMENSION_SESSION_START_BY_DAY,
              ApiDimension.DIMENSION_DEVICE,
            ],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                chart: {
                  [ApiMetric.METRIC_SESSIONS_COUNT]: ApiYAxisChartType.AREA,
                  [ApiMetric.METRIC_EFFECTIVE_SESSION_RATE]:
                    ApiYAxisChartType.LINE,
                  [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE]:
                    ApiYAxisChartType.LINE,
                },
              },
            ],
            displayOptions: {
              availableMetrics: [
                ApiMetric.METRIC_SESSIONS_COUNT,
                ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
              ],
            },
          }}
          onChangeMetric={setMetric}
        />
      </Grid>
      <Grid item xs={12} sx={{ minHeight: 272 }}>
        <AnalyticsCard
          chart={{
            name: ``,
            dimension: [ApiDimension.DIMENSION_DEVICE],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
                  ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE,
                  ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE,
                  ApiMetric.METRIC_AVERAGE_SCROLL_SPEED,
                  ApiMetric.METRIC_AVERAGE_PAGEVIEWS_PER_SESSION,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_TO_CONVERSION_RATE,
                  ApiMetric.METRIC_SESSION_REVENUE,
                ],
              },
            ],
          }}
        />
      </Grid>
    </Grid>
  );
}

export default DevicesInsights;
