import { TimelineSeparator } from "@mui/lab";
import { Typography, useTheme } from "@mui/material";
import {
  StyledTimelineConnector,
  StyledTimelineContent,
  StyledTimelineDot,
  StyledTimelineOppositeContent,
  StyledTimelineSubTitle,
  StyledTimelineTitle,
} from "Components/UI/Timeline";
import {
  friendlyDate,
  friendlyDateTime,
  friendlyTime,
  secondsToMinSecondsText,
} from "Helpers/dates";
import {
  ExtraUserActionType,
  IUserAction,
} from "features/leadAnalytics/types/types";
import { actionTypeToName } from "features/leadAnalytics";
import { formatNumber } from "Helpers/numbers";
import { ReactComponent as EffectiveStar } from "Assets/Images/effective-star.svg";
import {
  ApiLeadScoreReason,
  ApiLeadStatusType,
  ApiRetentionStatus,
  ApiUserActionType,
} from "@incendium/api";
import { formatPercentage } from "Helpers/percentage";
import { ReactComponent as Medal } from "Assets/Images/icon-medal.svg";
import { ReactComponent as Person } from "Assets/Images/icon-person.svg";
import { useLeadStatusRules } from "Hooks/useLeadStatusRules";
import {
  leadScoreReasonToName,
  retentionStatusToName,
} from "Helpers/formatters";

interface ILeadJourneyItemProps {
  item: IUserAction;
  index: number;
  isActive: boolean;
  itemsLength: number;
  onClick?: (item: IUserAction) => void;
}

function LeadJourneyItem({
  item,
  index,
  isActive,
  itemsLength,
  onClick,
}: ILeadJourneyItemProps) {
  const theme = useTheme();
  const { statusToName } = useLeadStatusRules();

  switch (item.type) {
    case ExtraUserActionType.VISIT_START:
      return (
        <>
          <StyledTimelineOppositeContent align="right" size="large">
            <Typography variant="body2">
              {friendlyDate(item.timestamp)}
            </Typography>
            <Typography variant="body2">
              {friendlyTime(item.timestamp)}
            </Typography>
          </StyledTimelineOppositeContent>
          <TimelineSeparator>
            <StyledTimelineConnector opacity={index > 0 ? 1 : 0} />
            <StyledTimelineDot
              isactive={isActive}
              bg={theme.palette.charts[1]}
              size="large"
              onClick={() => onClick && onClick(item)}
            ></StyledTimelineDot>

            <StyledTimelineConnector
              opacity={index < itemsLength - 1 ? 1 : 0}
            />
          </TimelineSeparator>
          <StyledTimelineContent>
            <StyledTimelineTitle>
              {actionTypeToName(item.type)}
            </StyledTimelineTitle>
            <StyledTimelineSubTitle>
              Source: {item.pageView?.source}
            </StyledTimelineSubTitle>
            {(item.pageView?.sessionAudiences || []).length > 0 && (
              <StyledTimelineSubTitle>
                Audiences: {(item.pageView?.sessionAudiences || []).join(", ")}
              </StyledTimelineSubTitle>
            )}
          </StyledTimelineContent>
        </>
      );
    case ExtraUserActionType.VISIT_END:
      return (
        <>
          <StyledTimelineOppositeContent align="right">
            <Typography variant="body2">
              {friendlyDateTime(item.endDate || item.timestamp)}
            </Typography>
            {item.sessionRevenue ? (
              <>
                <Typography variant="body2">
                  revenue: ${formatNumber((item.sessionRevenue || 0) / 100)}
                </Typography>
                {(item.sessionPurchasedProducts || []).length > 0 && (
                  <Typography variant="body2">
                    products: {item.sessionPurchasedProducts?.join(", ")}
                  </Typography>
                )}
              </>
            ) : (
              <></>
            )}
          </StyledTimelineOppositeContent>
          <TimelineSeparator>
            <StyledTimelineConnector opacity={index > 0 ? 1 : 0} />
            <StyledTimelineDot
              isactive={isActive}
              bg={theme.palette.charts[3]}
              size="small"
            >
              {item.pageView?.isSessionEffective && <EffectiveStar />}
            </StyledTimelineDot>
            <StyledTimelineConnector
              opacity={index < itemsLength - 1 ? 1 : 0}
            />
          </TimelineSeparator>
          <StyledTimelineContent>
            <StyledTimelineTitle>Visit end</StyledTimelineTitle>
            <StyledTimelineSubTitle>
              {item.pageView?.isSessionEffective
                ? "effective"
                : "non-effective"}
            </StyledTimelineSubTitle>
          </StyledTimelineContent>
        </>
      );
    case ApiUserActionType.PAGE_VIEW:
      return (
        <>
          <StyledTimelineOppositeContent align="right">
            <Typography variant="body2">
              {item.pageView?.locations?.join(", ")}
            </Typography>
            <Typography variant="body2">
              {friendlyTime(item.timestamp)}
            </Typography>
          </StyledTimelineOppositeContent>
          <TimelineSeparator>
            <StyledTimelineConnector opacity={index > 0 ? 1 : 0} />
            <StyledTimelineDot
              isactive={isActive}
              bg={theme.palette.charts[6]}
              size="small"
            >
              {item.pageView?.isEffective && <EffectiveStar />}
            </StyledTimelineDot>
            <StyledTimelineConnector
              opacity={index < itemsLength - 1 ? 1 : 0}
            />
          </TimelineSeparator>
          <StyledTimelineContent>
            <StyledTimelineTitle>Pageview</StyledTimelineTitle>
            <StyledTimelineSubTitle noWrap>
              {item.pageView?.displayPage}
            </StyledTimelineSubTitle>
            <StyledTimelineSubTitle noWrap>
              time on page:{" "}
              {formatNumber(item.pageView?.timeOnPageSeconds || 0)}s
            </StyledTimelineSubTitle>
            <StyledTimelineSubTitle noWrap>
              percentage of page viewed:{" "}
              {formatPercentage(item.pageView?.viewedPercentage || 0)}
            </StyledTimelineSubTitle>
            <StyledTimelineSubTitle noWrap>
              percentage of page scrolled:{" "}
              {formatPercentage(item.pageView?.scrolledPercentage || 0)}
            </StyledTimelineSubTitle>
          </StyledTimelineContent>
        </>
      );
    case ExtraUserActionType.GPROUPED_INTERACTION:
      return (
        <>
          <StyledTimelineOppositeContent align="right">
            <Typography variant="body2">
              {item.sessionPagviewsCount} pageview(s)
            </Typography>
            <Typography variant="body2">
              {secondsToMinSecondsText(item.sessionAvgTimOnPageSeconds || 0)}
            </Typography>
          </StyledTimelineOppositeContent>
          <TimelineSeparator>
            <StyledTimelineConnector opacity={index > 0 ? 1 : 0} />
            <StyledTimelineDot
              isactive={isActive}
              bg={theme.palette.charts[6]}
              size="small"
            ></StyledTimelineDot>
            <StyledTimelineConnector
              opacity={index < itemsLength - 1 ? 1 : 0}
            />
          </TimelineSeparator>
          <StyledTimelineContent>
            <StyledTimelineTitle>Interactions</StyledTimelineTitle>
            <StyledTimelineSubTitle>
              {item.microCount || 0} micro / {item.macroCount || 0} macro
            </StyledTimelineSubTitle>
          </StyledTimelineContent>
        </>
      );
    case ApiUserActionType.LEAD_STATUS:
      return (
        <>
          <StyledTimelineOppositeContent align="right">
            <Typography variant="body2">
              {friendlyDate(item.timestamp)}
            </Typography>
            <Typography variant="body2">
              {friendlyTime(item.timestamp)}
            </Typography>
          </StyledTimelineOppositeContent>
          <TimelineSeparator>
            <StyledTimelineConnector opacity={index > 0 ? 1 : 0} />
            <StyledTimelineDot
              isactive={isActive}
              bg={theme.palette.secondary.main}
              size="small"
            >
              <Person />
            </StyledTimelineDot>
            <StyledTimelineConnector
              opacity={index < itemsLength - 1 ? 1 : 0}
            />
          </TimelineSeparator>
          <StyledTimelineContent>
            <StyledTimelineTitle>New lead status</StyledTimelineTitle>
            <StyledTimelineSubTitle>
              {statusToName(item.leadStatus?.status as ApiLeadStatusType)}
            </StyledTimelineSubTitle>
          </StyledTimelineContent>
        </>
      );
    case ApiUserActionType.LEAD_SCORE:
      return (
        <>
          <StyledTimelineOppositeContent align="right">
            <Typography variant="body2">
              {friendlyDate(item.timestamp)}
            </Typography>
            <Typography variant="body2">
              {friendlyTime(item.timestamp)}
            </Typography>
          </StyledTimelineOppositeContent>
          <TimelineSeparator>
            <StyledTimelineConnector opacity={index > 0 ? 1 : 0} />
            <StyledTimelineDot
              isactive={isActive}
              bg={theme.palette.secondary.main}
              size="small"
            >
              <Medal />
            </StyledTimelineDot>
            <StyledTimelineConnector
              opacity={index < itemsLength - 1 ? 1 : 0}
            />
          </TimelineSeparator>
          <StyledTimelineContent>
            <StyledTimelineTitle>New lead score</StyledTimelineTitle>
            <StyledTimelineSubTitle>
              (
              {leadScoreReasonToName(
                item.leadScore?.reason as ApiLeadScoreReason
              )}
              ) {item.leadScore?.diff}
            </StyledTimelineSubTitle>
          </StyledTimelineContent>
        </>
      );
    case ApiUserActionType.RETENTION:
      return (
        <>
          <StyledTimelineOppositeContent align="right">
            <Typography variant="body2">
              {friendlyDate(item.timestamp)}
            </Typography>
            <Typography variant="body2">
              {friendlyTime(item.timestamp)}
            </Typography>
          </StyledTimelineOppositeContent>
          <TimelineSeparator>
            <StyledTimelineConnector opacity={index > 0 ? 1 : 0} />
            <StyledTimelineDot
              isactive={isActive}
              bg={theme.palette.charts[4]}
              size="small"
            ></StyledTimelineDot>
            <StyledTimelineConnector
              opacity={index < itemsLength - 1 ? 1 : 0}
            />
          </TimelineSeparator>
          <StyledTimelineContent>
            <StyledTimelineTitle>New Retention</StyledTimelineTitle>
            <StyledTimelineSubTitle>
              {retentionStatusToName(
                item.retention?.status as ApiRetentionStatus
              )}
            </StyledTimelineSubTitle>
          </StyledTimelineContent>
        </>
      );
    case ExtraUserActionType.MACRO_CONVERSION:
    case ExtraUserActionType.MICRO_CONVERSION:
      return (
        <>
          <StyledTimelineOppositeContent align="right">
            <Typography variant="body2">
              {item.pageView?.locations?.join(", ")}
            </Typography>
            <Typography variant="body2">
              {friendlyTime(item.timestamp)}
            </Typography>
          </StyledTimelineOppositeContent>
          <TimelineSeparator>
            <StyledTimelineConnector opacity={index > 0 ? 1 : 0} />
            <StyledTimelineDot
              isactive={isActive}
              bg={theme.palette.charts[2]}
              size="small"
            ></StyledTimelineDot>
            <StyledTimelineConnector
              opacity={index < itemsLength - 1 ? 1 : 0}
            />
          </TimelineSeparator>
          <StyledTimelineContent>
            <StyledTimelineTitle>
              {item.type === ExtraUserActionType.MACRO_CONVERSION
                ? "Conversion"
                : "Interaction"}
            </StyledTimelineTitle>
            <StyledTimelineSubTitle>
              {item.conversionName}
            </StyledTimelineSubTitle>
          </StyledTimelineContent>
        </>
      );
    default:
      return <>todo</>;
  }
}

export default LeadJourneyItem;
