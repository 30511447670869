import {
  Alert,
  AlertTitle,
  Box,
  capitalize,
  CircularProgress,
  Typography,
} from "@mui/material";
import { StyledDrawerContainer } from "Components/UI/StyledDrawer";
import ConnectButton from "features/dataConnectors/components/ConnectButton";
import SyncAccountSelector from "features/dataConnectors/components/SyncAccountSelector";
import { useCallback, useMemo } from "react";
import { ISyncConfigComponentProps } from "features/dataConnectors/types";
import {
  ApiAccount,
  ApiProject,
  ApiService,
  ApiSync,
  ApiSyncType,
} from "@incendium/api";
import { TMappedApiAccount } from "Hooks/useSyncClients";
import { syncService } from "Apis";
import { CallbackOrVal } from "Interfaces";
import produce from "immer";
import { useNotification } from "Hooks";
import { TMappedApiUserOauthClient } from "Hooks/useSharedState";

interface ISyncConnectAdAccountProps
  extends Omit<ISyncConfigComponentProps, "open" | "setOpen"> {
  service: ApiService;
  type: ApiSyncType;
  provider: string;
  error: boolean;
  accountsLoading: boolean;
  accounts: TMappedApiAccount;
  externalSyncs: ApiSync[];
  setExternalSyncs: (val: CallbackOrVal<ApiSync[]>) => void;
  project: ApiProject;
  syncClients: TMappedApiUserOauthClient;
  setSyncClients: (val: CallbackOrVal<TMappedApiUserOauthClient>) => void;
  description?: string | React.ReactNode;
}

function SyncConnectAdAccount({
  oauthClient,
  service,
  syncs,
  type,
  provider,
  error,
  accountsLoading,
  accounts,
  project,
  externalSyncs,
  setExternalSyncs,
  syncClients,
  setSyncClients,
  description,
}: ISyncConnectAdAccountProps) {
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const isReady = useMemo(() => {
    return oauthClient?.readyToTypes?.includes(type);
  }, [oauthClient, type]);

  const connectionAccounts = useMemo(() => {
    return oauthClient?.id ? accounts[oauthClient.id] : [];
  }, [accounts, oauthClient?.id]);

  const linkAccount = useCallback(
    async (selectedAccount: ApiAccount) => {
      if (!selectedAccount?.externalId || !oauthClient?.id) {
        return;
      }

      try {
        const res = await syncService.syncServiceLinkAccount({
          projectId: project?.id as number,
          oauthClientId: oauthClient.id,
          payload: {
            externalId: selectedAccount?.externalId,
            type,
          },
        });

        setExternalSyncs(
          produce(externalSyncs, (draft) => {
            draft.push(res);
          })
        );

        showSuccessNotification(`Account ${selectedAccount.name} Linked.`);
      } catch (error) {
        showErrorNotification(`Failed to link ${selectedAccount.name}.`);
      }
    },
    [
      project,
      oauthClient?.id,
      showSuccessNotification,
      showErrorNotification,
      externalSyncs,
      setExternalSyncs,
      type,
    ]
  );

  return (
    <StyledDrawerContainer>
      {oauthClient?.id && !isReady ? (
        <Alert severity="warning">
          <AlertTitle>
            The {provider} connection you created lacks the necessary
            permissions to manage your ad account.
          </AlertTitle>
          Please remove the connection and reconnecting making sure the "See,
          edit, create and delete your Google Ads accounts and data." permission
          is selected.
        </Alert>
      ) : error ? (
        <Alert severity="warning">
          <AlertTitle>
            There was an error fetching data from your {provider} ad's account
          </AlertTitle>
          Please try again later.
        </Alert>
      ) : oauthClient?.id && isReady ? (
        <>
          <Typography variant="subtitle1">
            Configure {capitalize(provider)}
          </Typography>
          {description ? (
            <>{description}</>
          ) : (
            <>
              <Typography variant="body2">
                Select an Ad Account to link to this project.
              </Typography>
              <Typography variant="body2">
                This will the start to pull attribution and cost data for your
                ads.
              </Typography>
            </>
          )}
          <Box mt={3}>
            {accountsLoading && <CircularProgress size={20} />}
            {accounts && connectionAccounts && (
              <>
                {connectionAccounts.length === 0 && oauthClient.id ? (
                  <Alert severity="warning">
                    It looks like you dont have any ad accounts.
                  </Alert>
                ) : (
                  <SyncAccountSelector
                    connectionAccounts={connectionAccounts}
                    provider={provider}
                    onLinkAccount={linkAccount}
                    syncs={syncs || []}
                  />
                )}
              </>
            )}
          </Box>
        </>
      ) : (
        <Alert severity="warning">
          <AlertTitle>
            You have not connected your {provider} profile to incendium
          </AlertTitle>
          <Box mb={2}>
            {(syncs || []).length > 0 ? (
              <>
                It looks like a different user has connected their {provider}{" "}
                profile and added an ads account. If you would like to add one
                of your own accounts please connect your {provider} account
              </>
            ) : (
              <>
                To be able to Link a account to incendium you must first connect
                your {provider} account, close this dialog and click connect on
                the {provider} ads tile to connect.
              </>
            )}
          </Box>
          <ConnectButton
            service={service}
            syncClients={syncClients}
            setSyncClients={setSyncClients}
          />
        </Alert>
      )}
    </StyledDrawerContainer>
  );
}

export default SyncConnectAdAccount;
