/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function ApiShareParametersFromJSON(json) {
    return ApiShareParametersFromJSONTyped(json, false);
}
export function ApiShareParametersFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'reportId': !exists(json, 'reportId') ? undefined : json['reportId'],
        'from': !exists(json, 'from') ? undefined : (new Date(json['from'])),
        'to': !exists(json, 'to') ? undefined : (new Date(json['to'])),
        'lastNDays': !exists(json, 'lastNDays') ? undefined : json['lastNDays'],
    };
}
export function ApiShareParametersToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'clientId': value.clientId,
        'projectId': value.projectId,
        'timezone': value.timezone,
        'reportId': value.reportId,
        'from': value.from === undefined ? undefined : (value.from.toISOString()),
        'to': value.to === undefined ? undefined : (value.to.toISOString()),
        'lastNDays': value.lastNDays,
    };
}
