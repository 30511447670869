import {
  ApiForm,
  ApiFormRule,
  ApiAction,
  ApiActionType,
  ApiFormRuleType,
} from "@incendium/api";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Switch,
  Typography,
  styled,
  CircularProgress,
  SelectChangeEvent,
} from "@mui/material";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import { FormFieldsList } from "Components/FormFieldsList";
import { FormActionList } from "Components/FormActionList";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useIframeContext } from "Components/SmartIframe";
import { useNotification } from "Hooks";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { StyledButton, TextFieldHeading } from "Components/TagPages";
import { SidebarStyledDivider, SidebarSubtitle } from "Components/UI/Sidebar";

export const preventSubmitTooltipText =
  "Activating 'prevent submit' will give incendium full control of the user's journey from the point of form submission, rather than looping back to the website's default journey.";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
}));

const VerticalLine = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  height: 40,
  width: 1,
  margin: "0 auto",
}));

export const FormHandlersForm = ({
  form,
  onCancel,
  onAdd,
  onEdit,
}: {
  form?: ApiForm;
  onCancel: () => void;
  onEdit: (
    formData: ApiForm,
    formId: number,
    rulesToCreate: ApiFormRule[],
    rulesToDelete: ApiFormRule[],
    rulesToUpdate: ApiFormRule[],
    actionsToCreate: ApiAction[],
    actionsToDelete: ApiAction[],
    actionsToUpdate: ApiAction[]
  ) => void;
  onAdd: (
    formData: ApiForm,
    rulesToCreate: ApiFormRule[],
    actionsToCreate: ApiAction[]
  ) => void;
}) => {
  let init = useRef<Boolean>(true);
  const [loading, setLoading] = useState(false);

  const { showSuccessNotification } = useNotification();

  const [tempFormRulesToCreate, setTempFormRulesToCreate] = useState<
    ApiFormRule[]
  >([]);
  const [tempFormRulesToUpdate, setTempFormRulesToupdate] = useState<
    ApiFormRule[]
  >([]);
  const [formFieldsToDelete, setFormFieldsToDelete] = useState<ApiFormRule[]>(
    []
  );

  const [tempFormActionsToCreate, setTempFormActionsToCreate] = useState<
    ApiAction[]
  >([]);
  const [tempFormActionsToUpdate, setTempFormActionsToUpdate] = useState<
    ApiAction[]
  >([]);
  const [formActionsToDelete, setFormActionsToDelete] = useState<ApiAction[]>(
    []
  );

  const [formName, setFormName] = useState(form?.name || "");
  const [selector, setSelector] = useState("");
  const [preventSubmit, setPreventSubmit] = useState(
    form?.preventSubmit || false
  );

  const [fields, setFields] = useState<ApiFormRule[]>(form?.rules || []);
  const [selectedField, setSelectedField] = useState<
    string | null | ApiFormRule
  >();
  const [fieldName, setFieldName] = useState("");
  const [fieldType, setFieldType] = useState<ApiFormRuleType>(
    ApiFormRuleType.INPUT
  );
  const [mapFieldTo, setMapFieldTo] = useState("");
  const mapFields = [
    "first_name",
    "last_name",
    "email",
    "phone",
    "position",
    "industry",
  ];

  useEffect(() => {
    if (selectedField && typeof selectedField !== "string") {
      setFieldName(selectedField.target || "");
      setFieldType(selectedField.type || ApiFormRuleType.INPUT);
      setMapFieldTo(selectedField.mapping || "");
    }
  }, [selectedField]);

  const [actions, setActions] = useState<ApiAction[]>(form?.actions || []);
  const [selectedAction, setSelectedAction] = useState<
    string | null | ApiAction
  >();
  const [action, setAction] = useState<ApiActionType>(
    ApiActionType.EMAIL_CONTACT
  );
  const [manualInput, setManualInput] = useState(false);
  const [sendTo, setSendTo] = useState("");
  const [allExistingFieldNames, setAllExistingFieldNames] = useState<
    ApiFormRule[]
  >([]);
  const [existingFieldName, setExistingFieldName] = useState("");

  useEffect(() => {
    if (selectedAction && typeof selectedAction !== "string") {
      setAction(selectedAction.type || ApiActionType.EMAIL_CONTACT);
      setManualInput(false);
      setSendTo(selectedAction.target || "");
      setExistingFieldName(
        selectedAction.targetField ||
          (allExistingFieldNames.length > 0
            ? (allExistingFieldNames[0].mapping as string)
            : "")
      );
    }
  }, [selectedAction]);

  useEffect(() => {
    setAllExistingFieldNames([...fields]);
  }, []);

  useEffect(() => {
    if (allExistingFieldNames.length > 0) {
      setExistingFieldName(allExistingFieldNames[0].mapping as string);
    } else {
      setExistingFieldName("");
    }
  }, [allExistingFieldNames]);

  const handleFormHandlersSubmit = () => {
    setLoading(true);
    if (form) {
      onEdit(
        {
          name: formName,
          target: selector || "",
          preventSubmit: preventSubmit,
        },
        form.id as number,
        tempFormRulesToCreate,
        formFieldsToDelete,
        tempFormRulesToUpdate,
        tempFormActionsToCreate,
        formActionsToDelete,
        tempFormActionsToUpdate
      );
    } else {
      onAdd(
        {
          name: formName,
          target: selector || "",
          preventSubmit: preventSubmit,
        },
        tempFormRulesToCreate,
        tempFormActionsToCreate
      );
    }
    setLoading(false);
  };

  const handleFieldDelete = (field: ApiFormRule) => {
    const tempArr = tempFormRulesToCreate.filter(
      (rule) => rule.id !== field.id
    );
    setTempFormRulesToCreate(tempArr);
    setAllExistingFieldNames(
      allExistingFieldNames.filter((rule) => rule.id !== field.id)
    );
    if (form) {
      const isPermanentField = fields.find((f) => f.id === field.id);
      if (isPermanentField) {
        const newFields = fields.filter((f) => f.id !== field.id);
        setFields(newFields);
        setFormFieldsToDelete([...formFieldsToDelete, isPermanentField]);
      }
    }
    showSuccessNotification("field deleted");
  };

  const handleActionDelete = (action: ApiAction) => {
    const tempArr = tempFormActionsToCreate.filter((a) => a.id !== action.id);
    setTempFormActionsToCreate(tempArr);
    if (form) {
      const isPermanentAction = actions.find((f) => f.id === action.id);
      if (isPermanentAction) {
        const newActions = actions.filter((f) => f.id !== action.id);
        setActions(newActions);
        setFormActionsToDelete([...formActionsToDelete, isPermanentAction]);
      }
    }
    showSuccessNotification("action deleted");
  };

  const handleActionChange = (e: SelectChangeEvent) => {
    if (e.target.value === ApiActionType.EMAIL_CONTACT) {
      setAction(ApiActionType.EMAIL_CONTACT);
    } else {
      setAction(ApiActionType.REDIRECT);
    }
  };

  const handleFieldTypeChange = (e: SelectChangeEvent) => {
    if (e.target.value === ApiFormRuleType.FILE) {
      setFieldType(ApiFormRuleType.FILE);
    } else if (e.target.value === ApiFormRuleType.INPUT) {
      setFieldType(ApiFormRuleType.INPUT);
    } else {
      setFieldType(ApiFormRuleType.SEARCH_PARAM);
    }
  };

  const handleExistingFieldNameChange = (e: SelectChangeEvent) => {
    setExistingFieldName(e.target.value as string);
  };

  const handleFieldSave = () => {
    const newRule = {
      target: fieldName,
      type: fieldType,
      mapping: mapFieldTo,
      id:
        selectedField && typeof selectedField !== "string"
          ? selectedField.id
          : Math.random(),
    };
    setAllExistingFieldNames([...allExistingFieldNames, newRule]);
    if (typeof selectedField === "string") {
      setTempFormRulesToCreate([...tempFormRulesToCreate, newRule]);
      showSuccessNotification("mapping saved");
    } else {
      const isPermanentField = fields.find((f) => f.id === newRule.id);
      if (isPermanentField) {
        const newFields = fields.map((f) =>
          f.id === newRule.id ? newRule : f
        );
        setFields(newFields);
        const newRulesToUpdate = tempFormRulesToUpdate.filter(
          (r) => r.id !== newRule.id
        );
        setTempFormRulesToupdate([...newRulesToUpdate, newRule]);
      } else {
        const newRules = tempFormRulesToCreate.map((rule) =>
          rule.id === newRule.id ? newRule : rule
        );
        setTempFormRulesToCreate(newRules);
      }
      showSuccessNotification("mapping updated");
    }
    setFieldName("");
    setFieldType(ApiFormRuleType.INPUT);
    setMapFieldTo("");
    setSelectedField(null);
  };

  const handleActionSave = () => {
    const newAction: {
      type: ApiActionType;
      id: number;
      target?: string;
      targetField?: string;
    } = {
      type: action,
      id:
        selectedAction && typeof selectedAction !== "string"
          ? (selectedAction.id as number)
          : Math.random(),
    };

    if (!manualInput) {
      newAction.target = sendTo;
    } else {
      newAction.targetField = existingFieldName;
    }
    if (typeof selectedAction === "string") {
      setTempFormActionsToCreate([...tempFormActionsToCreate, newAction]);
      showSuccessNotification("Action saved");
    } else {
      const isPermanentAction = actions.find((a) => a.id === newAction.id);
      if (isPermanentAction) {
        const newActions = actions.map((a) =>
          a.id === newAction.id ? newAction : a
        );
        setActions(newActions);
        const newActionsToUpdate = tempFormActionsToUpdate.filter(
          (a) => a.id !== newAction.id
        );
        setTempFormActionsToUpdate([...newActionsToUpdate, newAction]);
      } else {
        const newActions = tempFormActionsToCreate.map((action) =>
          action.id === newAction.id ? newAction : action
        );
        setTempFormActionsToCreate(newActions);
      }
      showSuccessNotification("action updated");
    }
    setAction(ApiActionType.EMAIL_CONTACT);
    setManualInput(false);
    setSendTo("");
    setSelectedAction(null);
  };

  const mapYourFormTooltipText = (
    <>
      <p>
        Train incendium to recognise fields on your form by matching the fields
        name in your own HTML to incendium's field names in the drop-down menu.
        You can find the HTML of your own field by hovering over the field in
        the prview to the left.
      </p>
      <p>
        Example: if your want to tell incendium this is a first name field and
        hovering over the field shows the 'FNAME', then you should type FNAME as
        the 'field name' and match it with the incendium's 'first_name' from the
        drop-down. Note the field name is case-sensitive.
      </p>
      <p>
        If incendium does not have any appropriate category, you can type your
        own and this will be recorded as metadata.
      </p>
    </>
  );

  const { activateIframe, deactivateIframe, selectedSelector } =
    useIframeContext();

  useEffect(() => {
    deactivateIframe();
    activateIframe(["form"]);
    return () => deactivateIframe();
  }, []);

  useEffect(() => {
    if (init.current === true) {
      setSelector(form?.target || "");
      init.current = false;
    } else {
      setSelector(selectedSelector || "");
    }
  }, [selectedSelector]);

  useEffect(() => {
    activateIframe(["form"], selector);
  }, [selector]);

  return (
    <ValidatorForm onSubmit={handleFormHandlersSubmit}>
      <Box pt={1} pb={3}>
        <TextValidator
          label="name of form"
          value={formName}
          disabled={loading}
          variant="outlined"
          name="form name"
          fullWidth
          validators={["required"]}
          errorMessages={["form name is required"]}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setFormName(e.currentTarget.value)
          }
        />
      </Box>
      <Box paddingBottom={3}>
        <TextFieldHeading variant="body1" color="textPrimary">
          selector
        </TextFieldHeading>
        <Box marginBottom={1}>
          <Typography variant="caption" color="textSecondary">
            please select form on page preview
          </Typography>
        </Box>
        <TextValidator
          value={selector}
          variant="outlined"
          name="selector"
          fullWidth
          disabled={loading}
          validators={["required"]}
          errorMessages={["selector is required"]}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setSelector(e.currentTarget.value)
          }
        />
      </Box>
      <StyledFlexBox>
        <Box display="flex" alignItems="center">
          <Switch
            disabled={loading}
            checked={preventSubmit}
            onChange={(e, checked) => setPreventSubmit(checked)}
          />
          <Typography color={preventSubmit ? "primary" : "secondary"}>
            prevent submit
          </Typography>
        </Box>
        <ClickableTooltip
          text={preventSubmitTooltipText}
          icon={<StyledHelp />}
        />
      </StyledFlexBox>
      <Box my={2}>
        <SidebarStyledDivider />
      </Box>
      {/* map your form */}
      {!selectedAction && (
        <>
          <Box
            display="flex"
            marginBottom={2}
            pr={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <SidebarSubtitle>map your form</SidebarSubtitle>
            <ClickableTooltip
              text={mapYourFormTooltipText}
              icon={<StyledHelp />}
            />
          </Box>
          <Box>
            {!selectedField ? (
              <FormFieldsList
                fields={[...fields, ...tempFormRulesToCreate]}
                onAdd={() => setSelectedField("new")}
                onEdit={(field) => {
                  setSelectedField(field);
                }}
                onDelete={handleFieldDelete}
              />
            ) : (
              <>
                <Box>
                  <TextValidator
                    label="field name"
                    value={fieldName}
                    disabled={loading}
                    variant="outlined"
                    name="field name"
                    fullWidth
                    validators={["required"]}
                    errorMessages={["field name is required"]}
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                      setFieldName(e.currentTarget.value)
                    }
                  />
                </Box>
                <VerticalLine />
                <Box>
                  <StyledFormControl variant="outlined">
                    <InputLabel htmlFor="outlined-age-native-simple">
                      select type
                    </InputLabel>
                    <Select
                      value={fieldType}
                      onChange={handleFieldTypeChange}
                      label="select type"
                      name="field type"
                    >
                      <MenuItem value={ApiFormRuleType.INPUT}>input</MenuItem>
                      <MenuItem value={ApiFormRuleType.SEARCH_PARAM}>
                        file
                      </MenuItem>
                      <MenuItem value={ApiFormRuleType.FILE}>
                        search_param
                      </MenuItem>
                    </Select>
                  </StyledFormControl>
                </Box>
                <VerticalLine />
                <Box marginBottom={3}>
                  <Autocomplete
                    id="map-fields-to"
                    onChange={(e, value) => setMapFieldTo(value as string)}
                    value={mapFieldTo}
                    disableClearable
                    options={mapFields.map((rule) => rule)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="type or select from drop down"
                        value={mapFieldTo}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setMapFieldTo(e.currentTarget.value)
                        }
                        id="outlined-basic"
                        label="map field to"
                        variant="outlined"
                      />
                    )}
                  />
                </Box>
                <Box marginY={3} display="flex" justifyContent="space-between">
                  <StyledButton
                    disableElevation
                    disabled={!fieldName || !mapFieldTo}
                    size="large"
                    variant="contained"
                    type="button"
                    fullWidth
                    color="primary"
                    onClick={handleFieldSave}
                  >
                    {loading ? (
                      <CircularProgress size={"1rem"} color="inherit" />
                    ) : (
                      "save field"
                    )}
                  </StyledButton>
                  <StyledButton
                    disableElevation
                    size="large"
                    fullWidth
                    variant="contained"
                    onClick={() => setSelectedField(null)}
                    color="secondary"
                  >
                    cancel
                  </StyledButton>
                </Box>
              </>
            )}
          </Box>
        </>
      )}
      {!selectedField && !selectedAction && (
        <Box mt={4} mb={2}>
          <SidebarStyledDivider />
        </Box>
      )}
      {!selectedField && (
        <>
          <Box
            display="flex"
            marginBottom={2}
            pr={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <SidebarSubtitle>actions</SidebarSubtitle>
            <ClickableTooltip
              text="Let incendium know which actions to perform when the form is submitted."
              icon={<StyledHelp />}
            />
          </Box>
          <Box>
            {!selectedAction ? (
              <FormActionList
                actions={[...actions, ...tempFormActionsToCreate]}
                onAdd={() => setSelectedAction("new")}
                onEdit={(action) => {
                  setSelectedAction(action);
                }}
                onDelete={handleActionDelete}
              />
            ) : (
              <>
                <Box marginBottom={3} marginTop={4}>
                  <StyledFormControl variant="outlined">
                    <InputLabel htmlFor="outlined-age-native-simple">
                      select action
                    </InputLabel>
                    <Select
                      value={action}
                      onChange={handleActionChange}
                      label="select action"
                      name="select action"
                    >
                      <MenuItem value={ApiActionType.EMAIL_CONTACT}>
                        email_contact
                      </MenuItem>
                      <MenuItem value={ApiActionType.REDIRECT}>
                        redirect
                      </MenuItem>
                    </Select>
                  </StyledFormControl>
                </Box>
                <StyledFlexBox>
                  <Box display="flex" alignItems="center">
                    <Typography color={preventSubmit ? "primary" : "secondary"}>
                      manual
                    </Typography>
                    <Switch
                      disabled={loading}
                      checked={manualInput}
                      onChange={(e, checked) => setManualInput(checked)}
                    />
                    <Typography color={preventSubmit ? "primary" : "secondary"}>
                      field name
                    </Typography>
                  </Box>
                  <ClickableTooltip
                    text="Choose whether to manually input an email or URL, or to use information which will be input by the user into an existing field."
                    icon={<StyledHelp />}
                  />
                </StyledFlexBox>
                {!manualInput ? (
                  <Box marginY={3}>
                    <TextFieldHeading variant="body1" color="textPrimary">
                      send to
                    </TextFieldHeading>
                    <Box marginBottom={1}>
                      <Typography variant="caption" color="textSecondary">
                        please type email or url
                      </Typography>
                    </Box>
                    <TextValidator
                      value={sendTo}
                      variant="outlined"
                      name="send to"
                      fullWidth
                      disabled={loading}
                      validators={["required"]}
                      errorMessages={["input is required"]}
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        setSendTo(e.currentTarget.value)
                      }
                    />
                  </Box>
                ) : (
                  <>
                    <Box marginBottom={3} marginTop={4}>
                      <StyledFormControl
                        variant="outlined"
                        disabled={!allExistingFieldNames.length}
                      >
                        <InputLabel htmlFor="outlined-age-native-simple">
                          select existing field name
                        </InputLabel>
                        <Select
                          value={existingFieldName}
                          onChange={handleExistingFieldNameChange}
                          label="select existing field name"
                          name="existing field name"
                        >
                          {allExistingFieldNames?.map((field) => (
                            <MenuItem value={field.mapping}>
                              {field.mapping}
                            </MenuItem>
                          ))}
                        </Select>
                      </StyledFormControl>
                    </Box>
                  </>
                )}
                <Box marginY={3} display="flex" justifyContent="space-between">
                  <StyledButton
                    disableElevation
                    disabled={
                      (!manualInput && !sendTo) ||
                      (manualInput && !allExistingFieldNames.length)
                    }
                    size="large"
                    variant="contained"
                    type="button"
                    fullWidth
                    color="primary"
                    onClick={handleActionSave}
                  >
                    {loading ? (
                      <CircularProgress size={"1rem"} color="inherit" />
                    ) : (
                      "save action"
                    )}
                  </StyledButton>
                  <StyledButton
                    disableElevation
                    size="large"
                    fullWidth
                    variant="contained"
                    onClick={() => setSelectedAction(null)}
                    color="secondary"
                  >
                    cancel
                  </StyledButton>
                </Box>
              </>
            )}
          </Box>
        </>
      )}
      {/* main form buttons */}
      {!selectedField && !selectedAction && (
        <Box marginY={3} display="flex" justifyContent="space-between">
          <StyledButton
            disableElevation
            size="large"
            variant="contained"
            type="submit"
            fullWidth
            color="primary"
          >
            {loading ? (
              <CircularProgress size={"1rem"} color="inherit" />
            ) : (
              "save"
            )}
          </StyledButton>
          <StyledButton
            disableElevation
            size="large"
            fullWidth
            variant="contained"
            onClick={onCancel}
            color="secondary"
          >
            cancel
          </StyledButton>
        </Box>
      )}
    </ValidatorForm>
  );
};
