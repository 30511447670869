import { Box } from "@mui/material";
import { ChartLegendItem } from "features/analytics";
import { snakeToTitle } from "Helpers/camelToText";
import { TColourMap } from "Providers/AnalyticsProvider";
import { Payload } from "recharts/types/component/DefaultLegendContent";

interface IChartLegendWrapperProps {
  colourMap?: TColourMap;
  payload?: Payload[];
}

function ChartLegendWrapper({ colourMap, payload }: IChartLegendWrapperProps) {
  return (
    <Box
      mb={3}
      sx={{
        display: "grid",
        columnGap: 2,
        gridTemplateColumns: `repeat(${
          (payload || []).length > 3 ? 3 : (payload || []).length
        }, 1fr)`,
      }}
    >
      {(payload || []).map((entry, i) => (
        <ChartLegendItem
          fill={
            colourMap && colourMap[entry.value.split("\\")[0]]
              ? colourMap[entry.value.split("\\")[0]]
              : undefined
          }
          item={snakeToTitle(entry.value.replace("\\", "_"))}
          index={i}
          key={i}
        />
      ))}
    </Box>
  );
}

export default ChartLegendWrapper;
