/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function ApiTabChartCSVPayloadFromJSON(json) {
    return ApiTabChartCSVPayloadFromJSONTyped(json, false);
}
export function ApiTabChartCSVPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'from': !exists(json, 'from') ? undefined : (new Date(json['from'])),
        'to': !exists(json, 'to') ? undefined : (new Date(json['to'])),
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'lastNDays': !exists(json, 'lastNDays') ? undefined : json['lastNDays'],
    };
}
export function ApiTabChartCSVPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'from': value.from === undefined ? undefined : (value.from.toISOString()),
        'to': value.to === undefined ? undefined : (value.to.toISOString()),
        'timezone': value.timezone,
        'lastNDays': value.lastNDays,
    };
}
