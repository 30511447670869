/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiDimensionFromJSON, ApiDimensionToJSON, } from './ApiDimension';
export function ApiDimensionNamesFromJSON(json) {
    return ApiDimensionNamesFromJSONTyped(json, false);
}
export function ApiDimensionNamesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dimension': !exists(json, 'dimension') ? undefined : ApiDimensionFromJSON(json['dimension']),
        'names': !exists(json, 'names') ? undefined : json['names'],
    };
}
export function ApiDimensionNamesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dimension': ApiDimensionToJSON(value.dimension),
        'names': value.names,
    };
}
