import { ApiDomainStatus } from "@incendium/api";
import StatusLabel from "Components/UI/StatusLabel";
import { getSubdomainStatusText } from "features/subdomains/utils";
import { useMemo } from "react";

function SubdomainStatusLabel({ status }: { status: ApiDomainStatus }) {
  const statusTxt = useMemo(() => getSubdomainStatusText(status!), [status]);

  return (
    <StatusLabel
      variant="body2"
      value={`${status}`}
      mapping={{
        [ApiDomainStatus.DOMAIN_STATUS_NONE]: "secondary",
        [ApiDomainStatus.DOMAIN_STATUS_CERTIFICATE_PENDING]: "warning.main",
        [ApiDomainStatus.DOMAIN_STATUS_DOMAIN_PENDING]: "warning.main",
        [ApiDomainStatus.DOMAIN_STATUS_READY]: "success.main",
        [ApiDomainStatus.DOMAIN_STATUS_DELETING]: "error.main",
      }}
    >
      {statusTxt}
    </StatusLabel>
  );
}

export default SubdomainStatusLabel;
