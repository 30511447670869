import { ArrowRight } from "@mui/icons-material";
import { Badge, Button, Chip, Stack, Tooltip } from "@mui/material";
import produce from "immer";
import { useMemo } from "react";

function AnalyticsUserJourneyColumns({
  formatedValue,
  onClick,
}: {
  formatedValue: string;
  onClick?: (v: string, o?: string) => void;
}) {
  const pills = useMemo(() => {
    const paths = formatedValue.split(" -> ");

    return paths.reduce(
      (agg: { label: string; repeated: number }[], v: string, idx: number) => {
        const prev = paths[idx - 1];
        if (prev && prev === v) {
          return produce(agg, (draft) => {
            draft[draft.length - 1].repeated =
              draft[draft.length - 1].repeated + 1;
          });
        }

        return [
          ...agg,
          {
            label: v,
            repeated: 1,
          },
        ];
      },
      []
    );
  }, [formatedValue]);

  return (
    <Tooltip title={formatedValue} arrow>
      <Stack py={2} direction="row" sx={{ width: "100%", height: "100%" }}>
        {(pills || []).map(
          (p: { label: string; repeated: number }, i: number) => (
            <Stack key={i} direction="row" alignItems="center">
              <Badge
                color="secondary"
                sx={{
                  "& .MuiBadge-badge": {
                    color: "white",
                    fontSize: 10,
                    height: 15,
                    padding: 0.5,
                  },
                }}
                badgeContent={p.repeated < 2 ? 0 : `x${p.repeated}`}
              >
                <Button
                  sx={{ padding: 0, fontWeight: 400 }}
                  variant="text"
                  onClick={
                    onClick
                      ? () => onClick(p.label || "", formatedValue)
                      : undefined
                  }
                >
                  <Chip label={p.label} size="small" />
                </Button>
              </Badge>

              {i + 1 < pills.length && <ArrowRight />}
            </Stack>
          )
        )}
      </Stack>
    </Tooltip>
  );
}

export default AnalyticsUserJourneyColumns;
