import {
  ApiLocation,
  ApiLanderRequestPayload,
  ApiReadLanderResponse,
  LocationItemLocationItemType,
  LocationItemLocationItemCondition,
} from "@incendium/api";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { landersService, locationService } from "Apis";
import StyledDrawer, {
  StyledDrawerActions,
  StyledDrawerContainer,
  StyledDrawerTitle,
} from "Components/UI/StyledDrawer";
import { useLocations, useNotification, useSelectedProject } from "Hooks";
import { useComponents } from "Hooks/useComponents";
import { useSubdomains } from "Hooks/useSubdomains";
import { GenericDialoagProps } from "Interfaces";
import produce from "immer";
import { Suspense, useCallback, useMemo, useState } from "react";
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import { Link as RouterLink } from "react-router-dom";
import { SubdomainSelector } from "features/subdomains";
import { AccordianChartBuilderSidebarBlock } from "features/chartLibrary";
import { useDebounce, useUpdateEffect } from "react-use";
import { getPathFromUrl } from "Helpers/urls";
import { getRootDomain, getScheme } from "features/subdomains/utils";

interface ILandingPathSetupProps extends GenericDialoagProps {
  landingPath: ApiReadLanderResponse;
  setLandingPath: (v: ApiReadLanderResponse) => void;
  onSaved: (v: ApiReadLanderResponse) => void;
}

function LandingPathSetup({
  open,
  setOpen,
  landingPath,
  setLandingPath,
  onSaved,
}: ILandingPathSetupProps) {
  const { selectedClient, selectedProject } = useSelectedProject();
  const { subdomains } = useSubdomains();
  const { components } = useComponents();
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const [locationName, setLocationName] = useState("");
  const [validatedURL, setValidatedURL] = useState("");
  const [matchingLocations, setMatchingLocations] = useState<ApiLocation[]>([]);
  const { setLocations } = useLocations();

  const onClose = useCallback(() => {
    setOpen(false);
    setLandingPath({});
  }, [setOpen, setLandingPath]);

  const onSubmit = useCallback(async () => {
    const payload: ApiLanderRequestPayload = {
      description: landingPath.description,
      path: landingPath.path,
      subdomainId: landingPath.subdomainId,
      componentId: landingPath.componentId,
      noIndex: landingPath.noIndex,
      canonical: landingPath.canonical,
      deferIncendiumjs: landingPath.deferIncendiumjs,
    };

    try {
      const fn = landingPath.id
        ? landersService.landersServiceUpdateLander({
            projectId: selectedProject!.id as number,
            landerId: landingPath.id,
            payload,
          })
        : landersService.landersServiceCreateLander({
            projectId: selectedProject!.id as number,
            payload,
          });
      const res = await fn;
      onSaved(res);
      onClose();

      showSuccessNotification(`${landingPath.path} Saved!`);
    } catch (error) {
      showErrorNotification("Internal Error - Failed to save Lander");
    }
  }, [
    showSuccessNotification,
    showErrorNotification,
    landingPath,
    selectedProject,
    onSaved,
    onClose,
  ]);

  const subDomainObj = useMemo(() => {
    return landingPath.subdomainId
      ? subdomains.find((s) => s.id === landingPath.subdomainId)
      : null;
  }, [landingPath, subdomains]);

  const scheme = useMemo(() => {
    return subDomainObj?.domain
      ? `${getScheme(subDomainObj?.domain || "")}`
      : "[not-set]";
  }, [subDomainObj]);

  const domain = useMemo(() => {
    return subDomainObj?.domain
      ? `.${getRootDomain(subDomainObj?.domain || "")}`
      : "[not-set]";
  }, [subDomainObj]);

  const subDomain = useMemo(() => {
    return subDomainObj ? subDomainObj.subdomain : "[not-set]";
  }, [subDomainObj]);

  const fullPath = useMemo(() => {
    return `${scheme}//${subDomain}${domain}/${
      landingPath.path || "[not-set]"
    }/`;
  }, [domain, scheme, subDomain, landingPath.path]);

  useDebounce(
    () => {
      let url = validatedURL && validatedURL !== "" ? validatedURL : fullPath;

      const fn = async () => {
        const res =
          await locationService.locationServiceGetProjectsLocationsByPage({
            projectKey: `${selectedProject?.key}`,
            queryPage: url,
          });

        setMatchingLocations(res.matching || []);
      };
      if (open && selectedProject && !url.includes("[not-set]")) {
        fn();
      }
    },
    500,
    [open, fullPath, selectedProject, validatedURL]
  );

  const validateURL = useCallback(async (url) => {
    const res = await locationService.locationServiceValidateLocation({
      body: {
        url,
      },
    });
    if (res && res.newUrl && res.newUrl !== "") {
      setValidatedURL(res.newUrl);
    }
  }, []);

  useUpdateEffect(() => {
    if (fullPath.includes("not-set")) {
      return;
    }
    validateURL(fullPath);
  }, [fullPath]);

  const createLocation = useCallback(async () => {
    try {
      let fullUrl =
        validatedURL && validatedURL !== "" ? validatedURL : fullPath;
      let path = getPathFromUrl(fullUrl);

      const location: ApiLocation = {
        name: locationName,
        urlMatch: fullUrl,
        showInAnalytics: true,
        items: [
          {
            type: LocationItemLocationItemType.DOMAIN,
            condition: LocationItemLocationItemCondition.EQUAL,
            value: `${subDomain}${domain}`,
          },
          {
            type: LocationItemLocationItemType.PATH,
            condition: LocationItemLocationItemCondition.EQUAL,
            value: path,
          },
        ],
      };

      const res = await locationService.locationServiceCreateLocation({
        projectId: selectedProject!.id as number,
        payload: { location },
      });

      setMatchingLocations(
        produce(matchingLocations, (draft) => {
          draft.push(res.result!);
        })
      );
      setLocations((locations) =>
        produce(locations, (draft) => {
          draft.push(res.result!);
        })
      );
      setLocationName("");
      showSuccessNotification(`${res.result?.name || ""} Saved!`);
    } catch (error) {
      showErrorNotification("Internal Error - Failed to save Location");
    }
  }, [
    selectedProject,
    fullPath,
    locationName,
    domain,
    subDomain,
    showSuccessNotification,
    showErrorNotification,
    matchingLocations,
    validatedURL,
    setLocations,
  ]);

  return (
    <StyledDrawer open={open} onClose={onClose} maxWidth={600}>
      <StyledDrawerTitle>
        <Typography variant="subtitle1">Lightning Lander Setup</Typography>
        <Typography variant="body2">
          Craft your Lightning Lander by adding the path and linking it to a
          subdomain of your choice for your landing page location. Incorporate
          one of your personalized components and choose how search engines
          should perceive your page.
        </Typography>
      </StyledDrawerTitle>
      <ValidatorForm onSubmit={onSubmit}>
        <Suspense>
          <StyledDrawerContainer>
            <TextField
              variant="outlined"
              fullWidth
              name="description"
              label="Description"
              helperText="Provide your Lander with a description to clarify its purpose and enhance understanding of the page's objectives."
              value={landingPath.description}
              multiline
              rows={2}
              minRows={2}
              maxRows={5}
              onChange={(e) =>
                setLandingPath(
                  produce(landingPath, (draft) => {
                    draft.description = e.target.value;
                  })
                )
              }
            />
          </StyledDrawerContainer>
          <Divider />
          <StyledDrawerContainer>
            <Typography variant="subtitle1">Select Landing Page</Typography>
            <Typography variant="body2">
              Choose the landing page to use. If you haven't created the page
              yet, you can do so{" "}
              <Link
                underline="always"
                to={`/clients/${selectedClient!.id}/projects/${
                  selectedProject!.id
                }/publish/components`}
                color="inherit"
                component={RouterLink}
              >
                here
              </Link>
              .
            </Typography>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={8}>
                <SelectValidator
                  size={"small"}
                  variant="outlined"
                  fullWidth
                  label="Which Component"
                  name="component"
                  validators={["required"]}
                  errorMessages={["Component is required."]}
                  value={landingPath.componentId || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setLandingPath(
                      produce(landingPath, (draft) => {
                        draft.componentId = Number(e.target.value);
                      })
                    );
                  }}
                >
                  {(components || []).map((comp) => (
                    <MenuItem key={comp.id} value={comp.id}>
                      {comp.name}
                    </MenuItem>
                  ))}
                </SelectValidator>
              </Grid>
              <Grid item xs={4}>
                <Button fullWidth size="small">
                  Create new page
                </Button>
              </Grid>
            </Grid>
            <Stack mt={3} spacing={3}>
              <Stack>
                <FormControlLabel
                  componentsProps={{
                    typography: {
                      variant: "body1",
                    },
                  }}
                  control={
                    <Switch
                      checked={landingPath.noIndex}
                      onChange={(e, checked) => {
                        setLandingPath(
                          produce(landingPath, (draft) => {
                            draft.noIndex = checked;
                          })
                        );
                      }}
                    />
                  }
                  label="Add no-index"
                />
                <Typography variant="body2">
                  If enabled, we'll add the 'no-index' tag to your page,
                  preventing search engines like Google from indexing this page.
                </Typography>
              </Stack>
              <TextValidator
                label="Canonical"
                size="small"
                helperText="Add a canonical record to specify the preferred URL for a webpage, assisting in preventing duplicate content issues by signaling the primary version for search engines to index."
                fullWidth
                value={landingPath.canonical || ""}
                name="canonical"
                validators={["isDomain"]}
                errorMessages={["Must be a valid URL"]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLandingPath(
                    produce(landingPath, (draft) => {
                      draft.canonical = e.target.value;
                    })
                  );
                }}
              />
              <Stack>
                <FormControlLabel
                  componentsProps={{
                    typography: {
                      variant: "body1",
                    },
                  }}
                  control={
                    <Switch
                      checked={
                        typeof landingPath.deferIncendiumjs !== "undefined"
                          ? landingPath.deferIncendiumjs
                          : true
                      }
                      onChange={(e, checked) => {
                        setLandingPath(
                          produce(landingPath, (draft) => {
                            draft.deferIncendiumjs = checked;
                          })
                        );
                      }}
                    />
                  }
                  label="Defer Incendium.js"
                />
                <Typography variant="body2">
                  Enabling 'defer' instructs the browser to commence downloading
                  the incendium.js script immediately while delaying its
                  execution until after the webpage's rendering, potentially
                  enhancing loading efficiency.
                </Typography>
              </Stack>
            </Stack>
          </StyledDrawerContainer>
          <Divider />
          <StyledDrawerContainer>
            <Typography variant="subtitle1">Path Location</Typography>
            <Typography variant="body2">
              Configure the Path and Subdomain for your Landing Page. If you
              haven't created the required subdomain, you can do so{" "}
              <Link
                underline="always"
                to={`/clients/${selectedClient!.id}/projects/${
                  selectedProject!.id
                }/connect/subdomains`}
                color="inherit"
                component={RouterLink}
              >
                here
              </Link>
              .
            </Typography>
            <Stack mt={3} spacing={3}>
              <TextValidator
                variant="outlined"
                fullWidth
                size="small"
                name="path"
                label="Landing Path"
                helperText="do not include the initial /"
                value={landingPath.path || ""}
                validators={["isPath", "required"]}
                errorMessages={[
                  "Landing Path must be a valid path and not start with /",
                  "Landing Path is required.",
                ]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setLandingPath(
                    produce(landingPath, (draft) => {
                      draft.path = e.target.value;
                    })
                  )
                }
              />
              <SubdomainSelector
                subdomainId={landingPath.subdomainId}
                setSubdomainId={(v) =>
                  setLandingPath(
                    produce(landingPath, (draft) => {
                      draft.subdomainId = v;
                    })
                  )
                }
                helperText={`This is the subdomain people will access to use your landing page. e.g myselecteddomain${domain}`}
              />

              <Box>
                <Typography variant="subtitle2" color={"secondary"}>
                  Your page will be available at{" "}
                </Typography>
                <Link href={fullPath} target="_blank" color={"primary.main"}>
                  {fullPath}
                </Link>
              </Box>
            </Stack>
          </StyledDrawerContainer>
          <Divider />

          <AccordianChartBuilderSidebarBlock
            title="Location Setup"
            subTitle="Create a location for this lander."
            // defaultClosed
          >
            <Stack mb={4}>
              <Typography variant="subtitle2" color={"secondary"} gutterBottom>
                We have found that this lander would match with the following
                locations.
              </Typography>
              {[...matchingLocations]
                .sort(
                  (a, b) =>
                    (a.createdAt || new Date()).getTime() -
                    (b.createdAt || new Date()).getTime()
                )
                .map((l) => (
                  <Typography key={l.id} variant="body2">
                    <strong>{l.name}</strong> - (
                    {l.showInAnalytics
                      ? "show in analytics"
                      : "hide in analytics"}
                    )
                  </Typography>
                ))}
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <TextField
                fullWidth
                label="Create new location"
                helperText="Generate a new location with rules designed specifically for this Lander."
                size="small"
                value={locationName}
                onChange={(e) => setLocationName(e.target.value)}
              />
              <Button
                sx={{ width: "35%" }}
                size="small"
                onClick={createLocation}
              >
                Create Location
              </Button>
            </Stack>
          </AccordianChartBuilderSidebarBlock>

          <StyledDrawerActions>
            <Button onClick={onClose} color="secondary">
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </StyledDrawerActions>
        </Suspense>
      </ValidatorForm>
    </StyledDrawer>
  );
}

export default LandingPathSetup;
