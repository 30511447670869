import { ApiLocation } from "@incendium/api";
import {
  Button,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SpacedList, { SpacedLinkButton } from "Components/UI/SpacedList";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory, useRouteMatch } from "react-router-dom";
import AppendLocationPageTypeField from "Components/PageTypes/AppendLocationPageTypeField";
import { useLocations, useSelectedProject } from "Hooks";
import produce from "immer";
import { cell1Icon } from "consts";
import { useCallback } from "react";

export const LocationList = ({
  onDelete,
}: {
  onDelete: (l: ApiLocation) => void;
}) => {
  const { url } = useRouteMatch();
  const { locations, setLocations } = useLocations();
  const { selectedProject } = useSelectedProject();
  const history = useHistory();

  const handleNewLocation = useCallback(() => {
    history.push(`${url}/new/location-setup`);
  }, [url, history]);

  const onEdit = useCallback(
    (location) => {
      history.push(`${url}/${location.id}/location-setup`);
    },
    [url, history]
  );

  return (
    <SpacedList
      title="Locations List"
      action={<Button onClick={handleNewLocation}>New Location</Button>}
    >
      <TableHead>
        <TableRow>
          <TableCell>Location Name</TableCell>
          <TableCell>Example URL</TableCell>
          <TableCell>Location Tags</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {(locations || []).map((location, i) => {
          return (
            <TableRow key={location.id}>
              <TableCell className="primary" style={{ maxWidth: 450 }}>
                <SpacedLinkButton onClick={() => onEdit(location)}>
                  {location.name || ""}
                </SpacedLinkButton>
              </TableCell>
              <TableCell style={{ maxWidth: 300 }}>
                <Typography variant="body1" noWrap>
                  {location.urlMatch}
                </Typography>
              </TableCell>
              <TableCell>
                <AppendLocationPageTypeField
                  size="small"
                  project={selectedProject || {}}
                  location={location}
                  setLocation={(location) => {
                    setLocations(
                      produce(locations, (draft) => {
                        draft[i] = {
                          ...location,
                        };
                      })
                    );
                  }}
                />
              </TableCell>

              <TableCell align="right" sx={{ width: cell1Icon }}>
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={() => onDelete(location)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </SpacedList>
  );
};
