import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { Save } from "@mui/icons-material";

interface ISavingButtonProps extends ButtonProps {
  onSave?: (...args: any[]) => void;
  saving: boolean;
}

function SavingButton({
  onSave,
  children,
  saving,
  ...props
}: ISavingButtonProps) {
  return (
    <Button
      onClick={onSave}
      disabled={saving}
      startIcon={
        saving ? <CircularProgress color="inherit" size={20} /> : <Save />
      }
      {...props}
    >
      {children}
    </Button>
  );
}

export default SavingButton;
