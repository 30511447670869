/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiIntegrationServiceFromJSON, ApiIntegrationServiceToJSON, } from './ApiIntegrationService';
import { ApiIntegrationServiceChoiceFromJSON, ApiIntegrationServiceChoiceToJSON, } from './ApiIntegrationServiceChoice';
import { ApiMediumFromJSON, ApiMediumToJSON, } from './ApiMedium';
import { ApiProviderTypeFromJSON, ApiProviderTypeToJSON, } from './ApiProviderType';
export function ApiProviderFromJSON(json) {
    return ApiProviderFromJSONTyped(json, false);
}
export function ApiProviderFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : ApiProviderTypeFromJSON(json['type']),
        'integrationServiceChoice': !exists(json, 'integrationServiceChoice') ? undefined : (json['integrationServiceChoice'].map(ApiIntegrationServiceChoiceFromJSON)),
        'integrationService': !exists(json, 'integrationService') ? undefined : (json['integrationService'].map(ApiIntegrationServiceFromJSON)),
        'medium': !exists(json, 'medium') ? undefined : ApiMediumFromJSON(json['medium']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'instructions': !exists(json, 'instructions') ? undefined : json['instructions'],
    };
}
export function ApiProviderToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'type': ApiProviderTypeToJSON(value.type),
        'integrationServiceChoice': value.integrationServiceChoice === undefined ? undefined : (value.integrationServiceChoice.map(ApiIntegrationServiceChoiceToJSON)),
        'integrationService': value.integrationService === undefined ? undefined : (value.integrationService.map(ApiIntegrationServiceToJSON)),
        'medium': ApiMediumToJSON(value.medium),
        'description': value.description,
        'instructions': value.instructions,
    };
}
