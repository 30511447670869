import { Grid } from "@mui/material";
import { useAudienceAnalyticsContext } from "Providers/AudienceAnalyticsProvider";
import { AudiencePickerSubAction } from "Pages/Analyse/Audiences";
import { AnalyticsCard } from "features/analytics";
import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import { AnalyticsSpacing } from "consts";

function AudienceEngagementTab() {
  const { audienceAttributes } = useAudienceAnalyticsContext();
  return (
    <>
      <AudiencePickerSubAction />
      <Grid container spacing={AnalyticsSpacing}>
        <Grid item xs={6} sx={{ minHeight: 500 }}>
          <AnalyticsCard
            chart={{
              name: "Audience Engagement Performance",
              dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.BUBBLE,
              attributes: audienceAttributes,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [
                    ApiMetric.METRIC_SESSIONS_COUNT,
                    ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                    ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
                  ],
                },
              ],
              displayOptions: {
                noFilters: true,
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <AnalyticsCard
            chart={{
              name: "Audience Primary Stats",
              dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
              template: ApiChartTemplate.TABLE,
              type: ApiChartType.TABLE,
              attributes: audienceAttributes,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [
                    ApiMetric.METRIC_SESSIONS_COUNT,
                    ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                    ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
                    ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                    ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
                    ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT,
                  ],
                },
              ],
              displayOptions: {
                noFilters: true,
              },
            }}
          />
        </Grid>
        <Grid item xs={8} sx={{ minHeight: 500 }}>
          <AnalyticsCard
            chart={{
              name: "Audience Effective Sessions by User Type",
              dimension: [
                ApiDimension.DIMENSION_SESSION_START_BY_DAY,
                ApiDimension.DIMENSION_LEAD_AUDIENCE,
              ],
              template: ApiChartTemplate.TREND,
              type: ApiChartType.GRAPH,
              attributes: audienceAttributes,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT],
                },
              ],
              displayOptions: {
                noFilters: true,
                filterDropdowns: [ApiDimension.DIMENSION_USER_TYPE],
              },
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <AnalyticsCard
            chart={{
              name: "Audience Effective Sessions by User Type Composition",
              dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.PIE,
              attributes: audienceAttributes,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT],
                },
              ],
              displayOptions: {
                noFilters: true,
                filterDropdowns: [ApiDimension.DIMENSION_USER_TYPE],
                showTotals: true,
              },
            }}
          />
        </Grid>
        <Grid item xs={8} sx={{ minHeight: 500 }}>
          <AnalyticsCard
            chart={{
              name: "Audience Effective Sessions by Device",
              dimension: [
                ApiDimension.DIMENSION_SESSION_START_BY_DAY,
                ApiDimension.DIMENSION_LEAD_AUDIENCE,
              ],
              template: ApiChartTemplate.TREND,
              type: ApiChartType.GRAPH,
              attributes: audienceAttributes,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT],
                },
              ],
              displayOptions: {
                noFilters: true,
                filterDropdowns: [ApiDimension.DIMENSION_DEVICE],
              },
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <AnalyticsCard
            chart={{
              name: "Audience Effective Sessions by Device Composition",
              dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.PIE,
              attributes: audienceAttributes,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT],
                },
              ],
              displayOptions: {
                noFilters: true,
                filterDropdowns: [ApiDimension.DIMENSION_DEVICE],
                showTotals: true,
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AudienceEngagementTab;
