import {
  Container,
  Typography,
  Stepper,
  Step,
  Button,
  Box,
  Divider,
  Stack,
  StepButton,
} from "@mui/material";
import { BingAdsTrackingTemplate } from "features/utms";
import { bingIAIMapping } from "features/utms/components/BingAdsTrackingTemplate";
import {
  I_CAMPAIGNID,
  I_CONTENTID,
  I_MEDIUM,
  I_SOURCE,
  I_TERM,
} from "features/utms/constants";
import { useState } from "react";

const steps = [
  "Sign in to Microsoft Advertising",
  "Navigate to Account Settings",
  "Open URL Options",
  "Set Up Tracking Template",
  "Explanation of Parameters",
  "Save the Changes",
];

interface IBingAdsTrackingSetupProps {
  onFinish: () => void;
}

function BingAdsTrackingSetup({ onFinish }: IBingAdsTrackingSetupProps) {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Typography>
            Log in to your Microsoft Advertising account at{" "}
            <a
              href="https://ads.microsoft.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Microsoft Advertising
            </a>
            .
          </Typography>
        );
      case 1:
        return (
          <Typography>
            From the collapsible left menu hover on <strong>Campaigns</strong>{" "}
            and select <strong>Settings</strong>.
          </Typography>
        );
      case 2:
        return (
          <Typography>
            In the Settings page, click on{" "}
            <strong>Account Level Options</strong>.
          </Typography>
        );
      case 3:
        return <BingAdsTrackingTemplate />;
      case 4:
        return (
          <Box>
            <Typography>
              <strong>Explanation of Parameters:</strong>
            </Typography>
            <ul>
              <li>
                <strong>{I_SOURCE}</strong>: Represents the source of the
                traffic. Set this to <code>{bingIAIMapping[I_SOURCE]}</code>.
              </li>
              <li>
                <strong>{I_MEDIUM}</strong>: Represents the medium of the
                traffic. Set this to <code>{bingIAIMapping[I_MEDIUM]}</code>.
              </li>
              <li>
                <strong>{I_CAMPAIGNID}</strong>: Dynamically inserts the
                campaign ID. Use <code>{bingIAIMapping[I_CAMPAIGNID]}</code>.
              </li>
              <li>
                <strong>{I_CONTENTID}</strong>: Dynamically inserts the ad group
                ID. Use <code>{bingIAIMapping[I_CONTENTID]}</code>.
              </li>
              <li>
                <strong>{I_TERM}</strong>: Dynamically inserts the term or
                keyword that triggered the ad. Use{" "}
                <code>{bingIAIMapping[I_TERM]}</code>.
              </li>
            </ul>
          </Box>
        );
      case 5:
        return (
          <Typography>
            Click <strong>Save</strong> to apply the tracking template to your
            account.
          </Typography>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Container>
      <Typography variant="h4" align="center" mb={4}>
        Setting Up Account-Level Incendium Tracking Parameters in Microsoft
        Advertising
      </Typography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, i) => (
          <Step key={label} disabled={false}>
            <StepButton onClick={() => setActiveStep(i)}>{label}</StepButton>
          </Step>
        ))}
      </Stepper>
      <Stack mt={4} spacing={4}>
        {renderStepContent(activeStep)}
        <Divider />
        <Box>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={activeStep === steps.length - 1 ? onFinish : handleNext}
          >
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </Button>
        </Box>
      </Stack>
    </Container>
  );
}

export default BingAdsTrackingSetup;
