import { QueryStats } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { StyledMiddleBox } from "Components/UI/StylesFlexBox";

function NoData() {
  return (
    <StyledMiddleBox sx={{ height: "100%" }} flexDirection={"column"}>
      <QueryStats fontSize="large" />
      <Typography mt={1} variant="subtitle2">
        No Data
      </Typography>
    </StyledMiddleBox>
  );
}

export default NoData;
