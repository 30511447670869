/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiChartTemplate;
(function (ApiChartTemplate) {
    ApiChartTemplate["UNDEFINED"] = "CHART_TEMPLATE_UNDEFINED";
    ApiChartTemplate["TREND"] = "CHART_TEMPLATE_TREND";
    ApiChartTemplate["MAP"] = "CHART_TEMPLATE_MAP";
    ApiChartTemplate["SNAPSHOT"] = "CHART_TEMPLATE_SNAPSHOT";
    ApiChartTemplate["TABLE"] = "CHART_TEMPLATE_TABLE";
    ApiChartTemplate["ATTRIBUTION"] = "CHART_TEMPLATE_ATTRIBUTION";
})(ApiChartTemplate || (ApiChartTemplate = {}));
export function ApiChartTemplateFromJSON(json) {
    return ApiChartTemplateFromJSONTyped(json, false);
}
export function ApiChartTemplateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiChartTemplateToJSON(value) {
    return value;
}
